import React, { useState } from 'react';

import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export interface MessageSnackbarProps {
  content: string;
  config?: AlertProps;
}

function MessageSnackbar(props: MessageSnackbarProps) {
  const { content = '', config = { severity: 'error' } } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} {...config} elevation={5}>
        {content.charAt(0).toUpperCase() + content.slice(1)}
      </Alert>
    </Snackbar>
  );
}

export default MessageSnackbar;
