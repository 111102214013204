import React, { useEffect, useState } from 'react';

import { subYears } from 'date-fns';
import FinancialOrgTaxReportingSettingsForm, {
  FINANCIAL_ORG_TAX_REPORTING_SETTINGS_INT,
} from '../form/organization/FinancialOrgReportingSetting';
import { OrganizationTaxReportingSettings } from '../../api/OrganizationApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import {
  getOrganizationalSettings,
  putOrganizationalSettings,
} from '../../api/OrganizationApi';
import { errorMessages } from '../../utils/errorhandling.utils';

function OrganizationTaxReportSettings() {
  let isMounted = true;
  const [taxReportingSettings, setTaxReportingSettings] = useState(
    {} as OrganizationTaxReportingSettings
  );
  const { addGlobalMessage, taxYears } = useGlobalContext();
  const { user } = useUser();
  const currentTaxYear = subYears(new Date(), 1).getFullYear();
  const [activeTaxYear, setActiveYear] = useState(currentTaxYear);

  async function getOrgTaxReportingSettings(activeYear) {
    setActiveYear(activeYear);
    await getOrganizationalSettings(
      activeYear,
      user.organizationId,
      user.token
    )
      .then((res) => {
        if (isMounted) {
          setTaxReportingSettings(res.data);
        }
      })
      .catch((err) => {
        if (isMounted) {
  
 addGlobalMessage(errorMessages(err) || 'Error retreiving tax reporting settings'
          );
        }
      });
  }

  async function saveOrganizationTaxReportingSettings(data: any) {
    const cleanData = { ...data };
    delete cleanData.taxYear;

    await putOrganizationalSettings(
      cleanData,
      activeTaxYear,
      user.organizationId,
      user.token
    )
      .then(() => {
        if (isMounted) {
          getOrgTaxReportingSettings(activeTaxYear);
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
  
 addGlobalMessage(errorMessages(err) || 'Error saving organization information'
          );
        }
      });
  }

  useEffect(() => {
    if (user.token && user.organizationId) {
      const activeYear = taxYears.find(
        (year) => year.active === true
      )?.taxYear;

      setActiveYear(activeYear);
      getOrgTaxReportingSettings(activeYear);
    }
    return () => {
      isMounted = false;
    };
  } , [user.token, user.organizationId]);


  return (
    <FinancialOrgTaxReportingSettingsForm
      initialValues={{
        ...FINANCIAL_ORG_TAX_REPORTING_SETTINGS_INT,
        ...taxReportingSettings, 
        taxYear: activeTaxYear,
        // Pass through some other required org properties here even though the fields aren't available in the UI
        // But avoid spreading branches, etc which have their own endpoints
      }}
      onSubmit={async (data) => {
        await saveOrganizationTaxReportingSettings(data);
      }}
      getYearOptions={(activeYear) => getOrgTaxReportingSettings(activeYear)} 
    />
  );
}

export default OrganizationTaxReportSettings;
