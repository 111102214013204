import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import { Grid, Typography } from '@mui/material';

import SiraTextField from '../SiraTextField';
import PhoneNumberField, {
  PhoneNumberFormat,
  phoneValidation,
} from '../PhoneNumberField';
import { Account, AccountType } from '../../../api/AccountApi.d';
import SiraCheckbox from '../SiraCheckbox';
import InfoPopover from '../../InfoPopover';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { Country } from '../../../api/StatesApi.d';
import ResponsibleAddressForm, { AddressFieldNames, ADDRESS_SCHEMA } from '../ResponsibleAddressForm';

const addressFieldNames: AddressFieldNames = {
  addressLine1: 'responsibleAddressLine1',
  addressLine2: 'responsibleAddressLine2',
  city: 'responsibleCity',
  state: 'responsibleState',
  zip: 'responsibleZip',
  country: 'responsibleCountry',
  responsibleForeignStateProvinceRegion: 'responsibleForeignStateProvinceRegion',
};

export const COVERDELL_SCHEMA = ADDRESS_SCHEMA({
  addressFieldNames,
}).shape({
  responsibleFirstName: yup.string().max(40).required().label('First Name'),
  responsibleLastName: yup.string().required().max(40).label('Last Name'),
  responsiblePhoneNumber: phoneValidation(PhoneNumberFormat.unitedStates).label(
    'Phone Number'
  ),
  responsibleEmailAddress: yup.string().email().max(50).label('Email'),
});

export interface CoverdellFormProps {
  enableCoverdellSchema?: Function;
  accountOwner?: AccountOwner;
  account?: Account;
}

function CoverdellForm({
  enableCoverdellSchema,
  accountOwner = {},
  account = {},
}: CoverdellFormProps) {
  const { values } = useFormikContext<Account>();

  useEffect(() => {
    if (enableCoverdellSchema) {
      enableCoverdellSchema([AccountType.esa].includes(values.accountType));
    }
  }, [values.accountType]);

  useEffect(() => {
    // add reponsibleCountry to account if it doesn't exist and default to US
    if (!account.responsibleCountry) {
      account.responsibleCountry = Country['en-US'];
    }
  }
  , [account.responsibleCountry]);

  return [AccountType.esa].includes(values.accountType) ? (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h6" pt={1.5} pb={2.25}>
              Responsible Individual Information
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <InfoPopover
              text="This person is generally the designated beneficiary’s parent or guardian and is designated by the individual opening the Coverdell ESA. Only one responsible individual may be named for the account."
              ariaLabel="Responsible Individual Information"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SiraTextField name="responsibleFirstName" label="First Name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SiraTextField name="responsibleLastName" label="Last Name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneNumberField
              name="responsiblePhoneNumber"
              label="Phone Number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SiraTextField name="responsibleEmailAddress" label="Email" />
          </Grid>

          <ResponsibleAddressForm
            addressFieldNames={addressFieldNames}
            accountOwner={{...accountOwner}}
            account={{...account}}
            allowAddressCopy
            international
            esa
          />

          <Grid item xs={10}>
            <Field
              name="allowBeneficiaryChange"
              label="May change the designated beneficiary to another member of the individual’s family."
              type="checkbox"
              component={SiraCheckbox}
            />
          </Grid>
          <Grid item xs={2}>
            <InfoPopover
              text="The responsible individual may or may not change the beneficiary designated under this agreement to another member of the designated beneficiary’s family described in section 529(e)(2) in accordance with the trustee’s procedures"
              ariaLabel="Change Beneficiary Details"
            />
          </Grid>
          <Grid item xs={10}>
            <Field
              name="responsibleAfterMajority"
              label="Will continue to serve as the responsible individual after the designated beneficiary attains the age of majority under state law"
              type="checkbox"
              component={SiraCheckbox}
            />
          </Grid>
          <Grid item xs={2}>
            <InfoPopover
              text="The responsible individual shall continue to serve as the responsible individual for the trust after the designated beneficiary attains the age of majority under state law and until such time as all assets have been distributed from the trust and the trust terminates. If the responsible individual becomes incapacitated or dies after the designated beneficiary reaches the age of majority under state law, the responsible individual shall be the designated beneficiary."
              ariaLabel="Majority Choice Details"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : null;
}

export default CoverdellForm;
