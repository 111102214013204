import React, { useEffect, useState } from 'react';
import { Alert, Box, LinearProgress } from '@mui/material';
import {
  getAccountOwner,
  searchAccountOwner,
} from '../../../api/AccountOwnerApi';
import { useUser } from '../../../auth/useUser';
import {
  AccountOwnerResponse,
  AccountOwnerSearchResponse,
} from '../../../api/AccountOwnerApi.d';

interface DuplicateUserCheckProps {
  taxId: string;
  onDuplicateFound: Function;
}

const reSsn = /^\d{3}-?\d{2}-?\d{4}$/;
const reTax = /^\d{2}-?\d{7}$/;

function DuplicateUserCheck({
  taxId,
  onDuplicateFound,
}: DuplicateUserCheckProps) {
  const [loading, setLoading] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [accountOwnerId, setAccountOwnerId] = useState('');
  const active = !!onDuplicateFound;
  const { user } = useUser();
  let isMounted = true;

  async function taxIdSearch(id: string) {
    if (id.match(reSsn) || id.match(reTax)) {
      setUserLoaded(false);
      setLoading(true);
      searchAccountOwner({ query: taxId }, user.organizationId, user.token)
        .then((resp: AccountOwnerSearchResponse) => {
          if (isMounted) {
            setLoading(false);
            if (resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
              setAccountOwnerId(resp.data[0].accountOwner.accountOwnerId);
            }
          }
        })
        .catch(() => {
          if (isMounted) {
            setLoading(false);
            setIsError(true);
          }
        });
    }
  }

  async function loadAccountOwner(id: string) {
    if (!id) {
      return;
    }
    setLoading(true);
    setIsError(false);
    getAccountOwner(id, user.organizationId, user.token, user)
      .then((resp: AccountOwnerResponse) => {
        setLoading(false);
        setUserLoaded(true);
        onDuplicateFound(resp.data);
      })
      .catch(() => {
        setLoading(false);
        setIsError(true);
      });
  }

  useEffect(() => {
    if (taxId && active) {
      taxIdSearch(taxId);
    }
  }, [taxId, active]);

  useEffect(() => {
    if (isMounted) {
      loadAccountOwner(accountOwnerId);
    }
    return () => {
      isMounted = false;
    };
  }, [accountOwnerId]);

  return (
    <>
      {active && (
        <Box width="1">
          {loading && (
            <>
              <Alert severity="info">
                Checking for existing client with taxId ending in{' '}
                {taxId.slice(8)}.
              </Alert>
              <LinearProgress color="secondary" />
            </>
          )}
          {isError && (
            <Alert severity="warning">
              Unable to determine if User already registered in system.
            </Alert>
          )}
          {userLoaded && (
            <Alert severity="success">
              Owner information loaded from existing client record.
            </Alert>
          )}
        </Box>
      )}
    </>
  );
}

export default DuplicateUserCheck;
