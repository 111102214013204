import axios from 'axios';
import format from 'string-format';

import { RmdNotificationsResponse } from './RmdNotificationApi.d';
import { setBaseUrl } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;


const rmdNotificationsUrl = '/org/{0.orgId}/rmdNotifications';
const accountRmdNotificationsUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/accountRmdNotifications';
const rmdNotificationDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/rmdNotification/{0.rmdNotificationId}/document';

export async function getOrgRmdNotifications(
  orgId: string,
  token: string,
  user: any
): Promise<RmdNotificationsResponse> {
  if (!token) return null;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${rmdNotificationsUrl}`, { orgId })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountRmdNotifications(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token, 
   user: any
): Promise<RmdNotificationsResponse> {
  if (!token) return null;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountRmdNotificationsUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getRmdNotificationDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  rmdNotificationId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${rmdNotificationDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    rmdNotificationId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export async function deleteAccountRmdNotifications(
  orgId: string,
  rmdNotificationId: string,
  accountOwnerId: string,
  accountId: string,
  token,
  user: any
) {
  if (!token) return null;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${rmdNotificationDocumentUrl}`, {
    orgId,
    rmdNotificationId,
    accountOwnerId,
    accountId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}