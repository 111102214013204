import React from 'react';
import {
  useTheme,
  Card,
  CardActions,
  CardContent,
  Button,
  Avatar,
  Grid,
  GridProps,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../auth/useGlobalContext';
import { Solution } from '../api/OrganizationApi.d';

interface ActionCardProps extends GridProps {
  iconImage?: React.ReactElement;
  buttonText?: string;
  route?: string;
  solutions?: Array<Solution>;
  dataQa?: string;
  triggerCustomFunction?: Function;
}

function ActionCard(props: ActionCardProps) {
  const {
    iconImage,
    buttonText,
    route,
    solutions,
    dataQa,
    triggerCustomFunction = () => {},
  } = props;
  const navigate = useNavigate();
  const { organization } = useGlobalContext();
  const theme = useTheme();
  const classes = {
    root: {
      cursor: 'pointer',
      height: '100%',
      padding: '0',
      '& button': {
        height: '100%',
        alignItems: 'flex-start',
        textTransform: 'none',
      },
    },
    cardContent: {
      padding: '16px 0',
    },
    avaDot: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(9),
      height: theme.spacing(9),
      marginTop: '4px !important',
      marginBottom: '20px !important',
      margin: 'auto',
      '& svg': {
        color: theme.palette.secondary.light,
      },
    },
    center: {
      justifyContent: 'center',
    },
  };

  const hasMatchingSolutions =
    solutions && organization.solution
      ? solutions.includes(organization.solution)
      : true;

  if (!hasMatchingSolutions) {
    return null;
  }

  const navigateAway = () => {
    if (route) {
      navigate(route);
    }
    triggerCustomFunction();
  };

  return (
    <Grid item xs={6} sm={4}>
      <Card sx={classes.root} elevation={7}>
        <Button
          data-qa={dataQa}
          fullWidth
          size="large"
          color="primary"
          onClick={() => {
            navigateAway();
          }}
        >
          <CardContent sx={classes.cardContent}>
            <Avatar sx={classes.avaDot}>{iconImage}</Avatar>
            <CardActions sx={classes.center}>{buttonText}</CardActions>
          </CardContent>
        </Button>
      </Card>
    </Grid>
  );
}

export default ActionCard;
