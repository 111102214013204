import React from 'react';
import { useTheme, IconButton, Popover, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

type InfoPopoverProps = {
  text: string;
  ariaLabel?: string;
};

function InfoPopover({ text, ariaLabel }: InfoPopoverProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = {
    iconButton: {
      marginRight: theme.spacing(1),
    },
    infoPopover: {
      padding: theme.spacing(2),
      maxWidth: theme.breakpoints.values.sm,
    },
  };

  return (
    <>
      <IconButton
        aria-label={ariaLabel}
        sx={classes.iconButton}
        onClick={handleClick}
        size="large"
      >
        <InfoOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Typography sx={classes.infoPopover}>{text}</Typography>
      </Popover>
    </>
  );
}

export default InfoPopover;
