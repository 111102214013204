import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  Distribution,
  DistributionAllocationResponse,
} from './DistributionApi.d';
import { ScheduleDistributionListResponse } from './ScheduledDistributionListApi';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const scheduledDistributionUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/scheduledDistribution/{0.distributionId}';

const scheduledDistributionListUrl =
  '/api/org/{0.orgId}/recurringDistribution?startDate={0.startDate}&endDate={0.endDate}';

function scheduledDistributionFactory(distribution) {
  const {
    accountId,
    additionalStateWithholding,
    distributionMethod,
    distributionReason,
    effectiveDate,
    federalWithholdingAmount,
    federalWithholdingPercent,
    recurringDistributionId,
    scheduledDistributionId,
    stateWithholdingAmount,
    stateWithholdingPercent,
    totalAmount,
    withholdingState,
    netAmount,
  } = distribution;

  return {
    totalAmount,
    federalWithholdingPercent,
    withholdingState,
    federalWithholdingAmount,
    stateWithholdingAmount,
    accountId,
    additionalStateWithholding,
    effectiveDate,
    stateWithholdingPercent,
    distributionMethod,
    distributionReason,
    recurringDistributionId,
    scheduledDistributionId,
    netAmount,
  };
}

export async function createScheduledDistribution(
  data: Distribution,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${scheduledDistributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    distributionId: '',
  })}`;

  const dataNorm = removeEmptyStrings(scheduledDistributionFactory(data));

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateScheduledDistribution(
  data: Distribution,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${scheduledDistributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    distributionId: data.scheduledDistributionId,
  })}`;

  const dataNorm = removeEmptyStrings(scheduledDistributionFactory(data));

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteScheduledDistribution(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  distributionId: string,
  token: string,
  user: any
): Promise<DistributionAllocationResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${scheduledDistributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    distributionId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrgScheduledDistributions(
  orgId: string,
  token: string,
  startDate: string,
  endDate: string
): Promise<ScheduleDistributionListResponse> {
  if (!orgId || !token) return null;

  const url = `${urlBase}${format(scheduledDistributionListUrl, {
    orgId,
    startDate,
    endDate,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
