import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import SiraCurrencyField from '../SiraCurrencyField';
import {
  Distribution,
  DistributionTypeEnum,
} from '../../../api/DistributionApi.d';
import SiraDateField, { dateValidation } from '../SiraDateField';
import { useStates } from '../useStates';
import { AccountType } from '../../../api/AccountApi.d';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import DistributionStateField from '../beneficiariesClaims/DistributionStateField';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';

export const DIST_INIT: Distribution = {
  totalAmount: 0,
  grossAmount: 0,
  penaltyAmount: 0,
  federalWithholdingAmount: 0,
  stateWithholdingAmount: 0,
  distributionType: DistributionTypeEnum.SCHEDULED,
};

export const RECON_DIST_SCHEMA = () => {
  const yupObject = {
    totalAmount: yup.number().min(0).required().label('Total Amount'),
    federalWithholdingAmount: yup
      .number()
      .min(0)
      .required()
      .label('Federal Withholding'),
    stateWithholdingAmount: yup
      .number()
      .min(0)
      .required()
      .label('State Withholding'),
    effectiveDate: dateValidation().label('Distribution Date'),
  };
  return yupObject;
};

export interface ReconcileDistributionFormProps {
  initialValues: Distribution;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  stateAbbreviation?: string;
  accountType?: AccountType;
  accountOwner?: AccountOwner;
}

function ReconcileDistributionForm({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  stateAbbreviation,
  accountType,
  accountOwner,
}: ReconcileDistributionFormProps) {
  const { states = [] } = useStates();
  const [stateName, setStateName] = useState('State');
  const { getAppConstant } = useGlobalContext();

  const classes = {
    button: {
      mr: 2,
    },
  };

  useEffect(() => {
    if (stateAbbreviation && states) {
      const foundState = states.find(
        (state) => state.abbreviation === stateAbbreviation
      );
      if (foundState && foundState.name) {
        setStateName(foundState.name);
      }
    }
  }, [stateAbbreviation, states]);

  return (
    <Box
      style={{
        marginTop: '35px',
        paddingLeft: '10px',
        paddingTop: '15px',
        paddingBottom: '10px',
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
        onReset={() => onReset()}
        validationSchema={yup.object(RECON_DIST_SCHEMA())}
      >
        {({ handleSubmit, isSubmitting, setValues, values }) => {
          const [isLoading,setIsLoading] = useState(false);
          // calculate net amount
          useEffect(() => {
            setValues({
              ...values,
              netAmount:
                values.totalAmount -
                values.penaltyAmount -
                values.federalWithholdingAmount -
                values.stateWithholdingAmount,
              grossAmount: values.totalAmount - values.penaltyAmount,
              federalWithholdingPercent:
                values.federalWithholdingAmount === '0' ||
                values.federalWithholdingAmount === 0
                  ? 0
                  : values.federalWithholdingPercent,
            });
          }, [
            values.penaltyAmount,
            values.totalAmount,
            values.federalWithholdingAmount,
            values.stateWithholdingAmount,
          ]);

          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <SiraCurrencyField
                        name="totalAmount"
                        label="Total Amount"
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <SiraCurrencyField
                        name="penaltyAmount"
                        label="Financial Organization Fee Amount"
                      />
                    </Grid>
                    <Grid item xs={3} />
                    {![AccountType.esa, AccountType.hsa].includes(
                      accountType
                    ) && (
                      <>
                        {' '}
                        <Grid item xs={9}>
                          <SiraCurrencyField
                            name="federalWithholdingAmount"
                            label="Federal Withholding Amount"
                          />
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item xs={9}>
                          <SiraCurrencyField
                            name="stateWithholdingAmount"
                            label={`${stateName} Withholding Amount`}
                          />
                        </Grid>
                        <Grid item xs={3} />
                      </>
                    )}
                    <Grid item xs={9}>
                      <SiraDateField
                        name="effectiveDate"
                        label="Distribution Date"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <Typography variant="h6" gutterBottom>
                        Distribution Reason
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {getAppConstant(
                          ConstantsMappingKey.distributionReason,
                          values.distributionReason
                        )}
                      </Typography>
                    </Grid>{' '}
                    <Grid item xs={9}>
                      <SiraCurrencyField
                        name="grossAmount"
                        label="Gross Amount"
                        disabled
                      />
                    </Grid>{' '}
                    <Grid item xs={9}>
                      <SiraCurrencyField
                        name="netAmount"
                        label="Net Amount"
                        disabled
                      />
                    </Grid>
                    {accountOwner ? (
                      <Grid item xs={6}>
                        <DistributionStateField accountOwner={accountOwner} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <>
                <Box pt={3}>
                  {/* Form Submit */}
                  <Button
                    type="submit"
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    sx={classes.button}
                    data-qa="step-submit-button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      setIsLoading(!isSubmitting);
                    }}
                  >
                    {!isSubmitting && submitName ? submitName : 'Continue'}
                  </Button>

                  {/* Form Cancel */}

                  {onCancel && (
                    <Button
                      disabled={isLoading}
                      variant="outlined"
                      sx={classes.button}
                      data-qa="step-cancel-button"
                      onClick={() => {
                        onCancel();
                        setIsLoading(!isSubmitting);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>

                {/* Loading... */}
                {isLoading && (
                  <Box width="1" mt={2}>
                    <LinearProgress color="secondary" />
                  </Box>
                )}
              </>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export default ReconcileDistributionForm;
