import React, { useEffect } from 'react';
import { useTheme, Grid, Box, useMediaQuery } from '@mui/material';
import { useFormikContext } from 'formik';

import SiraSelectField from '../SiraSelectField';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraDateField from '../SiraDateField';
import DistributionSchedule, {
  FREQUENCY_DIVISOR,
} from './DistributionSchedule';
import { frequencyOptions, determineAgeGroup } from '../../../app.constants';
import { Distribution } from '../../../api/DistributionApi.d';
import { DistributionTerm } from './DistributionTermForm';
import {
  RecurringDistribution,
  TermChoiceOption,
} from '../../../api/RecurringDistributionApi.d';
import { DistributionChoices } from './DistributionAmountRecurringSubForm';
import { Account, AccountType } from '../../../api/AccountApi.d';
import {
  AccountOwner,
  AmortizationCalculationResponse,
  AmortRequestParams,
} from '../../../api/AccountOwnerApi.d';
import { getAmortizationCalculation } from '../../../api/AccountOwnerApi';
import { useUser } from '../../../auth/useUser';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { DistributionAmountFormData } from './DistributionAmountForm.d';
import { getDistributionWithholdingAmounts } from '../../../api/DistributionApi';
import { recurringDistributionHelpText } from './distribution.utils';
import { errorMessages } from '../../../utils/errorhandling.utils';

export interface ScheduleFormProps {
  distribution?: Distribution;
  taxAmounts?: Distribution;
  distributionTerm?: DistributionTerm;
  account?: Account;
  saveCalculatedInfo: Function;
  saveDistributionInfo: Function;
  accountOwner: AccountOwner;
}

export interface ScheduleFormData {
  effectiveDate?: string;
  frequency?: string;
}

function ScheduleForm({
  distribution,
  taxAmounts,
  distributionTerm,
  account,
  saveCalculatedInfo,
  saveDistributionInfo,
  accountOwner,
}: ScheduleFormProps) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { isSubmitting, values } = useFormikContext<ScheduleFormData>();
  const recurringDistribution = distribution as RecurringDistribution;
  const { dateOfBirth } = accountOwner as AccountOwner;
  const { currently73Years, turning73ThisYear } =
    determineAgeGroup(dateOfBirth);
  const isRoth = account.accountType === AccountType.rothIra;
  const isInherited =
    account.accountType === AccountType.inheritedTraditional ||
    account.accountType === AccountType.inheritedRoth;

  const classes = {
    root: {
      overflowX: 'auto' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1,
      },
    },
  };

  async function updateWithholdingAmounts(newTotal) {
    if (newTotal) {
      const newCalcObject = { ...taxAmounts, totalAmount: newTotal };
      await getDistributionWithholdingAmounts(
        newCalcObject as unknown as DistributionAmountFormData,
        user.token,
        user
      )
        .then((res) => {
          const calcInfo = res.data;
          if (saveCalculatedInfo) {
            saveCalculatedInfo(calcInfo);
            const newDistributionInfo = {
              ...distribution,
              totalAmount: newTotal,
              stateWithholdingAmount: calcInfo.stateWithholdingAmount,
              federalWitholdingAmount: calcInfo.federalWithholdingAmount,
            };
            saveDistributionInfo(newDistributionInfo, false);
          }
        })
        .catch((err) => {
          const { response: { data = {} } = {} } = err;
          addGlobalMessage(data.message);
        });
    }
  }

  useEffect(() => {
    if (
      values.frequency &&
      recurringDistribution.recurringSelection ===
        DistributionChoices.amortization
    ) {
      const amortizationParams = {
        accountBalance: recurringDistribution.accountBalance,
        frequency: values.frequency,
      } as AmortRequestParams;
      if (distributionTerm.termChoice === TermChoiceOption.jle) {
        amortizationParams.spouseDOB = distributionTerm.spouseBirthDate;
      }

      getAmortizationCalculation(
        user.organizationId,
        user.token,
        account.accountId,
        account.accountOwnerId,
        amortizationParams
      )
        .then((resp: AmortizationCalculationResponse) => {
          let amount = 0;

          if (
            distributionTerm.termChoice === TermChoiceOption.jle &&
            distributionTerm.qualifiedJLE
          ) {
            amount = Number(
              resp.data.jointLifeExpectancyRMDAmortization.grossWithdrawalAmount
            );
          } else if (distributionTerm.termChoice === TermChoiceOption.sle) {
            amount = Number(
              resp.data.singleLifeExpectancyRMDAmortization.grossWithdrawalAmount
            );
          } else {
            amount = Number(
              resp.data.uniformLifetimeRMDAmoritization.grossWithdrawalAmount
            );
          }
          const frequencyMultiplier = FREQUENCY_DIVISOR[values.frequency] || 1;
          updateWithholdingAmounts(amount * frequencyMultiplier);
        })
        .catch((err) => {
  
 addGlobalMessage(errorMessages(err) ||
              'Unexpected problem loading Amortization Calculation'
          );
        });
    }
  }, [values.frequency]);

  function dateText() {
    return currently73Years || turning73ThisYear && !isRoth && !isInherited
      ? recurringDistributionHelpText.startDateAddition
      : recurringDistributionHelpText.startDateBetweenYears;
  }

  return (
    <Grid container wrap="nowrap" sx={classes.root}>
      <Grid item xs={12} sx={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={10}>
            <Box mt={6} mb={1}>
              <SiraSelectField
                name="frequency"
                items={frequencyOptions}
                label="Frequency"
              />
            </Box>
          </Grid>
          {!isExtraSmall && <Grid item xs={2} />}
          <Grid item xs={12} sm={10}>
            <Box mt={2} mb={3}>
              <SiraDateField
                name="effectiveDate"
                label="Start Date"
                afterContent={dateText()}
              />
            </Box>
          </Grid>
          {!isExtraSmall && <Grid item xs={2} />}
        </Grid>
        <StepButtonBar isSubmitting={isSubmitting} />
      </Grid>

      <Grid item>
        <DistributionSchedule
          distribution={distribution}
          taxAmounts={taxAmounts}
          frequency={values.frequency}
          effectiveDate={values.effectiveDate}
        />
      </Grid>
    </Grid>
  );
}

export default ScheduleForm;
