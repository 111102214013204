import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
// import NumberFormat from 'react-number-format';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import NumberFormat from 'react-number-format';
import { useUser } from '../auth/useUser';
import { useGlobalContext } from '../auth/useGlobalContext';
import { TaxForm5498, TaxFormType } from '../api/TaxFormApi.d';
import { getTaxFormInfo5498 } from '../api/TaxFormApi';
import { AccountType } from '../api/AccountApi.d';
import { dateValueFormatter } from '../components/form/beneficiariesClaims/resource.utils';
import { errorMessages } from '../utils/errorhandling.utils';
import { getUsers } from '../api/UserApi';
import { User } from '../api/UserApi.d';
// import { ConstantsMappingKey } from '../api/SharedTextApi.d';
// import { AccountType } from '../api/AccountApi.d';

export interface TaxInfo {
  accountId: string;
  accountOwnerId: string;
  taxId: string;
  formType: TaxFormType;
  accountType?: string;
}

interface TaxFormDetailsProps {
  taxInfo?: TaxInfo;
  clicked?: Function;
}

function TaxForm5498Details(props: TaxFormDetailsProps) {
  let isMounted = true;
  const { taxInfo, clicked = () => {} } = props;
  const { accountId, accountOwnerId, taxId, accountType } = taxInfo;
  const [taxFormProfileInfo, setTaxFormProfileInfo] = useState(
    {} as TaxForm5498
  );
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [userInfo, setUserInfo] = useState('' as string);

  // const coverdellEsa =
  //   taxFormProfileInfo.form1099Type === 'Q' && accountType === AccountType.esa;
  // const hsa =
  //   taxFormProfileInfo.form1099Type === 'SA' && accountType === AccountType.hsa;

  
  const setRepInfo = async (sentToOwnerBy): Promise<void> => {
    await getUsers(user.organizationId, user.token, { includeMultiOrgUsers: true })
      .then((res) => {

        // find the user that matches the sent to owner by id
        const rep = res.data.find(
          (userValue: User) => userValue.auth0UserId === sentToOwnerBy
        );
        setUserInfo(`${rep.firstName} ${rep.lastName}`);
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage('Error fetching organization users');
        }
      });
  };


  async function getTaxForm(): Promise<void> {
    await getTaxFormInfo5498(
      accountId,
      accountOwnerId,
      taxId,
      user.organizationId,
      user.token,
      user
    )
      .then((responseValue) => {
        setTaxFormProfileInfo(responseValue.data);      
        if (responseValue.data.sentToOwnerBy){
          setRepInfo(responseValue.data.sentToOwnerBy);
        }

      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Could not fetch Tax Form Information'
          );
        }
      });
  }

  useEffect(() => {
    if (user.organizationId && user.token) {
      getTaxForm();
    }
    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId, accountId]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Payee Information
          </Typography>
          <Typography variant="body1" color="primary" pt={0.5}>
            {taxFormProfileInfo.legalName}
          </Typography>
          <Typography variant="body1" color="primary">
            {taxFormProfileInfo.mailingAddress}
          </Typography>
          <Typography variant="body1" color="primary">
            {taxFormProfileInfo.city}
            {', '}
            {!taxFormProfileInfo.foreignCountryIndicator &&
              taxFormProfileInfo.state}{' '}
            {taxFormProfileInfo.zipCode}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            {taxFormProfileInfo.formType === 'ESA'
              ? 'Regular Contributions'
              : 'Fair Market Value'}
          </Typography>
          <Typography variant="body1" color="primary">
            <NumberFormat
              value={
                taxFormProfileInfo.formType === 'ESA'
                  ? taxFormProfileInfo.esaContributionAmount
                  : taxFormProfileInfo.fmvAmount
              }
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Tax Year
          </Typography>
          <Typography variant="body1" color="primary">
            {taxFormProfileInfo.taxYear}
          </Typography>{' '}
        </Grid>
        {!['ESA'].includes(taxFormProfileInfo.formType) && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6" pt={1}>
              {taxFormProfileInfo.formType === 'HSA'
                ? `Regular Contributions Made in ${taxFormProfileInfo.taxYear}`
                : 'RMD Amount'}
            </Typography>
            <Typography variant="body1" color="primary">
              <NumberFormat
                value={
                  ['HSA'].includes(taxFormProfileInfo.formType)
                    ? taxFormProfileInfo.hsaContributionAmount
                    : taxFormProfileInfo.rmdAmount
                }
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
              {['HSA'].includes(taxFormProfileInfo.formType) && (
                <Typography variant="body1" color="primary">
                  {dateValueFormatter(taxFormProfileInfo.rmdDate)}
                </Typography>
              )}
            </Typography>
          </Grid>
        )}
        {['HSA', 'ESA'].includes(taxFormProfileInfo.formType) && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6" pt={1}>
              Rollover
            </Typography>
            <Typography variant="body1" color="primary">
              <NumberFormat
                value={
                  taxFormProfileInfo.formType === 'ESA'
                    ? taxFormProfileInfo.esaRolloverAmount
                    : taxFormProfileInfo.hsaRolloverAmount
                }
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
        )}
        {['HSA'].includes(taxFormProfileInfo.formType) && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6" pt={1}>
              Regular Contributions Made in {taxFormProfileInfo.taxYear + 1} for{' '}
              {taxFormProfileInfo.taxYear}
            </Typography>
            <Typography variant="body1" color="primary">
              <NumberFormat
                value={taxFormProfileInfo.hsaPriorYearContributionAmount}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Unique ID
          </Typography>
          <Typography variant="body1" color="primary">
            {taxFormProfileInfo.uniqueId}
          </Typography>
        </Grid>
        {!['ESA', 'HSA'].includes(taxFormProfileInfo.formType) && (
          <>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" pt={1}>
                Regular IRA Contributions
              </Typography>
              <Typography variant="body1" color="primary">
                <NumberFormat
                  value={
                    accountType === AccountType.inheritedRoth ||
                    accountType === AccountType.rothIra
                      ? taxFormProfileInfo.iraRothContributionAmount
                      : taxFormProfileInfo.iraContributionAmount
                  }
                  prefix="$"
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" pt={1}>
                  Rollovers
                </Typography>
                <Typography variant="body1" color="primary">
                  <NumberFormat
                    value={taxFormProfileInfo.iraRolloverAmount}
                    prefix="$"
                    displayType="text"
                    thousandSeparator
                    isNumericString
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" pt={1}>
                Recharacterizations
              </Typography>
              <Typography variant="body1" color="primary">
                <NumberFormat
                  value={taxFormProfileInfo.iraRecharacterizedAmount}
                  prefix="$"
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" pt={1}>
                Roth IRA Conversions
              </Typography>
              <Typography variant="body1" color="primary">
                <NumberFormat
                  value={taxFormProfileInfo.iraRothConversionAmount}
                  prefix="$"
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Typography>

              {/* <Grid container spacing={0.5} mt={3}>
                <Grid item xs={1}>
                  <Box pt={1}>
                    {taxFormProfileInfo.trusteeToTrusteeTransfer ||
                    taxFormProfileInfo.totalDistributionIndicator ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </Box>
                </Grid>{' '}
                <Grid item xs={11}>
                  <Typography variant="body1" color="primary" pt={1}>
                    {coverdellEsa
                      ? 'Trustee-to-trustee transfer'
                      : 'Total Distribution Indicator'}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Box pt={1}>
                    {taxFormProfileInfo.taxableAmountNotDetermined ||
                    taxFormProfileInfo.designatedBeneficiary ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </Box>
                </Grid>{' '}
                <Grid item xs={8}>
                  <Typography variant="body1" color="primary" pt={1}>
                    {coverdellEsa
                      ? 'Recipient is not the designated beneficiary'
                      : 'Taxable Amount Not Determined'}
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom pt={1}>
                SEP Contributions{' '}
              </Typography>{' '}
              <Typography variant="body1" color="primary">
                <NumberFormat
                  value={taxFormProfileInfo.iraSepAmount}
                  prefix="$"
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Typography>
            </Grid>{' '}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom pt={1}>
                SIMPLE Contributions
              </Typography>{' '}
              <Typography variant="body1" color="primary">
                <NumberFormat
                  value={taxFormProfileInfo.iraSimpleAmount}
                  prefix="$"
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  fixedDecimalScale
                  decimalScale={2}
                />{' '}
              </Typography>
            </Grid>{' '}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom pt={1}>
                Postponed/Late Contributions
              </Typography>{' '}
              <Typography variant="body1" color="primary">
                <NumberFormat
                  value={taxFormProfileInfo.postponedContributions}
                  prefix="$"
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  fixedDecimalScale
                  decimalScale={2}
                />{' '}
              </Typography>
              <Typography variant="body1" color="primary">
                {taxFormProfileInfo.postponedCode}
              </Typography>{' '}
              <Typography variant="body1" color="primary">
                {taxFormProfileInfo.postponedYear}
              </Typography>
            </Grid>
          </>
        )}
        {['ESA', 'HSA'].includes(taxFormProfileInfo.formType) && (
          <Grid item xs={12} md={6} />
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Date Filed With IRS
          </Typography>{' '}
          <Typography variant="body1" color="primary">
            {dateValueFormatter(taxFormProfileInfo.dateFiledWithIrs)}
          </Typography>
        </Grid>
        {!['ESA', 'HSA'].includes(taxFormProfileInfo.formType) && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom pt={1}>
              Repayments
            </Typography>{' '}
            <Typography variant="body1" color="primary">
              <NumberFormat
                value={taxFormProfileInfo.repayments}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />{' '}
            </Typography>
          </Grid>
        )}
        {['ESA', 'HSA'].includes(taxFormProfileInfo.formType) && (
          <Grid item xs={12} md={6} />
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Date Sent to Owner
          </Typography>{' '}
          <Typography variant="body1" color="primary">
            {dateValueFormatter(taxFormProfileInfo.dateSentToOwner)}
          </Typography>
        </Grid>
        {taxFormProfileInfo.sentToOwnerBy ? (
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom pt={1}>
              Sent to Owner By
            </Typography>{' '}
            <Typography variant="body1" color="primary">
              {userInfo}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Button
            aria-label="Go back to account tax forms"
            variant="outlined"
            color="primary"
            onClick={() => {
              clicked();
            }}
            startIcon={<ArrowBackIcon />}
          >
            All Tax Forms
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default TaxForm5498Details;
