import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EditIcon from '@mui/icons-material/Edit';

import NumberFormat from 'react-number-format';
import { Account } from '../../api/AccountApi.d';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { User } from '../../api/UserApi.d';
import { beneficiaryRelationshipOptions } from '../../app.constants';
import { beneficiaryPreferredContactOptions } from '../form/beneficiariesClaims/BeneficiariesClaimsContactInfoForm';
import {
  BeneficiariesClaimsInfo,
  BeneficiaryTypes,
} from '../../api/BeneficiaryClaimsApi.d';
import {
  dateValueFormatter,
  returnContactLayout,
} from '../form/beneficiariesClaims/resource.utils';
import { getBeneficiariesClaimsProfile } from '../../api/BeneficiariesClaimsApi';
import { getUser, getUsers } from '../../api/UserApi';
import BeneficiariesClaimsRepForm, {
  BENEFICIARIES_REP_INIT,
} from '../form/beneficiariesClaims/BeneficiariesClaimsRepForm';
import { errorMessages } from '../../utils/errorhandling.utils';

interface AccountOwnerAccountBeneficiaryClaimProps {
  account: Account;
}
function AccountOwnerAccountBeneficiaryClaim(
  props: AccountOwnerAccountBeneficiaryClaimProps
) {
  let isMounted = true;
  const [beneficiaryClaimProfile, setBeneficiariesClaimsProfile] = useState(
    {} as BeneficiariesClaimsInfo
  );

  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { account } = props;
  const { accountId = '', accountOwnerId = '' } = account;
  const [repInfo, setRepInfo] = useState({} as User);
  const [response, setResponse] = useState<Array<User>>([]);
  const [editRepInfo, setEditRepInfo] = useState(false as boolean);

  const grabRepInfo = async (): Promise<void> => {
    await getUsers(user.organizationId, user.token)
      .then((res) => {
        setResponse(res.data);
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage('Error fetching organization users');
        }
      });
  };

  async function getBenefitClaimsRepInfo(userId): Promise<void> {
    await getUser(user.organizationId, userId, user.token)
      .then((responseValue) => {
        setRepInfo(responseValue.data);
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            'Could not fetch the preselected beneficiary claim'
        );
      });
  }

  async function getBenefitClaimsInfo(): Promise<void> {
    await getBeneficiariesClaimsProfile(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((responseValue) => {
        setBeneficiariesClaimsProfile(responseValue.data);
        getBenefitClaimsRepInfo(responseValue.data.finOrgContactUserId);
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            'Could not fetch the preselected beneficiary claim'
        );
      });
  }

  const returnRelationship = (beneficiariesClaims: BeneficiariesClaimsInfo) => {
    let label;
    beneficiaryRelationshipOptions.forEach((relationships) => {
      if (
        relationships.value === beneficiariesClaims.relationshipToAccountOwner
      ) {
        label = relationships.label;
      }
    });
    return label;
  };

  const returnPreferredContact = (
    beneficiariesClaims: BeneficiariesClaimsInfo
  ) => {
    let label;
    beneficiaryPreferredContactOptions.forEach((preferredContactMethod) => {
      if (
        preferredContactMethod.value ===
        beneficiariesClaims.preferredContactMethod
      ) {
        label = preferredContactMethod.label;
      }
    });
    return label;
  };

  useEffect(() => {
    if (user.token) {
      getBenefitClaimsInfo();
      grabRepInfo();
    }
    return () => {
      isMounted = false;
    };
  }, [account, user.token]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="body1" color="secondary" gutterBottom>
            Account Information
          </Typography>
          <Typography variant="body1" color="primary">
            Claim Amount:{' '}
            <NumberFormat
              value={beneficiaryClaimProfile.dateOfDeathAccountBalance}
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
          <Typography variant="body1" color="primary" pt={0.5}>
            Submitted Date:{' '}
            {dateValueFormatter(beneficiaryClaimProfile.submittedDate)}
          </Typography>
          <Typography variant="body1" color="primary" pt={0.5}>
            Submitted Status: {beneficiaryClaimProfile.beneficiaryClaimStatus}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" color="secondary" gutterBottom pt={0.5}>
            Preferred Contact
          </Typography>
          <Typography variant="body1" color="primary">
            Name:{' '}
            {`${beneficiaryClaimProfile.firstName} ${beneficiaryClaimProfile.lastName}`}
          </Typography>
          <Typography variant="body1" color="primary" pt={0.5}>
            Relationship: {returnRelationship(beneficiaryClaimProfile)}
          </Typography>
          {beneficiaryClaimProfile.relationshipToAccountOwner ===
            BeneficiaryTypes.OTHER && (
            <Typography variant="body1" color="primary" pt={0.5}>
              Relationship Description:{' '}
              {beneficiaryClaimProfile.otherRelationshipToAccountOwner}
            </Typography>
          )}
          <Typography variant="body1" color="primary" pt={0.5}>
            Preferred Contact Method:{' '}
            {returnPreferredContact(beneficiaryClaimProfile)}
          </Typography>
          {returnContactLayout(beneficiaryClaimProfile)}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {repInfo ? (
          <Grid item xs={6}>
            {/* edit icon button to allow user to edit rep */}
            <Typography variant="body1" color="secondary" gutterBottom pt={0.5}>
              Financial Organization Representative Information{' '}
              {editRepInfo ? (
                <IconButton
                  data-testid="Edit Rep"
                  size="small"
                  aria-label="Edit Rep"
                  onClick={() => {
                    setEditRepInfo(!editRepInfo);
                  }}
                  sx={{
                    color: 'primary',
                    whiteSpace: 'nowrap',
                    marginLeft: 1,
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : null}
            </Typography>
            <Typography variant="body1" color="primary" pt={0.5}>
              Name: {`${repInfo.firstName} ${repInfo.lastName}`}
            </Typography>
            <Typography variant="body1" color="primary" pt={0.5}>
              Email: {repInfo.emailAddress}
            </Typography>
            {repInfo.phoneNumber && (
              <>
                <Typography variant="body1" color="primary" pt={0.5}>
                  Phone Number: {repInfo.phoneNumber}
                </Typography>
              </>
            )}
          </Grid>
        ) : null}

        {editRepInfo ? (
          <Grid item xs={12} md={8}>
            <BeneficiariesClaimsRepForm
              initialValues={{
                ...BENEFICIARIES_REP_INIT,
                ...beneficiaryClaimProfile,
              }}
              response={response}
              submitName="Save Rep"
              onSubmit={(values) => {
                getBenefitClaimsRepInfo(values);
              }}
            />
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <Typography variant="body1" color="secondary" gutterBottom pt={0.5}>
            Comments
          </Typography>
          <Typography variant="body1" color="primary">
            {beneficiaryClaimProfile.comments}
          </Typography>
        </Grid>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              color="secondary"
              gutterBottom
              pt={0.5}
              pl={1}
            >
              Designated Beneficiary
            </Typography>
          </Grid>{' '}
          <Grid item>
            <Box pl={1}>
              {beneficiaryClaimProfile.designateBeneficiary ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </Box>
          </Grid>{' '}
          <Grid item xs={4}>
            <Typography variant="body1" color="primary">
              No beneficiary designation on file for this account.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AccountOwnerAccountBeneficiaryClaim;
