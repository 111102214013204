import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import { filingStatusOptions } from '../../../app.constants';
import { DistributionAmountFormData } from './DistributionAmountForm.d';
import { FederalTaxElectionType } from '../../../api/DistributionApi.d';
import SiraSelectField from '../SiraSelectField';
import SiraCurrencyField from '../SiraCurrencyField';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import SiraSwitchField from '../SiraSwitchField';
import { distributionAmountHelpText } from './resource.txt';
import { Account } from '../../../api/AccountApi.d';

interface FederalTaxCalculationOptionProps {
  account: Account;
}

const federalTaxCalculationOptions: Array<RadioGroupOption> = [
  {
    value: FederalTaxElectionType.current,
    label: 'Withhold based on owner’s current election on file',
  },
  {
    value: FederalTaxElectionType.suggested,
    label: 'Withhold a different rate',
  },
  {
    value: FederalTaxElectionType.none,
    label: 'Do not withhold',
  },
];

const FederalTaxCalculationOption = (
  props: FederalTaxCalculationOptionProps
) => {
  const { values, setValues } = useFormikContext<DistributionAmountFormData>();
  const { account = {} } = props;
  const { federalWithholdingPercent: accountFederalWithholding = '' } = account;
  const {
    federalTaxElectionType,
    useSuggestedWithholding = false,
    filingStatus,
    federalWithholdingPercent,
  } = values;

  // Set field reasonable defaults based on which election option is selected
  function handleElectionTypeChange() {
    switch (federalTaxElectionType) {
      case FederalTaxElectionType.current:
        setValues({
          ...values,
          doNotWithholdFederal: false,
          federalWithholdingPercent: accountFederalWithholding || '10',
          useSuggestedWithholding: false,
        });
        break;
      case FederalTaxElectionType.suggested:
        setValues({
          ...values,
          doNotWithholdFederal: false,
          federalWithholdingPercent: federalWithholdingPercent || '',
          useSuggestedWithholding: Boolean(
            useSuggestedWithholding || filingStatus
          ),
        });
        break;
      case FederalTaxElectionType.none:
        setValues({
          ...values,
          doNotWithholdFederal: true,
          federalWithholdingPercent: '0',
          useSuggestedWithholding: false,
        });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    handleElectionTypeChange();
  }, [values.federalTaxElectionType]);

  return (
    <Grid item xs={12}>
      <Box mb={3}>
        <Field
          component={SiraRadioField}
          name="federalTaxElectionType"
          options={federalTaxCalculationOptions}
        />
      </Box>

      {/* Get Suggested Withholding */}
      {values.federalTaxElectionType === FederalTaxElectionType.suggested && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="div">
              <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                wrap="nowrap"
              >
                <Grid item>
                  <SiraSwitchField
                    name="useSuggestedWithholding"
                    checked={useSuggestedWithholding}
                    value={
                      distributionAmountHelpText
                        .determineWithholdingBasedonFillingStatusAndIncome.value
                    }
                  />
                </Grid>
                <Grid item>
                  Determine suggested withholding based on Marginal Rate Tables
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          {useSuggestedWithholding && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  What filing status will you use on your tax return?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SiraSelectField
                  name="filingStatus"
                  items={filingStatusOptions}
                  label="Filing Status"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  What is your total income on your tax return?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SiraCurrencyField name="totalIncome" label="Total Income" />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="body1">
              {useSuggestedWithholding
                ? 'Confirm or update the percentage you will withhold for federal taxes:'
                : 'Enter the percentage you will withhold for federal taxes:'}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FederalTaxCalculationOption;
