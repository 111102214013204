import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  LinearProgress,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useUser } from '../../../auth/useUser';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import BatchInfoList from './BatchInfoList';
import { BatchInfo, BatchStatus, BatchType } from '../../../api/BatchApi.d';
import { BatchOptionConfig } from '../DataImport.d';

interface BulkDataImportsProps {
  maxItems?: number;
  batchOptionsConfig: BatchOptionConfig;
}

interface BatchDataPanelProps {
  selectedBatchType: BatchType;
  batchConfig: BatchOptionConfig;
  isLoading: boolean;
  batchData: Array<BatchInfo>;
  onComplete?: Function;
}

interface BatchStatusFilterProps {
  onItemClick: Function;
  statusFilter: Array<BatchStatus>;
}

function BatchStatusFilter(props: BatchStatusFilterProps) {
  const { onItemClick = () => {}, statusFilter } = props;

  return (
    <Box mt={3} mb={1}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body2">Filter by:</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={
              [BatchStatus.processing, BatchStatus.reProcessing].some(
                (status) => statusFilter.includes(status)
              )
                ? 'contained'
                : 'outlined'
            }
            color="warning"
            startIcon={<PendingIcon />}
            size="small"
            onClick={() => {
              onItemClick([BatchStatus.processing, BatchStatus.reProcessing]);
            }}
          >
            Processing
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(BatchStatus.errors)
                ? 'contained'
                : 'outlined'
            }
            color="error"
            startIcon={<ErrorIcon />}
            size="small"
            onClick={() => {
              onItemClick([BatchStatus.errors]);
            }}
          >
            Error
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              [BatchStatus.completed, BatchStatus.userCompleted].some(
                (status) => statusFilter.includes(status)
              )
                ? 'contained'
                : 'outlined'
            }
            color="success"
            startIcon={<CheckCircleIcon />}
            size="small"
            onClick={() => {
              onItemClick([BatchStatus.completed, BatchStatus.userCompleted]);
            }}
          >
            Completed
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function BatchDataPanel(props: BatchDataPanelProps) {
  const {
    batchConfig,
    selectedBatchType,
    batchData,
    isLoading,
    onComplete = () => {},
  } = props;
  const { type } = batchConfig;

  return (
    <div
      key={`batch_info_panel_${type}`}
      role="tabpanel"
      hidden={selectedBatchType !== type}
      id={`simple-tabpanel-${type}`}
      aria-labelledby={`simple-tab-${type}`}
    >
      {selectedBatchType === type && (
        <Box pt={2}>
          <BatchInfoList
            onComplete={onComplete}
            batchData={batchData}
            isLoading={isLoading}
          />
        </Box>
      )}
    </div>
  );
}

function BulkDataImports(props: BulkDataImportsProps) {
  let isMounted = true;
  const { maxItems, batchOptionsConfig } = props;
  const { user } = useUser();
  const navigate = useNavigate();
  const { organization, addGlobalMessage } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isRefreshing, setIsRefreshing] = useState(false as boolean);
  const [statusFilter, setStatusFilter] = useState([] as Array<BatchStatus>);
  const [selectedBatchData, setSelectedBatchData] = useState(
    [] as Array<BatchInfo>
  );
  const { type: selectedBatchType } = batchOptionsConfig;

  // Filter the batch info by status
  const filteredBatchData = statusFilter.length
    ? selectedBatchData.filter(({ batchStatus }) =>
        statusFilter.includes(batchStatus)
      )
    : selectedBatchData;

  // GET and set the data for whichever batch info type is selected using the config method
  const fetchAndSetSelectedBatchData = async (): Promise<void> => {
    const { apiMethod } = batchOptionsConfig;

    await apiMethod()
      .then((res) => {
        if (isMounted) {
          setSelectedBatchData(
            maxItems ? res.data.slice(0, maxItems) : res.data
          );
        }
      })
      .catch(() => {
        addGlobalMessage(
          `Could not get batch import information for type: ${selectedBatchType}`
        );
      });

    if (isMounted) {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  };

  const goToLinkPath = () => {
    navigate(batchOptionsConfig.linkPath);
  };

  const handleBatchStatusClick = (batchStatuses: Array<BatchStatus>) => {
    if (batchStatuses.some((status) => statusFilter.includes(status))) {
      setStatusFilter([] as Array<BatchStatus>);
    } else {
      setStatusFilter(batchStatuses);
    }
  };

  useEffect(() => {
    if (user.organizationId && user.token && batchOptionsConfig) {
      setIsLoading(true);
      fetchAndSetSelectedBatchData();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token, batchOptionsConfig]);

  return (
    <Grid item xs={12}>
      <Paper elevation={3}>
        <Box p={2}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5">Data File Uploads</Typography>
                  <Typography variant="body2" gutterBottom>
                    {organization.legalName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {/* Back */}
                    <Grid item xs={false}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          navigate(-1);
                        }}
                        startIcon={<ArrowBackIcon />}
                      >
                        Back
                      </Button>
                    </Grid>

                    {/* Upload Button */}
                    <Grid item xs={false}>
                      <Button
                        onClick={goToLinkPath}
                        variant="contained"
                        size="small"
                        startIcon={<FileUploadIcon />}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {batchOptionsConfig.buttonText}
                      </Button>
                    </Grid>

                    {/* Refresh */}
                    <Grid item xs={false}>
                      <IconButton
                        onClick={() => {
                          setIsRefreshing(true);
                          fetchAndSetSelectedBatchData();
                        }}
                        disabled={isLoading}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Filter by batch status */}
              <BatchStatusFilter
                onItemClick={handleBatchStatusClick}
                statusFilter={statusFilter}
              />

              {/* Load the info panels */}
              {isLoading ? (
                <Box mt={4}>
                  <LinearProgress />
                </Box>
              ) : (
                <BatchDataPanel
                  key={`batch_info_panel_${batchOptionsConfig.type}`}
                  isLoading={isRefreshing}
                  batchData={filteredBatchData}
                  selectedBatchType={selectedBatchType}
                  batchConfig={batchOptionsConfig}
                  onComplete={() => {
                    setIsRefreshing(true);
                    fetchAndSetSelectedBatchData();
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
}

export default BulkDataImports;
