import { useEffect } from 'react';
import { useFormikContext } from 'formik';

interface ScrollToErrorProps {
  behavior?: ScrollBehavior;
  block?: ScrollLogicalPosition;
  inline?: ScrollLogicalPosition;
}

// Form helper to scroll the screen to the first validation error
function ScrollToError(props: ScrollToErrorProps) {
  // Warning: Sometimes block and inline together can lead to issues
  const { behavior = 'smooth', block, inline } = props;
  const { errors, submitCount } = useFormikContext();
  const [groupName] = Object.keys(errors); // Get first group in field array or use this as the error field
  const fieldName =
    // If a string comes back, assume there are multiple levels to the error fields
    errors[groupName] instanceof String
      ? Object.keys(errors[groupName] || {})[0]
      : null;

  useEffect(() => {
    const selector = fieldName
      ? `input[name="${groupName}.${fieldName}"]` // Target field arrays per formik standard name syntax
      : `input[name="${groupName}"]`; // Otherwise target direct input name
    const errorFieldEl = document.querySelector(selector);

    if (errorFieldEl) {
      errorFieldEl.scrollIntoView({
        behavior,
        ...(block && { block }),
        ...(inline && { inline }),
      });
    }
  }, [submitCount, fieldName, groupName]);

  return null;
}

export default ScrollToError;
