import { GridValueFormatterParams } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';

export const dateValueFormatterPro = (value) => {
  return value && format(parseISO(String(value)), 'MM/dd/yyyy');
};

export const dateValueFormatter = (params: GridValueFormatterParams) => {
  const { value = '' } = params;

  return value && format(parseISO(String(value)), 'MM/dd/yyyy');
};

export const currencyValueFormatter = (
  params: GridValueFormatterParams
): string => {
  const numberValue = Number(params.value);
  const valueFormatted = Number.isNaN(numberValue)
    ? ''
    : `$${numberValue.toLocaleString('en-us', {
        minimumFractionDigits: 2,
      })}`;

  return valueFormatted;
};


export const currencyValueFormatterPro = (
  value: any
): string => {
  const numberValue = Number(value);
  const valueFormatted = Number.isNaN(numberValue)
    ? ''
    : `$${numberValue.toLocaleString('en-us', {
        minimumFractionDigits: 2,
      })}`;

  return valueFormatted;
};

export const percentFormatter = (params) => {
  const valueFormatted = Number(params.value).toLocaleString();

  return `${valueFormatted || 0}%`;
};

export const percentFormatterPro = (value) => {
  const valueFormatted = Number(value).toLocaleString();

  return `${valueFormatted || 0}%`;
};