import React, { MouseEvent, useEffect, useState } from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Drawer,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { formatDistance, parseISO } from 'date-fns';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MoreHoriz } from '@mui/icons-material';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import {
  createFileBlobFromBase64,
  downloadAsFile,
} from '../../utils/App.utils';
import { getDoc } from '../../api/NotificationApi';
import { errorMessages } from '../../utils/errorhandling.utils';

export enum NotificationType {
  document = 'DOCUMENT',
  warning = 'WARNING',
  info = 'INFO',
  success = 'SUCCESS',
  error = 'ERROR',
  completed = 'COMPLETED',
}

export const notificationTypeDescriptions = {
  [NotificationType.document]: 'Document',
  [NotificationType.warning]: 'Warning',
  [NotificationType.info]: 'Info',
};

export enum NotificationStatus {
  new = 'NEW',
  read = 'RED',
}

interface NotificationObject {
  content: string;
  documentType?: string;
  notificationHeader: string;
  notificationType: NotificationType;
  notificationId: string;
  url?: string;
  timestamp?: string;
  isRead?: boolean;
  fileName?: string;
  severity?: NotificationType;
}

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

export type AlertColor = 'success' | 'info' | 'warning' | 'error';

export default function NotificationBadge() {
  let isMounted = true;
  const [dataVal, setData] = useState([] as Array<NotificationObject>);
  const [newNotications, setNewNotifications] = useState([]);
  const theme = useTheme();

  const [selectedNotification, setSelectedNotification] = useState(
    {} as NotificationObject
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [wsObject, setWsObject] = useState({} as WebSocket);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorIndividualEl, setAnchorIndividualEl] =
    useState<null | HTMLElement>(null);
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const open = Boolean(anchorEl);
  const openIndividualMenu = Boolean(anchorIndividualEl);
  const notificationUrl = process.env.REACT_APP_NOTIFICATION_URL;

  // establish initial connection
  let urlWebSocket;

  const styles = {
    cardButton: {
      display: 'block',
      textAlign: 'initial',
      width: '100%',
    },
    drawerPaper: {
      width: '100%',
      overflowX: 'auto',
      minheight: '100%',
      marginTop: '15px',
      [theme.breakpoints.up('md')]: {
        overflowX: 'auto',
        width: '100%',
        position: 'relative',
        minheight: '100%',
        marginTop: '15px',
      },
    },
  };

  const notificationTypeColors = {
    [NotificationType.warning]: theme.palette.warning.main,
    [NotificationType.info]: theme.palette.info.main,
    [NotificationType.success]: theme.palette.secondary.main,
    [NotificationType.error]: theme.palette.error.main,
    [NotificationType.completed]: theme.palette.secondary.main,
  };

  const sortResponse = (response) => {
    const sorted = response.sort((a, b) => {
      const fa = new Date(a.timestamp);
      const fb = new Date(b.timestamp);

      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    });

    return sorted;
  };
  const reconnectWebsocket = (subscription) => {
    // this will check if there is a connection still if it is closed connect again
    urlWebSocket = `${notificationUrl}?userId=${user.token}`;

    let ws = new WebSocket(urlWebSocket);

    ws.onopen = () => {
      setWsObject(ws);
      ws.send(JSON.stringify(subscription));
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (!response.message) {
        setNewNotifications(response);
      }
    };

    // if connection closes establish a new connection
    ws.onclose = () => {
      ws = new WebSocket(urlWebSocket);
      setNewNotifications([]);
      setData([]);
    };

    ws.onerror = () => {
      ws = new WebSocket(urlWebSocket);
      setNewNotifications([]);
      setData([]);
    };

    return () => {
      ws.close();
    };
  };

  useEffect(() => {
    let subscription;
    if (user.token) {
      subscription = { action: 'query', userId: user.token };
      reconnectWebsocket(subscription);
    }
    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  useEffect(() => {
    setData((dataVals) => dataVals.concat(newNotications));
    return () => {
      isMounted = false;
    };
  }, [newNotications]);

  const handleClick = () => {
    setOpenDrawer(true);
  };

  const handleNotificationActionClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleIndividualNotificationActionClick = (
    event: MouseEvent<HTMLElement>,
    dataVals: NotificationObject
  ) => {
    event.stopPropagation();
    setAnchorIndividualEl(event.currentTarget);
    setSelectedNotification(dataVals);
  };

  const actionClick = (actionValue) => {
    urlWebSocket = `${notificationUrl}?userId=${user.token}`;
    if (wsObject.readyState === WebSocket.CLOSED) {
      const ws = new WebSocket(urlWebSocket);
      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            action: actionValue,
            notificationId: selectedNotification.notificationId,
            userId: user.token,
          })
        );
      };
    } else {
      wsObject.send(
        JSON.stringify({
          action: actionValue,
          notificationId: selectedNotification.notificationId,
          userId: user.token,
        })
      );
    }

    wsObject.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (!response.message) {
        setData(sortResponse(response));
      }
    };

    setAnchorIndividualEl(null);
  };

  const handleIndividualActionClick = (event, actionValue) => {
    event.stopPropagation();
    actionClick(actionValue);
  };

  const handleAllActionClick = (actionValue) => {
    if (wsObject.readyState === WebSocket.CLOSED) {
      wsObject.onopen = () => {
        wsObject.send(
          JSON.stringify({
            action: actionValue,
            userId: user.token,
          })
        );
      };
    } else {
      wsObject.send(
        JSON.stringify({
          action: actionValue,
          userId: user.token,
        })
      );
    }

    wsObject.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (!response.message) {
        setData(sortResponse(response));
      }
    };

    setAnchorEl(null);
    setOpenDrawer(false);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setAnchorEl(null);
    setAnchorIndividualEl(null);
  };

  const handleIndividualClose = () => {
    setAnchorIndividualEl(null);
  };

  async function downloadFile(
    fileName: string,
    documentType: string
  ): Promise<void> {
    const nameValue = fileName.replace(/^.*[\\/]/, '');
    await getDoc(documentType, nameValue, user.organizationId, user.token)
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const blob =
          createFileBlobFromBase64(base64, 'application/pdf') || data;
        if (isMounted) {
          downloadAsFile(blob, nameValue);
        }
      })
      .catch((err) => {

        if (isMounted) {
          addGlobalMessage(errorMessages(err) ||'Could not fetch this document'
          );
        }
      });
  }
  
  const sendReadAction = (dataValues) => {
    if (!dataValues.isRead) {
      wsObject.send(
        JSON.stringify({
          action: 'read',
          notificationId: dataValues.notificationId,
          userId: user.token,
        })
      );

      wsObject.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (!response.message) {
          setData(sortResponse(response));
        }
      };
    }
  };

  const performNotificationAction = (dataValues: NotificationObject) => {
    // download file if the notificationType is Document
    if (dataValues.severity !== NotificationType.error) {
      if (dataValues.notificationType === NotificationType.document) {
        const downloadAction = {
          ACCOUNT_OWNER_ACCOUNT: () => {
            downloadFile(dataValues.fileName, dataValues.documentType);
          },
          USER: () => {
            downloadFile(dataValues.fileName, dataValues.documentType);
          },
          DISTRIBUTION: () => {
            downloadFile(dataValues.fileName, dataValues.documentType);
          },
          CONTRIBUTION: () => {
            downloadFile(dataValues.fileName, dataValues.documentType);
          },
          ACCOUNT_OWNER_AUDIT: () => {
            downloadFile(dataValues.fileName, dataValues.documentType);
          }
        }[dataValues.documentType];

        downloadAction();
      }
    }
    // when a user clicks on a notification mark it as read before you do check to see if the connection is closed
    if (wsObject.readyState === WebSocket.CLOSED) {
      wsObject.onopen = () => {
        sendReadAction(dataValues);
      };
    } else {
      sendReadAction(dataValues);
    }
  };

  const returnIcon = (type: NotificationType) => {
    switch (type) {
      case NotificationType.document:
        return <CloudDownloadOutlinedIcon />;
      case NotificationType.warning:
        return <WarningAmberOutlinedIcon />;
      case NotificationType.info:
        return <InfoOutlinedIcon />;
      default:
        return <InfoOutlinedIcon />;
    }
  };

  const setNotificatCount = (dataValue) => {
    let unreadCount = [];
    if (dataValue && dataValue.length > 0) {
      unreadCount = dataVal.filter((x) => x.isRead === false);
      return unreadCount.length;
    }
    return unreadCount.length;
  };

  const toggleDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <IconButton
        sx={{ color: 'white' }}
        aria-label="notification"
        onClick={handleClick}
      >
        <Badge color="error" badgeContent={setNotificatCount(dataVal)}>
          <NotificationsIcon fontSize="medium" />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={openDrawer}
        ModalProps={{ onBackdropClick: toggleDrawer }}
      >
        <Card sx={{ borderRadius: 0, paddingBottom: 3 }}>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                onClick={handleNotificationActionClick}
              >
                <MoreVertIcon />
              </IconButton>
            }
            titleTypographyProps={{ variant: 'body1' }}
            title="Notifications"
            sx={{ paddingBottom: '45px' }}
          />
        </Card>
        <Box sx={styles.drawerPaper}>
          {dataVal &&
            sortResponse(dataVal).map((dataValues) => {
              return (
                <Card
                  sx={{
                    width: 345,
                    maxWidth: 345,
                    margin: 1,
                  }}
                  key={dataValues.notificationId}
                >
                  <ButtonBase
                    sx={styles.cardButton}
                    onClick={() => {
                      performNotificationAction(dataValues);
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          aria-label="notification type"
                          sx={{
                            bgcolor:
                              notificationTypeColors[dataValues.severity],
                          }}
                        >
                          {returnIcon(dataValues.notificationType)}
                        </Avatar>
                      }
                      action={
                        <Grid container wrap="nowrap">
                          {!dataValues.isRead && (
                            <Grid item pt={2} pr={2}>
                              <CircleIcon color="info" fontSize="small" />
                            </Grid>
                          )}
                          <Grid item>
                            <Typography
                              pt={2}
                              variant="body2"
                              style={{
                                fontWeight: !dataValues.isRead
                                  ? 'bold'
                                  : 'normal',
                              }}
                            >
                              {dataValues.timestamp &&
                                formatDistance(
                                  parseISO(dataValues.timestamp),
                                  new Date(),
                                  {
                                    addSuffix: true,
                                  }
                                )}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      title={
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: !dataValues.isRead ? 'bold' : 'normal',
                          }}
                        >
                          {
                            notificationTypeDescriptions[
                              dataValues.notificationType
                            ]
                          }
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Grid
                        container
                        spacing={1}
                        wrap="nowrap"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {dataValues.content}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            aria-label={`menu-item-${dataValues.notificationHeader}`}
                            id="individual-menu-button"
                            aria-controls={
                              openIndividualMenu ? 'individual-menu' : undefined
                            }
                            aria-expanded={
                              openIndividualMenu ? 'true' : undefined
                            }
                            aria-haspopup="true"
                            onClick={(event) => {
                              handleIndividualNotificationActionClick(
                                event,
                                dataValues
                              );
                            }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </ButtonBase>
                </Card>
              );
            })}
        </Box>
      </Drawer>
      <Menu
        id="basic-all-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          key={1}
          onClick={() => {
            handleAllActionClick('readAll');
          }}
        >
          Mark all as read
        </MenuItem>
        <MenuItem
          key={2}
          onClick={() => {
            handleAllActionClick('clear');
          }}
        >
          Clear all
        </MenuItem>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorIndividualEl}
        open={openIndividualMenu}
        onClose={handleIndividualClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          key={1}
          onClick={(event) => {
            const action = selectedNotification.isRead ? 'unread' : 'read';
            handleIndividualActionClick(event, action);
          }}
        >
          Mark as {selectedNotification.isRead ? 'unread' : 'read'}
        </MenuItem>
        <MenuItem
          key={2}
          onClick={(event) => {
            handleIndividualActionClick(event, 'remove');
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}
