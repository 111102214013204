import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Box, Grid, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from 'react-router-dom';
import TransactionPendingIcon from '../../icons/TransactionPending';
import TransactionSignatureIcon from '../../icons/TransactionSignature';
import TransactionReview from '../../icons/TransactionReview';
import TransactionAwaitingIcon from '../../icons/TransactionAwaiting';

import { accountTransactionTypeNames } from '../../app.constants';
import {
  linkToOwnerTransaction,
  linkToTransaction,
} from '../../utils/transaction.utils';
import { Distribution } from '../../api/DistributionApi.d';
import { AccountContribution } from '../../api/ContributionApi.d';
import { RecurringDistribution } from '../../api/RecurringDistributionApi.d';
import { Beneficiaries } from '../../api/BeneficiariesApi.d';
import {
  Account,
  TransactionStatus,
  TransactionType,
} from '../../api/AccountApi.d';
import {
  TransferRequestStatus,
  TransferRequest,
} from '../../api/TransferApi.d';
import TransferWorkflowForm from '../form/transfer/TransferWorkflowForm';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import {
  getAccountContributions,
  getContributionDocument,
} from '../../api/ContributionApi';
import {
  getAccountDistributions,
  getDistributionDocument,
} from '../../api/DistributionApi';
import {
  getRecurringDistributionDocument,
  getRecurringDistributions,
} from '../../api/RecurringDistributionApi';
import {
  getBeneficiaries,
  getBeneficiariesDocument,
} from '../../api/BeneficiariesApi';
import {
  getTransferRequestDocument,
  getTransferRequestWithStatus,
} from '../../api/TransferApi';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { deleteApiCall, RowDefinition } from '../dashboard/dashboard.utils';
import { SiraDeleteModal } from '../SiraDeleteModal';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { dateValueFormatter } from '../../utils/DataGrid.utils';
import { UserRole } from '../../api/UserApi.d';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import { getAccountDocument } from '../../api/AccountApi';
import { errorMessages } from '../../utils/errorhandling.utils';

interface AccountOwnerAccountWipTransactionsProps {
  account: Account;
  getTransactions?: Function;
}

export const TAX_YEAR_FILTER_SCHEMA = {
  taxYearFilters: yup.array(yup.number()).label('Filters'),
};

function AccountOwnerAccountWipTransactions(
  props: AccountOwnerAccountWipTransactionsProps
) {
  let isMounted = true;
  const { account = {}, getTransactions = () => {} } = props;
  const navigate = useNavigate();
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const [transferToFinalize, setTransferToFinalize] = useState(null as any);
  const { accountId = '', accountOwnerId = '' } = account;
  const [distributions, setDistributions] = useState([] as Array<Distribution>);
  const [recurringDistributions, setRecurringDistributions] = useState(
    [] as Array<RecurringDistribution>
  );
  const [contributions, setContributions] = useState(
    [] as Array<AccountContribution>
  );
  const [beneficiaries, setBeneficiaries] = useState(
    [] as Array<Beneficiaries>
  );
  const [transferRequests, setTransferRequests] = useState(
    [] as Array<TransferRequest>
  );
  const [allowDiscard, setIsAllowDiscard] = useState(
    user.roles.includes(UserRole.orgTransactionsAdmin) as boolean
  );

  const [modalData, setModalData] = useState({ body: '', title: '' });
  const [modalOpen, setmodalOpen] = useState(false);
  const [rowData, setrowData] = useState({} as RowDefinition);

  const classes = {
    transactionLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  function getTransactionID(transaction: any, transactionType: string) {
    switch (transactionType) {
      case TransactionType.contribution:
        return 'contributionId';
      case TransactionType.distribution:
        return 'distributionId';
      case TransactionType.recurringDistribution:
        return 'recurringDistributionId';
      case TransactionType.beneficiaries:
        return 'version';
      case TransactionType.transfer:
        return 'transferRequestId';
      default:
        return '';
    }
  }

  function getTransactionsPromiseFactory(
    getMethod: Function,
    setMethod: Function,
    transactionType: TransactionType,
    args: Array<any> = [],
    statusToGet: any = ''
  ): Promise<any> {
    return getMethod(...args, statusToGet)
      .then((res) => {
        if (isMounted) {
          setMethod(
            res.data.map((t) => {
              const statusKey = Object.keys(t).find((key) =>
                key.includes('Status')
              );
              const id = getTransactionID(t, transactionType);
              return {
                ...t,
                transactionType,
                transactionStatus: t[statusKey],
                transactionId: t[id],
              };
            })
          );
        }
      })
      .catch((err) => {
        if (isMounted) {
  
          setMethod([]);
          addGlobalMessage(errorMessages(err) ||
              `Error fetching account ${accountTransactionTypeNames[
                transactionType
              ].toLowerCase()}`
          );
        }
      });
  }

  const wipTransactionStatuses = !user.roles.includes(UserRole.accountOwner)
    ? [
        TransactionStatus.pending,
        TransactionStatus.signature,
        TransactionStatus.review,
        TransactionStatus.submitOwner,
      ]
    : [TransactionStatus.pending, TransactionStatus.signature,];

  // Group all transaction requests into one promise and hide loader when they all complete
  const getAllAccountTransactions = async () => {
    await Promise.all(
      !user.roles.includes(UserRole.accountOwner)
        ? [
            getTransactionsPromiseFactory(
              getAccountContributions,
              setContributions,
              TransactionType.contribution,
              [
                accountId,
                accountOwnerId,
                user.organizationId,
                user.token,
                user,
              ],
              wipTransactionStatuses
            ),
            getTransactionsPromiseFactory(
              getAccountDistributions,
              setDistributions,
              TransactionType.distribution,
              [
                accountId,
                accountOwnerId,
                user.organizationId,
                user.token,
                user,
              ],
              wipTransactionStatuses
            ),
            getTransactionsPromiseFactory(
              getRecurringDistributions,
              setRecurringDistributions,
              TransactionType.recurringDistribution,
              [
                accountId,
                accountOwnerId,
                user.organizationId,
                user.token,
                user,
              ],
              wipTransactionStatuses
            ),
            getTransactionsPromiseFactory(
              getBeneficiaries,
              setBeneficiaries,
              TransactionType.beneficiaries,
              [
                accountId,
                accountOwnerId,
                user.organizationId,
                user.token,
                {
                  status: wipTransactionStatuses,
                },
                user,
              ]
            ),
            getTransactionsPromiseFactory(
              getTransferRequestWithStatus,
              setTransferRequests,
              TransactionType.transfer,
              [
                user.organizationId,
                accountOwnerId,
                accountId,
                user.token,
                user,
              ],
              [
                TransactionStatus.pending,
                TransactionStatus.signature,
                TransferRequestStatus.awaiting,
                TransactionStatus.review,
                TransactionStatus.submitOwner,
              ]
            ),
          ]
        : [
            getTransactionsPromiseFactory(
              getAccountContributions,
              setContributions,
              TransactionType.contribution,
              [
                accountId,
                accountOwnerId,
                user.organizationId,
                user.token,
                user,
              ],
              wipTransactionStatuses
            ),
            getTransactionsPromiseFactory(
              getAccountDistributions,
              setDistributions,
              TransactionType.distribution,
              [
                accountId,
                accountOwnerId,
                user.organizationId,
                user.token,
                user,
              ],
              wipTransactionStatuses
            ),
            getTransactionsPromiseFactory(
              getRecurringDistributions,
              setRecurringDistributions,
              TransactionType.recurringDistribution,
              [
                accountId,
                accountOwnerId,
                user.organizationId,
                user.token,
                user,
              ],
              wipTransactionStatuses
            ),
            getTransactionsPromiseFactory(
              getBeneficiaries,
              setBeneficiaries,
              TransactionType.beneficiaries,
              [
                accountId,
                accountOwnerId,
                user.organizationId,
                user.token,
                {
                  status: wipTransactionStatuses,
                },
                user,
              ]
            ),
            getTransactionsPromiseFactory(
              getTransferRequestWithStatus,
              setTransferRequests,
              TransactionType.transfer,
              [
                user.organizationId,
                accountOwnerId,
                accountId,
                user.token,
                user,
              ],
              [
                TransactionStatus.pending,
                TransactionStatus.signature,
                TransferRequestStatus.awaiting,
                TransactionStatus.review,
                TransactionStatus.submitOwner,
              ]
            ),
          ]
    );
  };

  // if the user is not a account owner, don't show them the transfer requests transactions
  const wipTransactions = !user.roles.includes(UserRole.accountOwner)
    ? [
        ...beneficiaries,
        ...contributions,
        ...distributions,
        ...recurringDistributions,
        ...transferRequests,
      ]
    : [
        ...beneficiaries,
        ...contributions,
        ...distributions,
        ...recurringDistributions,
        ...transferRequests,
      ];

  const handleTransactionLinkClick = (transaction: any) => {
    // Handle transfer requests that are awaiting funds
    if (
      transaction.transferRequestStatus === TransferRequestStatus.awaiting &&
      !user.roles.includes(UserRole.accountOwner)
    ) {
      setTransferToFinalize(transaction);
    } else if (user.roles.includes(UserRole.accountOwner)) {
      linkToOwnerTransaction(accountOwnerId, accountId, transaction, navigate);
    } else {
      linkToTransaction(accountOwnerId, accountId, transaction, navigate);
    }
  };

  // handle pending item discard
  async function discardItemClick(): Promise<void> {
    await deleteApiCall(rowData, user.organizationId, user.token, user)
      .then((res) => {
        if (res.status === 200) {
          setmodalOpen(false);
          getAllAccountTransactions();
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            `Error deleting ${accountTransactionTypeNames[
              rowData.transactionType
            ].toLowerCase()}s`
        );
        setmodalOpen(false);
      });
  }

  const buildData = (row) => {
    setrowData({ ...row, accountOwnerId });
    setModalData({
      title: row.transactionType,
      body: `Do you want to delete this ${
        accountTransactionTypeNames[row.transactionType]
      } - ${getAppConstant(
        ConstantsMappingKey.accountType,
        row.accountType
      )} ?`,
    });
    setmodalOpen(true);
  };

  const handleClose = () => {
    setmodalOpen(false);
  };

  const documentFetch = async (transactionId: string, transactionType) => {
    switch (transactionType) {
      case TransactionType.contribution:
        return getContributionDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          transactionId, // Ambiguous ID for fetching the document
          user.token,
          user
        );
      case TransactionType.distribution:
        return getDistributionDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          transactionId, // Ambiguous ID for fetching the document
          user.token,
          '',
          user
        );
      case TransactionType.recurringDistribution:
        return getRecurringDistributionDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          transactionId, // Ambiguous ID for fetching the document
          user.token,
          '',
          user
        );
      case TransactionType.beneficiaries:
        return getBeneficiariesDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          transactionId, // Ambiguous ID for fetching the document
          user.token,
          '',
          user
        );
      case TransactionType.transfer:
        return getTransferRequestDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          transactionId, // Ambiguous ID for fetching the document
          user.token,
          user,
          ''
        );
      case TransactionType.account:
        return getAccountDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          user.token,
          user,
          ''
        );
      default:
        return Promise.resolve({ data: {} });
    }
  };

  async function openPdf(row: any) {
    const { transactionType, transactionId } = row;

    await documentFetch(transactionId, transactionType)
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  const getStatusDisplay = (status: string) => {
    if (allowDiscard) {
      return true;
    }

    if (!allowDiscard && status === TransactionStatus.review) {
      return false;
    }

    return true;
  };

  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 100,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <>
            {getStatusDisplay(row.transactionStatus) && (
              <IconButton
                data-testid="Delete"
                size="small"
                aria-label="Delete Transaction"
                onClick={() => {
                  buildData(row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
            <Grid container justifyContent="flex-end">
              <IconButton
                data-testid="pdfDocument"
                size="small"
                aria-label="Get PDF Document"
                onClick={() => {
                  openPdf(row);
                }}
              >
                <PictureAsPdfIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
    {
      field: 'transactionType',
      headerName: 'Type',
      width: 256,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const typeId = String(value);
        const transaction = { ...row, accountId, accountOwnerId };

        return (
          <Button
            onClick={() => {
              handleTransactionLinkClick(transaction);
            }}
            sx={classes.transactionLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {accountTransactionTypeNames[typeId] || typeId}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'transactionStatus',
      headerName: 'Status',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const statusComponents = {
          [TransactionStatus.pending]: (
            <Button color="warning" startIcon={<TransactionPendingIcon />}>
              Draft
            </Button>
          ),
          [TransactionStatus.review]: (
            <Button color="warning" startIcon={<TransactionReview />}>
              Pending Review
            </Button>
          ),
          [TransactionStatus.signature]: (
            <Button color="info" startIcon={<TransactionSignatureIcon />}>
              Awaiting Signature
            </Button>
          ),
          [TransactionStatus.transfer]: (
            <Button color="info" startIcon={<TransactionAwaitingIcon />}>
              Awaiting Transfer
            </Button>
          ),
          [TransactionStatus.submitOwner]: (
            <Button color="info" startIcon={<TransactionPendingIcon />}>
              Submitted By Owner
            </Button>
          ),
        };

        return statusComponents[value as TransactionStatus] || value.toString();
      },
    },

    {
      field: 'date',
      headerName: 'Date',
      width: 126,
      valueGetter: ({ row }) => {
        const { effectiveDate, startDate, signedDate } = row as any;
        return effectiveDate || startDate || signedDate;
      },
      valueFormatter: dateValueFormatter,
    },
  ];

  useEffect(() => {
    getAllAccountTransactions();

    if (user.roles) {
      setIsAllowDiscard(user.roles.includes(UserRole.orgTransactionsAdmin));
    }
    return () => {
      isMounted = false;
    };
  }, [user.roles, user.organizationId]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {transferToFinalize ? (
            <TransferWorkflowForm
              transferRequest={transferToFinalize}
              onSuccess={() => {
                setTransferToFinalize(null);
                getTransactions();
              }}
              onCancel={() => {
                setTransferToFinalize(null);
              }}
            />
          ) : (
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              disableColumnMenu
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10]}
              autoHeight
              columns={columns}
              rows={wipTransactions.map((result, id) => ({
                id,
                ...result,
              }))}
            />
          )}
        </Grid>
        <SiraDeleteModal
          title={modalData.title}
          body={modalData.body}
          showPrompt={modalOpen}
          handleClose={handleClose}
          deleteTransaction={() => {
            discardItemClick();
          }}
        />
      </Grid>
    </>
  );
}

export default AccountOwnerAccountWipTransactions;
