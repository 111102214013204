import React from 'react';
import { Grid, Alert } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';
import AddressForm, { ADDRESS_INIT, ADDRESS_SCHEMA } from '../AddressForm';
import SiraCheckbox from '../SiraCheckbox';
import { Branch } from '../../../api/OrganizationApi.d';
import PhoneNumberField from '../PhoneNumberField';

export const BRANCH_INFO_INIT: Branch = {
  ...ADDRESS_INIT(),
  name: '',
  phoneNumber: '',
  primaryBranch: false,
};

export const BRANCH_INFO_SCHEMA = ADDRESS_SCHEMA({ required: true }).shape({
  name: yup.string().required().max(80).label('Branch name'),
  primaryBranch: yup.boolean(),
  phoneNumber: yup.string().label('Phone Number'),
});

export interface BranchProps {
  initialValues: Branch;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}

function BranchForm({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
}: BranchProps) {
  return (
    <section>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
        onReset={() => onReset()}
        validationSchema={BRANCH_INFO_SCHEMA}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={9}>
                <SiraTextField name="name" label="Branch Name" />
              </Grid>
              <Grid item xs={12} sm={7}>
                <PhoneNumberField name="phoneNumber" label="Phone Number" />
              </Grid>
              <Grid item xs={12} sm={9}>
                <SiraTextField name="emailAddress" label="Email" />
              </Grid>
              <AddressForm />

              <Grid item xs={12} sm={3}>
                <Field
                  name="primaryBranch"
                  label="Primary Branch"
                  component={SiraCheckbox}
                  disabled={initialValues.primaryBranch}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {initialValues.primaryBranch ? (
                  <Alert severity="warning" variant="outlined">
                    To remove primary branch designation set another branch to
                    primary.
                  </Alert>
                ) : (
                  <Alert severity="warning" variant="outlined">
                    Only 1 branch can be primary. Changing the primary setting
                    makes this the only primary branch.
                  </Alert>
                )}
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        )}
      </Formik>
    </section>
  );
}

export default BranchForm;
