import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography,
  Box,
  Grid,
  Divider,
  Button,
  Link,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format, parseISO } from 'date-fns';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import BeneficiaryClaimsIcon from '../../icons/BeneficiaryClaims';

import { accountStatusDescriptions } from '../../app.constants';
import { AccountOwner, AccountOwnerAccount } from '../../api/AccountOwnerApi.d';
import AccountOwnerAccountContributions from '../accountOwnerProfile/AccountOwnerAccountContributions';
import AccountOwnerAccountDistributions from '../accountOwnerProfile/AccountOwnerAccountDistributions';
import AccountOwnerAccountBeneficiaries from '../accountOwnerProfile/AccountOwnerAccountBeneficiaries';
import AccountRecurringDistributions from '../accountOwnerProfile/AccountRecurringDistributions';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import AccountOwnerAccountWipTransactions from '../accountOwnerProfile/AccountOwnerAccountWipTransactions';
import {
  changeAccountStatus,
  createOrSaveAccount,
  deleteAccount,
  getAccountDocument,
} from '../../api/AccountApi';
import {
  AccountStatus,
  AccountStatusState,
  Account,
} from '../../api/AccountApi.d';
import AccountDetailsEditForm, {
  ACCOUNT_DETAILS_EDIT_INIT,
} from '../form/accountSearch/AccountDetailsEditForm';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import EsaResponsibleIndividualInfo from '../accountOwnerProfile/EsaResponsibleIndividualInfo';
import { AccountMemberValue, Solution } from '../../api/OrganizationApi.d';
import BeneficiariesEditIcon from '../../icons/BeneficiariesEdit';
import AddMoneyIcon from '../../icons/AddMoney';
import RecurringDistributionEditIcon from '../../icons/RecurringDistributionEdit';
import RecurringDistributionNewIcon from '../../icons/RecurringDistributionNew';
import RemoveMoneyIcon from '../../icons/RemoveMoney';
import { SiraDeleteModal } from '../SiraDeleteModal';
import { UserRole } from '../../api/UserApi.d';
import AccountOwnerTaxForms from '../accountOwnerProfile/AccountOwnerTaxForms';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import AccountOwnerAccountBeneficiaryClaim from '../accountOwnerProfile/AccountOwnerAccountBeneficiaryClaims';
import { getBeneficiariesClaimsProfile } from '../../api/BeneficiariesClaimsApi';
import { getExternalAccount } from '../../api/ExternalAccountApi';
import { ExternalAccount } from '../../api/ExternalAccountApi.d';
import AccountOwnerAccountWithholdingNotices from '../accountOwnerProfile/AccountOwnerAccountWithholdingNotices';
import OwnerAccountFmv from '../accountOwnerProfile/OwnerAccountFmv';
import { errorMessages } from '../../utils/errorhandling.utils';

interface AccountOwnerAccountDetailsProps {
  selectedAccount: AccountOwnerAccount;
  onAccountUpdate: Function;
  accountOwner?: AccountOwner;
  onAccountDelete: Function;
}

// Use these keys in tandem with the ?expanded= param to default an accordion to open
export enum AccountDetailAccordionType {
  pending = 'PENDING_TRANSACTIONS',
  fmv = 'FMV',
  contributions = 'CONTRIBUTION',
  distributions = 'DISTRIBUTIONS',
  recurring = 'RECURRING_DISTRIBUTIONS',
  beneficiaries = 'BENEFICIARIES',
  beneficiaryClaims = 'BENEFICIARY_CLAIMS',
  withholdings = 'WITHHOLDING_NOTICES',
  taxForm = 'TAX_FORM',
  transfer = 'TRANSFER',
  investments = 'INVESTMENTS',
}

function OwnerAccountDetails(props: AccountOwnerAccountDetailsProps) {
  let isMounted = true;
  const {
    selectedAccount = {} as AccountOwnerAccount,
    onAccountUpdate = () => {},
    accountOwner,
    onAccountDelete = () => {},
  } = props;
  const { user } = useUser();
  const navigate = useNavigate();

  const {
    accountOwnerId = '',
    accountId = '',
    accountType = '',
    accountNumber = '',
    accountStatus,
    externalAccountOwnerAccountId = '',
    eligibleDesignatedBeneficiary = '',
    openDate: rawOpenDate = '',
    closedDate: rawClosedDate = '',
    federalWithholdingPercent = '',
    federalWithholdingPercentEffectiveDate:
      rawfederalWithholdingPercentEffectiveDate = '',
    fiveYearTest,
  } = selectedAccount as Account;
  const [isEditing, setIsEditing] = useState(false as boolean);
  const [externalOwner, setExternalOwner] = useState({} as ExternalAccount);
  const [isFetchingDocument, setIsFetchingDocument] = useState(
    false as boolean
  );
  const theme = useTheme();

  const { organization, getAppConstant, addGlobalMessage } = useGlobalContext();
  const openDate = rawOpenDate && format(parseISO(rawOpenDate), 'MM/dd/yyyy');
  const closedDate =
    rawClosedDate && format(parseISO(rawClosedDate), 'MM/dd/yyyy');
  const federalWithholdingPercentEffectiveDate =
    rawfederalWithholdingPercentEffectiveDate &&
    format(parseISO(rawfederalWithholdingPercentEffectiveDate), 'MM/dd/yyyy');
  const allowViewDocument =
    selectedAccount.accountStatus !== AccountStatus.pending;
  const allowAccountEdit =
    [AccountStatus.closed, AccountStatus.open].includes(accountStatus) &&
    !user.roles.includes(UserRole.accountOwner);
  const [beneficiaryClaimProfile, setBeneficiariesClaimsProfile] = useState(
    false as boolean
  );
  const [modalData, setModalData] = useState({ body: '', title: '' });
  const [modalOpen, setmodalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false as boolean);
  const [editing, setEditing] = useState(false);
  const [expandedObject, setExpandedObject] = useState({
    [AccountDetailAccordionType.beneficiaries]: false,
    [AccountDetailAccordionType.beneficiaryClaims]: false,
    [AccountDetailAccordionType.pending]: false,
    [AccountDetailAccordionType.fmv]: false,
    [AccountDetailAccordionType.recurring]: false,
    [AccountDetailAccordionType.contributions]: false,
    [AccountDetailAccordionType.distributions]: false,
    [AccountDetailAccordionType.withholdings]: false,
    [AccountDetailAccordionType.taxForm]: false,
  });

  // Query param that allows a url direct to an accordion
  const queryParams = new URLSearchParams(useLocation().search);
  const expandedDefault = queryParams.get('expanded') || '';

  const { accountNumberValue } = organization;
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Acct #'
      : 'Mmbr #';

  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      alignItems: 'center',
      pl: 0,
      pr: 2,
    },
    header:{
      color: `${theme.palette.text.primary} !important`,
    }
  };

  const handleEditAccountClick = () => {
    setIsEditing(true);
  };

  const deleteSelectedAccount = async (): Promise<void> => {
    setIsDeleting(true);

    await deleteAccount(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          onAccountDelete();
          setIsDeleting(false);
          setmodalOpen(false);
        }
        addGlobalMessage('Account successfully deleted', {
          severity: 'success',
        });
      })
      .catch((err) => {
        if (isMounted) {
          setIsDeleting(false);
          setmodalOpen(false);
        }
        addGlobalMessage(
          errorMessages(err) || 'Could not delete this distribution'
        );
      });
  };

  // Update status one forward or one back based on current status
  const updateAccountStatus = async (data: Account): Promise<void> => {
    const { accountStatus: newAccountStatus } = data;

    // Only two options so allow only move forward for open accounts or back for closed
    const state =
      newAccountStatus === AccountStatus.closed
        ? AccountStatusState.next
        : AccountStatusState.previous;

    await changeAccountStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      state,
      {},
      user.token,
      user
    ).then(() => {
      addGlobalMessage('Account details successfully updated', {
        severity: 'success',
      });
    });
  };

  // close opened modal
  const handleClose = () => {
    setmodalOpen(false);
  };

  // Save updated account fields when editing
  const updateAccountDetails = async (data: Account): Promise<void> => {
    // remove responsibleCountry from data object
    const cleanedData = data;
    if (cleanedData.responsibleCountry) {
      delete cleanedData.responsibleCountry;
    }

    if (data.accountStatus !== accountStatus) {
      await updateAccountStatus(cleanedData);
    }

    await createOrSaveAccount(
      cleanedData,
      user.organizationId,
      accountOwnerId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          onAccountUpdate(res.data);
          setIsEditing(false);
        }
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Error saving account details');
      });
  };

  // Get the exisiting PDF for the account
  async function openPdf() {
    await getAccountDocument(
      user.organizationId,
      accountOwnerId,
      accountId,
      user.token,
      user
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  // Fetch the decased original account owner for inherited types
  async function getExternalAccountOwner() {
    setExternalOwner({});

    if (externalAccountOwnerAccountId) {
      await getExternalAccount(
        externalAccountOwnerAccountId,
        user.organizationId,
        user.token
      ).then(({ data = {} }) => {
        if (isMounted) {
          setExternalOwner(data);
        }
      });
    }
  }

  // This function will be called when the workflow on the account owner page is clicked.
  const goToWorkFlow = (
    transaction: AccountDetailAccordionType,
    editActive: boolean = false
  ) => {
    switch (transaction) {
      case AccountDetailAccordionType.beneficiaries:
        navigate(
          `/owner/beneficiaries?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
        );
        break;
      case AccountDetailAccordionType.contributions:
        navigate(
          `/owner/contributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
        );
        break;
      case AccountDetailAccordionType.transfer:
        navigate(
          `/owner/rolloverTransfer?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
        );
        break;
      case AccountDetailAccordionType.distributions:
        navigate(
          `/owner/distributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
        );
        break;
      case AccountDetailAccordionType.recurring:
        navigate(
          `/owner/recurringDistributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}&editActive=${editActive}`
        );
        break;
      case AccountDetailAccordionType.beneficiaryClaims:
        navigate(
          `/beneficiaryClaims/viewBeneficiaryClaim/beneficiaryClaimsProfile?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
        );
        break;
      default:
        break;
    }
  };

  const setExpanded = (key: AccountDetailAccordionType, expanded) => {
    setExpandedObject({ ...expandedObject, [key]: expanded });
  };

  useEffect(() => {
    setExpandedObject({
      [AccountDetailAccordionType.beneficiaries]: false,
      [AccountDetailAccordionType.beneficiaryClaims]: false,
      [AccountDetailAccordionType.pending]: false,
      [AccountDetailAccordionType.fmv]: false,
      [AccountDetailAccordionType.recurring]: false,
      [AccountDetailAccordionType.contributions]: false,
      [AccountDetailAccordionType.withholdings]: false,
      [AccountDetailAccordionType.taxForm]: false,
      [AccountDetailAccordionType.distributions]: false,
    });
    // this single line will replace the url when the user clicks on an account. without reloading the page
    window.history.replaceState(
      '',
      '',
      `accountOwner/${selectedAccount.accountOwnerId}/account/${selectedAccount.accountId}`
    );
  }, [selectedAccount]);

  async function getBenefitClaimsInfo(): Promise<void> {
    await getBeneficiariesClaimsProfile(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    ).then(({ data: beneficiaryClaimInfo }) => {
      if (beneficiaryClaimInfo) {
        setBeneficiariesClaimsProfile(Boolean(beneficiaryClaimInfo));
      }
    });
  }

  useEffect(() => {
    getExternalAccountOwner();
  }, [accountId]);

  useEffect(() => {
    setIsEditing(false);
    getBenefitClaimsInfo();
    return () => {
      isMounted = false;
    };
  }, [accountId]);

  const buildData = () => {
    setModalData({
      title: 'Account',
      body: `Do you want to delete this ${getAppConstant(
        ConstantsMappingKey.accountType,
        accountType
      )}?`,
    });
    setmodalOpen(true);
  };

  return (
    <Paper elevation={3}>
      {/* Basic Info */}
      <Grid container sx={{ p: 3, mt: 2 }}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" sx={classes.header}>
            {getAppConstant(ConstantsMappingKey.accountType, accountType)}
          </Typography>

          {/* Editing */}
          {isEditing && (
            <Box mt={3} mb={5}>
              <AccountDetailsEditForm
                onSubmit={updateAccountDetails}
                onCancel={() => {
                  setIsEditing(false);
                }}
                initialValues={{
                  ...ACCOUNT_DETAILS_EDIT_INIT,
                  ...selectedAccount,
                }}
              />
            </Box>
          )}

          {/* Info */}
          {!isEditing && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography>
                  {headerAccountName}: {accountNumber}
                </Typography>
                <Typography>
                  Status: {accountStatusDescriptions[accountStatus]}
                </Typography>
                {externalOwner.externalAccountOwnerAccountId && (
                  <Typography variant="body2" mt={1}>
                    Original owner:{' '}
                    <RouterLink
                      to={`/accountOwner/${accountOwnerId}/externalAccountOwner?externalAccountOwnerId=${externalAccountOwnerAccountId}`}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {externalOwner.firstName} {externalOwner.lastName}
                      </Typography>
                    </RouterLink>
                  </Typography>
                )}
                {allowAccountEdit && (
                  <Link
                    variant="inherit"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleEditAccountClick}
                  >
                    <Typography>Edit details</Typography>
                  </Link>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {allowViewDocument && (
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        disabled={isFetchingDocument}
                        color="primary"
                        startIcon={<PictureAsPdfIcon />}
                        onClick={async () => {
                          setIsFetchingDocument(true);
                          await openPdf();
                          setIsFetchingDocument(false);
                        }}
                      >
                        View Document
                      </Button>
                    </Grid>
                  )}
                  {user.roles.includes(UserRole.orgSiteAdmin) && (
                    <Grid item>
                      <Button
                        disabled={isDeleting}
                        size="small"
                        variant="contained"
                        aria-label="Delete this deposit"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={buildData}
                      >
                        Delete Account
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Federal Withholding:{' '}
                  <NumberFormat
                    value={
                      federalWithholdingPercent === ''
                        ? '0'
                        : federalWithholdingPercent
                    }
                    suffix="%"
                    displayType="text"
                    isNumericString
                  />
                </Typography>
                {Boolean(federalWithholdingPercentEffectiveDate) && (
                  <Typography>
                    Effective Date: {federalWithholdingPercentEffectiveDate}
                  </Typography>
                )}
                {eligibleDesignatedBeneficiary && (
                  <Typography>Eligible Designated Beneficiary</Typography>
                )}
                {fiveYearTest && (
                  <Typography>
                    Five-year holding period has been satisfied
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* Dates */}
        <Grid item xs={12} md={4}>
          {rawOpenDate && (
            <Typography variant="body2" align="right">
              Open Date: {openDate}
            </Typography>
          )}
          {rawClosedDate && (
            <Typography variant="body2" align="right">
              Closed Date: {closedDate}
            </Typography>
          )}
        </Grid>
        <SiraDeleteModal
          title={modalData.title}
          body={modalData.body}
          showPrompt={modalOpen}
          handleClose={handleClose}
          deleteTransaction={deleteSelectedAccount}
        />
      </Grid>

      {/* ESA Responsible Individaul */}
      <EsaResponsibleIndividualInfo
        account={selectedAccount}
        accountOwner={accountOwner}
        updateAccountDetails={updateAccountDetails}
      />

      {/* Transactions */}
      <Box mt={2} p={2}>
        <Accordion
          onChange={(e, expanded) => {
            setExpanded(AccountDetailAccordionType.pending, expanded);
          }}
          defaultExpanded={
            expandedDefault === AccountDetailAccordionType.pending
          }
                       slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container wrap="nowrap" sx={classes.buttonBar}>
              <Grid item xs={12}>
                <Typography variant="h5" color="secondary">
                  Pending Transactions
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <AccountOwnerAccountWipTransactions account={selectedAccount} />
          </AccordionDetails>
        </Accordion>

        <Box p={2}>
          <Divider />
        </Box>

        {selectedAccount.accountStatus !== AccountStatus.review && (
          <>
            {/* FMV Fair Market Value */}
            <Accordion
              onChange={(e, expanded) => {
                setExpanded(AccountDetailAccordionType.fmv, expanded);
              }}
              defaultExpanded={
                expandedDefault === AccountDetailAccordionType.fmv
              }
                           slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      Fair Market Value
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <OwnerAccountFmv
                  account={selectedAccount}
                  accountOwner={accountOwner}
                />
              </AccordionDetails>
            </Accordion>

            {/* Tax Forms */}
            <Accordion
              onChange={(e, expanded) => {
                setExpanded(AccountDetailAccordionType.taxForm, expanded);
              }}
                           slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
              defaultExpanded={
                expandedDefault === AccountDetailAccordionType.taxForm
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      Tax Forms
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <AccountOwnerTaxForms account={selectedAccount} />
              </AccordionDetails>
            </Accordion>

            {/* CONTRIBUTIONS */}
            <Accordion
              onChange={(e, expanded) => {
                setExpanded(AccountDetailAccordionType.contributions, expanded);
              }}
              defaultExpanded={
                expandedDefault === AccountDetailAccordionType.contributions
              }
                           slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      Deposits
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        goToWorkFlow(AccountDetailAccordionType.transfer);
                      }}
                      startIcon={<AddMoneyIcon color="primary" />}
                      sx={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                    >
                      Request Rollover/Transfer
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        goToWorkFlow(AccountDetailAccordionType.contributions);
                      }}
                      startIcon={<AddMoneyIcon color="primary" />}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Add Deposit
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <AccountOwnerAccountContributions account={selectedAccount} />
              </AccordionDetails>
            </Accordion>

            {/* DISTRIBUTIONS */}
            <Accordion
              onChange={(e, expanded) => {
                setExpanded(AccountDetailAccordionType.distributions, expanded);
              }}
                           slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
              defaultExpanded={
                expandedDefault === AccountDetailAccordionType.distributions
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      Distributions
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        goToWorkFlow(AccountDetailAccordionType.distributions);
                      }}
                      startIcon={<RemoveMoneyIcon color="primary" />}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Add Distribution
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <AccountOwnerAccountDistributions
                  account={selectedAccount}
                  accountOwner={accountOwner}
                />
              </AccordionDetails>
            </Accordion>

            {/* RECURRING DISTRIBUTION */}
            <Accordion
              onChange={(e, expanded) => {
                setExpanded(AccountDetailAccordionType.recurring, expanded);
              }}
              defaultExpanded={
                expandedDefault === AccountDetailAccordionType.recurring
              }
                           slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      Recurring Distributions
                    </Typography>
                  </Grid>
                  {editing && (
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          goToWorkFlow(
                            AccountDetailAccordionType.recurring,
                            true
                          );
                        }}
                        startIcon={
                          <RecurringDistributionEditIcon color="primary" />
                        }
                        sx={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                      >
                        Edit Recurring Distribution
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        goToWorkFlow(AccountDetailAccordionType.recurring);
                      }}
                      startIcon={
                        <RecurringDistributionNewIcon color="primary" />
                      }
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Add Recurring Distribution
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <AccountRecurringDistributions
                  account={selectedAccount}
                  setEditing={setEditing}
                />
              </AccordionDetails>
            </Accordion>

            {/* BENEFICIARIES */}
            <Accordion
              onChange={(e, expanded) => {
                setExpanded(AccountDetailAccordionType.beneficiaries, expanded);
              }}
              defaultExpanded={
                expandedDefault === AccountDetailAccordionType.beneficiaries
              }
                           slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      Beneficiaries
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        goToWorkFlow(AccountDetailAccordionType.beneficiaries);
                      }}
                      startIcon={<BeneficiariesEditIcon color="primary" />}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Update Beneficiaries
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <AccountOwnerAccountBeneficiaries
                  account={selectedAccount}
                  beneficiariesAccount={expandedObject.BENEFICIARIES}
                />
              </AccordionDetails>
            </Accordion>

            {/* WITHHOLDING NOTICES */}

              <Accordion
                onChange={(e, expanded) => {
                  setExpanded(
                    AccountDetailAccordionType.withholdings,
                    expanded
                  );
                }}
                defaultExpanded={
                  expandedDefault === AccountDetailAccordionType.withholdings
                }
                             slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container wrap="nowrap" sx={classes.buttonBar}>
                    <Grid item xs={12}>
                      <Typography variant="h5" color="secondary">
                        Notifications
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <AccountOwnerAccountWithholdingNotices
                    account={selectedAccount}
                  />
                </AccordionDetails>
              </Accordion>

            {/* BENEFICIARIES CLAIMS */}
            {beneficiaryClaimProfile && (
              <Accordion
                onChange={(e, expanded) => {
                  setExpanded(
                    AccountDetailAccordionType.beneficiaryClaims,
                    expanded
                  );
                }}
                defaultExpanded={
                  expandedDefault ===
                  AccountDetailAccordionType.beneficiaryClaims
                }
                             slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container wrap="nowrap" sx={classes.buttonBar}>
                    <Grid item xs={12}>
                      <Typography variant="h5" color="secondary">
                        Beneficiary Claim Information
                      </Typography>
                    </Grid>
                    {user.roles.includes(UserRole.orgBeneficiaryClaims) && (
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            goToWorkFlow(
                              AccountDetailAccordionType.beneficiaryClaims
                            );
                          }}
                          startIcon={<BeneficiaryClaimsIcon color="primary" />}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          View Claim
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>

                <AccordionDetails>
                  <AccountOwnerAccountBeneficiaryClaim
                    account={selectedAccount}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
}

export default OwnerAccountDetails;
