import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgTransactionAwaiting(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="m10.772 0c-1.5559 0-2.8728 1.0054-3.3755 2.3937h-5.0025c-1.3165 0-2.3937 1.0772-2.3937 2.3937v16.756c0 1.3165 1.0772 2.3937 2.3937 2.3937h16.756c1.3165 0 2.3937-1.0772 2.3937-2.3937v-16.756c0-1.3165-1.0772-2.3937-2.3937-2.3937h-5.0025c-0.50267-1.3883-1.8196-2.3937-3.3755-2.3937zm0 2.3937c0.65827 0 1.1968 0.53858 1.1968 1.1969 0 0.65827-0.53858 1.1969-1.1968 1.1969-0.65827 0-1.1969-0.53858-1.1969-1.1969 0-0.65827 0.53858-1.1969 1.1969-1.1969zm-4.5326 3.8874h9.2055v4.6097l-3.0693 3.0623 3.0693 3.0693v4.6027h-9.2055l0.00706-4.5957 3.0623-3.0763-3.0623-3.0693zm4.6027 8.0554-3.0693 3.0693v2.6836h6.1385v-2.6836z"
        strokeWidth="1.1968"
        id="path2"
      />
    </SvgIcon>
  );
}

export default SvgTransactionAwaiting;
