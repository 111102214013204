import React from 'react';
import { Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import { BeneficiariesClaimsInfo } from '../../../api/BeneficiaryClaimsApi';
import AdobeIcon from '../../../icons/Adobe';
import WordIcon from '../../../icons/Word';
import ExcelIcon from '../../../icons/Excel';

/* eslint-disable no-plusplus */
export function formatBytes(x) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  let n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n /= 1024;
  }

  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

export const returnContactLayout = (
  beneficiariesClaims: BeneficiariesClaimsInfo
) => {
  switch (beneficiariesClaims.preferredContactMethod) {
    case 'MAIL':
      return (
        <>
          <Typography variant="body1" color="primary" pt={0.5}>
            Address:
          </Typography>
          <Typography variant="body2" color="primary">
            {`${beneficiariesClaims.pcmAddress}, ${beneficiariesClaims.pcmCity}, ${beneficiariesClaims.pcmState} ${beneficiariesClaims.pcmZip}`}
          </Typography>
        </>
      );
    case 'EMAIL':
      return (
        <>
          <Typography variant="body1" color="primary" pt={0.5}>
            Email:
          </Typography>
          <Typography variant="body2" color="primary">
            {beneficiariesClaims.pcmEmail}
          </Typography>
        </>
      );
    case 'PHONE':
      return (
        <>
          <Typography variant="body1" color="primary" pt={0.5}>
            Phone:
          </Typography>
          <Typography variant="body2" color="primary">
            {beneficiariesClaims.pcmPhone}
          </Typography>
        </>
      );
    default:
      return (
        <>
          <Typography variant="body1" color="primary" pt={0.5}>
            Address:
          </Typography>
          <Typography variant="body2" color="primary">
            {`${beneficiariesClaims.pcmAddress}, ${beneficiariesClaims.pcmCity}, ${beneficiariesClaims.pcmState} ${beneficiariesClaims.pcmZip}`}
          </Typography>
        </>
      );
  }
};

// set file type here. going based of the accepted files defined in the file type section
export const setFileTypeName = (nameValue) => {
  const extension = nameValue.split('.').pop();

  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'xlsx':
      return 'application/xlsx';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'doc':
      return 'application/msword';
    case 'tsv':
      return 'application/pdf';
    case 'png':
      return 'image/png';
    case 'svg':
      return 'image/svg';
    default:
      return 'image/jpeg';
  }
};

export const setImageIcon = (nameValue) => {
  const extension = nameValue.split('.').pop();

  switch (extension) {
    case 'pdf':
      return <AdobeIcon />;
    case 'xlsx':
      return <ExcelIcon />;
    case 'docx':
      return <WordIcon />;
    case 'doc':
      return <WordIcon />;
    case 'tsv':
      return <ExcelIcon />;
    default:
      return <ImageTwoToneIcon fontSize='large' />;
  }
};

export const dateValueFormatter = (params: string) => {

  return params && format(parseISO(String(params)), 'MM/dd/yyyy');
};
