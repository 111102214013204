import React, { ReactChild, useEffect } from 'react';
import {
  LinearProgress,
  Step,
  StepButton,
  StepContent,
  Stepper,
} from '@mui/material';

import SiraStepLabel from '../components/steps/SiraStepLabel';

export interface TransactionStep {
  label: string;
  stepContent: ReactChild;
}

interface TransactionStepperProps {
  steps: Array<TransactionStep>;
  activeStep: number;
  isLoading?: boolean;
  onStepClick: Function;
}

function TransactionStepper(props: TransactionStepperProps) {
  const {
    steps = [],
    activeStep = 0,
    isLoading = false,
    onStepClick = () => {},
  } = props;
  
  return (
    <Stepper orientation="vertical" activeStep={activeStep}>
      {steps.map((step, index) => (
        <Step key={`transaction_step_${step.label}`}>
          <StepButton
            onClick={() => {
              onStepClick(index, step);
            }}
          >
            <SiraStepLabel title={step.label} active={index === activeStep} />
          </StepButton>
          <StepContent>
            {isLoading ? (
              <LinearProgress color="secondary" />
            ) : (
              step.stepContent
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

export default TransactionStepper;
