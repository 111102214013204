/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid, Box } from '@mui/material';
import { Formik, Form } from 'formik';

import StepButtonBar from '../steps/StepButtonBar';
import { FileColumns } from './FileColumnsForm';
import SiraFileField from './SiraFileField';

export interface FileUpload extends FileColumns {
  importFiles?: Array<File>;
}

export const FILE_UPLOAD_INIT: FileUpload = {
  importFiles: null,
};
export interface Accept {
  [key: string]: string[];
}
export interface FileUploadFormProps {
  fileTypes?: Accept;
  fileText?: string;
  initialValues: FileUpload;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
  description?: boolean;
  multipleFiles?: boolean;
  descriptionField?:string;
}

const FileUploadForm = ({
  fileTypes = {},
  fileText,
  initialValues,
  onSubmit,
  onReset,
  submitName,
  resetName,
  description,
  multipleFiles,
  descriptionField,
}: FileUploadFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <Box maxWidth={480}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SiraFileField
                    onDropField={(docs) => {
                      setFieldValue('importFiles', docs);
                    }}
                    name="importFiles"
                    acceptedFilesText={fileText}
                    acceptedFileTypes={fileTypes}
                    description={description}
                    multipleFiles={multipleFiles}
                    descriptionField={descriptionField}
                  />
                </Grid>
              </Grid>
            </Box>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FileUploadForm;
