import axios from 'axios';
import format from 'string-format';
import {
  FmvQueryResponse,
  FmvQueryParams,
  FairMarketValue,
  FmvChangeResponse,
  FmvSearchResponse,
  FMVImportColumnsResponse,
  FmvNeededResponse,
} from './FairMarketValueApi.d';
import { prepareBinaryJson, setBaseUrl } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;


const fmvUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/fmv/{0.taxYear}';
const fmvSearchUrl = '/api/org/{0.orgId}/fmvSearch';
const fmvNeededUrl = '/api/org/{0.orgId}/fmvNeeded';
const fmvImportUrl = '/api/org/{0.orgId}/upload/fmvImport';
const fmvImportColumnsUrl = '/api/org/{0.orgId}/upload/fmvImportColumns';

function fairMarketValueRequestFactory(data: any): FairMarketValue {
  const { taxYear, fairMarketValue } = data;

  return { taxYear, fairMarketValue };
}

export async function importFMVsColumns(
  files: Array<File>,
  orgId: string,
  token: string
): Promise<FMVImportColumnsResponse> {
  const url = `${urlBase}${format(fmvImportColumnsUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importFairMarketValue(
  files: Array<File>,
  headerMappings: any,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(fmvImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();

  formData.append('file', files[0]);
  if (headerMappings) {
    formData.append('headerMappings', prepareBinaryJson(headerMappings));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

}

export async function getFairMarketValues(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  params: FmvQueryParams = {},
  user: any
): Promise<FmvQueryResponse> {

  const url = `${urlBase}${format(`${setBaseUrl(user)}${fmvUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createOrSaveFairMarketValue(
  data: any,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  user: any 
): Promise<FmvChangeResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${fmvUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    taxYear: data.taxYear,
  })}`;

  const dataNorm = fairMarketValueRequestFactory(data);

  if (data.fairMarketValueId) {
    return axiosInstance.put(url, dataNorm, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteFairMarketValue(
  fmv: FairMarketValue,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  user: any
): Promise<FmvChangeResponse> {

  const url = `${urlBase}${format(`${setBaseUrl(user)}${fmvUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    taxYear: fmv.taxYear,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function searchFairMarketValues(
  orgId: string,
  params: FmvQueryParams = {},
  token: string
): Promise<FmvSearchResponse> {
  const url = `${urlBase}${format(fmvSearchUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function neededFairMarketValues(
  orgId: string,
  params: FmvQueryParams = {},
  token: string
): Promise<FmvNeededResponse> {
  const url = `${urlBase}${format(fmvNeededUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
