import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useNavigate } from 'react-router-dom';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import ObscuredTaxId from '../../ObscuredTaxId';
import {
  BeneficiariesClaimResult,
  BeneficiaryStatus,
} from '../../../api/BeneficiaryClaimsApi.d';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { TaxIdFormat } from '../../../page/AccountSavedSearch';
import Layout from '../../Layout';
import FormPaper from '../../FormPaper';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import DataGridExportToolbar from '../../DataGridExportToolbar';
import { globalPaginationOptions } from '../../../app.constants';
import {
  setBeneficiarySearchResponse,
  useTransactionReducer,
} from '../../../page/TransactionReducer';
import BeneficiarySearchForm from './BeneficiarySearchForm';
import { dateValueFormatter } from '../../../utils/DataGrid.utils';
import {
  AccountOwner,
  AccountOwnerSearchResult,
} from '../../../api/AccountOwnerApi.d';
import { Account } from '../../../api/AccountApi.d';
import { useUser } from '../../../auth/useUser';
import { getUsers } from '../../../api/UserApi';
import { User } from '../../../api/UserApi.d';

interface ViewBeneficiaryRow
  extends Account,
    AccountOwner,
    BeneficiariesClaimResult {
  id: number;
}

// Flatten and map out the rmd notifications for display in the data grid
function viewBeneficiaryRowsFactory(
  notifications: Array<AccountOwnerSearchResult>
): Array<ViewBeneficiaryRow> {
  return notifications.map((result, id) => ({
    ...result.account,
    ...result.accountOwner,
    ...result.beneficiaryClaim,
    id,
  }));
}

function ViewBeneficiaryClaims() {
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const [pageState] = useTransactionReducer();
  const navigate = useNavigate();
  const { query = '' } = pageState.searchResponse || {};
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const [statusFilter, setStatusFilter] = useState<Array<BeneficiaryStatus>>(
    []
  );
  const [response, setResponse] = useState([]);
  const { user } = useUser();

  const [orgViewBeneficiarys, setOrgViewBeneficiarys] = useState(
    [] as Array<AccountOwnerSearchResult>
  );

  // Allow the filter items to be selected or deselected
  const handleTaskStatusClick = (
    viewBeneficiaryStatuses: Array<BeneficiaryStatus>
  ) => {
    if (
      viewBeneficiaryStatuses.some((status) => statusFilter.includes(status))
    ) {
      setStatusFilter([] as Array<BeneficiaryStatus>);
    } else {
      setStatusFilter(viewBeneficiaryStatuses);
    }
  };

  useEffect(() => {
    if (pageState.searchResponse && pageState.searchResponse.data) {
      setOrgViewBeneficiarys(pageState.searchResponse.data);
    }
  }, [query]);

  const linkToRecordAccount = (row: ViewBeneficiaryRow): void => {
    const { beneficiaryClaimStatus, accountId, accountOwnerId } = row;

    const shouldLinkToTransaction =
      beneficiaryClaimStatus === BeneficiaryStatus.pending;
    if (shouldLinkToTransaction) {
      navigate(
        `/beneficiaryClaims/submitBeneficiaryClaim?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
      );
    } else {
      navigate(
        `/beneficiaryClaims/viewBeneficiaryClaim/beneficiaryClaimsProfile?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
      );
    }
  };

  const setRepData = (submittedByID) => {
    // find the user that matches the submitted by id
    let submittedBy = '';
    response.forEach((userValue: User) => {
      if (userValue.userId === submittedByID) {
        submittedBy = `${userValue.firstName} ${userValue.lastName}`;
      }
    });

    return submittedBy;
  };

  const setRepInfo = async (): Promise<void> => {
    await getUsers(user.organizationId, user.token)
      .then((res) => {
        setResponse(res.data);
      })
      .catch(() => {
        addGlobalMessage('Error fetching organization users');
      });
  };

  useEffect(() => {
    if (user.organizationId && user.token) {
      setRepInfo();
    }
  }, [user.organizationId, user.token]);

  const columns = [
    {
      field: 'fullName',
      headerName: 'Owner',
      width: 256,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              linkToRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'finOrgSubmittedByUserId',
      headerName: 'Submitted By',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return setRepData(value);
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'submittedDate',
      headerName: 'Submitted Date',
      width: 156,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'beneficiaryClaimStatus',
      headerName: 'Status',
      width: 350,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        const statusComponents = {
          [BeneficiaryStatus.pending]: (
            <Button color="warning" startIcon={<PendingIcon />}>
              Pending
            </Button>
          ),
          [BeneficiaryStatus.processing]: (
            <Button color="success" startIcon={<CheckCircleIcon />}>
              Submitted for Processing
            </Button>
          ),
          [BeneficiaryStatus.awaitingElection]: (
            <Button color="info" startIcon={<PendingIcon />}>
              Awaiting Election
            </Button>
          ),
          [BeneficiaryStatus.awaitingAdditionalInfo]: (
            <Button color="info" startIcon={<PendingIcon />}>
              Awaiting Additional Information
            </Button>
          ),
          [BeneficiaryStatus.completed]: (
            <Button color="success" startIcon={<CheckCircleIcon />}>
              Completed
            </Button>
          ),
          [BeneficiaryStatus.notProcessed]: (
            <Button color="info" startIcon={<PendingIcon />}>
              Not Processed
            </Button>
          ),
        };

        return statusComponents[value as BeneficiaryStatus];
      },
    },
  ] as GridColDef[];

  const rows = viewBeneficiaryRowsFactory(orgViewBeneficiarys);

  // Multi-facted filter of entries based on the selected status and search terms
  const filteredRows = rows.filter((row) => {
    const matchesStatus = statusFilter.includes(row.beneficiaryClaimStatus);

    if (statusFilter.length && matchesStatus) {
      return row;
    }

    return !statusFilter.length && row.beneficiaryClaimStatus && row;
  });

  // If editing, create the recur distributions election steps
  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Beneficiary Claims
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Current Claims
          </Typography>

          <Box maxWidth={800}>
            <BeneficiarySearchForm
              setResponse={setBeneficiarySearchResponse}
              statusFilter={statusFilter}
              onItemClick={handleTaskStatusClick}
            />
          </Box>
          {query && (
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
                Toolbar: DataGridExportToolbar,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              disableColumnMenu
              autoHeight
              columns={columns}
              rows={filteredRows}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default ViewBeneficiaryClaims;
