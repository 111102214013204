import {
  Distribution,
  FederalTaxElectionType,
  StateWithholdingPercentType,
} from '../../../api/DistributionApi.d';
import {
  RecurringDistribution,
  LifeExpectancyTable,
} from '../../../api/RecurringDistributionApi.d';

export interface MinimumReq {
  isRequired: boolean;
  minimum: number;
}

// Extend and override some fields as required
export interface DistributionAmountFormData
  extends RecurringDistribution,
    Distribution {
  netAmount: string;
  totalAmount: string;
  fairMarketValue: string;
  netAmountEntered?: boolean;
  lifeExpectancyTable?: LifeExpectancyTable;
  distributionAmountType?: DistributionChoices;
  closingAccount: boolean;
  penaltyAmount: string;
  effectiveDate: string;
  filingStatus: FilingStatus;
  totalIncome: string;
  federalTaxElectionType: FederalTaxElectionType;
  federalWithholdingPercent: any;
  withholdingState: string;
  stateWithholdingPercent: string | number;
  additionalStateWithholding: string;
  recurringSelection: DistributionChoices;
  accountBalance?: string;
  netIncomeAttributable?: number | string;
  distributionReason?: DistributionReason;
  allowNoneState?: boolean;
  minimumDistribution?: MinimumReq; // this is an never visualized field used for validaiton purposes
  canSpecifyPercent?: StateWithholdingPercentType; // this is an never visualized field used for validaiton purposes
  suggestedStateWithholding?: string; // this is an never visualized field used for validaiton purposes
  doNotWithholdFederal?: boolean; // this is stored only so returning to step keeps it
  useSuggestedWithholding?: boolean; // this is an never visualized field used for validaiton purposes
  lifeExpectancy?: number;
  percentFieldChange?: boolean; // only used to set federalwithholdingamount
}

export enum FilingStatus {
  default = '',
  single = 'SINGLE',
  joint = 'MARRIED_FILING_JOINTLY',
  head = 'HEAD_OF_HOUSEHOLD',
}
