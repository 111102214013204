import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Typography, Button, Popover, Grid } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';

import {
  Organization,
  OrganizationSearchResponse,
} from '../../../api/OrganizationApi.d';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import {
  globalPaginationOptions,
  fiduciaryTypeLabels,
} from '../../../app.constants';
import { Task } from '../../../api/TaskApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import { dateValueFormatter } from '../../../utils/DataGrid.utils';

interface TaskOrgSelectionProps {
  response: OrganizationSearchResponse;
  onResultClick?: Function;
  filterMethod?: Function;
  data?:  Array<Organization>;
}

interface TaskOrgSelectionRow extends Organization {
  id: number;
}

export function TaskOrgSelection(props: TaskOrgSelectionProps) {
  const {
    response = {},
    onResultClick = () => {},
    filterMethod = () => true,
    data: results = [],
  } = props;
  const { values, setFieldValue } = useFormikContext<Task>();
  const { financialOrganizationIds } = values;
  const [selectedRows, setSelectedRows] = useState(
    [] as Array<TaskOrgSelectionRow>
  );
  const { setGlobalPageSize } = useGlobalContext();
  const classes = {
    organizationLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  const columns = [
    {
      field: 'financialOrganizationName',
      headerName: 'Name',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const isRowSelected = selectedRows.some(
          ({ financialOrganizationId }) =>
            financialOrganizationId === row.financialOrganizationId
        );
        const prunedSelectedRows = selectedRows.filter(
          ({ financialOrganizationId }) =>
            financialOrganizationId !== row.financialOrganizationId
        );

        return (
          <Button
            variant={isRowSelected ? 'contained' : 'text'}
            onClick={() => {
              // Remove from list if already selected or add if not
              setSelectedRows(
                !isRowSelected ? [row, ...selectedRows] : prunedSelectedRows
              );
              onResultClick(row);
            }}
            sx={classes.organizationLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'financialOrganizationId',
      headerName: 'Financial Organization ID',
      width: 150,
    },
    {
      field: 'solution',
      headerName: 'Solution',
      width: 100,
    },
    {
      field: 'financialOrganizationFederalTaxId',
      headerName: 'Federal Tax ID',
      width: 156,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ein} />
        );
      },
    },
    {
      field: 'fiduciaryType',
      headerName: 'Fiduciary Type',
      width: 126,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return fiduciaryTypeLabels[String(value)];
      },
    },
    {
      field: 'primaryBranchStateAbbreviation',
      headerName: 'State',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const { value = '', row = {} } = params;
        const [orgStateAnchor, setOrgStateAnchor] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setOrgStateAnchor(event.currentTarget);
        };

        const handleClose = () => {
          setOrgStateAnchor(null);
        };

        return (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <Button onClick={handleClick}>
                <Typography variant="body2">{value.toString()}</Typography>
              </Button>
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(orgStateAnchor)}
              onClose={handleClose}
              anchorEl={orgStateAnchor}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">
                  {row.primaryBranchStateName}
                </Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'agreementDate',
      headerName: 'Agreement Date',
      width: 156,
      valueFormatter: dateValueFormatter,
    },
  ];

  const filteredResults = results.filter(
    (result) =>
      result &&
      !selectedRows.some(
        ({ financialOrganizationId }) =>
          result.financialOrganizationId === financialOrganizationId
      )
  );
  // Filter out selected items then persist them on the bottom
  const rows: Array<TaskOrgSelectionRow> = [
    ...selectedRows,
    ...filteredResults,
  ].map((result, id) => {
    return {
      ...result,
      id,
    };
  });

  // Select all of the visible search results not currently selected
  const selectAllResults = (): void => {
    const selectedRowIds = selectedRows.map(
      ({ financialOrganizationId }) => financialOrganizationId
    );
    const unselectedResultRows = rows.filter(
      ({ financialOrganizationId }) =>
        !selectedRowIds.includes(financialOrganizationId)
    );

    setSelectedRows([...selectedRows, ...unselectedResultRows]);
  };

  // Deselect all selected rows
  const deselectAll = (): void => { 
    setSelectedRows([]);
  };

  // Set the array of org IDs in the form state
  useEffect(() => {
    setFieldValue(
      'financialOrganizationIds',
      selectedRows.map(({ financialOrganizationId }) => financialOrganizationId)
    );
  }, [selectedRows]);

  // Reset selected items when resetting form (the item lengths won't match)
  useEffect(() => {
    if (!financialOrganizationIds.length && selectedRows.length) {
      setSelectedRows([]);
    }
  }, [financialOrganizationIds]);

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item>
          <Button onClick={selectAllResults} size="small" variant="contained">
            Select All
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={deselectAll} size="small" variant="outlined">
            Deselect All
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        components={{
          NoRowsOverlay: SiraNoRowsOverlay,
        }}
        hideFooterSelectedRowCount // So the row vs. org selected count isn't confusing
        autoHeight
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={globalPaginationOptions}
        onPaginationModelChange={setGlobalPageSize}
        disableColumnMenu
        rows={rows}
      />
    </>
  );
}

export default TaskOrgSelection;
