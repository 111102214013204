import React, { useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';
import { parseISO } from 'date-fns';
import SiraCurrencyField from '../SiraCurrencyField';
import SiraDateField, { dateValidation } from '../SiraDateField';
import TransactionSubStepper, {
  TransactionSubStep,
} from '../../../page/TransactionSubStepper';
import StepButtonBar from '../../steps/StepButtonBar';
import { BeneficiariesClaimFiles } from './BeneficiariesClaimsForm.d';
import { addTransactionData } from '../../../page/TransactionReducer';
import SiraFileField from '../SiraFileField';
import { ClaimDocumentType } from '../../../api/BeneficiaryClaimsApi.d';
import { determineAgeGroupAtDeath } from '../../../app.constants';

import TaxFormDistributions from '../tax/TaxFormDistributions';
import { Account, AccountType } from '../../../api/AccountApi.d';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';

// import { AccountType } from '../../../api/AccountApi.d';

export const BENEFICIARIES_CLAIMS_INIT: BeneficiariesClaimFiles = {
  dateOfBirth: '',
  dateOfDeath: '',
  dateOfDeathAccountBalance: '',
  files: null,
  fileProperties: null,
};

export interface BeneficiariesClaimsFormProps {
  onSubmit?: Function;
  initialValues?: {};
  account: Account;
  accountOwner: AccountOwner;
}

const BENEFICIARIES_CLAIMS_SCHEMA = yup
  .object({
    dateOfDeath: dateValidation()
      .required()
      .label('Date of Death')
      .when('dateOfBirth', (dateOfBirth, schema) => {
        return dateOfBirth
          ? dateValidation(parseISO(dateOfBirth), new Date())
              .required()
              .label('Date of Death')
          : schema;
      }),
    dateOfDeathAccountBalance: yup.number().label('Amount').required(),
  })
  .shape({
    files: yup
      .array()
      .nullable(true)
      .min(1)
      .label('Death Certificate')
      .required(),
  });

function BeneficiariesClaimsFormContent() {
  return (
    <Box mt={5} mb={3} maxWidth={500}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SiraDateField name="dateOfDeath" label="Owners Date of Death" />
        </Grid>
      </Grid>
    </Box>
  );
}

function BeneficiariesClaimsBalanceStep() {
  return (
    <Box mt={5} mb={3} mr={3} maxWidth={500}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SiraCurrencyField
            allowNegative={false}
            name="dateOfDeathAccountBalance"
            label="Balance at Date of Death"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function BeneficiariesClaimsUploadStep() {
  const { values } = useFormikContext();
  const { dateOfDeath, dateOfDeathAccountBalance } =
    values as BeneficiariesClaimFiles;

  const onDropField = (docs) => {
    addTransactionData(
      {
        beneficiaryClaim: {
          files: docs,
          fileProperties: [
            {
              documentType: ClaimDocumentType.deathCert,
              description: 'Death Certificate',
            },
          ],
          dateOfDeath,
          dateOfDeathAccountBalance,
        },
      },
      false
    );
  };

  return (
    <Box mt={2} mb={3} maxWidth={500}>
      <Grid container spacing={3} sx={{ pt: 2 }}>
        <SiraFileField name="files" onDropField={onDropField} />
      </Grid>
    </Box>
  );
}

function BeneficiariesClaimsForm(props: BeneficiariesClaimsFormProps) {
  const { initialValues, onSubmit = () => {}, account, accountOwner } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [calculatedAge, setCalculatedAge] = useState(false as boolean);
  const [year, setYear] = useState('' as string);
  const [beneficiariesClaimsSubSteps, setBeneficiariesClaimsSubSteps] =
    useState([
      {
        label: 'Date of Death',
        stepContent: <BeneficiariesClaimsFormContent />,
        validationFields: ['dateOfDeath'],
      },
      {
        label: 'Claims Amount',
        stepContent: <BeneficiariesClaimsBalanceStep />,
        validationFields: ['dateOfDeathAccountBalance'],
      },
      {
        label: 'Upload Copy of Certified Original Death Certificate',
        stepContent: <BeneficiariesClaimsUploadStep />,
        validationFields: [],
      },
    ] as Array<TransactionSubStep>);
  
  const theme = useTheme();
  const classes = {
    root: {
      overflowX: 'auto' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1,
      },
    },
  };

  // using a useEffect here to basically insert a step into the transaction steps array after the date of death step
  useEffect(() => {
    if (beneficiariesClaimsSubSteps.length <= 3 && calculatedAge) {
      setBeneficiariesClaimsSubSteps([
        ...beneficiariesClaimsSubSteps.slice(0, 1),
        {
          label: 'Reconcile Distributions',
          stepContent: (
            <Box mt={5} mb={3} mr={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TaxFormDistributions
                    account={account}
                    accountOwner={accountOwner}
                  />
                </Grid>
              </Grid>
            </Box>
          ),
          validationFields: [],
        },
        ...beneficiariesClaimsSubSteps.slice(1),
      ]);
    } else if (beneficiariesClaimsSubSteps.length > 3 && !calculatedAge) {
      // remove the reconcile step in index pointion 1
      const newOptions =  beneficiariesClaimsSubSteps.slice(0, 1).concat(beneficiariesClaimsSubSteps.slice(2));
      setBeneficiariesClaimsSubSteps(newOptions);

    }
  }, [year]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={BENEFICIARIES_CLAIMS_SCHEMA}
      enableReinitialize
    >
      {({ isSubmitting, values }) => {
        const { dateOfDeath, dateOfBirth } = values as BeneficiariesClaimFiles;
        useEffect(() => {
          if (dateOfDeath) {
            const show = determineAgeGroupAtDeath(dateOfBirth, dateOfDeath).currentlyOrOver73;
            setCalculatedAge(show || account.accountType === AccountType.inheritedRoth || account.accountType === AccountType.inheritedTraditional);
            // stripping year of the death date to be used in the tax form 00-00-0000
          }
          setYear(new Date(dateOfDeath).getFullYear().toString());
        }, [dateOfDeath]);
        return (
          <Form>
            <Grid container wrap="nowrap" sx={classes.root}>
              <Grid item xs={12}>
                <TransactionSubStepper
                  steps={beneficiariesClaimsSubSteps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  explodeSteps={false}
                />
                <StepButtonBar isSubmitting={isSubmitting} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default BeneficiariesClaimsForm;
