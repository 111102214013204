import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgScheduledDistributionFilter(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="null"
          fill="currentColor"
          d="M 21 5.083 L 21 10.946 C 20.46 10.765 19.886 10.665 19.303 10.665 C 19.202 10.665 19.101 10.668 19 10.674 L 19 9.082 L 5 9.082 L 5 19.081 L 14.009 19.081 C 14.153 19.805 14.443 20.489 14.839 21.081 L 5 21.081 C 3.89 21.081 3 20.181 3 19.081 L 3.01 5.083 C 3.01 3.983 3.89 3.083 5 3.083 L 6 3.083 L 6 1.083 L 8 1.083 L 8 3.083 L 16 3.083 L 16 1.083 L 18 1.083 L 18 3.083 L 19 3.083 C 20.1 3.083 21 3.983 21 5.083 Z M 19 5.083 L 5 5.083 L 5 7.083 L 19 7.083 L 19 5.083 Z M 9 13.082 L 7 13.082 L 7 11.082 L 9 11.082 L 9 13.082 Z M 13 13.082 L 11 13.082 L 11 11.082 L 13 11.082 L 13 13.082 Z M 17 13.082 L 15 13.082 L 15 11.082 L 17 11.082 L 17 13.082 Z M 9 17.081 L 7 17.081 L 7 15.081 L 9 15.081 L 9 17.081 Z M 13 17.081 L 11 17.081 L 11 15.081 L 13 15.081 L 13 17.081 Z M 16.742 14.418 L 22.52 14.418 C 22.866 14.418 23.06 14.812 22.849 15.087 C 22.009 16.163 20.465 18.156 20.465 18.156 L 20.465 20.65 C 20.465 20.877 20.278 21.064 20.049 21.064 L 19.218 21.064 C 18.989 21.064 18.802 20.877 18.802 20.65 L 18.802 18.156 C 18.802 18.156 17.253 16.163 16.415 15.087 C 16.203 14.812 16.398 14.418 16.742 14.418 Z"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgScheduledDistributionFilter;
