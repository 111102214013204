import React, { ReactElement } from 'react';
import { Field, ErrorMessage, FieldInputProps, useField } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';

export interface SiraSelectItem {
  label: string;
  value: string;
  afterContent?: ReactElement;
}

export interface SiraSelectProps {
  items: SiraSelectItem[];
  errorString?: string;
}

// SiraSelectFieldProps is a type that includes all properties from SiraSelectProps and SelectProps
type SiraSelectFieldProps = SiraSelectProps & SelectProps;

export interface MateriralUISelectFieldProps extends FieldInputProps<string> {
  items: SiraSelectItem[];
  label?: string;
  errorString?: string;
  children: any;
}

const MaterialUISelectField: React.FC<MateriralUISelectFieldProps> = ({
  errorString = '',
  items = [],
  label = '',
  children,
  ...selectProps
}) => {
  const [field, meta] = useField<{}>(selectProps);
  const errorText = meta.error ? meta.error : '';
  const { afterContent = null } =
    items.find((item) => field.value === item.value) || {};

  return (
    <>
      <FormControl variant="outlined" error={Boolean(errorText)} fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          {...field}
          {...selectProps}
          label={label}
          value={field.value}
          inputProps={{
            'data-mui-type': 'select', // For targeting with Cypress since it uses a text field underneath
          }}
          error={Boolean(errorText)}
        >
          {children}
        </Select>
        <FormHelperText>{errorText || errorString}</FormHelperText>
      </FormControl>
      {afterContent && (
        <Box mt={1} width="1">
          {afterContent}
        </Box>
      )}
    </>
  );
};

const SiraSelectField: React.FC<SiraSelectFieldProps> = ({
  name = '',
  items = [],
  ...fieldProps
}) => {
  return (
    <Field
      {...fieldProps}
      name={name}
      as={MaterialUISelectField}
      errorString={<ErrorMessage name={name} />}
      items={items}
    >
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default SiraSelectField;
