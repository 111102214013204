import React from 'react';
import { Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import StepButtonBar from '../steps/StepButtonBar';
import SiraCheckbox from './SiraCheckbox';


export interface Review {
  verified?: boolean;
}

export const SIRA_VERIFY_INIT: Review = {
  verified: false,
};

export const SIRA_VERIFY_SCHEMA = {
  verified: yup
    .boolean()
    .oneOf([true], 'You must verify the information is accurate'),
};

interface SiraVerifyFormProps {
  onSubmit?: Function;
  submitName?: string;
  label?: string;
  complete?: boolean;
}

export const ReviewDocumentSchema = {};

function SiraVerifyForm({
  onSubmit = () => {},
  submitName,
  complete = false,
  label,
}: SiraVerifyFormProps) {
  return (
    <Formik
      initialValues={SIRA_VERIFY_INIT}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={yup.object(SIRA_VERIFY_SCHEMA)}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Box>
              <Field
                disabled={complete}
                name='verified'
                label={label}
                component={SiraCheckbox}
              />
            </Box>
            {!complete && (
              <StepButtonBar
                isSubmitting={isSubmitting}
                submitName={submitName}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SiraVerifyForm;
