import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Button, Grid, IconButton, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import { useUser } from '../../auth/useUser';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import {
  createApplicableFederalRate,
  deleteApplicableFederalRate,
  getApplicableFederalRates,
  updateApplicableFederalRate,
} from '../../api/SiteApi';
import { FederalRate } from '../../api/SiteApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import FederalRateForm, { FEDERAL_RATE_INIT } from '../form/FederalRateForm';
import TaxYearsFilterForm from '../form/TaxYearsFilterForm';
import { errorMessages } from '../../utils/errorhandling.utils';

// Sort function to sort by year then month, both descending
function rateSort(rate1: FederalRate, rate2: FederalRate) {
  const { year: a, month: c } = rate1;
  const { year: b, month: d } = rate2;

  if (a < b) return 1;
  if (a > b) return -1;
  if (c < d) return 1;
  if (c > d) return -1;

  return -1;
}

function ApplicableFederalRates() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [federalRates, setFederalRates] = useState([] as Array<FederalRate>);
  const [filteredFederalRates, setFilteredFederalRates] = useState(
    federalRates as Array<FederalRate>
  );
  const [rateToEdit, setRateToEdit] = useState({} as FederalRate);
  const [isCreating, setIsCreating] = useState(false as boolean);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const taxYearFilterOptions = [
    ...new Set(federalRates.map(({ year }) => year.toString())),
  ];
  const isEditing = Boolean(rateToEdit.applicableFederalRateID);

  // Add a new rate to list in memory
  const addRate = (rateToAdd: FederalRate): void => {
    setFederalRates([rateToAdd, ...federalRates]);
  };

  // Remove a rate from list in memory based on composite ID
  const removeRate = (rateToDelete: FederalRate): void => {
    const ratesToPatch = federalRates.filter(
      ({ applicableFederalRateID: { year, month } }) => {
        return !(
          year === rateToDelete.applicableFederalRateID.year &&
          month === rateToDelete.applicableFederalRateID.month
        );
      }
    );

    setFederalRates(ratesToPatch);
  };

  // Update an existing rate from list in memory
  const updateRate = (rateToUpdate: FederalRate): void => {
    const ratesToPatch = federalRates.filter(
      ({ applicableFederalRateID: { year, month } }) =>
        !(year === rateToUpdate.year && month === rateToUpdate.month)
    );

    setFederalRates([...ratesToPatch, rateToUpdate]);
  };

  // GET all rates for all tax years
  const getAllFederalRates = async () => {
    setIsLoading(true);

    await getApplicableFederalRates(user.token)
      .then((res) => {
        if (isMounted) {
          setFederalRates(res.data);
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error fetching applicable federal rates'
        );
      });

    setIsLoading(false);
  };

  // POST a new rate and update the list
  const createFederalRate = async (rate: FederalRate) => {
    await createApplicableFederalRate(rate, user.token)
      .then((res) => {
        const { data: rateToAdd } = res;

        if (isMounted) {
          addRate(rateToAdd);
          setIsCreating(false);
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error creating applicable federal rate'
        );
      });
  };

  // PUT a single rate and update the list
  const updateFederalRate = async (rateToUpdate: FederalRate) => {
    await updateApplicableFederalRate(
      rateToUpdate,
      rateToUpdate.year,
      rateToUpdate.month,
      user.token
    )
      .then(() => {
        if (isMounted) {
          updateRate(rateToUpdate);
          setRateToEdit({} as FederalRate);
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error updating applicable federal rate'
        );
      });
  };

  // DELETE a specific rate and update the list
  const deleteFederalRate = async (rateToDelete: FederalRate) => {
    await deleteApplicableFederalRate(
      rateToDelete.year,
      rateToDelete.month,
      user.token
    )
      .then(() => {
        if (isMounted) {
          removeRate(rateToDelete);
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error deleting applicable federal rate'
        );
      });
  };

  useEffect(() => {
    if (user.token) {
      getAllFederalRates();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token]);

  const columns = [
    {
      field: 'year',
      headerName: 'Year',
      align: 'center',
      headerAlign: 'center',
      minWidth: 80,
      flex: 1,
      sortable: false,
    },
    {
      field: 'month',
      headerName: 'Month',
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        // Convert a 1-based index to Month long name
        return format(new Date(row.year, row.month - 1, 1), 'MMMM');
      },
    },
    {
      field: 'annual',
      headerName: 'Annual',
      align: 'right',
      headerAlign: 'right',
      minWidth: 90,
      flex: 1,
    },
    {
      field: 'semiAnnual',
      headerName: 'Semi-Annual',
      align: 'right',
      headerAlign: 'right',
      minWidth: 126,
      flex: 1,
    },
    {
      field: 'quarterly',
      headerName: 'Quarterly',
      align: 'right',
      headerAlign: 'right',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'monthly',
      headerName: 'Monthly',
      align: 'right',
      headerAlign: 'right',
      minWidth: 90,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const [isDeleting, setIsDeleting] = useState(false as boolean);

        return (
          <>
            <Grid container justifyContent="flex-end">
              <IconButton
                disabled={isCreating || isLoading}
                data-testid="edit-federal-rate"
                size="small"
                aria-label="Edit Applicable Federal Rate"
                onClick={() => {
                  setRateToEdit(row);
                  setIsCreating(false);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                disabled={isDeleting}
                data-testid="delete-federal-rate"
                size="small"
                aria-label="Delete Applicable Federal Rate"
                onClick={async () => {
                  setIsDeleting(true);
                  await deleteFederalRate(row as FederalRate);
                  setIsDeleting(false);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
  ] as GridColDef[];

  const rows = filteredFederalRates
    .map((result, id) => {
      return {
        ...result,
        id,
      };
    })
    .sort((a, b) => rateSort(a, b));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TaxYearsFilterForm
          items={federalRates}
          options={taxYearFilterOptions}
          onChange={setFilteredFederalRates}
        />
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <DataGrid
            components={{
              NoRowsOverlay: SiraNoRowsOverlay,
            }}
            disableColumnMenu
            hideFooter
            autoHeight
            columns={columns}
            rows={rows}
          />
        )}
      </Grid>

      <Grid item>
        <Button
          disabled={isCreating || isEditing}
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setRateToEdit({});
            setIsCreating(true);
          }}
        >
          Add Federal Rate
        </Button>
      </Grid>

      {(isCreating || isEditing) && (
        <Grid item xs={12}>
          <FederalRateForm
            initialValues={{ ...FEDERAL_RATE_INIT, ...rateToEdit }}
            onSubmit={isCreating ? createFederalRate : updateFederalRate}
            onCancel={() => {
              setRateToEdit({});
              setIsCreating(false);
            }}
            isEditing={isEditing}
            scrollToOnMount
          />
        </Grid>
      )}
    </Grid>
  );
}
export default ApplicableFederalRates;
