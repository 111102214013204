import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { useGlobalContext } from '../../../auth/useGlobalContext';

import StateField from '../StateField';

import { StateWithholdingPercentType } from '../../../api/DistributionApi.d';

import SiraSwitchField from '../SiraSwitchField';
import { useUser } from '../../../auth/useUser';
import { getDistributionStateWithholding } from '../../../api/DistributionApi';
import InfoPopover from '../../InfoPopover';
import { DistributionAmountFormData } from '../distribution/DistributionAmountForm.d';
import StateWithholdingFields from '../distribution/StateWithholdingFields';

function DistributionStateField(props) {
  const { accountOwner } = props;
  const { user } = useUser();
  const { organization } = useGlobalContext();
  const { stateTaxIDS } = organization;
  const { values, setFieldValue } = useFormikContext();
  const { allowNoneState, withholdingState, stateWithholdingPercent } =
    values as DistributionAmountFormData;
  const [withholdingLanguage, setWithholdingLanguage] = useState('' as string);
  // Check for which states have withholding enabled based on state tax ID settings
  const validStates = stateTaxIDS
    .filter(({ stateWithholding }) => stateWithholding)
    .map(({ state }) => state);
  const [withholdingValues, setWithholdingValues] = useState(false);

  async function onWithholdingStateChange() {
    await getDistributionStateWithholding(
      values as DistributionAmountFormData,
      user.token,
      user
    )
      .then((res) => {
        const {
          percent = 0,
          canSpecifyPercent = StateWithholdingPercentType.disabled,
          canSpecifyAmount = false,
        } = res.data;

        // if state is CA federal Withholding/10%
        // if state is VA federal Withholding/3%
        const statesAndPercent = {
          CA: 10,
          VT: 30,
        };

        setWithholdingLanguage(res.data.withholdingLanguage);

        if (
          canSpecifyAmount &&
          canSpecifyPercent === StateWithholdingPercentType.disabled &&
          percent === 0
        ) {
          setWithholdingValues(true);
        } else {
          setWithholdingValues(false);
        }

        // check to see if state has a value in the object
        if (statesAndPercent[withholdingState]) {
          // run calculation with state value
          const percent = statesAndPercent[withholdingState];
          const { federalWithholdingPercent } = values as any;
          const amount = (parseInt(federalWithholdingPercent) * percent) / 100;
          setFieldValue('stateWithholdingPercent', amount);
        } else {
          if (
            validStates.includes(withholdingState) &&
            (stateWithholdingPercent === '' || stateWithholdingPercent === '0')
          ) {
            setFieldValue('stateWithholdingPercent', percent);
          }
        }
      })
      .catch(() => {
        setFieldValue('stateWithholdingPercent', '0');
      });
  }

  const checkstateWithholding = (state) => {
    return state !== 'XX' ? state : '';
  };

  useEffect(() => {
    // Check for user-entered value on mount and don't overwrite it

    if (allowNoneState) {
      const stateValue = validStates.includes(accountOwner.state)
        ? accountOwner.state
        : 'XX';
      setFieldValue('withholdingState', stateValue);
      setFieldValue('stateWithholdingPercent', '0');
    } else {
      setFieldValue(
        'withholdingState',
        (withholdingState === accountOwner.state &&
          validStates.includes(withholdingState)) ||
          (withholdingState === 'XX' && validStates.includes(withholdingState))
          ? accountOwner.state
          : checkstateWithholding(withholdingState)
      );

      if (validStates.includes(withholdingState)) {
        onWithholdingStateChange();
      } else {
        setWithholdingLanguage('');
      }
    }
  }, [allowNoneState]);

  useEffect(() => {
    if (validStates.includes(withholdingState) && !allowNoneState) {
      onWithholdingStateChange();
    } else {
      setWithholdingLanguage('');
    }
  }, [withholdingState]);

  return (
    <Box mb={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="div">
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <SiraSwitchField
                  name="allowNoneState"
                  checked={allowNoneState}
                />
              </Grid>
              <Grid item>Do Not Withhold</Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StateField
            name="withholdingState"
            label="State"
            // Filter out the state field options based on which ones support withholding
            filterFunction={({ value }) => validStates.includes(value)}
            allowNoneState={allowNoneState}
            disabled={allowNoneState}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Enter the {withholdingValues ? 'amount' : 'percentage'} you will
            withhold for state taxes.
            {withholdingLanguage && <InfoPopover text={withholdingLanguage} />}
          </Typography>
        </Grid>
        <StateWithholdingFields />
      </Grid>
    </Box>
  );
}

export default DistributionStateField;
