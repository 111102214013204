import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import { useGlobalContext } from '../auth/useGlobalContext';
import {
  createTaxFormZipFile1099,
  createTaxFormZipFile5498,
  taxReportFileExport,
} from '../api/TaxFormApi';
import { useUser } from '../auth/useUser';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import SiraSelectField, {
  SiraSelectItem,
} from '../components/form/SiraSelectField';
import { Tax5498FormType, TaxFormType } from '../api/TaxFormApi.d';
import SiraTransferList from './SiraTransferList';
import SiraTextField from '../components/form/SiraTextField';
import StepButtonBar from '../components/steps/StepButtonBar';
import SiraDateField from '../components/form/SiraDateField';
import SiraSwitchField from '../components/form/SiraSwitchField';

export const formType = [
  { label: 'All 1099', value: 'Q,R,SA' },

  { label: '1099-Q', value: 'Q' },
  { label: '1099-R', value: 'R' },
  { label: '1099-SA', value: 'SA' },
  { label: 'All 5498', value: 'IRA,ESA,HSA' },

  { label: '5498', value: Tax5498FormType.IRA },
  { label: '5498-ESA', value: Tax5498FormType.ESA },
  { label: '5498-SA', value: Tax5498FormType.HSA },
];

const downloadTaxFormFileType = [
  { label: 'Text', value: 'CSV' },
  { label: 'Excel', value: 'XLSX' },
  { label: 'XML', value: 'XML' },
];

export interface IndexDefinition {
  fileType: string;
  delimiter: any;
  exportFields?: Array<{
    exportRow: string;
    order: string;
    customDisplayName?: string;
  }>;
  textExportDisplayHeader?: boolean;
  excludeSentBeforeDate?: string;
  taxFormType?: string;
  taxYear?: string;
}

export interface DownloadTaxForm549819 {
  taxFormType: TaxFormType;
  indexDefinition: IndexDefinition;
}

const DownloadTaxForm = () => {
  const theme = useTheme();
  const { user } = useUser();
  const { addGlobalMessage, organization, taxFileIndex, taxYears } =
    useGlobalContext();
  const [taxYearDropdown, setTaxYearDropdown] = useState(
    [] as Array<SiraSelectItem>
  );
  const [initiaData, setInitialData] = useState<IndexDefinition>({
    taxFormType: '',
    taxYear: '',
    fileType: '',
    delimiter: '',
    textExportDisplayHeader: false,
    excludeSentBeforeDate: '',
  });
  const [loadingTaxSummary, setLoadingTaxSummary] = useState<boolean>(false);
  const [filedIndex, setFieldIndex] = useState<any>([]);
  const [fieldIndexOrder, setFieldIndexOrder] = useState<any>([]);
  const [delimeter, setDelimeter] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>('XML');
  const [txtOrExcel, setTxtOrExcel] = useState<boolean>(false);
  const [chosen, setChosen] = useState<any>([]);
  const { financialOrganizationId } = organization;
  const classes = {
    root: {
      overflowX: 'auto' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1,
      },
    },
  };

  useEffect(() => {
    if (taxYears) {
      setTaxYearDropdown(
        taxYears.map((availableYears) => ({
          value: availableYears.taxYear,
          label: availableYears.taxYear.toString(),
        }))
      );
    }
  }, [taxYears]);

  useEffect(() => {
    // transform the taxFileIndex to an array of objects
    // this is to make it easier to work with the data
    // in the transfer list component
    if (Object.entries(taxFileIndex).length > 0) {
      const index = Object.entries(taxFileIndex).map(([key, value]) => {
        const objectValue = value as any;
        return {
          enumValue: key,
          ...objectValue,
        };
      });

      setChosen(index.filter((item) => item.enumValue === 'FILE_NAME'));
      // going to pulll FILE_NAME from the index add it to chosen and sort it alphabetically
      setFieldIndex(
        index
          .filter((item) => item.enumValue !== 'FILE_NAME')
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
      );
    }
  }, [taxFileIndex]);

  const trigger1099SFTP = async (data, taxYear, taxFormType) => {
    await createTaxFormZipFile1099(
      data,
      taxYear,
      taxFormType,
      financialOrganizationId,
      user.token,
      user
    )
      .then(() => {
        setLoadingTaxSummary(false);
        addGlobalMessage(
          'Your 1099 Tax Form file is being created. You will receive an email when the file is available to download on your SFTP site.',
          {
            severity: 'success',
          }
        );
      })
      .catch(() => {
        setLoadingTaxSummary(false);

        addGlobalMessage('Error downloading 1099 Tax Forms');
      });
  };

  const trigger5498SFTP = async (data, taxYear, taxFormType) => {
    await createTaxFormZipFile5498(
      data,
      taxYear,
      taxFormType,
      financialOrganizationId,
      user.token,
      user
    )
      .then(() => {
        setLoadingTaxSummary(false);

        addGlobalMessage('Your 5498 Tax Form file is being created. You will receive an email when the file is available to download on your SFTP site.', {
          severity: 'success',
        });
      })
      .catch(() => {
        setLoadingTaxSummary(false);

        addGlobalMessage('Error downloading 1099/5498 Tax Forms');
      });
  };

  const mergeData = async (data) => {
    const indexDefinition = {
      fileType,
      delimiter: data.delimiter,
      exportFields: fieldIndexOrder.map((field, index) => {
        return {
          exportRow: field.enumValue,
          order: index,
          customDisplayName: field.displayName,
        };
      }),
      textExportDisplayHeader: data.textExportDisplayHeader,
      excludeSentBeforeDate: data.excludeSentBeforeDate,
    };

    if (['Q,R,SA', 'R', 'Q', 'SA'].includes(data.taxFormType)) {
      trigger1099SFTP(
        indexDefinition,
        data.taxYear,
        data.taxFormType === 'Q,R,SA' ? '' : data.taxFormType
      );
    } else {
      trigger5498SFTP(indexDefinition, data.taxYear, data.taxFormType);
    }
  };

  // useThis to retrieve the export file data and map it to the initial field values
  const retreiveExportFile = async () => {
    await taxReportFileExport(user.organizationId, user.token)
      .then((response) => {
        const exportFileData: IndexDefinition = response.data;
        if (exportFileData) {
          setInitialData({
            ...exportFileData,
          });
          setFileType(exportFileData.fileType);
          setDelimeter(exportFileData.fileType === 'CSV');
          setTxtOrExcel(
            exportFileData.fileType === 'XLSX' ||
              exportFileData.fileType === 'CSV'
          );
          setFieldIndexOrder(exportFileData.exportFields);
          // grab all the fields and set the chosen and pass customdisplyname to displayname
          const chosenFields = exportFileData.exportFields.map((field) => {
            return {
              ...field,
              displayName: field.customDisplayName || field.exportRow,
            };
          });
          setChosen(chosenFields);
        }
        setLoadingTaxSummary(false);
      })
      .catch(() => {
        setLoadingTaxSummary(false);
        addGlobalMessage('Error downloading Tax Forms');
      });
  };

  useEffect(() => {
    if (user.organizationId) {
      setLoadingTaxSummary(true);
      retreiveExportFile();
    }
  }, [user.organizationId, user.token]);
  return (
    <Layout>
      <FormPaper>
        <>
          <Typography color="secondary" variant="h1">
            Download Tax Form
          </Typography>
          <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
            <Grid container wrap="nowrap" sx={classes.root}>
              <Grid item xs={12}>
                <Formik
                  // Datepicker is only going to accept date in taxYear but we translate it to an integer on submit
                  initialValues={{
                    ...initiaData,
                  }}
                  onSubmit={(data) => {
                    setLoadingTaxSummary(true);
                    mergeData(data);
                  }}
                >
                  {({ setFieldValue, values }) => {
                    useEffect(() => {
                      setFieldValue('taxFormType', initiaData.taxFormType);
                      setFieldValue('taxYear', initiaData.taxYear);
                      setFieldValue('fileType', initiaData.fileType);
                      setFieldValue('delimiter', initiaData.delimiter);
                      setFieldValue(
                        'textExportDisplayHeader',
                        initiaData.textExportDisplayHeader
                      );
                      setFieldValue(
                        'excludeSentBeforeDate',
                        initiaData.excludeSentBeforeDate
                      );
                    }, [initiaData]);
                    return (
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          pt={1}
                          pb={2}
                        >
                          <Grid item xs={12} mt={2}>
                            <SiraSelectField
                              items={formType}
                              name="taxFormType"
                              label="Select Tax Form Type"
                            />
                          </Grid>
                          <Grid item xs={12} mt={2}>
                            <SiraSelectField
                              name="taxYear"
                              label="Tax Year"
                              items={taxYearDropdown}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} mt={2}>
                            <SiraSelectField
                              name="fileType"
                              label="Index File Type"
                              items={downloadTaxFormFileType}
                              fullWidth
                              onChange={(e) => {
                                const value = e.target.value as string; // get the value of the selected item
                                setDelimeter(value === 'CSV');
                                setTxtOrExcel(
                                  value === 'XLSX' || value === 'CSV'
                                );
                                setFileType(value);
                                setFieldValue('fileType', value);
                              }}
                            />
                          </Grid>

                          {delimeter ? (
                            <Grid item xs={12} mt={2}>
                              <SiraTextField
                                name="delimiter"
                                label="Delimiter"
                                fullWidth
                                helperText="Enter the delimiter you would like to use. Note: for a tab delimiter, hold down the Alt key and enter 09."
                              />
                            </Grid>
                          ) : null}

                          {txtOrExcel ? (
                            <Grid item xs={12}>
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                                wrap="nowrap"
                              >
                                <Grid item>
                                  <Typography variant="h6">Exclude</Typography>
                                </Grid>
                                <Grid item>
                                  <SiraSwitchField
                                    name="textExportDisplayHeader"
                                    checked={values.textExportDisplayHeader}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography variant="h6">
                                    Include header row in index file
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}
                          <Grid item xs={12} mt={1}>
                            <SiraDateField
                              name="excludeSentBeforeDate"
                              label="Exclude forms sent before"
                            />
                          </Grid>
                          <Grid item xs={12} mt={2}>
                            <Typography variant="h6" color="secondary">
                              Select the fields to include in your index file.
                              Drag and drop the selected fields to set the order
                              they will appear in the index file.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} mt={2}>
                            <SiraTransferList
                              choose={filedIndex}
                              choosen={chosen}
                              fieldIndexSorting={setFieldIndexOrder}
                            />
                          </Grid>
                          <Grid item xs={12} mt={2}>
                            <StepButtonBar isSubmitting={loadingTaxSummary} />
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </>
      </FormPaper>
    </Layout>
  );
};

export default DownloadTaxForm;
