import React from 'react';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';

export const RmdWarning = () => {
    return (
        <Alert severity="warning">
            <AlertTitle>Warning: </AlertTitle>
            Once this option is selected you <strong>can not</strong> switch again!
        </Alert>
    )
}