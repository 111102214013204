import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Grid,
  IconButton,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';

import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import {
  accountTransactionTypeNames,
  globalPaginationOptions,
} from '../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import { dateValueFormatter } from '../utils/DataGrid.utils';
import { useUser } from '../auth/useUser';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';

import {
  AccountStatus,
  AccountType,
  TransactionStatus,
  TransactionType,
} from '../api/AccountApi.d';
import AccountTypesSelectField from '../components/form/AccountTypesSelectField';

import {
  dahsboardTransactionFactory,
  getTransactionDocument,
  linkToTransaction,
} from '../utils/transaction.utils';
import {
  getDashboardAccounts,
  getDashboardBeneficiaries,
  getDashboardContributions,
  getDashboardDistributions,
  getDashboardRecurringDistributions,
  getDashboardTransferRequests,
} from '../api/OrganizationApi';
import { createFileBlobFromBase64 } from '../utils/App.utils';
import ObscuredTaxId from '../components/ObscuredTaxId';
import { TaxIdFormat } from './AccountSavedSearch';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import { errorMessages } from '../utils/errorhandling.utils';

// Convert the 2-dimensional Promise.all arrays into one, flat array
function flattenTransactions(data: Array<any>) {
  return data.reduce((acc: Array<any>, cur: Array<any>) => {
    acc.push(...cur);
    return acc;
  }, []);
}

function ReportsPendingTransaction() {
  let isMounted = true;
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage, organization } = useGlobalContext();

  const headerAccountName =
    organization.accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';

  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [accountTypeFilter, setAccountTypeFilter] = useState(
    [] as Array<AccountType>
  );
  const [filteredRows, setFilteredRows] = useState([] as Array<any>);
  const [pendingReviewTransactions, setPendingReviewTransactions] = useState(
    [] as Array<any>
  );

  const classes = {
    transactionLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  // Spit out a promise for every org transaction call
  function transactionsPromiseFactory(
    getMethod: Function,
    status: any,
    transactionType: TransactionType,
    accountStatus?: any
  ): Promise<any> {
    return getMethod(
      user.organizationId,
      status,
      user.token,
      accountStatus,
      user
    )
      .then((res) => {
        return dahsboardTransactionFactory(res.data, transactionType);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            `Error fetching ${status} org ${accountTransactionTypeNames[
              transactionType
            ].toLowerCase()}s`
        );
      });
  }

  // Get every org transaction in PENDING or AWAITING_SIGNATURE status
  async function getAndSetAllOrgWIPTransactions(): Promise<void> {
    setIsLoading(true);

    // Group all promises as one
    const wipTransactions: Array<any> = await Promise.all([
      transactionsPromiseFactory(
        getDashboardAccounts,
        [TransactionStatus.review],
        TransactionType.account
      ),
      transactionsPromiseFactory(
        getDashboardBeneficiaries,
        [TransactionStatus.review],
        TransactionType.beneficiaries,
        [AccountStatus.closed, AccountStatus.open]
      ),
      transactionsPromiseFactory(
        getDashboardContributions,
        [TransactionStatus.review],
        TransactionType.contribution,
        [AccountStatus.closed, AccountStatus.open]
      ),
      transactionsPromiseFactory(
        getDashboardDistributions,
        [TransactionStatus.review],
        TransactionType.distribution
      ),
      transactionsPromiseFactory(
        getDashboardRecurringDistributions,
        [TransactionStatus.review],
        TransactionType.recurringDistribution
      ),
      transactionsPromiseFactory(
        getDashboardTransferRequests,
        [TransactionStatus.review],
        TransactionType.transfer
      ),
    ]).then(flattenTransactions);

    // Filter by status
    const pendingReview = wipTransactions.map((transaction, id) => ({
      ...transaction,
      id,
    }));

    setIsLoading(false);

    if (isMounted) {
      setPendingReviewTransactions(pendingReview);
      setFilteredRows(pendingReview);
    }
  }

  // Apply all filters to the supplied rows
  const applyFilters = (rowsToFilter) => {
    const matchingRows: Array<any> = accountTypeFilter.length
      ? rowsToFilter.filter(({ accountType }) =>
          accountTypeFilter.includes(accountType)
        )
      : rowsToFilter;

    setFilteredRows(matchingRows);
  };

  // Handle transcation button click based on status or type
  const handleItemClick = (row): void => {
    const { accountId = '', accountOwnerId = '' } = row;

    linkToTransaction(accountOwnerId, accountId, row, navigate);
  };

  const openPdf = async (transaction: any): Promise<void> => {
    await getTransactionDocument(transaction, user)
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  };

  useEffect(() => {
    if (user.token && user.organizationId) {
      getAndSetAllOrgWIPTransactions();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  const columns = [
    {
      field: 'transactionType',
      headerName: 'Type',
      width: 180,
      valueGetter: (params: GridValueGetterParams) => {
        const { value = '', row = {} } = params;
        const typeId = String(value);

        return value === TransactionType.account
          ? `${accountTransactionTypeNames[typeId] || value} - ${getAppConstant(
              ConstantsMappingKey.accountType,
              row.accountType
            )}`
          : accountTransactionTypeNames[typeId] || value;
      },
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        return (
          <Button
            data-qa="pending-transaction-result_button"
            onClick={() => {
              handleItemClick(row);
            }}
            sx={classes.transactionLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              <>{value}</>
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 110,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueGetter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 150,
    },
    {
      field: 'fullName',
      headerName: 'Owner',
      width: 200,
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 250,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
  ] as GridColDef[];

  // Optional column to show review button(s) in PENDING_REVIEW
  const reviewPdfColumn: GridColDef = {
    field: 'pdfReview',
    headerName: ' ',
    width: 85,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      const { row = {} } = params;
      const [isFetchingDocument, setIsFetchingDocument] = useState(
        false as boolean
      );

      return (
        <>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item>
              <IconButton
                disabled={isFetchingDocument}
                color="primary"
                size="small"
                data-testid={`open-review-transaction-pdf_${row.transactionId}`}
                aria-label="Open pdf document"
                onClick={async () => {
                  setIsFetchingDocument(true);
                  await openPdf(row);
                  setIsFetchingDocument(false);
                }}
              >
                <PictureAsPdfIcon />
              </IconButton>
            </Grid>
          </Grid>
        </>
      );
    },
  };

  useEffect(() => {
    applyFilters(pendingReviewTransactions);

    return () => {
      isMounted = false;
    };
  }, [accountTypeFilter]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Reports and Statistics
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Pending Transactions
          </Typography>

          <AccountTypesSelectField
            onChange={setAccountTypeFilter}
            accountTypes={accountTypeFilter}
          />

          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <Box p={2}>
              <Box mt={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DataGrid
                      slots={{
                        noRowsOverlay: SiraNoRowsOverlay,
                      }}
                      disableColumnMenu
                      initialState={{
                        pagination: {
                          paginationModel: { pageSize: globalPageSize },
                        },
                      }}
                      pageSizeOptions={globalPaginationOptions}
                      onPaginationModelChange={setGlobalPageSize}
                      autoHeight
                      columns={[reviewPdfColumn, ...columns]}
                      rows={filteredRows}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default ReportsPendingTransaction;
