import axios from 'axios';
import { getYear, parseISO } from 'date-fns';
import format from 'string-format';

import { prepareBinaryJson, removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  Distribution,
  DistributionResponse,
  DistributionCalculateParams,
  WithholdingReqParams,
  StateWithholdingReqParams,
  StateWithholdingResponse,
  WithholdingResponse,
  DistributionAllocationResponse,
  DistributionAllocation,
  DistributionStatusResponse,
  DistributionStatusState,
  WorkflowDates,
  DistributionsResponse,
  NetIncomeAttributableRequest,
  NetIncomeAttributableResponse,
  DistributionImportColumnsResponse,
} from './DistributionApi.d';
import { DistributionAmountFormData } from '../components/form/distribution/DistributionAmountForm.d';
import { getConstantActiveTaxYear } from '../app.constants';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;

const distributionUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/distribution/{0.distributionId}';
const distributionsUrl = '/api/org/{0.orgId}/distribution';
const activeDistributionsUrl = '/api/org/{0.orgId}/activeDistributions';
const distributionWithholdingCalculateUrl = '/distribution/calculate';
const distributionFederalWithholdingUrl = '/api/distribution/withholding';
const distributionStateWithholdingUrl = '/distribution/stateWithholding';
const distributionAllocationUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/distribution/{0.distributionId}/allocations';
const distributionDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/distribution/{0.distributionId}/document';
const distributionStatusUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/distribution/{0.distributionId}/status';
const netIncomeAttributionUrl = '/api/distribution/netIncomeAttributable';
const distributionDeleteUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/distribution/{0.distributionId}';
const distributionImportColumsUrl =
  '/api/org/{0.orgId}/upload/distributionImportColumns';
const distributionImportUrl = '/api/org/{0.orgId}/upload/distributionImport';
const distributions =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/distribution';

function netIncomeAttributableRequestFactory(
  data: any
): NetIncomeAttributableRequest {
  const {
    niaExcessContribution = '',
    niaPreContributionAccountBalance = '',
    niaCurrentAccountBalance = '',
    niaTotalContributionsDuringComputationPeriod = '',
    niaTotalDistributionsDuringComputationPeriod = '',
  } = data;

  return {
    niaExcessContribution,
    niaPreContributionAccountBalance,
    niaCurrentAccountBalance,
    niaTotalContributionsDuringComputationPeriod,
    niaTotalDistributionsDuringComputationPeriod,
  };
}

// Send only the one-time distribution fields that the API accepts
function distributionRequestFactory(distribution) {
  const {
    penaltyAmount,
    niaTotalContributionsDuringComputationPeriod,
    federalWithholdingPercent,
    netIncomeAttributable,
    federalTaxElectionType,
    filingStatus,
    toAccountType,
    withholdingState,
    netAmount,
    distributionStatus,
    federalWithholdingAmount,
    toAccountNumber,
    toFinancialOrganization,
    netAmountEntered,
    stateWithholdingAmount,
    accountId,
    signedDate,
    additionalStateWithholding,
    effectiveDate,
    niaTotalDistributionsDuringComputationPeriod,
    toAccountTypeName,
    closingAccount,
    stateWithholdingPercent,
    grossAmount,
    distributionId,
    distributionMethod,
    niaExcessContribution,
    activeDate,
    niaCurrentAccountBalance,
    distributionReason,
    totalAmount,
    niaPreContributionAccountBalance,
    recurringDistributionId,
    scheduledDistributionId,
    payableTo,
    routingNumber,
    excludeFromRmd,
  } = distribution;

  return {
    // amounts are XOR on the API so just pick one here
    netAmount: !totalAmount ? netAmount : '',
    totalAmount,
    penaltyAmount,
    niaTotalContributionsDuringComputationPeriod,
    federalWithholdingPercent,
    netIncomeAttributable,
    federalTaxElectionType,
    filingStatus,
    toAccountType,
    withholdingState,
    distributionStatus,
    federalWithholdingAmount,
    toAccountNumber,
    toFinancialOrganization,
    netAmountEntered,
    stateWithholdingAmount,
    accountId,
    signedDate,
    additionalStateWithholding,
    effectiveDate,
    niaTotalDistributionsDuringComputationPeriod,
    toAccountTypeName,
    closingAccount,
    stateWithholdingPercent,
    grossAmount,
    distributionId,
    distributionMethod,
    niaExcessContribution,
    activeDate,
    niaCurrentAccountBalance,
    distributionReason,
    niaPreContributionAccountBalance,
    recurringDistributionId,
    scheduledDistributionId,
    payableTo,
    routingNumber,
    excludeFromRmd,
  };
}

function distributionCalculateRequestFactory(
  distribution: DistributionAmountFormData
): DistributionCalculateParams {
  const {
    totalAmount,
    netAmount,
    additionalStateWithholding,
    federalWithholdingPercent,
    penaltyAmount,
    stateWithholdingPercent,
  } = distribution;

  return {
    // amounts are XOR on the API so just pick one here
    netAmount: !totalAmount ? netAmount : '',
    totalAmount,
    additionalStateWithholding,
    federalWithholdingPercent: federalWithholdingPercent || '0',
    penaltyAmount,
    stateWithholdingPercent: stateWithholdingPercent || '0',
  };
}

function federalWithholdingReqParamsFactory(
  distribution: DistributionAmountFormData
): WithholdingReqParams {
  const { totalAmount, netAmount, filingStatus, totalIncome, effectiveDate } =
    distribution;

  return {
    distribution: totalAmount || netAmount,
    filingStatus,
    income: totalIncome,
    taxYear: getYear(parseISO(effectiveDate)).toString(),
  };
}

function stateWithholdingReqParamsFactory(
  distribution: DistributionAmountFormData
): StateWithholdingReqParams {
  const {
    withholdingState,
    effectiveDate,
    federalWithholdingPercent = 0,
  } = distribution;

  return {
    state: withholdingState,
    federalWithholdingPercent,
    taxYear: effectiveDate ? getYear(parseISO(effectiveDate)).toString() : getConstantActiveTaxYear(),
  };
}

export async function getAccountDistributions(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any,
  status?: string | Array<string>
): Promise<DistributionsResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${distributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    distributionId: '',
  })}`;

  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountDistribution(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  distributionId: string,
  token: string,
  user: any
): Promise<DistributionResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${distributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    distributionId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDistributionWithholdingAmounts(
  data: DistributionAmountFormData,
  token: string,
  user: any
): Promise<DistributionResponse> {
  const url = `${urlBase}${setBaseUrl(
    user
  )}${distributionWithholdingCalculateUrl}`;
  const params = distributionCalculateRequestFactory(data);

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDistributionFederalWithholding(
  data: DistributionAmountFormData,
  token: string
): Promise<WithholdingResponse> {
  const url = `${urlBase}${distributionFederalWithholdingUrl}`;
  const params = federalWithholdingReqParamsFactory(data);

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDistributionStateWithholding(
  data: DistributionAmountFormData,
  token: string,
  user
): Promise<StateWithholdingResponse> {
  const url = `${urlBase}${setBaseUrl(user)}${distributionStateWithholdingUrl}`;
  const params = stateWithholdingReqParamsFactory(data);

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createDistribution(
  data: Distribution,
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${distributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    distributionId: '',
  })}`;

  const dataNorm = removeEmptyStrings(distributionRequestFactory(data));

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateDistribution(
  data: Distribution,
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${distributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    distributionId: data.distributionId,
  })}`;

  const dataNorm = removeEmptyStrings(distributionRequestFactory(data));

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteDistribution(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  distributionId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${distributionDeleteUrl}`,
    {
      orgId,
      accountId,
      accountOwnerId,
      distributionId,
    }
  )}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function saveDistributionAllocations(
  data: Array<DistributionAllocation>,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  distributionId: string,
  token: string,
  user: any
): Promise<DistributionAllocationResponse> {
  const dataNorm = data.map((alloc) => removeEmptyStrings(alloc));
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${distributionAllocationUrl}`,
    {
      orgId,
      accountId,
      accountOwnerId,
      distributionId,
    }
  )}`;

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDistributionDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  distributionId: string,
  token: string,
  versionID: string = '',
  user: any
) {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${distributionDocumentUrl}`,
    {
      orgId,
      accountOwnerId,
      accountId,
      distributionId,
    }
  )}`;

  const params = removeEmptyStrings({ versionID });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createDistributionDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  distributionId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${distributionDocumentUrl}`,
    {
      orgId,
      accountOwnerId,
      accountId,
      distributionId,
    }
  )}`;

  return axiosInstance.post(url, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeDistributionStatus(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  distributionId: string,
  state: DistributionStatusState,
  workflowDates: WorkflowDates = {},
  token: string,
  user: any
): Promise<DistributionStatusResponse> {
  const params = { state };
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${distributionStatusUrl}`,
    {
      orgId,
      accountId,
      accountOwnerId,
      distributionId,
      state,
    }
  )}`;

  return axiosInstance.put(url, workflowDates, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDistributions(
  orgId: string,
  status: string | Array<string>,
  token: string,
  taxId?: number
) {
  const url = `${urlBase}${format(distributionsUrl, {
    orgId,
  })}`;
  const params = removeEmptyStrings({ status, taxId });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAllDistributions(
  orgId: string,
  status: string | Array<string>,
  token: string,
  accountId?: string,
  accountOwnerId?: string,
  taxYear?: string
) {
  const url = `${urlBase}${format(distributions, {
    orgId,
    accountId,
    accountOwnerId,
  })}`;

  const params = removeEmptyStrings({ status, taxYear });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getNetIncomeAttribution(
  data: NetIncomeAttributableRequest,
  accountId: string,
  orgId: string,
  token: string
): Promise<NetIncomeAttributableResponse> {
  const url = `${urlBase}${format(netIncomeAttributionUrl, {
    orgId,
    accountId,
  })}`;

  const params = removeEmptyStrings(netIncomeAttributableRequestFactory(data));

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getActiveDistributions(
  orgId: string,
  search: {
    startDate: string;
    endDate?: string;
  },
  token: string
) {
  const url = `${urlBase}${format(activeDistributionsUrl, { orgId })}`;
  const params = removeEmptyStrings(search);

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importDistributionsColumns(
  files: Array<File>,
  orgId: string,
  token: string
): Promise<DistributionImportColumnsResponse> {
  const url = `${urlBase}${format(distributionImportColumsUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importDistributions(
  files: Array<File>,
  headerMappings: any,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(distributionImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();

  formData.append('file', files[0]);
  if (headerMappings) {
    formData.append('headerMappings', prepareBinaryJson(headerMappings));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}
