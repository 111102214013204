import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, Box } from '@mui/material';

import { AccountOwner } from '../../api/AccountOwnerApi.d';
import {
  addTransactionData,
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
} from '../../page/TransactionReducer';
import { useUser } from '../../auth/useUser';
import DistributionTermForm, {
  DistributionTerm,
  DISTRIBUTION_TERM_INIT,
} from '../form/distribution/DistributionTermForm';
import DistributionAmountForm, {
  DISTRIBUTION_AMOUNT_INIT,
} from '../form/distribution/DistributionAmountForm';
import DistributionScheduleContainer, {
  ScheduleFormData,
  SCHEDULE_INIT,
} from '../form/distribution/DistributionScheduleContainer';
import DistributionMethodForm, {
  DISTRIBUTION_METHOD_INIT,
} from '../form/distribution/DistributionMethodForm';
import {
  calculateRecurringDistributionReason,
  changeRecurringDistributionStatus,
  createOrSaveRecurringDistribution,
} from '../../api/RecurringDistributionApi';
import {
  LifeExpectancyTable,
  RecurringDistribution,
  TermChoiceOption,
} from '../../api/RecurringDistributionApi.d';
import {
  WorkflowDates,
  DistributionStatusState,
  Distribution,
  DistributionStatus,
} from '../../api/DistributionApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { DistributionChoices } from '../form/distribution/DistributionAmountRecurringSubForm';
import TransactionStepper, {
  TransactionStep,
} from '../../page/TransactionStepper';
import { Account } from '../../api/AccountApi.d';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { determineAgeGroup } from '../../app.constants';
import { errorMessages } from '../../utils/errorhandling.utils';

interface RecurringDistributionElectionProps {
  account: Account;
  accountOwner: AccountOwner;
  onCancel?: Function;
  onComplete?: Function;
}

// Expedited form to make a new recurring distribution
function RecurringDistributionElection(
  props: RecurringDistributionElectionProps
) {
  let isMounted = true;
  const {
    account = {},
    accountOwner = {},
    onCancel = () => {},
    onComplete = () => {},
  } = props;
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [pageState] = useTransactionReducer();
  const { getAppConstant, organization, addGlobalMessage } = useGlobalContext();
  const { accountType = '', accountId = '', accountOwnerId = '' } = account;
  const { dateOfBirth = '', fullName } = accountOwner;

  // Only show state withholding when an org has at least one state that supports it
  const showStateWithholding =
    organization.stateTaxIDS &&
    organization.stateTaxIDS.some(
      ({ stateWithholding = false }) => stateWithholding
    );

  // Save term and conditionally assign some term-dependent fields to root distribution object
  const saveTermInfo = (
    distributionTermInformation: DistributionTerm,
    advanceStep: boolean
  ) => {
    const {
      ownerResponsible: newOwnerResponsible,
      termLength: newTermLength,
      termChoice: newTermChoice,
      termEndDateAction: newTermEndDateAction,
      qualifiedJLE: newQualifiedJLE,
    } = distributionTermInformation;

    // Lookup life expectancy table based on term choice
    const calcType = {
      [TermChoiceOption.jle]: newQualifiedJLE
        ? LifeExpectancyTable.jle
        : LifeExpectancyTable.ule,
      [TermChoiceOption.ult]: LifeExpectancyTable.ule,
      [TermChoiceOption.sle]: LifeExpectancyTable.sle,
      [TermChoiceOption.slenonrecalc]: LifeExpectancyTable.slenonrecalc,
    }[newTermChoice];

    const termValue = newTermLength || -1;

    const distributionInformation = {
      termEndDateAction: newTermEndDateAction,
      ownerResponsible: newOwnerResponsible,
      termChoice: newTermChoice,
      // Optional fields; one or the other will be added
      ...(!calcType && termValue && { term: termValue }),
      ...(calcType && { lifeExpectancyTable: calcType }),
    };

    addTransactionData({ distributionInformation }, false);
    addTransactionData({ distributionTermInformation }, advanceStep);
  };

  const saveDistributionInfo = (
    data: RecurringDistribution,
    advanceStep: boolean
  ) => {
    // set federalWithholgingAmount to
    const distributionInformation = {
      ...pageState.distributionInformation,
      ...data,
      federalWithholdingAmount: '0',
   }

    addTransactionData({ distributionInformation }, advanceStep);

    if (
      distributionInformation.recurringSelection === DistributionChoices.owner
    ) {
      skipStep(5);
    }
  };

  // Progress status to next valid status
  const progressStatus = async (
    distributionInformation: RecurringDistribution,
    finalizeWorkflow: boolean
  ): Promise<void> => {
    await changeRecurringDistributionStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      distributionInformation.recurringDistributionId,
      DistributionStatusState.next,
      {} as WorkflowDates,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          // if this is owner responsible i want you to check to see if the distribution status is signature. if so push it to active by running this function again.
          if (res.data.distributionStatus === DistributionStatus.signature) {
            progressStatus(res.data, true);
          }

          saveDistributionInfo(res.data, false);
          if (
            finalizeWorkflow &&
            res.data.distributionStatus === DistributionStatus.active
          ) {
            completeTransaction();
            onComplete();
          }
        }
      })
      .catch((err) => {

        setIsLoading(false);
        rejectTransaction();
 addGlobalMessage(errorMessages(err) ||
            'Cannot add a recurring distribution on an account that is not OPEN.'
        );
      });
  };

  const pageStateToRecurringDistribution = (
    distributionMethodForm: Distribution
  ) => {
    const { underHalf59 } = determineAgeGroup(dateOfBirth);

    const distributionReason = calculateRecurringDistributionReason(
      accountType,
      underHalf59
    );

    const term = pageState.distributionInformation.term // if the term is 0, it needs to be blank
      ? pageState.distributionInformation.term
      : '';

    const recurringDistribution = {
      ...pageState.distributionInformation,
      ...distributionMethodForm,
      frequency: pageState.schedule.frequency,
      startDate:
        pageState.schedule.effectiveDate || distributionMethodForm.startDate,
      grossAmount: pageState.distributionInformation.totalAmount,
      distributionReason,
      term,
    } as RecurringDistribution;

    // Remove extra fields
    return recurringDistribution;
  };

  const mergeAndSaveDistribution = async (
    data: Distribution
  ): Promise<void> => {
    setIsLoading(true);

    await createOrSaveRecurringDistribution(
      pageStateToRecurringDistribution(data), // Here we use a factory to produce the distribution
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          progressStatus(res.data, true);
        }
      })
      .catch((err) => {

        setIsLoading(false);
        addGlobalMessage(errorMessages(err) || 'Error updating status');
      });
  };

  const saveCalculatedInfo = (taxCalculatedInfo) => {
    addTransactionData({ taxAmounts: taxCalculatedInfo }, false);
  };

  const saveSchedule = (values: ScheduleFormData) => {
    addTransactionData({ schedule: values });
  };

  const RecurringDistributionSteps: Array<TransactionStep> = [
    {
      label: 'Select Term',
      stepContent: (
        <Box mt={5} mb={3}>
          <DistributionTermForm
            account={account}
            initialValues={{
              ...DISTRIBUTION_TERM_INIT,
              ...pageState.distributionTermInformation,
              ...pageState.distributionInformation,
            }}
            onSubmit={async (values) => {
              saveTermInfo(values, !values.ownerResponsible);
              // Owner responsible elections end here—save it now.
              if (values.ownerResponsible) {
                await mergeAndSaveDistribution(values);
              }
            }}
            onCancel={onCancel}
          />
        </Box>
      ),
    },
    {
      label: 'Provide Distribution Amounts',
      stepContent: (
        <Box mt={5} mb={3}>
          <DistributionAmountForm
            account={account}
            accountOwner={accountOwner}
            onSubmit={(values) => saveDistributionInfo(values, true)}
            initialValues={{
              ...DISTRIBUTION_AMOUNT_INIT,
              ...pageState.distributionTermInformation,
              ...pageState.distributionInformation,
            }}
            term={pageState.distributionTermInformation}
            saveCalculatedInfo={saveCalculatedInfo}
            recurringDistribution
            showWithholding
            showStateWithholding={showStateWithholding}
          />
        </Box>
      ),
    },
    {
      label: 'Choose Distribution Schedule',
      stepContent: (
        <DistributionScheduleContainer
          account={account}
          accountOwner={accountOwner}
          distribution={pageState.distributionInformation}
          onSubmit={saveSchedule}
          taxAmounts={pageState.taxAmounts}
          distributionTerm={pageState.distributionTermInformation}
          saveCalculatedInfo={saveCalculatedInfo}
          saveDistributionInfo={saveDistributionInfo}
          initialValues={{
            ...SCHEDULE_INIT,
            ...pageState.distributionInformation,
          }}
        />
      ),
    },
    {
      label: 'Select Distribution Method',
      stepContent: (
        <Box mt={3} mb={3}>
          <DistributionMethodForm
            onSubmit={mergeAndSaveDistribution}
            initialValues={{
              ...DISTRIBUTION_METHOD_INIT,
              ...pageState.distributionInformation,
            }}
            onCancel={onCancel}
            distributionReason={
              pageState.distributionInformation.distributionReason
            }
            accountType={accountType}
            distributionType="editRecurringDistribution"
          />
        </Box>
      ),
    },
  ];

  const recurringDistributionSteps = () => {
    return RecurringDistributionSteps;
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  return (
    <>
      {accountId && (
        <Box mt={2}>
          <Paper elevation={3}>
            <Box p={4}>
              <Typography variant="overline">Name</Typography>

              <Typography color="secondary" variant="h3" gutterBottom>
                {fullName}
              </Typography>

              <Grid container>
                <Grid item sm={12}>
                  <Typography variant="overline">Account</Typography>

                  <Typography variant="h4">
                    {getAppConstant(
                      ConstantsMappingKey.accountType,
                      accountType
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}

      <Box mt={5}>
        <TransactionStepper
          steps={recurringDistributionSteps()}
          activeStep={pageState.activeStep}
          isLoading={isLoading}
          onStepClick={skipStep}
        />
      </Box>
    </>
  );
}

export default RecurringDistributionElection;
