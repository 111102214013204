import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';
import { BeneficiariesClaimsInfo } from '../../../api/BeneficiaryClaimsApi';

export const BENEFICIARIES_COMMENT_INIT: BeneficiariesClaimsInfo = {
  comments: '',
};

export interface BeneficiariesClaimsRepFormProps {
  initialValues: BeneficiariesClaimsInfo;
  onSubmit?: Function;
  cancel?: Function;
  submitName?: string;

}


function BeneficiariesClaimsCommentForm({
  initialValues,
  onSubmit,
  cancel = () => {},
  submitName,
}: BeneficiariesClaimsRepFormProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box mt={5} mb={3} mr={3} maxWidth={500}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Examples: Updated beneficiary addresses or other pertinent
                  information for processing the claim.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SiraTextField
                  name="comments"
                  label="Additional comments"
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Box>
          <StepButtonBar
            isSubmitting={isSubmitting}
            submitName={submitName}
            onCancel={cancel}
          />
        </Form>
      )}
    </Formik>
  );
}

export default BeneficiariesClaimsCommentForm;
