import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form } from 'formik';

import StepButtonBar from '../../steps/StepButtonBar';

import {
  DocumentationAddress,
  Organization,
  SpousalConsentOption,
  TransactionEmailSettings,
} from '../../../api/OrganizationApi.d';
import SiraSelectField from '../SiraSelectField';
import {
  documentationAddressOptions,
  orgSpousalConsentOptions,
  transactionEmailSettingsOptions,
} from '../../../app.constants';
import SiraTextField from '../SiraTextField';
import SiraPercentField from '../SiraPercentField';
import SiraSwitchField from '../SiraSwitchField';

export const ORG_TRANSACTION_INIT: Organization = {
  orgTransactionEmailSetting: TransactionEmailSettings.none,
  documentationAddress: DocumentationAddress.userBranch,
  investmentRatePrecision: 2,
  apyRatePrecision: 0,
  spousalConsentRequired: SpousalConsentOption.community,
  displayFeeAmount: false,
  displayInvestments: false,
  planNumberEnabled: false,
};

export interface TransactionEmailSettingsFormProps {
  initialValues: Organization;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}

function TransactionEmailSettingsForm({
  initialValues,
  onSubmit,
  onReset,
  submitName,
  resetName,
  onCancel,
}: TransactionEmailSettingsFormProps) {
  return (
    <section>
      <Formik
        initialValues={{
          ...initialValues,
        }}
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
        onReset={() => onReset()}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SiraSelectField
                      name="spousalConsentRequired"
                      items={orgSpousalConsentOptions}
                      label="Spousal Consent Required"
                    />
                  </Grid>
                  <Grid item xs={12} pt={1}>
                    <Grid container spacing={2} wrap="nowrap">
                      <Grid item>
                        <SiraTextField
                          fullWidth={false}
                          maxLength={1}
                          name="investmentRatePrecision"
                          label="Investment Rate Precision"
                        />
                      </Grid>
                      {values.investmentRatePrecision > 0 && (
                        <Grid item>
                          <SiraPercentField
                            disabled
                            value={0.12345}
                            fullWidth={false}
                            name="test"
                            label="Example"
                            decimalScale={values.investmentRatePrecision}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {/* APY precision */}
                  <Grid item xs={12}>
                    <Grid container spacing={2} wrap="nowrap">
                      <Grid item>
                        <SiraTextField
                          fullWidth={false}
                          maxLength={1}
                          name="apyRatePrecision"
                          label="APY Precision"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Documentation Address */}
                  <Grid item xs={12}>
                    <SiraSelectField
                      name="documentationAddress"
                      items={documentationAddressOptions}
                      label="Documentation Address"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ paddingTop: 10, paddingLeft: 10 }}
                    >
                      Select how frequently Transaction Administrators receive
                      reminders that transactions are pending review and users
                      receive reminders for past due tasks.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SiraSelectField
                      name="orgTransactionEmailSetting"
                      items={transactionEmailSettingsOptions}
                      label="Email Frequency"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Box>
                          <SiraSwitchField
                            name="displayInvestments"
                            checked={values.displayInvestments}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="primary">
                          Show the Select Investment Options step in
                          contribution workflows
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Box>
                          <SiraSwitchField
                            name="displayFeeAmount"
                            checked={values.displayFeeAmount}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="primary">
                          Display Financial Organization Fee Amount in the
                          Distribution Workflow
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <StepButtonBar
                isSubmitting={isSubmitting}
                submitName={submitName}
                resetName={resetName}
                onCancel={onCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}

export default TransactionEmailSettingsForm;
