import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';

import { useUser } from '../../auth/useUser';
import DesignatePrimaryBeneficiaries from '../../components/beneficiaries/DesignatePrimaryBeneficiaries';
import DesignateSecondaryBeneficiaries from '../../components/beneficiaries/DesignateSecondaryBeneficiaries';
import ReviewDocumentForm from '../../components/form/newAccount/ReviewDocumentForm';
import Layout from '../../components/Layout';
import FormPaper from '../../components/FormPaper';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import {
  addTransactionData,
  skipStep,
  completeTransaction,
  useTransactionReducer,
} from '../TransactionReducer';
import { BeneficiaryStatus } from '../../api/BeneficiariesApi.d';
import {
  createBeneficiariesDocument,
  getBeneficiariesDocument,
} from '../../api/BeneficiariesApi';
import { getAccountOwner } from '../../api/AccountOwnerApi';
import { getAccount } from '../../api/AccountApi';
import { Account, TransactionType } from '../../api/AccountApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import TransactionStepper from '../TransactionStepper';
import SkipChallenge from '../../components/steps/SkipChallenge';
import {
  useUnsavedChangesWarning,
  RowDefinition,
} from '../../components/useUnsavedChangesWarning';
import SiraAccountOwnerInfo from '../../components/form/SiraAccountOwnerInfo';
import { useBeneficiariesReducer } from '../../components/beneficiaries/BeneficiariesReducer';
import { IMMDocumentType } from '../../api/ESignApi.d';
import createOwnerTheme from '../../themes/ownerTheme';
import { AccountDetailAccordionType } from '../../components/ownerProfile/OwnerAccountDetails';
import { errorMessages } from '../../utils/errorhandling.utils';

function OwnerDesignatedBeneficiaries() {
  let isMounted = true;
  const [pageState] = useTransactionReducer();
  const { user } = useUser();
  const { addGlobalMessage, organization } = useGlobalContext();
  const [beneficiariesState] = useBeneficiariesReducer();
  const queryParams = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);
  const accountId = queryParams.get('accountId');
  const accountOwnerId = queryParams.get('accountOwnerId');
  const { beneficiaryStatus, secondaryBeneficiaries = [] } =
    pageState.beneficiaryInformation;
  const isAwaiting = [
    BeneficiaryStatus.signature,
    BeneficiaryStatus.review,
  ].includes(beneficiaryStatus);
  const isActive = [BeneficiaryStatus.live, BeneficiaryStatus.review, BeneficiaryStatus.signature].includes(
    beneficiaryStatus
  );
  const showContingentChallenge = !secondaryBeneficiaries.length;
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();
  const [selectedAccount, setSelectedAccount] = useState({} as Account);

  async function progressCurrentBeneficiariesStatus(finalizeWorkflow: boolean) {
    if (isMounted) {
      if (finalizeWorkflow) {
        completeTransaction();
        setUnsavedChanges(null);
      }
    }
  }

  // Update the status imperatively after document creation
  // The api does this but doesn't send a payload back so we'll hardcode it here for now
  const handleCreateDocument = useCallback(() => {
    if (beneficiaryStatus === BeneficiaryStatus.pending) {
      addTransactionData(
        {
          beneficiaryInformation: {
            beneficiaryStatus: BeneficiaryStatus.signature,
          },
        },
        false
      );
    }
  }, [beneficiaryStatus]);

  // using this to set transaction deletion Values
  const setRouterPromptValues = (beneficiaryInformation) => {
    const transactionData: RowDefinition = {
      accountId,
      accountOwnerId,
      transactionType: TransactionType.beneficiaries,
      transactionId: beneficiaryInformation.version,
    };
    setUnsavedChanges(transactionData);
  };

  // Get the accountOwner for the account loaded
  async function updateAccountOwner(): Promise<void> {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setAccountOwner(res.data);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  }

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    await getAccount(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Could not fetch the preselected account'
          );
        }
      });
  }

  // Optionaly set account when passed query params
  async function preselectAccountInfo(): Promise<void> {
    if (queryParams.get('accountId')) {
      setIsLoading(true);
      await fetchAndSetAccount();
    }
  }

  // Generate the PDF in S3 and/or stream it
  const viewDocument = (): Promise<any> => {
    return isActive
      ? getBeneficiariesDocument(
          user.organizationId,
          selectedAccount.accountOwnerId,
          selectedAccount.accountId,
          pageState.beneficiaryInformation.version,
          user.token,
          '',
          user
        )
      : createBeneficiariesDocument(
          user.organizationId,
          selectedAccount.accountOwnerId,
          selectedAccount.accountId,
          pageState.beneficiaryInformation.version,
          user.token,
          user
        );
  };

  const designateBeneficiariesSteps = [
    {
      label: 'Name Primary Beneficiaries',
      stepContent: (
        <DesignatePrimaryBeneficiaries
          state={beneficiariesState}
          initializeAsPending
          onSave={(beneficiaryInformation) => {
            addTransactionData({ beneficiaryInformation });
            setRouterPromptValues(beneficiaryInformation);
          }}
          account={selectedAccount}
          accountOwner={accountOwner}
          onSkipToSignature={(beneficiaryInformation) => {
            addTransactionData({ beneficiaryInformation }, false);
            skipStep(2);
          }}
        />
      ),
    },
    {
      label: 'Name Contingent Beneficiaries',
      stepContent: (
        <SkipChallenge
          shouldDisplay={showContingentChallenge}
          onSkip={() => skipStep(pageState.activeStep + 1)}
          skipText="Skip"
          acceptText="Add Beneficiaries"
        >
          <DesignateSecondaryBeneficiaries
            state={beneficiariesState}
            onSave={(beneficiaryInformation) => {
              addTransactionData({ beneficiaryInformation });
            }}
            account={selectedAccount}
            accountOwner={accountOwner}
            onSkipToSignature={(beneficiaryInformation) => {
              addTransactionData({ beneficiaryInformation }, false);
              skipStep(3);
            }}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Review and Sign Document',
      stepContent: (
        <>
          <ReviewDocumentForm
            searchResult={selectedAccount}
            allowSignature={isAwaiting}
            submitName="Done"
            hideStepButtonBar={pageState.completed}
            getDocument={viewDocument}
            onGetDocument={handleCreateDocument}
            onSubmit={async () => {
              if (isAwaiting) {
                await progressCurrentBeneficiariesStatus(true);
              }
            }}
            documentType={IMMDocumentType.beneficiaries}
            accountTransaction={AccountDetailAccordionType.beneficiaries}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (user.token || user.organizationId) {
      preselectAccountInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  useEffect(() => {
    setIsLoading(true);
    updateAccountOwner();
  }, [accountOwnerId]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createOwnerTheme(organization)}>
        <Layout>
          <FormPaper>
            <>
              <Typography variant="overline" gutterBottom>
                Transactions
              </Typography>

              <Typography color="secondary" variant="h1" gutterBottom>
                Update Beneficiaries
              </Typography>

              {selectedAccount.accountId && (
                <Box mt={2}>
                  <Paper elevation={3}>
                    <SiraAccountOwnerInfo
                      selectedAccount={selectedAccount}
                      accountOwner={accountOwner}
                    />
                  </Paper>
                </Box>
              )}

              <Box mt={5}>
                <TransactionStepper
                  steps={designateBeneficiariesSteps}
                  activeStep={pageState.activeStep}
                  isLoading={isLoading}
                  onStepClick={(index) => {
                    skipStep(index);
                  }}
                />
              </Box>
              {UnsavedChangesPrompt}
            </>
          </FormPaper>
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default OwnerDesignatedBeneficiaries;
