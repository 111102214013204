import axios from 'axios';
import format from 'string-format';
import { removeEmptyStrings } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const irsFileListUrl = '/api/taxFile/1220/list';
const irsFileUrl = '/api/taxFile/1220';

export async function retreiveIrsFile(
  fileName: string,
  token: string
){
  const url = `${urlBase}${format(irsFileUrl)}`;

  const params = removeEmptyStrings({ fileName });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}

export async function getIrsFileList(
  token: string
): Promise<any> {
  const url = `${urlBase}${format(irsFileListUrl)}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteIrsFile(
  fileName: string,
  token: string
): Promise<void> {
  const url = `${urlBase}${format(irsFileUrl)}`;

  const params = removeEmptyStrings({ fileName });

  return axiosInstance.delete(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}