import { Button, Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SiraModal from '../SiraModal';

export interface SkipChallengProps {
  shouldDisplay?: boolean;
  skipText?: string;
  acceptText?: string;
  skipModal?: any;
  skipModalTitle?: string;
  onSkip?: Function;
  onAccept?: Function;
  children?: React.ReactChild;
  afterContent?: React.ReactChild;
}

const SkipChallenge = ({
  shouldDisplay = true,
  skipText = 'Skip',
  acceptText = 'Accept',
  onSkip,
  onAccept,
  children,
  skipModal,
  skipModalTitle,
  afterContent,
}: SkipChallengProps) => {
  const [showChallenge, setShowChallenge] = useState(shouldDisplay);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
    onSkip(2);
  };

  const showStep = useCallback(() => {
    setShowChallenge(false);

    if (onAccept) {
      onAccept();
    }
  }, [onAccept]);

  const skipStep = useCallback(() => {
    if (onSkip) {
      if (skipModal) {
        setModalOpen(!modalOpen);
      } else {
        onSkip(1);
      }
    }
  }, [onSkip]);

  useEffect(() => {
    setShowChallenge(shouldDisplay);
  }, [shouldDisplay]);

  return (
    <>
      {!showChallenge ? (
        <>{children}</>
      ) : (
        <Box >
          <Box mb={2}>{afterContent}</Box>
          <Box component="span" mr={2}>
            <Button
              data-qa="accept-challenge-button"
              color="primary"
              variant="contained"
              onClick={showStep}
            >
              {acceptText}
            </Button>
          </Box>
          <Button
            data-qa="skip-challenge-button"
            color="secondary"
            variant="contained"
            onClick={skipStep}
          >
            {skipText}
          </Button>
        </Box>
      )}

      <SiraModal
        title={skipModalTitle}
        open={modalOpen}
        handleClose={handleClose}
      >
        {skipModal}
      </SiraModal>
    </>
  );
};

export default SkipChallenge;
