import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const dotPulse = keyframes`
  0% { opacity: 0; }
  33% { opacity: 1; }
  66% { opacity: 0; }
  100% { opacity: 0; }
`;

interface LoadingAnimationProps {
  loadingText?: string;
}

function LoadingAnimation(props: LoadingAnimationProps) {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="body1"
        sx={{
          animation: `${pulse} 1.5s infinite`,
        }}
      >
        {props.loadingText}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          animation: `${dotPulse} 1.5s infinite`,
          animationDelay: '0.5s',
        }}
      >
        .
      </Typography>
      <Typography
        variant="h6"
        sx={{
          animation: `${dotPulse} 1.5s infinite`,
          animationDelay: '1s',
        }}
      >
        .
      </Typography>
      <Typography
        variant="h6"
        sx={{
          animation: `${dotPulse} 1.5s infinite`,
          animationDelay: '1.5s',
        }}
      >
        .
      </Typography>
    </Box>
  );
}

export default LoadingAnimation;
