import axios from 'axios';
import format from 'string-format';
import { removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  AddChangeInvestmentRateResponse,
  Allocation,
  AllocationsResponse,
  InvestmentRate,
  InvestmentRateResponse,
} from './InvestmentRateApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
export const investmentRateUrl = '/org/{0.orgId}/investmentRate/{0.rateId}';
export const allocationsUrl =
  '/org/{orgId}/accountOwner/{accountOwnerId}/account/{accountId}/contribution/{contributionId}/allocations?active={active}';
export const investmentRatesDocumentUrl =
  '/org/{0.orgId}/investmentRate/{0.investmentRateId}/document/truthInSavings';
const investmentDocumentUploadUrl = '/org/{0.orgId}/investmentRate/{0.investmentRateId}/upload/document/truthInSavings'
const documentMAppings = '/document/mappedFields';
const symitarInvestmentRateUrl = '/org/{0.orgId}/symitar/accountowner/{0.accountOwnerId}/investmentoptions';
export async function getSymitarInvestmentRates(
  orgId: string,
  token: string,
  query: any = {},
  user: any,
  accountOwnerId: string = ''
): Promise<InvestmentRateResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${symitarInvestmentRateUrl}`, {
    orgId,
    accountOwnerId
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export async function getInvestmentRates(
  orgId: string,
  token: string,
  query: any = {},
  user: any,
  accountOwnerId: string = ''
): Promise<InvestmentRateResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${investmentRateUrl}`, {
    orgId,
    accountOwnerId
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: query,
  });
}

export async function createInvestmentRate(
  investmentRate: InvestmentRate,
  orgId: string,
  token: string,
  user: any
): Promise<AddChangeInvestmentRateResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${investmentRateUrl}`, {
    orgId,
  })}`;

  const cleanedRate = removeEmptyStrings(investmentRate);

  return axiosInstance.post(url, cleanedRate, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateInvestmentRate(
  investmentRate: InvestmentRate,
  orgId: string,
  token: string,
  user: any
): Promise<AddChangeInvestmentRateResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${investmentRateUrl}`, {
    orgId,
    rateId: investmentRate.investmentRateId,
  })}`;

  const cleanedRate = removeEmptyStrings(investmentRate);

  return axiosInstance.put(url, cleanedRate, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createOrSaveAllocations(
  data: Array<Allocation>,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  contributionId: string,
  token: string,
  user: any,
  active: boolean = true
): Promise<AllocationsResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${allocationsUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    contributionId,
    active,
  })}`;

  return axiosInstance.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function saveInvestmentRateDocument(
  investmentRateId: string,
  files: any,
  orgId: string,
  token: string,
  user: any
): Promise<any> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${investmentDocumentUploadUrl}`,
    {
      orgId,
      investmentRateId,
    }
  )}`;

  const formData = new FormData();

  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getInvestmentRateDocument(
  investmentRateId: string,
  orgId: string,
  token: string,
  user: any
): Promise<any> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${investmentRatesDocumentUrl}`,
    {
      orgId,
      investmentRateId,
    }
  )}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteInvestmentRateDocument(
  investmentRateId: string,
  orgId: string,
  token: string,
  user: any
): Promise<any> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${investmentRatesDocumentUrl}`,
    {
      orgId,
      investmentRateId,
    }
  )}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDocumentMappings(
  token: string,
  user: any
): Promise<any> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${documentMAppings}`
  )}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
