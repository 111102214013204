import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Formik, Form, useFormikContext, Field } from 'formik';
import * as yup from 'yup';
import StepButtonBar from '../../steps/StepButtonBar';
import { BeneficiariesClaimFiles } from './BeneficiariesClaimsForm.d';
import { addTransactionData } from '../../../page/TransactionReducer';
import SiraFileField from '../SiraFileField';
import { ClaimDocumentType } from '../../../api/BeneficiaryClaimsApi.d';
import SiraCheckbox from '../SiraCheckbox';
import SiraHyperLink from '../SiraTextHyperLink';
import { claimOptionValues } from './ViewBeneficiaryClaimFiles';

export const BENEFICIARIES_DESIGNEE_INIT: BeneficiariesClaimFiles = {
  designateBeneficiary: false,
  designateBeneficiaryFiles: null,
  fileProperties: null,
};

export interface BeneficiariesDesignatedFormProps {
  onSubmit?: Function;
  initialValues?: {};
}

const BENEFICIARIES_CLAIMS_SCHEMA = yup.object().shape({
  designateBeneficiaryFiles: yup
    .array()
    .nullable(true)
    .min(1)
    .label('Beneficiary Designation')
    .when('designateBeneficiary', (designateBeneficiary, schema) => {
      return designateBeneficiary ? schema : schema.required();
    }),
});


function BeneficiariesDesignatedUpload() {
  const { values } = useFormikContext();
  const { designateBeneficiary } = values as BeneficiariesClaimFiles;

  const onDropField = (docs) => {
    addTransactionData(
      {
        beneficiaryClaim: {
          designateBeneficiary,
          designateBeneficiaryFiles: docs,
          fileProperties: [
            {
              documentType: ClaimDocumentType.designatedBene,
              description: 'Beneficiary Designation',
            },
          ],
        },
      },
      false
    );
  };
  

  return (
    <Grid container spacing={3} sx={{ pt: 2 }}>
      <Grid item xs={12}>
        <Box mt={1.5}>
          <Field
            name="designateBeneficiary"
            type="checkbox"
            label={
              <Typography variant="body2">
                No beneficiary designation on file for this account. Process
                this claim with the{' '}
                <SiraHyperLink
                  textHyperLink="default beneficiary"
                  textType="BENEFICIARY_DESIGNATION"
                  title={claimOptionValues[ClaimDocumentType.designatedBene]}
                />
                .
              </Typography>
            }
            component={SiraCheckbox}
          />
        </Box>{' '}
      </Grid>
      <Grid item xs={12}>
        <Box mt={1.5}>
          <SiraFileField
            name="designateBeneficiaryFiles"
            onDropField={onDropField}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function BeneficiariesDesignatedForm(props: BeneficiariesDesignatedFormProps) {
  const { initialValues, onSubmit = () => {} } = props;

  const theme = useTheme();
  const classes = {
    root: {
      overflowX: 'auto' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1,
      },
    },
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={BENEFICIARIES_CLAIMS_SCHEMA}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container wrap="nowrap" sx={classes.root}>
              <Grid item xs={12}>
                  <BeneficiariesDesignatedUpload />
                <StepButtonBar isSubmitting={isSubmitting} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default BeneficiariesDesignatedForm;
