import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgTransactionPending(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 8.062 12.956 C 8.062 14.208 7.047 15.223 5.795 15.223 C 4.543 15.223 3.528 14.208 3.528 12.956 C 3.528 11.704 4.543 10.689 5.795 10.689 C 7.047 10.689 8.062 11.704 8.062 12.956 Z M 14.145 12.949 C 14.145 14.201 13.13 15.216 11.878 15.216 C 10.626 15.216 9.611 14.201 9.611 12.949 C 9.611 11.697 10.626 10.682 11.878 10.682 C 13.13 10.682 14.145 11.697 14.145 12.949 Z M 20.331 12.967 C 20.331 14.219 19.316 15.234 18.064 15.234 C 16.812 15.234 15.797 14.219 15.797 12.967 C 15.797 11.715 16.812 10.7 18.064 10.7 C 19.316 10.7 20.331 11.715 20.331 12.967 Z M 20.363 2.417 L 15.355 2.417 C 14.852 1.027 13.535 0.021 11.977 0.021 C 10.42 0.021 9.102 1.027 8.599 2.417 L 3.592 2.417 C 2.274 2.417 1.196 3.495 1.196 4.813 L 1.196 21.584 C 1.196 22.901 2.274 23.979 3.592 23.979 L 20.363 23.979 C 21.68 23.979 22.759 22.901 22.759 21.584 L 22.759 4.813 C 22.759 3.495 21.68 2.417 20.363 2.417 Z M 11.977 4.813 C 11.318 4.813 10.779 4.274 10.779 3.615 C 10.779 2.956 11.318 2.417 11.977 2.417 C 12.636 2.417 13.175 2.956 13.175 3.615 C 13.175 4.274 12.636 4.813 11.977 4.813 Z" />
    </SvgIcon>
  );
}

export default SvgTransactionPending;
