import React, { useState } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  IconButton,
  AlertTitle,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';

import { Beneficiary, BeneficiaryTypes } from '../../api/BeneficiariesApi.d';
import { beneficiaryRelationshipOptions } from '../../app.constants';
import TrimmedNumber from '../TrimmedNumber';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { dateValueFormatter } from '../../utils/DataGrid.utils';


interface BeneficiariesListProps {
  beneficiaries: Array<Beneficiary>;
  updateBeneficiaries?: Function;
  onEditClick?: Function;
  showForm: boolean;
  signedDate?:string;
}

function BeneficiariesList(props: BeneficiariesListProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    beneficiaries = [],
    onEditClick = () => {},
    updateBeneficiaries = () => {},
    showForm = false,signedDate
  } = props;



  function deleteBeneficiary(beneficiaryIndex: number) {
    const updatedBeneficiaries = beneficiaries.filter(
      (_, index) => index !== beneficiaryIndex
    );

    setIsLoading(true);
    updateBeneficiaries(updatedBeneficiaries);
    setIsLoading(false);
  }

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Beneficiary',
      width: 256,
    },
    {
      field: 'relationship',
      headerName: 'Relationship',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (
          beneficiaryRelationshipOptions.find(
            ({ value = '' }) => value === params.value
          ).label || params.value
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
      width: 156,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'percent',
      headerName: 'Percent',
      width: 86,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const percent = value.toString();

        return (
          <>
            <Box display="inline-block" mr={4}>
              <TrimmedNumber value={percent} decimalPlaces={2} />%
            </Box>
          </>
        );
      },
    },
    {
      field: 'signedDate',
      headerName: 'Signed Date',
      width: 156,
      renderCell: () => {
        return signedDate;
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 86,
      renderCell: (params: GridCellParams) => {
        const { row = {}} = params;

        return (
          <>
            {!showForm && (
              <Box display="inline-block">
                <IconButton
                  data-testid="Edit Beneficiary"
                  size="small"
                  aria-label="edit beneficiary"
                  onClick={() => {
                    onEditClick(row);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  data-testid="Delete Beneficiary"
                  size="small"
                  aria-label="delete beneficiary"
                  onClick={() => {
                    deleteBeneficiary(row.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </>
        );
      },
    },
  ];

  const rows = beneficiaries.map((result: Beneficiary, index: number) => ({
    ...result,
    id: index,
    fullName: [BeneficiaryTypes.TRUST, BeneficiaryTypes.ESTATE, BeneficiaryTypes.NONEHUMAN].includes(
      result.relationship
    )
      ? result.name
      : `${result.firstName} ${result.lastName}`,
    isEditing: false,
  }));

  const percentDesignated = beneficiaries.reduce(
    (acc, { percent = 0 }) => acc + parseFloat(percent.toString()),
    0
  );

  return (
    <>
      <Box>
        {!isLoading ? (
          <DataGrid
            components={{
              NoRowsOverlay: SiraNoRowsOverlay,
            }}
            rowHeight={75}
            disableColumnMenu
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10]}
            hideFooter
            autoHeight
            columns={columns}
            rows={rows}
          />
        ) : (
          <Box width="1">
            <LinearProgress color="secondary" />
          </Box>
        )}
      </Box>

      <Box>
        <Box p={2} pl={0} display="inline-block">
          <Typography variant="body2">Remaining</Typography>
          <Typography variant="h4" color="primary">
            <TrimmedNumber value={100 - percentDesignated} decimalPlaces={2} />%
          </Typography>
        </Box>

        <Box p={2} display="inline-block">
          <Typography variant="body2">Designated</Typography>
          <Typography variant="h4">
            <TrimmedNumber value={percentDesignated} decimalPlaces={2} />%
          </Typography>
        </Box>
      </Box>

      {percentDesignated < 100 && (
        <Box pb={1}>
          <Alert severity="error">
            <AlertTitle>
              You have{' '}
              <TrimmedNumber
                value={100 - percentDesignated}
                decimalPlaces={2}
              />
              % remaining to designate.
            </AlertTitle>
            Add a beneficiary or edit the designations above.
          </Alert>
        </Box>
      )}

      {percentDesignated > 100 && (
        <Box pb={1}>
          <Alert severity="error">
            <AlertTitle>
              You have{' '}
              <TrimmedNumber value={percentDesignated} decimalPlaces={2} />%
              designated.
            </AlertTitle>
            Remove a beneficiary or edit the designations above.
          </Alert>
        </Box>
      )}
    </>
  );
}

export default BeneficiariesList;
