import { AccountType } from '../../../api/AccountApi.d';
import { DepositType } from '../../../api/ContributionApi.d';
import { newAccountDepositHelpTxt } from './resource.txt';

export function getTransferText(accountType) {
  switch (accountType) {
    case AccountType.hsa:
      return newAccountDepositHelpTxt.hsa.transfer;
    case AccountType.esa:
      return newAccountDepositHelpTxt.esa.transfer;
    default:
      return newAccountDepositHelpTxt.tradSepSimpleRoth.transfer;
  }
}

export function getRolloverText(accountType: any, fromAccountType) {
  switch (accountType) {
    case AccountType.hsa:
      return newAccountDepositHelpTxt.hsa.rollover;
    case AccountType.esa:
      return newAccountDepositHelpTxt.esa.rollover;
    default:
      if (fromAccountType === AccountType.employer) {
        return newAccountDepositHelpTxt.tradSepSimpleRoth.rollover;
      }

      return newAccountDepositHelpTxt.other.rollover;
  }
}

export function getInfoText(accountType, depositMethod, fromAccountType) {
  let infoHelpText;

  switch (depositMethod) {
    case DepositType.transfer:
      infoHelpText = getTransferText(accountType);
      return infoHelpText;
    case DepositType.rollover:
      infoHelpText = getRolloverText(accountType, fromAccountType);
      return infoHelpText;
    case DepositType.regular:
      infoHelpText = newAccountDepositHelpTxt.regularContribution;
      return infoHelpText;
    case DepositType.regularPrior:
      infoHelpText = newAccountDepositHelpTxt.priorYearContribution;
      return infoHelpText;
    case DepositType.recharacter:
      infoHelpText = newAccountDepositHelpTxt.recharacterization;
      return infoHelpText;
    case DepositType.rothConversion:
      infoHelpText = newAccountDepositHelpTxt.roth.rothConversion;
      return infoHelpText;
    case DepositType.postponed:
      infoHelpText =
        newAccountDepositHelpTxt.postponedContribution.afterContent;
      return infoHelpText;
    case DepositType.repayment:
      infoHelpText = newAccountDepositHelpTxt.repayment.afterContent;
      return infoHelpText;
    case DepositType.sep:
      infoHelpText = newAccountDepositHelpTxt.tradSepSimpleRoth.sepContribution;
      return infoHelpText;
    case DepositType.simple:
      infoHelpText = newAccountDepositHelpTxt.simpleIra.simpleContribution;
      return infoHelpText;
    case DepositType.qualifiedHSA:
      infoHelpText = newAccountDepositHelpTxt.hsa.qlHsaFundingDist;
      return infoHelpText;
    default:
      return infoHelpText;
  }

  return '';
}
