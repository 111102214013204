import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgBranchSearch(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M0 19h9.982l1.487 3H0zM10 1L0 6v2h20V6zM2 10h3v7H2zM8 10h3v7H8zM14 10h3v2l-3 .5z"
        />
        <path d="M16.716 14l.198.005a3.716 3.716 0 012.621 6.13l.16.154h.452L23 23.15l-.852.851-2.859-2.853v-.452l-.154-.16-.167.136A3.716 3.716 0 1116.716 14v-.001zm-.216 1l-.165.005A2.496 2.496 0 0014 17.5c0 1.383 1.117 2.5 2.5 2.5l.165-.005A2.496 2.496 0 0019 17.5c0-1.383-1.117-2.5-2.5-2.5z" />
      </g>
    </SvgIcon>
  );
}

export default SvgBranchSearch;
