import React from 'react';
import { Box, Grid, Typography, Chip, Select, MenuItem } from '@mui/material';

import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { AccountType } from '../../api/AccountApi.d';
import { SiraSelectItem } from './SiraSelectField';

interface AccountTypesSelectFieldProps {
  onChange: Function;
  accountTypes: Array<AccountType>;
  categorical?: boolean; // If select should only offer one of HSA, ESA, or IRA categories
}

function AccountTypesSelectField(props: AccountTypesSelectFieldProps) {
  const { onChange = () => {}, accountTypes, categorical = false } = props;
  const { organization, getAppConstant } = useGlobalContext();
  const { accountTypesSupported = [] } = organization;

  // Allow the account type filter items to be selected or deselected
  const handleAccountTypeSelect = (
    contributionAccountTypes: Array<AccountType>
  ) => {
    onChange(contributionAccountTypes);
  };

  // Allow the chip account types in the filter to be deleted
  const handleAccountTypeDelete = (valueToExclude): void => {
    onChange(accountTypes.filter((value) => value !== valueToExclude));
  };

  const filterOptions: Array<SiraSelectItem> = accountTypesSupported.map(
    (value) => ({
      value,
      label: getAppConstant(ConstantsMappingKey.accountType, value),
    })
  );

  // Groups ESA, HSA, and IRAs cetegorically if enabled
  const categoricalOptions: Array<SiraSelectItem> =
    accountTypesSupported.reduce((acc, value) => {
      if ([AccountType.esa, AccountType.hsa].includes(value)) {
        acc.push({
          value,
          label: getAppConstant(ConstantsMappingKey.accountType, value),
        });
      } else if (
        !acc.find(({ value: v }) => v === AccountType.traditionalIra)
      ) {
        acc.push({
          value: AccountType.traditionalIra,
          label: 'IRA',
        });
      }

      return acc;
    }, []);

  return categorical ? (
    <Grid container spacing={2} alignItems="center" wrap="nowrap" pb={2}>
      <Grid item>
        <Typography noWrap variant="body2">
          Filter by:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          onChange={(e) => {
            handleAccountTypeSelect(e.target.value as Array<AccountType>);
          }}
          value={accountTypes}
        >
          {categoricalOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2} alignItems="center" wrap="nowrap" pb={2}>
      <Grid item>
        <Typography noWrap variant="body2">
          Show accounts:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          onChange={(e) => {
            handleAccountTypeSelect(e.target.value as Array<AccountType>);
          }}
          value={accountTypes}
          multiple
          renderValue={(selected: Array<any>) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const label =
                  getAppConstant(ConstantsMappingKey.accountType, value) ||
                  value;

                return (
                  <Chip
                    key={value}
                    label={label}
                    onDelete={() => {
                      handleAccountTypeDelete(value);
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation(); // Prevents clicking the chip from opening the select box
                    }}
                  />
                );
              })}
            </Box>
          )}
        >
          {filterOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}

export default AccountTypesSelectField;
