import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import TaxReportingIcon from '../icons/TaxReporting';
import { TaxDocument } from '../api/TaxDocument';

interface TaxReportingCheckListProgressProps {
  documentStatus: TaxDocument;
}
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(0.80turn,#233329,#63D471)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(0.80turn,#233329,#63D471)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(0.80turn,#233329,#63D471)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient(0.80turn,#233329,#63D471)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PictureAsPdfIcon />,
    2: <EmailOutlinedIcon />,
    3: <TaxReportingIcon />, 
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  'Tax Forms Created',
  'Tax Forms Sent to Owners',
  'Tax Forms Sent to IRS',
];

export function TaxReportingCheckListProgress(
  props: TaxReportingCheckListProgressProps
) {
  const { documentStatus } = props;
  const [activeStep, setactiveStep] = useState(0 as number);

  useEffect(() => {
    const statusStep = [
      documentStatus.readyToPrint,
      documentStatus.sentToOwner,
      documentStatus.sentToIrs,
    ];

    setactiveStep(statusStep.filter((step) => step === true).length - 1);
    
  }, [documentStatus.readyToPrint]);

  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
}

export default TaxReportingCheckListProgress;
