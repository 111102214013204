import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const colors = [
  'gray',
  'blue',
  'green',
  'purple',
  'teal',
  'maroon',
  'aqua',
  'red',
];

interface NameValuePair {
  name: string;
  value: number;
}
interface SimplePieChartProps {
  data: Array<NameValuePair>;
  unit?: string;
}

export default function SimplePieChart({
  data,
  unit = '$',
}: SimplePieChartProps) {
  const customLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#8884d8"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {data[index].name} {unit}
        {value}
      </text>
    );
  };

  return (
    <PieChart width={500} height={300}>
      <Pie
        dataKey="value"
        isAnimationActive={false}
        data={data}
        cx="50%"
        cy="50%"
        label={customLabel}
        outerRadius={80}
      >
        {data &&
          data.map((entry, index) => (
            <Cell key={entry.value} fill={colors[index % colors.length]} />
          ))}
      </Pie>
      <Tooltip />
    </PieChart>
  );
}
