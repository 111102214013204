import React from 'react';
import { Box } from '@mui/material';

import SvgSuperiorLoader from '../icons/SvgSuperiorLoader';

const Loading = () => {
  const classes = {
    spinner: {
      svg: {
        position: 'fixed' as any,
        top: '50%',
        left: '50%',
        maxWidth: '100px',
        marginTop: '-50px',
        marginLeft: '-50px',
      },
    },
  };

  return (
    <>
      <Box position="absolute" width="1" height="1" bgcolor="white">
        <Box sx={classes.spinner}>
          <SvgSuperiorLoader />
        </Box>
      </Box>
    </>
  );
};

export default Loading;
