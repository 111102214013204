import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Divider,
  Box,
  Button,
  Paper,
  Popover,
  IconButton,
} from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BlockIcon from '@mui/icons-material/Block';

import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import { getAccountOwnerAccounts } from '../../api/AccountOwnerApi';
import {
  AccountOwnerAccountsResponse,
  AccountOwnerAccount,
  AccountOwner,
} from '../../api/AccountOwnerApi.d';
import AccountOwnerAccountSummaryCard from './AccountOwnerAccountSummaryCard';
import {
  AccountStatus,
  TransactionType,
  Account,
} from '../../api/AccountApi.d';
import TransactionSignatureIcon from '../../icons/TransactionSignature';
import TransactionPendingIcon from '../../icons/TransactionPending';
import TransactionReviewIcon from '../../icons/TransactionReview';

import { linkToTransaction } from '../../utils/transaction.utils';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { UserRole } from '../../api/UserApi.d';

interface CorrectionOwnerAccountsProps {
  accountOwner: AccountOwner;
  setAccount: Function
  accountIdToSelect?: Function; // Account ID to preselect once they're all fetched
}

function CorrectionOwnerAccounts(props: CorrectionOwnerAccountsProps) {
  let isMounted = true;
  const { accountOwner = {}, accountIdToSelect = '', setAccount } = props;
  const { accountOwnerId = '' } = accountOwner;
  const { user } = useUser();
  const navigate = useNavigate();
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const [response, setResponse] = useState({} as AccountOwnerAccountsResponse);
  const [selectedAccount, setSelectedAccount] = useState(
    {} as AccountOwnerAccount
  );
  const { data: accounts = [] } = response;
  const wipAccounts = accounts.filter(
    ({ accountStatus }) =>
      [AccountStatus.pending, AccountStatus.signature].includes(
        accountStatus
      ) ||
      (AccountStatus.review === accountStatus &&
        user.roles.includes(UserRole.orgTransactionsAdmin))
  );

  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  // Link to the WIP transaction based on
  function goToTransaction(row: Account) {
    linkToTransaction(row.accountOwnerId, row.accountId, row, navigate);
  }

  // Get all of the accounts for the current account owner
  async function fetchAccountOwnerAccounts(): Promise<void> {
    await getAccountOwnerAccounts(
      accountOwnerId,
      user.organizationId,
      user.token, 
      user
    )
      .then((res) => {
        if (isMounted) {
          setResponse(res);
        }
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage("Error fetching account owner's accounts");
        }
      });
  }

  const columns: GridColDef[] = [
    {
      field: 'accountStatus',
      headerName: ' ',
      width: 0,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const statusIcons = {
          [AccountStatus.open]: <AssignmentTurnedInIcon />,
          [AccountStatus.closed]: <BlockIcon />,
          [AccountStatus.pending]: <TransactionPendingIcon />,
          [AccountStatus.signature]: <TransactionSignatureIcon />,
          [AccountStatus.review]: <TransactionReviewIcon />,
        };
        const [rolesAnchorEl, setRolesAnchorEl] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setRolesAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setRolesAnchorEl(null);
        };

        return (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <IconButton
                color="primary"
                onClick={handleClick}
                aria-label={`account status ${value}`}
                size="large"
              >
                {statusIcons[value.toString()]}
              </IconButton>
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(rolesAnchorEl)}
              onClose={handleClose}
              anchorEl={rolesAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">Status: {value.toString()}</Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Type',
      width: 300,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const typeId = value.toString();

        return (
          <Button
            onClick={() => {
              goToTransaction(row);
            }}
            sx={classes.accountLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {getAppConstant(ConstantsMappingKey.accountType, typeId)}
            </Box>
          </Button>
        );
      },
    },
  ];

  const addSelectedAccount = (account: AccountOwnerAccount) => {
    setSelectedAccount(account);
    setAccount(account);
  }

  useEffect(() => {
    if (user.token && user.organizationId && accountOwnerId) {
      fetchAccountOwnerAccounts();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId, accountOwnerId]);

  // Once a response comes back, preselect the account by ID passed
  useEffect(() => {
    const accountToSelect = accounts.find(
      ({ accountId = '' }) => Number(accountId) === Number(accountIdToSelect)
    );

    if (accountToSelect) {
      setSelectedAccount(accountToSelect);
    }
  }, [accounts]);

  return (
    <>
      {Boolean(wipAccounts.length) && (
        <Box mt={4}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Pending Accounts
          </Typography>

          <Divider />

          <Paper>
            <Box p={2} mt={2}>
              <DataGrid
                components={{
                  NoRowsOverlay: SiraNoRowsOverlay,
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10]}
                disableColumnMenu
                hideFooter
                autoHeight
                columns={columns}
                rows={wipAccounts.map((result, id) => ({
                  id,
                  transactionType: TransactionType.account,
                  ...result,
                }))}
              />
            </Box>
          </Paper>
        </Box>
      )}

      <Box mt={4}>
        <Typography variant="h5" color="secondary" gutterBottom>
          Accounts
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          {accounts
            // Sort account cards by ID so they don't jump around when editing
            .sort(({ accountId: a }, { accountId: b }) => (a > b ? 1 : -1))
            .map((account) => (
             ![AccountStatus.pending, AccountStatus.signature, AccountStatus.review].includes(account.accountStatus) && <Grid
                item
                xs={6}
                md={4}
                key={`account-summary-card_${account.accountId}`}
              >
                <AccountOwnerAccountSummaryCard
                  account={account}
                  selectedAccountId={selectedAccount.accountId}
                  setSelectedAccount={addSelectedAccount}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
}

export default CorrectionOwnerAccounts;
