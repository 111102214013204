import { Typography } from '@mui/material';
import React from 'react';

interface StepLabelProps {
  title?: string;
  errorText?: string;
  active?: boolean;
}

const SiraStepLabel = ({ title, errorText, active }: StepLabelProps) => {
  return (
    <>
      <Typography
        variant="subtitle2"
        color={active ? 'textPrimary' : 'textSecondary'}
      >
        {title}
      </Typography>
      {active && <Typography variant="caption">{errorText}</Typography>}
    </>
  );
};

export default SiraStepLabel;
