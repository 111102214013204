import React from 'react';
import { Box, Grid, Typography, Chip, Select, MenuItem } from '@mui/material';

import { SiraSelectItem } from './SiraSelectField';
import { BeneficiaryStatus } from '../../api/BeneficiaryClaimsApi.d';

interface BeneficiarySelectFieldProps {
  onChange: Function;
  beneficiaryTypes: Array<BeneficiaryStatus>;
}

export const beneficiarySupportedStatus = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'SUBMITTED_FOR_PROCESSING', label: 'Submitted for Processing' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'AWAITING_ELECTION', label: 'Awaiting Election' },
  { value: BeneficiaryStatus.awaitingAdditionalInfo, label: 'Awaiting Additional Information' },
  { value: BeneficiaryStatus.notProcessed, label: 'Not Processed' },
];

function BeneficiarySelectField(props: BeneficiarySelectFieldProps) {
  const { onChange = () => {}, beneficiaryTypes } = props;

  // Allow the filter items to be selected or deselected
  const handleBeneStatusSelect = (claimsTypes: Array<BeneficiaryStatus>) => {
    onChange(claimsTypes);
  };

  // Allow the chip in the filter to be deleted
  const handleBeneficiaryStatusDelete = (valueToExclude): void => {
    onChange(beneficiaryTypes.filter((value) => value !== valueToExclude));
  };

  const filterOptions: Array<SiraSelectItem> = beneficiarySupportedStatus;

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      <Grid item>
        <Typography noWrap variant="body2">
          Show status:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          onChange={(e) => {
            handleBeneStatusSelect(e.target.value as Array<BeneficiaryStatus>);
          }}
          value={beneficiaryTypes}
          multiple
          renderValue={(selected: Array<any>) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const { label = '' } =
                  beneficiarySupportedStatus.find(
                    ({ value: valueTolookup }) => value === valueTolookup
                  ) || value;

                return (
                  <Chip
                    key={value}
                    label={label}
                    onDelete={() => {
                      handleBeneficiaryStatusDelete(value);
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation(); // Prevents clicking the chip from opening the select box
                    }}
                  />
                );
              })}
            </Box>
          )}
        >
          {filterOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}

export default BeneficiarySelectField;
