import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import StepButtonBar from '../../steps/StepButtonBar';
import { DistributionMethod } from '../../../api/DistributionApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraCheckbox from '../SiraCheckbox';

export interface AllowedDistribution {
  distributionMethodsSupported: Array<DistributionMethod>;
}

export const DEFAULT_DISTRIBUTION_SETTINGS_INIT: AllowedDistribution = {
  distributionMethodsSupported: [] as Array<DistributionMethod>,
};

const DEFAULT_DISTRIBUTION_SETTINGS_SCHEMA = {
  distributionMethodsSupported: yup
    .array(yup.string())
    .min(1)
    .label('Supported Methods'),
};

export interface DefaultDistributionSettingsFormProps {
  initialValues: AllowedDistribution;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

function AllowedDistributionSettingsForm({
  initialValues,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
}: DefaultDistributionSettingsFormProps) {
  const { organization } = useGlobalContext();
  const distributionMethodOptions = [
    { value: DistributionMethod.cash, label: 'Cash' },
    { value: DistributionMethod.check, label: 'Check' },
    {
      value: DistributionMethod.direct,
      label: `Transfer to account at ${organization.name}`,
    },
    {
      value: DistributionMethod.transfer,
      label: `Transfer to account at another financial organization`,
    },
  ];

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      enableReinitialize
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => onReset()}
      validationSchema={yup.object(DEFAULT_DISTRIBUTION_SETTINGS_SCHEMA)}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={5}>
              {/* Distribution Method */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Distribution Method
                    </Typography>
                  </Grid>
                  {distributionMethodOptions.map((method) => {
                    return (
                      <Grid item xs={12} key={method.value}>
                        <Field
                          name="distributionMethodsSupported"
                          value={method.value}
                          label={method.label}
                          type="checkbox"
                          component={SiraCheckbox}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default AllowedDistributionSettingsForm;
