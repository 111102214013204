import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';

import { useUser } from '../../auth/useUser';
import { saveUser, sendPasswordReset } from '../../api/UserApi';
import { User, UserResponse } from '../../api/UserApi.d';
import UserRoleForm, { USER_ROLE_INIT } from '../form/newUser/UserRoleForm';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { errorMessages } from '../../utils/errorhandling.utils';

interface UserProfileRoleProps {
  response: UserResponse;
  setResponse: Function;
  editing?: boolean;
  setEditing?: Function;
}

function UserProfileRoleDetails(props: UserProfileRoleProps) {
  let isMounted = true;
  const { user } = useUser();
  const { roles = [], addGlobalMessage } = useGlobalContext();
  const [isEditing, setIsEditing] = useState(false);
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const {
    response = {},
    setResponse = () => {},
    editing,
    setEditing = () => {},
  } = props;
  const { data: selectedUser = {} as User } = response as UserResponse;
  const { userId, roles: userRoles = [] } = selectedUser as User;

  
  async function saveUserRoles(data) {
    await saveUser(data, user.organizationId, userId, user.token)
      .then((res) => {
        if (isMounted) {
          setResponse(res);
          setIsEditing(false);
          setEditing(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
  
 addGlobalMessage(errorMessages(err) || 'Error saving user role information'
          );
        }
      });
  }

  async function sendPasswordResetEmail(): Promise<void> {
    await sendPasswordReset(user.organizationId, userId, user.token)
      .then(() => {
        setPasswordResetSent(true);
        addGlobalMessage(
          "Instructions to reset password have been sent to the user's email",
          {
            severity: 'success',
          }
        );
      })
      .catch(() => {
        addGlobalMessage('Password reset email could not be sent');
      });
  }

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (editing) {
      setIsEditing(true);
    }
  }, [editing]);

  return isEditing ? (
    <UserRoleForm
      initialValues={{
        ...USER_ROLE_INIT,
        ...selectedUser,
      }}
      onSubmit={async (data) => {
        await saveUserRoles(data);
      }}
      onCancel={() => {
        setIsEditing(false);
        setEditing(false);
      }}
    />
  ) : (
    <Box width="1">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2">Roles</Typography>
          {userRoles.length > 0 ? (
            userRoles.map((userRole) => {
              const { displayName = '' } = roles.find(
                ({ name }) => name === userRole
              );

              return (
                <Typography key={userRole} variant="body1">
                  {displayName || userRole}
                </Typography>
              );
            })
          ) : (
            <Typography variant="body1">
              No roles assigned to this user
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              aria-label="Edit user role and permissions"
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => {
                setIsEditing(true);
                setEditing(true);
              }}
            >
              Edit Details
            </Button>
          </Grid>
          <Grid item>
            <Button
              aria-label="Send reset password email"
              variant="outlined"
              color="primary"
              startIcon={<LockIcon />}
              disabled={passwordResetSent}
              onClick={() => {
                sendPasswordResetEmail();
              }}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default UserProfileRoleDetails;
