import React, { useEffect, useState } from 'react';
import {
  Box,
  LinearProgress,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';

import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';

import { useUser } from '../../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';

import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import {
  documentationAddressLabels,
  globalPaginationOptions,
} from '../../app.constants';
import {
  getOrg,
  getOrganizationTransactionSettings,
  putOrganizationTransactionSettings,
  updateOrg,
} from '../../api/OrganizationApi';
import {
  CustomDistributionCodeDTO,
  DocumentationAddress,
  SpousalConsentOption,
} from '../../api/OrganizationApi.d';
import TransactionEmailSettingsForm, {
  ORG_TRANSACTION_INIT,
} from '../form/organization/TransactionEmailSettingsForm';
import { errorMessages } from '../../utils/errorhandling.utils';
import NumberFormat from 'react-number-format';

interface OrganizationStateInvestmentMaintenanceProps {
  financialOrganizationId?: string;
}

export const DISTRIBUTION_CODE_INIT = {
  distributionCode: '',
  distributionReason: '',
  displayName: '',
};

function OrganizationTransactionSettings(
  props: OrganizationStateInvestmentMaintenanceProps
) {
  let isMounted = true;
  const { user } = useUser();
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState(false);
  const { organization, setCurrentOrg, addGlobalMessage } = useGlobalContext();
  const {
    spousalConsentRequired = SpousalConsentOption.community,
    documentationAddress = '' as DocumentationAddress,
    investmentRatePrecision,
    apyRatePrecision,
    displayInvestments = false,
    displayFeeAmount = false,
    planNumberEnabled = false,
    orgTransactionEmailSetting,
  } = organization;
  const [distributionCodeData, setDistributionCodeData] = useState([] as any);
  const [editWorkflowSettings, setEditWorkflowSettings] = useState(
    false as boolean
  );
  const { financialOrganizationId = '' } = props;

  const useStyles = makeStyles(() => ({
    toolbarContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '15px',
    },
    exportButton: {
      backgroundColor: 'black', // Set your desired background color
      color: 'white', // Set your desired text color
      '&:hover': {
        backgroundColor: 'black', // Set your desired hover background color
      },
      padding: '5px',
    },
    ellipsisOverflow: {
      whiteSpace: 'nowrap' as any,
      overflow: 'hidden' as any,
      textOverflow: 'ellipsis' as any,
    },
  }));

  const classes = useStyles();

  async function getDistributionCodeData() {
    setIsLoading(true);
    getOrganizationTransactionSettings(financialOrganizationId, user.token)
      .then((res) => {
        if (isMounted) {
          const data = res.data.map((row, id) => ({ ...row, id }));
          setDistributionCodeData(data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error loading distribtuion codes'
        );
      });
  }

  useEffect(() => {
    if (user.token && financialOrganizationId) {
      getDistributionCodeData();
    }
    setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, [user.token, financialOrganizationId]);

  async function addOrUpdateDistributionCodee(
    distributionData: CustomDistributionCodeDTO
  ) {
    const mergerData = {
      distributionCode: distributionData.distributionCode,
      distributionReason: distributionData.distributionReason,
      displayName: distributionData.displayName,
      reportingCode: distributionData.reportingCode,
    };

    await putOrganizationTransactionSettings(
      mergerData,
      financialOrganizationId,
      user.token
    )
      .then(() => {
        if (isMounted) {
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);

          addGlobalMessage(
            errorMessages(err) || 'Error saving distribution code'
          );
        }
      });
  }

  const saveOrganization = async (data) => {
    // Update shallow org details
    await updateOrg({ ...organization, ...data }, organization, user.token)
      .then((res) => {
        if (isMounted) {
          setCurrentOrg(res.data);
          addGlobalMessage('Transaction email setting saved successfully', {
            severity: 'success',
          });
          setEditWorkflowSettings(!editWorkflowSettings);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Error saving organization information'
          );
        }
      });
  };

  const getAccountType = (accountType: string) => {
    const iraAccountType = ['IRA', 'E_IRA', 'Q_IRA'];
    const rothAccountType = ['ROTH', 'E_ROTH', 'Q_ROTH'];
    const esaAccountType = ['ESA'];
    const hsaAccountType = ['HSA'];
    const inheritedIraAccountType = ['I_IRA'];

    if (iraAccountType.some((type) => accountType.startsWith(type))) {
      return 'IRA';
    }
    if (rothAccountType.some((type) => accountType.startsWith(type))) {
      return 'Roth';
    }
    if (esaAccountType.some((type) => accountType.startsWith(type))) {
      return 'ESA';
    }
    if (hsaAccountType.some((type) => accountType.startsWith(type))) {
      return 'HSA';
    }
    if (inheritedIraAccountType.some((type) => accountType.startsWith(type))) {
      return 'Inherited IRA';
    }

    return '';
  };

  const CustomGridToolbarContainer = () => {
    return (
      <GridToolbarContainer className={classes.toolbarContainer}>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          slotProps={{ button: { variant: 'contained'} }}        />
      </GridToolbarContainer>
    );
  };

  async function fetchOrganization() {
    setIsLoading(true);
    await getOrg(organization.financialOrganizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setCurrentOrg(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error fetching organization information'
          );
        }
      });
  }

  useEffect(() => {
    if (isMounted) {
      fetchOrganization();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'reportingCode',
      headerName: 'IRS Code',
      width: 186,
      sortable: true,
    },
    {
      field: 'displayName',
      headerName: 'Display Name',
      width: 205,
      renderCell: (params: GridCellParams) => {
        const { value } = params;
        const description = value as string;
        return (
          <Box
            sx={{
              whiteSpace: 'nowrap' as any,
              overflow: 'hidden' as any,
              textOverflow: 'ellipsis' as any,
            }}
            title={description}
          >
            {description}
          </Box>
        );
      },
    },
    {
      field: 'distributionCode',
      headerName: 'Description',
      width: 205,
      editable: true,
    },
    {
      field: 'distributionReason',
      headerName: 'Account Type',
      width: 205,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return getAccountType(row.distributionReason);
      },
    },
  ];

  if (isLoading) {
    return (
      <Box width="1" pt={10} pb={10} textAlign="center">
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion
          slotProps={{
            transition: {
              mountOnEnter: true,
              unmountOnExit: true,
            },
          }}
        >
          <AccordionSummary
            disabled={isLoading}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h5" color="secondary">
              Distribution Code Descriptions
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Box width="1">
              <Box>
                <Typography
                  variant="body2"
                  color="primary"
                  mt={4}
                  mb={4}
                  fontStyle="italic"
                >
                  Double click the Description field to edit the value. Press
                  the enter key to save.
                </Typography>
                <DataGridPro
                  slots={{
                    noRowsOverlay: SiraNoRowsOverlay,
                    toolbar: CustomGridToolbarContainer,
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: globalPageSize },
                    },
                  }}
                  pagination
                  pageSizeOptions={globalPaginationOptions}
                  onPaginationModelChange={setGlobalPageSize}
                  disableColumnMenu
                  autoHeight
                  columns={columns}
                  rows={distributionCodeData}
                  processRowUpdate={(updatedRow) => {
                    const updatedRows = distributionCodeData.map((row) => {
                      if (row.id === updatedRow.id) {
                        addOrUpdateDistributionCodee(updatedRow);
                        return updatedRow;
                      }
                      return row;
                    });
                    setDistributionCodeData(updatedRows);

                    return updatedRow;
                  }}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion
          slotProps={{
            transition: {
              mountOnEnter: true,
              unmountOnExit: true,
            },
          }}
        >
          <AccordionSummary
            disabled={isLoading}
            expandIcon={<ExpandMoreIcon />}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" color="secondary">
                Transaction Workflow Settings
              </Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            {!editWorkflowSettings ? (
              <Grid container spacing={2}>
                {spousalConsentRequired && (
                  <Grid item xs={12} md={6} mt={1}>
                    <Typography variant="h6">
                      Spousal Consent Required
                    </Typography>
                    <Typography variant="body1">
                      {spousalConsentRequired === SpousalConsentOption.community
                        ? 'Only in community or marital property states'
                        : 'In all states'}
                    </Typography>
                  </Grid>
                )}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                      Investment Rate Precision
                    </Typography>
                    <Typography variant="body1">
                      <NumberFormat
                        value={investmentRatePrecision}
                        displayType="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">APY Rate Precision</Typography>
                    <Typography variant="body1">
                      <NumberFormat
                        value={apyRatePrecision}
                        displayType="text"
                      />
                    </Typography>
                  </Grid>
                {documentationAddress && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Documentation Address</Typography>
                    <Typography variant="body1">
                      {documentationAddress ===
                      DocumentationAddress.primaryBranch
                        ? documentationAddressLabels.PRIMARY_BRANCH
                        : documentationAddressLabels.USER_BRANCH}{' '}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Show the Select Investment Options step in contribution workflows</Typography>
                  <Typography variant="body1">{displayInvestments ? 'True' : 'False'}</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Display Financial Organization Fee Amount in the Distribution Workflow</Typography>
                  <Typography variant="body1">{displayFeeAmount ? 'True' : 'False'}</Typography>
                </Grid>

                {
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                      Transaction Email Settings
                    </Typography>
                    <Typography variant="body1">
                      {orgTransactionEmailSetting}
                    </Typography>
                  </Grid>
                }
                {
                  // using this button to edit the values above
                  <Grid item xs={12}>
                    <Button
                      disabled={isLoading}
                      onClick={() => {
                        setEditWorkflowSettings(!editWorkflowSettings);
                      }}
                      startIcon={<EditIcon />}
                      variant="contained"
                      color="primary"
                    >
                      Edit Workflow Settings
                    </Button>
                  </Grid>
                }
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box width="1">
                    <Box>
                      <TransactionEmailSettingsForm
                        initialValues={{
                          ...ORG_TRANSACTION_INIT,
                          ...organization,
                        }}
                        onSubmit={async (data) => {
                          saveOrganization(data);
                          setIsLoading(true);
                        }}
                        onCancel={() => {
                          setEditWorkflowSettings(!editWorkflowSettings);
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}

export default OrganizationTransactionSettings;
