import { Distribution, DistributionReason } from '../../../api/DistributionApi.d';
import { RadioGroupOption } from '../SiraRadioField';

export enum RmdOption {
  doNotExcludeFromRmd = 'doNotExcludeFromRmd',
  excludeFromRmd = 'excludeFromRmd',
}

export interface DistributionCodeFormData extends Distribution{
  distributionReason: DistributionReason;
  rmdOptions: RmdOption;
}

export enum DistributionCodeGroup {
  empty = '',
  default = 'DEFAULT',
  qualified = 'QUALIFIED',
  disability = 'DISABILITY',
  early = 'EARLY',
  earlyPenalty = 'EARLY_PENALTY',
  rollover = 'ROLLOVER',
  prohibited = 'PROHIBITED',
  hsaprohibited = 'HSA_PROHIBITED',
  esaprohibited = 'ESA_PROHIBITED',
  rechar = 'RECHAR',
  excess = 'EXCESS',
  esaexcess = 'ESA_EXCESS',
  hsaexcess = 'HSA_EXCESS',
  revocation = 'REVOCATION',
  beneDistribution = 'BENE_DISTRIBUTION',
  excessContribution = 'EXCESS_CONTRIBUTION',
  iraconversion = 'IRA_NORMAL_ROTH_CONVERSION',
  esaTransfer = 'ESA_TRANSFER',
}

export interface DistributionCode extends RadioGroupOption {
  value: string;
  label: string;
  group: DistributionCodeGroup;
}
