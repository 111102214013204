import React from 'react';
import { Typography } from '@mui/material';

export const transferToAnotherESADiffAdd = (
  <Typography variant="body2">
    Qualifying family members include the designated beneficiary’s spouse and
    the following other relatives:{' '}
    <p style={{ paddingLeft: 5 }}>
      <li>
        Son, daughter, stepchild, foster child, adopted child, or a descendant
        of any of them.
      </li>
      <li>Brother, sister, stepbrother, or stepsister.</li>
      <li>Father or mother or ancestor of either.</li>
      <li>Stepfather or stepmother. Son or daughter of a brother or sister.</li>
      <li>
        Brother or sister of father or mother. Son-in-law, daughter-in-law,
        father-in-law, mother-in-law, brother-in-law, or sister-in-law.
      </li>
      <li>The spouse of any individual listed above.</li>
      <li>First cousin</li>
    </p>
    <strong>NOTE: </strong>The age 30 restriction may be waived for a special
    needs individual.
  </Typography>
);

export const distributionReasonHelpText = {
  inheritedIramyOwnTransfer: 'A non-reportable movement of assets from an inherited IRA to a spouse beneficiary’s own IRA of the same type. The IRA owner does not have access to the assets, and they are not subject to federal tax withholding.',
  rothQaulifiedHelpText:
    'The IRA owner has attained age 59½, and the 5-year holding period has been met. The 5-year holding period begins January 1 of the taxable year for which the IRA owner made the first contribution (regular, conversion, rollover, etc.) to any Roth IRA owned by the individual.',
  rothEarlyQaulifiedHelpText:
    'The IRA owner is disabled, and the five-year holding period has been met. The 5-year holding period begins January 1 of the taxable year for which the IRA owner made the first contribution (regular, conversion, rollover, etc.) to any Roth IRA owned by the individual.',
  rothQaulifiedDistributionWexception:
    'The IRA owner has attained age 59½, but the 5-year holding period has not been met. The 5-year holding period begins January 1 of the taxable year for which the IRA owner made the first contribution (regular, conversion, rollover, etc.) to any Roth IRA owned by the individual.',
  transferToAnotherIRA:
    'A non-reportable movement of assets from one IRA to another IRA of the same type. The IRA owner does not have access to the assets, and they are not subject to federal tax withholding.',
  normalDistribution: 'The IRA owner is age 59½ or older.',
  earlyDistributionwopenalty:
    'The IRA owner is under age 59½ and the following penalty exceptions do not apply—direct Roth IRA conversion, substantially equal periodic payments, IRS levy, or disability.',
  twoYearWaitingPeriod:
    'The IRA owner is under age 59½ and the following penalty exceptions do not apply—direct Roth IRA conversion, substantially equal periodic payments, IRS levy, or disability. The two-year waiting period begins the day contributions are first deposited into the SIMPLE IRA.',
  rothEarlyDistributionwopenalty:
    'The IRA owner is under age 59½ and the following penalty exceptions do not apply—substantially equal periodic payments, IRS levy, or disability.',
  tradOrSepIRaEarlyDistributionWpenalty:
    'The IRA owner is under age 59½ but one of the following penalty exceptions apply.',
  dueToDisability:
    'It is recommended to obtain a physician statement to certify the IRA owner is disabled, as defined under IRC section 72(m)(7). IRS Instructions for Schedule R, Credit for the Elderly or the Disabled, includes such a statement that may be used to verify an individual’s disability if it was filed with the IRA owner’s tax return.',
  dueToDirectRothIRAConversion:
    'These penalty exceptions are reported as IRS code 2. All other penalty exceptions should be reported as IRS code 1, “Early distribution without penalty exception,” and the IRA owner claims the exception on a tax return.',
  dueToSubstantialEqualPpayIRSLevy:
    'These penalty exceptions are reported as IRS code 2. All other penalty exceptions should be reported as IRS code 1, “Early distribution without penalty exception,” and the IRA owner claims the exception on a tax return.',
  dueToSubstantialEqualPpayIRSLevySIMP:
    'These penalty exceptions are reported as IRS code 2. All other penalty exceptions are reported either as IRS code 1, “Early distribution without penalty exception” (if the two-year waiting period has been met) or as IRS code S, “Early distribution in the first two years, without penalty exception” (if the two-year waiting period has not been met), and the IRA owner claims the exception on a tax return.',
  simpleIRAEarlyDistributionWpenalty:
    'The IRA owner is under age 59½ but one of the following penalty exceptions apply.',
  dueToDisability5yrHldingPnotMet:
    ' It is recommended to obtain a physician statement to certify the IRA owner is disabled, as defined under IRC section 72(m)(7). IRS Instructions for Schedule R, Credit for the Elderly or the Disabled, includes such a statement that may be used to verify an individual’s disability if it was filed with the IRA owner’s tax return. The five-year holding period begins January 1 of the taxable year for which the IRA owner made the first contribution (regular, conversion, rollover, etc.) to any Roth IRA owned by the individual.',
  dueToIRSLevy:
    'This penalty exception is reported as IRS code 2. All other penalty exceptions should be reported as IRS code J, “Early distribution without penalty exception,” and the IRA owner claims the exception on a tax return.',
  directRothIRAconversion:
    'The assets are moving directly from an IRA to a Roth IRA, without the IRA owner having access to the assets. However, the transaction is considered a taxable distribution.',
  directRollOverTradSepIRAEmpSponsored:
    'The assets are moving directly from an IRA to an eligible employer-sponsored retirement plan, without the IRA owner having access to the assets.',
  directRollOverSimpleIRAEmpSponsored:
    'The assets are moving directly from an IRA to an eligible employer-sponsored retirement plan, without the IRA owner having access to the assets. The two-year waiting period begins the day contributions are first deposited into the SIMPLE IRA.',
  removalOfExcessContribution:
    'An excess contribution occurs when an IRA owner contributes more than the individual is eligible to contribute to the IRA.',
  removalOfExcessContributionSame:
    'The excess removal deadline is generally six months following the IRA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made).',
  removalOfExcessContributionPrior:
    'The excess removal deadline is generally six months following the IRA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made).',
  q_removalOfExcess5yr:
    'The excess removal deadline is generally six months following the IRA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made). The 5-year holding period begins January 1 of the taxable year for which the IRA owner made the first contribution (regular, conversion, rollover, etc.) to any Roth IRA owned by the individual.',
  q_removalOfExcess5yrNotMet:
    'The excess removal deadline is generally six months following the IRA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made). The 5-year holding period begins January 1 of the taxable year for which the IRA owner made the first contribution (regular, conversion, rollover, etc.) to any Roth IRA owned by the individual.',
  tradSepSimpleIraEPCRS:
    'These include excess employer contributions (other than elective deferrals), and the earnings on them, under SEP, SARSEP, and SIMPLE IRA plans that are removed and returned to an employer with the owner’s consent.',
  recharacterization:
    'A recharacterization occurs when an IRA owner moves an IRA contribution from one type of IRA to another type of IRA either by a transfer between organizations or within the same organization.',
  recharacterizationSame:
    'This only includes recharacterizations of contributions that were made for the current year. ',
  recharacterizationPrior:
    'This only includes recharacterizations of contributions that were made for the prior year (including those deposited in the current year but attributed to the prior year).',
  revocation:
    'A revocation may occur if the IRA owner decides to revoke the IRA within seven days from receiving the disclosure statement for opening the account. Select the appropriate reason below to indicate how the IRA was originally funded by the IRA owner and if earnings will also be removed from the IRA.',
  prohibitedTransaction:
    'If an IRA owner engages in a prohibited transaction, the entire IRA is treated as a distribution as of January 1 of the year in which the transaction takes place.',
  prohibitedTransactionESA:
    'If an ESA owner engages in a prohibited transaction, the entire ESA is treated as a distribution as of January 1 of the year in which the transaction takes place.',
  prohibitedTransactionisqaulifiedAndIRA:
    'Of any of the following that occurred between the IRA and a disqualified person that is prohibited by law:',
  disqaulifiedAndIRASubHelpText: {
    sale: 'the sale, exchange, or leasing of any property; ',
    lending: 'the lending of money or other extension of credit; ',
    furnishing: 'the furnishing of goods, services, or facilities; ',
    transfer:
      'the transfer of the IRA income or assets to, or use by or for the benefit of, a disqualified person; ',
    fiduciary:
      'any act by a fiduciary whereby the IRA income or assets are used for the person’s own interest; and ',
    receipt:
      'the receipt of consideration by fiduciary for the person’s own account from any party dealing with the IRA in a transaction that involves IRA income or assets.',
  },
  transferToAnotherHSA:
    'A non-reportable movement of assets from one HSA to another HSA of the same type. The HSA owner does not have access to the assets.',
  dueToDisabilityHSA:
    'It is recommended to obtain a physician statement to certify the HSA owner is disabled, as defined under IRC section 72(m)(7). IRS Instructions for Schedule R, Credit for the Elderly or the Disabled, includes such a statement that may be used to verify an individual’s disability if it was filed with the HSA owner’s tax return. An individual that meets this definition avoids the 20 percent additional penalty tax on nonqualified distributions.',
  hsaBeforeRemovalExcess:
    'The excess removal deadline is generally six months following the HSA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made).',
  hsaAfterRemovalExcess:
    'The excess removal deadline is generally six months following the HSA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made).',
  hsaRemovalofExcessContribution:
    'An excess contribution occurs when an HSA owner contributes more than the individual is eligible to contribute to the HSA. Only a true excess can be removed from an HSA.',
  hsaProhibitedTransaction:
    'If an HSA owner engages in a prohibited transaction, the entire HSA is treated as a distribution as of January 1 of the year in which the transaction takes place.',

  transferToAnotherESASame:
    'A movement of assets from one Coverdell ESA to another ESA for the same designated beneficiary. The responsible individual/designated beneficiary does not have access to the assets.',

  dueToDisabilityESA:
    'It is recommended to obtain a physician statement to certify the designated beneficiary is disabled, as defined under IRC section 72(m)(7). IRS Instructions for Schedule R, Credit for the Elderly or the Disabled, includes such a statement that may be used to verify an individual’s disability if it was filed with a tax return.',
  esaBeforeRemovalExcess:
    'The excess removal deadline is generally before the first day of the sixth month following the taxable year (typically May 31 of the year following the tax year for which the contribution was made).',
  esaRemovalofExcessContribution:
    'An excess contribution occurs when contributors deposit more than they are eligible to contribute to the ESA. Only a true excess can be removed from an ESA.',
  esaProhibitedTransaction:
    'If the responsible individual/designated beneficiary engages in a prohibited transaction, the entire ESA is treated as a distribution as of January 1 of the year in which the transaction takes place.',
  transferInheritedRothIra:
    'A non-reportable movement of assets from one IRA to another IRA of the same type. The IRA owner does not have access to the assets, and they are not subject to federal tax withholding.',
  transferInheritedTradIra:
    'A non-reportable movement of assets from one IRA to another IRA of the same type. The IRA owner does not have access to the assets, and they are not subject to federal tax withholding.',
  excessAfterInheritedTradIra:
    'The excess removal deadline is generally six months following the IRA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made).',
  excessPriorInheritedTradIra:
    'The excess removal deadline is generally six months following the IRA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made).',
  excessSameInheritedTradIra:
    'The excess removal deadline is generally six months following the IRA owner’s tax filing deadline (typically October 15 of the year following the tax year for which the contribution was made).',
  deathDistributionInheritedTradIra: `Use Code 4 regardless of the age of the participant to indicate payment to a decedent's beneficiary, including an estate or trust.`,
};

export const distributionAmountHelpText = {
  distributeEntireBalCloseAcct: {
    label: 'distributeEntireBalCloseAcct',
    value:
      'Select this option only if the distribution will leave the account with a zero balance and thus close the account.',
  },
  totalNetIndicator: {
    label: 'Total Amount',
    value:
      'Select “total” to enter the entire amount of the distribution leaving the account before any tax withholding or financial organization penalties are assessed. Or select “net” to enter the amount the account owner wants to receive after any tax withholding or financial organization penalties are assessed.',
    afterContent: (
      <Typography variant="body2">
        Select “total” to enter the entire amount of the distribution leaving
        the account before any tax withholding or financial organization
        penalties are assessed. Or select “net” to enter the amount the account
        owner wants to receive after any tax withholding or financial
        organization penalties are assessed.
      </Typography>
    ),
  },
  financialOrgPenaltyAmount: {
    label: 'Financial Organization Fee Amount',
    afterContent: (
      <Typography variant="body2">
        Enter the total amount of any fees or investment penalties assessed on
        the distribution by your organization, if applicable.
      </Typography>
    ),
  },
  financialOrgPenaltyAmountHSA: {
    label: 'Financial Organization Fee Amount',
    afterContent: (
      <Typography variant="body2">
        Enter the total amount of any fees or investment penalties assessed on
        the distribution of the excess contribution by your organization, if
        applicable.
      </Typography>
    ),
  },
  calcNIARemoveExcessContribution: {
    label:
      'Calculate the net income attributable (NIA) to remove with the excess contribution',
    afterContent: (
      <Typography variant="body2">
        In addition to removing the excess or recharacterized contribution, any
        income that was generated by that contribution while it was in the IRA
        (the net income attributable) must also be removed, taking into account
        a pro rata portion of the earnings on the contribution amount against
        all assets in the IRA. Select this option to complete the calculation.
      </Typography>
    ),
  },
  calcHSANIARemoveExcessContribution: {
    label:
      'Calculate the net income attributable (NIA) to remove with the excess contribution',
    afterContent: (
      <Typography variant="body2">
        In addition to removing the excess contribution, any income that was
        generated by that contribution while it was in the HSA (the net income
        attributable) must also be removed, taking into account a pro rata
        portion of the earnings on the contribution amount against all assets in
        the HSA. Select this option to complete the calculation.
      </Typography>
    ),
  },
  calcESANIARemoveExcessContribution: {
    label:
      'Calculate the net income attributable (NIA) to remove with the excess contribution',
    afterContent: (
      <Typography variant="body2">
        In addition to removing the excess contribution, any income that was
        generated by that contribution while it was in the ESA (the net income
        attributable) must also be removed, taking into account a pro rata
        portion of the earnings on the contribution amount against all assets in
        the ESA. Select this option to complete the calculation.
      </Typography>
    ),
  },
  enterNIAwithoutCalculating: {
    label: 'Enter the NIA without calculating',
    afterContent: (
      <Typography variant="body2">
        In addition to removing the excess or recharacterized contribution, any
        income that was generated by that contribution while it was in the IRA
        (the net income attributable) must also be removed, taking into account
        a pro rata portion of the earnings on the contribution amount against
        all assets in the IRA. Select this option if you already know the NIA
        amount to remove (applicable to revocations in most cases).
      </Typography>
    ),
  },
  enterHSANIAwithoutCalculating: {
    label: 'Enter the NIA without calculating',
    afterContent: (
      <Typography variant="body2">
        In addition to removing the excess contribution, any income that was
        generated by that contribution while it was in the HSA (the net income
        attributable) must also be removed, taking into account a pro rata
        portion of the earnings on the contribution amount against all assets in
        the HSA. Select this option if you already know the NIA amount to
        remove.
      </Typography>
    ),
  },
  enterESANIAwithoutCalculating: {
    label: 'Enter the NIA without calculating',
    afterContent: (
      <Typography variant="body2">
        In addition to removing the excess contribution, any income that was
        generated by that contribution while it was in the ESA (the net income
        attributable) must also be removed, taking into account a pro rata
        portion of the earnings on the contribution amount against all assets in
        the ESA. Select this option if you already know the NIA amount to
        remove.
      </Typography>
    ),
  },
  excessContributionAmountRechar: {
    label: 'Excess Contribution Amount',
    afterContent: (
      <Typography variant="body2">
        Enter the amount of the contribution being removed as an excess or being
        recharacterized.
      </Typography>
    ),
  },
  excessHSAContributionAmountRechar: {
    label: 'Excess Contribution Amount',
    afterContent: (
      <Typography variant="body2">
        Enter the amount of the contribution being removed as an excess.
      </Typography>
    ),
  },
  accountBalBeforeContributionMade: {
    label: 'Account Balance before contribution was made',
    afterContent: (
      <Typography variant="body2">
        Enter the balance of the IRA immediately before the excess or
        recharacterized contribution was made to the account.
      </Typography>
    ),
  },
  accountBalHSABeforeContributionMade: {
    label: 'Account Balance before contribution was made',
    afterContent: (
      <Typography variant="body2">
        Enter the balance of the HSA immediately before the excess contribution
        was made to the account.
      </Typography>
    ),
  },
  accountBalESABeforeContributionMade: {
    label: 'Account Balance before contribution was made',
    afterContent: (
      <Typography variant="body2">
        Enter the balance of the ESA immediately before the excess contribution
        was made to the account.
      </Typography>
    ),
  },
  currentAccountBalance: {
    label: 'Current Account Balance',
    afterContent: (
      <Typography variant="body2">
        Enter the current balance of the IRA immediately before the excess or
        recharacterized contribution amount is removed from the account.
      </Typography>
    ),
  },
  currentHSAAccountBalance: {
    label: 'Current Account Balance',
    afterContent: (
      <Typography variant="body2">
        Enter the current balance of the HSA immediately before the excess
        contribution amount is removed from the account.
      </Typography>
    ),
  },
  currentESAAccountBalance: {
    label: 'Current Account Balance',
    afterContent: (
      <Typography variant="body2">
        Enter the current balance of the ESA immediately before the excess
        contribution amount is removed from the account.
      </Typography>
    ),
  },
  ttlDepositMadeSinceImmedBeforExcessContribution: {
    label: 'Total Deposits during Computation Period',
    afterContent: (
      <Typography variant="body2">
        Enter the total amount of all contributions made to the IRA since
        immediately before the excess or recharacterized contribution was
        originally made to the account. Contributions include, but are not
        limited to, the excess or recharacterized contribution, transfers,
        rollovers, conversions, and recharacterizations.
      </Typography>
    ),
  },
  ttlDistributionMadeSncBeforeExcessContribution: {
    label: 'Total Distributions during Computation Period',
    afterContent: (
      <Typography variant="body2">
        Enter the total amount of all distributions made to the IRA since
        immediately before the excess or recharacterized contribution was
        originally made to the account. Distributions include, but are not
        limited to, transfers, rollovers, and recharacterizations (but do not
        include the distribution of the excess contribution/recharacterization
        currently being removed).
      </Typography>
    ),
  },
  ttlDepositMadeSinceImmedBeforExcessContributionHSA: {
    label: 'Total Deposits during Computation Period',
    afterContent: (
      <Typography variant="body2">
        Enter the total amount of all contributions made to the HSA since
        immediately before the excess contribution was originally made to the
        account. Contributions include, but are not limited to, the excess
        contribution, transfers, or rollovers.
      </Typography>
    ),
  },
  ttlDistributionMadeSncBeforeExcessContributionHSA: {
    label: 'Total Distributions during Computation Period',
    afterContent: (
      <Typography variant="body2">
        Enter the total amount of all distributions made to the HSA since
        immediately before the excess contribution was originally made to the
        account. Distributions include, but are not limited to, transfers and
        rollovers (but do not include the distribution of the excess
        contribution currently being removed).
      </Typography>
    ),
  },
  ttlDepositMadeSinceImmedBeforExcessContributionESA: {
    label: 'Total Deposits during Computation Period',
    afterContent: (
      <Typography variant="body2">
        Enter the total amount of all contributions made to the ESA since
        immediately before the excess contribution was originally made to the
        account. Contributions include, but are not limited to, the excess
        contribution, transfers, and rollovers.
      </Typography>
    ),
  },
  ttlDistributionMadeSncBeforeExcessContributionESA: {
    label: 'Total Distributions during Computation Period',
    afterContent: (
      <Typography variant="body2">
        Enter the total amount of all distributions made to the ESA since
        immediately before the excess contribution was originally made to the
        account. Distributions include, but are not limited to, transfers and
        rollovers (but do not include the distribution of the excess
        contribution currently being removed).
      </Typography>
    ),
  },
  determineWithholdingBasedonFillingStatusAndIncome: {
    label: 'Determine suggested withholding based on Marginal Rate Tables',
    value:
      'Enter the filing status used on the account owner’s tax return and the account owner’s total income from all sources to determine the recommended federal tax withholding rate. See Form W-4R, Withholding Certificate for Nonperiodic Payments and Eligible Rollover Distributions, for more information.',
    afterContent: (
      <Typography variant="body2">
        Enter the filing status used on the account owner’s tax return and the
        account owner’s total income from all sources to determine the
        recommended federal tax withholding rate. See Form W-4R, Withholding
        Certificate for Nonperiodic Payments and Eligible Rollover
        Distributions, for more information.
      </Typography>
    ),
  },
  percentageWithholdForFederalTaxes: {
    label: 'Enter rate (between 1-100%)',
    afterContent: (
      <Typography variant="body2">
        Must be a whole number between 0–100 percent.
      </Typography>
    ),
  },
  confirmOrUpdatedpercentageWithholdForFederalTaxes: {
    label:
      'Confirm or update the percentage you will withhold for federal taxes.',
    afterContent: (
      <Typography variant="body2">
        Must be a whole number between 0–100 percent.
      </Typography>
    ),
  },
};

export const distributionAmountLabelList = [
  'Total Amount',
  'Financial Organization Fee Amount',
  'Calculate the net income attributable (NIA) to remove with the excess contribution',
  'Enter the NIA without calculating',
  'Excess Contribution Amount',
  'Account Balance before contribution was made',
  'Current Account Balance',
  'Total Deposits during Computation Period',
  'Total Distributions during Computation Period',
  'Enter rate (between 1-100%)',
  'Confirm or update the percentage you will withhold for federal taxes.',
];

export const niaSelectionHelpText = [
  'Enter the NIA without calculating',
  'Calculate the net income attributable (NIA) to remove with the excess contribution',
];

export const distributionMethods = {
  tradIRA: {
    check: {
      value:
        'Check must be made payable to the receiving financial organization for benefit of the owner’s IRA (e.g., ABC Financial Organization as Custodian FBO John Smith’s Traditional IRA).',
      afterContent: (
        <Typography variant="body2">
          Check must be made payable to the receiving financial organization for
          benefit of the owner’s IRA (e.g., ABC Financial Organization as
          Custodian FBO John Smith’s Traditional IRA).
        </Typography>
      ),
    },
    transferToAccount: {
      value:
        'It has been two or more years since the first SIMPLE IRA contribution was made to the SIMPLE IRA.',
      afterContent: (
        <Typography variant="body2">
          It has been two or more years since the first SIMPLE IRA contribution
          was made to the SIMPLE IRA.
        </Typography>
      ),
    },
    transferToAccountAnother: {
      value:
        'It has been two or more years since the first SIMPLE IRA contribution was made to the SIMPLE IRA.',
      afterContent: (
        <Typography variant="body2">
          It has been two or more years since the first SIMPLE IRA contribution
          was made to the SIMPLE IRA.
        </Typography>
      ),
    },
  },
  simpleIRA: {
    check: {
      value:
        'Check must be made payable to the receiving financial organization for benefit of the owner’s IRA (e.g., ABC Financial Organization as Custodian FBO John Smith’s SIMPLE IRA).',
      afterContent: (
        <Typography variant="body2">
          Check must be made payable to the receiving financial organization for
          benefit of the owner’s IRA (e.g., ABC Financial Organization as
          Custodian FBO John Smith’s SIMPLE IRA).
        </Typography>
      ),
    },
    transferToAccount: {
      afterContent: (
        <Typography variant="body2">
          It has been two or more years since the first SIMPLE IRA contribution
          was made to the receiving SIMPLE IRA.
        </Typography>
      ),
      accountType: {
        tradIra: (
          <Typography variant="body2">
            It has been two or more years since the first SIMPLE IRA
            contribution was made to the SIMPLE IRA.
          </Typography>
        ),
        sepIra: (
          <Typography variant="body2">
            It has been two or more years since the first SIMPLE IRA
            contribution was made to the SIMPLE IRA.
          </Typography>
        ),
      },
    },
    transferToAccountAnother: {
      accountType: {
        tradIra: (
          <Typography variant="body2">
            It has been two or more years since the first SIMPLE IRA
            contribution was made to the SIMPLE IRA.
          </Typography>
        ),
        sepIra: (
          <Typography variant="body2">
            It has been two or more years since the first SIMPLE IRA
            contribution was made to the SIMPLE IRA.
          </Typography>
        ),
      },
    },
  },
  sepIRA: {
    check: {
      afterContent: (
        <Typography variant="body2">
          Check must be made payable to the receiving financial organization for
          benefit of the owner’s IRA (e.g., ABC Financial Organization as
          Custodian FBO John Smith’s SEP IRA).
        </Typography>
      ),
    },
    transferToAccount: {
      afterContent: (
        <Typography variant="body2">
          It has been two or more years since the first SIMPLE IRA contribution
          was made to the SIMPLE IRA.
        </Typography>
      ),
    },
    transferToAccountAnother: {
      afterContent: (
        <Typography variant="body2">
          It has been two or more years since the first SIMPLE IRA contribution
          was made to the SIMPLE IRA.
        </Typography>
      ),
    },
    dueDirectRothIRAConv: {
      check: {
        afterContent: (
          <Typography variant="body2">
            Check must be made payable to the receiving financial organization
            for benefit of the owner’s IRA (e.g., ABC Financial Organization as
            Custodian FBO John Smith’s Traditional IRA).
          </Typography>
        ),
      },
    },
    directRothIRAConv: {
      check: {
        afterContent: (
          <Typography variant="body2">
            Check must be made payable to the receiving financial organization
            for benefit of the owner’s IRA (e.g., ABC Financial Organization as
            Custodian FBO John Smith’s Traditional IRA).
          </Typography>
        ),
      },
    },
    directRollover: {
      check: {
        afterContent: (
          <Typography variant="body2">
            Check must be made payable to the receiving financial organization
            for benefit of the owner’s IRA (e.g., ABC Financial Organization as
            Custodian FBO John Smith’s Traditional IRA).
          </Typography>
        ),
      },
    },
    recharacterization: {
      check: {
        afterContent: (
          <Typography variant="body2">
            Check must be made payable to the receiving financial organization
            for benefit of the owner’s IRA (e.g., ABC Financial Organization as
            Custodian FBO John Smith’s Traditional IRA).
          </Typography>
        ),
      },
    },
  },
  rothIRA: {
    check: {
      afterContent: (
        <Typography variant="body2">
          Check must be made payable to the receiving financial organization for
          benefit of the owner’s IRA (e.g., ABC Financial Organization as
          Custodian FBO John Smith’s Roth IRA).
        </Typography>
      ),
    },
    recharacterization: {
      check: {
        afterContent: (
          <Typography variant="body2">
            Check must be made payable to the receiving financial organization
            for benefit of the owner’s IRA (e.g., ABC Financial Organization as
            Custodian FBO John Smith’s Roth IRA).
          </Typography>
        ),
      },
    },
  },
  hsa: {
    check: {
      value:
        'Check must be made payable to the receiving financial organization for benefit of the owner’s HSA (e.g., ABC Financial Organization as Custodian FBO John Smith’s HSA).',
      afterContent: (
        <Typography variant="body2">
          Check must be made payable to the receiving financial organization for
          benefit of the owner’s IRA (e.g., ABC Financial Organization as
          Custodian FBO John Smith’s SIMPLE IRA).
        </Typography>
      ),
    },
    transferToAnotherHSA: {
      check: {
        afterContent: (
          <Typography variant="body2">
            Check must be made payable to the receiving financial organization
            for benefit of the owner’s HSA (e.g., ABC Financial Organization as
            Custodian FBO John Smith’s HSA).
          </Typography>
        ),
      },
    },
  },
  esa: {
    transferToAnotherESASame: {
      check: {
        afterContent: (
          <Typography variant="body2">
            Check must be made payable to the receiving financial organization
            for benefit of the owner’s Coverdell ESA (e.g., ABC Financial
            Organization as Custodian FBO John Smith’s ESA).
          </Typography>
        ),
      },
    },
    transferToAnotherESADiff: {
      check: {
        afterContent: (
          <Typography variant="body2">
            Check must be made payable to the receiving financial organization
            for benefit of the owner’s Coverdell ESA (e.g., ABC Financial
            Organization as Custodian FBO John Smith’s ESA).
          </Typography>
        ),
      },
    },
  },
  default: {
    check: {
      afterContent: (
        <Typography variant="body2">
          If the owner is making a qualified charitable distribution, enter the
          name of the charity in the Check Payable To field.
        </Typography>
      ),
    },
  },
};

export const eligibleDesignatedBeneficiary = (
  <Typography variant="body2">
    <strong>Eligible Designated Beneficiary: </strong>Upon an IRA owner’s death,
    the beneficiary may elect between the ten-year rule and the life expectancy
    option if the individual is an eligible designated beneficiary. Eligible
    designated beneficiaries include:
    <p style={{ paddingLeft: 5 }}>
      <li>The spouse of the deceased owner</li>
      <li>
        Disabled individuals as defined under Internal Revenue Code Section
        72(m)
      </li>
      <li>
        Chronically ill individuals as defined in Internal Revenue Code Section
        401(1)(9)(E)(ii)(IV)
      </li>
      <li>Minor children of the deceased owner</li>
      <li>
        Individuals older than or not more than 10 years younger than the
        deceased owner
      </li>
    </p>
  </Typography>
);

export const fiveYearTest = (
  <Typography variant="body2">
    <strong>Five-year holding period has been satisfied: </strong>The five-year
    holding period begins January 1 of the taxable year for which the IRA owner
    made the first contribution (regular, conversion, rollover, etc.) to any
    Roth IRA owned by the individual.
  </Typography>
);
