import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { Formik, Form, Field, useFormikContext } from 'formik';

import {
  TransferRequest,
  TransferRequestRelationship,
} from '../../../api/TransferApi.d';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';
import TaxIdField, { ssnValidation, taxIdValidation } from '../TaxIdField';
import getTransferOwnerOptions from './getTransferOwnerOptions';
import { AccountType } from '../../../api/AccountApi.d';
import { requestRolloverTransfer } from './resource.txt';
import SiraTransferRadioField from '../SiraTransferRadioField';

export const TRANSFER_OWNER_INIT: TransferRequest = {
  relationshipToCurrentOwner: TransferRequestRelationship.default,
  sourceAccountType: '' as AccountType,
  sourceOwnerFirstName: '',
  sourceOwnerLastName: '',
  sourceOwnerMiddleInitial: '',
  sourceOwnerName: '',
  sourceOwnerTaxIdNumber: '',
};

function isCurrentOwner(
  relationshipToCurrentOwner: TransferRequestRelationship
): boolean {
  return (
    relationshipToCurrentOwner === TransferRequestRelationship.currentOwner ||
    relationshipToCurrentOwner === TransferRequestRelationship.currentDesBene
  );
}

export const TRANSFER_OWNER_SCHEMA = yup.object({
  relationshipToCurrentOwner: yup
    .string()
    .required()
    .label('Relationship to Current Owner'),
  sourceOwnerFirstName: yup
    .string()
    .label('Owner First Name')
    .when(['relationshipToCurrentOwner', 'sourceOwnerName'], (...args) => {
      const [relationshipToCurrentOwner, sourceOwnerName, schema] = args;
      // Required unless owner is non-human
      return !isCurrentOwner(relationshipToCurrentOwner) && !sourceOwnerName
        ? schema.required()
        : schema;
    }),
  sourceOwnerLastName: yup
    .string()
    .label('Owner Last Name')
    .when(['relationshipToCurrentOwner', 'sourceOwnerName'], (...args) => {
      const [relationshipToCurrentOwner, sourceOwnerName, schema] = args;
      // Required unless owner is non-human
      return !isCurrentOwner(relationshipToCurrentOwner) && !sourceOwnerName
        ? schema.required()
        : schema;
    }),
  sourceOwnerMiddleInitial: yup.string(),
  sourceOwnerName: yup.string(),
  sourceOwnerTaxIdNumber: yup
    .string()
    .when(['relationshipToCurrentOwner', 'sourceOwnerName'], (...args) => {
      const [relationshipToCurrentOwner, sourceOwnerName, schema] = args;
      // Validate as EIN if non-human is the transfer owner
      if (!isCurrentOwner(relationshipToCurrentOwner)) {
        if (sourceOwnerName) {
          return taxIdValidation().required();
        }

        return ssnValidation().required();
      }

      return schema;
    }),
});

export interface TransferSourceOwnerFormProps {
  accountOwner: AccountOwner;
  sourceAccountType: AccountType;
  destinationAccountType: AccountType;
  initialValues: TransferRequest;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

function TransferOwnerSubForm(props) {
  const { options = [], accountOwner = {} } = props;
  const { values, setValues } = useFormikContext();
  const { relationshipToCurrentOwner } = values as TransferRequest;

  const showNameFields =
    relationshipToCurrentOwner &&
    ![
      TransferRequestRelationship.currentOwner,
      TransferRequestRelationship.currentDesBene,
    ].includes(relationshipToCurrentOwner);

  // Clear fields name fields if current owner is selected
  useEffect(() => {
    if (isCurrentOwner(relationshipToCurrentOwner)) {
      setValues({
        ...(values as TransferRequest),
        sourceOwnerFirstName: accountOwner.firstName,
        sourceOwnerMiddleInitial: '',
        sourceOwnerLastName: accountOwner.lastName,
        sourceOwnerTaxIdNumber: accountOwner.taxpayerIdNumber,
        sourceOwnerName: accountOwner.name,
      });
    } else {
      setValues({
        ...(values as TransferRequest),
        sourceOwnerFirstName: '',
        sourceOwnerMiddleInitial: '',
        sourceOwnerLastName: '',
        sourceOwnerTaxIdNumber: '',
        sourceOwnerName: '',
      });
    }
  }, [showNameFields]);

  // Clear out form if a relationship option value is preloaded that isn't displayed
  useEffect(() => {
    const validOptionValues = options.map(({ value }) => value);

    if (!validOptionValues.includes(relationshipToCurrentOwner)) {
      setValues({
        ...(values as TransferRequest),
        relationshipToCurrentOwner: '',
      });
    }
  }, []);

  return (
    showNameFields && (
      <>
        <Grid item xs={12} sm={5}>
          <SiraTextField name="sourceOwnerFirstName" label="First Name" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SiraTextField
            maxLength={1}
            name="sourceOwnerMiddleInitial"
            label="MI"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <SiraTextField name="sourceOwnerLastName" label="Last Name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TaxIdField name="sourceOwnerTaxIdNumber" label="Tax ID" ssn />
        </Grid>
      </>
    )
  );
}

function TransferSourceOwnerForm({
  accountOwner,
  sourceAccountType,
  destinationAccountType,
  initialValues,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
}: TransferSourceOwnerFormProps) {
  const isOwnerHuman = Boolean(accountOwner.firstName);
  const transferSourceOwnerOptions = getTransferOwnerOptions(
    destinationAccountType,
    sourceAccountType,
    isOwnerHuman
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        onSubmit(data);
      }}
      onReset={() => {
        onReset();
      }}
      validationSchema={TRANSFER_OWNER_SCHEMA}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Field
                  label={requestRolloverTransfer.relationshipToCurrentOwnerTxt}
                  name="relationshipToCurrentOwner"
                  accountType={destinationAccountType}
                  options={transferSourceOwnerOptions}
                  component={SiraTransferRadioField}
                />
              </Grid>
              <TransferOwnerSubForm
                options={transferSourceOwnerOptions}
                accountOwner={accountOwner}
              />
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default TransferSourceOwnerForm;
