import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraCurrencyField from '../SiraCurrencyField';
import {
  Distribution,
  DistributionTypeEnum,
} from '../../../api/DistributionApi.d';
import SiraDateField, { dateValidation } from '../SiraDateField';
import { useStates } from '../useStates';

export const SCHED_DIST_INIT: Distribution = {
  totalAmount: 0,
  federalWithholdingAmount: 0,
  stateWithholdingAmount: 0,
  distributionType: DistributionTypeEnum.SCHEDULED,
};

export const RECON_SCHED_DIST_SCHEMA = () => {
  const yupObject = {
    totalAmount: yup.number().min(0).required().label('Total Amount'),
    federalWithholdingAmount: yup
      .number()
      .min(0)
      .required()
      .label('Federal Withholding'),
    stateWithholdingAmount: yup
      .number()
      .min(0)
      .required()
      .label('State Withholding'),
    effectiveDate: dateValidation().label('Distribution Date'),
  };
  return yupObject;
};

export interface ReconcileScheduledDistributionProps {
  initialValues: Distribution;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  stateAbbreviation?: string;
}

function ReconcileScheduledDistributionForm({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
  stateAbbreviation,
}: ReconcileScheduledDistributionProps) {
  const { states = [] } = useStates();
  const [stateName, setStateName] = useState('State');
  useEffect(() => {
    if (stateAbbreviation && states) {
      const foundState = states.find(
        (state) => state.abbreviation === stateAbbreviation
      );
      if (foundState && foundState.name) {
        setStateName(foundState.name);
      }
    }
  }, [stateAbbreviation, states]);

  return (
    <section>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
        onReset={() => onReset()}
        validationSchema={yup.object(RECON_SCHED_DIST_SCHEMA())}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <SiraCurrencyField name="totalAmount" label="Amount" />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={9}>
                <SiraCurrencyField
                  name="federalWithholdingAmount"
                  label="Federal Withholding Amount"
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={9}>
                <SiraCurrencyField
                  name="stateWithholdingAmount"
                  label={`${stateName} Withholding Amount`}
                />
              </Grid>
              <Grid item xs={3} />
                <Grid item xs={9}>
                  <SiraDateField
                    name="effectiveDate"
                    label="Distribution Date"
                  />
                </Grid>
            </Grid>

            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        )}
      </Formik>
    </section>
  );
}

export default ReconcileScheduledDistributionForm;
