import React from 'react';
import { Box, Grid, Typography, Chip, Select, MenuItem } from '@mui/material';

import { SiraSelectItem } from '../SiraSelectField';
import { Tax5498FormType } from '../../../api/TaxFormApi.d';

interface TaxReportingSelectField5498Props {
  onChange: Function;
  taxFormTypes: Array<Tax5498FormType | string>;
}

export const supportedTaxFormTypes: Array<SiraSelectItem> = [
  {
    value: 'IRA',
    label: Tax5498FormType.IRA,
  },
  {
    value: 'ESA',
    label: Tax5498FormType.ESA,
  },
  {
    value: 'HSA',
    label: Tax5498FormType.HSA,
  },
];

function TaxReportingSelectField5498(props: TaxReportingSelectField5498Props) {
  const { onChange = () => {}, taxFormTypes } = props;

  // Allow the tax type filter items to be selected or deselected
  const handleTaxFormTypeSelect = (taxTypes: Array<Tax5498FormType>) => {
    onChange(taxTypes);
  };

  // Allow the chip tax types in the filter to be deleted
  const handleTaxFormTypeDelete = (valueToExclude): void => {
    onChange(taxFormTypes.filter((value) => value !== valueToExclude));
  };

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap" pb={2}>
      <Grid item>
        <Typography noWrap variant="body2">
          Show tax form types:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          onChange={(e) => {
            handleTaxFormTypeSelect(e.target.value as Array<Tax5498FormType>);
          }}
          value={taxFormTypes}
          multiple
          renderValue={(selected: Array<any>) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const label = value;
                return (
                  <Chip
                    key={value}
                    label={label}
                    onDelete={() => {
                      handleTaxFormTypeDelete(value);
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation(); // Prevents clicking the chip from opening the select box
                    }}
                  />
                );
              })}
            </Box>
          )}
        >
          {supportedTaxFormTypes.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}

export default TaxReportingSelectField5498;
