import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import OrganizationSearchForm from '../components/form/orgSearch/OrganizationSearchForm';
import {
  Organization,
  OrganizationSearchResponse,
} from '../api/OrganizationApi.d';
import OrganizationSearchResults from '../components/form/orgSearch/OrganizationSearchResults';
import { useUser } from '../auth/useUser';
import { useGlobalContext } from '../auth/useGlobalContext';
import { FINANCIAL_ORG_INIT } from '../components/form/organization/FinancialOrgForm';
import { getOrg } from '../api/OrganizationApi';
import RecentOrganizationSearch from '../components/form/orgSearch/RecentOrganizationSearch';

function OrganizationSearch() {
  const [response, setResponse] = useState({});
  const [savedResponse, setSavedResponse] = useState([]);
  const navigate = useNavigate();
  const { setCurrentOrg } = useGlobalContext();
  const { user, setOrganizationId } = useUser();
  const { query = '' } = response as OrganizationSearchResponse;
  const [isStoredSession, setisStoredSession] = useState(
    JSON.parse(sessionStorage.getItem('recentOrgSearchSelections')) !== null
  );
  const [storedSessionValues, setstoredSessionValues] = useState(
    JSON.parse(sessionStorage.getItem('recentOrgSearchSelections'))
  );
  const [isSearchExpanded, setisSearchExpanded] = useState(false);

  useEffect(() => {
    const getStored = JSON.parse(
      sessionStorage.getItem('recentOrgSearchSelections')
    );
    setstoredSessionValues(getStored);
  }, []);

  useEffect(() => {
    if (query !== '') {
      setisStoredSession(false);
    }
    setisSearchExpanded(true);
  }, [query]);

  useEffect(() => {
    const savedOrgSearch =
      (storedSessionValues || []).find(
        ({ userEmail }) => user.email === userEmail
      ) || {};

    const { organizationInfo } = savedOrgSearch;
    setSavedResponse(organizationInfo);
  }, [storedSessionValues]);

  const setSessionedStorage = (result): void => {
    const storing = [
      {
        userEmail: user.email,
        organization: result.financialOrganizationId,
        organizationInfo: [],
      },
    ];

    let recentItems = JSON.parse(
      sessionStorage.getItem('recentOrgSearchSelections')
    );

    if (recentItems) {
      // if an org an org is a multi org it really doesn't matter which org
      // entry we use we should just add to what out there.
      // add to the first one
      const existingOrgEntry = recentItems.find(
        (entry) => entry.userEmail === user.email
      );

      const isDupDataEntry = recentItems.find((entry) =>
          entry.organizationInfo.includes(result.financialOrganizationId)
        );

      // if the user email exist and there is no dulicate data entry add the new selection
      if (existingOrgEntry && !isDupDataEntry) {
        existingOrgEntry.organizationInfo.unshift(result);
      } 
      
      // if the user email does not exist and there is no duplicate data entry add the new selection
      if (!existingOrgEntry && !isDupDataEntry){
        storing[0].organizationInfo.unshift(result);
        recentItems.push(storing[0]);
      }
    } else {
      recentItems = storing;
      recentItems[0].organizationInfo.unshift(result);
    }

    sessionStorage.setItem(
      'recentOrgSearchSelections',
      JSON.stringify(recentItems)
    );
  };

  const selectOrgAndGoToProfile = async (
    result: Organization
  ): Promise<void> => {
    const { financialOrganizationId = '' } = result || {};
    setSessionedStorage(result);

    setOrganizationId(financialOrganizationId);

    await getOrg(financialOrganizationId, user.token, user).then((res) => {
      setCurrentOrg({ ...FINANCIAL_ORG_INIT, ...res.data });
    });

    if (financialOrganizationId) {
      navigate(`/accountOwner`);
    }
  };

  const setSessionExpansion = (e, expanded: boolean): void => {
    setisStoredSession(expanded);
  };

  const setSearchExpansion = (e, expanded: boolean): void => {
    setisSearchExpanded(expanded);
  };

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Financial Organization Search
          </Typography>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box mt={3} mb={5}>
                <Box pb={2}>
                  <Typography variant="body2">
                    Search Financial Organizations
                  </Typography>
                </Box>

                <OrganizationSearchForm setResponse={setResponse} />
              </Box>
            </Grid>
          </Grid>
          <Box mt={0.5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Accordion
                               slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
                  onChange={setSessionExpansion}
                  expanded={isStoredSession}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      Recent Searches
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RecentOrganizationSearch
                      onResultClick={selectOrgAndGoToProfile}
                      response={savedResponse}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
          <Box mt={5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Accordion
                               slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
                  onChange={setSearchExpansion}
                  expanded={isSearchExpanded}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      Search Results
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <OrganizationSearchResults
                      onResultClick={selectOrgAndGoToProfile}
                      response={response as OrganizationSearchResponse}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default OrganizationSearch;
