import React, { useState } from 'react';
import { Link } from '@mui/material';
import SiraModal from '../../SiraModal';

// eslint-disable-next-line import/no-cycle
import { getModalText } from './newAccountModal.util';

import { AccountType } from '../../../api/AccountApi.d';
import { DepositType } from '../../../api/ContributionApi.d';

export interface NewAccountModalReasonsProps {
  textHyperLink?: string;
  accountType?: AccountType;
  depositType?: DepositType;
  dropDown?: string;
}

function NewAccountModalReasons({
  textHyperLink,
  accountType,
  depositType,
  dropDown,
}: NewAccountModalReasonsProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(!modalOpen);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Link variant="body2" sx={{cursor:'pointer'}} onClick={handleClick}>
        {textHyperLink}
      </Link>

      <SiraModal title={depositType} open={modalOpen} handleClose={handleClose}>
        {getModalText(accountType, depositType, dropDown)}
      </SiraModal>
    </>
  );
}

export default NewAccountModalReasons;
