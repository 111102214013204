import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  TransferAllocation,
  TransferAllocationResponse,
  TransferRequest,
  TransferRequestResponse,
  TransferRequestStatusState,
  TransferRequestStatus,
  Workflow,
  TransferStatusRequestResponse,
} from './TransferApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;

const transferRequestUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/transferRequest/{0.transferRequestId}';
const transferAllocationUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/transferRequest/{0.transferRequestId}/allocations';
const transferRequestStatusUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/transferRequest/{0.transferRequestId}/status';
const transferRequestDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/transferRequest/{0.transferRequestId}/document';
const transferRequestMultiStatusUrl =
  '/org/{0.orgId}/accountOwner/{accountOwnerId}/account/{accountId}/transferRequest';

function transferRequestFactory(data: TransferRequest): TransferRequest {
  const {
    sourceOrgZip,
    sourceOwnerFirstName,
    sourceOrgName,
    accountId,
    sourceAccountNumber,
    sourceOrgAddressLine1,
    sourceOrgAddressLine2,
    sourceOrgCity,
    sourceAccountType,
    relationshipToCurrentOwner,
    sourceOrgState,
    sourcePlanName,
    transferRequestStatus,
    sourceOrgPhoneNumber,
    sourceOwnerLastName,
    transferRequestId,
    transferAmount,
    contributionCreatedDate,
    signedDate,
    activeDate,
    sourceOwnerMiddleInitial,
    sourceOwnerTaxIdNumber,
    entireAccount,
    transferDate,
    transferDateImmediately,
    transferDateInvestmentMaturity,
    additionalDetails,
  } = data;

  return {
    sourceOrgZip,
    sourceOwnerFirstName,
    sourceOrgName,
    accountId,
    sourceAccountNumber,
    sourceOrgAddressLine1,
    sourceOrgAddressLine2,
    sourceOrgCity,
    sourceAccountType,
    relationshipToCurrentOwner,
    sourceOrgState,
    sourcePlanName,
    transferRequestStatus,
    sourceOrgPhoneNumber,
    sourceOwnerLastName,
    transferRequestId,
    transferAmount,
    contributionCreatedDate,
    signedDate,
    activeDate,
    sourceOwnerMiddleInitial,
    sourceOwnerTaxIdNumber,
    entireAccount,
    transferDate,
    transferDateImmediately,
    transferDateInvestmentMaturity,
    additionalDetails,
  };
}

export async function createTransferRequest(
  data: TransferRequest,
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user: any = {}
): Promise<TransferRequestResponse> {
  const dataNorm = removeEmptyStrings(transferRequestFactory(data));
  const url = `${urlBase}${format(`${setBaseUrl(user)}${transferRequestUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    transferRequestId: '',
  })}`;

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateTransferRequest(
  data: TransferRequest,
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user: any = {}
): Promise<TransferRequestResponse> {
  const { transferRequestId } = data;
  const dataNorm = removeEmptyStrings(transferRequestFactory(data));
  const url = `${urlBase}${format(`${setBaseUrl(user)}${transferRequestUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    transferRequestId,
  })}`;

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTransferRequests(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user: any = {}
): Promise<TransferStatusRequestResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${transferRequestUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    transferRequestId: '',
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTransferRequest(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  transferRequestId: string,
  token: string,
  user: any = {}
): Promise<TransferRequestResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${transferRequestUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    transferRequestId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTransferRequestWithStatus(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user: any,
  status: string | Array<TransferRequestStatus>
): Promise<TransferRequestResponse> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${transferRequestMultiStatusUrl}`,
    {
      orgId,
      accountOwnerId,
      accountId,
    }
  )}`;
  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function saveTransferAllocations(
  data: Array<TransferAllocation>,
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  transferRequestId: string,
  token: string,
  user: any = {}
): Promise<TransferAllocationResponse> {
  const dataNorm = data.map((alloc) => removeEmptyStrings(alloc));
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${transferAllocationUrl}`,
    {
      orgId,
      accountOwnerId,
      accountId,
      transferRequestId,
    }
  )}`;

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTransferAllocations(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  transferRequestId: string,
  token: string,
  user: any = {}
): Promise<TransferAllocationResponse> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${transferAllocationUrl}`,
    {
      orgId,
      accountOwnerId,
      accountId,
      transferRequestId,
    }
  )}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeTransferRequestStatus(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  transferRequestId: string,
  state: TransferRequestStatusState,
  workflow: Workflow = {},
  token: string,
  user: any = {}
): Promise<TransferRequestResponse> {
  const params = { state };
  const dataNorm = removeEmptyStrings(workflow);
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${transferRequestStatusUrl}`,
    {
      orgId,
      accountId,
      accountOwnerId,
      transferRequestId,
    }
  )}`;

  return axiosInstance.put(url, dataNorm, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTransferRequestDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  transferRequestId: string,
  token: string,
  user: any = {},
  versionID: string = ''
) {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${transferRequestDocumentUrl}`,
    {
      orgId,
      accountOwnerId,
      accountId,
      transferRequestId,
    }
  )}`;

  const params = removeEmptyStrings({ versionID });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createTransferRequestDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  transferRequestId: string,
  token: string,
  user: any = {}
) {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${transferRequestDocumentUrl}`,
    {
      orgId,
      accountOwnerId,
      accountId,
      transferRequestId,
    }
  )}`;

  return axiosInstance.post(url, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteTransferRequest(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  transferRequestId: string,
  token: string,
  user: any = {}
): Promise<TransferRequestResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${transferRequestUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    transferRequestId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
