import React, { useEffect, useMemo, useState } from 'react';
import { parseISO, isBefore, endOfDay, format } from 'date-fns';
import { ContentState, convertToRaw } from 'draft-js';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

import {
  Grid,
  Box,
  Typography,
  Alert,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import AirlinesStopsIcon from '@mui/icons-material/AirlineStops';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import Done from '@mui/icons-material/Done';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Task, TaskStatus } from '../../api/TaskApi.d';
import { User, UserRole } from '../../api/UserApi.d';
import SiraTextEditor from '../form/SiraTextEditor';
import { is } from 'cypress/types/bluebird';
import { createMultiOrgTask } from '../../api/TaskApi';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { errorMessages } from '../../utils/errorhandling.utils';
import { set } from 'cypress/types/lodash';

export interface TaskDetailProps {
  task: Task;
  users: Array<User>;
  saveTask: Function;
  isLoading?: boolean;
}

const taskSeverityLabels = {
  error: <ErrorIcon titleAccess="Overdue" />,
  warning: <PendingIcon titleAccess="To-Do" />,
  success: <CheckCircleIcon titleAccess="Complete" />,
};

// TODO: will get these names from the API eventually
const categoryNameMap = new Map<string, string>([
  ['Administrator', 'Superior IRA Administrator'],
  ['MultiOrgAccess', 'Superior IRA Access'],
  ['OrgBeneficiaryClaims', 'Beneficiary Claims'],
  ['OrgRecurringDistributions', 'Recurring Distributions'],
  ['OrgSiteAdmin', 'Site Admin'],
  ['OrgTaxReporting', 'Tax Reporting'],
  ['OrgTransactions', 'Transactions'],
  ['OrgUserAdmin', 'User Admin'],
  ['OrgDataProcessor', 'Data Processor'],
  ['OrgTransactionsAdmin', 'Transactions Admin'],
]);

function TaskDetail(props: TaskDetailProps) {
  const { task, saveTask, users, isLoading = false } = props;
  const {
    title,
    category,
    dueDate,
    status,
    completedDate,
    completedById,
    actionLink,
    assignedToId,
    description,
  } = task;
  const { user } = useUser();
  const isComplete = status === TaskStatus.complete;
  const interpretedCategory = categoryNameMap.get(category) || category;
  const [defaultDescription, setdefaultDescription] = useState('');
  const [isEditDueDate, setIsEditDueDate] = useState(false);
  const initialDate = format(parseISO(dueDate), 'MM/dd/yyyy');
  const [dueDateValue, setDueDateValue] = useState(initialDate);
  const { addGlobalMessage } = useGlobalContext();
  const [draftConversion, setdraftConversion] = useState('' as string);
  const [descriptionValue, setDescription] = useState(draftConversion);
  const [isReadOnly, setReadOnly] = useState(true);
  const [taskTitle, setTaskTitle] = useState(title);

  // Don't allow multi-org users in the assignee drop-down
  const assignableUsers = users.filter(
    (user) => !user.roles.includes(UserRole.multi),
  );

  useEffect(() => {
    if (!description.includes('entityMap')) {
      const convertToDraft = ContentState.createFromText(description);
      setdefaultDescription(JSON.stringify(convertToRaw(convertToDraft)));
    } else {
      setdefaultDescription(description);
    }
  }, [description]);

  // Determine severity to apply to the Alert
  const severity = useMemo(() => {
    const dateDue = dueDate && parseISO(dueDate);
    const closeOfDay = endOfDay(new Date());

    if (isComplete) {
      return 'success';
    }
    if (dueDate && isBefore(dateDue, closeOfDay)) {
      return 'error';
    }
    if (assignedToId) {
      return 'warning';
    }

    return 'info';
  }, [isComplete, dueDate, assignedToId]);

  // Assemble the name of who completed the task
  const completedByName = useMemo(() => {
    const { firstName = '', lastName = '' } =
      users.find((user) => `${user.userId}` === `${completedById}`) || {};

    return firstName ? `${firstName} ${lastName}` : '';
  }, [completedById]);

  // Link to the the page to resolve the task (also trims off absolute path segments)
  const goToResolutionLink = (): void => {
    const routerFriendlyUrl = actionLink
      .split('/')
      // The filter covers all environments and http/https domains
      .filter(
        (segment) =>
          segment &&
          !segment.includes('http') &&
          !segment.includes('superiorira.com'),
      )
      .join('/');

    // navigate to a new tab with this url
    window.open(routerFriendlyUrl, '_blank');
  };

  const createNewTask = async (): Promise<void> => {
    // Imperatively set status here. Could API do this for new tasks?
    const taskWithStatus = {
      ...task,
      description: descriptionValue,
      descriptionForEmail: draftConversion,
      title: taskTitle,
    };

    await createMultiOrgTask(taskWithStatus, user.token)
      .then(() => {
        addGlobalMessage('Task successfully created', { severity: 'success' });
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Error creating task');
      });
  };

  useEffect(() => {
    // Ensure the date is in the correct format
    if (dueDate) {
      const formattedDate = new Date(dueDate).toISOString().split('T')[0];
      setDueDateValue(formattedDate);
    }
  }, [dueDate]);

  // this function is used to retunr a date string and when edit icon is clicked an
  // input field will be shown to edit the date with a cancel and check icon
  const editDueDate = () => {
    if (Boolean(!isComplete && dueDate)) {
      return isEditDueDate ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '35px',
          }}
        >
          <TextField
            fullWidth
            label="Due:"
            type="date"
            defaultValue={dueDateValue}
            InputProps={{
              readOnly: !isEditDueDate,
              sx: {
                height: '35px',
              },
            }}
            inputProps={{
              max: '9999-12-31', // Keeps year to 4 digits only (default allows up to 6)
              'data-qa': 'dueDate', // This is the data-qa attribute
            }}
            onClick={(evt) => {
              evt.stopPropagation(); // Prevent the accordion from expanding
            }}
            onChange={(evt) => {
              task.dueDate = evt.target.value;
              evt.stopPropagation(); // Prevent the accordion from expanding
              setDueDateValue(evt.target.value);
            }}
          />
          <Box sx={{ alignContent: 'center' }} />
          <IconButton
            aria-label="cancel"
            size="small"
            onClick={(evt) => {
              evt.stopPropagation(); // Prevent the accordion from expanding
              setIsEditDueDate(false);
            }}
            sx={{
              paddingTop: 1,
            }}
          >
            <CancelIcon />
          </IconButton>
          <IconButton
            aria-label="save"
            size="small"
            onClick={(evt) => {
              evt.stopPropagation(); // Prevent the accordion from expanding
              saveTask(task);
              // Save the new due date logic here
              setIsEditDueDate(false);
            }}
            sx={{
              paddingTop: 1,
            }}
          >
            <CheckIcon />
          </IconButton>
        </Box>
      ) : (
        Boolean(!isComplete && dueDate) && (
          // placing everything inlined to avoid extra space
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="body2">
              Due: {format(parseISO(dueDate), 'MM/dd/yyyy')}
            </Typography>
            {user.roles.includes(UserRole.multi) ? (
              <IconButton
                aria-label="edit"
                size="small"
                onClick={(evt) => {
                  evt.stopPropagation(); // Prevent the accordion from expanding
                  setIsEditDueDate(true);
                }}
                style={{
                  paddingLeft: '1',
                }}
              >
                <EditIcon />
              </IconButton>
            ) : null}
          </Box>
        )
      );
    } else {
      return null;
    }
  };

  return (
    <Accordion disableGutters elevation={2}>
      <AccordionSummary sx={{ pt: 0, pb: 0 }} expandIcon={<ExpandMoreIcon />}>
        <Alert
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: '4px',
            zIndex: 0,
          }}
          iconMapping={taskSeverityLabels}
          elevation={0}
          severity={severity}
        />
        <Box zIndex={1} ml={4} sx={{ width: '100%', height: '35px' }}>
          <Grid container justifyContent="space-between">
            <Typography
              component="span"
              variant="body2"
              align="left"
              sx={{ textDecoration: isComplete && 'line-through' }}
            >
              Task - {title}
            </Typography>
            <Typography
              component="span"
              variant="body2"
              whiteSpace="nowrap"
              align="right"
            >
              <Box component="span" mr={2} sx={{ height: 2 }}>
                {/* Completed info */}
                {Boolean(isComplete && completedDate && completedByName) &&
                  `Completed: ${format(
                    parseISO(completedDate),
                    'MM/dd/yyyy',
                  )} by ${completedByName || ''}`}

                {/* or Due Date */}
                {editDueDate()}
              </Box>
            </Typography>
          </Grid>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={3}>
              {/* Title */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={taskTitle}
                  label="Subject"
                  disabled={isReadOnly}
                  onChange={(evt) => {
                    setTaskTitle(evt.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {/* Description */}
              <Grid item xs={12} mt={2}>
                <Typography variant="body2" gutterBottom>
                  Description
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              {/* Assignee */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Assignee
                  </InputLabel>
                  <Select
                    labelId="assignee-select"
                    id="assignee-select"
                    value={assignedToId || ''}
                    label="Assignee"
                    disabled={isComplete}
                    onChange={(evt) => {
                      task.assignedToId = evt.target.value;
                      saveTask(task);
                    }}
                  >
                    <MenuItem value={null} key="unassignedUser">
                      Unassign Task
                    </MenuItem>

                    {assignableUsers.map((user) => {
                      const { firstName, lastName, userId } = user;
                      return (
                        <MenuItem value={userId} key={userId}>
                          {firstName} {lastName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {/* Category */}
                <Typography variant="body2" gutterBottom>
                  Category: {interpretedCategory}
                </Typography>
                {/* Completed */}
                {completedByName && (
                  <Typography variant="body2" gutterBottom>
                    Completed by: {completedByName}
                  </Typography>
                )}
                {/* Due */}
                {isComplete && (
                  <Typography variant="body2" gutterBottom>
                    Was due by: {format(parseISO(dueDate), 'MM/dd/yyyy')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <SiraTextEditor
              fieldLabel=""
              defaultValue={defaultDescription}
              setReadOnly={isReadOnly}
              showToolbar={!isReadOnly}
              setValue={(value: '') => {
                setDescription(value);
              }}
              formatToHtml={(value: '') => {
                setdraftConversion(value);
              }}
            />
          </Grid>

          {/* Actions */}
          <Grid item xs={12}>
            {!isComplete && (
              <Grid container spacing={2}>
                {actionLink && (
                  <Grid item>
                    <Button
                      disabled={isLoading}
                      onClick={goToResolutionLink}
                      startIcon={<AirlinesStopsIcon />}
                      variant="contained"
                      color="primary"
                    >
                      Resolve
                    </Button>
                  </Grid>
                )}
                {user.roles.includes(UserRole.multi) && (
                  <Grid item>
                    {isReadOnly ? (
                      <Button
                        disabled={isLoading}
                        onClick={() => {
                          setReadOnly(!isReadOnly);
                        }}
                        startIcon={<EditIcon />}
                        variant="contained"
                        color="primary"
                      >
                        Edit Description
                      </Button>
                    ) : (
                      <Button
                        disabled={isLoading}
                        onClick={() => {
                          setReadOnly(true);
                        }}
                        startIcon={<CancelIcon />}
                        variant="contained"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    )}
                  </Grid>
                )}
                <Grid item>
                  {isReadOnly ? (
                    <Button
                      disabled={isLoading}
                      variant="contained"
                      color="success"
                      startIcon={<Done />}
                      onClick={() => {
                        task.status = TaskStatus.complete;
                        saveTask(task);
                      }}
                    >
                      Mark Complete
                    </Button>
                  ) : (
                    <Button
                      disabled={isLoading}
                      variant="contained"
                      color="success"
                      startIcon={<SaveIcon />}
                      onClick={() => {
                        setReadOnly(true);
                        createNewTask();
                      }}
                    >
                      Save Description
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default TaskDetail;
