import { deleteAccount } from '../../api/AccountApi';
import { TransactionType } from '../../api/AccountApi.d';
import { deleteBeneficiaries } from '../../api/BeneficiariesApi';
import { deleteBeneficiaryClaimStatus } from '../../api/BeneficiariesClaimsApi';
import { deleteAccountContribution } from '../../api/ContributionApi';
import { deleteDistribution } from '../../api/DistributionApi';
import { deleteRecurringDistribution } from '../../api/RecurringDistributionApi';
import { deleteTransferRequest } from '../../api/TransferApi';
import { deleteUser } from '../../api/UserApi';

export interface RowDefinition {
  accountId?: number;
  accountOwnerId?: number;
  accountType?: string;
  date?: string;
  contributionId?: number;
  distributionId?: number;
  recurringDistributionId?: number;
  fullName?: string;
  id?: number;
  beneficiaryClaimId?: string;
  taxpayerIdNumber?: string;
  transactionId?: number;
  transactionStatus?: string;
  transactionType?: string;
  visibility?: true;
  transferRequestId?: string;
}

export const deleteApiCall = (row: RowDefinition, organizationId, token, user) => {
  switch (row.transactionType) {
    case TransactionType.account:
      return deleteAccount(
        row.accountId.toString(),
        row.accountOwnerId.toString(),
        organizationId,
        token,
        user
      );
    case TransactionType.beneficiaries:
      return deleteBeneficiaries(
        organizationId,
        row.accountId.toString(),
        row.accountOwnerId.toString(),
        row.transactionId.toString(),
        token,
        user
      );
    case TransactionType.contribution:
      return deleteAccountContribution(
        row.accountId.toString(),
        row.accountOwnerId.toString(),
        organizationId,
        row.contributionId.toString(),
        token,
        user
      );
    case TransactionType.distribution:
      return deleteDistribution(
        row.accountId.toString(),
        row.accountOwnerId.toString(),
        organizationId,
        row.distributionId.toString(),
        token,
        user
      );
    case TransactionType.recurringDistribution:
      return deleteRecurringDistribution(
        row.recurringDistributionId.toString(),
        row.accountId.toString(),
        row.accountOwnerId.toString(),
        organizationId,
        token,
        user
      );
    case TransactionType.transfer:
      return deleteTransferRequest(
        organizationId,
        row.accountOwnerId.toString(),
        row.accountId.toString(),
        row.transferRequestId.toString(),
        token, 
        user
      );
    case 'user':
      return deleteUser(organizationId, row.id.toString(), token);
    case TransactionType.beneficiaryClaims:
      return deleteBeneficiaryClaimStatus(
        organizationId,
        row.beneficiaryClaimId,
        row.accountId.toString(),
        row.accountOwnerId.toString(),
        token
      );
    default:
      return Promise.resolve({});
  }
};
