import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { useDebounce } from 'use-debounce';

import { DistributionAmountFormData } from './DistributionAmountForm.d';
import SiraCurrencyField from '../SiraCurrencyField';
import { getNetIncomeAttribution } from '../../../api/DistributionApi';
import { Distribution } from '../../../api/DistributionApi.d';
import { useUser } from '../../../auth/useUser';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { AccountType } from '../../../api/AccountApi.d';
import { distributionAmountHelpText } from './resource.txt';
import {
  getAfterContentDisplay,
  getEsaAfterContentDisplay,
  getHsaAfterContentDisplay,
} from './distribution.utils';
import { errorMessages } from '../../../utils/errorhandling.utils';

interface NetIncomeAttributionFieldsProps {
  accountId: string;
  isRecharacterization: boolean;
  isRemovingExcess: boolean;
  isRegularRevoc: boolean;
  isConversionRevoc: boolean;
  accountType?: AccountType;
}

function NetIncomeAttributionFields(props: NetIncomeAttributionFieldsProps) {
  let isMounted = true;
  const {
    isRecharacterization = false,
    isRemovingExcess = false,
    isRegularRevoc = false,
    isConversionRevoc = false,
    accountId,
    accountType,
  } = props;
  const isNIAOptions = isRemovingExcess || isRegularRevoc || isConversionRevoc;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { values, setValues, setFieldValue } = useFormikContext();
  const [debouncedValues] = useDebounce(values, 600);
  const {
    distributionReason,
    niaExcessContribution,
    niaPreContributionAccountBalance,
    niaCurrentAccountBalance,
    niaTotalContributionsDuringComputationPeriod,
    niaTotalDistributionsDuringComputationPeriod,
  } = debouncedValues as DistributionAmountFormData;

  async function fetchAndSetNetIncomeAttributable(): Promise<void> {
    if (
      distributionReason &&
      String(niaExcessContribution) &&
      String(niaPreContributionAccountBalance) &&
      String(niaCurrentAccountBalance) &&
      String(niaTotalContributionsDuringComputationPeriod) &&
      String(niaTotalDistributionsDuringComputationPeriod)
    ) {
      await getNetIncomeAttribution(
        values as Distribution,
        accountId,
        user.organizationId,
        user.token
      )
        .then((res) => {
          const { totalToWithdraw, netIncomeAttributable } = res.data;

          setFieldValue('totalAmount', totalToWithdraw);
          setFieldValue('netIncomeAttributable', netIncomeAttributable);
        })
        .catch((err) => {
  

          if (isMounted) {
             addGlobalMessage(errorMessages(err)|| 'Error fetching net income attributable'
            );
          }
        });
    }
  }

  const getAfterContentNIACalculate = (inputField) => {
    // distributionAmountHelpText.accountBalBeforeContributionMade.afterContent
    switch (accountType) {
      case AccountType.esa:
        return getEsaAfterContentDisplay(inputField);
      case AccountType.hsa:
        return getHsaAfterContentDisplay(inputField);
      default:
        return getAfterContentDisplay(inputField);
    }
  };

  useEffect(() => {
    fetchAndSetNetIncomeAttributable();
  }, [
    niaExcessContribution,
    niaPreContributionAccountBalance,
    niaCurrentAccountBalance,
    niaTotalContributionsDuringComputationPeriod,
    niaTotalDistributionsDuringComputationPeriod,
  ]);

  useEffect(() => {
    setValues({
      ...(values as DistributionAmountFormData),
      netAmount: '',
      totalAmount: '',
    });

    return () => {
      isMounted = false;
    };
  }, [distributionReason]);

  return (
    <>
      {isRecharacterization && (
        <Grid item xs={12}>
          <SiraCurrencyField
            name="niaExcessContribution"
            label="Recharacterization Amount"
            allowNegative={false}
            afterContent={
              distributionAmountHelpText.excessContributionAmountRechar
                .afterContent
            }
          />
        </Grid>
      )}
      {isNIAOptions && (
        <>
          <Grid item xs={12}>
            <SiraCurrencyField
              name="niaExcessContribution"
              label="Excess Contribution Amount"
              allowNegative={false}
              afterContent={
                distributionAmountHelpText.excessContributionAmountRechar
                  .afterContent
              }
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <SiraCurrencyField
          name="niaPreContributionAccountBalance"
          label="Account Balance before contribution was made"
          allowNegative={false}
          afterContent={getAfterContentNIACalculate(
            'niaPreContributionAccountBalance'
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SiraCurrencyField
          name="niaCurrentAccountBalance"
          label="Current Account Balance"
          allowNegative={false}
          afterContent={getAfterContentNIACalculate('niaCurrentAccountBalance')}
        />
      </Grid>
      <Grid item xs={12}>
        <SiraCurrencyField
          name="niaTotalContributionsDuringComputationPeriod"
          label="Total Deposits during Computation Period"
          allowNegative={false}
          afterContent={getAfterContentNIACalculate(
            'niaTotalContributionsDuringComputationPeriod'
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SiraCurrencyField
          name="niaTotalDistributionsDuringComputationPeriod"
          label="Total Distributions during Computation Period"
          allowNegative={false}
          afterContent={getAfterContentNIACalculate(
            'niaTotalDistributionsDuringComputationPeriod'
          )}
        />
      </Grid>
    </>
  );
}

export default NetIncomeAttributionFields;
