/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { parseISO } from 'date-fns';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';
import SiraDateField, { dateValidation } from '../SiraDateField';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import { AccountType } from '../../../api/AccountApi.d';
import TaxIdField from '../TaxIdField';
import DuplicateUserCheck from '../userSearch/DuplicateUserCheck';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { ExternalAccount } from '../../../api/ExternalAccountApi.d';

export const EXTERNAL_ACCOUNT_INIT: ExternalAccount = {
  externalAccountOwnerAccountId: '',
  taxpayerIdNumber: '',
  firstName: '',
  middleInitial: '',
  lastName: '',
  dateOfBirth: '',
  dateOfDeath: '',
  accountType: '' as AccountType,
};

export const EXTERNAL_ACCOUNT_SCHEMA = {
  firstName: yup.string().required().max(40).label('First Name'),
  lastName: yup.string().required().max(40).label('Last Name'),
  taxpayerIdNumber: yup
    .string()
    .required()
    .matches(/\d{3}-\d{2}-\d{4}/, 'SSN must be valid ssn')
    .label('SSN'),
  dateOfBirth: dateValidation().required().label('Date of Birth'),
  dateOfDeath: dateValidation()
    .required()
    .label('Date of Death')
    .when('dateOfBirth', (dateOfBirth, schema) => {
      return dateOfBirth
        ? dateValidation(parseISO(dateOfBirth), new Date())
            .required()
            .label('Date of Death')
        : schema;
    }),
};

export interface ExternalAccountFormProps {
  initialValues: ExternalAccount;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}

const ExternalAccountForm = ({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
}: ExternalAccountFormProps) => {
  const [internalUser, setInternalUser] = useState({} as ExternalAccount);
  const { organization, getAppConstant } = useGlobalContext();
  const { accountTypesSupported = [] } = organization;
  const onDuplicateUser = useCallback((user) => {
    setInternalUser(user);
  }, []);

  const accountTypeOptions: Array<RadioGroupOption> = [];

  // Only these account types can create an inherited traditional
  if (accountTypesSupported.includes(AccountType.inheritedTraditional)) {
    accountTypeOptions.push(
      {
        value: AccountType.traditionalIra,
        label: getAppConstant(
          ConstantsMappingKey.accountType,
          AccountType.traditionalIra
        ),
      },
      {
        value: AccountType.employerTraditional,
        label: getAppConstant(
          ConstantsMappingKey.accountType,
          AccountType.employerTraditional
        ),
      },
      {
        value: AccountType.inheritedTraditional,
        label: getAppConstant(
          ConstantsMappingKey.accountType,
          AccountType.inheritedTraditional
        ),
      }
    );
  }

  // Only these types can create an inherited Roth
  if (accountTypesSupported.includes(AccountType.inheritedRoth)) {
    accountTypeOptions.push(
      {
        value: AccountType.rothIra,
        label: getAppConstant(
          ConstantsMappingKey.accountType,
          AccountType.rothIra
        ),
      },
      {
        value: AccountType.employerRoth,
        label: getAppConstant(
          ConstantsMappingKey.accountType,
          AccountType.employerRoth
        ),
      },
      {
        value: AccountType.inheritedRoth,
        label: getAppConstant(
          ConstantsMappingKey.accountType,
          AccountType.inheritedRoth
        ),
      }
    );
  }

  return (
    <Formik
      initialValues={{ ...initialValues, ...internalUser }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      validationSchema={yup.object(EXTERNAL_ACCOUNT_SCHEMA)}
      enableReinitialize
    >
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <input name="accountOwnerId" type="hidden" />
            <Box maxWidth={480}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    Provide the following information for the deceased owner.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TaxIdField name="taxpayerIdNumber" label="SSN" ssn />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DuplicateUserCheck
                    onDuplicateFound={onDuplicateUser}
                    taxId={values.taxpayerIdNumber}
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <SiraTextField name="firstName" label="First Name" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <SiraTextField
                    maxLength={1}
                    name="middleInitial"
                    label="MI"
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <SiraTextField name="lastName" label="Last Name" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SiraDateField name="dateOfBirth" label="Date of Birth" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SiraDateField name="dateOfDeath" label="Date of Death" />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Deceased Owner's Account Type"
                    name="accountType"
                    options={accountTypeOptions}
                    component={SiraRadioField}
                  />
                </Grid>
              </Grid>
            </Box>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ExternalAccountForm;
