import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import { getYear, parseISO, subYears } from 'date-fns';
import * as yup from 'yup';

import {
  FairMarketValue,
  FmvSearchResponseObject,
} from '../../api/FairMarketValueApi.d';
import SiraTextField from '../form/SiraTextField';
import StepButtonBar from '../steps/StepButtonBar';
import { currentYear } from '../../app.constants';
import SiraCurrencyField from '../form/SiraCurrencyField';
import SiraSwitchField from '../form/SiraSwitchField';

export const FMV_INIT: FairMarketValueForm = {
  taxYear: '',
  fairMarketValue: '',
  isDateOfDeath: false,
  dateOfDeathAccountBalance: '',
};

interface FairMarketValueForm extends FairMarketValue {
  isDateOfDeath: boolean;
  dateOfDeathAccountBalance?: string;
}

export const FMV_SCHEMA = (existingYears) => {
  return {
    taxYear: yup
      .number()
      .min(currentYear - 2)
      .notOneOf(existingYears, 'This FMV exists, please edit existing record')
      .required()
      .label('Tax Year')
      .when('isDateOfDeath', (isDateOfDeath, schema) =>
        // if date of death balance, allow it to be current year since it'll likely be before 12/31
        isDateOfDeath ? schema.max(currentYear) : schema.max(currentYear - 1)
      ),
    fairMarketValue: yup
      .number()
      .min(0)
      .label('Fair Market Value')
      .when('isDateOfDeath', (isDateOfDeath, schema) =>
        !isDateOfDeath ? schema.required() : schema
      ),
    dateOfDeathAccountBalance: yup
      .number()
      .min(0)
      .label('Date of Death Account Balance')
      .when('isDateOfDeath', (isDateOfDeath, schema) =>
        isDateOfDeath ? schema.required() : schema
      ),
    isDateOfDeath: yup.boolean(),
  };
};

export interface FMVFormProps {
  fmvRecord: FmvSearchResponseObject;
  initialValues: FairMarketValueForm;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  existingYears: Array<string>;
  isUpdating: boolean;
  showDateOfDeath?: boolean;
  fmvTaxYear?: string;
}

interface DateOfDeathFieldProps {
  fmvRecord: FmvSearchResponseObject;
  fmvTaxYear?: string;
}

function DateOfDeathField(props: DateOfDeathFieldProps) {
  const { fmvRecord, fmvTaxYear } = props;
  const [isInitialized, setIsInitialized] = useState(false as boolean);
  const { values, setFieldValue } = useFormikContext<FairMarketValueForm>();
  const { isDateOfDeath } = values;
  const yearOfDeath = getYear(parseISO(fmvRecord.dateOfDeath));

  useEffect(() => {
    if (isInitialized) {
      if (isDateOfDeath && yearOfDeath) {
        setFieldValue('taxYear', yearOfDeath);
      } else {
        setFieldValue('taxYear', FMV_INIT.taxYear || fmvTaxYear);
      }
    } else {
      setIsInitialized(true);
    }
  }, [isDateOfDeath]);

  return (
    <Grid item xs={12}>
      <Grid
        component="label"
        container
        alignItems="center"
        spacing={1}
        wrap="nowrap"
      >
        <Grid item>
          <SiraSwitchField name="isDateOfDeath" checked={isDateOfDeath} />
        </Grid>
        <Grid item>
          <Typography>is FMV on date of death</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function FmvForm({
  fmvRecord = {},
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
  existingYears,
  isUpdating,
  showDateOfDeath,
  fmvTaxYear,
}: FMVFormProps) {
  return (
    <Formik
      initialValues={{
        ...initialValues,
        isDateOfDeath: Boolean(initialValues.dateOfDeathAccountBalance),
      }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => onReset()}
      validationSchema={yup.object(FMV_SCHEMA(isUpdating ? [] : existingYears))}
    >
      {({ isSubmitting, values, setFieldValue }) => {
        if (values.taxYear === 'NaN') {
          setFieldValue('taxYear', getYear(subYears(new Date(),1)));
        }
        return (
          <Form>
            <Grid container spacing={3}>
              {(fmvRecord.dateOfDeath || showDateOfDeath) && (
                <DateOfDeathField
                  fmvRecord={fmvRecord}
                  fmvTaxYear={fmvTaxYear}
                />
              )}

              <Grid item xs={9}>
                <SiraTextField
                  name="taxYear"
                  label="Tax Year"
                  disabled={isUpdating || values.isDateOfDeath}
                />
              </Grid>
              <Grid item xs={9}>
                <SiraCurrencyField
                  name={
                    values.isDateOfDeath
                      ? 'dateOfDeathAccountBalance'
                      : 'fairMarketValue'
                  }
                  label={
                    values.isDateOfDeath
                      ? 'FMV on Date of Death'
                      : '12/31 Fair Market Value'
                  }
                />
              </Grid>
            </Grid>

            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default FmvForm;
