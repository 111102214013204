import React, { useEffect, useState } from 'react';
import { LinearProgress, Box, Button, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { isAfter, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import {
  getBeneficiaries,
  updateBeneficiary,
  createBeneficiaries,
  deleteBeneficiaries,
  getBeneficiariesDocument,
} from '../../api/BeneficiariesApi';
import {
  Beneficiary,
  BeneficiaryStatus,
  Beneficiaries,
} from '../../api/BeneficiariesApi.d';
import BeneficiaryForm, { BENEFICIARY_INIT } from '../form/BeneficiaryForm';
import PrimaryBeneficiaries from './PrimaryBeneficiaries';
import {
  setBeneficiariesResponse,
  updateLatestBeneficiariesStatus,
  setPrimaryBeneficiaries,
  addOrReplaceBeneficiary,
  setBeneficiaryToEdit,
  changeBeneficiaryAddType,
  cancelBeneficiaryForm,
  AddBeneficiaryType,
  BeneficiariesState,
} from './BeneficiariesReducer';
import { AccountStatus, Account } from '../../api/AccountApi.d';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import { errorMessages } from '../../utils/errorhandling.utils';

interface DesignatePrimaryBeneficiariesProps {
  state: BeneficiariesState;
  account: Account;
  initializeAsPending?: boolean;
  onSave?: Function;
  onSkipToSignature?: Function;
  onInitialize?: Function;
  statusFilter?: BeneficiaryStatus;
  accountOwner?: AccountOwner;
  beneficiariesAccount?: boolean;
  accountOwnerProfile?: boolean;
}

function DesignatePrimaryBeneficiaries(
  props: DesignatePrimaryBeneficiariesProps
) {
  let isMounted = true;
  const {
    state,
    account = {},
    initializeAsPending = false,
    onSave = () => {},
    onSkipToSignature = () => {},
    onInitialize = () => {},
    statusFilter,
    accountOwner,
    beneficiariesAccount,
    accountOwnerProfile
  } = props;
  const navigate = useNavigate();
  // const queryParams = new URLSearchParams(useLocation().search);
  const {
    accountId = '',
    accountStatus,
    closedDate = '',
    accountOwnerId,
  } = account;
  const { addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);
  const [showBeneficiariesDeleted, setShowBeneficiariesDeleted] = useState(
    false as boolean
  );
  const { beneficiariesRes, showForm, beneficiaryToEdit } = state;
  const { data: versions = [] } = beneficiariesRes;
  const [latestBeneficiaries = {}] = versions;
  const { beneficiaryStatus, effectiveDate, version, signedDate } =
    latestBeneficiaries;
  const isPending = beneficiaryStatus === BeneficiaryStatus.pending;
  const isAwaiting = [
    BeneficiaryStatus.signature,
    BeneficiaryStatus.review,
  ].includes(beneficiaryStatus);
  const isAccountClosed = accountStatus === AccountStatus.closed;
  const isBeforeAccountClosed = isAfter(
    parseISO(closedDate),
    parseISO(effectiveDate)
  );
  const allowViewDocument =
    Boolean(latestBeneficiaries.version) &&
    latestBeneficiaries.beneficiaryStatus === BeneficiaryStatus.live;

  // Update a single, ACTIVE beneficiary after it's editied
  const updateEditedBeneficiary = async (data: Beneficiary): Promise<void> => {
    setIsLoading(true);
    await updateBeneficiary(
      data,
      user.organizationId,
      accountId,
      accountOwnerId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          addOrReplaceBeneficiary(data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
  
          setIsLoading(false);
          addGlobalMessage(errorMessages(err) || 'Error updating active beneficiary'
          );
        }
      });
  };

  // Save beneficiaries with POST when there are none
  async function createPendingBeneficiaries(
    beneficiaries: Beneficiaries
  ): Promise<void> {
    setIsLoading(true);

    await createBeneficiaries(
      beneficiaries,
      user.organizationId,
      accountId,
      accountOwnerId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          updateLatestBeneficiariesStatus(res.data.beneficiaryStatus);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
  
          setIsLoading(false);
          addGlobalMessage(errorMessages(err) || 'Error saving beneficiaries');
        }
      });
  }

  // Get all of the beneficiary sets and work on the first
  async function getBeneficiaryVersions(): Promise<void> {
    setIsLoading(true);

    const params = {
      ...(statusFilter && { status: statusFilter }),
    };

    const userAccountId = accountId;
    const userAccountOwnerId = accountOwnerId;
    // if (accountOwnerId) {
    //   userAccountId = accountId;
    //   userAccountOwnerId = accountOwnerId;
    // }

     getBeneficiaries(
      userAccountId,
      userAccountOwnerId,
      user.organizationId,
      user.token,
      params,
      user
    )
      .then((res) => {
          setBeneficiariesResponse(res);
          setIsLoading(false);

        onInitialize(res.data);
      })
      .catch((err) => {
        if (isMounted) {
          setBeneficiariesResponse({
            data: [],
            errorMessage: err?.response?.data?.message,
            status: err.response?.status,
          });
          setIsLoading(false);
        }
      });
  }

  // DELETE the pending beneficiares set (e.g. if account is closed and the can't be updated anyway)
  const deleteLatestBeneficiaries = async () => {
    setIsLoading(true);

    await deleteBeneficiaries(
      user.organizationId,
      accountId,
      accountOwnerId,
      version,
      user.token,
      user
    )
      .then(() => {
        setBeneficiariesResponse({
          data: [],
        });
        setIsLoading(false);
        setShowBeneficiariesDeleted(true);
      })
      .catch((err) => {


        setIsLoading(false);
        addGlobalMessage(errorMessages(err) || 'Error deleting beneficiaries');
      });
  };

  async function openPdf(beneficiaryVersion: string) {
    await getBeneficiariesDocument(
      user.organizationId,
      accountOwnerId,
      accountId,
      beneficiaryVersion,
      user.token,
      '',
      user
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  function skipToSignature() {
    // When AWAITING_SIGNATURE transaction date is before account closing, still allow signing
    if (isAccountClosed && isBeforeAccountClosed) {
      onSkipToSignature();
    }
  }

  const goToDashboard = () => {
    navigate('/');
  };

  useEffect(() => {
    if (user.token && user.organizationId && accountId) {
      getBeneficiaryVersions();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId, accountId]);

  useEffect(() => {
    const isLive = beneficiaryStatus === BeneficiaryStatus.live;

    // If latest beneficiaries are live and a new PENDING set should be created when account is open
    if (isLive && initializeAsPending && !isAccountClosed) {
      createPendingBeneficiaries(latestBeneficiaries);
    }
  }, [beneficiaryStatus]);

  useEffect(() => {
    skipToSignature();
  }, [isBeforeAccountClosed]);

  if (isLoading) {
    return (
      <Box width="1" mt={5} mb={3}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  if (showBeneficiariesDeleted) {
    return (
      <Box width="1" mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="success">
              Beneficiaries successfully deleted.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={goToDashboard} variant="contained" color="primary">
              Go to Dashboard
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (isAccountClosed) {
    return (
      <Box width="1" mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="error">
              Cannot update beneficiaries after an account is closed
            </Alert>
          </Grid>
          {(isPending || isAwaiting) && (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                There were primary beneficiaries in-progress after the date the
                account was closed that cannot be updated. Would you like to
                delete them?
              </Typography>
              <Button
                onClick={deleteLatestBeneficiaries}
                variant="contained"
                color="primary"
              >
                Delete Beneficiaries
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  return (
    <Box width="1">
      <Box mb={2}>
        {allowViewDocument && (
          <>
            <Button
              size="small"
              variant="contained"
              disabled={isFetchingDocument}
              color="primary"
              startIcon={<PictureAsPdfIcon />}
              onClick={async () => {
                setIsFetchingDocument(true);
                await openPdf(latestBeneficiaries.version);
                setIsFetchingDocument(false);
              }}
            >
              View Document
            </Button>
          </>
        )}
      </Box>
      {beneficiariesAccount && (
        <Box>
          <Typography variant="overline">Primary</Typography>
        </Box>
      )}
      <Box>
        <PrimaryBeneficiaries
          account={account}
          beneficiaries={latestBeneficiaries}
          onSave={(data) => {
            updateLatestBeneficiariesStatus(data.beneficiaryStatus);
            onSave(data);
          }}
          onSkipToSignature={onSkipToSignature}
          onAddClick={() => {
            changeBeneficiaryAddType(AddBeneficiaryType.primary);
          }}
          onEditClick={(row) => {
            setBeneficiaryToEdit(row as Beneficiary);
            changeBeneficiaryAddType(AddBeneficiaryType.primary);
          }}
          showForm={showForm}
          updateBeneficiaries={setPrimaryBeneficiaries}
          updateBeneficiariesStatus={updateLatestBeneficiariesStatus}
          beneficiariesAccount={beneficiariesAccount}
          signedDate={signedDate}
          accountOwnerProfile={accountOwnerProfile}
        />
      </Box>

      {showForm && (
        <Box mt={4}>
          {beneficiaryStatus === BeneficiaryStatus.live && beneficiaryToEdit ? (
            <BeneficiaryForm
              initialValues={{
                ...BENEFICIARY_INIT,
                ...beneficiaryToEdit,
              }}
              accountOwner={accountOwner}
              account={account}
              onSubmit={updateEditedBeneficiary}
              onCancel={cancelBeneficiaryForm}
              isLiveEditing
            />
          ) : (
            <BeneficiaryForm
              initialValues={{
                ...BENEFICIARY_INIT,
                ...beneficiaryToEdit,
              }}
              accountOwner={accountOwner}
              account={account}
              onSubmit={addOrReplaceBeneficiary}
              onCancel={cancelBeneficiaryForm}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default DesignatePrimaryBeneficiaries;
