import React from 'react';
import { Field, FieldAttributes, FormikState } from 'formik';
import Radio from '@mui/material/Radio';
import {
  Grid,
  FormLabel,
  Typography,
  Box,
  RadioProps,
  RadioGroupProps,
} from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import InfoPopover from '../InfoPopover';
import SiraCheckbox from './SiraCheckbox';

export interface RadioGroupOption extends RadioProps {
  forceAfterContent?: boolean;
  info?: string;
  infoAria?: string;
  afterContent?: any;
  sublabel?: any;
  value?: any;
  label?: any;
  checkBox?: boolean;
  checkBoxLabel?: string;
  checkBoxName?: string;
  checkBoxAfterContent?: any;
}

interface SiraRadioFieldProps extends RadioGroupProps {
  field: FieldAttributes<any>;
  form: FormikState<any>;
  name: string;
  options: Array<RadioGroupOption>;
  sublabel: string;
  label: any;
}

const renderOptions = (
  options: Array<RadioGroupOption>,
  field: FieldAttributes<RadioGroupOption>
) => {
  return options.map((option) => {
    const {
      forceAfterContent = false,
      afterContent = null,
      info = '',
      infoAria = '',
      label,
      name,
      checkBox = false,
      checkBoxLabel = '',
      checkBoxName = '',
      checkBoxAfterContent = null,
      ...radioProps
    } = option;
    const isSelected = field.value === option.value;
    // XOR to show afterContent when forced or if option is selected and present
    const showAfterContent =
      forceAfterContent || (!forceAfterContent && isSelected && (afterContent || checkBoxAfterContent));

    return (
      <Grid
        key={`${option.name}-${option.value}`}
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid item mt={0.5} mb={0.5}>
          <FormControlLabel
            label={label}
            name={name}
            control={<Radio {...radioProps} />}
          />
          {showAfterContent ? <Box ml={4}>{afterContent}</Box> : ''}
          { checkBox && showAfterContent && (
            <Box ml={4}>
              <Field
                name={checkBoxName}
                label={checkBoxLabel}
                type="checkbox"
                component={SiraCheckbox}
              />
              <Box ml={4}>{checkBoxAfterContent}</Box>
            </Box>
          )}
          {info && <InfoPopover text={info} ariaLabel={infoAria} />}
        </Grid>
      </Grid>
    );
  });
};

const SiraRadioField = (props: SiraRadioFieldProps) => {
  const {
    field,
    form: { touched, errors },
    name,
    options,
    children,
    sublabel,
    label = 'Choose One',
    ...radioGroupProps
  } = props;
  const fieldName = name || field.name;

  return (
    <>
      {label && (
        <FormLabel component="legend">
          {label}
          {sublabel}
          {touched[fieldName] && errors[fieldName] && (
            <Typography variant="caption" color="error" pl={2}>
              {errors[fieldName].toString()}
            </Typography>
          )}
        </FormLabel>
      )}
      <RadioGroup {...field} {...radioGroupProps} name={fieldName}>
        {options ? renderOptions(options, field) : children}
      </RadioGroup>
    </>
  );
};

export default SiraRadioField;
