import * as React from 'react';

function SvgAdobe() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 500 550"
      enableBackground="new 0 0 500 550"
    >
      <g id="icon_PDF_file">
        <g id="contour_1">
          <polygon
            fill="#FFFFFF"
            points="51.791,356.646 51.791,23.99 204.497,23.99 282.646,102.066 282.646,356.646   "
          />
          <path d="M201.186,31.99l73.46,73.393v243.264H59.791V31.99H201.186 M207.809,15.99H43.791v348.657h246.854V98.75L207.809,15.99    L207.809,15.99z" />
        </g>
        <g id="contour_2">
          <polygon
            fill="#FFFFFF"
            points="51.791,356.646 51.791,23.99 204.497,23.99 206.309,25.8 206.309,100.327 280.904,100.327     282.646,102.066 282.646,356.646   "
          />
          <path d="M198.309,31.99v60.337v16h16h60.337v240.32H59.791V31.99H198.309 M207.809,15.99H43.791v348.657h246.854V98.75    l-6.43-6.424h-69.907V22.484L207.809,15.99L207.809,15.99z" />
        </g>
        <g id="triangle">
          <polygon points="219.643,87.75 219.643,48.667 258.309,86.38 258.309,87.75   " />
          <path d="M227.643,67.646l12.41,12.104h-12.41V67.646 M222.641,40.417h-10.998V95.75h54.666V83.008L222.641,40.417L222.641,40.417z    " />
        </g>
        <g id="red_square">
          <polygon
            fill="#ED1C24"
            points="22.544,284.49 22.544,167.682 37.291,152.935 37.291,171.489 297.146,171.489 297.146,152.935     311.893,167.682 311.893,284.49   "
          />
          <path
            fill="#ED1C24"
            d="M303.646,168.627l1.747,1.747V277.99H29.044V170.374l1.747-1.747v9.362h13h246.854h13V168.627     M290.646,137.242v27.747H43.791v-27.747l-27.747,27.747V290.99h302.349V164.989L290.646,137.242L290.646,137.242z"
          />
        </g>
        <rect
          x="52.096"
          y="189.324"
          fill="none"
          width="232.826"
          height="87.834"
        />
        <text
          transform="matrix(1 0 0 1 52.0957 271.6621)"
          fill="#FFFFFF"
          fontFamily="DejaVu Sans"
          fontWeight="bold"
          fontSize="105"
        >
          PDF
        </text>
      </g>
    </svg>
  );
}

export default SvgAdobe;
