import React from 'react';
import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface WobbleCardProps {
  children: React.ReactNode;
  isSelected?: boolean;
}

// A custom styled component for the Card with hover effects
const CustomCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  // Wobble animation keyframes
  '@keyframes wobble': {
    '0%': { transform: 'rotate3d(0, 0, 1, -0.5deg)' },
    '25%': { transform: 'rotate3d(0, 0, 1, 0.5deg)' },
    '50%': { transform: 'rotate3d(0, 0, 1, -0.5deg)' },
    '75%': { transform: 'rotate3d(0, 0, 1, 0.5deg)' },
    '100%': { transform: 'rotate3d(0, 0, 1, 0deg)' },
  },
  '&:hover': {
    // Wobble animation on hover
    animation: 'none',
    // 3D effect on hover
    transform: 'rotate3d(1, 1, 0, 5deg) scale(1.05)',
    boxShadow: theme.shadows[8],
  },
  '&.selected': {
    transform: 'rotate3d(1, 1, 0, 5deg) scale(1.05)',
    boxShadow: theme.shadows[8],
    // Override hover effects for selected state
    '&:hover': {
      animation: 'none', // This stops the wobble animation on hover
      transform: 'rotate3d(1, 1, 0, 5deg) scale(1.05)', // Keeps the 3D effect and scale
    },  
    '&.MuiSelect-root': {
    boxShadow: 'none', // Override shadow effect or other styles for the Select component
  }
  },

  
}));

function WobbleCard(props: WobbleCardProps) {
  const { children, isSelected } = props;
  return (
    <CustomCard variant="outlined" className={isSelected ? 'selected' : ''}>
      <CardContent>
        {
          /* Card content */
          children
        }
      </CardContent>
    </CustomCard>
  );
}

export default WobbleCard;
