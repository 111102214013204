import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Field, useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';

import { determineAgeGroup } from '../../../app.constants';
import SiraCurrencyField from '../SiraCurrencyField';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import { DistributionAmountFormData } from './DistributionAmountForm.d';
import {
  LifeExpectancyTable,
  RecurringDistribution,
  TermChoiceOption,
} from '../../../api/RecurringDistributionApi.d';
import { getLifeExpectancyTerms } from '../../../api/LifeExpectancyApi';
import { useUser } from '../../../auth/useUser';
import { getAmortizationCalculation } from '../../../api/AccountOwnerApi';
import { DistributionTerm } from './DistributionTermForm';
import {
  AccountOwner,
  AmortizationCalculationResponse,
  AmortRequestParams,
} from '../../../api/AccountOwnerApi.d';
import { AccountType, Account } from '../../../api/AccountApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { RmdWarning } from './RmdWarning';
import { recurringDistributionHelpText } from './distribution.utils';
import { errorMessages } from '../../../utils/errorhandling.utils';

export enum DistributionChoices {
  empty = '',
  rmd = 'RMD',
  owner = 'OWNER',
  amount = 'SPECIFIC',
  amortization = 'AMORTIZATION',
  annuitization = 'ANNUITIZATION',
}

const over73Choices = [
  {
    label: 'Required minimum distribution (RMD)',
    value: DistributionChoices.rmd,
    afterContent: recurringDistributionHelpText.rmd,
  },
  {
    label: 'Specific amount',
    value: DistributionChoices.amount,
    afterContent: recurringDistributionHelpText.specificAmount,
  },
] as Array<RadioGroupOption>;

const currently73Choices = [
  {
    label: 'Required minimum distribution (RMD)',
    value: DistributionChoices.rmd,
    afterContent: recurringDistributionHelpText.rmd,
  },
  {
    label: 'Specific amount',
    value: DistributionChoices.amount,
    afterContent: recurringDistributionHelpText.specificAmount,
  },
] as Array<RadioGroupOption>;

const betweenHalf59and73Choices = [
  { label: 'Specific amount', value: DistributionChoices.amount },
] as Array<RadioGroupOption>;

function underHalf59Choices(editActive: boolean) {
  return [
    {
      label: 'Required minimum distribution (RMD)',
      value: DistributionChoices.rmd,
      afterContent: (
        <>
          {recurringDistributionHelpText.rmdUnderAge}
          {editActive && <RmdWarning />}
        </>
      ),
    },
    {
      label: 'Fixed amortization amount',
      value: DistributionChoices.amortization,
      disabled: editActive,
      afterContent: recurringDistributionHelpText.fixedAmoritization,
    },
    {
      label: 'Fixed annuitization amount',
      value: DistributionChoices.annuitization,
      disabled: editActive,
      afterContent: recurringDistributionHelpText.fixedAnnuitization,
    },
  ] as Array<RadioGroupOption>;
}

const rothChoices = [
  { label: 'Specific amount', value: DistributionChoices.amount },
] as Array<RadioGroupOption>;

const inheritedChoices = [
  {
    label: 'Required minimum distribution (RMD)',
    value: DistributionChoices.rmd,
    afterContent: recurringDistributionHelpText.rmd,
  },
  {
    label: 'Specific amount',
    value: DistributionChoices.amount,
    afterContent: recurringDistributionHelpText.specificAmountInherited,
  },
] as Array<RadioGroupOption>;

const inheritedAmountChoices = [
  {
    label: 'Specific amount',
    value: DistributionChoices.amount,
    afterContent: recurringDistributionHelpText.specificAmountInherited,
  },
] as Array<RadioGroupOption>;

interface DistributionAmountRecurringSubFormProps {
  account: Account;
  accountOwner: AccountOwner;
  term: DistributionTerm;
  parentDistribution: RecurringDistribution;
}

const validAccountTypes = [
  AccountType.simpleIra,
  AccountType.traditionalSep,
  AccountType.rothIra,
  AccountType.traditionalIra,
  AccountType.inheritedRoth,
  AccountType.inheritedTraditional,
];

const distributionChoiceMap = {
  [DistributionChoices.amortization]: LifeExpectancyTable.amortization,
  [DistributionChoices.annuitization]: LifeExpectancyTable.annuitization,
};

function DistributionAmountRecurringSubForm({
  account,
  accountOwner,
  term,
  parentDistribution,
}: DistributionAmountRecurringSubFormProps) {
  const { user } = useUser();
  const [rmd, setRmd] = useState('0');
  const [loading, setLoading] = useState(false);
  const [isMounted, setMounted] = useState(true);
  const [requireMinimum, setRequireMinimum] = useState(true);
  const { addGlobalMessage } = useGlobalContext();
  const [recurringSelectionChoices, setRecurringSelectionChoices] =
    React.useState([] as Array<RadioGroupOption>);
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<DistributionAmountFormData>();
  const [debouncedValues] = useDebounce(values, 800);
  const queryParams = new URLSearchParams(useLocation().search);
  const editActive = queryParams.get('editActive') === 'true';
  const { dateOfBirth } = accountOwner as AccountOwner;
  const { over73, underHalf59, currently73Years, turning73ThisYear } =
    determineAgeGroup(dateOfBirth);
  const { lifeExpectancyTable, termChoice } = parentDistribution;

  const isRMD = [DistributionChoices.rmd].includes(
    values.recurringSelection as DistributionChoices
  );

  const isINHRMD =
    [DistributionChoices.amount].includes(
      values.recurringSelection as DistributionChoices
    ) &&
    [AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(
      account.accountType
    );

  const isSpecificAmount =
    [
      AccountType.inheritedRoth,
      AccountType.inheritedTraditional,
      AccountType.traditionalIra,
      AccountType.traditionalSep,
      AccountType.simpleIra,
    ].includes(account.accountType) &&
    [
      TermChoiceOption.inheritedRoth10,
      TermChoiceOption.inheritedTrad10,
      TermChoiceOption.rothTermInherited,
      TermChoiceOption.tradInherited,
      TermChoiceOption.rothTermJle,
    ].includes(term.termChoice);

  const isFixed = [
    DistributionChoices.amortization,
    DistributionChoices.annuitization,
  ].includes(values.recurringSelection as DistributionChoices);

  const {
    accountBalance: debouncedAccountBalance,
    recurringSelection: debouncedRecurringSelection,
  } = debouncedValues;

  // Find and set the choice using the parent's calculation type
  const setDistributionChoiceByParent = (): void => {
    const [matchingChoice] =
      Object.entries(distributionChoiceMap).find(([key, value]) =>
        lifeExpectancyTable === value ? key : ''
      ) || [];

    if (matchingChoice) {
      setFieldValue('recurringSelection', matchingChoice);
    } else if (
      [
        TermChoiceOption.jle,
        TermChoiceOption.ult,
        TermChoiceOption.sle,        
        TermChoiceOption.slenonrecalc,
      ].includes(termChoice)
    ) {
      setFieldValue('recurringSelection', DistributionChoices.rmd);
    }
  };

  // Match and set the calculation type using current distribution choice
  const setCalculationTypeByChoice = (): void => {
    if (termChoice === TermChoiceOption.jle) {
      setFieldValue('lifeExpectancyTable', LifeExpectancyTable.jle);
    } else if (termChoice === TermChoiceOption.ult) {
      setFieldValue('lifeExpectancyTable', LifeExpectancyTable.ule);
    } else if (termChoice === TermChoiceOption.sle) {
      setFieldValue('lifeExpectancyTable', LifeExpectancyTable.sle);
    }else if (termChoice === TermChoiceOption.slenonrecalc) {
      setFieldValue('lifeExpectancyTable', LifeExpectancyTable.slenonrecalc);
    }
  };

  // Pre-fill any form fields using the parent distributions
  const prefillParentOptions = (): void => {
    setDistributionChoiceByParent();
  };

  const setAmountValue = () => {
    let value = '';
    if (values.accountBalance) {
      value = values.accountBalance;
    }

    if (values.fairMarketValue) {
      value = values.fairMarketValue;
    }

    return value;
  };

  useEffect(() => {
    if ([AccountType.rothIra].includes(account.accountType)) {
      setRecurringSelectionChoices(rothChoices);
      setRequireMinimum(false);
      setFieldValue('recurringSelection', DistributionChoices.amount); // only one choice, select it.
    }

    if (
      [AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(
        account.accountType
      ) &&
      [TermChoiceOption.sle, TermChoiceOption.slenonrecalc].includes(term.termChoice)
    ) {
      setRecurringSelectionChoices(inheritedChoices);
      setRequireMinimum(true);
    }

    if (isSpecificAmount) {
      setRecurringSelectionChoices(inheritedAmountChoices);
      setRequireMinimum(true);
      setFieldValue('recurringSelection', DistributionChoices.amount);
    }

    if (
      [
        AccountType.traditionalIra,
        AccountType.traditionalSep,
        AccountType.simpleIra,
      ].includes(account.accountType) &&
      accountOwner &&
      accountOwner.dateOfBirth
    ) {
      if (over73) {
        setRecurringSelectionChoices(over73Choices);
        setRequireMinimum(true);
        // using this to set the specific amount for termchoice selections containing a term
        if (isSpecificAmount) {
          setRecurringSelectionChoices(inheritedAmountChoices);
          setRequireMinimum(true);
          setFieldValue('recurringSelection', DistributionChoices.amount);
        }
      } else if (turning73ThisYear || currently73Years) {
        setRecurringSelectionChoices(currently73Choices);
        setRequireMinimum(true);
        // using this to set the specific amount for termchoice selections containing a term
        if (isSpecificAmount) {
          setRecurringSelectionChoices(inheritedAmountChoices);
          setRequireMinimum(true);
          setFieldValue('recurringSelection', DistributionChoices.amount);
        }
      } else if (underHalf59) {
        setRecurringSelectionChoices(underHalf59Choices(editActive));
        setRequireMinimum(false);
      } else {
        setRecurringSelectionChoices(betweenHalf59and73Choices);
        setRequireMinimum(false);
        setFieldValue('recurringSelection', DistributionChoices.amount); // only one choice, select it.
      }
    }
  }, [accountOwner?.dateOfBirth, account.accountType]);

  useEffect(() => {
    const amortizationParams = {
      accountBalance: debouncedAccountBalance,
    } as AmortRequestParams;
    const balanceNumber = Number(debouncedAccountBalance);

    if (term.termChoice === TermChoiceOption.jle) {
      amortizationParams.spouseDOB = term.spouseBirthDate;
    }

    if (Number.isNaN(balanceNumber) || balanceNumber < 0) {
      setFieldTouched('accountBalance');
    } else if (isFixed && balanceNumber > 0) {
      getAmortizationCalculation(
        user.organizationId,
        user.token,
        account.accountId,
        accountOwner.accountOwnerId,
        amortizationParams
      )
        .then((resp: AmortizationCalculationResponse) => {
          if (term.termChoice === TermChoiceOption.jle && term.qualifiedJLE) {
            setFieldValue(
              'totalAmount',
              resp.data.jointLifeExpectancyRMDAmortization.grossWithdrawalAmount
            );
          } else if (term.termChoice === TermChoiceOption.sle) {
            setFieldValue(
              'totalAmount',
              resp.data.singleLifeExpectancyRMDAmortization.grossWithdrawalAmount
            );
          } else if (term.termChoice === TermChoiceOption.slenonrecalc) {
            setFieldValue(
              'totalAmount',
              resp.data.singleLifeExpectancyNonRecalculatedRMDAmortization.grossWithdrawalAmount
            );
          } else {
            setFieldValue(
              'totalAmount',
              resp.data.uniformLifetimeRMDAmoritization.grossWithdrawalAmount
            );
          }
        })
        .catch((err) => {
          addGlobalMessage(errorMessages(err) ||
              'Unexpected problem loading Amortization Calculation'
          );
        });
    }
  }, [debouncedAccountBalance, debouncedRecurringSelection]);

  useEffect(() => {
    // account balance if underHalf59.5
    // 73older use fairmarketvalue
    if (isRMD || isINHRMD) {
      // enter RMD if that's selected
      setFieldValue('totalAmount', rmd, false);
    }

    let calc: string = '';
    if (isSpecificAmount) {
      // enter fmv/termlenght||lifeExpectancy if that's selected
      if (term.termLength) {
        calc = (Number(values.fairMarketValue) / term.termLength).toFixed(2);
      }

      if (
        values.lifeExpectancy &&
        ![
          TermChoiceOption.inheritedRoth10,
          TermChoiceOption.inheritedTrad10,
        ].includes(term.termChoice)
      ) {
        calc = (Number(values.fairMarketValue) / values.lifeExpectancy).toFixed(
          2
        );
      }

      setFieldValue('totalAmount', calc, false);
    }

    let minimumVal = 0;
    if (calc !== '') {
      minimumVal = Number(calc);
    } else {
      minimumVal = Number(rmd);
    }

    setFieldValue('distributionAmountType', values.recurringSelection);
    setCalculationTypeByChoice();

    setFieldValue('minimumDistribution', {
      isRequired: requireMinimum,
      minimum: minimumVal,
    });

  }, [values.recurringSelection]);

  useEffect(() => {
    if (account.accountId && accountOwner.accountOwnerId) {
      setLoading(true);
      let fmv = '';

      if (!term.fmvInDb && (values.fairMarketValue || values.accountBalance)) {
        fmv = setAmountValue();
      }

      getLifeExpectancyTerms(
        user.organizationId,
        account.accountId,
        accountOwner.accountOwnerId,
        term.ignoreSpouseBirthDate || !term.qualifiedJLE
          ? ''
          : term.spouseBirthDate,
        fmv,
        user.token,
        user
      )
        .then((response) => {

          if (isMounted) {
            let newRmd = '';
            if (
              response.data.jointLifeExpectancyRMD ||
              response.data.singleLifeExpectancyRMD ||
              response.data.singleLifeExpectancyNonRecalculatedRMD ||
              response.data.uniformLifetimeRMD
            ) {
              if (
                term.termChoice === TermChoiceOption.jle &&
                term.qualifiedJLE
              ) {
                newRmd = response.data.jointLifeExpectancyRMD.rmd;
              } else if (term.termChoice === TermChoiceOption.sle) {
                newRmd = `${response.data.singleLifeExpectancyRMD.rmd}`;
              }else if (term.termChoice === TermChoiceOption.slenonrecalc) {
                newRmd = `${response.data.singleLifeExpectancyNonRecalculatedRMD.rmd}`;
              }  else {
                newRmd = `${response.data.uniformLifetimeRMD.rmd}`;
              }
            }
            setRmd(newRmd);
          }
        })
        .catch((err) => {
  
          addGlobalMessage(errorMessages(err) ||
              'Unexpected error loading Life Expectancy Terms'
          );
        });
      setLoading(false);
    }

    prefillParentOptions();

    return () => {
      setMounted(false);
    };
  }, [user.token, user.organizationId, account.accountId]);

  return (
    <>
      {loading && (
        <Box width="1">
          <LinearProgress color="secondary" />
        </Box>
      )}
      {!validAccountTypes.includes(account.accountType) && (
        <h4>
          Recurring Distributions not supported for this account{' '}
          {account.accountType}
        </h4>
      )}
      {validAccountTypes.includes(account.accountType) && !loading && (
        <>
          {recurringSelectionChoices.length > 1 && (
            <Grid item xs={12}>
              <Field
                name="recurringSelection"
                options={recurringSelectionChoices}
                component={SiraRadioField}
              />
            </Grid>
          )}
          {[DistributionChoices.amount].includes(values.recurringSelection) && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SiraCurrencyField
                      name="totalAmount"
                      label="Total Annual Amount"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Enter the total annual amount the IRA owner would like to
                      receive each year.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

export default DistributionAmountRecurringSubForm;
