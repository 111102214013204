import React, { useContext } from 'react';

import { GlobalContext } from '../auth/GlobalContext';
import MessageSnackbar from './MessageSnackbar';

function GlobalMessages() {
  const { globalMessages } = useContext(GlobalContext);

  return (
    <>
      {globalMessages.map(({ id, content, config }) => (
        <MessageSnackbar key={id} content={content} config={config} />
      ))}
    </>
  );
}

export default GlobalMessages;
