import { StandardTextFieldProps, TextField } from '@mui/material';
import React from 'react';
import { FieldAttributes, useField } from 'formik';
import InputMask from 'react-input-mask';

interface StateTaxIdFieldProps extends StandardTextFieldProps {
  state: string;
}

const masks = {
  CA: '999-9999-9',
  CT: '99999999 999',
  IA: ['999999999', '999999999999'],
  IL: '99-9999999',
  NC: '999999999',
  VT: 'WHT99999999',
  WI: '999999999999999',
};

const StateTaxIdField: React.FC<FieldAttributes<StateTaxIdFieldProps>> = ({
  state,
  ...textFieldProps
}) => {
  const [field, meta] = useField<{}>(textFieldProps);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const currentStateMask = masks[state];


  return (
    <InputMask mask={currentStateMask} {...field}>
      {() => (
        <TextField
          helperText={errorText}
          error={!!errorText}
          variant="outlined"
          fullWidth
          type="text"
          {...field}
          {...textFieldProps}
        />
      )}
    </InputMask>
  );
};

export default StateTaxIdField;
