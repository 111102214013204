import axios from 'axios';
import format from 'string-format';

import { AmendmentNotificationsResponse } from './AmendmentNotificationApi.d';
import { setBaseUrl } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const amendmentNotificationsUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/amendmentNotifications';
const amendmentNotificationDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/amendmentNotification/{0.amendmentNotificationId}/document';

export async function getAccountAmendmentNotifications(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token,
  user: any
): Promise<AmendmentNotificationsResponse> {
  if (!token) return null;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${amendmentNotificationsUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAmendmentNotificationDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  amendmentNotificationId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${amendmentNotificationDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    amendmentNotificationId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteAccountAmendmentNotifications(
  orgId: string,
  amendmentNotificationId: string,
  accountOwnerId: string,
  accountId: string,
  token,
  user: any
) {
  if (!token) return null;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${amendmentNotificationDocumentUrl}`, {
    orgId,
    amendmentNotificationId,
    accountOwnerId,
    accountId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
