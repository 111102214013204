import { useContext } from 'react';

import { GlobalContext, PaginationContext } from './GlobalContext';

export function useGlobalContext() {
  return useContext(GlobalContext) as any;
}

export function usePaginationContext() {
  return useContext(PaginationContext) as any;
}
