import React from 'react';
import { Grid, Typography } from '@mui/material';
import Layout from '../components/Layout';
import ActionCard from '../components/ActionCard';
import PaperlessPage from '../components/PaperlessPage';
import BeneficiaryClaimsNewIcon from '../icons/BeneficiaryClaimsNew';
import BeneficiarySearch from '../icons/BeneficiarySearch';

function BeneficiaryClaims() {
  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography
            data-qa="beneficiary-header"
            variant="overline"
            gutterBottom
          >
            Beneficiary Claims
          </Typography>
          <Grid container spacing={3} wrap="wrap" mt={2}>
            <ActionCard
              dataQa="submit-claim"
              iconImage={
                <BeneficiaryClaimsNewIcon
                  color="secondary"
                  style={{ fontSize: 42 }}
                />
              }
              buttonText="Submit Beneficiary Claim"
              route="/beneficiaryClaims/submitBeneficiaryClaim"
            />
            <ActionCard
              dataQa="view-claim"
              iconImage={
                <BeneficiarySearch color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="View Beneficiary Claims"
              route="/beneficiaryClaims/viewBeneficiaryClaim"
            />
          </Grid>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default BeneficiaryClaims;
