import React from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  StandardTextFieldProps,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FieldAttributes, useField, useFormikContext } from 'formik';

const SiraSearchField: React.FC<FieldAttributes<StandardTextFieldProps>> = (
  props
) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const { isSubmitting } = useFormikContext();

  const classes = {
    input: {
      background: 'white',
    },
  };

  return (
    <Box pt={1}>
    <TextField
      helperText={errorText}
      error={Boolean(errorText)}
      variant="outlined"
      fullWidth
      data-qa='searchForOrg'
      InputProps={{      
        disabled:isSubmitting,
        sx: classes.input,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="secondary"
              aria-label="submit and find account"
              type="submit"
              size="large"
              disabled={isSubmitting}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...field}
      {...props}
    />
    </Box>
  );
};

export default SiraSearchField;
