import React, { useState, useEffect } from 'react';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import { useUser } from '../../auth/useUser';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { useGlobalContext } from '../../auth/useGlobalContext';
import {
  createStateTaxDeadline,
  deleteStateTaxDeadline,
  getStateTaxDeadlineNoYear,
} from '../../api/StatesApi';
import { StateTaxDeadline } from '../../api/StatesApi.d';
import { dateValueFormatter } from '../../utils/DataGrid.utils';
import StateTaxDeadlineForm, {
  STATE_TAX_DEADLINE,
} from './form/StateTaxDeadlineForm';
import TaxYearsFilterForm from '../form/TaxYearsFilterForm';
import { errorMessages } from '../../utils/errorhandling.utils';

function StateTaxDeductible() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();

  const [isEditing, setIsEditing] = useState(false as boolean);
  const [isCreating, setIsCreating] = useState(false as boolean);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [stateTaxToEdit, setStateTaxToEdit] = useState({} as StateTaxDeadline);
  const [stateTaxDeductible, setStateTaxDeductible] = useState(
    [] as Array<StateTaxDeadline>
  );
  const [filteredStateTaxDeductible, setFilteredstateTaxDeductible] = useState(
    stateTaxDeductible as Array<StateTaxDeadline>
  );
  const taxYearFilterOptions = [
    ...new Set(stateTaxDeductible.map(({ taxYear }) => taxYear.toString())),
  ];

  const theme = useTheme();

  const classes = {
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      justifyContent: 'flex-end' as any,
      padding: theme.spacing(2),
    },
  };

  const getAllStateTaxDeadline = async () => {
    setIsLoading(true);
    await getStateTaxDeadlineNoYear(user.token, user)
      .then((res) => {
        setIsLoading(false);
        if (isMounted) {
          const modifiedData = res.data.map((item) => {
            return {
              ...item,
              year: item.taxYear.toString(),
            };
          }
          );

          setStateTaxDeductible(modifiedData);
        }
      })
      .catch((err) => {
        setIsLoading(false);

 addGlobalMessage(errorMessages(err) || 'Error fetching state tax deadline'
        );
      });
  };

  const createOrUpdateStateTaxDeadline = async (values: StateTaxDeadline) => {
    setIsLoading(true);
    const { filingDeadline, promptText, state, taxYear } = values;
    const stateTaxDeadline = {
      filingDeadline,
      promptText,
      state,
      taxYear,
    };
    await createStateTaxDeadline(stateTaxDeadline, user.token, user)
      .then(() => {
        addGlobalMessage('State tax deadline successfully created/updated', {
          severity: 'success',
        });
        setIsLoading(false);

        if (isMounted) {
          getAllStateTaxDeadline();
        }
      })
      .catch((err) => {
        setIsLoading(false);

 addGlobalMessage(errorMessages(err) || 'Error fetching state tax deadline'
        );
      });
  };

  const deleteTaxDeadline = async (row) => {
    setIsLoading(true);
    await deleteStateTaxDeadline(row, user.token, user)
      .then(() => {
        setIsLoading(false);
        if (isMounted) {
          addGlobalMessage('State tax deadline successfully deleted', {
            severity: 'success',
          });
          setIsLoading(false);

          if (isMounted) {
            getAllStateTaxDeadline();
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);

 addGlobalMessage(errorMessages(err) || 'Error deleting state tax deadline'
        );
      });
  };

  useEffect(() => {
    if (user.token) {
      getAllStateTaxDeadline();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token]);

  const columns: GridColDef[] = [
    {
      field: 'taxYear',
      headerName: 'Tax Year',
      width: 126,
    },
    {
      field: 'state',
      headerName: 'State',
    },
    {
      field: 'filingDeadline',
      headerName: 'Tax Return Deadline',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'promptText',
      headerName: 'Help Text',

      width: 300,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const { promptText } = row as StateTaxDeadline;

        return (
          <Tooltip
            title={
              <Typography style={{ fontSize: 13 }}>{promptText}</Typography>
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ width: 300 }}>{promptText}</Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const [isDeleting, setIsDeleting] = useState(false as boolean);

        return (
          <>
            <Grid container justifyContent="flex-end">
              <IconButton
                disabled={isCreating || isLoading}
                data-testid="edit-federal-rate"
                size="small"
                aria-label="Edit Applicable Federal Rate"
                onClick={() => {
                  setStateTaxToEdit(row);
                  setIsEditing(true);
                  setIsCreating(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                disabled={isDeleting}
                data-testid="delete-federal-rate"
                size="small"
                aria-label="Delete Applicable Federal Rate"
                onClick={async () => {
                  setIsDeleting(true);
                  deleteTaxDeadline(row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
  ];


  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12}>
            <TaxYearsFilterForm
              items={stateTaxDeductible}
              options={taxYearFilterOptions}
              onChange={setFilteredstateTaxDeductible}
            />
          </Grid>
        </Grid>
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          loading={isLoading}
          components={{
            NoRowsOverlay: SiraNoRowsOverlay,
            Toolbar: () => (
              <Grid container wrap="nowrap" spacing={2} sx={classes.buttonBar}>
                <Grid item>
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setIsCreating(true);
                      setIsEditing(false);
                      setStateTaxToEdit({} as StateTaxDeadline);
                    }}
                  >
                    Add New State Tax Deadline
                  </Button>
                </Grid>
              </Grid>
            ),
          }}
          pageSizeOptions={[5, 10, 15]}
          disableColumnMenu
          autoHeight
          columns={columns}
          rows={filteredStateTaxDeductible.map((result, id) => {
            return {
              id,
              ...result,
            };
          })
          }
        />
      </Box>
      <Grid container spacing={2}>
        {isCreating && (
          <Grid item xs={12}>
            <StateTaxDeadlineForm
              initialValues={{
                ...STATE_TAX_DEADLINE,
                ...stateTaxToEdit,
              }}
              onCancel={() => {
                setIsCreating(false);
                setIsEditing(false);
              }}
              onSubmit={(values) => {
                setIsCreating(false);
                setIsEditing(false);
                createOrUpdateStateTaxDeadline(values);
              }}
              isEditing={isEditing}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default StateTaxDeductible;
