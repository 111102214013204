import React from 'react';

interface PluralizedStringProps {
  quantity: number;
  noun: string;
}

const PluralizedString = (props: PluralizedStringProps) => {
  const { quantity = 0, noun = '' } = props;

  if (quantity !== 1) {
    if (noun.length > 3 && noun.slice(-1) === 'y') {
      return <>{noun.substring(0, noun.length - 1)}ies</>;
    }
    if (noun.length > 3 && noun.slice(-2) === 'us') {
      return <>{noun.substring(0, noun.length - 2)}i</>;
    }
    if (noun.slice(-2) === 'ss') {
      return <>{noun.substring(0, noun.length - 1)}ses</>;
    }
    if (noun.slice(-1) === 's') {
      return <>{noun}ses</>;
    }

    return <>{noun}s</>;
  }

  return <>{noun}</>;
};

export default PluralizedString;
