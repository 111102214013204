import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { AccountType } from '../../../api/AccountApi.d';
import {
  DistributionReason,
  DistributionMethod,
  BeneWithholdingDistributitionOption,
} from '../../../api/DistributionApi.d';
import { RadioGroupOption } from '../SiraRadioField';
import { distributionMethods } from './resource.txt';
import { BeneficiaryTypes } from '../../../api/BeneficiariesApi.d';

export const distributionTypes = [
  'newRecurringDistribution',
  'editRecurringDistribution',
];

const checkTypeText = (accountType) => {
  switch (accountType) {
    case AccountType.hsa:
      return distributionMethods.hsa.transferToAnotherHSA.check.afterContent;
    case AccountType.esa:
      return distributionMethods.esa.transferToAnotherESASame.check
        .afterContent;
    case AccountType.traditionalSep:
      return distributionMethods.sepIRA.check.afterContent;
    case AccountType.simpleIra:
      return distributionMethods.simpleIRA.check.afterContent;
    case AccountType.traditionalIra:
      return distributionMethods.tradIRA.check.afterContent;
    default:
      return distributionMethods.tradIRA.check.afterContent;
  }
};

const transferOtherLabel = (accountType) => {
  switch (accountType) {
    case AccountType.hsa:
      return 'Other non-health account';
    case AccountType.esa:
      return 'Other non-education account';
    default:
      return 'Other non-retirement account';
  }
};

export const beneDistributionMethodOptions = [
  {
    value: DistributionMethod.cash,
    label: 'Cash',
  },
  {
    value: DistributionMethod.check,
    label: 'Check',
    afterContent: distributionMethods.default.check.afterContent,
  },
  {
    value: DistributionMethod.direct,
    label: 'Transfer to account at another financial organization',
  },
  {
    value: DistributionMethod.transfer,
    label: 'Transfer to account at another financial organization',
  },
];

export const beneStateWithholdingDistributionOptions = [
  {
    value: BeneWithholdingDistributitionOption.stateWitholding,
    label: 'With state withholding on form',
  },
  {
    value: BeneWithholdingDistributitionOption.stateWitholdingsepform,
    label: 'With state withholding on separate form',
  },
  {
    value: BeneWithholdingDistributitionOption.noStateWithholding,
    label: 'With no state withholding',
  },
];

export const getAllowedDistributionMethodCustomer = (organization) => {
  const methodResult = [];

  organization.distributionMethodsSupported.forEach((method) => {
    switch (method) {
      case DistributionMethod.check:
        methodResult.push({
          value: DistributionMethod.check,
          label: 'Check',
        });
        break;
      case DistributionMethod.direct:
        methodResult.push({
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        });
        break;
      case DistributionMethod.transfer:
        methodResult.push({
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        });
        break;
      default:
        break;
    }
  });
  return methodResult;
};

export const getAllowedDistributionMethod = (organization) => {
  const methodResult = [];

  organization.distributionMethodsSupported.forEach((method) => {
    switch (method) {
      case DistributionMethod.cash:
        methodResult.push({
          value: DistributionMethod.cash,
          label: 'Cash',
        });
        break;
      case DistributionMethod.check:
        methodResult.push({
          value: DistributionMethod.check,
          label: 'Check',
        });
        break;
      case DistributionMethod.direct:
        methodResult.push({
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        });
        break;
      case DistributionMethod.transfer:
        methodResult.push({
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        });
        break;
      default:
        break;
    }
  });
  return methodResult;
};

export const getAllowedDistributionMethodWithReasonCustomer = (
  distributionReason,
  organization,
  accountType
) => {
  let methodResult;
  switch (distributionReason) {
    case DistributionReason.TRANSFER:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: checkTypeText(accountType),
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.HSA_TRANSFER:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: checkTypeText(accountType),
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_DISABILITY:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_EARLY:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_EARLY_IRS_LEVY:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_EARLY_ROTH_CONVERSION:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_NORMAL:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_NORMAL_ROTH_CONVERSION:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.SAME_YEAR_RECHAR:
      methodResult = [
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.PRIOR_YEAR_RECHAR:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_ROLLOVER:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.ESA_TRANSFER:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent:
            distributionMethods.esa.transferToAnotherESASame.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.ESA_NORMAL:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.ESA_TRANSFER_NEW_BENEFICIARY:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent:
            distributionMethods.esa.transferToAnotherESASame.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    default:
      return [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
  }
};

export const getAllowedDistributionMethodWithReason = (
  distributionReason,
  organization,
  accountType
) => {
  let methodResult;
  switch (distributionReason) {
    case DistributionReason.TRANSFER:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: checkTypeText(accountType),
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.HSA_TRANSFER:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: checkTypeText(accountType),
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_DISABILITY:
      methodResult = [
        {
          value: DistributionMethod.cash,
          label: 'Cash',
        },
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_EARLY:
      methodResult = [
        {
          value: DistributionMethod.cash,
          label: 'Cash',
        },
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_EARLY_IRS_LEVY:
      methodResult = [
        {
          value: DistributionMethod.cash,
          label: 'Cash',
        },
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_EARLY_ROTH_CONVERSION:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_NORMAL:
      methodResult = [
        {
          value: DistributionMethod.cash,
          label: 'Cash',
        },
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_NORMAL_ROTH_CONVERSION:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.SAME_YEAR_RECHAR:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.PRIOR_YEAR_RECHAR:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.IRA_ROLLOVER:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.tradIRA.check.afterContent,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.ESA_TRANSFER:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent:
            distributionMethods.esa.transferToAnotherESASame.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.ESA_NORMAL:
      methodResult = [
        {
          value: DistributionMethod.cash,
          label: 'Cash',
        },
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    case DistributionReason.ESA_TRANSFER_NEW_BENEFICIARY:
      methodResult = [
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent:
            distributionMethods.esa.transferToAnotherESASame.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
      return methodResult;
    default:
      return [
        {
          value: DistributionMethod.cash,
          label: 'Cash',
        },
        {
          value: DistributionMethod.check,
          label: 'Check',
          afterContent: distributionMethods.default.check.afterContent,
        },
        {
          value: DistributionMethod.direct,
          label: `Transfer to account at ${organization.name}`,
        },
        {
          value: DistributionMethod.transfer,
          label: 'Transfer to account at another financial organization',
        },
      ];
  }
};

export const distributionMethodLogic = (
  distributionReason,
  organization,
  accountType,
  distributionType,
  customerPortalUser
) => {
  let methodResult;

  if (distributionTypes.includes(distributionType)) {
    methodResult = customerPortalUser
      ? getAllowedDistributionMethodCustomer(organization)
      : getAllowedDistributionMethod(organization);
  } else {
    methodResult = customerPortalUser
      ? getAllowedDistributionMethodWithReasonCustomer(
          distributionReason,
          organization,
          accountType
        )
      : getAllowedDistributionMethodWithReason(
          distributionReason,
          organization,
          accountType
        );
  }

  return methodResult;
};

const transferDistributionReason = (
  getAppConstant: Function,
  accountType: AccountType,
  relationship: BeneficiaryTypes
) => {
  const transferAccountType = [
    {
      value: AccountType.traditionalIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalIra
      ),
    },
    {
      value: AccountType.simpleIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.simpleIra
      ),
      afterContent:
        distributionMethods.simpleIRA.transferToAccount.afterContent,
    },
    {
      value: AccountType.traditionalSep,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalSep
      ),
    },
  ];

  const transferAccountTypeSimpleIra = [
    {
      value: AccountType.traditionalIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalIra
      ),
      afterContent: distributionMethods.tradIRA.transferToAccount.afterContent,
    },
    {
      value: AccountType.simpleIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.simpleIra
      ),
    },
    {
      value: AccountType.traditionalSep,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalSep
      ),
      afterContent:
        accountType === AccountType.simpleIra &&
        distributionMethods.sepIRA.transferToAccount.afterContent,
    },
  ];

  const transferAccountTypeRoth = [
    {
      value: AccountType.rothIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.rothIra
      ),
    },
  ];

  const transferAccountTypeInheritedTrad = [
    {
      value: AccountType.traditionalIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalIra
      ),
    },
    {
      value: AccountType.inheritedTraditional,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.inheritedTraditional
      ),
    },
  ];

  const transferAccountTypeInheritedRoth = [
    {
      value: AccountType.rothIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.rothIra
      ),
    },
    {
      value: AccountType.inheritedRoth,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.inheritedRoth
      ),
    },
  ];


  let distributionMethodOptions;

  switch (accountType) {
    case AccountType.rothIra:
      distributionMethodOptions = transferAccountTypeRoth;
      return distributionMethodOptions;
    case AccountType.simpleIra:
      distributionMethodOptions = transferAccountTypeSimpleIra;
      return distributionMethodOptions;
    case AccountType.inheritedRoth:
      // if relationship is spouse, then show inherited roth and roth, otherwise return inherited roth only
      distributionMethodOptions = relationship === BeneficiaryTypes.SPOUSE ? transferAccountTypeInheritedRoth :[transferAccountTypeInheritedRoth[1]];
      return distributionMethodOptions;
    case AccountType.inheritedTraditional:
      // if relationship is spouse, then show inherited traditional and traditional, otherwise return inherited traditional only
      distributionMethodOptions = relationship === BeneficiaryTypes.SPOUSE ? transferAccountTypeInheritedTrad : [transferAccountTypeInheritedTrad[1]];
      return distributionMethodOptions;
    default:
      distributionMethodOptions = transferAccountType;
      return distributionMethodOptions;
  }
};

export function accountTypeSelection(
  getAppConstant,
  accountType,
  distributionReason,
  relationship
) {
  
  let distributionMethodOptions: Array<RadioGroupOption> = [
    {
      value: AccountType.checking,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.checking
      ),
    },
    {
      value: AccountType.savings,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.savings
      ),
    },
    {
      value: AccountType.other,
      label: transferOtherLabel(accountType),
    },
  ];

  const transferAccountTypeHSA = [
    {
      value: AccountType.hsa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.hsa),
    },
  ];

  const transferAccountTypeESA = [
    {
      value: AccountType.esa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.esa),
    },
    {
      value: AccountType.esa529,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.esa529
      ),
    },
  ];

  const transferAccountTypeRoth = [
    {
      value: AccountType.rothIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.rothIra
      ),
    },
  ];

  const transferAccountTypeTradIRA = [
    {
      value: AccountType.traditionalIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalIra
      ),
    },
  ];

  switch (distributionReason) {
    case DistributionReason.HSA_TRANSFER:
      distributionMethodOptions = transferAccountTypeHSA;
      return distributionMethodOptions;
    case DistributionReason.TRANSFER:
      distributionMethodOptions = transferDistributionReason(
        getAppConstant,
        accountType, 
        relationship
      );
      return distributionMethodOptions;
    case DistributionReason.IRA_EARLY_ROTH_CONVERSION:
      distributionMethodOptions = transferAccountTypeRoth;
      return distributionMethodOptions;
    case DistributionReason.IRA_NORMAL_ROTH_CONVERSION:
      return transferAccountTypeRoth;
    case DistributionReason.SAME_YEAR_RECHAR:
      distributionMethodOptions =
        accountType === AccountType.rothIra
          ? transferAccountTypeTradIRA
          : transferAccountTypeRoth;
      return distributionMethodOptions;
    case DistributionReason.PRIOR_YEAR_RECHAR:
      distributionMethodOptions =
        accountType === AccountType.rothIra
          ? transferAccountTypeTradIRA
          : transferAccountTypeRoth;
      return distributionMethodOptions;
    case DistributionReason.IRA_ROLLOVER:
      return [
        {
          value: AccountType.employer,
          label: getAppConstant(
            ConstantsMappingKey.accountType,
            AccountType.employer
          ),
        },
      ];
    case DistributionReason.ESA_TRANSFER:
      return transferAccountTypeESA;
    case DistributionReason.ESA_TRANSFER_NEW_BENEFICIARY:
      return transferAccountTypeESA;
    default:
      return distributionMethodOptions;
  }
}
