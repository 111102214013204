import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Grid,
  Box,
  LinearProgress,
  Button,
  Tooltip,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { Form, Formik } from 'formik';
import { useGlobalContext, usePaginationContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';

import {
  createFinancialOrganizationDocs,
  deleteFinancialOrganizationDoc,
  getFinancialOrganizationDoc,
  getIndividualFinancialOrganizationDoc,
  putFinancialOrganizationDoc,
} from '../../api/OrganizationApi';
import { dateValueFormatter } from '../../utils/DataGrid.utils';
import { globalPaginationOptions } from '../../app.constants';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { User, UserRole } from '../../api/UserApi.d';
import { FinOrgDoc } from '../../api/OrganizationApi.d';
import { getUsers } from '../../api/UserApi';
import { Desc } from '../form/beneficiariesClaims/ViewBeneficiaryClaimFiles';
import SiraFileField from '../form/SiraFileField';
import StepButtonBar from '../steps/StepButtonBar';
import { setFileTypeName } from '../form/beneficiariesClaims/resource.utils';
import { completeTransaction } from '../../page/TransactionReducer';
import {
  createFileBlobFromBase64,
  downloadAsFile,
} from '../../utils/App.utils';
import { errorMessages } from '../../utils/errorhandling.utils';

interface SearchResultRow extends FinOrgDoc {
  id: number;
  visibility: boolean;
  communityPropertyState: boolean;
}

export const FINANCIAL_ORGANIZATION_DOCS_INIT = {
  uploadFiles: [],
  descriptionField: [{ description: '' }],
};

const FINANCIAL_ORGANIZATION_DOCS_SCHEMA = {
  descriptionField: yup.array().of(
    yup.object().shape({
      description: yup.string().required().label('Description'),
    })
  ),
};

export interface FinOrgFiles {
  uploadFiles: Array<File>;
  descriptionField: Array<Desc>;
}

function OrganizationDocuments() {
  const isMounted = false;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const {  setGlobalPageSize, globalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState(false);
  const [addNewFile, setAddNewFile] = useState(false);
  const [finOrgDocs, setFinOrgDocs] = useState<Array<FinOrgDoc>>();
  const [currentUser, setCurrentUser] = useState<User>();
  const [multiple, setMultiple] = useState(true);
  const [resetState, setResetState] = useState('');
  const [dataToEdit, setDataToEdit] = useState(null as any);

  const classes = {
    fileLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  async function downloadFile(row: FinOrgDoc): Promise<void> {
    const nameValue = row.key.replace(/^.*[\\/]/, '');
    await getIndividualFinancialOrganizationDoc(
      nameValue,
      user.organizationId,
      user.token
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const blob =
          createFileBlobFromBase64(base64, 'application/pdf') || data;

        downloadAsFile(blob, nameValue);
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) ||
              'Could not fetch this individuals organization documents'
          );
        }
      });
  }

  async function getFinOrgDocuments(): Promise<void> {
    await getFinancialOrganizationDoc(
      currentUser,
      user.organizationId,
      user.token
    )
      .then((responseValue) => {
        setFinOrgDocs(responseValue.data);
        setIsLoading(false);
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) ||
              'Could not fetch the financial organization documents'
          );
        }
      });
  }

  const setRepInfo = async (): Promise<void> => {
    await getUsers(user.organizationId, user.token)
      .then((res) => {
        setCurrentUser(
          res.data.find((data) => {
            return data.auth0UserId === user.sub && data;
          })
        );
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(errorMessages(err) ||'Error fetching organization users');
        }
      });
  };

  async function deleteFinancialOrgDoc(row: FinOrgDoc): Promise<void> {
    const nameValue = row.key.replace(/^.*[\\/]/, '');

    await deleteFinancialOrganizationDoc(
      nameValue,
      user.organizationId,
      user.token
    )
      .then(() => {
        setRepInfo();
        setDataToEdit(null);
        getFinOrgDocuments();
        addGlobalMessage('Document deleted successfully', {
          severity: 'success',
        });
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) ||
              'Could not delete the financial organization documents'
          );
        }
      });
  }

  async function uploadDocuments(values): Promise<void> {
    const { descriptionField: descriptions = [], uploadFiles } = values;
    // Pick the first one when updating a file (does not support multi-file)
    const [{ description = '' }] = descriptions;

    if (addNewFile) {
      await createFinancialOrganizationDocs(
        uploadFiles,
        descriptions,
        user.organizationId,
        user.token
      )
        .then(() => {
          setAddNewFile(false);
          getFinOrgDocuments();
          setResetState('reset');
          setDataToEdit(null);
          completeTransaction();
          addGlobalMessage('Document upload successful', {
            severity: 'success',
          });
        })
        .catch((err) => {


        addGlobalMessage(errorMessages(err) || 'Could not upload file'
        );         
         setResetState('reset');
        });
    } else {
      await putFinancialOrganizationDoc(
        uploadFiles,
        description,
        user.organizationId,
        user.token
      )
        .then(() => {
          setAddNewFile(false);
          getFinOrgDocuments();
          completeTransaction();
          setDataToEdit(null);
          setResetState('reset');
        })
        .catch((err) => {
  
          addGlobalMessage(errorMessages(err) ||
            'Could not update uploaded file'
        );
              });
    }
  }

  useEffect(() => {
    setIsLoading(true);
    setRepInfo();
    getFinOrgDocuments();
  }, [user.organizationId, user.token]);

  const acceptedFileTypes = {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
    'text/csv': ['.csv', '.txt'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
  };
  const acceptedFilesText = 'pdf, png, jpg, jpeg, xlsx, csv, txt, and docx';

  const retrieveFileObject = async (row) => {
    const nameValue = row.key.replace(/^.*[\\/]/, '');

    await getIndividualFinancialOrganizationDoc(
      nameValue,
      user.organizationId,
      user.token
    )
      .then((res) => {
        const { description = '' } = row;
        const document = [
          new File([res.data], nameValue, { type: setFileTypeName(nameValue) }),
        ];
        setDataToEdit({ document, description });
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) ||
              'Could not fetch this individuals organization documents'
          );
        }
      });
  };

  const columns = user.roles.includes(UserRole.admin)
    ? [
        {
          field: 'key',
          headerName: 'File Name',
          width: 175,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            const nameValue = row.key.replace(/^.*[\\/]/, '');

            return (
              <Button
                data-qa="account-search-result-link"
                sx={classes.fileLink}
                startIcon={<FileDownloadIcon />}
                onClick={() => {
                  downloadFile(row);
                }}
                title={nameValue}
              >
                <Box overflow="hidden" textOverflow="ellipsis">
                  {nameValue}
                </Box>
              </Button>
            );
          },
        },
        {
          field: 'ownerDisplayName',
          headerName: 'Owner',
          width: 175,
          sortable: false,
        },
        {
          field: 'lastModified',
          headerName: 'Submitted Date',
          width: 126,
          valueFormatter: dateValueFormatter,
        },

        {
          field: 'description',
          headerName: 'Description',
          width: 200,
          sortable: false,
        },
        {
          field: 'updateDelete',
          headerName: '',
          width: 100,
          sortable: false,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;

            return (
              <>
                <Box pl={1}>
                  <Tooltip title="Update">
                    <IconButton
                      onClick={() => {
                        setAddNewFile(false);
                        setResetState('');
                        setMultiple(false);
                        retrieveFileObject(row);
                      }}
                    >
                      <FileUploadIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box pl={1}>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => {
                        deleteFinancialOrgDoc(row);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            );
          },
        },
      ]
    : [
        {
          field: 'key',
          headerName: 'File Name',
          width: 175,
          renderCell: (params: GridCellParams) => {
            const { value = '', row = {} } = params as any;
            const nameValue = value.split('/')[3].split('.')[0] ;
            return (
              <Button
                data-qa="account-search-result-link"
                sx={classes.fileLink}
                startIcon={<FileDownloadIcon />}
                onClick={() => {
                  downloadFile(row);
                }}
                title={nameValue}
              >
                <Box overflow="hidden" textOverflow="ellipsis">
                  {nameValue}
                </Box>
              </Button>
            );
          },
        },
        {
          field: 'ownerDisplayName',
          headerName: 'Owner',
          width: 175,
          sortable: false,
        },
        {
          field: 'lastModified',
          headerName: 'Submitted Date',
          width: 126,
          valueFormatter: dateValueFormatter,
        },

        {
          field: 'description',
          headerName: 'Description',
          width: 200,
          sortable: false,
        },
      ];

  function resultRowFactory(
    resultRow: FinOrgDoc,
    index: number
  ): SearchResultRow {
    return {
      ...resultRow,
      id: index,
      visibility: false,
      communityPropertyState: false,
    };
  }

  const rows = finOrgDocs
    ? finOrgDocs.map((resultRow, index) => resultRowFactory(resultRow, index))
    : [];

  return isLoading ? (
    <Box width="1">
      <LinearProgress color="secondary" />
    </Box>
  ) : (
    <Formik
      initialValues={FINANCIAL_ORGANIZATION_DOCS_INIT}
      onSubmit={async (values: FinOrgFiles) => {
        await uploadDocuments(values);
      }}
      validationSchema={yup.object(FINANCIAL_ORGANIZATION_DOCS_SCHEMA)}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue, resetForm }) => {
        const { document, description } = dataToEdit || {};

        useEffect(() => {
          setFieldValue('uploadFiles', document);
          setFieldValue('descriptionField[0.description]', description);
        }, [dataToEdit]);

        return (
          <Form>
            {/* LOGO */}
            <Box p={2} border="1px solid lightgrey" borderRadius={2}>
              <Grid container spacing={3}>
                <>
                  <Grid item>
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="contained"
                      color="primary"
                      startIcon={<FileUploadIcon />}
                      onClick={() => {
                        setAddNewFile(true);
                        setMultiple(true);
                        resetForm(FINANCIAL_ORGANIZATION_DOCS_INIT as any);
                        setResetState('reset');
                      }}
                    >
                      Add New Files
                    </Button>
                  </Grid>
                </>
                <Grid item xs={12}>
                  <Box mt={1}>
                    <DataGrid
                      components={{
                        NoRowsOverlay: SiraNoRowsOverlay,
                      }}
                      disableColumnMenu
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}

                      autoHeight
                      columns={columns}
                      rows={rows}
                    />
                  </Box>
                </Grid>
                {(addNewFile || dataToEdit) && (
                  <Grid item xs={12}>
                    <Box mt={1}>
                      <SiraFileField
                        onDropField={(docs) => {
                          setFieldValue('uploadFiles', docs);
                        }}
                        name="uploadFiles"
                        description
                        multipleFiles={multiple}
                        descriptionField="descriptionField"
                        resetState={resetState}
                        acceptedFileTypes={acceptedFileTypes}
                        acceptedFilesText={acceptedFilesText}
                      />
                      <StepButtonBar
                        isSubmitting={isSubmitting}
                        submitName={
                          addNewFile ? 'Upload Document(s)' : 'Update Document'
                        }
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OrganizationDocuments;
