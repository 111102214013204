import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { parseISO } from 'date-fns';

import { useGlobalContext } from '../../../auth/useGlobalContext';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { createOrSaveAccountOwner } from '../../../api/AccountOwnerApi';
import { useUser } from '../../../auth/useUser';
import SiraDateField, { dateValidation } from '../SiraDateField';
import StepButtonBar from '../../steps/StepButtonBar';
import { createOrSaveAccount } from '../../../api/AccountApi';
import { Account } from '../../../api/AccountApi.d';
import SiraCurrencyField from '../SiraCurrencyField';

export enum AccountLocationEnum {
  internal = 'INTERNAL',
  external = 'EXTERNAL',
  unknown = 'UNKNOWN',
}

export interface DeceasedOwnerFormProps {
  deceasedOwner: AccountOwner;
  deceasedAccount: Account;
  onUpdateOwner: Function;
  onUpdateAccount: Function;
  initialValues: any;
  submitName?: string;
  resetName?: string;
}

export const DEATH_DATE_FORM_INIT = {
  dateOfDeath: '',
  dateOfDeathAccountBalance: '',
};

export const EXTERNAL_ACCOUNT_SCHEMA = (dateOfBirth: string) => {
  return {
    dateOfDeath: dateValidation(
      dateOfBirth && parseISO(dateOfBirth),
      new Date()
    )
      .required()
      .label('Date of Death'),
    dateOfDeathAccountBalance: yup
      .string()
      .required()
      .label('Date of Death Account Balance'),
  };
};

function DeathDateForm({
  submitName,
  deceasedOwner,
  deceasedAccount,
  onUpdateOwner,
  onUpdateAccount,
  initialValues,
  resetName,
}: DeceasedOwnerFormProps) {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();

  const updateDeathDate = async (dateOfDeath: string) => {
    const updatedOwner = { ...deceasedOwner, dateOfDeath };

    createOrSaveAccountOwner(updatedOwner, user.organizationId, user.token, user)
      .then(({ data }) => {
        if (isMounted) {
          onUpdateOwner(data, false);
        }
      })
      .catch(() => {
        addGlobalMessage('Unable to Update Date of Owner Death');
      });
  };

  const updateDateOfDeathAccountBalance = async (
    dateOfDeathAccountBalance: string
  ) => {
    const updatedAccount = { ...deceasedAccount, dateOfDeathAccountBalance };
    createOrSaveAccount(
      updatedAccount,
      user.organizationId,
      deceasedOwner.accountOwnerId,
      user.token,
      user
    )
      .then(({ data }) => {
        if (isMounted) {
          onUpdateAccount(data, false);
        }
      })
      .catch(() => {
        addGlobalMessage('Unable to Update Date of Death Account Balance');
      });
  };

  const deathDaySchema = useMemo(() => {
    return EXTERNAL_ACCOUNT_SCHEMA(deceasedOwner.dateOfBirth);
  }, [deceasedOwner.dateOfBirth]);

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ dateOfDeath, dateOfDeathAccountBalance }) => {
        await updateDeathDate(dateOfDeath);
        await updateDateOfDeathAccountBalance(dateOfDeathAccountBalance);
      }}
      validationSchema={yup.object(deathDaySchema)}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <Box width="1" mt={4} mb={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SiraDateField name="dateOfDeath" label="Date of Death" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SiraCurrencyField
                  name="dateOfDeathAccountBalance"
                  label="Balance on Date of Death"
                />
              </Grid>
            </Grid>
          </Box>
          <StepButtonBar
            isSubmitting={isSubmitting}
            submitName={submitName}
            resetName={resetName}
          />
        </Form>
      )}
    </Formik>
  );
}

export default DeathDateForm;
