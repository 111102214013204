import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Params } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  LinearProgress,
  Badge,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useUser } from '../auth/useUser';
import {
  createOrSaveAccountOwner,
  deleteAccountOwner,
  getAccountOwner,
  syncAccountOwner,
} from '../api/AccountOwnerApi';
import { AccountOwner, AccountOwnerResponse } from '../api/AccountOwnerApi.d';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import AccountOwnerAccounts from '../components/accountOwnerProfile/AccountOwnerAccounts';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import { useGlobalContext } from '../auth/useGlobalContext';
import AccountOwnerForm, {
  ACCOUNT_OWNER_INIT,
} from '../components/form/newAccount/AccountOwnerForm';
import { UserRole } from '../api/UserApi.d';
import { SiraDeleteModal } from '../components/SiraDeleteModal';
import { errorMessages } from '../utils/errorhandling.utils';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
export interface AccountOwnerPageParams extends Params {
  accountOwnerId: string;
  accountId: string;
}

function AccountOwnerAdditionalInfo(props) {
  const [isEditing, setIsEditing] = useState(false as boolean);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [modalData, setModalData] = useState({ body: '', title: '' });
  const [modalOpen, setmodalOpen] = useState(false);

  const { addGlobalMessage, countries } = useGlobalContext();
  const { user } = useUser();
  const navigate = useNavigate();

  const { accountOwner = {}, setResponse } = props;
  const {
    representativeFullName = '',
    dateOfBirth = '',
    dateOfDeath = '',
    emailAddress = '',
    phoneNumber = '',
    addressLine1 = '',
    addressLine2 = '',
    addressLine3 = '',
    city = '',
    state = '',
    foreignStateProvinceRegion = '',
    zip = '',
    country = '',
  } = accountOwner as AccountOwner;

  const saveAccountOwner = async (data: AccountOwner) => {
    await createOrSaveAccountOwner(data, user.organizationId, user.token, user)
      .then((res) => {
        setResponse(res);
        setIsEditing(false);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error saving account owner information'
        );
      });
  };

  const deleteAccountOwnerActivity = async () => {
    setIsLoading(true);
    await deleteAccountOwner(
      accountOwner.accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(() => {
        navigate(`/accountOwner`);
        setmodalOpen(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setmodalOpen(false);

        addGlobalMessage(errorMessages(err) || 'Error deleting account owner');
      });
  };

  const buildModalData = () => {
    setModalData({
      title: 'Account',
      body: `This will delete all accounts and activity for this account owner. Do you want to delete this account owner?`,
    });
    setmodalOpen(true);
  };

  // close opened modal
  const handleClose = () => {
    setmodalOpen(false);
  };

  if (isLoading) {
    return (
      <Box width="1" mt={5} mb={3}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return isEditing ? (
    <>
      <AccountOwnerForm
        initialValues={{
          ...ACCOUNT_OWNER_INIT,
          ...accountOwner,
        }}
        onSubmit={saveAccountOwner}
        onCancel={() => {
          setIsEditing(false);
        }}
        showDateOfDeath
      />
    </>
  ) : (
    <>
      <Grid container spacing={3}>
        {representativeFullName && (
          <Grid item xs={12}>
            <Typography variant="h6">Representative</Typography>
            <Typography variant="body1">{representativeFullName}</Typography>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Date of Birth</Typography>
          <Typography variant="body1">
            {dateOfBirth && format(parseISO(String(dateOfBirth)), 'MM/dd/yyyy')}
          </Typography>
        </Grid>
        {dateOfDeath && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Date of Death</Typography>
            <Typography variant="body1">
              {format(parseISO(String(dateOfDeath)), 'MM/dd/yyyy')}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Email</Typography>
          <Typography variant="body1">{emailAddress || '---'}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Phone</Typography>
          <Typography variant="body1">{phoneNumber || '---'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Address</Typography>
          <Typography variant="body1">{addressLine1}</Typography>
          <Typography variant="body1">{addressLine2}</Typography>
          <Typography variant="body1">{addressLine3}</Typography>
          {city && (
            <Typography variant="body1">
              {city}
              {city && ','}
              {foreignStateProvinceRegion || (state !== 'XX' && state)} {zip}
            </Typography>
          )}
          <Typography variant="body1">{countries[country]}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            aria-label="Edit account owner information"
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit Details
          </Button>
          {user.roles?.includes(UserRole.orgSiteAdmin) && (
            <Button
              aria-label="Delete all accounts and activity for this account owner."
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                buildModalData();
              }}
              style={{ marginLeft: '10px' }}
            >
              Delete Owner
            </Button>
          )}
        </Grid>
        <SiraDeleteModal
          title={modalData.title}
          body={modalData.body}
          showPrompt={modalOpen}
          handleClose={handleClose}
          deleteTransaction={deleteAccountOwnerActivity}
        />
      </Grid>
    </>
  );
}

function AccountOwnerProfile() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage, organization, customerPortalUser } =
    useGlobalContext();
  const { accountOwnerId = '', accountId = '' } =
    useParams() as AccountOwnerPageParams;
  const [response, setResponse] = useState({} as AccountOwnerResponse);
  const { data: accountOwner = {} } = response;
  const {
    name = '',
    fullName = '',
    emailAddress = '',
    taxpayerIdNumber = '',
  } = accountOwner;
  const [isSyncing, setIsSyncing] = useState(false as boolean);
  const [showIcon, setShowIcon] = useState(false as boolean);
  const [symitarAccountId, setSymitarAccountId] = useState('' as string);

  const syncingAccountOwner = async () => {
    setIsSyncing(true);
    await syncAccountOwner(accountOwner, user.organizationId, user.token, user)
      .then((res) => {
        setIsSyncing(false);
        setSymitarAccountId(res.data.symitarAccountId);
      })
      .catch((err) => {
        setIsSyncing(false);
        addGlobalMessage(
          errorMessages(err) || 'Error syncing account owner information'
        );
      });
  };

  async function getSelectedAccountOwner(): Promise<void> {
    getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setResponse(res);
                  }
      })
      .catch((err) => {
        const { response: { data = {} } = {} } = err;
        if (isMounted) {
          setResponse({
            data: {},
            errorMessage: data.message,
          });
          addGlobalMessage('Error fetching account owner profile');
        }
      });
  }

  useEffect(() => {
    if (user.token && user.organizationId) {
      getSelectedAccountOwner();
            setShowIcon(
        organization.coreIntegration === 'SYMITAR' && !customerPortalUser
          ? true
          : false
      );
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Account Owner Profile
          </Typography>

          <Paper elevation={3}>
            <Box mt={2} p={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="overline">Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box position="relative" display="inline-flex">
                    <Typography
                      color="secondary"
                      variant="h3"
                      gutterBottom
                      pr={1}
                    >
                      {fullName}
                    </Typography>
                    {fullName && showIcon ? (
                      <Badge
                        badgeContent={
                          symitarAccountId ? (
                            <Tooltip title="Synced">
                              <CheckCircleIcon
                                style={{
                                  cursor: 'pointer',
                                  color: 'green',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <>
                              {isSyncing ? (
                                <Tooltip title="Syncing">
                                <CircularProgress
                                  sx={{
                                    width: '24px',
                                  }}
                                  color='info'
                                  size={18}
                                />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Sync Account">
                                <SyncOutlinedIcon
                                  className="spinning"
                                  style={{
                                    cursor: 'pointer',
                                    color: '24A0ED',
                                    paddingLeft: '5px',
                                  }}
                                  onClick={syncingAccountOwner}
                                />
                                </Tooltip>
                              )}
                              </>
                          )
                        }
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        overlap="circular"
                      />
                    ) : null}
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Email
                  </Typography>

                  <Link href={`mailto:${emailAddress}`}>
                    <Typography color="primary">{emailAddress}</Typography>
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    SSN/ID
                  </Typography>

                  <Typography variant="body1">
                    <ObscuredTaxId
                      taxId={taxpayerIdNumber}
                      format={name ? TaxIdFormat.ein : TaxIdFormat.ssn}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Accordion sx={{ p: 2 }} elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2">More Information</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <AccountOwnerAdditionalInfo
                  setResponse={setResponse}
                  accountOwner={response.data}
                />
              </AccordionDetails>
            </Accordion>
          </Paper>

          <AccountOwnerAccounts
            accountOwner={accountOwner}
            accountIdToSelect={accountId}
          />
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default AccountOwnerProfile;
