import React from 'react';
import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

export function SiraDeleteModal(props) {
  const { showPrompt, handleClose, title, body, deleteTransaction } = props;

  const containerStyle = {
    position: 'absolute' as any,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    maxWidth: 480,
    height: 'auto',
    maxHeight: '66%',
    bgcolor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 2,
    box: 42,
    p: 4,
    overflow: 'auto',
  };
  const closeButtonStyle = {
    position: 'fixed' as any,
    top: 0,
    right: 0,
  };
  const contentStyle = {
    height: '100%',
  };
  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 2,
  };

  return (
    <>
      <Modal
        open={showPrompt}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={showPrompt}>
          <Box sx={containerStyle}>
            <Box sx={closeButtonStyle}>
              <IconButton
                data-qa="delete-modal_close"
                color="primary"
                onClick={() => {
                  handleClose();
                }}
                aria-label="close modal"
                size="large"
              >
                <CancelIcon />
              </IconButton>
            </Box>
            <Box sx={contentStyle}>
              {title && (
                <>
                  <Typography variant="h6" component="h2">
                    {title}
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={contentStyle} pt={2}>
              <Typography variant="body1">{body}</Typography>
            </Box>
            <Box sx={buttonContentStyle}>
              <Box pr={2}>
                <Button
                  data-qa="delete-modal_cancel"
                  variant="outlined"
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  data-qa="delete-modal_confirm"
                  variant="outlined"
                  onClick={() => {
                    deleteTransaction();
                  }}
                  sx={{ whiteSpace: 'nowrap' }}
                  startIcon={<DeleteIcon />}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
