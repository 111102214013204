import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import {
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  LinearProgress,
  IconButton,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import NumberFormat from 'react-number-format';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import {
  getConstantActiveTaxYear,
  globalPaginationOptions,
} from '../../../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { useUser } from '../../../auth/useUser';

import Layout from '../../Layout';
import FormPaper from '../../FormPaper';
import { TaxFormType } from '../../../api/TaxFormApi.d';
import {
  createTaxForm1099Summary,
  getTaxForm1099StateFiles,
  getTaxForm1099Summary,
} from '../../../api/TaxFormApi';

import StateField from '../StateField';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import { useStates } from '../useStates';
import { Country } from '../../../api/StatesApi.d';
import {
  createFileBlobFromBase64,
  downloadAsFile,
} from '../../../utils/App.utils';
import { dateValueFormatter } from '../beneficiariesClaims/resource.utils';
import {  getStateTaxDoc } from '../../../api/OrganizationApi';
import { errorMessages } from '../../../utils/errorhandling.utils';


function StateTaxFiles1099() {
  const { user } = useUser();

  const activeTaxYear = getConstantActiveTaxYear();
  const { addGlobalMessage } = useGlobalContext();
  const { states = [] } = useStates();
  const [taxSummary, setTaxSummary] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTaxSummary, setLoadingTaxSummary] = useState<boolean>(false);
  const [downloadTaxFile, setDownloadTaxFile] = useState<boolean>(false);
  const selectedCountryFilter = ({ country }) => country === Country['en-US'];
  const { setGlobalPageSize } = usePaginationContext();
  const [taxStateFiles, setTaxStateFiles] = useState<Array<any>>([]);
  const taxState = states
    .filter(selectedCountryFilter)
    .map(({ name: stateName = '', abbreviation = '' }) => ({
      abbreviation,
      stateName,
    }));
const [loadStateTaxFiles, setLoadStateTaxFiles] = useState<boolean>(false);

  const formType: Array<SiraSelectItem> = [
    {
      value: TaxFormType.R,
      label: '1099-R',
    },
    {
      value: TaxFormType.Q,
      label: '1099-Q',
    },
    {
      value: TaxFormType.SA,
      label: '1099-SA',
    },
  ];

  const accountsToIncludeSelection: Array<SiraSelectItem> = [
    {
      value: 'SELECTED',
      label: 'Only withholdings in this state',
    },
    {
      value: 'ALL',
      label: 'All owners with addresses in this states',
    },
  ];

  const CreateStateTaxFile = async (values) => {
    setIsLoading(true);
    const excludeZeroWithholding = values.accountsToInclude === 'SELECTED';
    await createTaxForm1099Summary(
      activeTaxYear,
      values.state,
      excludeZeroWithholding,
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        setIsLoading(false);
        const { bytes: base64 = '' } = data;
        const blob = createFileBlobFromBase64(base64, 'text/txt') || data;

        downloadAsFile(blob, '1099-R State Tax File.txt');
      })
      .catch((err) => {
        setIsLoading(false);

        addGlobalMessage(errorMessages(err) || 'Error fetching State Tax Files');
      });
  };

  const fetchndSetTaxForm = async () => {
    await getTaxForm1099Summary(
      activeTaxYear,
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        setLoadingTaxSummary(false);
        // eslint-disable-next-line array-callback-return
        data.taxFormSummaries.map((item) => {
          if (item.taxFormType === 'R') {
            setTaxSummary(item.stateTaxesWithheld);
          }
        });
      })
      .catch((err) => {
        setLoadingTaxSummary(false);

 addGlobalMessage(errorMessages(err) || 'Error fetching Tax Form Summary'
        );
      });
  };

  const fetchndSetTaxFormStateFiles = async () => {
    await getTaxForm1099StateFiles(
      activeTaxYear,
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        setLoadStateTaxFiles(false);
        // add fileName from filepath to data
        const files = data.map((item, id) => {
          return {
            id,
            ...item,
          };
        });
        setTaxStateFiles(files);
      })
      .catch((err) => {

        setLoadStateTaxFiles(false);
 addGlobalMessage(errorMessages(err) || 'Error fetching Tax Form Summary'
        );
      });
  };

  async function downloadFile(row: any): Promise<void> {
    const nameValue = row.key.replace(/^.*[\\/]/, '');
    await getStateTaxDoc(
    row.key,
      user.organizationId,
      user.token
    )
      .then(({ data }) => {
        setDownloadTaxFile(false);
        const { bytes: base64 = '' } = data;
        const blob = createFileBlobFromBase64(base64, 'text/txt') || data;

        downloadAsFile(blob, nameValue);
      })
      .catch((err) => {

        setIsLoading(false);
        addGlobalMessage(errorMessages(err) || 'Could not fetch this document');
      });
  }

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 85,
      renderCell: (params: GridCellParams) => (
        <IconButton
          color="primary"
          size="small"
          data-testid={`open-contribution-pdf-${params.id}`}
          aria-label="Open latest pdf document"
          onClick={() => {
            setDownloadTaxFile(true)
            downloadFile(params.row);
          }}
          disabled={downloadTaxFile}
        >
          <FileDownloadIcon />
        </IconButton>
      ),
    },
    {
      field: 'key',
      headerName: 'File Name',
      width: 350,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        // grab filename from file path
        const fileName = row.key.split('/').pop();
        return (
          <Typography variant="body2" color="primary">
            {fileName}
          </Typography>
        );
      },
    },
    { field: 'state', headerName: 'State', width: 85 },
    {
      field: 'taxYear',
      headerName: 'Tax Year',
      width: 100,
    },
    {
      field: 'printableTaxFormType',
      headerName: 'Form Type',
      width: 150,
    },
    {
      field: 'lastModified',
      headerName: 'Created Date',
      width: 150,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const { lastModified = '' } = row;
        return dateValueFormatter(lastModified);
      },
    },
  ] as GridColDef[];

  useEffect(() => {
    if (user.organizationId) {
      setLoadingTaxSummary(true);
      fetchndSetTaxForm();
    }
  }, [user.organizationId, user.token]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography color="secondary" variant="h1">
            1099 R State Files
          </Typography>

          {loadingTaxSummary ? (
            <Grid item xs={12}>
              <Box width="1" mt={5} mb={3}>
                <LinearProgress color="secondary" />
              </Box>
            </Grid>
          ) : (
            <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {taxSummary && taxSummary.length > 0
                    ? `Your financial organization has ${activeTaxYear} 1099-R tax forms
                with state withholding in the following states:`
                    : `Your financial organization does not have any 1099-R tax forms with state withholding.`}
                </Typography>
              </Grid>
              {taxSummary ? (
                <Grid item xs={6}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'background.paper',
                      listStyleType: 'disc',
                    }}
                  >
                    {taxSummary.map((item) => {
                      // find an object key that matches the state abbreviation
                      const stateName = taxState.find(
                        (state) => state.abbreviation === item.state
                      );

                      return (
                        <>
                          {' '}
                          <ListItem key={item.state}>
                            <ListItemText primary={stateName.stateName} />
                          </ListItem>
                          <ListItem
                            sx={{ display: 'list-item', ml: 8 }}
                            key={item.state}
                          >
                            <ListItemText
                              primary={
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 'bold' }}
                                  >
                                    State Tax Amount
                                  </Typography>{' '}
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 'bold' }}
                                  >
                                    <NumberFormat
                                      value={item.stateTax}
                                      displayType="text"
                                      thousandSeparator
                                      prefix="$"
                                      decimalScale={2}
                                    />
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>
                        </>
                      );
                    })}
                  </List>
                </Grid>
              ) : null}
            </Grid>
          )}

          <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
            <Grid item xs={12}>
              <Formik
                // Datepicker is only going to accept date in taxYear but we translate it to an integer on submit
                initialValues={{ taxFormType: TaxFormType.R }}
                onSubmit={(values) => {
                  CreateStateTaxFile(values);
                }}
                validationSchema={yup.object({
                  state: yup.string().required().label('State'),
                  accountsToInclude: yup
                    .string()
                    .required()
                    .label('Accounts To Include'),
                })}
              >
                {() => {
                  return (
                    <Form>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        pt={1}
                        pb={2}
                      >
                        <Grid item xs={6}>
                          <SiraSelectField
                            items={formType}
                            name="taxFormType"
                            label="1099 Tax Form Type"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <StateField name="state" label="State" />
                        </Grid>
                        <Grid item xs={8}>
                          <SiraSelectField
                            items={accountsToIncludeSelection}
                            name="accountsToInclude"
                            label="Accounts to include"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Button
                            disabled={isLoading}
                            variant="contained"
                            color="secondary"
                            startIcon={<SearchOutlinedIcon />}
                            type="submit"
                          >
                            Create Tax File
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          {isLoading && (
                            <Box width="1" mt={5} mb={3}>
                              <LinearProgress color="secondary" />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                disableGutters
                elevation={2}
                             slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
                onChange={(event, expanded) => {
                  if (expanded) {
                    setLoadStateTaxFiles(true);
                    fetchndSetTaxFormStateFiles();
                  }
                }}
              >
                <AccordionSummary
                  sx={{ pt: 0, pb: 0 }}
                  expandIcon={<ExpandMoreIcon />} // Disguise non-errors as regular alerts
                >
                  <Typography variant="h6">1099-R State Tax Files</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DataGrid
                    rows={taxStateFiles}
                    columns={columns}
                    pagination
                    pageSizeOptions={globalPaginationOptions}
                    onPaginationModelChange={setGlobalPageSize}
                    autoHeight
                    loading={loadStateTaxFiles}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </>
      </FormPaper>
    </Layout>
  );
}

export default StateTaxFiles1099;
