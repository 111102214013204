import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';

import RMDNotificationIcon from '../icons/RMDNotification';
import ScheduledDistributionIcon from '../icons/ScheduledDistribution';
import WithholdingNotificationIcon from '../icons/WithholdingNotification';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import ActionCard from '../components/ActionCard';
import { Solution } from '../api/OrganizationApi.d';
import SvgScheduledDistributionFilter from '../icons/ScheduledDistributionFilter';

function RecurringDistributions() {
  return (
    <Layout>
      <PaperlessPage>
        <>
          <Grid container wrap="nowrap" justifyContent="space-between">
            <Grid item>
              <Typography variant="overline" gutterBottom>
                Recurring Distributions
              </Typography>
            </Grid>
            <Grid item>
              <Link
                to="/recurringDistributions/settings"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SettingsIcon />}
                >
                  Settings
                </Button>
              </Link>
            </Grid>
          </Grid>

          <Grid container spacing={3} wrap="wrap" mt={2}>
            <ActionCard
              dataQa="rmd-notification"
              iconImage={
                <ScheduledDistributionIcon
                  color="secondary"
                  style={{ fontSize: 42 }}
                />
              }
              buttonText="Scheduled Distributions"
              route="/recurringDistributions/scheduledDistributionList"
            />

            <ActionCard
              dataQa="rmd-notification"
              iconImage={
                <RMDNotificationIcon
                  color="secondary"
                  style={{ fontSize: 42 }}
                />
              }
              buttonText="RMD Notifications"
              route="/recurringDistributions/rmdNotification"
            />

            <ActionCard
              solutions={[Solution.black]}
              dataQa="rmd-notification"
              iconImage={
                <WithholdingNotificationIcon
                  color="secondary"
                  style={{ fontSize: 42 }}
                />
              }
              buttonText="Withholding Notifications"
              route="/recurringDistributions/withholdingNotifications"
            />

            <ActionCard
              dataQa="rmd-summary"
              iconImage={
                <BarChartIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="RMD Summary"
              route="/recurringDistributions/rmdSummary"
            />

            <ActionCard
              dataQa="rmd-schedules"
              iconImage={
                <SvgScheduledDistributionFilter
                  color="secondary"
                  style={{ fontSize: 42 }}
                />
              }
              buttonText="Recurring Distribution Schedules"
              route="/recurringDistributions/schedules"
            />
          </Grid>
        </>
      </PaperlessPage>
    </Layout>
  );
}
export default RecurringDistributions;
