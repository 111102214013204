import axios from 'axios';
import format from 'string-format';

import { prepareBinaryJson, removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  AccountStatusState,
  AccountStatusResponse,
  WorkflowDates,
  Account,
  AccountImportColumnsResponse,
  AccountType,
} from './AccountApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const accountsUrl = '/api/org/{0.orgId}/accounts';
const accountUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}';
const newAccountDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/document';
const accountStatusUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/status';
const accountsImportUrl = '/api/org/{0.orgId}/upload/accountImport';
const accountsImportColumsUrl =
  '/api/org/{0.orgId}/upload/accountImportColumns';
const accountsExportUrl = '/api/org/{0.orgId}/accountOwnerAccountExport';

function accountFactory(data: any): Account {
  const {
    accountId,
    accountNumber,
    accountOwnerId,
    accountStatus,
    accountType,
    openDate,
    closedDate,
    signedDate,
    financialOrganizationId,
    deceasedAccountId,
    externalAccountOwnerAccountId,
    eligibleDesignatedBeneficiary,
    responsibleFirstName,
    responsibleLastName,
    responsiblePhoneNumber,
    responsibleEmailAddress,
    responsibleAddressLine1,
    responsibleAddressLine2,
    responsibleCity,
    responsibleState,
    responsibleZip,
    responsibleCountry,
    responsibleForeignStateProvinceRegion,
    allowBeneficiaryChange,
    responsibleAfterMajority,
    dateOfDeathAccountBalance,
    fiveYearTest,
    federalWithholdingPercent,
    federalWithholdingPercentEffectiveDate,
    relationship,
    excludeTaxForm1099,
    excludeTaxForm5498,
    planNumber,
  } = data;

  return {
    accountId,
    accountNumber,
    accountType,
    accountStatus,
    openDate,
    closedDate,
    financialOrganizationId,
    signedDate,
    accountOwnerId,
    // Conditionally add this if the ID exists. Or make sure it's removed
    ...(deceasedAccountId && {
      deceasedAccount: { accountId: deceasedAccountId },
    }),
    externalAccountOwnerAccountId,
    // If inherited, pass in otherwise make an empty string so it gets stripped out
    eligibleDesignatedBeneficiary: [
      AccountType.inheritedRoth,
      AccountType.inheritedTraditional,
    ].includes(accountType)
      ? eligibleDesignatedBeneficiary
      : '',
    responsibleFirstName,
    responsibleLastName,
    responsiblePhoneNumber,
    responsibleEmailAddress,
    responsibleAddressLine1,
    responsibleAddressLine2,
    responsibleCity,
    responsibleState,
    responsibleForeignStateProvinceRegion,
    responsibleZip,
    responsibleCountry,
    allowBeneficiaryChange,
    responsibleAfterMajority,
    dateOfDeathAccountBalance,
    fiveYearTest,
    federalWithholdingPercent,
    federalWithholdingPercentEffectiveDate,
    relationship,
    excludeTaxForm1099,
    excludeTaxForm5498,
    planNumber,
  };
}

export async function createOrSaveAccount(
  data: Account,
  orgId: string,
  accountOwnerId: string,
  token: string,
  user: any
): Promise<AccountStatusResponse> {
  const { accountId = '' } = data;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
  })}`;

  let dataNorm = { ...data, financialOrganizationId: orgId } as any;
  dataNorm = accountFactory(dataNorm);
  dataNorm = removeEmptyStrings(dataNorm);

  if (accountId) {
    return axiosInstance.put(url, dataNorm, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccount(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any = {}
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrgAccounts(
  orgId: string,
  status: string | Array<string>,
  token: string
) {
  const url = `${urlBase}${format(accountsUrl, { orgId })}`;
  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user,
  versionID: string = ''
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${newAccountDocumentUrl}`, {
      orgId,
      accountId,
      accountOwnerId,
    })}`;
  

  const params = removeEmptyStrings({ versionID });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createAccountDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${newAccountDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
  })}`;

  return axiosInstance.post(url, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeAccountStatus(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  state: AccountStatusState,
  workflowDates: WorkflowDates = {},
  token: string,
  user: any,
  closedDate: string = ''
): Promise<AccountStatusResponse> {
  const params = { state, closedDate};

  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountStatusUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.put(url, workflowDates, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importAccounts(
  files: Array<File>,
  headerMappings: any,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(accountsImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();

  formData.append('file', files[0]);
  if (headerMappings) {
    formData.append('headerMappings', prepareBinaryJson(headerMappings));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importAccountsColumns(
  files: Array<File>,
  orgId: string,
  token: string
): Promise<AccountImportColumnsResponse> {
  const url = `${urlBase}${format(accountsImportColumsUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function exportAccounts(
  orgId: string,
  token: string,
  fileType: string
): Promise<any> {
  const url = `${urlBase}${format(accountsExportUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    params: {
      fileType,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}

export async function deleteAccount(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
