import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme, Box, Typography, Button } from '@mui/material';
import HttpsIconOutlined from '@mui/icons-material/HttpsOutlined';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import { UserRole } from '../../api/UserApi.d';
import { Organization } from '../../api/OrganizationApi.d';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const theme = useTheme();
  const navigate = useNavigate();
  const { organization = {} as Organization } = useGlobalContext();
  const { customerPortalUrl } = organization;
  const { user } = useUser();
  const [isCustomerPortalUser, setIsCustomerPortalUser] = useState(
    false as boolean
  );

  useEffect(() => {
    if (user.roles) {
      setIsCustomerPortalUser(user.roles.includes(UserRole.accountOwner));
    }
  }, [user.roles, user.organizationId]);

  const classes = {
    root: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
    },
    linkText: {
      color: theme.palette.primary.contrastText,
    },
    textByIcon: {
      marginLeft: theme.spacing(1),
    },
  };

  const clearLocalStorage = () => {
    logout({
      logoutParams: {
        returnTo: isCustomerPortalUser
          ? customerPortalUrl
          : window.location.origin,
      },
    });
    navigate('/login');
    sessionStorage.clear();
    // Clear all caches
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.map((name) => caches.delete(name));
      });
    }
  };

  return (
    <Box sx={classes.root}>
      <Button
        data-qa="logout-button"
        startIcon={<HttpsIconOutlined />}
        onClick={clearLocalStorage}
        sx={classes.linkText}
      >
        <Typography noWrap variant="caption" sx={classes.textByIcon}>
          Sign Out
        </Typography>
      </Button>
    </Box>
  );
};

export default LogoutButton;
