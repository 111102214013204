/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';
import {
  DocumentationAddress,
  EsignLandingPage,
  FiduciaryType,
  Organization,
  Solution,
  SpousalConsentOption,
} from '../../../api/OrganizationApi.d';

import { useUser } from '../../../auth/useUser';
import { UserRole } from '../../../api/UserApi.d';
import SiraCheckbox from '../SiraCheckbox';
import SiraSwitchField from '../SiraSwitchField';
import SiraRadioField from '../SiraRadioField';
import { eSignLandingPageOptions } from '../../../app.constants';

export const FINANCIAL_ORG_SETTINGS_INT: Organization = {
  federalTaxId: '',
  name: '',
  legalName: '',
  fiduciaryType: FiduciaryType.default,
  spousalConsentRequired: SpousalConsentOption.community,
  logoImageString: '',
  primaryColorRGB: '',
  solution: '' as Solution,
  agreementDate: new Date().toISOString().slice(0, 10),
  documentationAddress: DocumentationAddress.userBranch,
  eSign: false,
  isEsignPassthroughId: false,
  esignDefaultAppUserId: '',
  esignUserId: '',
  esignHostFiid: '',
  eSignLandingPage: '' as EsignLandingPage,
  eSignApiKey: '',
  eSignBaseUrl: '',
  immField: '',
  superiorField: '',
};

export const FINANCIAL_ORG_ESIGN_SETTINGS_SCHEMA = {
  eSign: yup.boolean(),
  eSignWithSuperior: yup.boolean(),
  isEsignPassthroughId: yup.boolean(),
  esignDefaultAppUserId: yup
    .string()
    .when(['isEsignPassthroughId', 'eSign'], (...args) => {
      const [isEsignPassthroughId, eSign, schema] = args as any;
      return !isEsignPassthroughId && eSign ? schema.required() : schema;
    })
    .label('Default App User ID'),
  esignUserId: yup
    .string()
    .when('eSign', (...args) => {
      const [eSign, schema] = args as any;
      return eSign ? schema.required() : schema;
    })
    .label('Business App User ID'),
  esignHostFiid: yup
    .string()
    .when('eSign', (...args) => {
      const [eSign, schema] = args as any;
      return eSign ? schema.required() : schema;
    })
    .label('Host Financial Institution ID'),
  eSignLandingPage: yup.string(),
  eSignApiKey: yup
    .string()
    .when('eSign', (...args) => {
      const [eSign, schema] = args as any;
      return eSign ? schema.required() : schema;
    })
    .label('API Key'),
  eSignBaseUrl: yup
    .string()
    .when('eSign', (...args) => {
      const [eSign, schema] = args as any;
      return eSign ? schema.required() : schema;
    })
    .label('eSign Base URL'),
};

export interface FinancialOrgEsignSettingsFormProps {
  initialValues: Organization;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}

const FinancialOrgEsignSettingsForm = ({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
}: FinancialOrgEsignSettingsFormProps) => {
  const { user } = useUser();
  const allowSolutionChange = user.roles && user.roles.includes(UserRole.admin);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      validationSchema={yup.object(FINANCIAL_ORG_ESIGN_SETTINGS_SCHEMA)}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue }) => {
        const { eSignPassthroughId, eSign } = values as Organization;

        useEffect(() => {
          if (eSignPassthroughId) {
            setFieldValue('esignDefaultAppUserId', '');
          }
        }, [eSignPassthroughId]);

        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  disabled={!allowSolutionChange}
                  name="eSign"
                  type="checkbox"
                  label="Turn on Esign"
                  component={SiraCheckbox}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  disabled={!allowSolutionChange}
                  name="eSignWithSuperior"
                  type="checkbox"
                  label="Turn on Superior Esign"
                  component={SiraCheckbox}
                />
              </Grid>
              {eSign && (
                <>
                  <Grid item xs={12}>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Typography>Static User ID</Typography>
                      </Grid>
                      <Grid item>
                        <Box>
                          <SiraSwitchField
                            name="eSignPassthroughId"
                            checked={eSignPassthroughId}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography>Superior User Info</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={4} mb={3}>
                    <Field
                      label="eSign Landing Page"
                      name="eSignLandingPage"
                      options={eSignLandingPageOptions}
                      component={SiraRadioField}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SiraTextField name="eSignBaseUrl" label="eSign Base Url" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SiraTextField
                      name="eSignApiKey"
                      label="eSign API Key"
                      type="password"
                    />
                  </Grid>
                  {!eSignPassthroughId && (
                    <Grid item xs={12}>
                      <SiraTextField
                        name="esignDefaultAppUserId"
                        label="Esign Default App User ID"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SiraTextField
                      name="esignUserId"
                      label="Business App User ID"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SiraTextField
                      name="esignHostFiid"
                      label="Host Financial Institution ID"
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FinancialOrgEsignSettingsForm;
