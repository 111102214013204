import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Grid, Box, Paper, Typography } from '@mui/material';
import { darken, useTheme } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';

import { useFormikContext } from 'formik';
import { Distribution } from '../../../api/DistributionApi.d';
import ScheduledMonths from './ScheduledMonths';
import { DistributionAmountFormData } from './DistributionAmountForm.d';
import { DistributionChoices } from './DistributionAmountRecurringSubForm';

interface DistributionScheduleProps {
  distribution?: Distribution;
  taxAmounts?: Distribution;
  frequency?: string;
  effectiveDate?: string;
}

export const FREQUENCY_TEXT = {
  ANNUAL: 'Annually',
  SEMI_ANNUAL: 'Semi-Annually',
  QUARTERLY: 'Quarterly',
  MONTHLY: 'Monthly',
};
export const FREQUENCY_DIVISOR = {
  ANNUAL: 1,
  SEMI_ANNUAL: 2,
  QUARTERLY: 4,
  MONTHLY: 12,
};

export const PaymentElectionLable = {
    rmd: {
      label: 'Required minimum distribution (RMD)',
      value: DistributionChoices.rmd,
    },
    amortization: {
      label: 'Fixed amortization amount',
      value: DistributionChoices.amortization,
    },
    annuitization: {
      label: 'Fixed annuitization amount',
      value: DistributionChoices.annuitization,
    },
 amount:{label: 'Specific amount', value: DistributionChoices.amount }
}
;

function DistributionSchedule({
  distribution,
  frequency,
  effectiveDate,
  taxAmounts,
}: DistributionScheduleProps) {
  const [eachDistributionAmount, setEachDistributionAmount] = useState('0');
  const theme = useTheme();
  const { values } = useFormikContext();
  const { totalAmount, federalWithholdingPercent, stateWithholdingPercent } =
    distribution;
  const chartColors = [
    theme.palette.secondary.light,
    darken(theme.palette.secondary.light, 0.3),
    darken(theme.palette.secondary.dark, 0.6),
    theme.palette.grey[500],
  ];
  const { recurringSelection } = values as DistributionAmountFormData;
  const classes = {
    summaryCard: {
      padding: theme.spacing(2),
    },
    tLeft: {
      borderRight: '0.1px solid lightgray',
      borderTop: '0.1px solid lightgray',
    },
    tRight: {
      borderTop: '0.1px solid lightgray',
    },
  };
  useEffect(() => {
    if (frequency && totalAmount) {
      const amount = Number(totalAmount) / FREQUENCY_DIVISOR[frequency];
      setEachDistributionAmount(amount.toFixed(2));
    }
  }, [totalAmount, frequency]);

  const scheduleRmdorAmount = () => {
    let selection = '';

   switch (recurringSelection) {
     case DistributionChoices.amortization:
       selection = PaymentElectionLable.amortization.label;
       break;
     case DistributionChoices.annuitization:
       selection = PaymentElectionLable.annuitization.label;
       break;
     case DistributionChoices.amount:
       selection = PaymentElectionLable.amount.label;
       break;
     case DistributionChoices.rmd:
       selection = PaymentElectionLable.rmd.label;
       break;
     default:
       break;
   }

    return selection;
  };

  return (
    <Box width={256} p={2}>
      <Paper elevation={5} sx={classes.summaryCard}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Distribution Schedule
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Annual Distribution Amount (gross)
            </Typography>
            <Typography gutterBottom>
              <NumberFormat
                value={totalAmount}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box width="1" pt={2} pb={2}>
              <Typography variant="body2">
                Each Distribution Amount (gross)
              </Typography>
              <Typography gutterBottom>
                <NumberFormat
                  value={eachDistributionAmount}
                  prefix="$"
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Typography>
            </Box>
          </Grid>
          {recurringSelection && (
            <Grid item xs={12}>
              <Box width="1" pb={2}>
                <Typography variant="body2">Payment Election</Typography>
                <Typography variant="body2" gutterBottom>
                  {scheduleRmdorAmount()}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={6}>
            <Box pt={1} mt={1} sx={classes.tLeft}>
              <Typography variant="body2">Start Date</Typography>
              <Typography gutterBottom>
                {effectiveDate &&
                  format(parseISO(String(effectiveDate)), 'MM/dd/yyyy')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box pt={1} pl={1} mt={1} sx={classes.tRight}>
              <Typography variant="body2">Frequency</Typography>
              <Typography gutterBottom>{FREQUENCY_TEXT[frequency]}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box width="1" mt={1}>
              <ScheduledMonths
                frequency={frequency}
                effectiveDate={effectiveDate}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Annual Withholding Elections</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box width="1" pb={2}>
              <Typography variant="body2">Federal Tax</Typography>
              <Typography variant="h5">
                <NumberFormat
                  value={federalWithholdingPercent}
                  suffix="%"
                  displayType="text"
                  isNumericString
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box width="1" pb={2}>
              <Typography variant="body2">State Tax</Typography>
              <Typography variant="h5">
                <NumberFormat
                  value={stateWithholdingPercent}
                  suffix="%"
                  displayType="text"
                  isNumericString
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <Box
                component="span"
                mr={1}
                display="inline-block"
                width={10}
                height={10}
                bgcolor={chartColors[1]}
              />
              Federal
            </Typography>
            <Typography gutterBottom>
              <NumberFormat
                value={taxAmounts?.federalWithholdingAmount}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <Box
                component="span"
                mr={1}
                display="inline-block"
                width={10}
                height={10}
                bgcolor={chartColors[2]}
              />
              State
            </Typography>
            <Typography gutterBottom>
              <NumberFormat
                value={taxAmounts?.stateWithholdingAmount}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default DistributionSchedule;
