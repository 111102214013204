import React from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { AccountContribution } from '../../../api/ContributionApi.d';
import { AccountType } from '../../../api/AccountApi.d';
import SiraCurrencyField from '../SiraCurrencyField';
import ContributionLimit from '../../ContributionLimit';

export interface DepositAmountFormProps {
  amount?: number;
  accountType: AccountType;
  accountOwnerId: string;
}

function DepositAmountForm({
  amount,
  accountType,
  accountOwnerId,
}: DepositAmountFormProps) {
  const { values } = useFormikContext<AccountContribution>();
  
  return (
    <>
      <Grid container spacing={3} sx={{ pt: 2 }}>
        <Grid item xs={12} sm={5}>
          <SiraCurrencyField
            allowNegative={false}
            name="amount"
            label="Amount"
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <ContributionLimit
            amount={amount}
            taxYear={values.taxYear}
            accountType={accountType}
            accountOwnerId={accountOwnerId}
            depositType={values.depositType}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DepositAmountForm;
