import React from 'react';
import { Button, LinearProgress, Box } from '@mui/material';

interface StepButtonBarProps {
  resetName?: string;
  submitName?: string;
  onCancel?: Function;
  isSubmitting: boolean;
}

const StepButtonBar = (props: StepButtonBarProps) => {
  const { resetName, submitName, isSubmitting = false, onCancel,} = props;

  const classes = {
    button: {
      mr: 2,
    },
  };

  return (
    <>
      <Box pt={3}>
        {/* Form Submit */}
        <Button
          type="submit"
          disabled={isSubmitting}
          variant="contained"
          color="primary"
          sx={classes.button}
          data-qa="step-submit-button"
        >
          {!isSubmitting && submitName ? submitName : 'Continue'}
        </Button>

        {/* Form Reset - TODO: Removing temporarily to see if we still want it. If not, clean up the form reset, resetName, props, and references to reset, etc. */}
        {/* <Button
          type="reset"
          disabled={isSubmitting}
          variant="outlined"
          sx={classes.button}
          data-qa="step-reset-button"
        >
          {resetName || 'Reset'}
        </Button> */}

        {/* Form Cancel */}

        {onCancel && (
          <Button
            disabled={isSubmitting}
            variant="outlined"
            sx={classes.button}
            data-qa="step-cancel-button"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        )}
      </Box>

      {/* Loading... */}
      {isSubmitting && (
        <Box width="1" mt={2}>
          <LinearProgress color="secondary" />
        </Box>
      )}
    </>
  );
};

export default StepButtonBar;
