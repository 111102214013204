import React from 'react';
import { Grid, Typography } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsightsIcon from '@mui/icons-material/Insights';
import TransactionReview from '../icons/TransactionReview';

import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import ActionCard from '../components/ActionCard';
import AddMoneyIcon from '../icons/AddMoney';
import RemoveMoneyIcon from '../icons/RemoveMoney';

function Reports() {
  return (
    <Layout>
      <PaperlessPage>
        <>
          <Grid container wrap="nowrap" justifyContent="space-between">
            <Grid item>
              <Typography variant="overline" gutterBottom>
                Reports and Statistics
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} wrap="wrap" mt={2}>
            <ActionCard
              dataQa="accounts-summary-report-link"
              iconImage={
                <BarChartIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="Accounts Summary"
              route="/reports/accountsSummary"
            />
            <ActionCard
              dataQa="distributions-report-link"
              iconImage={
                <RemoveMoneyIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="Distributions"
              route="/reports/distributions"
            />
            <ActionCard
              dataQa="deposits-report-link"
              iconImage={
                <AddMoneyIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="Deposits"
              route="/reports/deposits"
            />
            <ActionCard
              dataQa="fmv-report-link"
              iconImage={
                <AttachMoneyIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="Fair Market Values"
              route="/reports/fmv"
            />{' '}
            <ActionCard
              dataQa="audit-report-link"
              iconImage={
                <InsightsIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="Audit Reports"
              route="/reports/auditReports"
            />
            <ActionCard
              dataQa="pending-report-link"
              iconImage={
                <TransactionReview color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="Pending Transactions"
              route="/reports/pendingTransactions"
            />
          </Grid>
        </>
      </PaperlessPage>
    </Layout>
  );
}
export default Reports;
