import useSWR from "swr";

import { useUser } from '../../auth/useUser';
import { getStatesInfo } from '../../api/StatesApi';

export function useStates() {
  const { user } = useUser();
  const { data: statesData, error: statesError } = useSWR([user.token], getStatesInfo);
  const {
    data: states = [],
  } = statesData || {};

  return {
    states,
    isError: statesError,
  }
}