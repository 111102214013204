import { Dispatch, useReducer } from 'react';
import {
  BeneficiaryVersionsResponse,
  Beneficiary,
  BeneficiaryStatus,
} from '../../api/BeneficiariesApi.d';

export enum BeneficiaryFormActionTypes {
  setBeneficiariesRes = 'UPDATE_BENEFICIARIES_RES',
  updateBeneficiariesStatus = 'UPDATE_BENEFICIARIES_STATUS',
  updatePrimaryBeneficiaries = 'UPDATE_PRIMARY_BENEFICIARIES',
  updateSecondaryBeneficiaries = 'UPDATE_SECONDARY_BENEFICIARIES',
  addOrReplaceBeneficiary = 'ADD_REPLACE_BENEFICIARY',
  editBeneficiary = 'EDIT_BENEFICIARY',
  changeAddType = 'UPDATE_ADD_TYPE',
  setBeneficiaryToEdit = 'SET_EDIT_BENEFICIARY',
  cancelBeneficiaryForm = 'CANCEL_BENEFICIARY_FORM',
}

export enum AddBeneficiaryType {
  primary = 'PRIMARY',
  secondary = 'SECONDARY',
}

export interface BeneficiaryFormAction {
  type: BeneficiaryFormActionTypes | AddBeneficiaryType;
  payload?: BeneficiariesPayload;
}

export interface BeneficiariesPayload {
  response?: BeneficiaryVersionsResponse;
  primaryBeneficiaries?: Array<Beneficiary>;
  secondaryBeneficiaries?: Array<Beneficiary>;
  beneficiary?: Beneficiary;
  beneficiaryStatus?: BeneficiaryStatus;
  addType?: AddBeneficiaryType;
  errorMessage?: string;
}

export interface BeneficiariesState {
  beneficiariesRes: BeneficiaryVersionsResponse;
  addType: AddBeneficiaryType;
  showForm: boolean;
  beneficiaryToEdit?: Beneficiary;
}

export const BENEFICIARIES_INIT_STATE: BeneficiariesState = {
  beneficiariesRes: {
    data: [],
  },
  addType: AddBeneficiaryType.primary,
  showForm: false,
};

let dispatch: Dispatch<BeneficiaryFormAction> = () => {};

/* THE REDUCER */
export function beneficiariesReducer(prev, action) {
  const { type, payload } = action;
  const newState = { ...prev };
  const { data: beneficiaryVersions = [] } = prev.beneficiariesRes;
  const [activeBeneficiaryVersion = {}] = beneficiaryVersions;
  const { beneficiaryToEdit } = prev;
  const { primaryBeneficiaries = [], secondaryBeneficiaries = [] } =
    activeBeneficiaryVersion;

  switch (type) {
    // Set the response containing all beneficiary versions
    case BeneficiaryFormActionTypes.setBeneficiariesRes:
      newState.beneficiariesRes = payload.response;
      break;

    // Update the latest set's status
    case BeneficiaryFormActionTypes.updateBeneficiariesStatus:
      newState.beneficiariesRes.data[0] = {
        ...activeBeneficiaryVersion,
        beneficiaryStatus: payload.beneficiaryStatus,
      };
      break;

    // Set the primary beneficiaries in the latest set
    case BeneficiaryFormActionTypes.updatePrimaryBeneficiaries:
      newState.beneficiariesRes.data[0] = {
        ...activeBeneficiaryVersion,
        primaryBeneficiaries: payload.primaryBeneficiaries,
      };
      break;

    // Set the secondary beneficiaries in the latest set
    case BeneficiaryFormActionTypes.updateSecondaryBeneficiaries:
      newState.beneficiariesRes.data[0] = {
        ...activeBeneficiaryVersion,
        secondaryBeneficiaries: payload.secondaryBeneficiaries,
      };
      break;

    // Add or replace a beneficiary based on whether edit is flagged and which addType is set
    case BeneficiaryFormActionTypes.addOrReplaceBeneficiary:
      // Replace based on addType if editing
      if (beneficiaryToEdit) {
        if (prev.addType === AddBeneficiaryType.primary) {
          primaryBeneficiaries.splice(beneficiaryToEdit.id, 1);
        } else if (prev.addType === AddBeneficiaryType.secondary) {
          secondaryBeneficiaries.splice(beneficiaryToEdit.id, 1);
        }
      }

      // Add primary
      if (prev.addType === AddBeneficiaryType.primary) {
        newState.beneficiariesRes.data[0] = {
          ...activeBeneficiaryVersion,
          primaryBeneficiaries: [
            ...primaryBeneficiaries,
            { ...payload.beneficiary },
          ],
        };
      }

      // Add secondary
      if (prev.addType === AddBeneficiaryType.secondary) {
        newState.beneficiariesRes.data[0] = {
          ...activeBeneficiaryVersion,
          secondaryBeneficiaries: [
            ...secondaryBeneficiaries,
            { ...payload.beneficiary },
          ],
        };
      }

      newState.showForm = false;
      newState.beneficiaryToEdit = undefined;
      break;

    // Set the type of beneficiary to add/replace
    case BeneficiaryFormActionTypes.changeAddType:
      newState.addType = payload.addType;
      newState.showForm = true;
      break;

    // Set which beneficiary is currenty being edited
    case BeneficiaryFormActionTypes.setBeneficiaryToEdit:
      newState.beneficiaryToEdit = payload.beneficiary;
      newState.showForm = true;
      break;

    // just a simple cancel helper to get out of an edit/add state
    case BeneficiaryFormActionTypes.cancelBeneficiaryForm:
      newState.showForm = false;
      newState.beneficiaryToEdit = undefined;
      break;

    default:
      return prev;
  }

  return newState;
}

/* THE HOOK */
export const useBeneficiariesReducer = () => {
  const [pageState, setPageState] = useReducer(
    beneficiariesReducer,
    BENEFICIARIES_INIT_STATE
  );

  dispatch = setPageState;

  // Passes through the same API as useReducer
  return [pageState, setPageState];
};

/* THE ACTION CONSTRUCTORS */
export const setBeneficiariesResponse = (
  response: BeneficiaryVersionsResponse
): void => {
  dispatch({
    type: BeneficiaryFormActionTypes.setBeneficiariesRes,
    payload: { response },
  } as BeneficiaryFormAction);
};

export const updateLatestBeneficiariesStatus = (
  beneficiaryStatus: BeneficiaryStatus
): void => {
  dispatch({
    type: BeneficiaryFormActionTypes.updateBeneficiariesStatus,
    payload: { beneficiaryStatus },
  } as BeneficiaryFormAction);
};

export const setPrimaryBeneficiaries = (
  primaryBeneficiaries: Array<Beneficiary>
): void => {
  dispatch({
    type: BeneficiaryFormActionTypes.updatePrimaryBeneficiaries,
    payload: { primaryBeneficiaries },
  } as BeneficiaryFormAction);
};

export const setSecondaryBeneficiaries = (
  secondaryBeneficiaries: Array<Beneficiary>
): void => {
  dispatch({
    type: BeneficiaryFormActionTypes.updateSecondaryBeneficiaries,
    payload: { secondaryBeneficiaries },
  } as BeneficiaryFormAction);
};

export const addOrReplaceBeneficiary = (beneficiary: Beneficiary): void => {
  dispatch({
    type: BeneficiaryFormActionTypes.addOrReplaceBeneficiary,
    payload: { beneficiary },
  } as BeneficiaryFormAction);
};

export const changeBeneficiaryAddType = (addType: AddBeneficiaryType): void => {
  dispatch({
    type: BeneficiaryFormActionTypes.changeAddType,
    payload: { addType },
  } as BeneficiaryFormAction);
};

export const setBeneficiaryToEdit = (beneficiary: Beneficiary): void => {
  dispatch({
    type: BeneficiaryFormActionTypes.setBeneficiaryToEdit,
    payload: { beneficiary },
  } as BeneficiaryFormAction);
};

export const cancelBeneficiaryForm = (): void => {
  dispatch({
    type: BeneficiaryFormActionTypes.cancelBeneficiaryForm,
  } as BeneficiaryFormAction);
};
