import * as React from "react";

function SvgSuperiorLoader(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.62 139.31"
      {...props}
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 0 0"
        to="360 0 0"
        dur="2s"
        additive="sum"
        repeatCount="indefinite"
      />
      <path
        d="M72.71 60.62a11.76 11.76 0 004.46 12l26.56 18.42a40.66 40.66 0 01-46.21 23.72l-1.42-.35a40.39 40.39 0 01-21-13.38 11.56 11.56 0 00-6.43-3.89c-10.91-2.44-18.83 10.32-11.71 18.94a64.19 64.19 0 00112-26.92l1.67-7.45-39.41-28a11.77 11.77 0 00-18.51 6.87"
        fill="#64c195"
      />
      <path
        d="M55.67 66.9L26.9 48.25a40.63 40.63 0 0168.6-10.07 12 12 0 006.64 4c10.86 2.43 18.72-10.25 11.66-18.87A64.18 64.18 0 001.67 50.12L0 57.58l41.62 28.21A11.771 11.771 0 0055.67 66.9"
        fill="#0b1f2a"
      />
    </svg>
  );
}

export default SvgSuperiorLoader;
