import axios from 'axios';
import format from 'string-format';
import { removeEmptyStrings } from './api.utils';
import { TaxDocument, TaxDocumentResponse } from './TaxDocument';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;

const taxDocument = '/api/org/{0.orgId}/taxDocument/status/{0.taxYear}';
const updateTaxDocumentUrl =
  '/api/org/{0.orgId}/taxDocument/status/';
const exportDistribution = '/api/org/{0.orgId}/distributionListExport';
const contributionExportUrl = '/api/org/{0.orgId}/contributionListExport';


  export async function getTaxDocumnentStatus(
    taxYear: number,
    orgId: string,
    token: string
  ): Promise<TaxDocumentResponse> {
    const url = `${urlBase}${format(taxDocument, {
      orgId,
taxYear
    })}`;

    return axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }


  
  export async function updateTaxDocumnentStatus(
    data: TaxDocument,
    orgId: string,
    token: string
  ): Promise<TaxDocumentResponse> {
    const url = `${urlBase}${format(updateTaxDocumentUrl, {
      orgId,
    })}`;

    return axiosInstance.put(url, removeEmptyStrings(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  export async function exportDistributions(
    orgId: string,
    token: string,
    fileType:string,
    startDate:string,
    endDate:string,
    taxYears?: Array<string>,
    accountTypes?:Array<string>,
  ): Promise<any> {
    const url = `${urlBase}${format(exportDistribution, {
      orgId,
    })}`;

    return axiosInstance.get(url, {
      params: {
        fileType,
        startDate,
        endDate,
        taxYears,
        accountTypes,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
  }


    export async function exportDeposits(
      orgId: string,
      token: string,
      fileType: string,
      accountTypes?:Array<string>,
      taxYears?: Array<string>,
      reportable?: boolean,
      startDate?: string,
      endDate?: string
    ): Promise<any> {
      const url = `${urlBase}${format(contributionExportUrl, {
        orgId,
      })}`;

      return axiosInstance.get(url, {
        params: {
          fileType,
          startDate,
          endDate,
          accountTypes,
          taxYears,
          reportable,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });
    }

