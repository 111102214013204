/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  Tooltip,
  Typography,
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

import * as yup from 'yup';

import { endOfYear, format, startOfYear } from 'date-fns';
import { Form, Formik } from 'formik';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { useGlobalContext } from '../auth/useGlobalContext';
import { useUser } from '../auth/useUser';
import {
  exportDistributions,
  getTaxDocumnentStatus,
  updateTaxDocumnentStatus,
} from '../api/TaxDocumentApi';
import { TaxDocument, TaxFormType } from '../api/TaxDocument.d';
import TaxReportingCheckListProgress from './TaxReportingCheckListProgress';
import SiraModal from '../components/SiraModal';
import MultiButton, { MultiButtonAction } from '../components/MultiButton';
import SiraSelectField, {
  SiraSelectItem,
} from '../components/form/SiraSelectField';
import SiraSwitchField from '../components/form/SiraSwitchField';
import { errorMessages } from '../utils/errorhandling.utils';
import { getConstantActiveTaxYear } from '../app.constants';

// irs use tax reporting
// envelope for sent to owner
// create pdf
// change to icons instead of checkbox and change color
enum PrintableTaxForm {
  default = '',
  TAX_FORM_1099R = 'TAX_FORM_1099R',
  TAX_FORM_1099SA = 'TAX_FORM_1099SA',
  TAX_FORM_1099Q = 'TAX_FORM_1099Q',
}

export const taxFormDescriptions = {
  [PrintableTaxForm.default]: '',
  [PrintableTaxForm.TAX_FORM_1099Q]: 'ESA',
  [PrintableTaxForm.TAX_FORM_1099SA]: 'HSA',
  [PrintableTaxForm.TAX_FORM_1099R]: 'IRA',
};

export const printableTaxFormDescriptions = {
  [PrintableTaxForm.default]: '',
  [PrintableTaxForm.TAX_FORM_1099Q]: PrintableTaxForm.TAX_FORM_1099Q,
  [PrintableTaxForm.TAX_FORM_1099SA]: PrintableTaxForm.TAX_FORM_1099SA,
  [PrintableTaxForm.TAX_FORM_1099R]: PrintableTaxForm.TAX_FORM_1099R,
};

interface SecondaryActionButtonsProps {
  formtype?: TaxFormType;
  documentStatus: TaxDocument;
  buttonstring: string;
  triggerReadyToPrint: Function;
  checkButtonClicked: Function;
  accountType?: string;
}

interface TaxReportingSelectionProps {
  initialValues: { taxFormType: PrintableTaxForm; switchValue: boolean };
  onSubmit?: Function;
  validTaxTypes: Array<TaxDocument>;
}

export interface ActiveSteps {
  readyToPrint: boolean;
  sentToOwner: boolean;
  sentToIrs: boolean;
  correctionsReadyToPrint: boolean;
  correctionsSentToOwner: boolean;
  correctionsSentToIrs: boolean;
}

type SiraListItem = ListItemTextProps &
  ListItemProps & {
    isroot?: boolean;
    group: number;
    indent?: number;
    formtype?: TaxFormType;
    buttonstring?: string;
    url?: string;
    // Number of levels to indent a list item
  };

export function SecondaryActionButtons(props: SecondaryActionButtonsProps) {
  const {
    formtype,
    documentStatus,
    buttonstring,
    triggerReadyToPrint,
    checkButtonClicked,
    accountType,
  } = props;

  const { addGlobalMessage, activeTaxYear } = useGlobalContext();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  const exportOrgDistributions = async (fileType): Promise<void> => {
    const taxYear = activeTaxYear;
    const startDate = format(
      startOfYear(new Date(taxYear, 0, 1)),
      'yyyy-MM-dd'
    );
    const endDate = format(endOfYear(new Date(taxYear, 0, 1)), 'yyyy-MM-dd');
    const formType = taxFormDescriptions[accountType];
    let type = [];

    if (formType === 'IRA'){
      type=['TRADITIONAL_IRA', 'ROTH_IRA', 'SEP_IRA', 'SIMPLE_IRA', 'INHERITED_TRADITIONAL_IRA', 'INHERITED_ROTH_IRA']
    }

    if (formType === 'HSA'){
      type=['HEALTH_SAVINGS_ACCOUNT']
    }

    if (formType === 'ESA'){
      type=['COVERDELL_ESA']
    }

    await exportDistributions(
      user.organizationId,
      user.token,
      fileType,
      startDate,
      endDate,
      [taxYear],
      type
    )
      .then(() => {
        setLoading(false);
        addGlobalMessage(
          'Your file will be available shortly under Notifications, located at the top of the page.',
          {
            severity: 'success',
          }
        );
      })
      .catch(() => {
        setLoading(false);
        addGlobalMessage('Error exporting organization distributions');
      });
  };

  // Define the download types
  const downloadActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => {
        setLoading(true);
        exportOrgDistributions('tsv');
      },
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => {
        setLoading(true);
        exportOrgDistributions('xlsx');
      },
      icon: <DownloadIcon />,
    },
  ];

  if (buttonstring && formtype && documentStatus) {
    return !documentStatus.readyToPrint ? (
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          triggerReadyToPrint(documentStatus);
        }}
      >
        {buttonstring}
      </Button>
    ) : (
      <></>
    );
  }

  if (buttonstring === 'Export Distributions') {
    return (
      <MultiButton
        actions={downloadActions}
        sx={{ whiteSpace: 'nowrap' }}
        disabled={loading}
        variant="contained"
        color="primary"
        startIcon={<DownloadIcon />}
        endIcon={<ExpandMoreIcon />}
      >
        {buttonstring}
      </MultiButton>
    );
  }

  return buttonstring ? (
    <Button
      variant="contained"
      size="small"
      onClick={() => {
        checkButtonClicked(buttonstring);
      }}
    >
      {buttonstring}
    </Button>
  ) : (
    <></>
  );
}

export function TaxReportingSelection({
  initialValues,
  onSubmit,
  validTaxTypes,
}: TaxReportingSelectionProps) {
  const helpText =
    'Select Spreadsheet if you plan to pull distribution spreadsheets from the Superior platform to review the current data listed in the site and make updates accordingly. Select Tax File if you plan to upload an IRS 750 formatted file to the platform for the 1099 series reporting.';
  const [taxFormTypeDescriptions, settaxFormTypeDescriptions] = useState(
    [] as Array<SiraSelectItem>
  );
  const TYPESELECTION_SCHEMA = yup.object().shape({
    taxFormType: yup.string().required().label('Tax Form Type'),
  });
  const classes = {
    button: {
      mr: 2,
    },
  };

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (validTaxTypes.length > 0) {
      const siraSelectItems = validTaxTypes.map((taxType) => {
        return {
          value: taxType.taxFormType,
          label: taxFormDescriptions[taxType.taxFormType],
        };
      });

      settaxFormTypeDescriptions(siraSelectItems);
    }
  }, [validTaxTypes]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      onSubmit={(values) => {
        onSubmit(values);
        setDisabled(true);
      }}
      validationSchema={TYPESELECTION_SCHEMA}
    >
      {({ values }) => {
        const { switchValue } = values;

        useEffect(() => {
          setDisabled(false);
        }, [values.taxFormType]);

        return (
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', pt: 2, pb: 2 }}
                  gutterBottom
                >
                  Select an account type to review
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SiraSelectField
                  items={taxFormTypeDescriptions}
                  name="taxFormType"
                  label="1099 Tax Form Type"
                />
              </Grid>
              <Grid container>
                <Grid item pt={2}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', pr: 2, pt: 2, pb: 2 }}
                  >
                    Spreadsheet (Excel)
                  </Typography>
                </Grid>
                <Grid item pt={2}>
                  <Tooltip
                    title={
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', p: 2 }}
                      >
                        {helpText}
                      </Typography>
                    }
                    disableFocusListener
                    disableTouchListener
                    aria-describedby="five-Year-Test-description"
                  >
                    <Box p={1}>
                      <SiraSwitchField
                        name="switchValue"
                        checked={switchValue}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item pt={2}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', p: 2 }}>
                    Tax File (IRS 750 Format)
                  </Typography>
                </Grid>
                <Grid item ml={4}>
                  <Box pt={3}>
                    <Button
                      type="submit"
                      disabled={disabled}
                      variant="contained"
                      color="primary"
                      sx={classes.button}
                      data-qa="step-submit-button"
                      onClick={() => {
                        onSubmit(values);
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

function TaxReportingChecklist() {
  // Could send this array to an API or session storage when saving progress
  // Just fetch it and pass it into the default arg
  let isMounted = true;

  const [taxDocumentStatus, settaxDocumentStatus] = useState(
    [] as Array<TaxDocument>
  );
  const { width, height } = useWindowSize();

  const [selectedDocumentStatus, setselectedDocumentStatus] = useState(
    {} as TaxDocument
  );
  const { addGlobalMessage, activeTaxYear } = useGlobalContext();
  const { user } = useUser();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const currentYear = useMemo(() => activeTaxYear, [activeTaxYear]);
  // setting this to be used for calling document status
  const [reviewType, setReviewType] = useState( 
    PrintableTaxForm.default as PrintableTaxForm
  );
  const [validTaxTypes, setValidTaxTypes] = useState([] as Array<any>);

  const [fileFormat, setFileFormat] = useState(false as boolean);

  const contentStyle = {
    height: '100%',
  };

  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 2,
  };

  useEffect(() => {
    const validTaxType = taxDocumentStatus.filter((taxDocument) =>
      [
        TaxFormType.TAX_FORM_1099R,
        TaxFormType.TAX_FORM_1099SA,
        TaxFormType.TAX_FORM_1099Q,
      ].includes(taxDocument.taxFormType)
    );

    setValidTaxTypes(validTaxType);
  }, [taxDocumentStatus]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = (value: TaxDocument) => {
    setselectedDocumentStatus(value);
    setModalOpen(true);
  };

  const getCurrentTaxDocumentStatus = async (): Promise<void> => {
    await getTaxDocumnentStatus(getConstantActiveTaxYear(), user.organizationId, user.token)
      .then((status) => {
        if (isMounted) {
          settaxDocumentStatus(status.data);
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Could not retreive the tax documents status'
        );
      });
  };

  const triggerReadyToPrint = async (): Promise<void> => {
    const data = { ...selectedDocumentStatus, readyToPrint: true };

    await updateTaxDocumnentStatus(data, user.organizationId, user.token)
      .then(() => {
        if (isMounted) {
          getCurrentTaxDocumentStatus();
          setModalOpen(false);
          setShowConfetti(true);
          addGlobalMessage('Tax form production approved!', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Could not set the tax documents status'
        );
      });
  };

  const gotoUploadDistribution = (): void => {
    navigate(`/taxReporting/checklist/dataImport/distributions`);
  };

  const gotoTaxReport = (): void => {
    navigate(`/taxReporting/checklist/1099TaxReport`);
  };

  const checkButtonClicked = (value: string): void => {
    switch (value) {
      case 'Upload Distributions':
        gotoUploadDistribution();
        break;
      case 'Get Report':
        gotoTaxReport();
        break;
      case 'Upload 1099 Tax File':
        navigate(`/taxReporting/checklist/taxReporting1099`);
        break;
      default:
        break;
    }
  };

  const handleListClick = (event, formtype, documentStatus: TaxDocument) => {
    if (formtype && !documentStatus.readyToPrint) {
      handleModalOpen(documentStatus);
    }
    event.stopPropagation();
  };

  useEffect(() => {
    getCurrentTaxDocumentStatus();
    return () => {
      isMounted = false;
    };
  }, [user.organizationId]);

  const listItems: Array<SiraListItem> = [
    {
      group: 3,
      isroot: true,
      primary: `Validate ${currentYear} 1099 Tax Records`,
    },
    {
      group: 3,
      primary: `Export ${currentYear} 1099 report`,
      buttonstring: 'Get Report',
      indent: 1,
    },

    {
      group: 4,
      isroot: true,
      primary: 'Approve 1099s to begin tax form production.',
    },
    {
      group: 4,
      primary: `1099-R`,
      buttonstring: 'Create 1099-R tax forms',
      indent: 1,
      formtype: TaxFormType.TAX_FORM_1099R,
    },
    {
      group: 4,
      primary: `1099-Q`,
      buttonstring: 'Create 1099-Q tax forms',
      indent: 1,
      formtype: TaxFormType.TAX_FORM_1099Q,
    },
    {
      group: 4,
      primary: `1099-SA`,
      buttonstring: 'Create 1099-SA tax forms',
      formtype: TaxFormType.TAX_FORM_1099SA,
      indent: 1,
    },
  ];
  const reviewRListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Distributions`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} Distributions`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Distributions',
      indent: 1,
    },
    {
      group: 0,
      primary:
        'Add/update Distribution records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary: `If many updates are needed, upload an updated\nDistributions spreadsheet`,
      indent: 2,
      buttonstring: 'Upload Distributions',
    },
  ];

  const fmvValuesListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Enter 12/31 Fair Market Values`,
    },
    {
      group: 0,
      primary: `View the Fair Market Value Needed page`,
      indent: 1,
      url: '/taxReporting/FMV',
    },
    {
      group: 0,
      primary:
        'Search for Tax Year 2023 and select Coverdell ESA for the Show accounts filter',
      indent: 1,
    },
    {
      group: 0,
      primary: 'Enter the 12/31 FMVs for each ESA listed',
      indent: 1,
    },
  ];

  const dateofDeathListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Enter Date of Death Values`,
    },
    {
      group: 0,
      primary: `View the Date of Death Value Needed page`,
      indent: 1,
      url: '/taxReporting/FMV',
    },
    {
      group: 0,
      primary: 'Enter Date of Death Values for each HSA listed',
      indent: 1,
    },
  ];

  const reviewSAListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Distributions`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} Distributions`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Distributions',
      indent: 1,
    },
    {
      group: 0,
      primary:
        'Add/update Distribution records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary:
        'If many updates are needed, upload an updated Distributions spreadsheet',
      indent: 2,
      buttonstring: 'Upload Distributions',
    },
  ];

  const reviewQListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Distributions`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} distributions`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Distributions',
      indent: 1,
    },
    {
      group: 0,
      primary: 'Add/update deposit records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary:
        'If many updates are needed, upload an updated distributions spreadsheet',
      indent: 2,
      buttonstring: 'Upload Distributions',
    },
  ];

  const errorListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review Errors from Uploaded Spreadsheets (if applicable)`,
    },
    {
      group: 0,
      isroot: true,
      secondary: `If you uploaded a spreadsheet to update distribution records`,
    },
    {
      group: 0,
      primary: 'Correct any errors from the upload as needed',
      indent: 1,
    },
    { group: 0, primary: `Download the error file`, indent: 2 },
    { group: 0, primary: 'Correct errors', indent: 2 },
    {
      group: 0,
      primary: 'Upload the file with the corrected records',
      indent: 2,
    },
    {
      group: 0,
      primary:
        'Review the audit details report to view records that were added/updated',
      indent: 1,
    },
  ];

  const retrieveListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Retrieve 1099 Tax File from Core Processing System`,
    },
    {
      group: 0,
      primary: 'Upload IRS 750 formatted tax file',
      indent: 1,
      buttonstring: 'Upload 1099 Tax File',
    },
    {
      group: 0,
      isroot: true,
      primary: `Review Errors from Uploaded Tax File`,
    },
    {
      group: 0,
      primary: `Correct any errors from the upload as needed' accounts`,
      indent: 1,
    },
    {
      group: 0,
      primary:
        'Review audit details report to view records that were added/updated',
      indent: 1,
    },
  ];

  const getTaxReportingSteps = (
    accountType: PrintableTaxForm,
    fileType: boolean
  ) => {
    if (accountType === PrintableTaxForm.TAX_FORM_1099Q && !fileType) {
      return [
        ...reviewQListItems,
        ...errorListItems,
        ...fmvValuesListItems,
        ...listItems,
      ];
    }
    if (accountType === PrintableTaxForm.TAX_FORM_1099R && !fileType) {
      return [...reviewRListItems, ...errorListItems, ...listItems];
    }
    if (accountType === PrintableTaxForm.TAX_FORM_1099SA && !fileType) {
      return [
        ...reviewSAListItems,
        ...errorListItems,
        ...dateofDeathListItems,
        ...listItems,
      ];
    }

    if (
      [
        PrintableTaxForm.TAX_FORM_1099Q,
        PrintableTaxForm.TAX_FORM_1099SA,
        PrintableTaxForm.TAX_FORM_1099R,
      ].includes(accountType) &&
      fileType
    ) {
      return [...retrieveListItems, ...listItems];
    }
    return [...retrieveListItems, ...listItems];
  };

  const checkListEval = (props: any, documentStatus: TaxDocument, i: any, accountType:any) => {
    if (!props.formtype) {
      return (
        <ListItem
          key={`listItem_${+1}`}
          sx={{ pl: 5 * props.indent }}
          secondaryAction={
            <SecondaryActionButtons
              formtype={props.formtype}
              documentStatus={documentStatus}
              buttonstring={props.buttonstring}
              triggerReadyToPrint={handleModalOpen}
              checkButtonClicked={checkButtonClicked}
              accountType={accountType}
            />
          }
          disablePadding
        >
          <ListItemButton
            onClick={(event) => {
              handleListClick(event, props.formtype, documentStatus);
            }}
          >
            {!props.isroot && (
              <ListItemIcon id={i}>
                <CircleIcon color="primary" />
              </ListItemIcon>
            )}
            {props.url ? (
              <a href={props.url}>
                <ListItemText
                  primaryTypographyProps={
                    props.isroot
                      ? {
                          variant: 'h5',
                          fontWeight: 'bold',
                        }
                      : {
                          sx: { width: '400px' },
                        }
                  }
                  {...props}
                />
              </a>
            ) : (
              <ListItemText
                primaryTypographyProps={
                  props.isroot
                    ? {
                        variant: 'h5',
                        fontWeight: 'bold',
                      }
                    : {
                        sx: { width: '400px' },
                      }
                }
                {...props}
              />
            )}
          </ListItemButton>
        </ListItem>
      );
    }

    return (
      documentStatus &&
      documentStatus.enabled &&
      !documentStatus.readyToPrint && (
        <ListItem
          key={`listItem_${+1}`}
          sx={{ pl: 5 * props.indent }}
          secondaryAction={
            <SecondaryActionButtons
              formtype={props.formtype}
              documentStatus={documentStatus}
              buttonstring={props.buttonstring}
              triggerReadyToPrint={handleModalOpen}
              checkButtonClicked={checkButtonClicked}
              accountType={reviewType}
            />
          }
          disablePadding
        >
          <ListItemButton
            onClick={(event) => {
              handleListClick(event, props.formtype, documentStatus);
            }}
          >
            {!props.isroot && (
              <ListItemIcon>
                <CircleIcon color="primary" />
              </ListItemIcon>
            )}
            {props.url ? (
              <a href={props.url}>
                <ListItemText
                  primaryTypographyProps={
                    props.isroot
                      ? {
                          variant: 'h5',
                          fontWeight: 'bold',
                        }
                      : {
                          sx: { width: '400px' },
                        }
                  }
                  {...props}
                />
              </a>
            ) : (
              <ListItemText
                primaryTypographyProps={
                  props.isroot
                    ? {
                        variant: 'h5',
                        fontWeight: 'bold',
                        noWrap: true,
                      }
                    : {
                        noWrap: true,
                        sx: { width: '400px' },
                      }
                }
                {...props}
              />
            )}
          </ListItemButton>
        </ListItem>
      )
    );
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Tax Reporting
          </Typography>
          <Typography variant="h1" color="secondary" gutterBottom>
            1099 Reporting Checklist
          </Typography>
          <TaxReportingSelection
            initialValues={{
              taxFormType: reviewType,
              switchValue: fileFormat,
            }}
            onSubmit={(value) => {
              setReviewType(value.taxFormType);
              setFileFormat(value.switchValue);
            }}
            validTaxTypes={validTaxTypes}
          />

          {reviewType && (
            <List sx={{ width: '80%' }}>
              {getTaxReportingSteps(reviewType, fileFormat).map((props, i) => {
                const documentStatus = taxDocumentStatus
                  ? taxDocumentStatus.find(
                      (status) => status.taxFormType === props.formtype
                    )
                  : ({} as TaxDocument);
                const showProgress =
                  documentStatus &&
                  documentStatus.readyToPrint &&
                  documentStatus.enabled;

                return (
                  <>
                    {checkListEval(props, documentStatus, i,reviewType)}
                    {showProgress && (
                      <Box sx={{ pl: 5 * props.indent }}>
                        <Typography variant="body1">{props.primary}</Typography>

                        <TaxReportingCheckListProgress
                          documentStatus={documentStatus}
                        />
                      </Box>
                    )}
                  </>
                );
              })}
            </List>
          )}

          <SiraModal open={modalOpen} handleClose={handleClose}>
            <>
              <Box sx={contentStyle} pt={2}>
                <Typography variant="body1">
                  {' '}
                  Tax forms will be created upon your approval and will be
                  delivered by Superior.
                </Typography>
              </Box>
              <Box sx={buttonContentStyle}>
                <Box pr={2}>
                  <Button
                    data-qa="delete-modal_cancel"
                    variant="outlined"
                    onClick={() => {
                      handleClose();
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <Button
                    data-qa="delete-modal_confirm"
                    variant="outlined"
                    onClick={() => {
                      triggerReadyToPrint();
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    OK
                  </Button>
                </Box>
              </Box>
            </>
          </SiraModal>
          {showConfetti && (
            <Confetti
              width={width}
              height={height + 400}
              numberOfPieces={200}
              onConfettiComplete={() => {
                setShowConfetti(false);
              }}
              recycle={false}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default TaxReportingChecklist;
