// this component will be a side card appears when the change account button is clicked or the owner information is scrolled out of view
import React, { FC } from 'react';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';

export interface AccountOwnerInfoCardProps {
  accountOwner: AccountOwner;
}

const AccountOwnerInfoCard: FC<AccountOwnerInfoCardProps> = ({
  accountOwner,
}) => {
  const theme = useTheme();
  const classes = {
    summaryCard: {
      padding: theme.spacing(2),
    },
    summaryContent: {
      transition: 'filter ease-in-out 0.66s',
      '&.loading': {
        transition: 'filter ease-in-out 4s',
        filter: 'blur(7px)',
      },
    },
  };
  const {
    fullName = '',
    dateOfBirth = '',
    taxpayerIdNumber = '',
  } = accountOwner as AccountOwner;

  return (
    <Box width={256}>
      <Paper elevation={5} sx={classes.summaryCard}>
        <Grid container sx={classes.summaryContent} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Account Owner</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" pt={1}>
              Name
            </Typography>
            <Typography variant="body1">{fullName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Date of Birth</Typography>
            <Typography variant="body1">
              {dateOfBirth &&
                format(parseISO(String(dateOfBirth)), 'MM/dd/yyyy')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              SSN/ID
            </Typography>

            <Typography variant="body1">
              <ObscuredTaxId
                taxId={taxpayerIdNumber}
                format={fullName ? TaxIdFormat.ein : TaxIdFormat.ssn}
              />
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AccountOwnerInfoCard;
