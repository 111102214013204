import { Dispatch, useReducer } from 'react';
import {
  AccountOwner,
  AccountOwnerSearchResponse,
  AccountOwnerSearchResult,
} from '../api/AccountOwnerApi.d';
import { ExternalAccount } from '../api/ExternalAccountApi.d';
import { Beneficiaries } from '../api/BeneficiariesApi.d';
import { AccountContribution } from '../api/ContributionApi.d';
import { Distribution } from '../api/DistributionApi.d';
import { Organization } from '../api/OrganizationApi.d';
import { TransferRequest } from '../api/TransferApi.d';
import { User } from '../api/UserApi.d';
import { DistributionTerm } from '../components/form/distribution/DistributionTermForm';
import { ScheduleFormData } from '../components/form/distribution/ScheduleForm';
import { FileUpload } from '../components/form/FileUploadForm';
import { InheritingAccountOwner } from '../components/form/inheritedIra/InheritedAccountOwner';
import { Account } from '../api/AccountApi.d';
import { BeneficiariesClaimFiles } from '../components/form/beneficiariesClaims/BeneficiariesClaimsForm.d';
import { BeneficiariesClaimResult } from '../api/BeneficiaryClaimsApi.d';

export enum TransactionPageActionType {
  addTransactionData = 'SAVE_RESULT',
  setSearchResponse = 'SET_SEARCH',
  setAccount = 'SET_ACCOUNT',
  skipStep = 'SKIP_STEP',
  completeTransaction = 'COMPLETE_TRANS',
  rejectTransaction = 'REJECT_TRANS',
  setBeneficiarySearchResponse = 'SET_BENEFICIARY_SEARCH',
}

// Specific data layer for transactions
interface TransactionDataState {
  organizationInformation?: Organization;
  accountInformation?: Account;
  accountOwnerInformation?: AccountOwner;
  inheritingAccountOwnerInformation?: InheritingAccountOwner;
  deceasedAccountOwnerInformation?: ExternalAccount;
  deceasedAccountInformation?: Account;
  beneficiaryInformation?: Beneficiaries;
  contributionInformation?: AccountContribution;
  distributionInformation?: Distribution;
  distributionTermInformation?: DistributionTerm;
  taxAmounts?: Distribution;
  schedule?: ScheduleFormData;
  transferRequestInformation?: TransferRequest;
  userInformation?: User;
  fileUploadInformation?: FileUpload;
  beneficiaryClaim?: BeneficiariesClaimFiles;
}

// Extend data with generic interaction state
interface TransactionState extends TransactionDataState {
  searchResponse: AccountOwnerSearchResponse;
  selectedAccount: Account & AccountOwner & BeneficiariesClaimResult;
  activeStep: number;
  completed: boolean;
  rejected: boolean;
}

// Allowed params from a dispatch payload
interface TransactionPayload extends TransactionDataState {
  searchResponse?: AccountOwnerSearchResponse;
  selectedAccount?: any;
  advanceStep?: boolean;
  stepNumber?: number;
}

export interface TransactionAction {
  type: TransactionPageActionType;
  payload?: TransactionPayload;
}

const TRANSACTION_DATA_INIT_STATE: TransactionDataState = {
  organizationInformation: {},
  accountInformation: {},
  accountOwnerInformation: {},
  inheritingAccountOwnerInformation: {},
  deceasedAccountOwnerInformation: {},
  deceasedAccountInformation: {},
  beneficiaryInformation: {},
  contributionInformation: {},
  distributionInformation: {},
  distributionTermInformation: {},
  taxAmounts: {},
  schedule: {},
  transferRequestInformation: {},
  userInformation: {},
  fileUploadInformation: {},
  beneficiaryClaim: {},
};

export const TRANSACTION_INIT_STATE: TransactionState = {
  ...TRANSACTION_DATA_INIT_STATE,
  searchResponse: {},
  selectedAccount: {},
  activeStep: 0,
  completed: false,
  rejected: false,
};

let dispatch: Dispatch<TransactionAction> = () => {};

/* THE REDUCER */
export function transactionReducer(prev, action: TransactionAction) {
  const { type, payload = {} as TransactionPayload } = action;
  const newState = { ...prev } as TransactionState;
  const { advanceStep = true } = payload;
  const [payloadKey] = Object.keys(payload);
  const {
    selectedAccount: {
      account = {},
      accountOwner = {},
      beneficiaryClaim = {},
    } = {},
  } = payload;

  switch (type) {
    // Accumulate new data for a given state entry by the provided payload object's first key
    case TransactionPageActionType.addTransactionData:
      // Only allow saving keys that have been initialized as obj in reducer state, ignore primitives like step number, etc...
      if (prev[payloadKey]) {
        newState[payloadKey] = {
          ...prev[payloadKey],
          ...payload[payloadKey],
        };
      }

      if (advanceStep) {
        newState.activeStep = prev.activeStep + 1;
      }
      break;

    // Store the account search results response
    case TransactionPageActionType.setSearchResponse:
      newState.searchResponse = payload.searchResponse;
      break;

    // Set which account was selected from the search results
    case TransactionPageActionType.setAccount:
      newState.selectedAccount = {
        ...account,
        ...accountOwner,
        ...beneficiaryClaim,
        ...payload.selectedAccount,
      };
      newState.activeStep = prev.activeStep + 1;

      // Reset the transaction data when changing accounts
      Object.assign(newState, TRANSACTION_DATA_INIT_STATE);
      break;

    // Skip to a particular step number
    case TransactionPageActionType.skipStep:
      if (!prev.completed) {
        newState.activeStep = payload.stepNumber;
      }
      break;

    // Mark a transaction flow 'completed'
    case TransactionPageActionType.completeTransaction:
      newState.rejected = false;
      newState.completed = true;
      break;

    // Mark a transaction flow as having errors
    case TransactionPageActionType.rejectTransaction:
      newState.completed = false;
      newState.rejected = true;
      break;
    default:
      return prev;
  }

  return newState;
}

/* THE HOOK */
export const useTransactionReducer = () => {
  const [pageState, setPageState] = useReducer(
    transactionReducer,
    TRANSACTION_INIT_STATE
  );

  dispatch = setPageState;

  // Passes through the same API as useReducer
  return [pageState, setPageState];
};

/* THE ACTION CONSTRUCTORS */
export const addTransactionData = (
  payload: TransactionPayload,
  advanceStep: boolean = true
): void => {
  dispatch({
    type: TransactionPageActionType.addTransactionData,
    payload: { ...payload, advanceStep },
  } as TransactionAction);
};

export const setSearchResponse = (
  searchResponse: AccountOwnerSearchResponse
): void => {
  dispatch({
    type: TransactionPageActionType.setSearchResponse,
    payload: { searchResponse },
  } as TransactionAction);
};

export const setBeneficiarySearchResponse = (
  searchResponse: AccountOwnerSearchResponse
): void => {
  dispatch({
    type: TransactionPageActionType.setSearchResponse,
    payload: { searchResponse },
  } as TransactionAction);
};

export const setSelectedAccount = (
  selectedAccount: AccountOwnerSearchResult | Account
): void => {
  dispatch({
    type: TransactionPageActionType.setAccount,
    payload: { selectedAccount },
  } as TransactionAction);
};

export const skipStep = (step: number): void => {
  dispatch({
    type: TransactionPageActionType.skipStep,
    payload: { stepNumber: step },
  } as TransactionAction);
};

export const completeTransaction = (): void => {
  dispatch({
    type: TransactionPageActionType.completeTransaction,
  } as TransactionAction);
};

export const rejectTransaction = (): void => {
  dispatch({
    type: TransactionPageActionType.rejectTransaction,
  } as TransactionAction);
};
