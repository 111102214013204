import axios from 'axios';
import format from 'string-format';
import { AccountType } from './AccountApi.d';
import { prepareBinaryJson, removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  DistributionReason,
  DistributionStatusState,
  WorkflowDates,
} from './DistributionApi.d';
import {
  RecurringDistribution,
  RecurringDistributionImportColumnsResponse,
  RecurringDistributionStatus,
  RecurringDistributionStatusResponse,
} from './RecurringDistributionApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;


const recurringDistributionUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/recurringDistribution/{0.recurringDistributionId}';
const recurringDistributionDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/recurringDistribution/{0.recurringDistributionId}/document';
const recurringDistributionStatusUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/recurringDistribution/{0.distributionId}/status';
const recurringDistributionsSearchUrl =
  '/api/org/{0.orgId}/recurringDistribution';
const recurringDistributionImportColumsUrl =
  '/api/org/{0.orgId}/upload/recurringDistributionImportColumns';
const recurringDistributionImportUrl =
  '/api/org/{0.orgId}/upload/recurringDistributions';

// Send only the recurring distribution fields that the API accepts
function recurringDistributionRequestFactory(distribution) {
  const {
    toFinancialOrganization,
    federalWithholdingPercent,
    accountId,
    endDate,
    signedDate,
    term,
    startDate,
    additionalStateWithholding,
    withholdingState,
    netAmount,
    distributionStatus,
    toAccountTypeName,
    stateWithholdingPercent,
    toAccountNumber,
    recurringDistributionId,
    lifeExpectancyTable,
    distributionAmountType,
    distributionMethod,
    distributionReason,
    toAccountType,
    activeDate,
    totalAmount,
    frequency,
    parentRecurringDistributionId,
    ownerResponsible,
    termEndDateAction,
    payableTo,
    routingNumber,
  } = distribution;

  return {
    // API requires that several fields explicitly be 'null' (ommitted isn't good enough) when sending ownerResponsible election
    toFinancialOrganization: ownerResponsible ? null : toFinancialOrganization,
    federalWithholdingPercent: ownerResponsible
      ? null
      : federalWithholdingPercent,
    accountId,
    endDate,
    signedDate,
    term: ownerResponsible ? null : term,
    startDate,
    additionalStateWithholding: ownerResponsible
      ? null
      : additionalStateWithholding,
    withholdingState: ownerResponsible ? null : withholdingState,
    netAmount: ownerResponsible ? null : netAmount,
    distributionStatus,
    toAccountTypeName: ownerResponsible ? null : toAccountTypeName,
    stateWithholdingPercent: ownerResponsible ? null : stateWithholdingPercent,
    toAccountNumber: ownerResponsible ? null : toAccountNumber,
    recurringDistributionId,
    lifeExpectancyTable: ownerResponsible ? null : lifeExpectancyTable,
    distributionAmountType: ownerResponsible ? null : distributionAmountType,
    distributionMethod: ownerResponsible ? null : distributionMethod,
    distributionReason: ownerResponsible ? null : distributionReason,
    toAccountType: ownerResponsible ? null : toAccountType,
    activeDate,
    totalAmount: ownerResponsible ? null : totalAmount,
    frequency: ownerResponsible ? null : frequency,
    parentRecurringDistributionId,
    ownerResponsible,
    termEndDateAction: ownerResponsible ? null : termEndDateAction,
    payableTo,
    routingNumber
  };
}

export async function getRecurringDistributions(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any,
  status?: string | Array<RecurringDistributionStatus>,
) {

  const url = `${urlBase}${format(`${setBaseUrl(user)}${recurringDistributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    recurringDistributionId: '',
  })}`;

  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getRecurringDistribution(
  accountId: string,
  accountOwnerId: string,
  recurringDistributionId: string,
  orgId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${recurringDistributionUrl}`, {
    orgId,
    recurringDistributionId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createOrSaveRecurringDistribution(
  data: RecurringDistribution,
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any
) {
  const { recurringDistributionId = '' } = data;
  const url = `${urlBase}${format(`${setBaseUrl(user)}${recurringDistributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    recurringDistributionId,
  })}`;

  const dataNorm = recurringDistributionRequestFactory(
    removeEmptyStrings(data)
  );

  if (recurringDistributionId) {
    return axiosInstance.put(url, dataNorm, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteRecurringDistribution(
  recurringDistributionId: string,
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${recurringDistributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    recurringDistributionId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getRecurringDistributionDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  recurringDistributionId: string,
  token: string,
  versionID: string = '',
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${recurringDistributionDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    recurringDistributionId,
  })}`;

  const params = removeEmptyStrings({ versionID });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createRecurringDistributionDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  recurringDistributionId: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${recurringDistributionDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    recurringDistributionId,
  })}`;

  return axiosInstance.post(url, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// Not yet supported this is a possible implementation
export async function changeRecurringDistributionStatus(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  distributionId: string,
  state: DistributionStatusState,
  workflowDates: WorkflowDates,
  token: string,
  user
): Promise<RecurringDistributionStatusResponse> {
  const params = { state };
  const url = `${urlBase}${format(`${setBaseUrl(user)}${recurringDistributionStatusUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    distributionId,
    state,
  })}`;

  return axiosInstance.put(url, workflowDates, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function calculateRecurringDistributionReason(
  accountType,
  underHalf59
): DistributionReason {
  if ([AccountType.rothIra].includes(accountType)) {
    return underHalf59
      ? DistributionReason.ROTH_EARLY_NO_EXCEPTION
      : DistributionReason.ROTH_QUALIFIED;
  }
  // inherited traditionalIra
  if ([AccountType.inheritedTraditional].includes(accountType)) {
    return DistributionReason.I_IRA_DEATH;
  }

  // inherited roth
  if ([AccountType.inheritedRoth].includes(accountType)) {
    return DistributionReason.ROTH_QUALIFIED;
  }

  if (
    [
      AccountType.simpleIra,
      AccountType.traditionalIra,
      AccountType.traditionalSep,
    ].includes(accountType)
  ) {
    return underHalf59
      ? DistributionReason.IRA_EARLY_IRS_LEVY
      : DistributionReason.IRA_NORMAL;
  }
  return DistributionReason.IRA_NORMAL;
}

export async function searchOrgRecurringDistributions(
  orgId: string,
  search: {
    startDate: string;
    endDate?: string;
  },
  token: string
) {
  const url = `${urlBase}${format(recurringDistributionsSearchUrl, {
    orgId,
  })}`;

  const params = removeEmptyStrings(search);

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importRecurringDistributionsColumns(
  files: Array<File>,
  orgId: string,
  token: string
): Promise<RecurringDistributionImportColumnsResponse> {
  const url = `${urlBase}${format(recurringDistributionImportColumsUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importRecurringDistributions(
  files: Array<File>,
  headerMappings: any,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(recurringDistributionImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();

  formData.append('file', files[0]);
  if (headerMappings) {
    formData.append('headerMappings', prepareBinaryJson(headerMappings));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}
