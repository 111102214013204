import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, LinearProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { useUser } from '../../../auth/useUser';
import SiraSearchField from '../SiraSearchField';
import { searchOrg } from '../../../api/OrganizationApi';
import { OrganizationSearchFormData } from './OrganizationSearch';
import { useGlobalContext } from '../../../auth/useGlobalContext';

const SEARCH_QUERY_SCHEMA = yup.object().shape({
  query: yup.string().required().max(60).label('Search term'),
});

interface OrganizationSearchFormProps {
  setResponse?: Function;
  showViewAll?: boolean;
}

function OrganizationSearchForm(props: OrganizationSearchFormProps) {
  let isMounted = true;
  const { showViewAll = false, setResponse = () => {} } = props;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false as boolean);

  async function submitSearch(data: OrganizationSearchFormData): Promise<void> {
    await searchOrg(data, user.token)
      .then((res) => {
        if (isMounted) {
          setResponse({ ...res, query: data.query });
        }
      })
      .catch(() => {
        addGlobalMessage('Error fetching organization search results');
      });
  }

  const getAllOrganizations = async (): Promise<void> => {
    setIsLoading(true);
    await submitSearch({ query: '*' });
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Formik
      initialValues={{ query: '' }}
      onSubmit={async (data) => {
        await submitSearch(data);
      }}
      validationSchema={SEARCH_QUERY_SCHEMA}
    >
      {({ isSubmitting, resetForm }) => (
        <Form>
          <Container disableGutters>
            <Grid container wrap="nowrap" spacing={2} alignItems="center">
              <Grid item xs={12}>
                <SiraSearchField name="query" label="Enter name or tax id" />
              </Grid>
              {showViewAll && (
                <Grid item>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      getAllOrganizations();
                      resetForm();
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    View&nbsp;All
                  </Button>
                </Grid>
              )}
            </Grid>
          </Container>
          {(isSubmitting || isLoading) && (
            <Box width="1" mt={2}>
              <LinearProgress color="secondary" />
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default OrganizationSearchForm;
