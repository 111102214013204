/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form } from 'formik';

import StepButtonBar from '../../steps/StepButtonBar';
import { OrganizationTaxReportingSettings } from '../../../api/OrganizationApi.d';

import SiraSwitchField from '../SiraSwitchField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';

export const FINANCIAL_ORG_TAX_REPORTING_SETTINGS_INT: OrganizationTaxReportingSettings =
  {
    TAX_FORM_1099R: false,
    TAX_FORM_1099SA: false,
    TAX_FORM_1099Q: false,
    TAX_FORM_5498: false,
    TAX_FORM_5498SA: false,
    TAX_FORM_5498ESA: false,
  };

export interface FinancialOrgTaxReportingSettingsFormProps {
  initialValues: OrganizationTaxReportingSettings;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  getYearOptions?: Function;
}

const FinancialOrgTaxReportingSettingsForm = ({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
  getYearOptions,
}: FinancialOrgTaxReportingSettingsFormProps) => {
  const { taxYears } = useGlobalContext();
  const [taxYearDropdown, setTaxYearDropdown] = useState(
    [] as Array<SiraSelectItem>
  );


  useEffect(() => {
    if (taxYears) {
      setTaxYearDropdown(
        taxYears.map((availableYears) => ({
          value: availableYears.taxYear,
          label: availableYears.taxYear.toString(),
        }))
      );
    }
  }, [taxYears]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      enableReinitialize
    >
      {({ isSubmitting, values, setValues }) => {
        const {
          TAX_FORM_1099R,
          TAX_FORM_1099SA,
          TAX_FORM_1099Q,
          TAX_FORM_5498,
          TAX_FORM_5498SA,
          TAX_FORM_5498ESA,
        } = values as OrganizationTaxReportingSettings;

        useEffect(() => {
          setValues({ ...(values as OrganizationTaxReportingSettings) });
        }, [isSubmitting]);

        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SiraSelectField
                  name="taxYear"
                  label="Tax Year"
                  items={taxYearDropdown}
                  fullWidth
                  onChange={(e) => {
                    getYearOptions(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_1099R"
                        checked={TAX_FORM_1099R}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>IRA Distributions (1099-R)</Typography>
                  </Grid>
                </Grid>
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_5498"
                        checked={TAX_FORM_5498}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>IRA FMV and Deposits (5498)</Typography>
                  </Grid>
                </Grid>
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_1099SA"
                        checked={TAX_FORM_1099SA}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>HSA Distributions (1099-SA)</Typography>
                  </Grid>
                </Grid>
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_5498SA"
                        checked={TAX_FORM_5498SA}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>HSA FMV and Deposits (5498-SA)</Typography>
                  </Grid>
                </Grid>
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_1099Q"
                        checked={TAX_FORM_1099Q}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Coverdell ESA Distributions (1099-Q)
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_5498ESA"
                        checked={TAX_FORM_5498ESA}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>Coverdell ESA Deposits (5498-ESA)</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FinancialOrgTaxReportingSettingsForm;
