/* eslint-disable import/no-cycle */
import React from 'react';
import { Typography, Link } from '@mui/material';
import { AccountType } from '../../../api/AccountApi.d';
import { DepositType } from '../../../api/ContributionApi.d';
import NewAccountModalReasons from './newAccountModal';

export const newAccountDepositHelpTxt = {
  tradSepSimpleRoth: {
    transfer: (
      <Typography variant="body2">
        A non-reportable movement of assets from one IRA to another IRA of the
        same type. The IRA owner does not have access to the assets, and they
        are not subject to federal tax withholding.
      </Typography>
    ),
    rollover: (
      <Typography variant="body2">
        For employer-sponsored retirement plan-to-IRA rollovers::
        <ul style={{ paddingLeft: '15px' }}>
          <li>The once-every-12-months limit does not apply.</li>
          <li>
            If the account owner has access to the assets, the rollover must be
            completed within 60 days from receipt of those assets.
          </li>
          <li>
            {' '}
            If the retirement plan assets are sent directly to the receiving
            organization (e.g., direct rollover), the 60-day limit does not
            apply.{' '}
          </li>
        </ul>
        <strong>NOTE:</strong> Direct rollovers are the only option available
        for beneficiaries moving assets to inherited IRAs.
      </Typography>
    ),
    sepContribution: (
      <Typography variant="body2">
        If the account owner is a participant in a simplified employee pension
        (SEP) plan, these contributions may be made to the account owner’s
        Traditional or SEP IRA. They are reported for the year in which they are
        made.
      </Typography>
    ),
  },
  other: {
    rollover: (
      <Typography variant="body2">
        For IRA-to-IRA rollovers:
        <ul style={{ paddingLeft: '15px' }}>
          <li>
          The account owner has 60 days from receipt of the IRA assets to complete a rollover transaction to the same IRA or another IRA.
          </li>
          <li>
            {' '}
            Only one IRA-to-IRA rollover is allowed every 12 months (starting with the date of distribution) among all IRAs owned by the individual.{' '}
          </li>
          <li>
          All RMDs must be withdrawn as required under the Code and Regulations prior to a rollover.
          </li>
        </ul>
      </Typography>
    ),
  },
  simpleIra: {
    simpleContribution: (
      <Typography variant="body2">
        If the account owner is a participant in a savings incentive match plan
        for employees (SIMPLE) IRA, SIMPLE contributions (i.e., employer
        contributions and deferrals) may be made to the account owner’s SIMPLE
        IRA. They are reported for the year in which they are made.
      </Typography>
    ),
  },
  hsa: {
    transfer: (
      <Typography variant="body2">
        A non-reportable movement of assets from one HSA to another HSA. The
        account owner does not have access to the assets.
      </Typography>
    ),
    rollover: (
      <Typography variant="body2">
        For HSA-to-HSA rollovers, the account owner has 60 days from receipt of
        the account assets to complete a rollover transaction to the same HSA or
        another HSA. Only one HSA-to-HSA rollover is allowed once a year among
        all HSAs owned by the individual.
      </Typography>
    ),
    qlHsaFundingDist: (
      <Typography variant="body2">
        A{' '}
        <NewAccountModalReasons
          textHyperLink="one-time"
          accountType={AccountType.hsa}
          depositType={DepositType.qualifiedHSAlbl}
          dropDown="oneTime"
        />{' '}
        tax-free transfer of Traditional or Roth IRA assets to an HSA. The
        amount is included as part of the account owner’s contribution limit for
        the year. (The transfer may also be made from a SEP or SIMPLE IRA as
        long as the IRA is not an{' "'}
        <NewAccountModalReasons
          textHyperLink="ongoing SEP or SIMPLE IRA"
          accountType={AccountType.hsa}
          depositType={DepositType.qualifiedHSAlbl}
          dropDown="ongoingSep"
        />
        {'" '}. )
      </Typography>
    ),
  },
  esa: {
    transfer: (
      <Typography variant="body2">
        A movement of assets from one Coverdell ESA to another ESA (either for
        the same designated beneficiary or to a{' '}
        <NewAccountModalReasons
          textHyperLink="qualifying family member"
          accountType={AccountType.esa}
          depositType={DepositType.transfer}
        />{' '}
        who is under age 30). The responsible individual/designated beneficiary
        does not have access to the assets.
      </Typography>
    ),
    rollover: (
      <Typography variant="body2">
        For ESA-to-ESA rollovers, the responsible individual/designated
        beneficiary has 60 days from receipt of the account assets to complete a
        rollover transaction to the same ESA or to another ESA for either the
        same designated beneficiary or to the ESA of a{' '}
        <NewAccountModalReasons
          textHyperLink="qualifying family member"
          accountType={AccountType.esa}
          depositType={DepositType.rollover}
        />{' '}
        who is under age 30. Only one ESA-to-ESA rollover is allowed once a
        year, regardless of the number of ESAs owned.
      </Typography>
    ),
  },
  regularContribution: (
    <Typography variant="body2">
      A contribution made to the account for the current tax year.
    </Typography>
  ),
  priorYearContribution: (
    <Typography variant="body2">
      A contribution made between January 1 and the tax return deadline
      (generally April 15) of the current year that is irrevocably designated by
      the account owner as a contribution for the prior year.
    </Typography>
  ),
  repayment: {
    afterContent: (
      <Typography variant="body2">
        These contribution types may include repayments of a qualified reservist
        distribution, qualified disaster distribution, or qualified birth or
        adoption distribution.
      </Typography>
    ),
    birthOrAdoption: (
      <Typography variant="body2">
        For an IRA owner who took a{' '}
        <NewAccountModalReasons
          textHyperLink="qualified birth or adoption distribution"
          dropDown="birthOrAdoption"
          depositType={DepositType.repayment}
        />{' '}
        from an IRA or eligible retirement plan to pay for the birth or adoption
        of a child and is repaying the distribution amount to an IRA. According
        to the SECURE Act, there is no time limit on when the IRA owner is
        eligible to repay the distribution.
      </Typography>
    ),
    disasterDistribution: (
      <Typography variant="body2">
        For an IRA owner who took an IRA distribution as a result of a federally
        declared disaster and is repaying the qualified disaster distribution to
        an IRA. These repayments typically must be made within three years of
        receiving the distribution. Visit www.irs.gov for more information about
        these distributions and repayments.
      </Typography>
    ),
    reservistDistribution: (
      <Typography variant="body2">
        For an eligible member of a U.S. military reserve component who received
        a{' '}
        <NewAccountModalReasons
          textHyperLink="qualified reservist distribution"
          dropDown="reservistDistribution"
          depositType={DepositType.repayment}
        />{' '}
        and is repaying the distribution to an IRA within two years of the end
        of the individual’s active duty.
      </Typography>
    ),
  },
  postponedContribution: {
    afterContent: (
      <Typography variant="body2">
        Postponed contributions are contributions made in the current tax year
        but designated for a prior year for one of the following reasons:
        <ul>
          <li>qualified plan loan offset;</li>
          <li>federally designated disaster; or </li>
          <li>
            for military personnel who served in a specific combat zone,
            hazardous duty area, or direct support area.{' '}
          </li>
        </ul>
      </Typography>
    ),
    qlPlanOffset: (
      <Typography variant="body2">
        A plan loan offset resulting from a plan termination or severance from
        employment that is paid to an IRA after the standard 60-day deadline but
        before the account owner’s tax filing deadline, including extensions,
        for the tax year in which the offset occurs.
      </Typography>
    ),
    executiveOrder: {
      afterContent: (
        <Typography variant="body2">
          For postponed contributions made by military personnel who served
          under an executive order, select the appropriate military operation
          code.
        </Typography>
      ),
      afghanistan: (
        <Typography variant="body2">
          For those who served in Afghanistan and those countries in direct
          support, including Djibouti, Jordan, Kyrgyzstan, Pakistan, Somalia,
          Syria, Tajikistan, Uzbekistan, Yemen, and the Philippines. For the
          Philippines only, personnel must be deployed in conjunction with
          Operation Enduring Freedom supporting military operations in the
          Afghanistan combat zone.
        </Typography>
      ),
      arabianPeninsula: (
        <Typography variant="body2">
          For those who served in the Arabian Peninsula, including air space and
          adjacent waters (the Persian Gulf, the Red Sea, the Gulf of Oman, the
          Gulf of Aden, the portion of the Arabian Sea that lies north of 10
          degrees north latitude and west of 68 degrees east longitude, and the
          total land areas of Iraq, Kuwait, Saudi Arabia, Oman, Bahrain, Qatar,
          and the United Arab Emirates), and Jordan which is in direct support
          of the Arabian Peninsula.
        </Typography>
      ),
      federalRepublicYugo: (
        <Typography variant="body2">
          For those who served in the Federal Republic of Yugoslavia (Serbia and
          Montenegro), Albania, Kosovo, the Adriatic Sea, and the Ionian Sea
          north of the 39th parallel. (The combat zone designation for
          Montenegro and Kosovo, previously a province within Serbia, under
          Executive Order 13119 remains in force even though Montenegro and
          Kosovo became independent nations since EO13119 was signed.)
        </Typography>
      ),
    },
    federalDisasterArea: (
      <Typography variant="body2">
        For postponed contributions made by those affected by a federally
        declared disaster as defined at{' '}
        <Link href="www.irs.gov" variant="body2">
          www.irs.gov
        </Link>
        . (Click <strong>News</strong> and then click
        <strong> Tax Relief in Disaster Situations</strong> within the website
        for further details about federally declared disaster areas.
      </Typography>
    ),
    publicLaw: {
      afterContent: (
        <Typography variant="body2">
          For postponed contributions made by military personnel who served
          under a public law, select the appropriate military operation code.
        </Typography>
      ),
      federalRepublicYugo: (
        <Typography variant="body2">
          For those who served in the Federal Republic of Yugoslavia (Serbia and
          Montenegro), Albania, Kosovo, the Adriatic Sea, and the Ionian Sea
          north of the 39th parallel. (The combat zone designation for
          Montenegro and Kosovo, previously a province within Serbia, under
          Executive Order 13119 remains in force even though Montenegro and
          Kosovo became independent nations since EO13119 was signed.)
        </Typography>
      ),
      sinaiPeninsulaEgypt: (
        <Typography variant="body2">
          For those who served in the Sinai Peninsula of Egypt
        </Typography>
      ),
    },
  },
  recharacterization: (
    <Typography variant="body2">
      The movement of a current-year contribution, plus the net income
      attributable (NIA), from one type of IRA to another type of IRA in order
      to “undo” an IRA owner’s original contribution and treat it as if it were
      made to another type of IRA.
    </Typography>
  ),
  roth: {
    rothConversion: (
      <Typography variant="body2">
        A taxable movement from a Traditional, SEP, or SIMPLE IRA into a Roth
        IRA. If the source of assets is from a SIMPLE IRA, the two-year waiting
        period must be met, which begins the day contributions are first
        deposited into the SIMPLE IRA.
      </Typography>
    ),
  },
  primaryBeneficiary: {
    skipBenefeciaries: (
      <Typography variant="body2">
        If no beneficiaries are designated for the account at the time of the
        owner’s death, assets will be distributed to the owner’s surviving
        spouse. If there is no surviving spouse, the owner’s estate will receive
        the assets.
      </Typography>
    ),
    curPrimaryBeneficiary: (
      <Typography variant="body2">
        The primary beneficiaries designated below will receive the account
        assets upon the account owner’s death.
      </Typography>
    ),
    curContingentBeneficiary: (
      <Typography variant="body2">
        The contingent beneficiaries designated below will receive the account
        assets upon the account owner’s death if all of the named primary
        beneficiaries die before the account owner.
      </Typography>
    ),
  },
};

export const newAccountModalHelpTxt = {
  esa: {
    transfer: (
      <Typography variant="body2">
        Qualifying family members include the designated beneficiary’s spouse
        and the following other relatives:
        <ul style={{ paddingLeft: '15px' }}>
          <li>
            Son, daughter, stepchild, foster child, adopted child, or a
            descendant of any of them.
          </li>
          <li>Brother, sister, stepbrother, or stepsister.</li>
          <li> Father or mother or ancestor of either. </li>
          <li>Stepfather or stepmother.</li>
          <li>Son or daughter of a brother or sister.</li>
          <li>Brother or sister of father or mother.</li>
          <li>
            Son-in-law, daughter-in-law, father-in-law, mother-in-law,
            brother-in-law, or sister-in-law.
          </li>
          <li>The spouse of any individual listed above.</li>
          <li>First cousin</li>
        </ul>
        <strong>NOTE:</strong> The age 30 restriction may be waived for a
        special needs individual.
      </Typography>
    ),
    rollover: (
      <Typography variant="body2">
        Qualifying family members include the designated beneficiary’s spouse
        and the following other relatives:{' '}
        <ul style={{ paddingLeft: '15px' }}>
          <li>
            Son, daughter, stepchild, foster child, adopted child, or a
            descendant of any of them.
          </li>
          <li>Brother, sister, stepbrother, or stepsister.</li>
          <li> Father or mother or ancestor of either. </li>
          <li>Stepfather or stepmother.</li>
          <li>Son or daughter of a brother or sister.</li>
          <li>Brother or sister of father or mother.</li>
          <li>
            Son-in-law, daughter-in-law, father-in-law, mother-in-law,
            brother-in-law, or sister-in-law.
          </li>
          <li>The spouse of any individual listed above.</li>
          <li>First cousin</li>
        </ul>
        <br />
        <strong>NOTE:</strong>The age 30 restriction may be waived for a special
        needs individual.
      </Typography>
    ),
    qlHsaFundingDist: (
      <Typography variant="body2">
        A SEP or SIMPLE IRA is ongoing if an employer contribution is made for
        the plan year ending with or within the account owner’s tax year in
        which the distribution would be made.
      </Typography>
    ),
  },
  hsa: {
    oneTime: (
      <Typography variant="body2">
        An exception to the “one per lifetime” rule is if the account owner
        changes from self-only to family coverage during the same year the
        qualified HSA funding distribution is made. In this instance, the
        account owner may make another distribution within that same tax year as
        long as the total of the two distributions does not exceed the
        contribution limit for family coverage (plus catch-up contributions, if
        applicable).
      </Typography>
    ),
    ongoingSepOrSimple: (
      <Typography variant="body2">
        A SEP or SIMPLE IRA is ongoing if an employer contribution is made for
        the plan year ending with or within the account owner’s tax year in
        which the distribution would be made.
      </Typography>
    ),
    oncePerLifeTime: (
      <Typography variant="body2">
        An exception to the one-per-lifetime rule may apply for owners who
        switch from self-only health coverage to family coverage in the same
        year. In these instances, owners should consult with a competent tax
        advisor to determine eligibility for making a qualified HSA funding
        distribution.
      </Typography>
    ),
  },
  repayment: {
    birthOrAdoption: (
      <Typography variant="body2">
        A qualified birth or adoption distribution includes any distribution
        withdrawn on or after January 1, 2020, from an individual’s IRA (or
        eligible retirement plan) that was taken during the one-year period
        beginning on the date of birth of the child or the date on which the
        legal adoption of the eligible adoptee is finalized.
      </Typography>
    ),
    reservistDistribution: (
      <Typography variant="body2">
        A distribution is a qualified reservist distribution if the following
        requirements are met. The individual was ordered or called to active
        duty after September 11, 2001, for a period of more than 179 days or for
        an indefinite period as a member of a reserve component (i.e., the Army
        National Guard of the United States; the Army, Naval, Marine Corps, Air
        Force, or Coast Guard Reserve; the Air National Guard of the United
        States; or the Reserve Corps of the Public Health Service). The
        distribution is from an IRA or from deferral amounts under a
        401(k)/403(b) plan. The distribution must be made between the date of
        the order or call to active duty and the close of the active duty
        period.
      </Typography>
    ),
  },
};
