import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as yup from 'yup';

import StepButtonBar from '../../steps/StepButtonBar';
import {
  IncludeWithholdingCert,
  StateTaxId,
} from '../../../api/OrganizationApi.d';
import StateField from '../StateField';
import StateTaxIdField from '../StateTaxIdField';
import SiraTextField from '../SiraTextField';
import SiraCheckbox from '../SiraCheckbox';

export const STATE_TAX_ID_INIT: StateTaxId = {
  state: '',
  stateWithholding: false,
  taxId: '',
  create4P: true,
};

export const STATE_TAX_ID_SCHEMA = (excludedStates) => {
  return {
    state: yup
      .string()
      .required()
      .max(2)
      .notOneOf(excludedStates)
      .label('State'),
    taxId: yup.string().max(30).label('State'),
  };
};

export interface StateTaxIdProps {
  initialValues: StateTaxId;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  excludeStates?: Array<string>;
  editingMode?: boolean;
}

// Map which states to show state withholding certificate fields here!
export const stateWithholdingCertFieldMap = {
  NC: 'NC',
  MI: 'MI',
  MN: 'MN',
  CT: 'CT',
};
const certFieldLabels = {
  NC: 'Include NC-4P for Distributions',
  MI: 'Include MI W-4P for Distributions',
  MN: 'Include W-4MNP for Distributions',
  CT: 'Include CT-W4P for Distributions',
};

// Conditional fields to enable withholding certs for certain states that support it (mapped above)
function StateWithholdingCertificateFields() {
  const { values, setFieldValue } = useFormikContext();
  const { state: selectedState = '', stateWithholding } = values as StateTaxId;

  useEffect(() => {
    if (stateWithholding) {
      // If supports state withholding and the selected state maps to a cert field, default the field to true
      // Also map from the enum to a boolean for incoming IDs
      setFieldValue(
        'create4P',
        selectedState && values[selectedState] !== IncludeWithholdingCert.false
      );
    } else {
      // If state withholding not supported, set to false
      setFieldValue('create4P', false);
    }
  }, [stateWithholding, selectedState]);

  if (!stateWithholding) {
    return null;
  }

  return (
    <>
      <Grid item xs={12} key={`withholding-cert-options_${selectedState}`}>
        <Field
          name="create4P"
          label={certFieldLabels[selectedState] || selectedState}
          component={SiraCheckbox}
        />
      </Grid>
    </>
  );
}

function StateTaxIdForm({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
  editingMode = false,
  excludeStates = [],
}: StateTaxIdProps) {
  return (
    <section>
      <Formik
        initialValues={initialValues}
        onSubmit={async (data) => {
          const cleanDate = {
            create4P: data.create4P,
            state: data.state,
            stateWithholding: data.stateWithholding,
            taxId: data.taxId,
          };
          await onSubmit(cleanDate);
        }}
        onReset={() => onReset()}
        validationSchema={yup.object(
          STATE_TAX_ID_SCHEMA(editingMode ? [] : excludeStates)
        )}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Box maxWidth={300}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {editingMode ? (
                    <SiraTextField name="state" label="State" disabled />
                  ) : (
                    <StateField name="state" label="State" />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <StateTaxIdField
                    name="taxId"
                    state={values.state}
                    label="Tax ID"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="stateWithholding"
                    label="Supports Witholding"
                    component={SiraCheckbox}
                  />
                </Grid>
                <StateWithholdingCertificateFields />
              </Grid>
            </Box>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        )}
      </Formik>
    </section>
  );
}

export default StateTaxIdForm;
