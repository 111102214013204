import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  IconButton,
  Button,
  Box,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { DataGridPro, GridCellParams, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import { useUser } from '../../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import {
  createInvestmentRate,
  deleteInvestmentRateDocument,
  getInvestmentRateDocument,
  getInvestmentRates,
  saveInvestmentRateDocument,
  updateInvestmentRate,
} from '../../api/InvestmentRateApi';
import { currencyValueFormatter, currencyValueFormatterPro } from '../../utils/DataGrid.utils';
import { InvestmentRate } from '../../api/InvestmentRateApi.d';
import InvestmentRateForm, {
  INVESTMENT_RATE_INIT,
} from '../form/organization/InvestmentRateForm';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { globalPaginationOptions } from '../../app.constants';
import SiraModal from '../SiraModal';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import MultiButton, { MultiButtonAction } from '../MultiButton';
import { errorMessages } from '../../utils/errorhandling.utils';

interface OrganizationStateInvestmentMaintenanceProps {
  financialOrganizationId?: string;
}

function OrganizationInvestmentMaintenance(
  props: OrganizationStateInvestmentMaintenanceProps
) {
  let isMounted = true;
  const { user } = useUser();
  const { organization, addGlobalMessage } = useGlobalContext();
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState(false);
  const [creatingInvestmentRate, setCreatingInvestmentRate] = useState(false);
  const [editingInvestmentRate, setEditingInvestmentRate] = useState(false);
  const [investmentRates, setInvestmentRates] = useState(
    [] as Array<InvestmentRate>
  );
  const [investmentRateIdToEdit, setInvestmentRateToEdit] = useState(
    {} as InvestmentRate
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({} as InvestmentRate);
  const { financialOrganizationId = '' } = props;
  const contentStyle = {
    height: '100%',
  };
  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 2,
  };


  const [selectedInterestRateId, setSelectedInterestRateId] = useState('');

  const useStyles = makeStyles(() => ({
    toolbarContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '15px',
    },
    exportButton: {
      backgroundColor: 'black', // Set your desired background color
      color: 'white', // Set your desired text color
      '&:hover': {
        backgroundColor: 'black', // Set your desired hover background color
      },
      padding: '5px',
    },
    ellipsisOverflow: {
      whiteSpace: 'nowrap' as any,
      overflow: 'hidden' as any,
      textOverflow: 'ellipsis' as any,
    },
  }));

  const classes = useStyles();

  async function getInvestmentData() {
    setIsLoading(true);
    getInvestmentRates(financialOrganizationId, user.token, {}, user)
      .then((res) => {
        if (isMounted) {
          const sortedInvestmentRates = res.data.sort((a, b) => {
            // Sorts first by active flag, then by the term
            // eslint-disable-next-line no-nested-ternary
            const activeFirst = a.active === b.active ? 0 : a.active ? -1 : 1;
            return activeFirst || Number(a.term) - Number(b.term);
          });
          setInvestmentRates(sortedInvestmentRates);
          setIsLoading(false);
        }
      })
      .catch((err) => {

        addGlobalMessage(errorMessages(err) || 'Error loading investment rates');
      });
  }

  useEffect(() => {
    if (user.token && financialOrganizationId) {
      getInvestmentData();
    }
    setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, [user.token, financialOrganizationId]);

  async function uploadInvestmentRateDocument(res, files, investmentRate) {
    let newInvestmentRates = [...investmentRates];

    saveInvestmentRateDocument(
      res.data.investmentRateId,
      files,
      financialOrganizationId,
      user.token,
      user
    )
      .then(() => {
        if (editingInvestmentRate) {
          newInvestmentRates = investmentRates.map((item) => {
            return item.investmentRateId === investmentRate.investmentRateId
              ? res.data
              : item;
          });
        } else {
          newInvestmentRates.push(res.data);
        }

        if (isMounted) {
          setInvestmentRates(newInvestmentRates);
          setEditingInvestmentRate(false);
          setCreatingInvestmentRate(false);
          setModalOpen(false);
          getInvestmentData();
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
  
 addGlobalMessage(errorMessages(err) || 'Error saving truth in savings document'
          );
        }
      });
  }

  async function addOrUpdateInvestmentRate(investmentRate: InvestmentRate) {
    const apiFunction = editingInvestmentRate
      ? updateInvestmentRate
      : createInvestmentRate;

    const mergedInvestmentRate = investmentRate;
    const files = mergedInvestmentRate.importFiles;

    // delete importfiles from the mergedInvestmentRate object
    delete mergedInvestmentRate.importFiles;

    await apiFunction(
      mergedInvestmentRate,
      financialOrganizationId,
      user.token,
      user
    )
      .then((res) => {
        if (files) {
          uploadInvestmentRateDocument(res, files, investmentRate);
        } else {
          let newInvestmentRates = [...investmentRates];

          if (editingInvestmentRate) {
            newInvestmentRates = investmentRates.map((item) => {
              return item.investmentRateId === investmentRate.investmentRateId
                ? res.data
                : item;
            });
          } else {
            newInvestmentRates.push(res.data);
          }

          if (isMounted) {
            setInvestmentRates(newInvestmentRates);
            setEditingInvestmentRate(false);
            setCreatingInvestmentRate(false);
            setModalOpen(false);
            getInvestmentData();
            addGlobalMessage('Changes saved successfully', {
              severity: 'success',
            });
          }
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
  
          addGlobalMessage(errorMessages(err) || 'Error saving investment rate');
        }
      });
  }

  async function openPdf() {
    await getInvestmentRateDocument(
      selectedInterestRateId,
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  async function deletePdf() {
    await deleteInvestmentRateDocument(
      selectedInterestRateId,
      user.organizationId,
      user.token,
      user
    )
      .then(() => {
        addGlobalMessage('Document deleted successfully', {
          severity: 'success',
        });
        getInvestmentData();
      })
      .catch(() => {
        addGlobalMessage('Error deleting the selected PDF document');
      });
  }

  const CustomGridToolbarContainer = () => {
    return (
      <GridToolbarContainer className={classes.toolbarContainer}>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          className={classes.exportButton}
        />
      </GridToolbarContainer>
    );
  };

  // Define the detail export types
  // Define the download types
  const pdfDetailsActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.pdf',
      handleAction: () => openPdf(),
      icon: <DownloadIcon />,
    },
    {
      label: 'Delete *.pdf',
      handleAction: () => deletePdf(),
      icon: <DeleteIcon />,
    },
  ];

  const columns = [
    {
      field: 'truthInSavingsDocument',
      headerName: 'TIS',
      description: 'Truth in Savings',
      width: 88,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          value !== '' && (
            <>
              <Grid container justifyContent="center">
                <Grid item mr={1}>
                  <MultiButton
                    data-testid="pdf-details"
                    size="small"
                    variant="outlined"
                    actions={pdfDetailsActions}
                    setAdditionalAction={() => {
                      setSelectedInterestRateId(params.row.investmentRateId);
                    }}
                    additionalAction
                  >
                    <PictureAsPdfIcon />
                  </MultiButton>
                </Grid>
              </Grid>
            </>
          )
        );
      },
    },
    {
      field: 'defaultInvestment',
      headerName: 'Default',
      width: 88,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <>
            <Grid container justifyContent="center">
              {value && <CheckIcon />}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 205,
      renderCell: (params: GridCellParams) => {
        const { value } = params;
        const description = value as string;
        return (
          <Box sx={{
            whiteSpace: 'nowrap' as any,
            overflow: 'hidden' as any,
            textOverflow: 'ellipsis' as any,
          }} title={description}>
            {description}
          </Box>
        );
      },
    },
    {
      field: 'term',
      headerName: 'Term',
      type: 'number',
      width: 75,
      sortable: false,
    },
    {
      field: 'minimumInvestment',
      headerName: 'Minimum',
      width: 115,
      type: 'number',
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'interestRate',
      headerName: 'Rate',
      type: 'number',
      width: 95,
      renderCell: (params: GridCellParams) => {
        const { value } = params as any;

        return (
          <NumberFormat
            displayType="text"
            fixedDecimalScale
            decimalScale={organization.investmentRatePrecision}
            isNumericString
            value={value}
            suffix="%"
          />
        );
      },
    },
    {
      field: 'apy',
      headerName: 'APY',
      type: 'number',
      width: 87,
      renderCell: (params: GridCellParams) => {
        const { value } = params as any;

        return (
          <NumberFormat
            displayType="text"
            fixedDecimalScale
            decimalScale={organization.apyRatePrecision}
            isNumericString
            value={value}
            suffix="%"
          />
        );
      },
    },
    {
      field: 'enrollmentFee',
      headerName: 'Enrollment Fee',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'annualFee',
      headerName: 'Annual Fee',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'earlyWithdrawalFee',
      headerName: 'Early Withdrawal Fee',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'earlyWithdrawalOption',
      headerName: 'Early Withdrawal option',
      width: 156,
    },
    {
      field: 'additionalFeedDescription',
      headerName: 'Additional Fee Description',
      width: 156,
    },
    {
      field: 'additionalFeedamount',
      headerName: 'Additional Fee Amount',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'compoundingFrequency',
      headerName: 'Compounding Frequency',
      width: 156,
    },
    {
      field: 'active',
      headerName: 'Active',
      width: 75,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <>
            <Grid container justifyContent="center">
              {value && <CheckIcon />}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 55,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <>
            <Grid container justifyContent="center">
              <IconButton
                data-testid="Edit Investment"
                size="small"
                aria-label="edit Investment"
                onClick={() => {
                  setInvestmentRateToEdit(row);
                  setEditingInvestmentRate(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
  ] as GridColDef[];

  const handleClose = () => {
    setModalOpen(false);
  };

  if (isLoading) {
    return (
      <Box width="1" pt={10} pb={10} textAlign="center">
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return editingInvestmentRate || creatingInvestmentRate ? (
    <>
      {' '}
      <InvestmentRateForm
        initialValues={{
          ...INVESTMENT_RATE_INIT,
          ...investmentRateIdToEdit,
        }}
        onSubmit={async (data) => {
          if (data.defaultInvestment) {
            setModalData(data);
            setModalOpen(true);
          } else await addOrUpdateInvestmentRate(data);
        }}
        onCancel={() => {
          setEditingInvestmentRate(false);
          setCreatingInvestmentRate(false);
        }}
      />
      <SiraModal open={modalOpen} handleClose={handleClose}>
        <>
          <Box sx={contentStyle} pt={2}>
            <Typography variant="body1">
              Change your default investment to this investment?
            </Typography>
            <Typography variant="body1">
              Note: the default investment should be the lowest rate you offer
              for IRAs{' '}
            </Typography>
          </Box>
          <Box sx={buttonContentStyle}>
            <Box pr={2}>
              <Button
                data-qa="delete-modal_cancel"
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                data-qa="delete-modal_confirm"
                variant="outlined"
                onClick={async () => {
                  await addOrUpdateInvestmentRate(modalData);
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </>
      </SiraModal>
    </>
  ) : (
    <Box width="1">
      <Box>
        <DataGridPro
          slots={{
            noRowsOverlay: SiraNoRowsOverlay,
            toolbar: CustomGridToolbarContainer,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: globalPageSize } },
            columns: {
              columnVisibilityModel: {
                enrollmentFee: false,
                annualFee: false,
                earlyWithdrawalFee: false,
                earlyWithdrawalOption: false,
                additionalFeedDescription: false,
                additionalFeedamount: false,
                coumpoundFrequency: false,
              },
            },
          }}
          pageSizeOptions={globalPaginationOptions}
          onPaginationModelChange={setGlobalPageSize}
          autoHeight
          columns={columns}
          rows={investmentRates}
          getRowId={(r) => r.investmentRateId}
        />
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setInvestmentRateToEdit({});
            setCreatingInvestmentRate(true);
          }}
        >
          Add Investment
        </Button>
      </Box>
    </Box>
  );
}

export default OrganizationInvestmentMaintenance;
