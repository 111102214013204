import React from "react";

interface TrimmedNumberProps {
  value: number | string;
  decimalPlaces: number;
}

const TrimmedNumber = (props: TrimmedNumberProps) => {
  const {
    value = 0,
    decimalPlaces = 2,
  } = props;

  return (
    <>
      {
        parseFloat(
          parseFloat(value.toString())
            .toFixed(decimalPlaces)
            .toString()
        ) // Parse rounded string to float which will trim unused decimals
      }
    </>
  );
};

export default TrimmedNumber;
