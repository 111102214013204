import React from 'react';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';

import { Beneficiaries, Beneficiary } from '../../api/BeneficiariesApi.d';
import { beneficiaryRelationshipOptions } from '../../app.constants';
import TrimmedNumber from '../TrimmedNumber';

interface SelectBeneficiaryListProps {
  beneficiaries: Beneficiaries;
  onSelect?: Function;
}

interface SelectBeneficiaryRow extends Beneficiary {
  id: number;
  fullName: string;
  type?: string;
}

function selectBeneficiaryRowsFactory(
  beneficiaries: Array<Beneficiary>
): Array<SelectBeneficiaryRow> {
  return beneficiaries.map((result: Beneficiary, id: number) => ({
    ...result,
    id,
    fullName: result.name || `${result.firstName} ${result.lastName}`,
  }));
}

function SelectBeneficiaryList(props: SelectBeneficiaryListProps) {
  const { beneficiaries = {} as Beneficiaries, onSelect = () => {} } = props;
  const { primaryBeneficiaries = [], secondaryBeneficiaries = [] } =
    beneficiaries;

  const selectButtonCss = {
    justifyContent: 'flex-start' as any,
    width: '100%',
    textDecoration: 'none' as any,
    textTransform: 'none' as any,
  };

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Beneficiary',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="select-beneficiary-button"
            onClick={() => {
              onSelect(row);
            }}
            sx={selectButtonCss}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'relationship',
      headerName: 'Relationship',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (
          beneficiaryRelationshipOptions.find(
            ({ value = '' }) => value === params.value
          ).label || params.value
        );
      },
    },
    {
      field: 'percent',
      headerName: 'Percent',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const percent = value.toString();

        return (
          <>
            <Box display="inline-block" mr={4}>
              <TrimmedNumber value={percent} decimalPlaces={2} />%
            </Box>
          </>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Beneficiary Type',
      width: 256,
    },
  ];

  const primaryRows = primaryBeneficiaries.map((result: Beneficiary) => ({
    ...result,
    type: 'Primary',
  }));
  const secondaryRows = secondaryBeneficiaries.map((result: Beneficiary) => ({
    ...result,
    type: 'Contingent',
  }));
  const rows = selectBeneficiaryRowsFactory([...primaryRows, ...secondaryRows]);

  return (
    <Box>
      <DataGrid
        rowHeight={75}
        disableColumnMenu
        hideFooter
        autoHeight
        columns={columns}
        rows={rows}
      />
    </Box>
  );
}

export default SelectBeneficiaryList;
