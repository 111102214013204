import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';

import AddressForm, {
  AddressFieldNames,
  ADDRESS_INIT,
  ADDRESS_SCHEMA,
} from '../AddressForm';
import { Beneficiary, BeneficiaryTypes } from '../../../api/BeneficiariesApi.d';
import PhoneNumberField, {
  PhoneNumberFormat,
  phoneValidation,
} from '../PhoneNumberField';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { Account } from '../../../api/AccountApi.d';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import { beneficiaryRelationshipOptions } from '../../../app.constants';
import SiraTextField from '../SiraTextField';
import StepButtonBar from '../../steps/StepButtonBar';
import { BeneficiariesClaimFiles } from './BeneficiariesClaimsForm.d';

export enum BeneficiaryPreferedContact {
  DEFAULT = '',
  MAIL = 'MAIL',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

const addressFieldNames: AddressFieldNames = {
  addressLine1: 'pcmAddress',
  city: 'pcmCity',
  state: 'pcmState',
  zip: 'pcmZip',
};

export const CONTACT_INFORMATION_INIT: BeneficiariesClaimFiles = {
  ...ADDRESS_INIT(addressFieldNames),
  files: null,
  firstName: '',
  lastName: '',
  pcmPhone: '',
  pcmEmail: '',
  relationshipToAccountOwner: BeneficiaryTypes.DEFAULT,
  preferredContactMethod: BeneficiaryPreferedContact.DEFAULT,
  otherRelationshipToAccountOwner: '',
};

export const beneficiaryPreferredContactOptions: Array<SiraSelectItem> = [
  { value: BeneficiaryPreferedContact.DEFAULT, label: '' },
  { value: BeneficiaryPreferedContact.MAIL, label: 'Mail' },
  { value: BeneficiaryPreferedContact.EMAIL, label: 'Email' },
  { value: BeneficiaryPreferedContact.PHONE, label: 'Phone' },
];

export const BENEFICIARY_SCHEMA = ADDRESS_SCHEMA({ addressFieldNames })
  .shape({
    firstName: yup
      .string()
      .max(40)
      .max(40)
      .when(
        'relationshipToAccountOwner',
        (relationshipToAccountOwner, schema) =>
          [
            BeneficiaryTypes.TRUST,
            BeneficiaryTypes.ESTATE,
            BeneficiaryTypes.NONEHUMAN,
          ].includes(relationshipToAccountOwner)
            ? schema
            : schema.required()
      )
      .label('First name'),
    lastName: yup
      .string()
      .max(40)
      .when(
        'relationshipToAccountOwner',
        (relationshipToAccountOwner, schema) =>
          [
            BeneficiaryTypes.TRUST,
            BeneficiaryTypes.ESTATE,
            BeneficiaryTypes.NONEHUMAN,
          ].includes(relationshipToAccountOwner)
            ? schema
            : schema.required()
      )
      .label('Last name'),
    pcmPhone: yup
      .string()
      .when('preferredContactMethod', (preferredContactMethod, schema) =>
        preferredContactMethod === BeneficiaryPreferedContact.PHONE
          ? phoneValidation(PhoneNumberFormat.unitedStates)
              .label('Phone Number')
              .required()
          : schema
      ),
    pcmEmail: yup
      .string()
      .email()
      .max(50)
      .label('Email')
      .when('preferredContactMethod', (preferredContactMethod, schema) =>
        preferredContactMethod === BeneficiaryPreferedContact.EMAIL
          ? schema.required()
          : schema
      ),
    relationshipToAccountOwner: yup
      .string()
      .nullable()
      .max(20)
      .required()
      .label('Relationship'),
    preferredContactMethod: yup
      .string()
      .nullable()
      .max(20)
      .required()
      .label('Preferred Contact Method'),
    otherRelationshipToAccountOwner: yup
      .string()
      .when(
        'relationshipToAccountOwner',
        (relationshipToAccountOwner, schema) =>
          relationshipToAccountOwner === BeneficiaryTypes.OTHER
            ? schema.required().label('Relationship Description')
            : schema
      ),
  })
  .shape(
    {
      [addressFieldNames.addressLine1]: yup
        .string()
        .when('preferredContactMethod', (preferredContactMethod, schema) =>
          preferredContactMethod === BeneficiaryPreferedContact.MAIL
            ? schema.required()
            : schema
        ),
    },
    [[addressFieldNames.addressLine1, addressFieldNames.zip]]
  );

export interface BeneficiaryFormProps {
  initialValues: Beneficiary;
  onSubmit?: (values: Beneficiary, formikHelpers?: any) => void;
  submitName?: string;
  resetName?: string;
  accountOwner?: AccountOwner;
  account?: Account;
}

function SetInformation(props) {
  const {
    isHumanBeneficairy,
    accountOwner = {},
    account = {},
    relationshipValues,
  } = props;
  const [firstName, setfirstName] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const { values } = useFormikContext();
  const { relationshipToAccountOwner } = values as Beneficiary;

  useEffect(() => {
    switch (relationshipToAccountOwner) {
      case BeneficiaryTypes.TRUST:
        setName('Trust Name');
        setfirstName('Trust Representative First Name');
        setLastName('Trust Representative Last Name');
        break;
      case BeneficiaryTypes.ESTATE:
        setName('Estate Name');
        setfirstName('Estate Representative First Name');
        setLastName('Estate Representative Last Name');
        break;
      case BeneficiaryTypes.NONEHUMAN:
        setName('Organization Name');
        setfirstName('Organization Rep First Name');
        setLastName('Organization Rep Last Name');
        break;
      default:
        setName('Relationship to Owner');
        setfirstName('First Name');
        setLastName('Last Name');
        break;
    }
  }, [relationshipToAccountOwner]);

  return (
    <>
      <Grid item xs={12}>
        <Grid item xs={12} sm={6}>
          <SiraSelectField
            items={beneficiaryRelationshipOptions}
            name="relationshipToAccountOwner"
            label="Relationship to Owner"
          />
        </Grid>
      </Grid>
      {[BeneficiaryTypes.OTHER].includes(
        relationshipValues.relationshipToAccountOwner
      ) && (
        <Grid item xs={12} sm={6}>
          <SiraTextField
            name="otherRelationshipToAccountOwner"
            label="Relationship Description"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid item xs={12} sm={6}>
          <SiraSelectField
            items={beneficiaryPreferredContactOptions}
            name="preferredContactMethod"
            label="Preferred Contact Method"
          />
        </Grid>
      </Grid>
      {!isHumanBeneficairy && (
        <>
          <Grid item xs={12} sm={6}>
            <SiraTextField name="name" label={name} />
          </Grid>
          <Grid item xs={0} sm={6} />
          <Grid item xs={12} sm={6}>
            <SiraTextField name="representativeFirstName" label={firstName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SiraTextField name="representativeLastName" label={lastName} />
          </Grid>
        </>
      )}
      {isHumanBeneficairy && (
        <>
          <Grid item xs={12} sm={5}>
            <SiraTextField name="firstName" label="First Name" />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SiraTextField name="lastName" label="Last Name" />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <PhoneNumberField name="pcmPhone" label="Phone Number" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SiraTextField name="pcmEmail" label="Email" />
      </Grid>
      <AddressForm
        accountOwner={accountOwner}
        account={account}
        allowAddressCopy
        addressFieldNames={addressFieldNames}
        copyType="BENE_CLAIMS"
      />
    </>
  );
}

function ContactInformationForm({
  initialValues,
  onSubmit,
  submitName,
  resetName,
  accountOwner = {},
  account = {},
}: BeneficiaryFormProps) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={BENEFICIARY_SCHEMA}
      enableReinitialize
    >
      {({ isSubmitting, values }) => {
        const isHumanBeneficairy = ![
          BeneficiaryTypes.ESTATE,
          BeneficiaryTypes.TRUST,
          BeneficiaryTypes.NONEHUMAN,
        ].includes(values.relationshipToAccountOwner);

        return (
          <Form>
            <Grid container spacing={3}>
              <SetInformation
                isHumanBeneficairy={isHumanBeneficairy}
                accountOwner={accountOwner}
                account={account}
                relationshipValues={values}
              />
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContactInformationForm;
