import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Paper,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  LinearProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import Alert from '@mui/material/Alert';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
  accountTransactionTypeNames,
  globalPaginationOptions,
} from '../../app.constants';
import {
  progressTransactionStatus,
  getTransactionDocument,
  dahsboardTransactionFactory,
  linkToTransaction,
} from '../../utils/transaction.utils';
import { useUser } from '../../auth/useUser';
import ObscuredTaxId, { TaxIdFormat } from '../ObscuredTaxId';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import {
  AccountStatus,
  AccountStatusState,
  TransactionStatus,
  TransactionType,
  WorkflowDates,
} from '../../api/AccountApi.d';
import TransactionPendingIcon from '../../icons/TransactionPending';
import TransactionSignatureIcon from '../../icons/TransactionSignature';
import TransactionAwaitingIcon from '../../icons/TransactionAwaiting';
import TransactionReview from '../../icons/TransactionReview';
import {
  getDashboardAccounts,
  getDashboardContributions,
  getDashboardDistributions,
  getDashboardBeneficiaries,
  getDashboardRecurringDistributions,
  getDashboardTransferRequests,
} from '../../api/OrganizationApi';
import TransferWorkflowForm from '../form/transfer/TransferWorkflowForm';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { UserRole } from '../../api/UserApi.d';
import { deleteApiCall, RowDefinition } from './dashboard.utils';
import { SiraDeleteModal } from '../SiraDeleteModal';
import PluralizedString from '../PluralizedString';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import {
  changeAccountStatus,
  getAccount,
  getAccountDocument,
} from '../../api/AccountApi';
import { ContributionStatusState } from '../../api/ContributionApi.d';
import {
  changeDistributionStatus,
  getDistributionDocument,
} from '../../api/DistributionApi';
import { DistributionStatusState } from '../../api/DistributionApi.d';
import {
  changeTransferRequestStatus,
  getTransferRequestDocument,
} from '../../api/TransferApi';
import { TransferRequestStatusState, Workflow } from '../../api/TransferApi.d';
import {
  changeRecurringDistributionStatus,
  getRecurringDistributionDocument,
} from '../../api/RecurringDistributionApi';
import {
  changeContributionStatus,
  getContributionDocument,
} from '../../api/ContributionApi';
import { getUsers } from '../../api/UserApi';
import SiraModal from '../SiraModal';
import ReviewDocumentForm from '../form/newAccount/ReviewDocumentForm';
import {
  completeTransaction,
  setSelectedAccount,
  useTransactionReducer,
} from '../../page/TransactionReducer';
import {
  changeBeneficiariesStatus,
  getBeneficiariesDocument,
} from '../../api/BeneficiariesApi';
import { IMMDocumentType } from '../../api/ESignApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';
import { BeneficiaryStatusState } from '../../api/BeneficiariesApi.d';
import { set } from 'cypress/types/lodash';

// Convert the 2-dimensional Promise.all arrays into one, flat array
function flattenTransactions(data: Array<any>) {
  return data.reduce((acc: Array<any>, cur: Array<any>) => {
    acc.push(...cur);
    return acc;
  }, []);
}

function PendingOrgTransactions() {
  let isMounted = true;
  const { user } = useUser();
  const navigate = useNavigate();
  const { getAppConstant, addGlobalMessage, organization, userList } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [transferToFinalize, setTransferToFinalize] = useState(null as any);
  const [pendingTransactions, setPendingTransactions] = useState(
    [] as Array<any>
  );
  const [awaitingSignatureTransactions, setAwaitingSignatureTransactions] =
    useState([] as Array<any>);
  const [pendingReviewTransactions, setPendingReviewTransactions] = useState(
    [] as Array<any>
  );
  const [submitOwnerTransactions, setSubmitOwnerTransactions] = useState(
    [] as Array<any>
  );
  const [awaitingTransferTransactions, setAwaitingTransferTransactions] =
    useState([] as Array<any>);
  const [modalData, setModalData] = useState({ body: '', title: '' });
  const [modalOpen, setmodalOpen] = useState(false);
  const [rowData, setrowData] = useState({} as RowDefinition);
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const [response, setResponse] = useState([] as any);
  const [pageState] = useTransactionReducer();
  const [rowTransactionName, setRowTransactionName] = useState(
    '' as TransactionType
  );
  const [rowIMMDocumentType, setRowIMMDocumentType] = useState(
    '' as IMMDocumentType
  );
  const [rowTransactionId, setRowTransactionId] = useState('');
  const [rowAccountId, setRowAccountId] = useState('');
  const [rowAccountOwnerId, setRowAccountOwnerId] = useState('');
  const [selectedRow, setSelectedRow] = useState({} as any);
  const [dashboardTransactionData, setDashboardTransactionData] = useState();
  const [fetchingStatus, setFetchingStatus] = useState({} as any);
  const { branches } = organization;

  const classes = {
    transactionLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  const [modalSignatureOpen, setModalSignatureOpen] = useState(false);

  const contentStyle = {
    height: '100%',
  };

  // Spit out a promise for every org transaction call
  function transactionsPromiseFactory(
    getMethod: Function,
    status: any,
    transactionType: TransactionType,
    accountStatus?: any
  ): Promise<any> {
    return getMethod(
      user.organizationId,
      status,
      user.token,
      accountStatus,
      user
    )
      .then((res) => {
        return dahsboardTransactionFactory(res.data, transactionType);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            `Error fetching ${status} org ${accountTransactionTypeNames[
              transactionType
            ].toLowerCase()}s`
        );
      });
  }

  // Get every org transaction in PENDING or AWAITING_SIGNATURE status
  async function getAndSetAllOrgWIPTransactions(): Promise<void> {
    setIsLoading(true);

    // Group all promises as one
    const wipTransactions: Array<any> = await Promise.all([
      transactionsPromiseFactory(
        getDashboardAccounts,
        [
          TransactionStatus.pending,
          TransactionStatus.signature,
          TransactionStatus.review,
          TransactionStatus.submitOwner,
        ],
        TransactionType.account
      ),
      transactionsPromiseFactory(
        getDashboardBeneficiaries,
        [
          TransactionStatus.pending,
          TransactionStatus.signature,
          TransactionStatus.review,
          TransactionStatus.submitOwner,
        ],
        TransactionType.beneficiaries,
        [AccountStatus.closed, AccountStatus.open]
      ),
      transactionsPromiseFactory(
        getDashboardContributions,
        [
          TransactionStatus.pending,
          TransactionStatus.signature,
          TransactionStatus.review,
          TransactionStatus.submitOwner,
        ],
        TransactionType.contribution,
        [AccountStatus.closed, AccountStatus.open]
      ),
      transactionsPromiseFactory(
        getDashboardDistributions,
        [
          TransactionStatus.pending,
          TransactionStatus.signature,
          TransactionStatus.review,
          TransactionStatus.submitOwner,
        ],
        TransactionType.distribution
      ),
      transactionsPromiseFactory(
        getDashboardRecurringDistributions,
        [
          TransactionStatus.pending,
          TransactionStatus.signature,
          TransactionStatus.review,
          TransactionStatus.submitOwner,
        ],
        TransactionType.recurringDistribution
      ),
      transactionsPromiseFactory(
        getDashboardTransferRequests,
        [
          TransactionStatus.pending,
          TransactionStatus.signature,
          TransactionStatus.awaiting,
          TransactionStatus.review,
          TransactionStatus.submitOwner,
        ],
        TransactionType.transfer
      ),
    ]).then(flattenTransactions);

    // Filter by status
    const pending = wipTransactions
      .filter(
        ({ transactionStatus }) =>
          transactionStatus === TransactionStatus.pending
      )
      .map((transaction, id) => ({
        ...transaction,
        id,
        updatedByUserId: transaction.lastSavedBy || transaction.updatedByUserId,
      }));

    const awaitingSignature = wipTransactions
      .filter(
        ({ transactionStatus }) =>
          transactionStatus === TransactionStatus.signature
      )
      .map((transaction, id) => ({
        ...transaction,
        id,
        updatedByUserId: transaction.lastSavedBy || transaction.updatedByUserId,
      }));

    const pendingReview = wipTransactions
      .filter(
        ({ transactionStatus }) =>
          transactionStatus === TransactionStatus.review
      )
      .map((transaction, id) => ({
        ...transaction,
        id,
        updatedByUserId: transaction.lastSavedBy || transaction.updatedByUserId,
      }));

    const awaitingTransfer = wipTransactions
      .filter(
        ({ transactionStatus }) =>
          transactionStatus === TransactionStatus.awaiting
      )
      .map((transaction, id) => ({
        ...transaction,
        id,
        updatedByUserId: transaction.lastSavedBy,
      }));

    const submitOwner = wipTransactions
      .filter(
        ({ transactionStatus }) =>
          transactionStatus === TransactionStatus.submitOwner
      )
      .map((transaction, id) => ({
        ...transaction,
        id,
        updatedByUserId: transaction.lastSavedBy || transaction.updatedByUserId,
      }));

    setIsLoading(false);

    if (isMounted) {
      setPendingTransactions(pending);
      setAwaitingSignatureTransactions(awaitingSignature);
      setPendingReviewTransactions(pendingReview);
      setAwaitingTransferTransactions(awaitingTransfer);
      setSubmitOwnerTransactions(submitOwner);
    }
  }

  async function changeTransactionStatus(
    data: any,
    changeStatus: string
  ): Promise<any> {
    const {
      accountId,
      accountOwnerId,
      contributionId,
      distributionId,
      transferRequestId,
      recurringDistributionId,
      transactionId,
    } = data;

    const status = changeStatus === 'next' ? 'next' : 'previous';

    switch (data.transactionType) {
      case TransactionType.account:
        changeAccountStatus(
          user.organizationId,
          data.accountId,
          data.accountOwnerId,
          AccountStatusState[status],
          {} as WorkflowDates,
          user.token,
          user
        )
          .then(() => {
            getAndSetAllOrgWIPTransactions();
          })
          .catch((err) => {
            addGlobalMessage(
              errorMessages(err) || `Error updating transaction status`
            );
          });
        break;
      case TransactionType.contribution:
        changeContributionStatus(
          user.organizationId,
          accountId,
          accountOwnerId,
          contributionId,
          ContributionStatusState[status],
          {} as WorkflowDates,
          user.token,
          user
        )
          .then(() => {
            getAndSetAllOrgWIPTransactions();
          })
          .catch((err) => {
            addGlobalMessage(
              errorMessages(err) || `Error updating transaction status`
            );
          });
        break;
      case TransactionType.distribution:
        changeDistributionStatus(
          user.organizationId,
          accountId,
          accountOwnerId,
          distributionId,
          DistributionStatusState[status],
          {} as WorkflowDates,
          user.token,
          user
        )
          .then(() => {
            getAndSetAllOrgWIPTransactions();
          })
          .catch((err) => {
            addGlobalMessage(
              errorMessages(err) || `Error updating transaction status`
            );
          });
        break;
      case TransactionType.transfer:
        changeTransferRequestStatus(
          user.organizationId,
          accountId,
          accountOwnerId,
          transferRequestId,
          TransferRequestStatusState[status],
          {} as Workflow,
          user.token
        )
          .then(() => {
            getAndSetAllOrgWIPTransactions();
          })
          .catch((err) => {
            addGlobalMessage(
              errorMessages(err) || `Error updating transaction status`
            );
          });
        break;
      case TransactionType.recurringDistribution:
        changeRecurringDistributionStatus(
          user.organizationId,
          accountId,
          accountOwnerId,
          recurringDistributionId,
          DistributionStatusState[status],
          {} as WorkflowDates,
          user.token,
          user
        )
          .then(() => {
            getAndSetAllOrgWIPTransactions();
          })
          .catch((err) => {
            addGlobalMessage(
              errorMessages(err) || `Error updating transaction status`
            );
          });
        break;
      case TransactionType.beneficiaries:
        changeBeneficiariesStatus(
          user.organizationId,
          accountId,
          accountOwnerId,
          transactionId.toString(),
          BeneficiaryStatusState.previous,
          {},
          user.token,
          user
        )
          .then(() => {
            getAndSetAllOrgWIPTransactions();
          })
          .catch((err) => {
            addGlobalMessage(
              errorMessages(err) || `Error updating transaction status`
            );
          });
        break;
      default:
        break;
    }
  }

  // assign IMM DocumentType
  const assignIMMDocumentType = (transactionType: TransactionType): void => {
    switch (transactionType) {
      case TransactionType.distribution:
        setRowIMMDocumentType(IMMDocumentType.distribution);
        break;
      case TransactionType.recurringDistribution:
        setRowIMMDocumentType(IMMDocumentType.recurringDistribution);
        break;
      case TransactionType.beneficiaries:
        setRowIMMDocumentType(IMMDocumentType.beneficiaries);
        break;
      case TransactionType.transfer:
        setRowIMMDocumentType(IMMDocumentType.transfer);
        break;
      case TransactionType.contribution:
        setRowIMMDocumentType(IMMDocumentType.contribution);
        break;
      case TransactionType.account:
        setRowIMMDocumentType(IMMDocumentType.account);
        break;
      default:
        break;
    }
  };
  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(
    accountId,
    accountOwnerId,
    row
  ): Promise<void> {
    await getAccount(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          setSelectedRow(row);
          setModalSignatureOpen(true);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Could not fetch the preselected account'
          );
        }
      });
  }
  // Handle transcation button click based on status or type
  const handleItemClick = (row): void => {
    const {
      accountId = '',
      accountOwnerId = '',
      transactionStatus,
      transactionType,
      transactionId,
    } = row;

    if (
      transactionType === TransactionType.transfer &&
      transactionStatus === TransactionStatus.awaiting
    ) {
      setTransferToFinalize(row);
    } else if (transactionStatus === TransactionStatus.submitOwner) {
      fetchAndSetAccount(accountId, accountOwnerId, row);
      setRowTransactionName(transactionType);
      setRowTransactionId(transactionId);
      setRowAccountId(accountId);
      setRowAccountOwnerId(accountOwnerId);
      assignIMMDocumentType(transactionType);
    } else {
      linkToTransaction(accountOwnerId, accountId, row, navigate);
    }
  };

  const viewDocument = async (): Promise<any> => {
    switch (rowTransactionName) {
      case TransactionType.account:
        return getAccountDocument(
          user.organizationId,
          rowAccountOwnerId,
          rowAccountId,
          user.token,
          user
        );
      case TransactionType.contribution:
        return getContributionDocument(
          user.organizationId,
          rowAccountOwnerId,
          rowAccountId,
          rowTransactionId,
          user.token,
          user
        );
      case TransactionType.distribution:
        return getDistributionDocument(
          user.organizationId,
          rowAccountOwnerId,
          rowAccountId,
          rowTransactionId,
          user.token,
          '',
          user
        );
      case TransactionType.transfer:
        return getTransferRequestDocument(
          user.organizationId,
          rowAccountOwnerId,
          rowAccountId,
          rowTransactionId,
          user.token,
          user,
        );
      case TransactionType.beneficiaries:
        return getBeneficiariesDocument(
          user.organizationId,
          rowAccountOwnerId,
          rowAccountId,
          rowTransactionId,
          user.token,
          '',
          user
        );
      case TransactionType.recurringDistribution:
        return getRecurringDistributionDocument(
          user.organizationId,
          rowAccountOwnerId,
          rowAccountId,
          rowTransactionId,
          user.token,
          '',
          user
        );
      default:
        return null;
    }
  };
  // handle pending item discard
  async function discardItemClick(): Promise<void> {
    await deleteApiCall(rowData, user.organizationId, user.token, user)
      .then((res) => {
        if (res.status === 200) {
          setmodalOpen(false);

          getAndSetAllOrgWIPTransactions();
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            `Error deleting ${accountTransactionTypeNames[
              rowData.transactionType
            ].toLowerCase()}s`
        );
        setmodalOpen(false);
      });
  }

  const openPdf = async (transaction: any): Promise<void> => {
    await getTransactionDocument(transaction, user)
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  };

  // Complete a given transaction and update the status
  async function completeReviewedTransaction(transaction: any): Promise<void> {
    if (
      [
        TransactionType.distribution,
        TransactionType.recurringDistribution,
        TransactionType.beneficiaries,
        TransactionType.contribution,
        TransactionType.account,
      ].includes(transaction.transactionType) &&
      transaction.transactionStatus === TransactionStatus.submitOwner
    ) {
      addGlobalMessage('Transaction review successfully completed', {
        severity: 'success',
      });
    } else {
      await progressTransactionStatus(transaction, user)
        .then(() => {
          addGlobalMessage('Transaction review successfully completed', {
            severity: 'success',
          });
        })
        .catch((err) => {
          addGlobalMessage(
            errorMessages(err) || `Error updating transaction status`
          );
        });
    }
  }

  useEffect(() => {
    if (userList.length > 0) {
      setResponse(userList);
    }

    return () => {
      isMounted = false;
    };
  }, [userList]);

  const buildData = (row) => {
    setrowData(row);
    setModalData({
      title: row.transactionType,
      body: `Do you want to delete this ${
        accountTransactionTypeNames[row.transactionType]
      } - ${getAppConstant(
        ConstantsMappingKey.accountType,
        row.accountType
      )} ?`,
    });
    setmodalOpen(true);
  };

  const handleClose = () => {
    setmodalOpen(false);
  };

  const handleSignatureClose = () => {
    setModalSignatureOpen(false);
  };

  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
  };

  useEffect(() => {
    if (response.length !== 0 && user.token && user.organizationId) {
      getAndSetAllOrgWIPTransactions();
    }
  }, [response]);

  const getAndSetlastSavedby = (value) => {
    let submittedBy;
    if (response) {
      response.forEach((userId) => {
        if (userId.auth0UserId === value) {
          submittedBy = userId;
        }
      }
      );
    }
    
    return submittedBy
      ? `${submittedBy.firstName} ${submittedBy.lastName}`
      : '';
  };
  const getAndSetorgName = (value) => {
    let submittedBy;
    if (response) {
      response.forEach((userId) => {
        if (userId.auth0UserId === value) {
          submittedBy = userId;
        }
      });
    }
    // find the branch id in the branches if the user was found in response
    const branchValues = branches.find(
      (branch) => branch.branchId === submittedBy?.branchId
    );
    return branchValues?.name || '';
  };

  const columns = [
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 185,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <Box sx={buttonContentStyle}>
            <Box pr={2}>
              <Button
                data-qa="pending-transaction-result_discard"
                variant="outlined"
                onClick={() => {
                  buildData(row);
                }}
                sx={{ whiteSpace: 'nowrap' }}
                startIcon={<DeleteIcon />}
              >
                Discard
              </Button>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'pdfReview',
      headerName: ' ',
      width: 50,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const isFetchingDocument = fetchingStatus.fetchingDocument || false;

        return (
          <>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item>
                <IconButton
                  disabled={isFetchingDocument}
                  color="primary"
                  size="small"
                  data-testid={`open-review-transaction-pdf_${row.transactionId}`}
                  aria-label="Open pdf document"
                  onClick={async () => {
                    setFetchingStatus((prevStatus) => ({
                      ...prevStatus,
                      [`open-review-transaction-pdf_${row.transactionId}_${row.transactionStatus}`]:
                        false,
                      fetchingDocument: true,
                    }));
                    await openPdf(row);
                    setFetchingStatus((prevStatus) => ({
                      ...prevStatus,
                      [`open-review-transaction-pdf_${row.transactionId}_${row.transactionStatus}`]:
                        true,
                      fetchingDocument: false,
                    }));
                  }}
                >
                  <PictureAsPdfIcon />
                </IconButton>{' '}
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: 'pdfDraftOrDiscard',
      headerName: ' ',
      width: 85,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const isFetchingDocument = fetchingStatus.fetchingDocument || false;

        return (
          <>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item>
                <Tooltip title="Discard">
                  <IconButton
                    disabled={isFetchingDocument}
                    color="primary"
                    size="small"
                    data-testid={`pending-transaction-result_discard_${row.transactionId}`}
                    aria-label="Open pdf document"
                    onClick={() => {
                      buildData(row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Draft">
                  <IconButton
                    disabled={isFetchingDocument}
                    color="primary"
                    size="small"
                    data-testid={`pending-transaction-result_draft_${row.transactionId}`}
                    onClick={() => {
                      changeTransactionStatus(row, 'previous')
                        .then(() => {
                          getAndSetAllOrgWIPTransactions();
                        })
                        .catch((err) => {
                          const { response: { data: errorData = {} } = {} } =
                            err;
                          addGlobalMessage(
                            errorData.message ||
                              `Error updating transaction status`
                          );
                        });
                    }}
                  >
                    <AssignmentReturnOutlinedIcon />{' '}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: 'pdfDone',
      headerName: ' ',
      width: 185,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const isReview =
          fetchingStatus[
            `open-review-transaction-pdf_${row.transactionId}_${row.transactionStatus}`
          ] || false;

        return (
          <>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item>
                <Button
                  startIcon={<CheckCircleIcon />}
                  disabled={
                    row.transactionStatus === TransactionStatus.submitOwner
                      ? false
                      : !isReview
                  }
                  color="success"
                  variant="contained"
                  size="small"
                  data-testid={`complete-review-transaction-pdf_${row.transactionId}`}
                  aria-label="Mark as reviewed"
                  onClick={async () => {
                    if (row.transactionStatus === AccountStatus.submitOwner) {
                      handleItemClick(row);
                    } else {
                      await completeReviewedTransaction(row);
                      getAndSetAllOrgWIPTransactions();
                    }
                  }}
                >
                  {row.transactionStatus === TransactionStatus.submitOwner
                    ? 'REVIEW & SIGN'
                    : 'Done'}
                </Button>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: 'transactionType',
      headerName: 'Type',
      width: 180,
      valueGetter: (value, row) => {
        const typeId = value;

        return value === TransactionType.account
          ? `${accountTransactionTypeNames[typeId] || value} - ${getAppConstant(
              ConstantsMappingKey.accountType,
              row.accountType
            )}`
          : accountTransactionTypeNames[typeId] || value;
      },
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        if (row.transactionStatus === AccountStatus.submitOwner) {
          return (
            <Box overflow="hidden" textOverflow="ellipsis">
              <>{value as React.ReactNode}</>
            </Box>
          );
        }
        return (
          <Button
            data-qa="pending-transaction-result_button"
            onClick={() => {
              handleItemClick(row);
            }}
            sx={classes.transactionLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value as React.ReactNode}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 110,
      valueGetter: (value) => {
        const time = value as string;
        if (time && !Number.isNaN(Date.parse(time))) {
          return format(parseISO(time), 'MM/dd/yyyy');
        }
        return '';
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueGetter: (value) => {
        const typeId = value;
        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'fullName',
      headerName: 'Owner',
      width: 185,
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 125,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    // {
    //   field: 'createdByUserId',
    //   headerName: 'Created By',
    //   width: 185,
    //   renderCell: (params: GridCellParams) => {
    //     const { value = '', row } = params;
    //     return (
    //       <Box overflow="hidden" textOverflow="ellipsis">
    //         {getAndSetlastSavedby(value)}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: 'lastSavedBy',
      headerName: 'Last Saved By',
      width: 185,
      renderCell: (params: GridCellParams) => {
        const { value = '', row } = params;
        return (
          <Box overflow="hidden" textOverflow="ellipsis">
            {getAndSetlastSavedby(value)}
          </Box>
        );
      },
    },
    {
      field: 'organizationName',
      headerName: 'Branch Name',
      sortable: false,
      width: 185,
      renderCell: (params: GridCellParams) => {
        const { row = '' } = params;
        return (
          <Box overflow="hidden" textOverflow="ellipsis">
            {getAndSetorgName(row.updatedByUserId)}
          </Box>
        );
      },
    },
  ] as GridColDef[];

  return (
    <Grid item xs={12}>
      <Paper elevation={3}>
        <Box p={2}>
          <Typography variant="h5">Pending Transactions</Typography>
          <Typography variant="body2" gutterBottom>
            {organization.legalName}
          </Typography>

          <Divider sx={{ mb: 2 }} />

          {!user.organizationId ? (
            <Box p={2} pt={5} pb={5}>
              <Alert severity="warning">
                Select an organization to see pending transactions
              </Alert>
            </Box>
          ) : (
            <>
              {/* PENDING */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  data-qa="pending-transaction-accordion-summary_draft"
                >
                  <TransactionPendingIcon sx={{ mr: 1 }} />
                  <Grid container wrap="nowrap" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="body1">Draft</Typography>
                    </Grid>
                    {!isLoading && (
                      <Grid item textAlign="right">
                        <Typography variant="caption" noWrap>
                          {pendingTransactions.length || 'No'}{' '}
                          <PluralizedString
                            noun="Row"
                            quantity={pendingTransactions.length}
                          />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {isLoading ? (
                    <Box width="1">
                      <LinearProgress color="secondary" />
                    </Box>
                  ) : (
                    <DataGridPro
                      columnVisibilityModel={{
                        pdfReview: false,
                        pdfDraftOrDiscard: false,
                        pdfDone: false,
                      }}
                      slots={{
                        noRowsOverlay: SiraNoRowsOverlay,
                      }}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'date', sort: 'desc' }],
                        },
                        pagination: {
                          paginationModel: { pageSize: globalPageSize },
                        },
                      }}
                      pageSizeOptions={[10]}
                      autoHeight
                      columns={columns}
                      rows={pendingTransactions}
                      pagination
                    />
                  )}
                </AccordionDetails>
              </Accordion>

              {/* AWAITING_SIGNATURE */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  data-qa="pending-transaction-accordion-summary_signature"
                >
                  <TransactionSignatureIcon sx={{ mr: 1 }} />
                  <Grid container wrap="nowrap" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Awaiting Signature
                      </Typography>
                    </Grid>
                    {!isLoading && (
                      <Grid item textAlign="right">
                        <Typography variant="caption" noWrap>
                          {awaitingSignatureTransactions.length || 'No'}{' '}
                          <PluralizedString
                            noun="Row"
                            quantity={awaitingSignatureTransactions.length}
                          />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {isLoading ? (
                    <Box width="1">
                      <LinearProgress color="secondary" />
                    </Box>
                  ) : (
                    <DataGridPro
                      columnVisibilityModel={{
                        pdfReview: false,
                        pdfDraftOrDiscard: false,
                        pdfDone: false,
                      }}
                      slots={{
                        noRowsOverlay: SiraNoRowsOverlay,
                      }}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'date', sort: 'desc' }],
                        },
                        pagination: {
                          paginationModel: { pageSize: globalPageSize },
                        },
                      }}
                      pageSizeOptions={[10]}
                      autoHeight
                      columns={columns}
                      rows={awaitingSignatureTransactions}
                      pagination
                    />
                  )}
                </AccordionDetails>
              </Accordion>

              {/* PENDING_REVIEW */}
              {user.roles.includes(UserRole.orgTransactionsAdmin) ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    data-qa="pending-transaction-accordion-summary_review"
                  >
                    <TransactionReview sx={{ mr: 1 }} />
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item xs={12}>
                        <Typography variant="body1">Pending Review</Typography>
                      </Grid>
                      {!isLoading ? (
                        <Grid item textAlign="right">
                          <Typography variant="caption" noWrap>
                            {pendingReviewTransactions.length || 'No'}{' '}
                            <PluralizedString
                              noun="Row"
                              quantity={pendingReviewTransactions.length}
                            />
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isLoading ? (
                      <Box width="1">
                        <LinearProgress color="secondary" />
                      </Box>
                    ) : (
                      <Grid container wrap="nowrap" alignItems="center">
                        <Grid item xs={12} md={12}>
                          <DataGridPro
                            slots={{
                              noRowsOverlay: SiraNoRowsOverlay,
                            }}
                            initialState={{
                              sorting: {
                                sortModel: [{ field: 'date', sort: 'desc' }],
                              },
                              pagination: {
                                paginationModel: { pageSize: globalPageSize },
                              },
                            }}
                            columnVisibilityModel={{
                              actions: false,
                            }}
                            pageSizeOptions={[10]}
                            autoHeight
                            columns={columns}
                            rows={pendingReviewTransactions}
                            pagination
                          />
                        </Grid>
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {/* AWAITING_TRANSFER */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  data-qa="pending-transaction-accordion-summary_transfer"
                >
                  <TransactionAwaitingIcon sx={{ mr: 1 }} />
                  <Grid container wrap="nowrap" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="body1">Awaiting Transfer</Typography>
                    </Grid>
                    {!isLoading && (
                      <Grid item textAlign="right">
                        <Typography variant="caption" noWrap>
                          {awaitingTransferTransactions.length || 'No'}{' '}
                          <PluralizedString
                            noun="Row"
                            quantity={awaitingTransferTransactions.length}
                          />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {isLoading ? (
                    <Box width="1">
                      <LinearProgress color="secondary" />
                    </Box>
                  ) : (
                    <>
                      {transferToFinalize ? (
                        <TransferWorkflowForm
                          transferRequest={transferToFinalize}
                          onSuccess={() => {
                            setTransferToFinalize(null);
                            getAndSetAllOrgWIPTransactions();
                          }}
                          onCancel={() => {
                            setTransferToFinalize(null);
                          }}
                        />
                      ) : (
                        <DataGridPro
                          slots={{
                            noRowsOverlay: SiraNoRowsOverlay,
                          }}
                          initialState={{
                            sorting: {
                              sortModel: [{ field: 'date', sort: 'desc' }],
                            },
                            pagination: {
                              paginationModel: { pageSize: globalPageSize },
                            },
                          }}
                          columnVisibilityModel={{
                            pdfDone: false,
                            actions: false,
                          }}
                          pageSizeOptions={[10]}
                          autoHeight
                          columns={columns}
                          rows={awaitingTransferTransactions}
                          pagination
                        />
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Submit Owner */}
              {organization.eSignWithSuperior &&
              user.roles.includes(UserRole.orgTransactionsAdmin) ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    data-qa="pending-transaction-accordion-summary_review"
                  >
                    <TransactionReview sx={{ mr: 1 }} />
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Submitted by Owner
                        </Typography>
                      </Grid>
                      {!isLoading && (
                        <Grid item textAlign="right">
                          <Typography variant="caption" noWrap>
                            {submitOwnerTransactions.length || 'No'}{' '}
                            <PluralizedString
                              noun="Row"
                              quantity={submitOwnerTransactions.length}
                            />
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isLoading ? (
                      <Box width="1">
                        <LinearProgress color="secondary" />
                      </Box>
                    ) : (
                      <DataGridPro
                        slots={{
                          noRowsOverlay: SiraNoRowsOverlay,
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'date', sort: 'desc' }],
                          },
                          pagination: {
                            paginationModel: { pageSize: globalPageSize },
                          },
                        }}
                        columnVisibilityModel={{
                          pdfReview: false,
                          organizationName: false,
                          lastSavedBy: false,
                          actions: false,
                        }}
                        pagination
                        pageSizeOptions={globalPaginationOptions}
                        onPaginationModelChange={setGlobalPageSize}
                        autoHeight
                        columns={columns}
                        rows={submitOwnerTransactions}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              ) : null}
            </>
          )}
        </Box>{' '}
        <SiraDeleteModal
          title={modalData.title}
          body={modalData.body}
          showPrompt={modalOpen}
          handleClose={handleClose}
          deleteTransaction={() => {
            discardItemClick();
          }}
        />
        <SiraModal open={modalSignatureOpen} handleClose={handleSignatureClose}>
          <>
            <Box sx={contentStyle}>
              <Typography variant="h5" color="secondary" gutterBottom>
                Review and Sign Document
              </Typography>
            </Box>
            <Box sx={contentStyle}>
              <ReviewDocumentForm
                searchResult={pageState.selectedAccount}
                allowSignature
                hideStepButtonBar={pageState.completed}
                getDocument={viewDocument}
                onEsignClick={completeTransaction}
                submitName="Done"
                onSubmit={async () => {
                  await completeReviewedTransaction(selectedRow);
                  getAndSetAllOrgWIPTransactions();
                  setModalSignatureOpen(false);
                }}
                documentType={rowIMMDocumentType}
                transactionName={rowTransactionName}
                transactionStatus={AccountStatus.submitOwner}
              />
            </Box>
          </>
        </SiraModal>
      </Paper>
    </Grid>
  );
}

export default PendingOrgTransactions;
