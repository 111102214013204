import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import StepButtonBar from '../../steps/StepButtonBar';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { User } from '../../../api/UserApi.d';
import SiraCheckbox from '../SiraCheckbox';
import { assignableUserRoles } from '../../../app.constants';
import { Role } from '../../../api/RoleApi.d';

export const USER_ROLE_INIT: User = {
  organizationRole: '',
  branchId: '',
  roles: [],
};

export const USER_ROLE_SCHEMA = {
  organizationRole: yup.string().max(100).label('Job Title'),
  branchId: yup.string().label('Branch'),
  roles: yup.array(yup.string()).label('Roles'),
};

export interface UserRoleFormProps {
  initialValues: User;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}



const UserRoleForm = ({
  initialValues,
  onSubmit = () => {},
  onReset = () => {},
  onCancel,
  submitName,
  resetName,
}: UserRoleFormProps) => {
  const { roles = [], organization } = useGlobalContext();

  // Get assignable roles based on the org's subscription level
  const userRoleOptions: Array<Role> = roles.filter(({ name = '' }) =>
    assignableUserRoles[organization.solution].includes(name)
  );



  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        onReset();
      }}
      validationSchema={yup.object(USER_ROLE_SCHEMA)}
      enableReinitialize
    >
      {({ isSubmitting }) => {
       return  <Form>
          <Box maxWidth={480}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {userRoleOptions.map((role) => (
                  <Box mt={1.5} key={role.name}>
                    <Field
                      name="roles"
                      value={role.name}
                      label={
                        <>
                          <span>{role.displayName || role.name}</span>
                          <br />
                          <Typography variant="subtitle2">
                            {role.parsedDescription}
                          </Typography>
                        </>
                      }
                      type="checkbox"
                      component={SiraCheckbox}
                    />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
          <StepButtonBar
            isSubmitting={isSubmitting}
            submitName={submitName}
            resetName={resetName}
            onCancel={onCancel}
          />
        </Form>
      }}
    </Formik>
  );
};

export default UserRoleForm;
