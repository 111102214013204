import axios from 'axios';

import {
  StateTaxDeadline,
  StateTaxDeadlineRequest,
  StatesResponse,
} from './StatesApi.d';
import { setBaseUrl } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const statesUrl = '/api/states';
const statesTaxUrl = '/state/taxFilingDeadline';

export async function getStatesInfo(token: string): Promise<StatesResponse> {
  const url = `${urlBase}${statesUrl}`;
  const statesFromSession = window.sessionStorage.getItem('states');

  if (statesFromSession) {
    return { data: JSON.parse(statesFromSession) };
  }

  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res: StatesResponse) => {
      if (res.data.length) {
        window.sessionStorage.setItem('states', JSON.stringify(res.data));
      }

      return res;
    });
}

export async function getStateTaxDeadline(
  taxYear: number,
  token: string,
  user: any
): Promise<StateTaxDeadlineRequest> {
  const url = `${urlBase}${setBaseUrl(user)}${statesTaxUrl}`;
  const params = { taxYear };

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getStateTaxDeadlineNoYear(
  token: string,
  user: any
): Promise<StateTaxDeadlineRequest> {
  const url = `${urlBase}${setBaseUrl(user)}${statesTaxUrl}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export async function createStateTaxDeadline(
  data: StateTaxDeadline,
  token: string,
  user: any
): Promise<StateTaxDeadlineRequest> {
  const url = `${urlBase}${setBaseUrl(user)}${statesTaxUrl}`;

  return axiosInstance.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteStateTaxDeadline(
  data: StateTaxDeadline,
  token: string,
  user: any
): Promise<StateTaxDeadlineRequest> {
  const url = `${urlBase}${setBaseUrl(user)}${statesTaxUrl}`;
  const params = { taxYear: data.taxYear, state: data.state };

  return axiosInstance.delete(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
