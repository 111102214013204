import React, { useEffect, useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import * as yup from 'yup';
import { Formik, Form, useFormikContext, Field } from 'formik';
import { parseISO } from 'date-fns';

import {
  accountStatusOptions,
  beneficiaryRelationshipOptions,
} from '../../../app.constants';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraDateField, { dateValidation } from '../SiraDateField';
import { AccountStatus, Account, AccountType } from '../../../api/AccountApi.d';
import SiraSelectField from '../SiraSelectField';
import SiraTextField from '../SiraTextField';
import SiraSwitchField from '../SiraSwitchField';
import {
  eligibleDesignatedBeneficiary,
  fiveYearTest,
} from '../distribution/resource.txt';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraCheckbox from '../SiraCheckbox';
import { useUser } from '../../../auth/useUser';
import { UserRole } from '../../../api/UserApi.d';

export const ACCOUNT_DETAILS_EDIT_INIT: Account = {
  accountNumber: '',
  accountStatus: AccountStatus.open,
  openDate: new Date().toISOString().slice(0, 10),
  closedDate: '',
  eligibleDesignatedBeneficiary: false,
  fiveYearTest: false,
  relationship: '',
  excludeTaxForm1099: false,
  excludeTaxForm5498: false,
};

export const ACCOUNT_DETAILS_EDIT_SCHEMA = yup.object({
  accountNumber: yup.string().max(30).label('Account Number'),
  accountStatus: yup.string().required().label('Account Status'),
  openDate: dateValidation().label('Account Open Date'),
  closedDate: yup.string().when(['accountStatus', 'openDate'], (...args) => {
    const [accountStatus, openDate, schema] = args;

    return accountStatus === AccountStatus.closed
      ? dateValidation(parseISO(openDate))
          .required()
          .label('Account Closed Date')
      : schema;
  }),
  eligibleDesignatedBeneficiary: yup.boolean(),
});

export interface AccountEditDetailsFormProps {
  initialValues?: Account;
  onSubmit?: Function;
  onCancel?: Function;
  submitName?: string;
}

function ClosedDateField() {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const { accountStatus } = values as Account;
  const { accountNumber: initialAccountNumber, openDate: initialOpenDate } =
    initialValues as Account;

  useEffect(() => {
    if (accountStatus !== AccountStatus.closed) {
      setFieldValue('closedDate', '');
    } else {
      setFieldValue('accountNumber', initialAccountNumber);
      setFieldValue('openDate', initialOpenDate);
    }
  }, [accountStatus]);

  return (
    accountStatus === AccountStatus.closed && (
      <Grid item xs={12}>
        <SiraDateField name="closedDate" label="Closed Date" />
      </Grid>
    )
  );
}

function AccountDetailsEditForm({
  initialValues,
  onSubmit = () => {},
  onCancel = () => {},
  submitName = 'Submit',
}: AccountEditDetailsFormProps) {
  const { organization } = useGlobalContext();
  const { user } = useUser();
  const [showExclude, setShowExclude] = useState(false);

  // set showExclude based on user role
  useEffect(() => {
    setShowExclude(user?.roles.includes(UserRole.multi));
  }, [user]);

  return (
    <Formik
      initialValues={{
        ...ACCOUNT_DETAILS_EDIT_INIT,
        ...initialValues,
      }}
      validationSchema={ACCOUNT_DETAILS_EDIT_SCHEMA}
      onSubmit={async (data) => {
        await onSubmit(data);
      }}
    >
      {({ isSubmitting, values }) => {
        const isInherited = [
          AccountType.inheritedRoth,
          AccountType.inheritedTraditional,
        ].includes(values.accountType);
        const isFiveYearTest = [
          AccountType.inheritedRoth,
          AccountType.rothIra,
        ].includes(values.accountType);
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SiraTextField
                  disabled={values.accountStatus === AccountStatus.closed}
                  name="accountNumber"
                  label={
                    organization.accountNumberValue ===
                    AccountMemberValue.accountNumber
                      ? 'Account Number'
                      : 'Member Number'
                  }
                />
              </Grid>
              {organization.planNumberEnabled ? (
                <Grid item xs={12}>
                  <SiraTextField
                    data-testid="planNumber"
                    name="planNumber"
                    label="Plan Number"
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <SiraSelectField
                  items={accountStatusOptions}
                  name="accountStatus"
                  label="Account Status"
                />
              </Grid>
              <Grid item xs={12}>
                <SiraDateField
                  disabled={values.accountStatus === AccountStatus.closed}
                  name="openDate"
                  label="Open Date"
                />
              </Grid>
              <ClosedDateField />
              {isInherited && (
                <>
                  <Grid item xs={12}>
                    <SiraSelectField
                      items={beneficiaryRelationshipOptions}
                      name="relationship"
                      label="Relationship to Deceased Owner"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Tooltip
                          title={eligibleDesignatedBeneficiary}
                          aria-describedby="eligible-Designated-Beneficiary-description"
                        >
                          <Box>
                            <SiraSwitchField
                              name="eligibleDesignatedBeneficiary"
                              checked={values.eligibleDesignatedBeneficiary}
                            />
                          </Box>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Typography>
                          is eligible designated beneficiary
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {showExclude ? (
                <>
                  {' '}
                  <Grid item xs={12}>
                    <Field
                      name="excludeTaxForm1099"
                      label="Exclude from 1099 Tax Reporting"
                      type="checkbox"
                      component={SiraCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="excludeTaxForm5498"
                      label="Exclude from 5498 Tax Reporting"
                      type="checkbox"
                      component={SiraCheckbox}
                    />
                  </Grid>
                </>
              ) : null}

              {isFiveYearTest && (
                <Grid item xs={12}>
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Tooltip
                        title={fiveYearTest}
                        disableFocusListener
                        disableTouchListener
                        aria-describedby="five-Year-Test-description"
                      >
                        <Box>
                          <SiraSwitchField
                            name="fiveYearTest"
                            checked={values.fiveYearTest}
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Typography>
                        five-year holding period has been satisfied
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default AccountDetailsEditForm;
