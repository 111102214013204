import { RadioGroupOption } from '../SiraRadioField';
import { AccountType } from '../../../api/AccountApi.d';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { getTransferAfterContentDisplay } from './resource.txt';

function getTransferRequestSourceOptions(
  getAppConstant: Function,
  destinationAccountType: AccountType,
  isEarlyDistribution: boolean
): Array<RadioGroupOption> {
  const sourceOptions = [];

  const sourceRadioOptions = {
    traditional: {
      value: AccountType.traditionalIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalIra
      ),
      afterContent: getTransferAfterContentDisplay(
        isEarlyDistribution,
        destinationAccountType,
        AccountType.traditionalIra
      ),
    },
    inheritedTrad: {
      value: AccountType.inheritedTraditional,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.inheritedTraditional
      ),
      afterContent: getTransferAfterContentDisplay(
        isEarlyDistribution,
        destinationAccountType,
        AccountType.inheritedTraditional
      ),
    },
    simple: {
      value: AccountType.simpleIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.simpleIra
      ),
      afterContent: getTransferAfterContentDisplay(
        isEarlyDistribution,
        destinationAccountType,
        AccountType.simpleIra
      ),
    },
    roth: {
      value: AccountType.rothIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.rothIra
      ),
      afterContent: getTransferAfterContentDisplay(
        isEarlyDistribution,
        destinationAccountType,
        AccountType.rothIra
      ),
    },
    sep: {
      value: AccountType.traditionalSep,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalSep
      ),
    },
    inheritedRoth: {
      value: AccountType.inheritedRoth,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.inheritedRoth
      ),
    },
    employer: {
      value: AccountType.employer,
      label: 'Employer-sponsored retirement plan',
      afterContent: getTransferAfterContentDisplay(
        isEarlyDistribution,
        destinationAccountType,
        AccountType.employer
      ),
    },
    hsa: {
      value: AccountType.hsa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.hsa),
      afterContent: getTransferAfterContentDisplay(
        isEarlyDistribution,
        destinationAccountType,
        AccountType.hsa
      ),
    },
    acmsa: {
      value: AccountType.msa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.msa),
      afterContent: getTransferAfterContentDisplay(
        isEarlyDistribution,
        destinationAccountType,
        AccountType.msa
      ),
    },
    esa: {
      value: AccountType.esa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.esa),
      afterContent: getTransferAfterContentDisplay(
        isEarlyDistribution,
        destinationAccountType,
        AccountType.esa
      ),
    },
  };

  if (
    [AccountType.traditionalIra, AccountType.simpleIra].includes(
      destinationAccountType
    )
  ) {
    sourceOptions.push(
      sourceRadioOptions.traditional,
      sourceRadioOptions.simple,
      sourceRadioOptions.employer
    );

    if(destinationAccountType === AccountType.traditionalIra) {
      sourceOptions.push(sourceRadioOptions.sep);
    }

  }

  if ([AccountType.traditionalSep].includes(destinationAccountType)) {
    sourceOptions.push(sourceRadioOptions.sep);
  }

  if ([AccountType.rothIra].includes(destinationAccountType)) {
    sourceOptions.push(
      sourceRadioOptions.traditional,
      sourceRadioOptions.simple,
      sourceRadioOptions.roth,
      sourceRadioOptions.employer
    );
  }

  if ([AccountType.inheritedTraditional].includes(destinationAccountType)) {
    sourceOptions.push(
      sourceRadioOptions.traditional,
      sourceRadioOptions.inheritedTrad,
      sourceRadioOptions.simple,
      sourceRadioOptions.employer
    );
  }

  if ([AccountType.inheritedRoth].includes(destinationAccountType)) {
    sourceOptions.push(
      sourceRadioOptions.roth,
      sourceRadioOptions.inheritedRoth,
      sourceRadioOptions.employer
    );
  }

  if ([AccountType.hsa].includes(destinationAccountType)) {
    sourceOptions.push(
      sourceRadioOptions.hsa,
      sourceRadioOptions.traditional,
      sourceRadioOptions.roth,
      sourceRadioOptions.acmsa
    );
  }

  if ([AccountType.esa].includes(destinationAccountType)) {
    sourceOptions.push(sourceRadioOptions.esa);
  }

  return sourceOptions;
}

export default getTransferRequestSourceOptions;
