import { DistributionReason } from './DistributionApi.d';

export interface TaxForm {
  account: number;
  accountOwnerId: number;
  accountId: number;
  city: string;
  distributionAmount: number;
  distributionReason: DistributionReason;
  federalStateCode: string;
  federalTaxWithheld: number;
  firstPayeeNameLine: string;
  foreignCountryIndicator: boolean;
  formType: TaxFormType | string;
  id: number;
  iraIndicator: boolean;
  mailingAddress: string;
  nameControl: string;
  state: string;
  stateIncomeTaxWithheld: number;
  stateTaxId: string;
  taxId: number;
  taxYear: number;
  taxableAmount: number;
  taxableAmountNotDetermined: boolean;
  totalDistributionIndicator: boolean;
  uniqueId: string;
  withholdingState: string;
  zipCode: string;
  dateFiledWithIrs: string;
  dateSentToOwner: string;
  corrected: string;
  trusteeToTrusteeTransfer: boolean;
  designatedBeneficiary: boolean;
  basis: string;
  fairMarketValue: string;
  dateOfDeathAccountBalance: string;
  pdfGenerated: boolean;
  legalName: string;
  formattedAddress: string;
  year: number;
  sentToOwnerBy: string;
}

export interface TaxForm5498 {
  id: number;
  accountId: number;
  formattedAddress: string;
  esaContributionAmount: string;
  esaRolloverAmount: string;
  iraContributionAmount: number;
  iraRolloverAmount: number;
  iraRothConversionAmount: number;
  iraRothContributionAmount: number;
  iraRecharacterizedAmount: number;
  fmvAmount: number;
  iraSepAmount: number;
  iraSimpleAmount: number;
  rmdAmount: number;
  rmdDate: string;
  postponedContributions: number;
  repayments: number;
  year: number;
  taxYear: number;
  pdfGenerated: boolean;
  taxId: number;
  tinType: string;
  account: number;
  accountOwnerId: number;
  accountId: number;
  firstPayeeNameLine: string;
  legalName: string;
  foreignCountryIndicator: boolean;
  formType: Tax5498FormType | string;
  id: number;
  mailingAddress: string;
  nameControl: string;
  city: string;
  state: string;
  taxId: number;
  taxYear: number;
  year: number;
  uniqueId: string;
  zipCode: string;
  dateFiledWithIrs: string;
  dateSentToOwner: string;
  corrected: string;
  pdfGenerated: boolean;
  postponedCode: string;
  postponedYear: number;
  hsaPriorYearContributionAmount: number;
  hsaRolloverAmount: number;
  hsaContributionAmount: number;
  sentToOwnerBy: string;
}

export interface Totals1099Page {
  taxFormType: TaxFormType | string;
  payeeCount: number;
  distributionTotal: number;
  payeeCount: number;
  federalTaxWithheld: number;
  stateTaxWithheld: number;
  stateTaxesWithheld: Array<{
    state: string;
    federalTax?: number;
    stateTax?: number;
    distributionAmount?: number;
  }>;
}

export interface StateTax {
  stateTaxesWithheld: Array<{
    state: string;
    federalTax?: number;
    stateTax?: number;
    distributionTotal?: number;
  }>;
}

export interface Totals1099PageResponse {
  data?: { taxFormSummaries: Array<Totals1099Page> };
  errorMessage?: any;
}

export interface Import5498StatusResponse {
  data?: any;
  errorMessage?: any;
}

export interface TaxFormResponse {
  data?: Array<TaxForm>;
  errorMessage?: any;
}

export interface TaxForm5498Response {
  data?: Array<TaxForm5498>;
  errorMessage?: any;
}

export interface TaxFormInfoResponse {
  data?: TaxForm;
  errorMessage?: any;
}

export interface TaxForm5498InfoResponse {
  data?: TaxForm5498;
  errorMessage?: any;
  status?: number;
}

export enum TaxFormType {
  R = '1099-R',
  Q = '1099-Q',
  SA = '1099-SA',
}

export enum Tax5498FormType {
  IRA = 'IRA',
  ESA = 'ESA',
  HSA = 'HSA',
}

export const taxFormTypeDescription = {
  [TaxFormType.R]: '1099-R',
  [TaxFormType.Q]: '1099-Q',
  [TaxFormType.SA]: '1099-SA',
};
