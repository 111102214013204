/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import AccountSearchForm from '../components/form/accountSearch/AccountSearchForm';
import { AccountOwnerSearchResponse } from '../api/AccountOwnerApi.d';
import AccountSearchResults from '../components/form/accountSearch/AccountSearchResults';
import { useGlobalContext } from '../auth/useGlobalContext';
import AccountSavedSearch from './AccountSavedSearch';
import { useUser } from '../auth/useUser';

function AccountSearch() {
  const navigate = useNavigate();
  const [response, setResponse] = useState({});
  const { user } = useUser();

  const { organization } = useGlobalContext();
  const { query = '' } = response as AccountOwnerSearchResponse;
  const [isStoredSession, setisStoredSession] = useState(
    JSON.parse(sessionStorage.getItem('recentSearchSelections')) !== null
  );
  const [storedSessionValues, setstoredSessionValues] = useState(
    JSON.parse(sessionStorage.getItem('recentSearchSelections'))
  );
  const [isSearchExpanded, setisSearchExpanded] = useState(false);

  useEffect(() => {
    const getStored = JSON.parse(
      sessionStorage.getItem('recentSearchSelections')
    );
    setstoredSessionValues(getStored);
  }, []);

  useEffect(() => {
    if (query !== '') {
      setisStoredSession(false);
    }
    setisSearchExpanded(true);
  }, [query]);

  const setSessionedStorage = (result): void => {
    const { accountId } = result;

    if (!accountId) {
      return;
    }

    const storing = [
      {
        userEmail: user.email,
        organization: user.organizationId,
        accountIds: [],
      },
    ];

    let recentItems = JSON.parse(
      sessionStorage.getItem('recentSearchSelections')
    );

    if (recentItems) {
      const existingOrgEntry = recentItems.find(
        (entry) =>
          entry.organization === user.organizationId &&
          entry.userEmail === user.email
      );

      const isDupDataEntry = recentItems.find((entry) =>
        entry.accountIds.includes(result.accountId)
      );

      if (existingOrgEntry && !isDupDataEntry) {
        existingOrgEntry.accountIds.unshift(accountId);
      } else {
        storing[0].accountIds.unshift(accountId);
        recentItems.push(storing[0]);
      }
    } else {
      recentItems = storing;
      recentItems[0].accountIds.unshift(accountId);
    }

    sessionStorage.setItem(
      'recentSearchSelections',
      JSON.stringify(recentItems)
    );
  };

  const goToAccountProfile = (resultRow): void => {
    const { accountId, accountOwnerId } = resultRow || {};
    setSessionedStorage(resultRow);

    if (accountOwnerId && accountId) {
      navigate(`/accountOwner/${accountOwnerId}/account/${accountId}`);
    }

    if (accountId === '' || accountId === undefined) {
      navigate(`/accountOwner/${accountOwnerId}`);
    }
  };

  const setSessionExpansion = (e, expanded: boolean): void => {
    setisStoredSession(expanded);
  };

  const setSearchExpansion = (e, expanded: boolean): void => {
    setisSearchExpanded(expanded);
  };

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Account Search
          </Typography>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box mt={3} mb={5}>
                <Box pb={2}>
                  <Typography variant="body2">
                    Search Account Owners{' '}
                    {organization && `at ${organization.name}`}
                  </Typography>
                </Box>
                <AccountSearchForm setResponse={setResponse} />
              </Box>
            </Grid>
          </Grid>
          <Box mt={0.5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Accordion
                               slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
                  onChange={setSessionExpansion}
                  expanded={isStoredSession}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      Recent Searches
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isStoredSession && (
                      <AccountSavedSearch
                        storedSession={storedSessionValues}
                        onResultClick={goToAccountProfile}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
          <Box mt={5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Accordion
                               slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
                  onChange={setSearchExpansion}
                  expanded={isSearchExpanded}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      Search Results{' '}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AccountSearchResults
                      response={response as AccountOwnerSearchResponse}
                      onResultClick={goToAccountProfile}
                      showImportAccounts
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default AccountSearch;
