import React, { useEffect, useState } from 'react';
import { FieldArray, FieldAttributes, useFormikContext } from 'formik';
import {
  Box,
  Grid,
  Typography,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';

import { useGlobalContext } from '../../auth/useGlobalContext';
import {
  formatBytes,
  setImageIcon,
} from './beneficiariesClaims/resource.utils';
import SiraTextField from './SiraTextField';

export interface Accept {
  [key: string]: string[];
}

export interface FileFieldProps {
  onDropField?: Function;
  multipleFiles?: boolean;
  name?: string;
  acceptedFileTypes?: Accept;
  acceptedFilesText?: string;
  description?: boolean;
  descriptionField?: string;
  resetState?: string;
}

const SiraFileField: React.FC<FieldAttributes<FileFieldProps>> = (props) => {
  const {
    description = false,
    multipleFiles = false,
    name,
    onDropField = () => {},
    acceptedFileTypes = {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    acceptedFilesText = 'pdf, png, jpg and jpeg',
    descriptionField,
    resetState = '',
  } = props;
  const { values, errors, setFieldValue } = useFormikContext();
  const [preview, setPreview] = useState([]);
  const [uploaded, setUploaded] = useState<Array<File>>([]);
  const filePreview: Array<string> = [];

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: acceptedFileTypes,
      multiple: multipleFiles,
    });
  const { addGlobalMessage } = useGlobalContext();

  useEffect(() => {
    setUploaded([]);
    setPreview([]);
  }, [resetState]);
  useEffect(() => {
    const docs: Array<File> =
      acceptedFiles.length > 0 ? acceptedFiles : values[name];
    if (docs && docs[0] && docs.length > 0) {
      docs.forEach((file) => {
        filePreview.push(URL.createObjectURL(file));
      });

      setPreview(filePreview);
      setUploaded(docs);
      onDropField(docs);
    }
  }, [values[name], acceptedFiles]);

  useEffect(() => {
    const rejected = fileRejections;
    if (rejected.length > 0) {
      addGlobalMessage(
        `${rejected[0].errors[0].message}, please add only one file.`
      );
    }
  }, [fileRejections]);

  const removefile = (id) => {
    const files = values[name].filter((pr, idx) => idx !== id);
    setFieldValue(name, files);
    acceptedFiles.splice(id, 1);
    setPreview((currentPreview) =>
      currentPreview.filter((pr, idx) => idx !== id)
    );
    setUploaded((currentDocs) => currentDocs.filter((pr, idx) => idx !== id));
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          cursor: 'pointer',
          position: 'relative',
          border: '2px dashed #999999',
          textAlign: 'center',
          minHeight: '150px',
          margin: '0 0 16px 0',
          backgroundColor: '#EEEEE',
        }}
      >
        <Box {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Box pt={1}>
            <Typography variant="body1">Drag `n` drop file here</Typography>
            <Typography variant="body1" pt={1}>
              or
            </Typography>
            <Box pt={1} pb={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={open}
                sx={{ paddingLeft: '15px ' }}
              >
                Choose File
              </Button>
            </Box>
            <Typography variant="body1">
              <em>Accepted Files: {acceptedFilesText}</em>
            </Typography>
          </Box>
        </Box>
      </Box>
      {errors[name] && (
        <Typography variant="body2" color="error">
          {errors[name]}
        </Typography>
      )}
      {uploaded !== null &&
        uploaded.length > 0 &&
        (!description ? (
          <ImageList sx={{ width: 420, height: 250 }} cols={3} rowHeight={164}>
            {uploaded.map((file, id) => {
              return (
                <ImageListItem key={file.name}>
                  {['png', 'jpeg', 'jpg'].includes(
                    file.name.split('.').pop()
                  ) ? (
                    <img
                      alt=""
                      src={preview[id]}
                      onLoad={() => {
                        URL.revokeObjectURL(preview[id]);
                      }}
                    />
                  ) : (
                    setImageIcon(file.name)
                  )}
                  <ImageListItemBar
                    title={file.name}
                    subtitle={formatBytes(file.size)}
                    actionIcon={
                      <IconButton
                        onClick={() => {
                          removefile(id);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                    actionPosition="right"
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        ) : (
          <Box sx={{ minHeight: 200, overflow: 'auto', maxWidth: 480 }}>
            {uploaded.map((file, id) => {
              return (
                <FieldArray name={descriptionField}>
                  {() => {
                    return (
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Box maxWidth={100}>
                            {['png', 'jpeg', 'jpg'].includes(
                              file.name.split('.').pop()
                            ) ? (
                              <img
                                width="100%"
                                alt=""
                                src={preview[id]}
                                onLoad={() => {
                                  URL.revokeObjectURL(preview[id]);
                                }}
                              />
                            ) : (
                              <Box>{setImageIcon(file.name)}</Box>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            textOverflow="ellipsis"
                            noWrap
                            variant="body2"
                            title={file.name}
                            gutterBottom
                          >
                            {file.name}
                          </Typography>
                          <SiraTextField
                            name={`${descriptionField}[${id}.description]`}
                            label="Description"
                          />
                        </Grid>
                      </Grid>
                    );
                  }}
                </FieldArray>
              );
            })}
          </Box>
        ))}
    </Grid>
  );
};

export default SiraFileField;
