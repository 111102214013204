import React from 'react';
import {
  Box,
  Divider,
  Fade,
  IconButton,
  Modal,
  ModalProps,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

interface SiraModalProps extends ModalProps {
  handleClose: Function;
  title?: string;
}

function SiraModal(props: SiraModalProps) {
  const { children, open, handleClose = () => {}, title = '' } = props;
  const containerStyle = {
    position: 'absolute' as any,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    maxWidth: 480,
    height: 'auto',
    maxHeight: '66%',
    bgcolor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 2,
    box: 42,
    p: 4,
    overflow: 'auto',
  };
  const closeButtonStyle = {
    position: 'fixed' as any,
    top: 0,
    right: 0,
  };
  const contentStyle = {
    height: '100%',
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box sx={containerStyle}>
            <Box sx={closeButtonStyle}>
              <IconButton
                data-qa="close-modal_button"
                color="primary"
                onClick={() => {
                  handleClose();
                }}
                aria-label="close modal"
                size="large"
              >
                <CancelIcon />
              </IconButton>
            </Box>
            <Box sx={contentStyle}>
              {title && (
                <>
                  <Typography variant="h6" component="h2">
                    {title}
                  </Typography>
                  <Box pt={2} pb={2}>
                    <Divider />
                  </Box>
                </>
              )}
              {children}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default SiraModal;
