import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';

import { getContributionLimit } from '../api/AccountOwnerApi';
import { useUser } from '../auth/useUser';
import { ContributionLimitType, DepositType } from '../api/ContributionApi.d';
import { AccountType } from '../api/AccountApi.d';

interface ContributionLimitProps {
  amount?: number;
  accountOwnerId?: string;
  taxYear?: number;
  accountType?: AccountType; // HSA or IRA/ROTH  // base display on this only show if ...
  depositType?: DepositType; // postponed regular or prior year
}

interface ContributionLimitMessageProps {
  amount?: number;
  limitType: ContributionLimitType;
  maxAmount: number;
  taxYear: number;
}

function ContributionLimitMessage(props: ContributionLimitMessageProps) {
  const { limitType, maxAmount, taxYear, amount } = props;
  const formattedAmount = amount && amount !== 0 ? maxAmount + amount : maxAmount;
  const formattedMaxAmount = (
    <NumberFormat
      value={formattedAmount}
      prefix="$"
      displayType="text"
      thousandSeparator
      isNumericString
      fixedDecimalScale
      decimalScale={2}
    />
  );

  // Look up a special mesage component based on the type passed
  const specialMessage = {
    [ContributionLimitType.hsaFamily]: (
      <Typography variant="body2" gutterBottom>
        Maximum remaining family contribution for {taxYear} is{' '}
        {formattedMaxAmount}
      </Typography>
    ),
    [ContributionLimitType.hsaSelf]: (
      <Typography variant="body2" gutterBottom>
        Maximum remaining self-only contribution for {taxYear} is{' '}
        {formattedMaxAmount}
      </Typography>
    ),
  }[limitType];


  // Or default if one isn't found
  const defaultMessage = (
    <Typography variant="body2" gutterBottom>
      Maximum remaining contribution for {taxYear} is {formattedMaxAmount}
    </Typography>
  );

  return specialMessage || defaultMessage;
}

/**
 * If you are making a regular deposit or a postponed deposit into an HSA, IRA, ROTH_IRA or
 * ESA (coverdall) there is a year limit.  Currently the max for HSA depends on family status,
 * IRA depends on age, all limits may span multiple accounts so instead of that logic being
 * here we call the server. The API returns the remaining limit based on year and account
 * owner.  Then this component displays it.
 *
 * Nothing is displayed for non-HSA/IRA/ESA or for non-regular/postponed deposit types.
 */
function ContributionLimit(props: ContributionLimitProps) {
  let isMounted = true;
  const { accountOwnerId, taxYear, accountType, depositType, amount } = props;
  const [contributionMaxMap, setContributionMaxMap] = React.useState({} as any);
  const { user } = useUser();
  const [serverError, setServerError] = React.useState(false);
  const { setFieldValue } = useFormikContext();


  // Map out the account type to a list of limit message types to display
  const accountLimitTypesMap = {
    [AccountType.traditionalIra]: [ContributionLimitType.ira],
    [AccountType.rothIra]: [ContributionLimitType.ira],
    [AccountType.hsa]: [
      ContributionLimitType.hsaFamily,
      ContributionLimitType.hsaSelf,
    ],
    [AccountType.esa]: [ContributionLimitType.esa],
  };

  // Did we select a depositType that has a limit?
  const limitedDepositType = [
    DepositType.postponed,
    DepositType.regular,
    DepositType.regularPrior,
    DepositType.qualifiedHSA
  ];

  const hasContributionLimit =
    accountType in accountLimitTypesMap &&
    limitedDepositType.includes(depositType);

  useEffect(() => {
    setContributionMaxMap({});
    setFieldValue('maxRemainingAmount', null);
    setServerError(false);
    if (
      accountOwnerId &&
      taxYear &&
      accountType &&
      depositType &&
      hasContributionLimit
    ) {
      getContributionLimit(
        accountOwnerId,
        taxYear,
        user.organizationId,
        user.token,
        user
      )
        .then((response) => {
          if (isMounted) {
              const formattedAmount = amount &&
                amount !== 0
                  ? response.data[accountLimitTypesMap[accountType][0]] + amount
                  : response.data[accountLimitTypesMap[accountType][0]];

            setContributionMaxMap(response.data);
            setFieldValue(
              'maxRemainingAmount',
              formattedAmount.toFixed(2)
            );
          }
        })
        .catch(() => {
          if (isMounted) {
            setServerError(true);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [accountOwnerId, taxYear, accountType, depositType]);

  return (
    <>
      {hasContributionLimit && (
        <Alert severity={serverError ? 'warning' : 'info'}>
          {serverError
            ? 'Unable to calculate maximum contribution'
            : accountLimitTypesMap[accountType].map(
                (limitType: ContributionLimitType) => (
                  <ContributionLimitMessage
                    amount={amount}
                    limitType={limitType}
                    taxYear={taxYear}
                    maxAmount={contributionMaxMap[limitType]}
                  />
                )
              )}
        </Alert>
      )}
    </>
  );
}

export default ContributionLimit;
