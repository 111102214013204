import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Alert,
  Paper,
  Divider,
  Button,
} from '@mui/material';
import { formatISO, subDays } from 'date-fns';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useUser } from '../../auth/useUser';
import { Task, TaskStatus } from '../../api/TaskApi.d';
import {
  getAssignedTasks,
  getAvailableUnassignedTasks,
  updateTask,
} from '../../api/TaskApi';
import { useGlobalContext } from '../../auth/useGlobalContext';
import TaskDetail from './TaskDetail';
import { User, UserRole } from '../../api/UserApi.d';
import { getUsers } from '../../api/UserApi';

interface TaskStatusFilterProps {
  onItemClick: Function;
  statusFilter: Array<TaskStatus>;
}

interface TaskNoResultsProps {
  isLoading: boolean;
}

function TaskNoResults(props: TaskNoResultsProps) {
  const { isLoading = false } = props;

  return !isLoading ? (
    <Box width="1" textAlign="center" p={3}>
      <Typography variant="body2">No tasks found</Typography>
    </Box>
  ) : null;
}

function TaskStatusFilter(props: TaskStatusFilterProps) {
  const { onItemClick = () => {}, statusFilter } = props;

  return (
    <Box mt={3} mb={1}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body2">Filter by:</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(TaskStatus.complete)
                ? 'contained'
                : 'outlined'
            }
            color="success"
            startIcon={<CheckCircleIcon />}
            size="small"
            onClick={() => {
              onItemClick([TaskStatus.complete]);
            }}
          >
            Complete
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(TaskStatus.incomplete)
                ? 'contained'
                : 'outlined'
            }
            color="warning"
            startIcon={<PendingIcon />}
            size="small"
            onClick={() => {
              onItemClick([TaskStatus.incomplete]);
            }}
          >
            To-Do
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function TaskList() {
  let isMounted = true;
  const { organization, addGlobalMessage, userList } = useGlobalContext();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [taskList, setTaskList] = useState<Array<Task>>([]);
  const [statusFilter, setStatusFilter] = useState<Array<TaskStatus>>([]);

  // Filter the batch info by status
  const filteredTaskList = statusFilter.length
    ? taskList.filter(({ status }) => statusFilter.includes(status))
    : taskList;

  const getTasks = async (): Promise<void> => {
    const oneWeekAgo = subDays(new Date(), 7);
    let promiseCalls = [];
    // Get tasks assigned to the current, single-org user (hide completed older than one week)
    // Gets assigned tasks across users if multi-org (multi-org users also can't be assignees)
    const getMyTasksPromise = getAssignedTasks(
      user.organizationId,
      {
        completedAfter: formatISO(oneWeekAgo).slice(0, 10),
      },
      user.token,
    );

    promiseCalls.push(getMyTasksPromise);

    if (!user.roles.includes(UserRole.multi)) {
      // Get any tasks that haven't been assigned yet and can be reassigned by the current user
      const getAssignableTasksPromise = getAvailableUnassignedTasks(
        user.organizationId,
        user.token,
      );
      promiseCalls.push(getAssignableTasksPromise);
    }

    await Promise.all(promiseCalls)
      .then((responses) => {
        // sort task by due date latest date first to oldest date last
        const [myTaskData, unassignedTaskData] = responses;
        // Show unassigned after tasks assigned to current user
        let combinedTaskData = [...myTaskData.data];
        if(unassignedTaskData && unassignedTaskData.data.length > 0){
          // add the additional task
          combinedTaskData = [...combinedTaskData, ...unassignedTaskData.data]        }
        const sortedTaskData = combinedTaskData.sort((a, b) => {
          return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        });

        if (isMounted) {
          // Show unassigned after tasks assigned to current user
          setTaskList(sortedTaskData);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
          setTaskList([]);
        }
      });
  };

  

  const saveTask = async (task): Promise<void> => {
    setIsLoading(true);

    await updateTask(task, user.organizationId, task.toDoListId, user.token)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          const { data: newTask } = res;
          // Swap out the ID-matched task with the one in the PUT response
          const newTaskList = taskList.map((existingTask) =>
            task.toDoListId === existingTask.toDoListId
              ? newTask
              : existingTask,
          );
          setTaskList(newTaskList);
        }
      })
      .catch(() => {
        addGlobalMessage('Unable to update task');
      });
  };

  const handleTaskStatusClick = (taskStatuses: Array<TaskStatus>) => {
    if (taskStatuses.some((status) => statusFilter.includes(status))) {
      setStatusFilter([] as Array<TaskStatus>);
    } else {
      setStatusFilter(taskStatuses);
    }
  };

  useEffect(() => {
    if (userList.length > 0) {
      getTasks();
    }

    return () => {
      isMounted = false;
    };
  }, [userList]);

  return (
    <Grid item xs={12}>
      <Paper elevation={3}>
        <Box p={2}>
          <Typography variant="h5">Tasks</Typography>
          <Typography variant="body2" gutterBottom>
            {organization.legalName}
          </Typography>

          <Divider sx={{ mb: 2 }} />

          <Box mb={3}>
            <TaskStatusFilter
              onItemClick={handleTaskStatusClick}
              statusFilter={statusFilter}
            />
          </Box>

          {!user.organizationId ? (
            <Box p={2} pt={5} pb={5}>
              <Alert severity="warning">
                Select an organization to see task list
              </Alert>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {filteredTaskList.length ? (
                filteredTaskList.map((task) => (
                  <Grid item xs={12} key={`batch_${task.toDoListId}`}>
                    <TaskDetail
                      isLoading={isLoading}
                      saveTask={saveTask}
                      task={task}
                      users={userList}
                    />
                  </Grid>
                ))
              ) : (
                <TaskNoResults isLoading={isLoading} />
              )}
            </Grid>
          )}
        </Box>
      </Paper>
    </Grid>
  );
}

export default TaskList;
