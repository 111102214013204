import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import { ListItemIcon, MenuItem } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface MultiButtonAction {
  label: string;
  handleAction: Function;
  icon?: React.ReactElement<any>;
}

export interface MultiButtonProps extends ButtonProps {
  actions: Array<MultiButtonAction>;
  setAdditionalAction?: Function;
  additionalAction?: boolean;
}

const ActionMenu = (props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
);

export default function MultiButton(props: MultiButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    actions,
    children = '',
    setAdditionalAction,
    additionalAction = false,
  } = props;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (additionalAction) {
      setAdditionalAction();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (evt, callbackFunction) => {
    setAnchorEl(null);
    // Sometimes MUI returns a string as callbackFunction
    if (callbackFunction instanceof Function) {
      callbackFunction(evt);
    }
  };

  return (
    <div>
      <Button
        id="menu-button"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        {...props}
      >
        {children}
      </Button>
      <ActionMenu
        id="menu"
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions.map(({ label, handleAction, icon }) => {
          return (
            <MenuItem
              key={label}
              id={label}
              onClick={(evt) => handleClose(evt, handleAction)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              {label}
            </MenuItem>
          );
        })}
      </ActionMenu>
    </div>
  );
}
