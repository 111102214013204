import React from 'react';
import NumberFormat from 'react-number-format';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import PluralizedString from '../PluralizedString';

export interface ResponsivePercentBarProps {
  legend: string;
  quantity: number;
  noun?: string;
  percent: number;
  isNested?: boolean; // Boolean to flag if charting a sub statistic (show smaller bar, etc.)
  vertical?: boolean;
}

function ResponsivePercentBar(props: ResponsivePercentBarProps) {
  const theme = useTheme();
  const {
    legend = '',
    quantity = 0,
    percent,
    isNested = false,
    noun = 'account',
    vertical = false,
  } = props;

  return vertical ? (
    <Grid container zIndex={1} wrap="nowrap" alignItems="center">
      <Grid item xs={12} textAlign="center">
        <Box
          bgcolor={theme.palette.secondary.light}
          height={`${!Number.isNaN(percent) ? percent : 0}px`}
          zIndex={0}
          width={1}
        />

        <Divider />

        <Grid item xs={12} textAlign="center" mt={1}>
          {!Number.isNaN(percent) && (
            <Typography variant="caption">
              <NumberFormat
                suffix="%"
                value={percent}
                displayType="text"
                decimalScale={1}
              />
            </Typography>
          )}
          <Typography variant="body2" fontWeight="bold">
            {legend.includes('Owners') ? `${legend} in ${new Date().getFullYear()}` : legend}
          </Typography>
          <Typography variant="caption" noWrap>
            {quantity} <PluralizedString quantity={quantity} noun={noun} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container zIndex={1} wrap="nowrap" alignItems="center">
      <Grid item xs={6} sm={3}>
        <Typography variant="body2" fontWeight="bold">
        {legend.includes('Owners') ? `${legend} in ${new Date().getFullYear()}` : legend}
        </Typography>
        <Typography variant="caption" noWrap>
          {quantity} <PluralizedString quantity={quantity} noun={noun} />
        </Typography>
      </Grid>

      <Grid item xs={6} sm={9} ml={2}>
        <Box
          bgcolor={theme.palette.secondary.light}
          width={`${!Number.isNaN(percent) ? percent : 0}%`}
          zIndex={0}
          height={isNested ? '0.5em' : '1.25em'}
          minWidth="1px"
          mt={isNested ? 0.66 : 0}
          mb={isNested ? 0.66 : 0.3}
        />

        {!Number.isNaN(percent) && (
          <Typography variant="caption">
            <NumberFormat
              suffix="%"
              value={percent}
              displayType="text"
              decimalScale={1}
            />
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default ResponsivePercentBar;
