import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';

import {
  DistributionMethod,
  Distribution,
} from '../../../api/DistributionApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraTextField from '../SiraTextField';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import {
  accountTypeSelection,
  distributionMethodLogic,
} from './distributionMethod.util';
import { AccountType } from '../../../api/AccountApi.d';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import SiraDateRangeField from '../SiraDateRangeField';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import { useTransactionReducer } from '../../../page/TransactionReducer';
import { BeneficiaryTypes } from '../../../api/BeneficiariesApi.d';

export const DISTRIBUTION_METHOD_SCHEMA = {
  distributionMethod: yup.string().required().label('Distribution Method'),
  toAccountNumber: yup
    .string()
    .label('Account Number')
    .when('distributionMethod', (distributionMethod, schema) =>
      [DistributionMethod.direct, DistributionMethod.transfer].includes(
        distributionMethod
      )
        ? schema.required()
        : schema
    ),
  toAccountType: yup
    .string()
    .nullable()
    .label('Account Type')
    .when('distributionMethod', (distributionMethod, schema) =>
      [DistributionMethod.direct, DistributionMethod.transfer].includes(
        distributionMethod
      )
        ? schema.required()
        : schema
    ),
  toFinancialOrganization: yup
    .string()
    .label('Organization Name')
    .when('distributionMethod', (distributionMethod, schema) =>
      distributionMethod === DistributionMethod.transfer
        ? schema.required()
        : schema
    ),
  toAccountTypeName: yup
    .string()
    .label('Account Type')
    .when('toAccountType', (toAccountType, schema) =>
      toAccountType === AccountType.other ? schema.required() : schema
    ),
    routingNumber: yup
    .string()
    .max(9)
    .label('Routing Number'),
};

export const DISTRIBUTION_METHOD_INIT: Distribution = {
  distributionMethod: DistributionMethod.default,
  toAccountNumber: '',
  toAccountType: '' as AccountType,
  toFinancialOrganization: '',
  toAccountTypeName: '',
  routingNumber: '',
  payableTo: '',
};

export interface DistributionMethodFormProps {
  initialValues: Distribution;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  distributionReason?: string;
  accountType?: string;
  distributionType?: string;
  showDateAndStatus?: boolean;
  relationship?: BeneficiaryTypes;
}

const enum RecurringDistributionStatus {
  active = 'ACTIVE',
  inActive = 'INACTIVE',
}

export const recurringDistributionOptions: Array<SiraSelectItem> = [
  { value: RecurringDistributionStatus.active, label: 'Active' },
  { value: RecurringDistributionStatus.inActive, label: 'InActive' },
];

function DistributionMethodForm({
  initialValues = DISTRIBUTION_METHOD_INIT,
  onSubmit = () => {},
  onReset = () => {},
  onCancel,
  submitName,
  resetName,
  distributionReason,
  accountType,
  distributionType,
  showDateAndStatus,
  relationship=BeneficiaryTypes.DEFAULT,
}: DistributionMethodFormProps) {
  const { organization, getAppConstant, customerPortalUser } = useGlobalContext();
  const [hasInitialized, setHasInitialized] = useState(false as boolean);

  const distributionMethodOptions: Array<RadioGroupOption> =
    distributionMethodLogic( 
      distributionReason,
      organization,
      accountType,
      distributionType, customerPortalUser
    );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => onReset()}
      validationSchema={yup.object(DISTRIBUTION_METHOD_SCHEMA)}
    >
      {({ isSubmitting, values, setValues }) => {
        const isTransfer = [
          DistributionMethod.direct,
          DistributionMethod.transfer,
        ].includes(values.distributionMethod);
        const isExternalTransfer =
          values.distributionMethod === DistributionMethod.transfer;
        const isOtherAccount = values.toAccountType === AccountType.other;
        const isChecking =
          values.distributionMethod === DistributionMethod.check;
        
          useEffect(() => {
          if (hasInitialized) {
            setValues({
              ...values,
              toAccountNumber: '',
              toAccountType: '' as AccountType,
              toFinancialOrganization: '',
              toAccountTypeName: '',
              payableTo: '',
              routingNumber: '',
            });
          } else {
            setHasInitialized(true);
          }
        }, [values.distributionMethod]);

        return (
          <Form>
            {showDateAndStatus && (
              <Grid
              container
              spacing={2}
              alignItems="center"
              pt={1}
              pb={2}
            >
              <SiraDateRangeField />
            </Grid>)}
            <Grid container spacing={3}>
            {showDateAndStatus && (
                <>
                  <Grid item xs={12} sm={5}>
                    <SiraSelectField
                      items={recurringDistributionOptions}
                      name='distributionStatus'
                      label="Distribution Status"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Field
                  name="distributionMethod"
                  options={distributionMethodOptions}
                  component={SiraRadioField}
                />
              </Grid>
              {isChecking && (
                <>
                  <Grid item xs={12}>
                    <SiraTextField name="payableTo" label="Check Payable To" />
                  </Grid>
                </>
              )}
              {isExternalTransfer && (
                <>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField
                      name="toFinancialOrganization"
                      label="Organization Name"
                    />
                  </Grid>
                </>
              )}
              {isTransfer && (
                <>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField
                      name="toAccountNumber"
                      label={ organization.accountNumberValue ===
                        AccountMemberValue.accountNumber
                          ? 'Account Number'
                          : 'Member Number'}
                    />
                  </Grid>
                  {isExternalTransfer && (
                    <Grid item xs={12} sm={6}>
                      <SiraTextField
                        name="routingNumber"
                        label="Routing Number"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field
                      label="Account Type"
                      name="toAccountType"
                      options={accountTypeSelection(
                        getAppConstant,
                        accountType,
                        distributionReason,
                        relationship
                      )}
                      component={SiraRadioField}
                    />
                  </Grid>
                  {isOtherAccount && (
                    <Grid item xs={12} sm={6}>
                      <SiraTextField
                        name="toAccountTypeName"
                        label="Account Type"
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default DistributionMethodForm;
