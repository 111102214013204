import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import {
  useTheme,
  Alert,
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
  IconButton,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import moneyFormatter from '../../../moneyFormatter';
import { useUser } from '../../../auth/useUser';
import { getInvestmentRates } from '../../../api/InvestmentRateApi';
import { InvestmentRate, Allocation } from '../../../api/InvestmentRateApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import AllocationPieDisplay from './AllocationPieDisplay';
import SiraCurrencyField from '../SiraCurrencyField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { AccountContribution } from '../../../api/ContributionApi.d';
import SiraTextField from '../SiraTextField';
import { roundNumber } from '../../../utils/App.utils';
import { AccountOwner, SymitarAccount } from '../../../api/AccountOwnerApi.d';

import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import SiraPercentField from '../SiraPercentField';
import { getAccount } from '../../../api/AccountApi';
import { set } from 'cypress/types/lodash';

export interface ContributionAllocationFormData {
  allocations: Array<Allocation>;
}

interface InvestmentAllocation {
  investmentRateId: number | null;
  contributionId: number;
  investmentNumber: string;
  coreInvestmentId: string;
  amount: number;
}

export interface InvestmentAllocations {
  investmentAllocations: InvestmentAllocation[];
}

export interface ContributionAllocationFormProps {
  existingAllocations: Array<Allocation>;
  contributionInfo: AccountContribution;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
  accountOwnerId?: string;
  symitarDTO?: SymitarAccount;
  newAccount?: boolean;
}

export const ALLOCATION_INIT: Array<Allocation> = [];

function ContributionAllocationForm({
  existingAllocations = [],
  contributionInfo,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
  accountOwnerId,
  newAccount = false,
}: ContributionAllocationFormProps) {
  let isMounted = { current: true };
  const theme = useTheme();
  const [allocations, setAllocations] = useState(existingAllocations);
  const [investmentRates, setInvestmentRates] = useState([] as Array<any>);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const { organization, addGlobalMessage } = useGlobalContext();
  const { amount = 0 } = contributionInfo;
  const [addNewInvestment, setAddnewInvestment] = useState(false);
  const [investmentAllocationDropdown, setInvestmentAllocationDropdown] =
    useState([] as any);
  const [selectedInvestment, setSelectedInvestment] = useState({} as any);
  const [superiorInvestmentRates, setSuperiorInvestmentRates] = useState(
    [] as Array<any>,
  );
  const classes = {
    tableLabel: {
      marginBottom: '1.5rem',
    },
    tableCell: {
      borderBottom: 'none',
      borderRight: '1px solid grey',
    },
    headCell: {
      paddingTop: 0,
      borderBottom: 'none',
      borderRight: '1px solid grey',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    lastTableCell: {
      borderBottom: 'none',
    },
    lastHeadCell: {
      paddingTop: 0,
      borderBottom: 'none',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  };

  const CONTRIBUTION_ALLOCATION_SCHEMA = yup.object().shape({
    allocations: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup.string(),
          investmentNumber: yup.string(),
        }),
      )
      .required()
      .test(
        'total',
        `The sum of all allocation amounts must equal $${amount}`,
        (allocs = []) => {
          const totalAlloc = allocs.reduce((total, allocation) => {
            return roundNumber(total) + (Number(allocation.amount) || 0);
          }, 0);

          return totalAlloc === amount;
        },
      ),
  });

  // Get the accountOwner for the account loaded
  const updateAccountOwner = async (): Promise<AccountOwner> => {
    setIsLoading(true);
    let accountOwner = {} as AccountOwner;

    await await getAccount(
      contributionInfo.accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
    )
      .then((res) => {
        if (isMounted) {
          accountOwner = res.data;
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return accountOwner;
  };
  // Trims 0 contribution allocations and adds fields that aren't in the form data
  function contributionAllocationsFactory(
    formAllocations: Array<Allocation>,
  ): Array<Allocation> {
    // for any symitar account, we need to add new fields to the data before sending it to the server
    if (organization.coreIntegration === 'SYMITAR') {
      return investmentRates
        .concat(superiorInvestmentRates)
        .reduce((acc: Array<Allocation>, rate: InvestmentRate, index) => {
          const {
            amount: allocationAmount = '',
            investmentNumber = '',
            type = '',
            id,
          } = formAllocations[index] || {};
          const { investmentRateId = '' } = rate || {};

          if (allocationAmount) {
            let rateId;

            const foundCoreDefaultShareID = superiorInvestmentRates.find(
              (x) => x.coreShareDefaultId === type,
            );

            if (type) {
              rateId = foundCoreDefaultShareID.investmentRateId;
            } else {
              rateId = investmentRateId;
            }

            acc.push({
              amount: allocationAmount,
              investmentRateId: !newAccount ? rateId : investmentRateId,
              contributionId: contributionInfo.contributionId,
              investmentNumber,
              coreShareId: investmentNumber,
            } as Allocation);
          }

          return acc;
        }, []);
    }

    return investmentRates.reduce(
      (acc: Array<Allocation>, rate: InvestmentRate, index) => {
        const { amount: allocationAmount = '', investmentNumber = '' } =
          formAllocations[index] || {};
        const { investmentRateId = '' } = rate || {};

        if (investmentNumber || allocationAmount) {
          acc.push({
            amount: allocationAmount,
            investmentNumber,
            investmentRateId,
          } as Allocation);
        }

        return acc;
      },
      [],
    );
  }

  // Maps incoming allocations to their respective investment rate
  function mapSymitarInitialAllocations(rates: Array<any>): Array<any> {
    return rates.map((rate) => {
      const { amount: allocationAmount = 0, investmentNumber = rate.id } =
        allocations.find(({ id }) => rate.id === id) || {};

      return {
        amount: allocationAmount,
        investmentNumber,
        investmentRateId: rate.id,
        ...rate,
      } as any;
    });
  }

  // Maps incoming allocations to their respective investment rate
  function mapInitialAllocations(
    rates: Array<InvestmentRate>,
  ): Array<Allocation> {
    return rates.map((rate) => {
      const { amount: allocationAmount = 0, investmentNumber = '' } =
        allocations.find(
          ({ investmentRateId }) => rate.investmentRateId === investmentRateId,
        ) || {};

      // minimum Deposit retunring $NaN, so we need to check if it is a number if not return 0
      const minimumDeposit =
        typeof rate.minimumInvestment === 'number' ? rate.minimumInvestment : 0;

      return {
        amount: allocationAmount,
        investmentNumber,
        investmentRateId: rate.investmentRateId,
        minimumInvestment: minimumDeposit,
      } as Allocation;
    });
  }

  async function getSuperiorInvestmentRates(match): Promise<any> {
    await getInvestmentRates(
      user.organizationId,
      user.token,
      {
        active: true,
      },
      user,
      accountOwnerId,
    )
      .then((resp) => {
        const initialAllocations = mapInitialAllocations(resp.data);
        if (match) {
          setSuperiorInvestmentRates(resp.data);
        } else {
          if (isMounted) {
            setInvestmentRates(resp.data);
            setAllocations(initialAllocations);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        const { response: { data = {} } = {} } = err;

        setIsLoading(false);
        addGlobalMessage(data.message);
      });
  }

  async function getInitialInvestmentRates(): Promise<void> {
    if (!isMounted.current) return;

    if (user.token && user.organizationId) {
      setIsLoading(true);

      if (organization.coreIntegration === 'SYMITAR') {
        if (!newAccount) {
          const symitarDTO = (await updateAccountOwner()).symitarAccountDTO;
          const initialAllocations =
            symitarDTO && symitarDTO.investments
              ? mapSymitarInitialAllocations(symitarDTO.investments)
              : [];
          getSuperiorInvestmentRates(true);

          if (isMounted) {
            setInvestmentRates(symitarDTO.investments);
            setAllocations(initialAllocations);
            setIsLoading(false);
          }
        } else {
          getSuperiorInvestmentRates(false);
        }
      } else {
        getSuperiorInvestmentRates(false);
      }
    }
  }

  useEffect(() => {
    getInitialInvestmentRates();
    if (organization.coreIntegration === 'SYMITAR') {
      setAllocations(existingAllocations);
    }

    return () => {
      isMounted.current = false;
    };
  }, [user.token, user.organizationId]);

  if (isLoading) {
    return (
      <Box width="1" mt={5} mb={3}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  const handleNewInvestment = () => {
    // Create dropdown form for new investment
    const investmentSelection: Array<SiraSelectItem> =
      superiorInvestmentRates.map((rate) => {
        return {
          label: rate.description,
          value: rate.investmentRateId,
        };
      });

    // clear out the selected investment
    setSelectedInvestment({} as any);

    setInvestmentAllocationDropdown(investmentSelection);
  };

  const handleAddingNewInvestment = () => {
    // Add new investment to the allocations list
    const newInvestment = {
      investmentNumber: selectedInvestment.newInvestmentNumber,
      contributionId: contributionInfo.contributionId,
      minimumDeposit: selectedInvestment.minimumInvestment,
      id: selectedInvestment.investmentRateId,
      ...selectedInvestment,
    };

    // add new investment to the investmentrates state
    setInvestmentRates([...investmentRates, selectedInvestment]);
    setAllocations([...allocations, newInvestment]);
    setAddnewInvestment(false);
  };

  const investmentRows =
    Boolean(investmentRates.length) &&
    allocations.map((investment: Allocation, index: number) => {
      let minInvestmentOrDeposit;
      if (investmentRates[index] === undefined) {
        minInvestmentOrDeposit = selectedInvestment.minimumDeposit;
      } else {
        minInvestmentOrDeposit =
          investmentRates[index]?.minimumDeposit ||
          investmentRates[index]?.minimumInvestment;
      }


      const hasValue = (value: string) => value !== '0' && value !== '';

      return (
        <TableRow
          key={
            investmentRates[index]?.description ||
            selectedInvestment?.description
          }
          sx={{
            boxShadow: 1,
          }}
        >
          {/* this checks to see if there is a new row added and then uses the selected values */}
          {investmentRates[index] !== undefined ? (
            <>
              <TableCell sx={classes.tableCell}>
                {investmentRates[index].description}
              </TableCell>
              <TableCell sx={classes.tableCell}>
                {moneyFormatter.format(minInvestmentOrDeposit || 0)}
              </TableCell>
              <TableCell sx={classes.tableCell}>
                <NumberFormat
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={organization.investmentRatePrecision}
                  isNumericString
                  value={
                    investmentRates[index].interestRate ||
                    investmentRates[index].divRate
                  }
                  suffix="%"
                />
              </TableCell>
              {investmentRates[index].apy && (
                <TableCell sx={classes.tableCell}>
                  <NumberFormat
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={organization.investmentRatePrecision}
                    isNumericString
                    value={investmentRates[index].apy}
                    suffix="%"
                  />
                </TableCell>
              )}
                            {investmentRates[index].balance && (

                <TableCell sx={classes.tableCell}>
                  {moneyFormatter.format(investmentRates[index].balance || '0')}
                </TableCell>
              )}

            </>
          ) : (
            <>
              <TableCell sx={classes.tableCell}>
                {selectedInvestment.description}
              </TableCell>
              <TableCell sx={classes.tableCell}>
                {moneyFormatter.format(minInvestmentOrDeposit || 0)}
              </TableCell>
              <TableCell sx={classes.tableCell}>
                <NumberFormat
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={organization.investmentRatePrecision}
                  isNumericString
                  value={
                    selectedInvestment.interestRate ||
                    selectedInvestment.divRate
                  }
                  suffix="%"
                />
              </TableCell>
              {selectedInvestment.apy && (
                <TableCell sx={classes.tableCell}>
                  <NumberFormat
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={organization.investmentRatePrecision}
                    isNumericString
                    value={selectedInvestment.apy}
                    suffix="%"
                  />
                </TableCell>
              )}
              {selectedInvestment.balance && (
                <TableCell sx={classes.tableCell}>
                  {moneyFormatter.format(selectedInvestment.balance)}
                </TableCell>
              )}
            </>
          )}
          <TableCell sx={classes.tableCell}>
            <SiraTextField name={`allocations[${index}.investmentNumber]`} />
          </TableCell>
          <TableCell sx={classes.lastTableCell}>
            <SiraCurrencyField name={`allocations[${index}.amount]`} />
          </TableCell>
        </TableRow>
      );
    });

  return (
    <Formik
      initialValues={{ allocations }}
      onSubmit={async (values) => {
        await onSubmit(contributionAllocationsFactory(values.allocations));
      }}
      enableReinitialize
      onReset={() => onReset()}
      validationSchema={CONTRIBUTION_ALLOCATION_SCHEMA}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            {investmentRates.length > 0 && (
              <AllocationPieDisplay
                investmentRates={[...investmentRates, selectedInvestment]}
                amount={amount}
              />
            )}

            <Box>
              <Grid container spacing={2}>
                {!addNewInvestment ? (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={classes.tableLabel}>
                      Use these investment options to allocate full contribution
                    </Typography>
                    <Card sx={{ padding: 2, boxShadow: 3 }}>
                    <CardContent>
                      <Table>
                        <TableHead>
                          <FieldArray name="investments">
                            {() => (
                              <TableRow>
                                <TableCell sx={classes.headCell}>
                                  Account Type
                                </TableCell>
                                <TableCell sx={classes.headCell}>Min</TableCell>
                                <TableCell sx={classes.headCell}>
                                  Rate
                                </TableCell>
                                <TableCell sx={classes.headCell}>
                                  {organization.coreIntegration === 'SYMITAR'
                                    ? 'Balance'
                                    : 'APY'}
                                </TableCell>
                                <TableCell sx={classes.headCell}>
                                  Investment Number
                                </TableCell>
                                <TableCell sx={classes.lastHeadCell}>
                                  Amount
                                </TableCell>
                              </TableRow>
                            )}
                          </FieldArray>
                        </TableHead>
                        {allocations && (
                          <TableBody>
                            <FieldArray name="allocations">
                              {() => {
                                return <>{investmentRows}</>;
                              }}
                            </FieldArray>
                          </TableBody>
                        )}
                      </Table>
                    </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={classes.tableLabel}>
                          Add New Investment
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SiraSelectField
                          name="investmentAccountType"
                          label="Investment Type"
                          items={investmentAllocationDropdown}
                          value={selectedInvestment.id}
                          onChange={(e) => {
                            const selectedRate = superiorInvestmentRates.find(
                              (rate) =>
                                rate.investmentRateId === e.target.value,
                            );
                            setSelectedInvestment(selectedRate);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SiraCurrencyField
                          name="minimumInvestment"
                          label="Minimum Balance"
                          value={selectedInvestment.minimumInvestment}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SiraPercentField
                          disabled
                          value={selectedInvestment.interestRate}
                          decimalScale={organization.investmentRatePrecision}
                          name="percent"
                          label="Rate"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SiraTextField
                          name="newInvestmentNumber"
                          label="Investment Number"
                          onChange={(e) => {
                                                        // update selected investment with new investment number
                            setSelectedInvestment({
                              ...selectedInvestment,
                              newInvestmentNumber: e.target.value,
                            });
                          }}
                          value={selectedInvestment.newInvestmentNumber}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Button
                          data-qa="add-primary-beneficiary-button"
                          variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            // handle adding new investment to the alloocations list
                            handleAddingNewInvestment();
                          }}
                        >
                          Add
                        </Button>
                        <Button
                          data-qa="add-primary-beneficiary-button"
                          variant="outlined"
                          color="primary"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => {
                            setAddnewInvestment(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <ErrorMessage name="allocations">
                    {(msg) => (
                      <Alert severity="error" variant="outlined">
                        {msg}
                      </Alert>
                    )}
                  </ErrorMessage>
                </Grid>
              </Grid>
            </Box>

            {!addNewInvestment ? (
              <>
                <Box display="inline-block">
                  {' '}
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    sx={{ mr: 2 }}
                    data-qa="step-submit-button"
                  >
                    Continue
                  </Button>
                </Box>

                {organization.coreIntegration === 'SYMITAR' && !newAccount ? (
                  <Box pt={2} display="inline-block">
                    <Button
                      data-qa="add-primary-beneficiary-button"
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        handleNewInvestment();
                        setAddnewInvestment(true);
                      }}
                    >
                      Add New Investment
                    </Button>
                  </Box>
                ) : null}
              </>
            ) : null}
            {/* Loading... */}
            {isSubmitting && (
              <Box width="1" mt={2}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContributionAllocationForm;
