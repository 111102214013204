import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  LinearProgress,
  Switch,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import { useUser } from '../../../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import {
  BatchType,
  TaxRepotingImportDetails,
  TaxResult,
} from '../../../api/BatchApi.d';
import { BatchOptionConfig } from '../DataImport';
import TaxReportingInfoList from './TaxReportingInfoList';
import {
  taxReportUploadFileResultPagable,
} from '../../../api/TaxFormApi';
import { errorMessages } from '../../../utils/errorhandling.utils';
import { set } from 'cypress/types/lodash';


interface BulkDataImportsProps {
  batchOptionsConfig: BatchOptionConfig;
  currentBatchType: BatchType;
}

interface BatchStatusFilterProps {
  onItemClick: Function;
  filterCount: number;
  currentBatchType: BatchType;
}

function ImportStatusFilter(props: BatchStatusFilterProps) {
  const { onItemClick = () => {}, filterCount, currentBatchType } = props;
  const [isMatching, setIsMatching] = useState(false as boolean);
  const [isFMVAmount, setIsFMVAmount] = useState(false as boolean);
  const [isReset, setIsReset] = useState(false as boolean);

  useEffect(() => {
    const options = {
      fmvAmount: isFMVAmount,
      matching: isMatching,
    };

    onItemClick(options);
  }, [isFMVAmount, isMatching]);

  return (
    <Box mt={3} mb={1}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body2">Filter Options:</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography component="div">
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              wrap="nowrap"
              sx={{
                whiteSpace: 'nowrap',
                paddingTop: '0.5rem',
                paddingLeft: '0.5rem',
              }}
            >
              <Grid item>Not Matching</Grid>
              <Grid item>
                <Switch
                  checked={isMatching}
                  onChange={() => {
                    setIsMatching(!isMatching);
                  }}
                  inputProps={{ 'aria-label': 'show matched' }}
                />
              </Grid>
              <Grid item>Matching</Grid>
            </Grid>
          </Typography>
        </Grid>
        {currentBatchType === BatchType.taxReport5498 ? (
          <>
            <Grid item>
              <Button
                variant={isFMVAmount ? 'contained' : 'outlined'}
                color="success"
                startIcon={<CheckCircleIcon />}
                size="small"
                onClick={() => {
                  setIsFMVAmount(!isFMVAmount);
                }}
              >
                Fair Market Values
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ClearIcon />}
                size="small"
                onClick={() => {
                  setIsFMVAmount(false);
                  setIsMatching(false);
                  setIsReset(!isReset);
                }}
              >
                Reset Filters
              </Button>
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography component="div">
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              wrap="nowrap"
              sx={{
                whiteSpace: 'nowrap',
                paddingTop: '0.5rem',
                paddingLeft: '0.5rem',
              }}
            >
              <Typography variant="body1">
                {filterCount}{' '}
                {isFMVAmount
                  ? 'Fair Market Values found'
                  : `${
                      currentBatchType === BatchType.taxReport1099
                        ? 'Distributions'
                        : 'Contributions'
                    } amounts found`}
                {isMatching ? ' that match' : ' that do not match'}
              </Typography>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function TaxReportingImports(props: BulkDataImportsProps) {
  let isMounted = true;
  const { batchOptionsConfig, currentBatchType } = props;
  const { user } = useUser();
  const navigate = useNavigate();
  const { organization, addGlobalMessage } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isRefreshing, setIsRefreshing] = useState(false as boolean);
  const [selectedBatchData, setSelectedBatchData] = useState(
    [] as Array<TaxResult>
  );
  const [filterCount, setFilterCount] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    fmvAmount: false,
    matching: false,
  } as any);
  const { type: selectedBatchType } = batchOptionsConfig;
  const { globalPageSize, pageNumber } = usePaginationContext();
  const [sortedfield, setField] = useState('' as string);
  const [sortDirection, setSort] = useState('' as string);
  const [hasNextPage, setHasNextPage] = useState(false as boolean);
  const [filterObject, setFilterObject] = useState({} as any);

  const [selectedBatchFileData, setSelectedBatchFileData] = useState(
    [] as Array<TaxRepotingImportDetails>
  );

  const [filterSelectedBatchFileData, setFilterSelectedBatchFileData] =
    useState([] as Array<TaxRepotingImportDetails>);

  // GET and set the data for whichever batch info type is selected using the config method
  const fetchAndSetSelectedBatchData = async (): Promise<void> => {
    const { apiMethod } = batchOptionsConfig;
    await apiMethod()
      .then((res) => {
        if (isMounted) {
          setSelectedBatchData(res.data);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            `Could not get batch import information for type: ${selectedBatchType}`
        );
      });

    if (isMounted) {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  };

  const goToLinkPath = () => {
    navigate(batchOptionsConfig.linkPath);
  };

  const handleBatchFileClick = (options: any = {}) => {
    setFilterOptions(options);
    fetchIrsTaxFile(
      selectedId,
      globalPageSize,
      sortedfield,
      sortDirection,
      pageNumber,
      options
    );
  };

  const fetchIrsTaxFile = async (
    irsTaxFileUploadId: number,
    pageSize: number = globalPageSize,
    field: string = sortedfield,
    sort: string = sortDirection,
    page: number = 0,
    options: any = {    fmvAmount: false,
      matching: false,},
    filterObject: any = {}
  ) => {
    setSelectedId(irsTaxFileUploadId);
    await taxReportUploadFileResultPagable(
      user.organizationId,
      user.token,
      irsTaxFileUploadId,
      pageSize,
      field,
      sort,
      page,
      options,
      filterObject
    )
      .then((res) => {
        setIsRefreshing(false);
        // implementing serverside pagination for the tax data
        if (isMounted) {
          setFilterSelectedBatchFileData(res.data.content);
          setSelectedBatchFileData(res.data.content);
          setFilterCount(res.data.page.totalElements);
          const { totalPages, number } = res.data.page;
          const currentPage = number + 1;
          if (currentPage < totalPages) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
        }
      })
      .catch((err) => {
        setIsRefreshing(false);
        const { response: { data: errorData = {} } = {} } = err;
        addGlobalMessage(
          errorData.message ||
            `Could not get batch import information for type: ${selectedBatchType}`
        );
      });
  };

  useEffect(() => {
    fetchIrsTaxFile(
      selectedId,
      globalPageSize,
      sortedfield,
      sortDirection,
      pageNumber,
      filterOptions,
      filterObject
    );
  }, [globalPageSize, sortedfield, sortDirection, pageNumber, filterObject]);

  useEffect(() => {
    if (
      user.organizationId &&
      user.token &&
      batchOptionsConfig &&
      currentBatchType
    ) {
      setIsLoading(true);
      if (
        batchOptionsConfig.type !== null &&
        batchOptionsConfig.type !== undefined
      ) {
        fetchAndSetSelectedBatchData();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token, batchOptionsConfig, currentBatchType]);

  return (
    <Grid item xs={12}>
      <Paper elevation={3}>
        <Box p={2}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5">Data File Uploads</Typography>
                  <Typography variant="body2" gutterBottom>
                    {organization.legalName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {/* Back */}
                    <Grid item xs={false}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          navigate(-1);
                        }}
                        startIcon={<ArrowBackIcon />}
                      >
                        Back
                      </Button>
                    </Grid>

                    {/* Upload Button */}
                    <Grid item xs={false}>
                      <Button
                        onClick={goToLinkPath}
                        variant="contained"
                        size="small"
                        startIcon={<FileUploadIcon />}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {batchOptionsConfig.buttonText}
                      </Button>
                    </Grid>

                    {/* Refresh */}
                    <Grid item xs={false}>
                      <IconButton
                        onClick={() => {
                          setIsRefreshing(true);
                          fetchAndSetSelectedBatchData();
                        }}
                        disabled={isLoading}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Filter by batch status */}
              <ImportStatusFilter
                onItemClick={handleBatchFileClick}
                filterCount={filterCount}
                currentBatchType={currentBatchType}
              />

              {/* Load the info panels */}
              {isLoading ? (
                <Box mt={4}>
                  <LinearProgress />
                </Box>
              ) : (
                <div
                  key={`batch_info_panel_${batchOptionsConfig.type}`}
                  role="tabpanel"
                  hidden={selectedBatchType !== batchOptionsConfig.type}
                  id={`simple-tabpanel-${batchOptionsConfig.type}`}
                  aria-labelledby={`simple-tab-${batchOptionsConfig.type}`}
                >
                  {selectedBatchType === batchOptionsConfig.type && (
                    <Box pt={2}>
                      <TaxReportingInfoList
                        onComplete={(taxUploadId) => {
                          setIsRefreshing(true);
                          fetchIrsTaxFile(taxUploadId);
                        }}
                        batchData={selectedBatchData}
                        isLoading={isLoading || isRefreshing}
                        batchType={currentBatchType}
                        fetchIrsTaxFile={(value) => {
                          setIsRefreshing(true);
                          fetchIrsTaxFile(value);
                        }}
                        batchFileData={filterSelectedBatchFileData}
                        totalRowCount={filterCount}
                        hasNextPage={hasNextPage}
                        handleSortChange={(model) => {
                          setField(model[0]?.field);
                          setSort(model[0]?.sort);
                        }}
                        handleFilterChange={(model) => {
                          setFilterObject(model);
                        }
                        }
                      />
                    </Box>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
}

export default TaxReportingImports;
