import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormikContext, Field } from 'formik';
import {
  isRecharOrExcessOrRevoc,
  setDistributionReason,
} from './distribution.utils';
import SiraCurrencyField from '../SiraCurrencyField';
import SiraDateField from '../SiraDateField';
import NetOrTotalAmountField from './NetOrTotalAmountField';
import { netIncomeAttributableReasons } from './getDistributionCodes';
import { Distribution, NiaOption } from '../../../api/DistributionApi.d';
import NetIncomeAttributionFields from './NetIncomeAttributionFields';
import SiraRadioField from '../SiraRadioField';
import { distributionAmountHelpText } from './resource.txt';
import { AccountStatus, AccountType } from '../../../api/AccountApi.d';
import SiraSwitchTooltip from '../SiraSwitchToolTip';
import { useGlobalContext } from '../../../auth/useGlobalContext';

interface DistributionAmountSubFormProps {
  accountId: string;
  isEarlyDistribution?: boolean;
  accountType?: AccountType;
  accountStatus?: AccountStatus;
}

function DistributionAmountSubForm(props: DistributionAmountSubFormProps) {
  // useFormikContext
  const { values, setFieldValue } = useFormikContext();
  const { accountId, isEarlyDistribution, accountType, accountStatus } = props;

  const {organization} = useGlobalContext();
  const {displayFeeAmount} = organization;

  const afterContentNIACalculate = (niaOptionSel) => {
    switch (accountType) {
      case AccountType.esa:
        return niaOptionSel === NiaOption.calculate
          ? distributionAmountHelpText.calcESANIARemoveExcessContribution
              .afterContent
          : distributionAmountHelpText.enterESANIAwithoutCalculating
              .afterContent;
      case AccountType.hsa:
        return niaOptionSel === NiaOption.calculate
          ? distributionAmountHelpText.calcHSANIARemoveExcessContribution
              .afterContent
          : distributionAmountHelpText.enterHSANIAwithoutCalculating
              .afterContent;
      default:
        return niaOptionSel === NiaOption.calculate
          ? distributionAmountHelpText.calcNIARemoveExcessContribution
              .afterContent
          : distributionAmountHelpText.enterNIAwithoutCalculating.afterContent;
    }
  };

  const netIncomeAttrOptions = [
    {
      label:
        'Calculate the net income attributable (NIA) to remove with the excess contribution',
      value: 'calculate',
      afterContent: afterContentNIACalculate('calculate'),
    },
    {
      label: 'Enter the NIA without calculating',
      value: 'withoutCalculation',
      afterContent: afterContentNIACalculate('withoutCalculation'),
    },
  ];

  // pull toggle value from here
  const {
    distributionReason,
    niaOptions,
    closingAccount,
    netIncomeAttributable,
  } = values as Distribution;

  const isCalcution = niaOptions === NiaOption.withoutCalculation;
  const isRecharacterization =
    netIncomeAttributableReasons.RECHAR.includes(distributionReason);
  const isRemovingExcess =
    netIncomeAttributableReasons.EXCESS.includes(distributionReason);
  const isRegRevocation =
    netIncomeAttributableReasons.REGULARREVOC.includes(distributionReason);
  const isConvRevocation =
    netIncomeAttributableReasons.CONVERSIONREVOC.includes(distributionReason);

  const isNIAOptions = isRecharOrExcessOrRevoc(distributionReason);
  const isChecked = isNIAOptions && !isCalcution;

  // useEffect will handle setting the distribution reason based of the nia amount and age
  useEffect(() => {
    let newReason = '';

    if (isNIAOptions && netIncomeAttributable !== '') {
      newReason = setDistributionReason(
        netIncomeAttributable,
        isEarlyDistribution,
        distributionReason
      );
      setFieldValue('distributionReason', newReason);
    }
  }, [netIncomeAttributable]);

  useEffect(() => {
    if (!isNIAOptions) {
      setFieldValue('netIncomeAttributable', '');
      setFieldValue('niaOptions', NiaOption.calculate);
    }
  }, [isNIAOptions]);

  return (
    <>
      <Grid item xs={12}>
        <Typography component="div">
          {accountStatus !== AccountStatus.closed && (
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              {/* 
            Display new options for when this field has been toggled
            */}
              <Grid item>
                <SiraSwitchTooltip
                  name="closingAccount"
                  checked={closingAccount}
                  value={
                    distributionAmountHelpText.distributeEntireBalCloseAcct
                      .value
                  }
                />
              </Grid>
              <Grid item>
                Distribute the entire balance and close the account
              </Grid>
            </Grid>
          )}
          {isNIAOptions && (
            <>
              <Grid item>
                <Field
                  name="niaOptions"
                  options={netIncomeAttrOptions}
                  component={SiraRadioField}
                />
              </Grid>
            </>
          )}
        </Typography>
      </Grid>
      {isCalcution && isNIAOptions && (
        <Grid item xs={12}>
          <SiraCurrencyField
            name="netIncomeAttributable"
            label="NIA Amount"
            allowNegative={false}
          />
        </Grid>
      )}
      {isChecked ? (
        <NetIncomeAttributionFields
          accountId={accountId}
          isRecharacterization={isRecharacterization}
          isRemovingExcess={isRemovingExcess}
          isRegularRevoc={isRegRevocation}
          isConversionRevoc={isConvRevocation}
          accountType={accountType}
        />
      ) : (
        <NetOrTotalAmountField />
      )}
{    displayFeeAmount ?  <Grid item xs={12}>
        <SiraCurrencyField
          name="penaltyAmount"
          label="Financial Organization Fee Amount"
          allowNegative={false}
          afterContent={
            distributionAmountHelpText.financialOrgPenaltyAmount.afterContent
          }
        />
      </Grid> : null}
      <Grid item xs={12}>
        <SiraDateField name="effectiveDate" label="Distribution Date" />
      </Grid>
    </>
  );
}

export default DistributionAmountSubForm;
