import React from 'react';
import { Task, TaskStatus } from '../../api/TaskApi.d';
import { createMultiOrgTask } from '../../api/TaskApi';

import TaskForm, { TASK_ADD_INIT } from '../form/tasks/TaskForm';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { errorMessages } from '../../utils/errorhandling.utils';

function NewTask() {
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();

  const createNewTask = async (data: Task, draftToHtml:string): Promise<void> => {
    // Imperatively set status here. Could API do this for new tasks?
    const taskWithStatus = {
      ...data,
      status: TaskStatus.incomplete,
      descriptionForEmail: draftToHtml,
    };

    await createMultiOrgTask(taskWithStatus, user.token)
      .then(() => {
        addGlobalMessage('Task successfully created', { severity: 'success' });
      })
      .catch((err) => {

        addGlobalMessage(errorMessages(err) || 'Error creating task');
      });
  };

  return <TaskForm initialValues={TASK_ADD_INIT} onSubmit={createNewTask} />;
}
export default NewTask;
