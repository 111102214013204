import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { useLocation } from 'react-router-dom';
import {
  BeneficiariesClaimsDocuments,
  BeneficiaryClaim,
  ClaimDocumentType,
  claimDocumentTypeLabel,
  claimDocumentTypeLabelWithoutDC,
  FileProperties,
} from '../../../api/BeneficiaryClaimsApi.d';
import { setFileTypeName } from './resource.utils';

import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { globalPaginationOptions } from '../../../app.constants';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraFileField from '../SiraFileField';
import { useUser } from '../../../auth/useUser';
import {
  deleteBeneficiaryClaimDocument,
  deleteDeathCertificate,
  getBeneficiariesClaimsProfile,
  saveBeneficiaryClaimFiles,
  saveDeathCertificateFile,
  downloadBeneficiaryClaimFile,
  getDeathCertificate,
} from '../../../api/BeneficiariesClaimsApi';
import { completeTransaction } from '../../../page/TransactionReducer';
import { UserRole } from '../../../api/UserApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';

export interface ViewBeneficiaryClaimFilesProps {
  initialValues?: BeneficiariesClaimsDocuments;
  beneficiaryClaimDocuments?: Array<BeneficiariesClaimsDocuments>;
  beneficiaryClaimsId: string;
}

export interface Desc {
  description: string;
}

export const BENEFICIARIES_CLAIMS_INFO_INIT = {
  documentType: '' as ClaimDocumentType,
  uploadFiles: [],
  descriptionField: [{ description: '' }],
};

export interface ClaimFiles {
  documentType: ClaimDocumentType;
  uploadFiles: Array<File>;
  descriptionField: Array<Desc>;
}

export const claimOptionValues = {
  [ClaimDocumentType.other]: 'Other',
  [ClaimDocumentType.deathCert]: 'Death Certificate',
  [ClaimDocumentType.designatedBene]: 'Beneficiary Designation',
  [ClaimDocumentType.distributionElection]: 'Beneficiary Distribution Election',
  [ClaimDocumentType.completedDistElection]:
    'Completed Beneficiary Distribution Election',
};

interface SearchResultRow extends BeneficiariesClaimsDocuments {
  id: number;
  visibility: boolean;
  communityPropertyState: boolean;
}

const BENEFICIARIES_CLAIMS_FILES = yup
  .object({
    documentType: yup.string().nullable().required().label('Document Type'),
  })
  .shape({
    uploadFiles: yup
      .array()
      .nullable(true)
      .min(1)
      .when('documentType', (documentType, schema) => {
        let type = '';
        if (documentType === ClaimDocumentType.deathCert) {
          type = claimOptionValues[ClaimDocumentType.deathCert];
        } else if (documentType === ClaimDocumentType.designatedBene) {
          type = claimOptionValues[ClaimDocumentType.designatedBene];
        }

        return documentType === ClaimDocumentType.deathCert ||
          documentType === ClaimDocumentType.designatedBene
          ? schema.required().label(type)
          : schema.required().label('Files');
      }),
  });

function ViewBeneficiaryClaimFiles(props: ViewBeneficiaryClaimFilesProps) {
  let isMounted = true;
  const {
    beneficiaryClaimDocuments,
    initialValues = {},
    beneficiaryClaimsId,
  } = props;
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const [addNewFile, setaddNewFile] = useState(false);
  // add function to disable drop down selection

  // call getbeneclaimsDoc instead of setting the state when there is an update.
  const [disabled, setDisabled] = useState(false);
  const [docId, setdocId] = useState(0);
  const [buttonLabel, setButtonLabel] = useState('');
  const [multiple, setmultiple] = useState(true);
  const queryParams = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState(false);
  const [resetState, setresetState] = useState('');
  const [beneDocuments, setbeneDocuments] =
    useState<Array<BeneficiariesClaimsDocuments>>();
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [allowed, setAllowed] = useState(false);

  const claimOptions: Array<SiraSelectItem> = [
    {
      value: ClaimDocumentType.other,
      label: 'Other',
    },
    { value: ClaimDocumentType.deathCert, label: 'Death Certificate' },
    {
      value: ClaimDocumentType.designatedBene,
      label: 'Beneficiary Designation',
    },
    {
      value: ClaimDocumentType.distributionElection,
      label: 'Beneficiary Distribution Election',
    },
    {
      value: ClaimDocumentType.completedDistElection,
      label: 'Completed Beneficiary Distribution Election',
    },
  ];

  const claimOptionsWithoutDC: Array<SiraSelectItem> = [
    {
      value: ClaimDocumentType.other,
      label: 'Other',
    },
    {
      value: ClaimDocumentType.designatedBene,
      label: 'Beneficiary Designation',
    },
    {
      value: ClaimDocumentType.distributionElection,
      label: 'Beneficiary Distribution Election',
    },
    {
      value: ClaimDocumentType.completedDistElection,
      label: 'Completed Beneficiary Distribution Election',
    },
  ];

  useEffect(() => {
    setAllowed(user.roles.includes(UserRole.multi));
  }, [user.roles]);

  useEffect(() => {
    setbeneDocuments(beneficiaryClaimDocuments);
  }, [beneficiaryClaimDocuments]);

  const claimsList = () => {
    let claims: BeneficiariesClaimsDocuments;

    if (user.roles.includes(UserRole.multi)) {
      claims = beneDocuments.find((doc) => {
        return doc.documentType === ClaimDocumentType.deathCert && doc;
      });
      return claims ? claimOptionsWithoutDC : claimOptions;
    }

    if (user.roles.includes(UserRole.orgBeneficiaryClaims)) {
      claims = beneDocuments.find((doc) => {
        return doc.documentType === ClaimDocumentType.deathCert && doc;
      });
      return claims ? claimDocumentTypeLabelWithoutDC : claimDocumentTypeLabel;
    }

    return claimOptions;
  };

  const downloadAsFile = (file) => {
    const fileType = setFileTypeName(file.fileName);

    const bin = btoa(file.fileContents);
    const decodedB64 = `data:${fileType};base64,${atob(bin)}`;

    const a = document.createElement('a');
    a.href = decodedB64;
    a.download = file.fileName;
    a.click();
  };

  const convertToFile = (file) => {
    const fileType = setFileTypeName(file.fileName);

    const bin = btoa(file.fileContents);
    const decodedB64 = `data:${fileType};base64,${atob(bin)}`;
    const arr = decodedB64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const parsedFile: Array<File> = [];

    parsedFile.push(new File([u8arr], file.fileName, { type: mime }));

    return parsedFile;
  };

  async function getBenefitClaimsDoc(): Promise<void> {
    await getBeneficiariesClaimsProfile(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      user.token,
      user,
    )
      .then((responseValue) => {
        setbeneDocuments(responseValue.data.beneficiaryClaimDocuments);
        setIsLoading(false);
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) ||
              'Could not fetch the preselected beneficiary claim to view',
          );
        }
      });
  }

  async function uploadDocuments(values: ClaimFiles): Promise<void> {
    const fileProperties: Array<FileProperties> = [];
    const files: Array<File> = [];
    // let beneficiaryClaimDocId;
    const { descriptionField, documentType, uploadFiles } = values;
    let deathCertificate: BeneficiaryClaim;

    if (descriptionField) {
      descriptionField.forEach((desc, idx) => {
        switch (documentType) {
          case ClaimDocumentType.designatedBene:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description:
                desc.description ||
                claimOptionValues[ClaimDocumentType.designatedBene],
            });
            files.push(uploadFiles[idx]);
            break;
          case ClaimDocumentType.deathCert:
            deathCertificate = {
              files: [uploadFiles[idx]],
              fileProperties: [
                {
                  beneficiaryClaimDocumentId: docId > 0 ? docId : null,
                  documentType,
                  description:
                    desc.description ||
                    claimOptionValues[ClaimDocumentType.deathCert],
                },
              ],
            };
            break;
          case ClaimDocumentType.completedDistElection:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description:
                desc.description ||
                claimOptionValues[ClaimDocumentType.completedDistElection],
            });
            files.push(uploadFiles[idx]);

            break;
          case ClaimDocumentType.distributionElection:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description:
                desc.description ||
                claimOptionValues[ClaimDocumentType.distributionElection],
            });
            files.push(uploadFiles[idx]);

            break;
          case ClaimDocumentType.other:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description:
                desc.description || claimOptionValues[ClaimDocumentType.other],
            });
            files.push(uploadFiles[idx]);

            break;
          default:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description: desc.description,
            });
            files.push(uploadFiles[idx]);
            break;
        }
      });
    } else {
      uploadFiles.forEach((file) => {
        switch (documentType) {
          case ClaimDocumentType.designatedBene:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description: claimOptionValues[ClaimDocumentType.designatedBene],
            });
            files.push(file);
            break;
          case ClaimDocumentType.deathCert:
            deathCertificate = {
              files: [file],
              fileProperties: [
                {
                  beneficiaryClaimDocumentId: docId > 0 ? docId : null,
                  documentType,
                  description: claimOptionValues[ClaimDocumentType.deathCert],
                },
              ],
            };
            break;
          case ClaimDocumentType.completedDistElection:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description:
                claimOptionValues[ClaimDocumentType.completedDistElection],
            });
            files.push(file);

            break;
          case ClaimDocumentType.distributionElection:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description:
                claimOptionValues[ClaimDocumentType.distributionElection],
            });
            files.push(file);

            break;
          case ClaimDocumentType.other:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description: claimOptionValues[ClaimDocumentType.other],
            });
            files.push(file);

            break;
          default:
            fileProperties.push({
              beneficiaryClaimDocumentId: docId > 0 ? docId : null,
              documentType,
              description: claimOptionValues[ClaimDocumentType.other],
            });
            files.push(file);
            break;
        }
      });
    }

    const nonDeathCertificate: BeneficiaryClaim = {
      files,
      fileProperties,
    };

    if (deathCertificate) {
      await saveDeathCertificateFile(
        deathCertificate,
        queryParams.get('accountOwnerId'),
        user.organizationId,
        user.token,
      )
        .then(() => {
          setaddNewFile(false);
          getBenefitClaimsDoc();
          completeTransaction();
        })
        .catch((err) => {
          addGlobalMessage(errorMessages(err) || 'Could not upload file');
        });
    }

    if (nonDeathCertificate.files.length > 0) {
      await saveBeneficiaryClaimFiles(
        nonDeathCertificate,
        beneficiaryClaimsId,
        queryParams.get('accountId'),
        queryParams.get('accountOwnerId'),
        user.organizationId,
        user.token,
      )
        .then(() => {
          setaddNewFile(false);
          getBenefitClaimsDoc();
          completeTransaction();
        })
        .catch((err) => {
          addGlobalMessage(errorMessages(err) || 'Could not upload file');
        });
    }
  }

  async function deleteBeneClaimsDoc(row): Promise<void> {
    const { beneficiaryClaimDocId, beneficiaryClaimId, documentType } = row;

    if (addNewFile) {
      setaddNewFile(false);
    }
    if (documentType === ClaimDocumentType.deathCert) {
      await deleteDeathCertificate(
        user.organizationId,
        user.token,
        queryParams.get('accountOwnerId'),
      )
        .then(() => {
          isMounted = true;

          setIsLoading(true);
          if (isMounted) {
            getBenefitClaimsDoc();
          }
          return () => {
            isMounted = false;
          };
        })
        .catch((err) => {
          addGlobalMessage(err.message);
        });
    } else {
      await deleteBeneficiaryClaimDocument(
        beneficiaryClaimId,
        beneficiaryClaimDocId,
        user.organizationId,
        user.token,
        queryParams.get('accountId'),
        queryParams.get('accountOwnerId'),
      )
        .then(() => {
          isMounted = true;

          setIsLoading(true);
          if (isMounted) {
            getBenefitClaimsDoc();
          }
          return () => {
            isMounted = false;
          };
        })
        .catch((err) => {
          addGlobalMessage(err.message);
        });
    }
  }

  async function downloadFile(
    row: BeneficiariesClaimsDocuments,
  ): Promise<void> {
    if (row.documentType !== ClaimDocumentType.deathCert) {
      await downloadBeneficiaryClaimFile(
        user.organizationId,
        row.beneficiaryClaimId,
        queryParams.get('accountId'),
        queryParams.get('accountOwnerId'),
        row.beneficiaryClaimDocId,
        user.token,
      )
        .then((response) => {
          const { data: { fileContents = '', fileName = '' } = {} } = response;
          downloadAsFile({ fileContents, fileName });
        })
        .catch((err) => {
          addGlobalMessage(err.message || 'Error downloading file');
        });
    } else {
      await getDeathCertificate(
        queryParams.get('accountOwnerId'),
        user.organizationId,
        user.token,
      )
        .then((response) => {
          const { data: { fileContents = '', fileName = '' } = {} } = response;
          downloadAsFile({ fileContents, fileName });
        })
        .catch((err) => {
          addGlobalMessage(err.message || 'Error downloading file');
        });
    }
  }

  const classes = {
    fileLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  const columns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      width: 350,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            sx={classes.fileLink}
            startIcon={<FileDownloadIcon />}
            onClick={() => {
              downloadFile(row);
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'documentType',
      headerName: 'Document Type',
      width: 146,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        const type = claimOptionValues[value as ClaimDocumentType];
        return (
          <Box overflow="hidden" textOverflow="ellipsis">
            {type}
          </Box>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      sortable: false,
    },
    {
      field: 'beneficiaryClaimDocId',
      headerName: '',
      width: 100,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const { beneficiaryClaimDocId } = row;

        return (
          <>
            <Box pl={1}>
              <Tooltip title="Update">
                <IconButton
                  onClick={() => {
                    setaddNewFile(true);
                    setdocId(beneficiaryClaimDocId);
                    setButtonLabel('Update Document');
                    setresetState('');
                    setmultiple(false);
                    setDisabled(true);
                  }}
                >
                  <FileUploadIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Box pl={1}>
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    deleteBeneClaimsDoc(row);
                    setDisabled(false);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  function resultRowFactory(
    resultRow: BeneficiariesClaimsDocuments,
    index: number,
  ): SearchResultRow {
    return {
      ...resultRow,
      id: index,
      visibility: false,
      communityPropertyState: false,
    };
  }

  const rows = beneDocuments
    ? beneDocuments.map((resultRow, index) =>
        resultRowFactory(resultRow, index),
      )
    : [];

  const setMultiFile = (values) => {
    if (values.documentType) {
      if (
        values.documentType === ClaimDocumentType.designatedBene ||
        values.documentType === ClaimDocumentType.deathCert
      ) {
        setmultiple(false);
      } else {
        setmultiple(true);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: ClaimFiles) => {
        await uploadDocuments(values);
      }}
      validationSchema={BENEFICIARIES_CLAIMS_FILES}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue, values, resetForm }) => {
        useEffect(() => {
          if (values) {
            setMultiFile(values);
          }
        }, [values]);

        return (
          <Form>
            <Grid container spacing={3}>
              <Grid container justifyContent="flex-end">
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="contained"
                  color="primary"
                  startIcon={<FileUploadIcon />}
                  onClick={() => {
                    setaddNewFile(true);
                    setButtonLabel('Upload Document');
                    setdocId(0);
                    resetForm(BENEFICIARIES_CLAIMS_FILES as any);
                    setresetState('reset');
                    setDisabled(false);
                  }}
                >
                  Add New File
                </Button>
              </Grid>
              <Grid item xs={12}>
                {isLoading && (
                  <Box mt={4}>
                    <LinearProgress color="secondary" />
                  </Box>
                )}
                <DataGrid
                  components={{
                    NoRowsOverlay: SiraNoRowsOverlay,
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: globalPageSize },
                    },
                  }}
                  pageSizeOptions={globalPaginationOptions}
                  onPaginationModelChange={setGlobalPageSize}
                  columnVisibilityModel={{
                    beneficiaryClaimDocId: allowed,
                  }}
                  disableColumnMenu
                  autoHeight
                  columns={columns}
                  rows={rows}
                  onRowSelectionModelChange={(selectionModel = []) => {
                    rows.forEach((row) => {
                      Object.assign(row, { visibility: false });
                    });
                    selectionModel.forEach((index) => {
                      Object.assign(rows[index], { visibility: true });
                      setFieldValue('documentType', rows[index].documentType);
                      setFieldValue('uploadFiles', convertToFile(rows[index]));
                      setFieldValue(
                        `descriptionField[0.description]`,
                        rows[index].description,
                      );
                    });
                  }}
                />
              </Grid>
              {addNewFile && (
                <>
                  <Grid item xs={4}>
                    <SiraSelectField
                      disabled={disabled}
                      items={claimsList()}
                      name="documentType"
                      label="Document Type"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={1}>
                      <SiraFileField
                        onDropField={(docs) => {
                          setFieldValue('uploadFiles', docs);
                        }}
                        name="uploadFiles"
                        description
                        multipleFiles={multiple}
                        descriptionField="descriptionField"
                        resetState={resetState}
                      />
                      <StepButtonBar
                        isSubmitting={isSubmitting}
                        submitName={buttonLabel}
                        onCancel={() => {
                          setaddNewFile(false);
                        }}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ViewBeneficiaryClaimFiles;
