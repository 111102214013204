import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Box } from '@mui/material';
import { FieldAttributes } from 'formik';

interface SiraCheckboxFieldProps extends FormControlLabelProps {
  field: FieldAttributes<any>;
  afterContent: React.ReactNode;
  form: any;
}

const SiraCheckboxField = (props: SiraCheckboxFieldProps) => {
  const {  afterContent, label, field, name } = props;
  const fieldName = name || field.name;


  return (
    <FormGroup>
      <FormControlLabel
        {...field}
        name={name}
        label={label}
        checked={Boolean(field.value)}
        control={
          <Checkbox
          {...field} {...props} name={fieldName} color="primary"
          />
        }
      />
      {Boolean(field.value) && <Box ml={4}>{afterContent}</Box>}
    </FormGroup>
  );
};

export default SiraCheckboxField;
