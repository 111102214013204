import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import TransactionStepper, {
  TransactionStep,
} from '../../page/TransactionStepper';
import DistributionMethodForm, {
  DISTRIBUTION_METHOD_INIT,
} from '../form/distribution/DistributionMethodForm';

import {
  addTransactionData,
  skipStep,
  useTransactionReducer,
} from '../../page/TransactionReducer';
import {
  Distribution,
  DistributionStatus,
  DistributionStatusState,
} from '../../api/DistributionApi.d';
import {
  changeRecurringDistributionStatus,
  createOrSaveRecurringDistribution,
} from '../../api/RecurringDistributionApi';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { RecurringDistribution } from '../../api/RecurringDistributionApi.d';
import { useUser } from '../../auth/useUser';
import { createOrSaveFairMarketValue } from '../../api/FairMarketValueApi';
import { currentTaxYear } from '../../app.constants';
import { WorkflowDates } from '../../api/AccountApi.d';
import { UserRole } from '../../api/UserApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';

interface EditRecurringDistributionProps {
  editRecurringDistribution: RecurringDistribution;
  accountOwnerId: string;
  accountId: string;
  cancel: Function;
  isEditing: Function;    // This is a function that returns a boolean
}


function EditRecurringDistribution(props: EditRecurringDistributionProps) {
  const { editRecurringDistribution, cancel, accountOwnerId, accountId,isEditing } = props;
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [pageState] = useTransactionReducer();

  const {
    fmvInDb = false,
    distributionStatus,
    recurringDistributionId,
  } = editRecurringDistribution;
  const loadingExistingDistribution =
    editRecurringDistribution.recurringDistributionId;
  const { addGlobalMessage } = useGlobalContext();
  const { accountType = '' } = pageState.selectedAccount;
  const isAwaiting = [
    DistributionStatus.signature,
    DistributionStatus.review,
    DistributionStatus.submitOwner
  ].includes(distributionStatus);
  const pageStateToRecurringDistribution = (distributionMethodForm) => {
    const recurringDistribution = {
      ...editRecurringDistribution,
      ...distributionMethodForm,
    } as RecurringDistribution;

    // Remove extra fields
    return recurringDistribution;
  };
  const [showDateAndStatus, setShowDateAndStatus] = useState(false);

  // Save the FMV record for the current tax year if it was entered so it displays in the document
  const saveFMVRecord = async (data: Distribution) => {
    if (data.fairMarketValue && !fmvInDb && !data.ownerResponsible) {
      await createOrSaveFairMarketValue(
        { taxYear: currentTaxYear, fairMarketValue: data.fairMarketValue },
        user.organizationId,
        pageState.selectedAccount.accountId,
        pageState.selectedAccount.accountOwnerId,
        user.token,
        user
      ).then(() => {
        // Flag the FMV as now stored at the account
        addTransactionData(
          {
            distributionInformation: {
              ...data,
              fmvInDb: true,
            },
          },
          false
        );
      });
    }
  };

useEffect(() => {
  if(user.roles.includes(UserRole.orgRecurringDistributions) && loadingExistingDistribution){
    setShowDateAndStatus(true);
  }
}, [loadingExistingDistribution]);

  async function revertToPending(): Promise<void> {
    await changeRecurringDistributionStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      editRecurringDistribution.recurringDistributionId,
      DistributionStatusState.previous,
      {} as WorkflowDates,
      user.token,
      user
    )
      .then(() => {
        addGlobalMessage('Status Reverted', {
          severity: 'success',
        });
      })
      .catch((err) => {

        setIsLoading(false);
        addGlobalMessage(errorMessages(err) || 'Error reverting status');
      });
  }

  const mergeAndSaveDistribution = async (
    data: Distribution
  ): Promise<void> => {
    setIsLoading(true);

    if (isAwaiting) {
      await revertToPending();
    }

    await saveFMVRecord(data);

    await createOrSaveRecurringDistribution(
      pageStateToRecurringDistribution(data), // Here we use a factory to produce the distribution
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(() => {
        setIsLoading(false);
        isEditing(false, recurringDistributionId);
      })
      .catch((err) => {

        setIsLoading(false);
        addGlobalMessage(errorMessages(err) || 'Error saving distribution');
      });
  };

  const RecurringDistributionSteps: Array<TransactionStep> = [
    {
      label: 'Select Distribution Method',
      stepContent: (
        <Box mt={3} mb={3}>
          <DistributionMethodForm
            onSubmit={mergeAndSaveDistribution}
            initialValues={{
              ...DISTRIBUTION_METHOD_INIT,
              ...editRecurringDistribution,
            }}
            onCancel={cancel}
            submitName="Submit"
            distributionReason={editRecurringDistribution.distributionReason}
            accountType={accountType}
            distributionType="newRecurringDistribution"
            showDateAndStatus={showDateAndStatus}
          />
        </Box>
      ),
    },
  ];

  const recurringDistributionSteps = () => {
    return RecurringDistributionSteps;
  };

  return (
    <>
      <Box mt={5} pl={2}>
        <TransactionStepper
          steps={recurringDistributionSteps()}
          activeStep={pageState.activeStep}
          isLoading={isLoading}
          onStepClick={(index) => {
            if (
              !loadingExistingDistribution ||
              (loadingExistingDistribution && index > 0)
            ) {
              skipStep(index);
            }
          }}
        />
      </Box>
    </>
  );
}

export default EditRecurringDistribution;
