import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgAccountNew(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M16 2a2 2 0 012 2v9.174A6.503 6.503 0 0013.498 22L5 22a2 2 0 01-2-2V4a2 2 0 012-2h11zM7 13H6v1h1v-1zm6 .143H8v.714h5v-.714zM7 11H6v1h1v-1zm8 0H8v1h7v-1zM7 9H6v1h1V9zm8 0H8v1h7V9zM7 7H6v1h1V7zm8 0H8v1h7V7z"
          fill="currentColor"
        />
        <path
          d="M19.5 15a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm.5 2h-1v2h-2v1h1.999L19 22h1l-.001-2H22v-1h-2v-2z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgAccountNew;
