import React from 'react';
import { Typography } from '@mui/material';
import { endOfDay, isAfter, parseISO } from 'date-fns';
import * as yup from 'yup';
import NewAccountModalReasons from '../newAccount/newAccountModal';
import { DepositType } from '../../../api/ContributionApi.d';
import { AccountType } from '../../../api/AccountApi.d';

export const requestRolloverTransfer = {
  relationshipToCurrentOwnerTxt:
    'Choose the relationship to the current account owner and enter that individual’s information, if applicable',
  spouseBeneficiary:
    'I am the spouse beneficiary of the original account owner who is deceased',
  spouseBeneficiaryIRA:
    'I am the spouse beneficiary of the inherited IRA owner who is deceased',
  qaulifiedText: 'qualified member of the designated beneficiary’s family',
  tradrothIra: 'once-per-lifetime',
  inheritedTradRothIra:
    'Only assets from the same deceased owner may be transferred or directly rolled over to the inherited IRA. If the assets are currently held in an account for a different deceased owner, they must be moved into a separate inherited IRA.',
  sourceAssetLbl:
    'Only assets from the same deceased owner may be transferred or directly rolled over to the inherited IRA. If the assets are currently held in an account for a different deceased owner, they must be moved into a separate inherited IRA.',
  olderTradRothSimpleIRA: {
    simpleIra: (
      <Typography variant="body2">
        If the current account owner is under age 59½, it must be two or more
        years since the first SIMPLE IRA contribution was made to the SIMPLE
        IRA.
      </Typography>
    ),
    employerSponsorRetirementPlan: (
      <Typography variant="body2">
        Eligible plans include qualified plans (i.e., 401(k) plans or
        profit-sharing plans), certain 457(b) governmental plans, 403(b)
        arrangements, and 403(a) arrangements.
      </Typography>
    ),
  },
  underSimpleIRA: {
    tradIRA: (
      <Typography variant="body2">
        To receive a transfer from a Traditional IRA, it must be two or more
        years since the first SIMPLE IRA contribution was made to the receiving
        SIMPLE IRA.
      </Typography>
    ),
    employerSponsorRetirementPlan: (
      <Typography variant="body2">
        To receive a direct rollover from an employer-sponsored retirement plan,
        it must be two or more years since the first SIMPLE IRA contribution was
        made to the receiving SIMPLE IRA. Eligible plans include qualified plans
        (i.e., 401(k) plans or profit-sharing plans), certain 457(b)
        governmental plans, 403(b) arrangements, and 403(a) arrangements.
      </Typography>
    ),
  },
  hsa: {
    tradRothIRA: (
      <Typography variant="body2">
        If an HSA eligible individual, the owner may complete a qualified HSA
        funding distribution, which is a once-per-lifetime, tax- and
        penalty-free transfer of IRA assets to an HSA. The contribution cannot
        exceed the individual’s HSA contribution limit, and it is included as
        part of the owner’s contribution amount for the year.
      </Typography>
    ),
    archerMsa: (
      <Typography variant="body2">
        Created to help self-employed individuals and employees of certain small
        employers meet the medical care costs of the account owner, the owner’s
        spouse, or the owner’s dependent(s). Archer MSAs can no longer be
        established after December 31, 2007, but they may be transferred into an
        HSA.
      </Typography>
    ),
  },
  inheritedTradIra: {
    employerSponsorRetirementPlan: (
      <Typography variant="body2">
        Eligible plans include qualified plans (i.e., 401(k) plans or
        profit-sharing plans), certain 457(b) governmental plans, 403(b)
        arrangements, and 403(a) arrangements.
      </Typography>
    ),
  },
  relationshipToCurrentOwnerInfo: (
    <Typography variant="body2">
      Only assets from the same deceased owner may be transferred or directly
      rolled over to the inherited IRA. If the assets are currently held in an
      account for a different deceased owner, they must be moved into a separate
      inherited IRA.
    </Typography>
  ),
};
export const modal = {
  qaulifiedDesBene: (
    <Typography variant="body2">
      The new designated beneficiary must be a{' '}
      <NewAccountModalReasons
        textHyperLink={requestRolloverTransfer.qaulifiedText}
        depositType={DepositType.esalbl}
      />{' '}
      who is under age 30.
    </Typography>
  ),
  tradRothIra: (
    <Typography variant="body2">
      If an HSA eligible individual, the owner may complete a qualified HSA
      funding distribution, which is a{' '}
      <NewAccountModalReasons
        textHyperLink={requestRolloverTransfer.tradrothIra}
        depositType={DepositType.hsalbl}
      />{' '}
      , tax- and penalty-free transfer of IRA assets to an HSA. The contribution
      cannot exceed the individual’s HSA contribution limit, and it is included
      as part of the owner’s contribution amount for the year.{' '}
    </Typography>
  ),
};
export function getOwnerRadioOptions(sourceAccountType) {
  let ownerRadioOption;
  if (sourceAccountType === AccountType.traditionalSep) {
    ownerRadioOption = requestRolloverTransfer.spouseBeneficiaryIRA;
  } else {
    ownerRadioOption = requestRolloverTransfer.spouseBeneficiary;
  }

  return ownerRadioOption;
}

export function getTransferAfterContentDisplay(
  isEarlyDistribution,
  accountType,
  transferTo
) {
  let afterContent;

  if (isEarlyDistribution) {
    if (
      [
        AccountType.traditionalIra,
        AccountType.rothIra,
        AccountType.simpleIra,
      ].includes(accountType)
    ) {
      if (transferTo === AccountType.simpleIra) {
        afterContent = requestRolloverTransfer.olderTradRothSimpleIRA.simpleIra;
      }

      if (transferTo === AccountType.employer) {
        afterContent =
          requestRolloverTransfer.olderTradRothSimpleIRA
            .employerSponsorRetirementPlan;
      }
    }

    if ([AccountType.simpleIra].includes(accountType)) {
      if (transferTo === AccountType.traditionalIra) {
        afterContent = requestRolloverTransfer.underSimpleIRA.tradIRA;
      }

      if (transferTo === AccountType.employer) {
        afterContent =
          requestRolloverTransfer.underSimpleIRA.employerSponsorRetirementPlan;
      }
    }

    if ([AccountType.hsa].includes(accountType)) {
      if (
        transferTo === AccountType.traditionalIra ||
        transferTo === AccountType.rothIra
      ) {
        afterContent = modal.tradRothIra;
      }

      if (transferTo === AccountType.msa) {
        afterContent = requestRolloverTransfer.hsa.archerMsa;
      }
    }

    if ([AccountType.inheritedTraditional].includes(accountType)) {
      if (transferTo === AccountType.employer) {
        afterContent =
          requestRolloverTransfer.inheritedTradIra
            .employerSponsorRetirementPlan;
      }
    }
  } else {
    if (
      [
        AccountType.traditionalIra,
        AccountType.rothIra,
        AccountType.simpleIra,
      ].includes(accountType)
    ) {
      if (transferTo === AccountType.simpleIra) {
        afterContent = requestRolloverTransfer.olderTradRothSimpleIRA.simpleIra;
      }

      if (transferTo === AccountType.employer) {
        afterContent =
          requestRolloverTransfer.olderTradRothSimpleIRA
            .employerSponsorRetirementPlan;
      }
    }

    if ([AccountType.hsa].includes(accountType)) {
      if (
        transferTo === AccountType.traditionalIra ||
        transferTo === AccountType.rothIra
      ) {
        afterContent = modal.tradRothIra;
      }

      if (transferTo === AccountType.msa) {
        afterContent = requestRolloverTransfer.hsa.archerMsa;
      }
    }

    if ([AccountType.inheritedTraditional].includes(accountType)) {
      if (transferTo === AccountType.employer) {
        afterContent =
          requestRolloverTransfer.inheritedTradIra
            .employerSponsorRetirementPlan;
      }
    }
  }

  return afterContent;
}

export function dateValidation(minDate: Date = endOfDay(new Date(1900, 1, 1))) {
  return yup
    .string()
    .matches(/[1,2]{1}\d{3}-\d{2}-\d{2}/, 'Use 4 digit year')
    .test('valid Date', 'Invalid Date', (value) => {
      let x = null;
      try {
        x = new Date(value);
      } catch (err) {
        return false;
      }
      return x !== null;
    })
    .test(
      'min Date',
      `Date must be after ${minDate.toLocaleString('en-US').slice(0, 10)}`,
      (value) => {
        const asDate = endOfDay(parseISO(value));
        return value ? isAfter(asDate, minDate) : true;
      }
    );
}

export function dateEntryValidation() {
  return yup
    .string()
    .matches(/[1,2]{1}\d{3}-\d{2}-\d{2}/, 'Use 4 digit year')
    .test('valid Date', 'Invalid Date', (value) => {
      let x = null;
      try {
        x = new Date(value);
      } catch (err) {
        return false;
      }
      return x !== null;
    });
}
