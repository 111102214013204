import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import SiraSearchField from '../SiraSearchField';
import { UsersResponse } from '../../../api/UserApi.d';

interface RmdNotificationSearchFormProps {
  setResponse: Function;
}

interface RmdNotificationSearchFormData {
  query: string;
}

const SEARCH_QUERY_SCHEMA = yup.object().shape({
  query: yup.string().notRequired().max(60).label('Search term'),
});

function RmdNotificationSearchForm(props: RmdNotificationSearchFormProps) {
  const { setResponse = () => {} } = props;

  async function submitSearch(data: UsersResponse): Promise<void> {
    setResponse(data);
  }

  return (
    <Formik
      initialValues={{ query: '' }}
      onSubmit={async (data: RmdNotificationSearchFormData) => {
        await submitSearch(data);
      }}
      validationSchema={SEARCH_QUERY_SCHEMA}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box maxWidth="sm" pt={1}>
            <SiraSearchField name="query" label="Enter name or tax id" />
          </Box>
          {isSubmitting && (
            <Box width="1" mt={2}>
              <LinearProgress color="secondary" />
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default RmdNotificationSearchForm;
