/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Alert,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';

import StepButtonBar from '../steps/StepButtonBar';
import SiraSelectField from './SiraSelectField';
import ScrollToError from './ScrollToError';

export interface FileColumns {
  headerMappings?: any;
}

export const FILE_COLUMNS_INIT: FileColumns = {
  headerMappings: {},
};

export interface FileColumnsFormProps {
  ourHeaders: Array<string>;
  fileHeaders: Array<string>;
  rowData: Array<any>;
  initialValues: FileColumns;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

export interface FileColumnsTableProps {
  ourHeaders: Array<string>;
  fileHeaders: Array<string>;
  rowData: Array<any>;
}

const FileColumnsTable = (props: FileColumnsTableProps) => {
  const { ourHeaders, fileHeaders, rowData } = props;
  const { values } = useFormikContext();
  const { headerMappings } = values as any;

  // Select options contain the file's headers plus an empty option
  const fileHeaderOptions = [
    { label: '[None]', value: '' },
    ...fileHeaders.map((value) => ({
      label: value,
      value,
    })),
  ];

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ border: '1px solid #aaa' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {/* Map out all of the header options */}
            {ourHeaders.map((ourHeader) => (
              <TableCell key={`options_${ourHeader}`}>
                <Box mt={1}>
                  <SiraSelectField
                    label={ourHeader}
                    name={`headerMappings.${ourHeader}`}
                    items={fileHeaderOptions}
                  />
                </Box>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {/* Map out all of the headers that are assigned */}
            {ourHeaders.map((ourHeader) => (
              <TableCell
                key={`header_${ourHeader}`}
                sx={{ maxWidth: 200, textOverflow: 'ellipsis' }}
              >
                <Typography variant="body2" noWrap component="del">
                  {headerMappings[ourHeader] || '---'}
                </Typography>
                <Typography variant="h6" noWrap>
                  {ourHeader}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            position: 'relative',
            ':after': {
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '42px',
              bottom: 0,
              background:
                'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.33) 100%)',
              borderBottom: '4px double #555',
              marginBottom: '2px',
            },
          }}
        >
          {rowData.map((rowDatum, rowIndex) => (
            <TableRow key={`row_${rowIndex}`}>
              {/* Look up the datum based on the column mapping */}
              {Object.values(headerMappings).map((value: string, cellIndex) => (
                <TableCell
                  key={`cell_${cellIndex}`}
                  sx={{
                    maxWidth: 156,
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography variant="body2" noWrap>
                    {rowDatum[value] || '---'}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FileColumnsForm = (props: FileColumnsFormProps) => {
  const {
    initialValues,
    onSubmit,
    onReset,
    submitName,
    resetName,
    ourHeaders = [],
    fileHeaders = [],
    rowData = [],
  } = props;

  // Attempt to match up the columns by index if not already set
  const COLUMN_MAP_INIT = Object.keys(initialValues.headerMappings).length
    ? initialValues.headerMappings
    : ourHeaders.reduce((acc, cur, i) => {
        acc[cur] = fileHeaders[i] || '';
        return acc;
      }, {});

  // Add validation for accepted header fields as they come in
  const FILE_COLUMNS_SCHEMA = yup.object({
    headerMappings: yup.object().shape(
      ourHeaders.reduce((acc, cur) => {
        acc[cur] = yup.string();
        return acc;
      }, {})
    ),
  });

  return (
    <Formik
      initialValues={{ ...initialValues, headerMappings: COLUMN_MAP_INIT }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      validationSchema={FILE_COLUMNS_SCHEMA}
      enableReinitialize
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <ScrollToError inline="center" />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Use the options below to assign the accepted column headers to
                  those from the uploaded file.
                </Typography>
              </Grid>

              {Boolean(Object.keys(values.headerMappings).length) && (
                <Grid item xs={12}>
                  <FileColumnsTable
                    ourHeaders={ourHeaders}
                    fileHeaders={fileHeaders}
                    rowData={rowData}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Alert severity="info" sx={{ mb: 1 }}>
                  The file will upload using the corrected column headers shown
                </Alert>
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FileColumnsForm;
