import React from 'react';
import { Grid } from '@mui/material';
import MinimizeOutlinedIcon from '@mui/icons-material/MinimizeOutlined';

import SiraDateField from './SiraDateField';

function SiraDateRangeField() {
  return (
    <>
      <Grid item>
        <SiraDateField name="startDate" label="Start date" />
      </Grid>

      {/* Hide this icon for mobile screens when stacking the fields */}
      <Grid item display={{ xs: 'none', sm: 'flex' }}>
        <MinimizeOutlinedIcon />
      </Grid>

      <Grid item>
        <SiraDateField name="endDate" label="End date" />
      </Grid>
    </>
  );
}

export default SiraDateRangeField;
