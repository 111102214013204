import React from 'react';
import { Switch, SwitchProps } from '@mui/material';
import { FieldAttributes, useField } from 'formik';

interface SiraSwitchFieldProps extends SwitchProps {
  // TODO: Add some custom labeling fanciness here
}

const SiraSwitchField: React.FC<FieldAttributes<SiraSwitchFieldProps>> = ({
  ...switchProps
}) => {
  const [field] = useField<{}>(switchProps);
  return <Switch {...field} {...switchProps} />;
};

export default SiraSwitchField;
