import React, { useEffect, useState } from 'react';
import { Params, useLocation } from 'react-router-dom';
import {
  Paper,
  Box,
  Typography,
  Grid,
  LinearProgress,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { useUser } from '../auth/useUser';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import { useGlobalContext } from '../auth/useGlobalContext';
import { ExternalAccount } from '../api/ExternalAccountApi.d';
import {
  createOrSaveExternalAccount,
  getExternalAccount,
} from '../api/ExternalAccountApi';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { UserRole } from '../api/UserApi.d';
import ExternalAccountForm, {
  EXTERNAL_ACCOUNT_INIT,
} from '../components/form/inheritedIra/ExternalAccountForm';
import { AccountMemberValue } from '../api/OrganizationApi.d';

export interface AccountOwnerPageParams extends Params {
  accountOwnerId: string;
  accountId: string;
}

// function ExternalAccountOwnerProfileForm(){

// }

function ExternalAccountOwnerProfile() {
  let isMounted = true;
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage , organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  // Query param that allows a url direct to an accordion
  const queryParams = new URLSearchParams(useLocation().search);
  const [externalOwner, setExternalOwner] = useState({} as ExternalAccount);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Current Account Number': 'Current Member Number';


  async function getExternalAccountOwner(): Promise<void> {
    getExternalAccount(
      queryParams.get('externalAccountOwnerId'),
      user.organizationId,
      user.token
    )
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setExternalOwner(res.data);
        }
      })
      .catch((err) => {
        const { response: { data = {} } = {} } = err;
        if (isMounted) {
          addGlobalMessage(
            data.message || 'Error fetching external account profile'
          );
        }
      });
  }

  const createExternalAccountOwner = async (
    formData: ExternalAccount
  ): Promise<void> => {
    await createOrSaveExternalAccount(
      formData as ExternalAccount,
      user.organizationId,
      user.token
    )
      .then(({ data }) => {
        setExternalOwner(data);
        setIsEditing(false);
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage('Unable to create external deceased account owner');
        }
      });
  };

  useEffect(() => {
    if (user.token && user.organizationId) {
      setIsLoading(true);
      getExternalAccountOwner();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="h1" color="secondary" gutterBottom>
           Account Owner Profile
          </Typography>{' '}
          {isLoading ? (
            <Box mt={4}>
              <LinearProgress color="secondary" />
            </Box>
          ) : (
            <Paper elevation={3}>
              <Box mt={2} p={4}>
                {!isEditing && (
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={externalOwner.middleInitial ? 4 : 6}
                      >
                        <Typography variant="subtitle2" gutterBottom>
                          First Name
                        </Typography>

                        <Typography color="primary">
                          {externalOwner.firstName}
                        </Typography>
                      </Grid>{' '}
                      {externalOwner.middleInitial && (
                        <Grid item xs={12} sm={3}>
                          <Typography variant="subtitle2" gutterBottom>
                            Middle Initial
                          </Typography>

                          <Typography color="primary">
                            {externalOwner.middleInitial}
                          </Typography>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={externalOwner.middleInitial ? 5 : 6}
                      >
                        <Typography variant="subtitle2" gutterBottom>
                          Last Name
                        </Typography>

                        <Typography color="primary">
                          {externalOwner.lastName}
                        </Typography>
                      </Grid>{' '}
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          SSN/ID
                        </Typography>
                        <Typography variant="body1">
                          <ObscuredTaxId
                            taxId={externalOwner.taxpayerIdNumber}
                            format={TaxIdFormat.ssn}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Date of Birth{' '}
                        </Typography>

                        <Typography color="primary">
                          {externalOwner.dateOfBirth}
                        </Typography>
                      </Grid>{' '}
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Date of Death{' '}
                        </Typography>

                        <Typography color="primary">
                          {externalOwner.dateOfDeath}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Account Type
                        </Typography>

                        <Typography color="primary">
                          {getAppConstant(
                            ConstantsMappingKey.accountType,
                            externalOwner.accountType
                          )}
                        </Typography>
                      </Grid>{' '}
                      {externalOwner.accountNumber && (
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle2" gutterBottom>
                            {headerAccountName}
                          </Typography>

                          <Typography color="primary">
                            {externalOwner.accountNumber}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>

                    {user.roles.includes(UserRole.orgTransactionsAdmin) && (
                      <Box mt={5} mb={1}>
                        <Button
                          aria-label="Edit External account owner"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setIsEditing(true);
                          }}
                          startIcon={<EditIcon />}
                        >
                          Edit Details
                        </Button>
                      </Box>
                    )}
                  </>
                )}
                {isEditing && (
                  <Box mt={4} mb={4}>
                    <ExternalAccountForm
                      initialValues={{
                        ...EXTERNAL_ACCOUNT_INIT,
                        ...externalOwner,
                      }}
                      onSubmit={createExternalAccountOwner}
                      onCancel={() =>{setIsEditing(false)}}
                    />
                  </Box>
                )}
              </Box>
            </Paper>
          )}
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default ExternalAccountOwnerProfile;
