import React, { useState } from 'react';

import { Box, Paper, Typography, Button, Popover, Grid } from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';

import { Organization } from '../../../api/OrganizationApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { usePaginationContext } from '../../../auth/useGlobalContext';
import { globalPaginationOptions } from '../../../app.constants';

interface RecentOrganizationSearchProps {
  response: Array<Organization>;
  onResultClick?: Function;
}

export function RecentOrganizationSearch(props: RecentOrganizationSearchProps) {
  const { response = [], onResultClick = () => {} } = props;
  const {  setGlobalPageSize } = usePaginationContext();
  const classes = {
    organizationLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  const columns = [
    {
      field: 'financialOrganizationName',
      headerName: 'Name',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="searchResult_button"
            onClick={() => {
              onResultClick(row);
            }}
            sx={classes.organizationLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'financialOrganizationLegalName',
      headerName: 'Legal Name',
      width: 256,
    },
    {
      field: 'financialOrganizationId',
      headerName: 'ID',
      width: 72,
    },
    {
      field: 'financialOrganizationFederalTaxId',
      headerName: 'Federal Tax ID',
      width: 156,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ein} />
        );
      },
    },
    {
      field: 'primaryBranchStateAbbreviation',
      headerName: 'State',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const { value = '', row = {} } = params;
        const [orgStateAnchor, setOrgStateAnchor] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setOrgStateAnchor(event.currentTarget);
        };

        const handleClose = () => {
          setOrgStateAnchor(null);
        };

        return (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <Button onClick={handleClick}>
                <Typography variant="body2">{value.toString()}</Typography>
              </Button>
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(orgStateAnchor)}
              onClose={handleClose}
              anchorEl={orgStateAnchor}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">
                  {row.primaryBranchStateName}
                </Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
  ];

  const rows = response.map((result, id) => {
    return {
      ...result,
      id,
    };
  });

  return (
    <>
      <Paper elevation={3}>
        <Box p={2}>
          <Box mt={4}>
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              autoHeight
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              disableColumnMenu
              rows={rows}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default RecentOrganizationSearch;
