import React, { ReactChild } from 'react';
import { Slider, SliderProps, Typography } from '@mui/material';
import { FieldAttributes, useField } from 'formik';

interface SiraSliderProps extends SliderProps {
  label?: string;
  afterContent?: ReactChild;
}

const SiraSlider: React.FC<FieldAttributes<SiraSliderProps>> = ({
  label = '',
  afterContent = null,
  ...sliderProps
}) => {
  const [field, meta] = useField<{}>(sliderProps);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <>
      {Boolean(label) && (
        <Typography id="input-slider-label" gutterBottom>
          {label}
        </Typography>
      )}
      <Slider
        {...field}
        {...sliderProps}
        aria-labelledby="input-slider-label"
      />
      {Boolean(errorText) && (
        <Typography color="error" variant="body2">
          {errorText}
        </Typography>
      )}
      {afterContent}
    </>
  );
};

export default SiraSlider;
