import React, { ReactElement, useEffect, useState } from 'react';
import {
  useTheme,
  Container,
  Grid,
  Box,
  Drawer,
  useMediaQuery,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { makeStyles } from '@mui/styles';
import LeftNav from './LeftNav';
import SuperiorAppBar from './appBar/StandardAppBar';
import Breadcrumbs from './Breadcrumbs';
import SiraLayoutError from './SiraLayoutError';
import SuperiorFooter from './SuperiorFooter';
import { useUser } from '../auth/useUser';
import { UserRole } from '../api/UserApi.d';
import { useGlobalContext } from '../auth/useGlobalContext';

interface LayoutProps {
  children: ReactElement | ReactElement[];
}

const Layout = ({ children }: LayoutProps) => {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    drawerPaper: {
      backgroundColor: theme.palette.primary.main, // Replace with your desired background color
      // Add other styles as needed
    },
  }));
  const drawerClasses = useStyles();
  const classes = {
    root: {
      minHeight: '100vh',
      backgroundImage: `linear-gradient(#07141c, ${theme.palette.primary.main})`,
    },
    container: {
      [theme.breakpoints.down('md')]: {
        padding: '0',
      },
    },
    contentGrid: {
      background: `linear-gradient(${theme.palette.grey[100]}, ${theme.palette.grey[200]})`,
      paddingBottom: '5%',
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary,
    },
  };
  const [openDrawer, setOpenDrawer] = useState(false as boolean);

  useEffect(() => {
    // Adding this here for theme access
    window.document.body.style.backgroundColor = theme.palette.primary.main;
  }, []);
  const { user } = useUser();
  const isSmallScreen = useMediaQuery('(max-width: 1000px)');
  const [screenSize, setScreenSize] = useState(false as boolean);
  const [hasMatchingRoles, setHasMatchingRoles] = useState(true as Boolean);
  const [gridSize, setGridSize] = useState(12 as number);
  const [showBreadcrumb, setShowBreadcrumb] = useState(false as boolean);
  const { customerPortalUser } = useGlobalContext();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width >= 1001 && width < 1202) {
        setScreenSize(true);
      } else {
        setScreenSize(false);
      }
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Call the handleResize function initially
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // TODO: Add logic to remove signout if user has not been created yet

  useEffect(() => {
    if (user && user.roles) {
      setHasMatchingRoles(user.roles.includes(UserRole.accountOwner));
      if (!user.roles.includes(UserRole.accountOwner)) {
        if (!isSmallScreen) {
          if (screenSize) {
            setGridSize(8);
          } else {
            setGridSize(9);
          }
        }
      }

      if(user.roles.includes(UserRole.accountOwner) && customerPortalUser){
        setShowBreadcrumb(true);
      }

      if(user.roles.includes(UserRole.accountOwner) && !customerPortalUser){
        setShowBreadcrumb(false);
      }

      if (!user.roles.includes(UserRole.accountOwner)) {
        setShowBreadcrumb(true);
      }

    }
  }, [user]);


  return (
    <Box sx={classes.root}>
      <SuperiorAppBar
        openNav={() => {
          setOpenDrawer(true);
        }}
      />
      <Container maxWidth="xl" sx={classes.container}>
        <Grid container>
          {isSmallScreen
            ? !hasMatchingRoles && (
                <Drawer
                  classes={{ paper: drawerClasses.drawerPaper }}
                  color="primary"
                  anchor="left"
                  open={openDrawer}
                  onClose={() => {
                    setOpenDrawer(false);
                  }}
                >
                  <LeftNav />
                </Drawer>
              )
            : !hasMatchingRoles && (
                <Grid item>
                  <LeftNav />
                </Grid>
              )}
          <Grid
            sx={classes.contentGrid}
            item
            xs={12}
            // eslint-disable-next-line no-nested-ternary
            md={gridSize}
          >
            {showBreadcrumb && <Breadcrumbs />}
            {/* Render a general layout-level error */}
            <ErrorBoundary FallbackComponent={SiraLayoutError}>
              <Box>{children}</Box>
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Container>
      <SuperiorFooter />
    </Box>
  );
};

export default Layout;
