import { AccountType } from './AccountApi.d';

export interface ImmSessionResponse {
  data: string;
  errorMessage: any;
}

export interface ImmSessionRequest {
  email: string;
  phone: string;
  eSignUserId: string;
  fullName: string;
  firstName: string;
  taxIdNumber: string;
  lastName: string;
  accountType: AccountType;
  accountNumber: string;
  immSignatureUserType: IMMSignatureUserType;
}

export enum IMMSignatureUserType {
  accountOwner = 'ACCOUNT_OWNER',
  customerPortal = 'MAIN',
  grantor = 'GRANTOR',
  trustee = 'TRUSTEE',
  spouse = 'SPOUSE',
}

export enum IMMDocumentType {
  account = 'NEW_ACCOUNT',
  contribution = 'CONTRIBUTION',
  distribution = 'DISTRIBUTION',
  recurringDistribution = 'RECURRING_DISTRIBUTION',
  transfer = 'TRANSFER_REQUEST',
  beneficiaries = 'UPDATE_BENEFICIARIES',
}

export interface SuperiorIraEsignReponseData {
  geteSignatureId: number;
  financialOrganizationId: number;
  accountOwnerId: number;
  signature: string;
  signatureText: string;
}

export interface SuperiorIraEsignReponse {
  data: SuperiorIraEsignReponseData;
  errorMessage: any;
}
