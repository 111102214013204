import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, LinearProgress, Grid, useTheme } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { endOfMonth, format, getYear, parseISO, startOfMonth } from 'date-fns';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import {
  distributionMethodShortNames,
  globalPaginationOptions,
} from '../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import {
  currencyValueFormatter,
  dateValueFormatter,
  percentFormatter,
} from '../utils/DataGrid.utils';
import { useUser } from '../auth/useUser';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import { AccountDetailAccordionType } from '../components/accountOwnerProfile/AccountOwnerAccountDetails';
import { getActiveDistributions } from '../api/DistributionApi';
import { DistributionSearchResult } from '../api/DistributionApi.d';
import ReportsSearchForm from '../components/form/reports/ReportsSearchForm';
import { AccountType } from '../api/AccountApi.d';
import TaxYearsFilterForm from '../components/form/TaxYearsFilterForm';
import AccountTypesSelectField from '../components/form/AccountTypesSelectField';
import MultiButton, { MultiButtonAction } from '../components/MultiButton';
import { exportDistributions } from '../api/TaxDocumentApi';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import { errorMessages } from '../utils/errorhandling.utils';

interface ActiveDistributionRow extends DistributionSearchResult {
  year: string;
  id: number;
}

// Flatten and map out the distributions for display in the data grid
function ActiveDistributionsRowFactory(
  distributions: Array<DistributionSearchResult>
): Array<ActiveDistributionRow> {
  return distributions.map((result, id) => ({
    ...result,
    year: getYear(parseISO(result.effectiveDate)).toString(), // Used by the tax year filter
    id,
  }));
}

function ReportsDistribution() {
  let isMounted = true;
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage ,
    organization: { accountNumberValue },
  } = useGlobalContext();
  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account #': 'Member #';

  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [accountTypeFilter, setAccountTypeFilter] = useState(
    [] as Array<AccountType>
  );
    const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState(
    [] as Array<ActiveDistributionRow>
  );
  const [rows, setRows] = useState([] as Array<ActiveDistributionRow>);
  const [startDateParam, setStartDateParam] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [endDateParam, setEndDateParam] = useState(
    format(endOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [selectedYears, setSelectedYears] = useState([]);

  const classes = {
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      justifyContent: 'flex-end' as any,
    },
  };

    const exportOrgDistributions = async (fileType): Promise<void> => {

      await exportDistributions(
        user.organizationId,
        user.token,
        fileType,
        startDateParam,
        endDateParam,     
        selectedYears,
        accountTypeFilter,
      )
        .then(() => {
          setLoading(false);
          addGlobalMessage(
            'Your file will be available shortly under Notifications, located at the top of the page.',
            {
              severity: 'success',
            }
          );
        })
        .catch(() => {
          setLoading(false);
          addGlobalMessage('Error exporting organization distributions');
        });
    };

    // Define the download types
    const downloadActions: Array<MultiButtonAction> = [
      {
        label: 'Download *.tsv',
        handleAction: () => {
          setLoading(true);
          exportOrgDistributions('tsv');
        },
        icon: <DownloadIcon />,
      },
      {
        label: 'Download *.xlsx',
        handleAction: () => {
          setLoading(true);
          exportOrgDistributions('xlsx');
        },
        icon: <DownloadIcon />,
      },
    ];

  // Apply all filters to the supplied rows
  const applyFilters = (rowsToFilter) => {
    const matchingRows = accountTypeFilter.length
      ? rowsToFilter.filter(({ accountType }) =>
          accountTypeFilter.includes(accountType)
        )
      : rowsToFilter;

      const years = [
        ...new Set(matchingRows.map(({ year }) => String(year))),
      ];
  
  
      setFilteredRows(matchingRows);
      setSelectedYears(years)
    
  };

  // Map out relevant options for the org active deposits
  const taxYearFilterOptions: Array<string> = [
    ...new Set(rows.map(({ year }) => String(year))),
  ];

  // Fetch all active distributions for the current organization
  const getAndSetActiveOrgDistributions = async ({
    startDate,
    endDate,
  }): Promise<void> => {
    setIsLoading(true);
    setStartDateParam(startDate);
    setEndDateParam(endDate);
    await getActiveDistributions(
      user.organizationId,
      { startDate, endDate },
      user.token
    )
      .then(({ data = [] }) => {
        if (isMounted) {
          const preppedRows = ActiveDistributionsRowFactory(data);
          if (isMounted) {
            setRows(preppedRows);
            applyFilters(preppedRows);
          }
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            'Error fetching organization withholding notifications'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  };

  const gotoRecordAccount = (row: DistributionSearchResult): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.distributions}`
    );
  };

  const columns = [
    {
      field: 'accountOwnerFullName',
      headerName: 'Owner',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              gotoRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 130,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      hideable: true, // Hidden columns will be in the CSV but not the UI grid
      field: 'distributionMethod',
      headerName: 'Distribution Method',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return distributionMethodShortNames[String(value)] || String(value);
      },
    },
    {
      field: 'distributionReason',
      headerName: 'Distribution Reason',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.distributionReason, value);
      },
    },
    {
      field: 'distributionMethod',
      headerName: 'Distribution Method',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return distributionMethodShortNames[String(value)] || String(value);
      },
    },
    {
      field: 'effectiveDate',
      headerName: 'Date',
      width: 100,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'grossAmount',
      headerName: 'Gross Amount',
      width: 126,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'federalWithholdingAmount',
      headerName: 'Federal Withholding',
      width: 176,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'federalWithholdingPercent',
      headerName: 'Federal Withholding Percent',
      valueFormatter: percentFormatter,
    },
    {
      field: 'stateWithholdingAmount',
      headerName: 'State Withholding',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'stateWithholdingPercent',
      headerName: 'State Withholding Percent',
      valueFormatter: percentFormatter,
    },
    {
      field: 'withholdingState',
      headerName: 'Withholding State',
    },
    {
      field: 'additionalStateWithholding',
      headerName: 'Additional State Withholding',
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'penaltyAmount',
      headerName: 'Penalty',
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'toAccountType',
      headerName: 'DestinationAccount Type',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'toAccountNumber',
      headerName: `Destination ${headerAccountName}`,
    },

    {
      field: 'toAccountTypeName',
      headerName: 'Destination Account Type Name',
    },

    {
      field: 'toFinancialOrganization',
      headerName: 'Destination Financial Organization',
    },
    {
      field: 'netIncomeAttributable',
      headerName: 'Net Income Attributable',
      valueFormatter: currencyValueFormatter,
    },
  ] as GridColDef[];

  useEffect(() => {
    applyFilters(rows);

    return () => {
      isMounted = false;
    };
  }, [accountTypeFilter]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Reports and Statistics
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Distributions
          </Typography>

          <ReportsSearchForm
            initialValues={{
              startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
              endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
            }}
            onSubmit={getAndSetActiveOrgDistributions}
          />

          <AccountTypesSelectField
            onChange={setAccountTypeFilter}
            accountTypes={accountTypeFilter}
          />

          <TaxYearsFilterForm
            items={rows}
            options={taxYearFilterOptions}
            onChange={applyFilters}
          />

          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <Box p={2}>
              <Grid container wrap="nowrap" spacing={2} sx={classes.buttonBar}>
                <Grid item>
                  <MultiButton
                    actions={downloadActions}
                    sx={{ whiteSpace: 'nowrap' }}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    endIcon={<ExpandMoreIcon />}
                  >
                    Export Distribution
                  </MultiButton>
                </Grid>
              </Grid>
              <Box mt={4}>
                <DataGrid
                  components={{
                    NoRowsOverlay: SiraNoRowsOverlay,
                  }}
                  disableColumnMenu
                  initialState={{
                    pagination: { paginationModel: { pageSize: globalPageSize } },
                    columns: {
                      columnVisibilityModel: {
                        distributionReason: false,
                        distributionMethod: false,
                        federalWithholdingPercent: false,
                        stateWithholdingPercent: false,
                        withholdingState: false,
                        additionalStateWithholding: false,
                        penaltyAmount: false,
                        toAccountType: false,
                        toAccountNumber: false,
                        toAccountTypeName: false,
                        toFinancialOrganization: false,
                        netIncomeAttributable: false,
                      },
                    },
                  }}
                  pageSizeOptions={globalPaginationOptions}
                  onPaginationModelChange={setGlobalPageSize}
                  autoHeight
                  columns={columns}
                  rows={filteredRows}
                />
              </Box>
            </Box>
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default ReportsDistribution;
