import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgRecurringDistributionEdit(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M8 1v2h8V1h2v2h1c1.1 0 2 .9 2 2v8h-2V8H5v11h8v1.5c0 .17.012.337.036.5H5a2 2 0 01-2-2l.01-14c0-1.1.88-2 1.99-2h1V1h2zm1 9v2H7v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2zM14 21.228V23h1.772L21 17.772 19.228 16 14 21.228zm8.85-5.163c.2-.2.2-.52 0-.72l-1.195-1.196c-.2-.199-.52-.199-.72 0l-.935.935L21.916 17l.935-.935z" />
      </g>
    </SvgIcon>
  );
}

export default SvgRecurringDistributionEdit;
