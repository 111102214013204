import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  Beneficiary,
  Beneficiaries,
  BeneficiaryVersionsResponse,
  BeneficiaryStatus,
  BeneficiaryResponse,
  BeneficiaryStatusState,
  BeneficiariesStatusResponse,
  BeneficiaryVersionResponse,
  BeneficiaryTypes,
  WorkflowDates,
} from './BeneficiariesApi.d';
import { Country } from './StatesApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;

const orgBeneficiariesUrl = '/api/org/{0.orgId}/beneficiaries';
const beneficiariesUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaries/{0.beneficiaryId}';
const beneficiariesDeleteUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaries/{0.version}';
const beneficiariesDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaries/{0.beneficiariesVersion}/document';
const beneficiariesStatusUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaries/{0.version}/status?state={0.state}';

function humanBeneficiaryFactory(beneficiary: Beneficiary): Beneficiary {
  const {
    dateOfBirth = '',
    emailAddress = '',
    firstName = '',
    lastName = '',
    middleInitial = '',
    percent = '',
    phoneNumber = '',
    primaryBeneficiary = false,
    relationship = BeneficiaryTypes.DEFAULT,
    taxpayerIdNumber = '',
    country = '' as Country,
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    foreignStateProvinceRegion = '',
    state = '',
    zip = '',
    // NOTE: Uncomment these when the API is ready to accept representativeFirstName and LastName
    // representativeFirstName = '',
    // representativeLastName = '',
  } = beneficiary;

  return {
    dateOfBirth,
    emailAddress,
    firstName,
    lastName,
    middleInitial,
    percent: parseFloat(percent.toString()),
    phoneNumber,
    primaryBeneficiary,
    relationship,
    taxpayerIdNumber,
    country,
    addressLine1,
    addressLine2,
    city,
    foreignStateProvinceRegion,
    state,
    zip,
    // representativeFirstName,
    // representativeLastName,
  };
}

function nonHumanBeneficiaryFactory(beneficiary: Beneficiary): Beneficiary {
  const {
    name = '',
    representativeFirstName = '',
    representativeLastName = '',
    emailAddress = '',
    percent = '',
    phoneNumber = '',
    primaryBeneficiary = false,
    relationship = BeneficiaryTypes.DEFAULT,
    taxpayerIdNumber = '',
    country = '' as Country,
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    foreignStateProvinceRegion = '',
    state = '',
    zip = '',
  } = beneficiary;

  return {
    name,
    representativeFirstName,
    representativeLastName,
    emailAddress,
    percent: parseFloat(percent.toString()),
    phoneNumber,
    primaryBeneficiary,
    taxpayerIdNumber,
    country,
    addressLine1,
    addressLine2,
    relationship,
    city,
    foreignStateProvinceRegion,
    state,
    zip,
  };
}

function normalizeBeneficiaries(beneficiaries: Array<Beneficiary>) {
  return beneficiaries.map((beneficiary) => {
    const isNonHumanBeneficiary = [
      BeneficiaryTypes.TRUST,
      BeneficiaryTypes.ESTATE,
      BeneficiaryTypes.NONEHUMAN,
    ].includes(beneficiary.relationship);
    const normalized = isNonHumanBeneficiary
      ? nonHumanBeneficiaryFactory(beneficiary)
      : humanBeneficiaryFactory(beneficiary);

    return removeEmptyStrings(normalized);
  });
}

export async function getBeneficiaries(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  query: any = {},
  user: any
): Promise<BeneficiaryVersionsResponse> {
  const { status = '' as BeneficiaryStatus } = query;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${beneficiariesUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    beneficiaryId: '',
  })}`;

  const params = removeEmptyStrings({
    status,
  });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createBeneficiaries(
  data: Beneficiaries,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  user: any 
): Promise<BeneficiaryVersionResponse> {
  const {
    primaryBeneficiaries = [],
    secondaryBeneficiaries = [],
    requireSpousalConsent,
    spouseName,
  } = data;
  const url = `${urlBase}${format(`${setBaseUrl(user)}${beneficiariesUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    beneficiaryId: '',
  })}`;
  const dataNorm = {
    requireSpousalConsent,
    spouseName,
    primaryBeneficiaries: normalizeBeneficiaries(primaryBeneficiaries),
    secondaryBeneficiaries: normalizeBeneficiaries(secondaryBeneficiaries),
  };

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updatePendingBeneficiaries(
  data: Beneficiaries,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  user: any
): Promise<BeneficiaryVersionResponse> {
  const {
    requireSpousalConsent,
    spouseName,
    primaryBeneficiaries = [],
    secondaryBeneficiaries = [],
    beneficiaryStatus = BeneficiaryStatus.pending,
  } = data;
  const url = `${urlBase}${format(`${setBaseUrl(user)}${beneficiariesUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    beneficiaryId: '',
  })}`;
  const dataNorm = {
    ...removeEmptyStrings({ beneficiaryStatus }),
    requireSpousalConsent,
    spouseName,
    primaryBeneficiaries: normalizeBeneficiaries(primaryBeneficiaries),
    secondaryBeneficiaries: normalizeBeneficiaries(secondaryBeneficiaries),
  };

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteBeneficiaries(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  version: string,
  token: string,
  user: any
): Promise<any> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${beneficiariesDeleteUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    version,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateBeneficiary(
  data: Beneficiary,
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  user: any
): Promise<BeneficiaryResponse> {
  const { beneficiaryId = '' } = data;
  const isNonHumanBeneficiary = [
    BeneficiaryTypes.TRUST,
    BeneficiaryTypes.ESTATE,
    BeneficiaryTypes.NONEHUMAN,
  ].includes(data.relationship);
  const normalized = isNonHumanBeneficiary
    ? nonHumanBeneficiaryFactory(data)
    : humanBeneficiaryFactory(data);
  const url = `${urlBase}${format(`${setBaseUrl(user)}${beneficiariesUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    beneficiaryId,
  })}`;
  const dataNorm = removeEmptyStrings(normalized);

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getBeneficiariesDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  beneficiariesVersion: string,
  token: string,
  versionID: string = '',
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${beneficiariesDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    beneficiariesVersion,
  })}`;

  const params = removeEmptyStrings({ versionID });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createBeneficiariesDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  beneficiariesVersion: string,
  token: string,
  user: any
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${beneficiariesDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    beneficiariesVersion,
  })}`;

  return axiosInstance.post(url, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeBeneficiariesStatus(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  version: string,
  state: BeneficiaryStatusState,
  workflowDates: WorkflowDates = {},
  token: string,
  user
): Promise<BeneficiariesStatusResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${beneficiariesStatusUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    version,
    state,
  })}`;

  return axiosInstance.put(url, workflowDates, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrgBeneficiaries(
  orgId: string,
  status: string | Array<string>,
  token: string
) {
  const url = `${urlBase}${format(orgBeneficiariesUrl, {
    orgId,
  })}`;
  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
