import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings } from './api.utils';
import {
  FederalRate,
  FederalRateResponse,
  FederalRatesResponse,
} from './SiteApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const federalRatesUrl = '/api/applicablefederalrates';
const federalRateUrl =
  '/api/applicablefederalrates/year/{0.year}/month/{0.month}';

function federalRateCreateFactory(data: any): FederalRate {
  const { year, month } = data;
  const { annual, semiAnnual, quarterly, monthly } = data;

  return {
    applicableFederalRateID: { year, month },
    annual,
    semiAnnual,
    quarterly,
    monthly,
  };
}

function federalRateUpdateFactory(data: any): FederalRate {
  const { applicableFederalRateID, annual, semiAnnual, quarterly, monthly } =
    data;

  return { applicableFederalRateID, annual, semiAnnual, quarterly, monthly };
}

export async function getApplicableFederalRates(
  token: string
): Promise<FederalRatesResponse> {
  const url = `${urlBase}${federalRatesUrl}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createApplicableFederalRate(
  data: FederalRate,
  token: string
): Promise<FederalRateResponse> {
  const url = `${urlBase}${format(federalRatesUrl)}`;

  const dataNorm = federalRateCreateFactory(removeEmptyStrings(data));

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateApplicableFederalRate(
  data: FederalRate,
  year: string,
  month: string,
  token: string
): Promise<FederalRateResponse> {
  const url = `${urlBase}${format(federalRateUrl, {
    year,
    month,
  })}`;

  const dataNorm = federalRateUpdateFactory(removeEmptyStrings(data));

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteApplicableFederalRate(
  year: string,
  month: string,
  token: string
): Promise<FederalRateResponse> {
  const url = `${urlBase}${format(federalRateUrl, {
    year,
    month,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
