import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings } from './api.utils';
import { BatchInfoResponse, BatchStatus, BatchInfo } from './BatchApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const batchUsersUrl = '/api/org/{0.orgId}/batch/users';
const batchAccountsUrl = '/api/org/{0.orgId}/batch/accounts';
const batchCompleteUrl =
  '/api/org/{0.orgId}/batch/{0.batchId}/markascompletedbyuser';
const batchContributionsUrl = '/api/org/{0.orgId}/batch/contributions';
const batchDistributionsUrl = '/api/org/{0.orgId}/batch/distributions';
const batchRecurringDistributionsUrl =
  '/api/org/{0.orgId}/batch/recurringDistributions';
const batchAccountOwnersUpdateUrl =
  '/api/org/{0.orgId}/batch/accountOwnerUpdate';
const batchUrl = '/api/org/{0.orgId}/batch/{0.batchId}';
const batchErrorFileUrl = '/api/org/{0.orgId}/batch/{0.batchId}/errorExport';
const batchItemOverrideUrl =
  '/api/org/{0.orgId}/batchItem/{0.batchItemId}/overrideDuplicateError';
const batchDetailsExportUrl =
  '/api/org/{0.orgId}/batch/{0.batchId}/detailsExport';
const batchFMVUrl = '/api/org/{0.orgId}/batch/fmvImport';




export async function getUsersBatchInfo(
  orgId: string,
  token: string
): Promise<BatchInfoResponse> {
  const url = `${urlBase}${format(batchUsersUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountsBatchInfo(
  orgId: string,
  token: string
): Promise<BatchInfoResponse> {
  const url = `${urlBase}${format(batchAccountsUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getContributionBatchInfo(
  orgId: string,
  token: string
): Promise<BatchInfoResponse> {
  const url = `${urlBase}${format(batchContributionsUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDistributionBatchInfo(
  orgId: string,
  token: string
): Promise<BatchInfoResponse> {
  const url = `${urlBase}${format(batchDistributionsUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getRecurringDistributionBatchInfo(
  orgId: string,
  token: string
): Promise<BatchInfoResponse> {
  const url = `${urlBase}${format(batchRecurringDistributionsUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function get5498BatchInfo(
  orgId: string,
  token: string
): Promise<BatchInfoResponse> {
  const url = `${urlBase}${format(batchRecurringDistributionsUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getFairMarketValueBatchInfo(
  orgId: string,
  token: string
): Promise<BatchInfoResponse> {
  const url = `${urlBase}${format(batchFMVUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountOwnersUpdateBatchInfo(
  orgId: string,
  token: string
): Promise<BatchInfoResponse> {
  const url = `${urlBase}${format(batchAccountOwnersUpdateUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getBatchErrorFile(
  orgId: string,
  batchId: string | number,
  fileType: string = 'tsv',
  token: string
): Promise<any> {
  const url = `${urlBase}${format(batchErrorFileUrl, {
    orgId,
    batchId,
    fileType,
  })}`;

  const params = { fileType };

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}

export async function getBatchDetailsFile(
  orgId: string,
  batchId: string | number,
  fileType: string = 'tsv',
  token: string
): Promise<any> {
  const url = `${urlBase}${format(batchDetailsExportUrl, {
    orgId,
    batchId,
    fileType,
  })}`;

  const params = { fileType };

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}

export async function updateBatchInfo(
  data: BatchInfo,
  orgId: string,
  batchId: string | number,
  status: BatchStatus = '' as BatchStatus,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(batchUrl, {
    orgId,
    batchId,
  })}`;

  const dataNorm = removeEmptyStrings(data);
  const params = removeEmptyStrings({ status });

  return axiosInstance.put(url, dataNorm, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function markBatchCompletedByUser(
  orgId: string,
  batchId: string | number,
  token: string
): Promise<BatchInfo> {
  const url = `${urlBase}${format(batchCompleteUrl, {
    orgId,
    batchId,
  })}`;

  return axiosInstance.put(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export async function overrideBatchItem(
  orgId: string,
  batchItemId: string | number,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(batchItemOverrideUrl, {
    orgId,
    batchItemId,
  })}`;

  return axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
