import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import DefaultRmdSettingsForm, {
  DEFAULT_RMD_SETTINGS_INIT,
} from '../components/form/recurringDistributions/DefaultRmdSettingsForm';
import { useUser } from '../auth/useUser';
import {
  updateAllowedDistribution,
  updateDefaultRmd,
  updateOrg,
} from '../api/OrganizationApi';
import { DefaultRmdFormData } from '../components/form/recurringDistributions/DefaultSettingsForm.d';
import { useGlobalContext } from '../auth/useGlobalContext';
import DefaultDistributionSettingsForm, {
  DEFAULT_DISTRIBUTION_SETTINGS_INIT,
} from '../components/form/recurringDistributions/AllowedDistributionForm';
import ScheduledDistributionSettingsForm, {
  SCHED_DIST_SETTINGS_INIT,
  ScheduledDistributionSettings,
} from '../components/form/recurringDistributions/ScheduledDistributionSettingsForm';
import { errorMessages } from '../utils/errorhandling.utils';

function RecurringDistributionsSettings() {
  const { organization, addGlobalMessage, setCurrentOrg } = useGlobalContext();
  const { user } = useUser();

  // Update the allowed distribution methods usign the dedicated endpoint
  const updateAllowedDistributionMethods = async (values): Promise<void> => {
    const { distributionMethodsSupported } = values;

    await updateAllowedDistribution(
      distributionMethodsSupported,
      organization,
      user.token
    )
      .then(({ data }) => {
        addGlobalMessage('Successfully updated Allowed Distribution Methods', {
          severity: 'success',
        });
        setCurrentOrg({
          ...organization,
          distributionMethodsSupported: data,
        });
      })
      .catch((err) => {

        addGlobalMessage(errorMessages(err) || 'Error updating default RMD');
      });
  };

  // Update the dafault RMD object usign the dedicated endpoint
  const updateCurrentDefaultRmd = async (
    values: DefaultRmdFormData
  ): Promise<void> => {
    const { rmdNotificationsEnabled } = values;

    await updateOrg(
      {
        ...organization,
        rmdNotificationsEnabled,
      },
      organization,
      user.token
    )
      .then(async ({ data: org }) => {
        setCurrentOrg(org);
        addGlobalMessage('Successfully updated RMD defaults', {
          severity: 'success',
        });

        if (rmdNotificationsEnabled) {
          await updateDefaultRmd(values, user.organizationId, user.token)
            .then(({ data }) => {
              setCurrentOrg({
                ...org,
                defaultRMD: data,
              });
            })
            .catch((err) => {
      
              addGlobalMessage(errorMessages(err) || 'Error updating default RMD'
              );
            });
        }
      })
      .catch((err) => {

        addGlobalMessage(errorMessages(err) || 'Error updating default RMD');
      });
  };

  // Update scheduling settings on the org data object usign the org update endpoint
  const updateSchedulingSettings = async (
    values: ScheduledDistributionSettings
  ): Promise<void> => {
    const {
      autoScheduledDistributionsEnabled,
      withholdingNotificationsEnabled,
    } = values;

    await updateOrg(
      {
        ...organization,
        autoScheduledDistributionsEnabled,
        withholdingNotificationsEnabled,
      },
      organization,
      user.token
    )
      .then(({ data }) => {
        addGlobalMessage('Successfully updated scheduling settings', {
          severity: 'success',
        });
        setCurrentOrg({
          ...organization,
          autoScheduledDistributionsEnabled:
            data.autoScheduledDistributionsEnabled,
          withholdingNotificationsEnabled: data.withholdingNotificationsEnabled,
        });
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error updating scheduling settings'
        );
      });
  };

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Recurring Distributions Settings
          </Typography>
          <Box mt={5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* Allowed Distribution Methods */}
                <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      Allowed Distribution Methods
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DefaultDistributionSettingsForm
                      initialValues={{
                        ...DEFAULT_DISTRIBUTION_SETTINGS_INIT,
                        distributionMethodsSupported:
                          organization.distributionMethodsSupported,
                      }}
                      onSubmit={updateAllowedDistributionMethods}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* RMD Notification Settings */}
                <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      RMD Notification Settings
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <DefaultRmdSettingsForm
                      initialValues={{
                        ...DEFAULT_RMD_SETTINGS_INIT,
                        ...organization.defaultRMD,
                        rmdNotificationsEnabled:
                          organization.rmdNotificationsEnabled,
                      }}
                      onSubmit={updateCurrentDefaultRmd}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* Scheduled Distribution Settings */}
                <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      Scheduled Distribution Settings
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <ScheduledDistributionSettingsForm
                      initialValues={{
                        ...SCHED_DIST_SETTINGS_INIT,
                        ...organization,
                      }}
                      onSubmit={updateSchedulingSettings}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </>
      </PaperlessPage>
    </Layout>
  );
}
export default RecurringDistributionsSettings;
