import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import {
  AccountContribution,
  DepositCheckPayee,
  DepositMethod,
} from '../../../api/ContributionApi.d';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraSelectField from '../SiraSelectField';
import SiraDateField from '../SiraDateField';
import SiraTextField from '../SiraTextField';
import getFromAccountTypes from '../newAccount/getFromAccountTypes';
import { Account, AccountType } from '../../../api/AccountApi.d';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { depositCheckPayeeOptions } from '../../../app.constants';
import { AccountOwner, Share } from '../../../api/AccountOwnerApi.d';

export interface DepositMethodFormProps {
  account: Account;
  allowedDepositMethods?: Array<DepositMethod>;
  accountOwner?: AccountOwner;
}

function DepositMethodForm({
  account,
  // Allow all by default
  allowedDepositMethods = [
    DepositMethod.cash,
    DepositMethod.check,
    DepositMethod.direct,
    DepositMethod.transfer,
  ],
  accountOwner,
}: DepositMethodFormProps) {
  const { responsibleFirstName, responsibleLastName, accountType } = account;
  const { values, setValues, setFieldValue } =
    useFormikContext<AccountContribution>();
  const { depositMethod, payableTo, depositorFirstName, depositorLastName } =
    values;
  const { symitarAccountDTO } = accountOwner;
  const { organization, getAppConstant } = useGlobalContext();
  const [isInitialized, setIsInitialized] = useState(false as boolean);
  const isInherited = [
    AccountType.inheritedTraditional,
    AccountType.inheritedRoth,
  ].includes(accountType);
  const [symitarShares, setSymitarShares] = useState([] as Array<Share>);

  // Map the options based on the allowed methods passed in (defaults to all methods)
  const depositMethodOptions: Array<RadioGroupOption> =
    allowedDepositMethods.map((method: DepositMethod) => {
      // Apply a special suffix to the constants map text per deposit method
      const constantSuffix =
        {
          [DepositMethod.direct]: organization.name,
          [DepositMethod.check]: isInherited
            ? `payable to ${organization.legalName}`
            : '',
        }[method] || '';

      return {
        value: method,
        label: `${getAppConstant(
          ConstantsMappingKey.depositMethod,
          method,
        )} ${constantSuffix}`,
      };
    });

  // Store the result of the method to get the applicable source types
  const fromAccountTypes = useMemo(() => {
    const sameDepositor =
      depositorFirstName === responsibleFirstName &&
      depositorLastName === responsibleLastName;

    if (
      organization.coreIntegration === 'SYMITAR' &&
      [DepositMethod.direct].includes(
        values.depositMethod,
      )
    ) {
      let accountTypeOptions: Array<RadioGroupOption> = [];

      symitarShares.forEach((share: Share) => {
        accountTypeOptions.push({
          value: share.id,
          label: `${share.id} - ${share.description}`,
          afterContent: (
            <>
              <Typography variant="body1">
                Balance: ${share.balance.toFixed(2)}{' '}
              </Typography>
              <Typography variant="body1">
                Available Balance: ${share.availableBalance.toFixed(2)}{' '}
              </Typography>
            </>
          ),
        });
      });
      return accountTypeOptions;
    }

    return getFromAccountTypes(
      getAppConstant,
      accountType,
      depositMethod,
      payableTo,
      sameDepositor,
    );
  }, [
    accountType,
    depositMethod,
    payableTo,
    depositorFirstName,
    depositorLastName,
    responsibleFirstName,
    responsibleLastName,
    symitarShares,
  ]);

  // Clear out some fields when deposit method changes to avoid sending irrelevant fields.
  function handleDepositMethodChange() {
    // These fields should always be reset when depositMethod changes
    if (isInherited) {
      setValues({
        ...values,
        dateOnCheck: '',
        fromFinancialOrganization: '',
        payableTo:
          values.depositMethod === DepositMethod.check
            ? DepositCheckPayee.org
            : ('' as DepositCheckPayee),
      });
    } else {
      // These fields will be calculated and should never change for inherited

      setValues({
        ...values,
        payableTo: '' as DepositCheckPayee,
        fromAccountNumber: '',
        fromAccountType: '',
      });
    }
  }

  useEffect(() => {
    if (isInitialized) {
      handleDepositMethodChange();
    } else {
      setIsInitialized(true);
    }
  }, [depositMethod]);

  useEffect(() => {
    // if the user is a symitar account and a direct transfer we want to map the
    // AccounType Enums to the corresponding share id at the same time add fields for share type and share id
    if (
      organization.coreIntegration === 'SYMITAR' &&
      values.fromSymitarAccountType &&
      values.depositMethod === 'DIRECT_DEPOSIT'
    ) {
      // find the correct account type with the selected from account type and reset that value
      const shareAccountType = symitarAccountDTO.shares.find(
        (share: Share) => share.id === values.fromSymitarAccountType,
      );

      setFieldValue('fromAccountType', shareAccountType.accountType);
      setFieldValue('fromCoreAccountId', shareAccountType.id);
    }
  }, [values.fromSymitarAccountType]);

  useEffect(() => {
    // grab symitar shares and create an array from this
    if (symitarAccountDTO) {
      setSymitarShares(symitarAccountDTO.shares);
    }
  }, [symitarAccountDTO]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ paddingTop: 2 }}>
            <SiraDateField name="effectiveDate" label="Deposit Date" />
          </Box>
        </Grid>{' '}
        <Grid item xs={12}>
          <Field
            label="Deposit Method"
            name="depositMethod"
            options={depositMethodOptions}
            component={SiraRadioField}
          />
        </Grid>
        {values.depositMethod === DepositMethod.check && (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {!isInherited && (
                <Grid item xs={12} sm={6}>
                  <SiraSelectField
                    name="payableTo"
                    items={depositCheckPayeeOptions}
                    label="Check Payable to"
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <SiraDateField name="dateOnCheck" label="Date on Check" />
              </Grid>
            </Grid>
          </Grid>
        )}
        {[
          DepositMethod.check,
          DepositMethod.direct,
          DepositMethod.transfer,
        ].includes(values.depositMethod) ? (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  label="Select where the money came from"
                  component={SiraRadioField}
                  name={
                    [DepositMethod.direct].includes(
                      values.depositMethod,
                    ) && organization.coreIntegration === 'SYMITAR'
                      ? 'fromSymitarAccountType'
                      : 'fromAccountType'
                  }
                  options={fromAccountTypes}
                />
              </Grid>
              <Grid item>
                {values.depositMethod !== DepositMethod.check && organization.coreIntegration !== 'SYMITAR' && (
                  <Grid item xs={12} pb={2}>
                    <SiraTextField
                      name="fromAccountNumber"
                      label="Source Account Number"
                    />
                  </Grid>
                )}
                {values.depositMethod === DepositMethod.transfer && (
                  <Grid item xs={12}>
                    <SiraTextField
                      name="fromFinancialOrganization"
                      label="Financial Organization"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default DepositMethodForm;
