import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';

import StepButtonBar from '../../steps/StepButtonBar';
import { BeneficiariesClaimFiles } from './BeneficiariesClaimsForm.d';
import { User } from '../../../api/UserApi.d';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import { useUser } from '../../../auth/useUser';

export const BENEFICIARIES_REP_INIT: BeneficiariesClaimFiles = {
  files: null,
  finOrgContactUserId: '',
  finOrgSubmittedByUserId: '',
  selectedRep: '',
};

export interface BeneficiariesClaimsRepFormProps {
  initialValues: BeneficiariesClaimFiles;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
  isEditing?: boolean;
  response?: Array<User>;
}

function RepresentativeForm(props) {
  const { response } = props;
  const {  setFieldValue } = useFormikContext();
  const { user } = useUser();
  const [mergedName, setMergedNamed] = useState<Array<SiraSelectItem>>([]);
  let currentUser: User;

  const setRepData = () => {
    if (response !== undefined) {
      currentUser = response.find((data) => {
        return data.auth0UserId === user.sub && data;
      });

      response.forEach((userValue: User) => {
        setMergedNamed((currentArray) => [
          ...currentArray,
          {
            value: userValue.userId.toString(),
            label: `${userValue.firstName} ${userValue.lastName}`,
          },
        ]);
      });

      if(currentUser && currentUser.userId){
        setFieldValue('selectedRep', currentUser.userId.toString());
      }

    }
  };

  useEffect(() => {
    setRepData();
  }, [user.token, user.organizationId]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <SiraSelectField
          name="selectedRep"
          items={mergedName}
          label="Representative"
        />
      </Grid>
    </>
  );
}

function BeneficiariesClaimsRepForm({
  initialValues,
  onSubmit,
  onReset = () => {},
  submitName,
  resetName,
  isEditing = false,
  response,
}: BeneficiariesClaimsRepFormProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => onReset()}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <RepresentativeForm response={response} isEditing={isEditing} />
          </Grid>
          <StepButtonBar
            isSubmitting={isSubmitting}
            submitName={submitName}
            resetName={resetName}
          />
        </Form>
      )}
    </Formik>
  );
}

export default BeneficiariesClaimsRepForm;
