import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Select,
  MenuItem,
  Grid,
  Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNavigate } from 'react-router-dom';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import CheckIcon from '@mui/icons-material/Check';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';

import { useUser } from '../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import {
  RmdNotificationStatus,
  RmdNotificationResponse,
} from '../api/RmdNotificationApi.d';
import { Account } from '../api/AccountApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import { AccountDetailAccordionType } from '../components/accountOwnerProfile/AccountOwnerAccountDetails';
import {
  distributionMethodShortNames,
  globalPaginationOptions,
  frequencyOptionsValues,
} from '../app.constants';
import { getDashboardRecurringDistributions } from '../api/OrganizationApi';
import {
  RecurringDistribution,
  RecurringDistributionStatus,
} from '../api/RecurringDistributionApi.d';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import {
  currencyValueFormatterPro,
  dateValueFormatterPro,
  percentFormatterPro,
} from '../utils/DataGrid.utils';
import { FrequencyValues } from '../components/form/distribution/Frequency.d';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import { errorMessages } from '../utils/errorhandling.utils';

interface RmdScheduleRow extends RecurringDistribution, Account, AccountOwner {
  id: number;
}

// Flatten and map out the rmd notifications for display in the data grid
function recurringDistributionRowFactory(
  notifications: Array<any>
): Array<RmdScheduleRow> {
  return notifications.map((result, id) => ({
    ...result.account,
    ...result.accountOwner,
    ...result.recurringDistribution,
    id,
  }));
}

function ReportsRmdSchedule() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage, getAppConstant, organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [frequencyFilter, setFrequencyFilter] = useState<
    Array<FrequencyValues>
  >([]);
  const [orgRecurringDistributions, setOrgRecurringDistributions] = useState<
    Array<RmdNotificationResponse>
  >([]);
  const [filteredRows, setFilteredRows] = useState<Array<RmdScheduleRow>>([]);
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account Number'
      : 'Member Number';

  // Fetch all RMD notifications for the current organization
  async function getAndSetOrgRecurringDistributions(): Promise<void> {
    setIsLoading(true);

    await getDashboardRecurringDistributions(
      user.organizationId,
      [RecurringDistributionStatus.active],
      user.token
    )
      .then(({ data = [] }) => {
        if (isMounted) {
          setOrgRecurringDistributions(data);
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            'Error fetching organization recurring distributions'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  const linkToRecordAccount = (row: RmdScheduleRow): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.recurring}`
    );
  };

  const useStyles = makeStyles(() => ({
    toolbarContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '15px',
    },
    exportButton: {
      backgroundColor: 'black', // Set your desired background color
      color: 'white', // Set your desired text color
      '&:hover': {
        backgroundColor: 'black', // Set your desired hover background color
      },
      padding: '5px',
    },
  }));

  const classes = useStyles();

  const columns = [
    {
      field: 'fullName',
      headerName: 'Owner',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const { rmdNotificationStatus } = row;
        const shouldLinkToTransaction =
          rmdNotificationStatus !== RmdNotificationStatus.awaiting;

        return (
          <Button
            onClick={() => {
              if (shouldLinkToTransaction) {
                linkToRecordAccount(row);
              }
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (value) => {
        const typeId = value;
        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      width: 156,
      valueFormatter: (value) => {
        const frequency = String(value);
        return (
          getAppConstant(
            ConstantsMappingKey.distributionFrequency,
            frequency
          ) || frequency
        );
      },
    },
    {
      field: 'startDate',
      headerName: 'Start',
      width: 156,
      valueFormatter: dateValueFormatterPro,
    },
    {
      field: 'endDate',
      headerName: 'End',
      width: 156,
      valueFormatter: dateValueFormatterPro,
    },
    {
      field: 'distributionMethod',
      headerName: 'Method',
      width: 156,
      valueFormatter: (value ) => {
        const distributionMethod = String(value);
        return (
          distributionMethodShortNames[distributionMethod] || distributionMethod
        );
      },
    },
    {
      field: 'totalAmount',
      headerName: 'Total',
      width: 156,
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'federalWithholdingPercent',
      headerName: 'Federal Withholding %',
      width: 180,
      valueFormatter: percentFormatterPro,
    },
    {
      field: 'stateWithholdingPercent',
      headerName: 'State Withholding %',
      width: 170,
      valueFormatter: percentFormatterPro,
    },
    {
      field: 'additionalStateWithholding',
      headerName: 'Additional State Withholding',
      width: 156,
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'withholdingState',
      headerName: 'Withholding State',
      width: 156,
    },
    {
      field: 'ownerResponsible',
      headerName: 'Owner Responsible',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <>
            <Grid container justifyContent="center">
              {row.ownerResponsible && <CheckIcon />}
            </Grid>
          </>
        );
      },
    },
  ] as GridColDef[];

  const rows = recurringDistributionRowFactory(orgRecurringDistributions);

  const CustomGridToolbarContainer = () => {
    return (
      <GridToolbarContainer className={classes.toolbarContainer}>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          className={classes.exportButton}
        />
      </GridToolbarContainer>
    );
  };

  // Apply all filters to the supplied rows
  const applyFilters = (rowsToFilter) => {
    // Filter by account type first or pass through if none selected
    const matchingFrequencyRows =
      frequencyFilter.length > 0
        ? rowsToFilter.filter(({ frequency, ownerResponsible }) => {
            if (frequencyFilter.includes(FrequencyValues.ownerResponsibile)) {
              // return object if frequencyFilter includes  and ownerResponsible is true
              return ownerResponsible;
            }
            return frequencyFilter.includes(frequency);
          })
        : rowsToFilter;

    setFilteredRows(matchingFrequencyRows);
  };

  // Allow the frequency filter items to be selected or deselected
  const handleFrequencySelect = (frequencySelected: Array<FrequencyValues>) => {
    setFrequencyFilter(frequencySelected);
  };

  // Allow the chip frequency in the filter to be deleted
  const handleFrequencyDelete = (valueToExclude): void => {
    setFrequencyFilter(
      frequencyFilter.filter((value) => value !== valueToExclude)
    );
  };

  useEffect(() => {
    if (user.organizationId && user.token) {
      getAndSetOrgRecurringDistributions();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token]);

  useEffect(() => {
    applyFilters(rows);

    return () => {
      isMounted = false;
    };
  }, [frequencyFilter, orgRecurringDistributions]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Recurring Distributions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Recurring Distribution Schedules
          </Typography>

          <Grid container mb={2} spacing={2} wrap="nowrap" alignItems="center">
            <Grid item>
              <Typography variant="body2">Filter:</Typography>
            </Grid>

            <Grid item xs={12}>
              <Select
                fullWidth
                onChange={(e) => {
                  handleFrequencySelect(
                    e.target.value as Array<FrequencyValues>
                  );
                }}
                value={frequencyFilter}
                multiple
                renderValue={(selected: Array<any>) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const label = value;
                      return (
                        <Chip
                          key={value}
                          label={label}
                          onDelete={() => {
                            handleFrequencyDelete(value);
                          }}
                          onMouseDown={(event) => {
                            event.stopPropagation(); // Prevents clicking the chip from opening the select box
                          }}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {frequencyOptionsValues.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <DataGridPro
              slots={{
                noRowsOverlay: SiraNoRowsOverlay,
                toolbar: CustomGridToolbarContainer,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pagination
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={filteredRows}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default ReportsRmdSchedule;
