import axios from 'axios';
import format from 'string-format';
import { setBaseUrl } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';


const urlBase = process.env.REACT_APP_API_BASE_URL;
const geoApifyUrl = '/geocode?text={0.query}';


export async function getGeoApifyClaims(
  token: string,
  query: string,
  user
): Promise<any> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${geoApifyUrl}`, {
    query,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}



