export interface CoverdellAccountDetail {
  responsibleFirstName?: string;
  responsibleLastName?: string;
  responsiblePhoneNumber?: string;
  responsibleEmailAddress?: string;
  responsibleAddressLine1?: string;
  responsibleAddressLine2?: string;
  responsibleCity?: string;
  responsibleState?: string;
  responsibleZip?: string;
  responsibleCountry?: string;
  allowBeneficiaryChange?: boolean;
  responsibleAfterMajority?: boolean;
  responsibleForeignStateProvinceRegion?: string;
}

export interface Account extends CoverdellAccountDetail {
  accountId?: string;
  accountOwnerId?: string;
  accountType?: AccountType;
  accountNumber?: string;
  planNumber?: string;
  accountStatus?: AccountStatus;
  fairMarketValue?: string;
  openDate?: string;
  closedDate?: string;
  signedDate?: string;
  financialOrganizationId?: string | number;
  deceasedAccount?: Account;
  deceasedAccountOwnerId?: string;
  deceasedAccountId?: string;
  eligibleDesignatedBeneficiary?: boolean;
  externalAccountOwnerAccountId?: string;
  federalWithholdingPercent?: number | string;
  federalWithholdingPercentEffectiveDate?: string;
  dateOfDeathAccountBalance?: string;
  fiveYearTest?: boolean;
  hsaYearOfDeath?: string;
  relationship?: BeneficiaryTypes;
  excludeTaxForm1099?: boolean;
  excludeTaxForm5498?: boolean;
}

export interface AccountStatusResponse {
  data?: Account;
  errorMessage?: any;
}

export interface AccountImportColumnsResponse {
  data?: AccountImportColumns;
  errorMessage?: any;
}

export interface AccountImportColumns {
  ourHeaders?: Array<string>;
  fileHeaders?: Array<string>;
  rowData?: Array<any>;
}

export enum AccountStatusState {
  next = 'NEXT',
  previous = 'PREVIOUS',
}

export enum AccountStatus {
  signature = 'AWAITING_SIGNATURE',
  closed = 'CLOSED',
  open = 'OPEN',
  pending = 'PENDING',
  review = 'PENDING_REVIEW',
  submitOwner = 'SUBMITTED_BY_OWNER',
  previous = 'PREVIOUS',

}

export enum TransactionType {
  account = 'ACCOUNT',
  distribution = 'DISTRIBUTION',
  contribution = 'CONTRIBUTION',
  recurringDistribution = 'RECURRING_DISTRIBUTION',
  beneficiaries = 'BENEFICIARIES',
  beneficiaryClaims = 'BENEFICIARY_CLAIMS',
  transfer = 'ROLLOVER_TRANSFER',
}

export enum TransactionStatus {
  pending = 'PENDING',
  signature = 'AWAITING_SIGNATURE',
  awaiting = 'AWAITING_TRANSFER',
  review = 'PENDING_REVIEW',
  submitOwner = 'SUBMITTED_BY_OWNER',
  transfer = 'AWAITING_TRANSFER',
}

export enum AccountType {
  init = '',

  // Account types generally serviced by orgs using this application
  traditionalIra = 'TRADITIONAL_IRA',
  rothIra = 'ROTH_IRA',
  traditionalSep = 'SEP_IRA',
  simpleIra = 'SIMPLE_IRA',
  esa = 'COVERDELL_ESA',
  hsa = 'HEALTH_SAVINGS_ACCOUNT',
  msa = 'ARCHER_MSA',
  inheritedRoth = 'INHERITED_ROTH_IRA',
  inheritedTraditional = 'INHERITED_TRADITIONAL_IRA',

  // The following generally refer to source account types being serviced by external entities
  savings = 'SAVINGS',
  checking = 'CHECKING',
  employer = 'EMPLOYER_SPONSORED',
  employerRoth = 'EMPLOYER_SPONSORED_ROTH',
  employerTraditional = 'EMPLOYER_SPONSORED_TRADITIONAL_IRA',
  esa529 = 'FIVE_TWENTY_NINE',
  other = 'OTHER',
}

export const AccountSupportedTypestring = [
  'TRADITIONAL_IRA',
  'ROTH_IRA',
  'SEP_IRA',
  'SIMPLE_IRA',
  'COVERDELL_ESA',
  'HEALTH_SAVINGS_ACCOUNT',
  'INHERITED_ROTH_IRA',
  'INHERITED_TRADITIONAL_IRA',
];

export enum CategoricalAccountType {
  ira = 'CATEGORICAL_IRA',
}

export interface WorkflowDates {
  activeDate?: string;
  effectiveDate?: string;
  signedDate?: string;
  openDate?: string;
  closedDate?: string;
}

export interface AccountComment {
  accountCommentId: number;
  financialOrganizationId: number;
  accountId: number;
  comment: string;
  superiorUserId?: number;
  recordCreated: string;
  recordUpdated: string;
}

export interface AccountCommentResponse {
  data: Array<AccountComment>;
  errorMessage?: any;
}

interface SymitarService {
  service: number;
  entryId: number;
}

interface Share {
  id: string;
  irsCode: number;
  irsPlan: number;
  shareCode: number;
  symitarServices: SymitarService[];
  description: string;
  divRate: number;
  balance: number;
  availableBalance: number;
  minimumBalance: number;
  minimumDeposit: number;
  minimumWithdrawal: number;
  openDate: string;
  type: number;
}

interface PayeeLine {
  payeeLine: string;
  entryId: number;
}

interface PayeePhone {
  payeePhone: string;
  entryId: number;
}

interface Investment {
  id: string;
  irsCode: number;
  irsPlan: number;
  shareCode: number;
  symitarServices: SymitarService[];
  description: string;
  divRate: number;
  balance: number;
  availableBalance: number;
  minimumBalance: number;
  minimumDeposit: number;
  minimumWithdrawal: number;
  openDate: string;
  type: number;
}

interface CurrentTaxYearContribution {
  amount: number;
  entryId: number;
}

interface PriorTaxYearDistribution {
  amount: number;
  entryId: number;
}

interface CurrentTaxYearDistribution {
  amount: number;
  entryId: number;
}

interface IrsRecord {
  irsCode: number;
  irsPlan: number;
  ssn: string;
  fairMarketValueDeath: number;
  fairMarketValueLastYear: number;
  iraRmd: number;
  iraRmdRoundTableOption: number;
  priorTaxYearContributions: any; // Assuming this can be null or another type
  currentTaxYearContributions: CurrentTaxYearContribution[];
  priorTaxYearDistributions: PriorTaxYearDistribution[];
  currentTaxYearDistributions: CurrentTaxYearDistribution[];
}

export interface SymitarAccount {
  symitarAccountId: string;
  payeeNameType: number;
  statementMailCode: number;
  branch: number;
  shares: Share[];
  payeeLines: PayeeLine[];
  payeePhones: PayeePhone[];
  memberDetails: any; // Assuming this can be null or another type
  investments: Investment[];
  irsRecords: IrsRecord[];
}