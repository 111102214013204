import React, { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { endOfYear, format, startOfYear } from 'date-fns';
import { Form, Formik } from 'formik';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import SiraDateRangeField from '../components/form/SiraDateRangeField';
import MultiButton, { MultiButtonAction } from '../components/MultiButton';
import { useGlobalContext } from '../auth/useGlobalContext';
import { useUser } from '../auth/useUser';
import { exportAccountOwners } from '../api/AuditReportApi';

function AuditReports() {
  const { addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [dateStart, setDateStart] = useState(
    format(startOfYear(new Date()), 'yyyy-MM-dd')
  );
  const [dateEnd, setDateEnd] = useState(
    format(endOfYear(new Date()), 'yyyy-MM-dd')
  );

  const exportOrgAccounts = async (fileType): Promise<any> => {
    setIsLoading(true);
    await exportAccountOwners(
      user.organizationId,
      user.token,
      fileType,
      dateStart,
      dateEnd
    )
      .then(() => {
        addGlobalMessage(
          'Your file will be available shortly under Notifications, located at the top of the page.',
          {
            severity: 'success',
          }
        );
      })
      .catch(() => {
        addGlobalMessage('Error exporting organization accounts');
      });
    setIsLoading(false);
  };

  // Define the download types
  const downloadActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => exportOrgAccounts('tsv'),
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => exportOrgAccounts('xlsx'),
      icon: <DownloadIcon />,
    },
  ];

  return (
    <Layout>
      <PaperlessPage>
        <Formik
          initialValues={{
            startDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
            endDate: format(endOfYear(new Date()), 'yyyy-MM-dd'),
          }}
          onSubmit={() => {}}
        >
          {(values) => {
            useEffect(() => {
              const { startDate, endDate } = values.values;
              setDateStart(startDate);
              setDateEnd(endDate);
            }, [values]);

            return (
              <Paper elevation={3}>
                <Form>
                  {' '}
                  <Box m={2}>
                    <Typography variant="h5" color="secondary">
                      Owner Audit
                    </Typography>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    pt={1}
                    pb={2}
                    ml={2}
                  >
                    <SiraDateRangeField />

                    <Grid item>
                      <MultiButton
                        actions={downloadActions}
                        sx={{ whiteSpace: 'nowrap' }}
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        endIcon={<ExpandMoreIcon />}
                        disabled={isLoading}
                      >
                        Export Owner Report
                      </MultiButton>
                    </Grid>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress color="secondary" />
                      </Grid>
                    )}
                  </Grid>
                </Form>
              </Paper>
            );
          }}
        </Formik>
      </PaperlessPage>
    </Layout>
  );
}

export default AuditReports;
