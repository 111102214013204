import React from 'react';
import { useTheme, Box, Paper, Typography, Button, Grid } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import PluralizedString from '../../PluralizedString';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { globalPaginationOptions } from '../../../app.constants';
import {
  BeneficiariesClaimResult,
  BeneficiaryStatus,
} from '../../../api/BeneficiaryClaimsApi.d';
import { dateValueFormatter } from '../../../utils/DataGrid.utils';
import {
  AccountOwner,
  AccountOwnerSearchResponse,
  AccountOwnerSearchResult,
} from '../../../api/AccountOwnerApi.d';
import { Account } from '../../../api/AccountApi.d';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';

interface ClaimsSearchResultsProps {
  onResultClick: Function;
  response: AccountOwnerSearchResponse;
  filterFunction?: Function;
}

interface SearchResultRow
  extends Account,
    AccountOwner,
    BeneficiariesClaimResult {
  id: number;
  communityPropertyState: boolean;
}

export const beneficiarySupportedStatusDescription = {
  [BeneficiaryStatus.default]: '',
  [BeneficiaryStatus.pending]: 'Pending',
  [BeneficiaryStatus.processing]: 'Submitted for Processing',
  [BeneficiaryStatus.completed]: 'Completed',
  [BeneficiaryStatus.awaitingElection]: 'Awaiting Election',
  [BeneficiaryStatus.awaitingAdditionalInfo]: 'Awaiting Additional Information',
  [BeneficiaryStatus.notProcessed]: 'Not Processed',
};

export function ClaimsSearchResults(props: ClaimsSearchResultsProps) {
  const { response = {}, onResultClick = () => {}, filterFunction } = props;
  const { data = [], query = '' } = response as AccountOwnerSearchResponse;
  const theme = useTheme();
  const { getAppConstant, organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';

  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
  };

  const results = filterFunction
    ? data.filter((value) => filterFunction(value))
    : data;

  const linkToRecordAccount = (row: SearchResultRow): void => {
    const { beneficiaryClaim } = row;

    let shouldLinkToTransaction = true;
    if (beneficiaryClaim) {
      shouldLinkToTransaction =
        beneficiaryClaim.beneficiaryClaim.beneficiaryClaimStatus ===
        BeneficiaryStatus.pending;
    }

    if (shouldLinkToTransaction) {
      onResultClick(row);
    } else {
      navigate(
        `/beneficiaryClaims/viewBeneficiaryClaim/beneficiaryClaimsProfile?accountId=${row.accountId}&accountOwnerId=${row.accountOwnerId}`,
      );
    }
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              linkToRecordAccount(row);
            }}
            sx={classes.accountLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 136,
      sortable: false,
    },
    {
      field: 'dateOfDeath',
      headerName: 'Date of Death',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'beneficiaryClaimStatus',
      headerName: 'Claims Status',
      width: 350,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const statusComponents = {
          [BeneficiaryStatus.pending]: (
            <Button color="warning" startIcon={<PendingIcon />}>
              Pending
            </Button>
          ),
          [BeneficiaryStatus.processing]: (
            <Button color="success" startIcon={<CheckCircleIcon />}>
              Submitted for Processing
            </Button>
          ),
          [BeneficiaryStatus.awaitingElection]: (
            <Button color="info" startIcon={<PendingIcon />}>
              Awaiting Election
            </Button>
          ),
          [BeneficiaryStatus.awaitingAdditionalInfo]: (
            <Button color="info" startIcon={<PendingIcon />}>
              Awaiting Additional Information
            </Button>
          ),
          [BeneficiaryStatus.completed]: (
            <Button color="success" startIcon={<CheckCircleIcon />}>
              Completed
            </Button>
          ),
          [BeneficiaryStatus.notProcessed]: (
            <Button color="info" startIcon={<PendingIcon />}>
              Not Processed
            </Button>
          ),
        };

        return statusComponents[value as BeneficiaryStatus];
      },
    },
  ];

  function resultRowFactory(
    resultRow: AccountOwnerSearchResult,
    index: number,
  ): SearchResultRow {
    return {
      ...resultRow.account,
      ...resultRow.accountOwner,
      ...resultRow.beneficiaryClaim,
      id: index,
      communityPropertyState: false,
    };
  }

  const rows = results.map((resultRow, index) =>
    resultRowFactory(resultRow, index),
  );

  return (
    <>
      <Box mb={4}>
        <Typography variant="body1">Showing results for</Typography>
        <Typography variant="h5">&quot;{query}&quot;</Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <Grid container wrap="nowrap" spacing={2} sx={classes.buttonBar}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {results.length}{' '}
                <PluralizedString noun="account" quantity={results.length} />{' '}
                found
              </Typography>
            </Grid>
          </Grid>

          <Box mt={4}>
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              disableColumnMenu
              autoHeight
              columns={columns}
              rows={rows}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default ClaimsSearchResults;
