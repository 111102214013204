import { Country } from './StatesApi.d';

export enum BeneficiaryTypes {
  DEFAULT = '',
  SPOUSE = 'SPOUSE',
  ESTATE = 'ESTATE',
  TRUST = 'TRUST',
  CHILD = 'CHILD',
  OTHER = 'OTHER',
  NONEHUMAN = 'OTHER_NON_HUMAN',
}

export enum BeneficiaryStatus {
  previous = 'INACTIVE',
  signature = 'AWAITING_SIGNATURE',
  live = 'ACTIVE',
  pending = 'PENDING',
  review = 'PENDING_REVIEW',
  submitOwner = 'SUBMITTED_BY_OWNER',
}

export enum BeneficiaryStatusState {
  next = 'NEXT',
  previous = 'PREVIOUS',
}

export interface WorkflowDates {
  activeDate?: string;
  effectiveDate?: string;
  signedDate?: string;
}

export interface BeneficiaryVersionsResponse {
  data?: Array<Beneficiaries>;
  errorMessage?: any;
  status?: number;
}

export interface BeneficiaryVersionResponse {
  data?: Beneficiaries;
  errorMessage?: any;
  status?: number;
}

export interface BeneficiaryResponse {
  data?: Beneficiary;
  errorMessage?: any;
  status?: number;
}

export interface BeneficiariesStatusResponse {
  data?: Beneficiaries;
  errorMessage?: any;
}

export interface Beneficiaries {
  beneficiaryStatus?: BeneficiaryStatus;
  endDate?: string;
  effectiveDate?: string; // Not sure if this will ever make it into the data model, but it's being used.
  primaryBeneficiaries?: Array<Beneficiary>;
  secondaryBeneficiaries?: Array<Beneficiary>;
  signedDate?: string;
  startDate?: string;
  version?: BeneficiaryVersion | number;
  requireSpousalConsent?: boolean;
  spouseName?: string;
}

export interface Beneficiary {
  name?: string;
  representativeFirstName?: string;
  representativeLastName?: string;
  beneficiaryId?: string;
  dateOfBirth?: string;
  emailAddress?: string;
  firstName?: string;
  fullName?: string;
  lastName?: string;
  middleInitial?: string;
  percent?: number;
  phoneNumber?: string;
  primaryBeneficiary?: boolean;
  relationship?: any;
  taxpayerIdNumber?: string;
  accountNumber?: string;
  relationshipToAccountOwner?: BeneficiaryTypes;
  otherRelationshipToAccountOwner?: string;
  eligibleDesignatedBeneficiary?: boolean;
  
  // Address fields are optional here
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  country?: any;
  city?: string;
  state?: string;
  foreignStateProvinceRegion?: string;
  zip?: string;
  openDate?: string;
}
