/* eslint-disable import/no-cycle */
import React from 'react';
import { Typography } from '@mui/material';
import { netIncomeAttributableReasons } from './getDistributionCodes';
import { DistributionReason } from '../../../api/DistributionApi.d';
import { distributionAmountHelpText } from './resource.txt';

// pulled out this function from  setDistributionReason to cleaner it up a bit
export function distributionReasonNiaLogic(
  netIncomeAttributable: number | string,
  distributionReason: DistributionReason
) {
  let newReason = distributionReason;
  const parsedNiaAmount = Number(netIncomeAttributable);
  const rothRevocation =
    distributionReason === DistributionReason.ROTH_REGULAR_REVOCATION ||
    distributionReason ===
      DistributionReason.ROTH_REGULAR_REVOCATION_NO_EARNINGS;

  if (parsedNiaAmount === 0) {
    if (rothRevocation) {
      newReason = DistributionReason.ROTH_REGULAR_REVOCATION_NO_EARNINGS;
    } else {
      newReason = DistributionReason.E_IRA_REGULAR_REVOCATION_NO_EARNINGS;
    }
  }

  if (parsedNiaAmount > 0) {
    newReason = DistributionReason.E_IRA_REGULAR_REVOCATION;
    if (rothRevocation) {
      newReason = DistributionReason.ROTH_REGULAR_REVOCATION;
    }
  }

  return newReason;
}

// for Net Income Attributable to set the distribution reason based of the user selection in the drop down and distribution age status.
export function setDistributionReason(
  netIncomeAttributable: number | string,
  isEarlyDistribution: boolean,
  distributionReason: DistributionReason
) {
  const isRegRevocation =
    netIncomeAttributableReasons.REGULARREVOC.includes(distributionReason);
  const isConvRevocation =
    netIncomeAttributableReasons.CONVERSIONREVOC.includes(distributionReason);
  const rothRevocation =
    distributionReason === DistributionReason.ROTH_REGULAR_REVOCATION ||
    distributionReason ===
      DistributionReason.ROTH_REGULAR_REVOCATION_NO_EARNINGS;

  let newReason = distributionReason;

  if (isRegRevocation) {
    if (isEarlyDistribution) {
      newReason = distributionReasonNiaLogic(
        netIncomeAttributable,
        distributionReason
      );
    } else {
      newReason = rothRevocation
        ? distributionReasonNiaLogic(netIncomeAttributable, distributionReason)
        : DistributionReason.Q_IRA_REGULAR_REVOCATION;
    }
  }

  if (isConvRevocation) {
    newReason = DistributionReason.ROTH_CONVERSION_REVOCATION;
  }

  return newReason;
}

// figuring out if this is revocation(regular or conversion), excess, recharacterization.
export function isRecharOrExcessOrRevoc(
  distributionReason: DistributionReason
) {
  return Boolean(
    netIncomeAttributableReasons.EXCESS.includes(distributionReason) ||
      netIncomeAttributableReasons.RECHAR.includes(distributionReason) ||
      netIncomeAttributableReasons.REGULARREVOC.includes(distributionReason) ||
      netIncomeAttributableReasons.CONVERSIONREVOC.includes(distributionReason)
  );
}

export function getEsaAfterContentDisplay(currecyField) {
  let content;
  switch (currecyField) {
    case 'niaPreContributionAccountBalance':
      content =
        distributionAmountHelpText.accountBalESABeforeContributionMade
          .afterContent;
      break;
    case 'niaCurrentAccountBalance':
      content =
        distributionAmountHelpText.currentESAAccountBalance.afterContent;
      break;
    case 'niaTotalContributionsDuringComputationPeriod':
      content =
        distributionAmountHelpText
          .ttlDepositMadeSinceImmedBeforExcessContributionESA.afterContent;
      break;
    case 'niaTotalDistributionsDuringComputationPeriod':
      content =
        distributionAmountHelpText
          .ttlDistributionMadeSncBeforeExcessContributionESA.afterContent;
      break;
    default:
      break;
  }

  return content;
}

export function getHsaAfterContentDisplay(currecyField) {
  let content;
  switch (currecyField) {
    case 'niaPreContributionAccountBalance':
      content =
        distributionAmountHelpText.accountBalHSABeforeContributionMade
          .afterContent;
      break;
    case 'niaCurrentAccountBalance':
      content =
        distributionAmountHelpText.currentHSAAccountBalance.afterContent;
      break;
    case 'niaTotalContributionsDuringComputationPeriod':
      content =
        distributionAmountHelpText
          .ttlDepositMadeSinceImmedBeforExcessContributionHSA.afterContent;
      break;
    case 'niaTotalDistributionsDuringComputationPeriod':
      content =
        distributionAmountHelpText
          .ttlDistributionMadeSncBeforeExcessContributionHSA.afterContent;
      break;
    default:
      break;
  }
  return content;
}

export function getAfterContentDisplay(currecyField) {
  let content;
  switch (currecyField) {
    case 'niaPreContributionAccountBalance':
      content =
        distributionAmountHelpText.accountBalBeforeContributionMade
          .afterContent;
      break;
    case 'niaCurrentAccountBalance':
      content = distributionAmountHelpText.currentAccountBalance.afterContent;
      break;
    case 'niaTotalContributionsDuringComputationPeriod':
      content =
        distributionAmountHelpText
          .ttlDepositMadeSinceImmedBeforExcessContribution.afterContent;
      break;
    case 'niaTotalDistributionsDuringComputationPeriod':
      content =
        distributionAmountHelpText
          .ttlDistributionMadeSncBeforeExcessContribution.afterContent;
      break;
    default:
      break;
  }

  return content;
}

export function suggestedWithholding(useSuggestedWithholding) {
  return useSuggestedWithholding
    ? distributionAmountHelpText
        .confirmOrUpdatedpercentageWithholdForFederalTaxes.afterContent
    : distributionAmountHelpText.percentageWithholdForFederalTaxes.afterContent;
}

export const tooltipStyling = {
  backgroundColor: '#f5f5f9',
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: 220,
  fontSize: 13,
  border: '1px solid #dadde9',
};

export const distributionChoiceText = (
  <Typography variant="body2">
    Select the life expectancy table to determine the recurring distributions.
    (The divisor used to calculate the distributions will appear upon
    selection.)
  </Typography>
);

export const recurringDistributionHelpText = {
  rmd: (
    <Typography variant="body2">
      This is the minimum amount the IRA owner is required to receive each year.
    </Typography>
  ),
  specificAmount: (
    <Typography variant="body2">
      Enter the total annual amount the IRA owner would like to receive each
      year. The amount entered must be equal to or greater than the IRA
      owner&apos;s RMD for that year. If the amount is less than the RMD for any
      year, the organization will be instructed to distribute the IRA
      owner&apos;s RMD amount instead.
    </Typography>
  ),
  specificAmountInherited: (
    <Typography variant="body2">
      The amount entered must be equal to or greater than the IRA owner&apos;s
      RMD for that year. If the amount is less than the RMD for any year, the
      organization will be instructed to distribute the IRA owner&apos;s RMD
      amount instead.
    </Typography>
  ),
  startDateAddition: (
    <>
      <Typography variant="body2">
        Enter the date that distributions will begin this year.
      </Typography>
      <Typography variant="body2">
        If this is the IRA owner&apos;s first RMD (in the 73rd year), the IRA
        owner may delay receiving that first RMD until April 1 of the following
        year. Note that if this occurs, then the IRA owner will be required to
        receive two RMDs in the 74th year—one to satisfy the first RMD (for the
        73rd year) and one to satisfy the following year&apos;s RMD (for the
        74th year).
      </Typography>
    </>
  ),
  startDateBetweenYears: (
    <Typography variant="body2">
      Enter the date that distributions will begin this year.
    </Typography>
  ),
  rmdUnderAge: (
    <Typography variant="body2">
      This method uses the account balance and a divisor derived from the
      selected life expectancy table to calculate the distribution. The RMD
      amount to be taken is recalculated each year.
    </Typography>
  ),
  fixedAmoritization: (
    <Typography variant="body2">
      This method amortizes the account balance over a specified number of years
      that is derived from the selected life expectancy table and a reasonable
      interest rate. The account owner will be required to take the same amount
      from the account each year (it is not recalculated).
    </Typography>
  ),
  fixedAnnuitization: (
    <Typography variant="body2">
      This method involves dividing the account balance by an annuity factor
      representing the present value of an annuity of $1 per year over the life
      expectancy divisor determined by the owner&apos;s attained age in the
      first year and assuming a reasonable rate of return. (The life expectancy
      divisor may be obtained from the owner&apos;s selected life expectancy
      table—Single Life or Joint Life and Last Survivor Expectancy Table).
    </Typography>
  ),
};
