/* eslint-disable import/no-cycle */
// TODO: Find a way to fetch modal content from CRM once one is established

import React, { useState } from 'react';
import { Link } from '@mui/material';
import SiraModal from '../SiraModal';
import { getPopupText } from '../../utils/transaction.utils';


export interface SiraHyperLinkProps {
  textHyperLink?: string;
  textType?: string;
  title?: string;
}

function SiraHyperLink({
  textHyperLink,
  textType,title,
}: SiraHyperLinkProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(!modalOpen);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Link variant="body2" sx={{ cursor: 'pointer' }} onClick={handleClick}>
        {textHyperLink}
      </Link>

      <SiraModal title={title} open={modalOpen} handleClose={handleClose}>
        {getPopupText(textType)}
      </SiraModal>
    </>
  );
}

export default SiraHyperLink;
