import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgWithholdingNotification(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="null"
          fill="currentColor"
          d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
        />
        <path
          stroke="null"
          fill="currentColor"
          d="M14 17H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
        />
        <path
          d="M19.5,15 C21.9852814,15 24,17.0147186 24,19.5 C24,21.9852814 21.9852814,24 19.5,24 C17.0147186,24 15,21.9852814 15,19.5 C15,17.0147186 17.0147186,15 19.5,15 Z M20,16 L19,16 L19,16.6909105 C18.419295,16.9322152 18,17.5738909 18,18.3335137 C18,19.2981914 18.6730056,20.0832432 19.5,20.0832432 C19.775974,20.0832432 20,20.3451074 20,20.6664863 C20,20.9878652 19.775974,21.2497295 19.5,21.2497295 C19.224026,21.2497295 19,20.9878652 19,20.6664863 L19,20.6664863 L18,20.6664863 C18,21.4261091 18.419295,22.0677848 19,22.3090895 L19,22.3090895 L19,23 L20,23 L20,22.3090895 C20.580705,22.0677848 21,21.4261091 21,20.6664863 C21,19.7018086 20.3269944,18.9167568 19.5,18.9167568 C19.224026,18.9167568 19,18.6548926 19,18.3335137 C19,18.0121348 19.224026,17.7502705 19.5,17.7502705 C19.775974,17.7502705 20,18.0121348 20,18.3335137 L20,18.3335137 L21,18.3335137 C21,17.5738909 20.580705,16.9322152 20,16.6909105 L20,16.6909105 L20,16 Z"
          id="Combined-Shape"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgWithholdingNotification;
