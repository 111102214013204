import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useLocation, useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import EditIcon from '@mui/icons-material/Edit';
import RemoveMoneyIcon from '../icons/RemoveMoney';

import { useUser } from '../auth/useUser';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import { useGlobalContext } from '../auth/useGlobalContext';
import {
  setSelectedAccount,
  useTransactionReducer,
} from './TransactionReducer';
import { getAccount } from '../api/AccountApi';
import Layout from '../components/Layout';
import SiraAccountOwnerInfo from '../components/form/SiraAccountOwnerInfo';
import { getAccountOwner } from '../api/AccountOwnerApi';
import PaperlessPage from '../components/PaperlessPage';
import {
  changeBeneficiaryClaimStatus,
  createOrSaveBeneficiaryClaim,
  getBeneficiariesClaimsProfile,
} from '../api/BeneficiariesClaimsApi';
import {
  BeneficiariesClaimsInfo,
  BeneficiaryClaim,
  BeneficiaryStatus,
  BeneficiaryTypes,
} from '../api/BeneficiaryClaimsApi.d';
import { dateValueFormatter } from '../components/form/beneficiariesClaims/resource.utils';
import { beneficiaryRelationshipOptions } from '../app.constants';
import { beneficiaryPreferredContactOptions } from '../components/form/beneficiariesClaims/BeneficiariesClaimsContactInfoForm';
import { getUser, getUsers } from '../api/UserApi';
import { User, UserRole } from '../api/UserApi.d';
import ViewBeneficiaryClaimFiles from '../components/form/beneficiariesClaims/ViewBeneficiaryClaimFiles';
import DesignateBeneficiariesClaim from './DesignateBenificiariesClaim';
import BeneficiariesClaimsStatusSelectField, {
  BENEFICIARIES_STATUS_INIT,
} from '../components/form/beneficiariesClaims/BeneficiariesClaimsStatusSelectField';
import BeneficiariesClaimsRepForm, {
  BENEFICIARIES_REP_INIT,
} from '../components/form/beneficiariesClaims/BeneficiariesClaimsRepForm';
import BeneficiariesClaimsCommentForm, {
  BENEFICIARIES_COMMENT_INIT,
} from '../components/form/beneficiariesClaims/BeneficiariesClaimsComments';
import { errorMessages } from '../utils/errorhandling.utils';
import AccountOwnerAccountFmv from '../components/accountOwnerProfile/AccountOwnerAccountFmv';
import AccountOwnerAccountDistributions from '../components/accountOwnerProfile/AccountOwnerAccountDistributions';
import SiraTextEditor from '../components/form/SiraTextEditor';

function BeneficiaryClaimProfile() {
  let isMounted = true;
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);
  const [beneficiaryClaimProfile, setBeneficiariesClaimsProfile] = useState(
    {} as BeneficiariesClaimsInfo,
  );
  const { user } = useUser();
  const { roles: userRoles = [] } = user;
  const { addGlobalMessage, organization } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(useLocation().search);
  const [pageState] = useTransactionReducer();
  const { accountId = '', accountOwnerId = '' } = pageState.selectedAccount;
  const [repInfo, setRepInfo] = useState({} as User);
  const [editRepInfo, setEditRepInfo] = useState(false as boolean);
  const [response, setResponse] = useState<Array<User>>([]);
  const [updateComment, setUpdateComment] = useState(false);
  const [commentValue, setCommentValue] = useState(
    beneficiaryClaimProfile.comments,
  );
  const { beneyClaimSpecialHandling } = organization;

  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      alignItems: 'center',
      pl: 0,
      pr: 2,
    },
  };

  const grabRepInfo = async (): Promise<void> => {
    await getUsers(user.organizationId, user.token)
      .then((res) => {
        setResponse(res.data);
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage('Error fetching organization users');
        }
      });
  };

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    await getAccount(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      user.token,
      user,
    )
      .then(async (res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          await getAccountOwner(
            res.data.accountOwnerId,
            user.organizationId,
            user.token,
            user,
          )
            .then((accountRes) => {
              setAccountOwner(accountRes.data);
            })
            .catch((err) => {
              if (isMounted) {
                addGlobalMessage(
                  errorMessages(err) ||
                    'Could not fetch the account owner information',
                );
              }
            });
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Could not fetch the preselected account',
          );
        }
      });
  }

  // Optionaly set account when passed query params
  async function preselectAccountInfo(): Promise<void> {
    if (queryParams.get('accountId')) {
      await fetchAndSetAccount();
    }
  }

  async function updateBeneficiaryClaim(userId): Promise<void> {
    const mergedData: BeneficiaryClaim = {
      beneficiaryClaim: {
        ...beneficiaryClaimProfile,
        finOrgContactUserId: userId,
        finOrgSubmittedByUserId: userId,
      },
    };

    await createOrSaveBeneficiaryClaim(
      mergedData,
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
    )
      .then(() => {
        addGlobalMessage('Representative info successfully updated', {
          severity: 'success',
        });
      })
      .catch((err) => {
        if (isMounted) {
          setRepInfo({} as User);
          addGlobalMessage(
            errorMessages(err) ||
              'Could not update of representative information',
          );
        }
      });
  }

  async function updateBeneficiaryClaimComment(comments): Promise<void> {
    const mergedData: BeneficiaryClaim = {
      beneficiaryClaim: {
        ...beneficiaryClaimProfile,
        comments,
      },
    };

    await createOrSaveBeneficiaryClaim(
      mergedData,
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
    )
      .then(() => {
        setUpdateComment(false);
        setCommentValue(comments);
        addGlobalMessage('Comments successfully updated', {
          severity: 'success',
        });
      })
      .catch((err) => {
        if (isMounted) {
          setRepInfo({} as User);
          addGlobalMessage(
            errorMessages(err) || 'Could not update of comments',
          );
        }
      });
  }

  async function getBenefitClaimsRepInfo(userId): Promise<void> {
    await getUser(user.organizationId, userId, user.token)
      .then((responseValue) => {
        setRepInfo(responseValue.data);
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Could not fetch rep information',
          );
        }
      });
  }

  async function getBenefitClaimsInfo(): Promise<void> {
    await getBeneficiariesClaimsProfile(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      user.token,
      user,
    )
      .then((responseValue) => {
        setBeneficiariesClaimsProfile(responseValue.data);
        if (responseValue.data.finOrgContactUserId) {
          getBenefitClaimsRepInfo(responseValue.data.finOrgContactUserId);
        } else {
          grabRepInfo();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) ||
              'Could not fetch the preselected beneficiary claim profile',
          );
        }
      });
  }

  const returnRelationship = (beneficiariesClaims: BeneficiariesClaimsInfo) => {
    let label;
    beneficiaryRelationshipOptions.forEach((relationships) => {
      if (
        relationships.value === beneficiariesClaims.relationshipToAccountOwner
      ) {
        label = relationships.label;
      }
    });
    return label;
  };

  const returnPreferredContact = (
    beneficiariesClaims: BeneficiariesClaimsInfo,
  ) => {
    let label;
    beneficiaryPreferredContactOptions.forEach((preferredContactMethod) => {
      if (
        preferredContactMethod.value ===
        beneficiariesClaims.preferredContactMethod
      ) {
        label = preferredContactMethod.label;
      }
    });
    return label;
  };

  async function updateBeneficiariesStatus(
    status: BeneficiaryStatus,
    beneficiaryClaimId,
  ): Promise<void> {
    await changeBeneficiaryClaimStatus(
      beneficiaryClaimId,
      user.organizationId,
      status,
      user.token,
      accountId,
      accountOwnerId,
    )
      .then(() => {
        getBenefitClaimsInfo();
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) ||
              'Could not change status of beneficiary claim',
          );
        }
      });
  }

  useEffect(() => {
    if (user.organizationId && user.token) {
      preselectAccountInfo();
      grabRepInfo();
    }
    return () => {
      isMounted = false;
    };
  }, [user.token]);

  useEffect(() => {
    if (user.token) {
      setIsLoading(true);
      getBenefitClaimsInfo();
    }
  }, [pageState.selectedAccount, user.token]);

  useEffect(() => {
    setCommentValue(beneficiaryClaimProfile.comments);
  }, [beneficiaryClaimProfile]);

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Beneficiary Claims Profile
          </Typography>
          <Paper sx={{ mt: 2 }} elevation={3}>
            {accountId && (
              <SiraAccountOwnerInfo
                selectedAccount={pageState.selectedAccount}
                accountOwner={accountOwner}
              />
            )}
          </Paper>
          {isLoading ? (
            <Box mt={4}>
              <LinearProgress color="secondary" />
            </Box>
          ) : (
            <>
              <Grid container spacing={1}>
                {beneyClaimSpecialHandling && user.roles.includes(UserRole.multi) ? (
                  <Grid item xs={12}>
                    <Paper sx={{ mt: 2 }} elevation={3}>
                      <Accordion
                        sx={{ mt: 2, pt: 2, pb: 2 }}
                        elevation={0}
                        slotProps={{
                          transition: {
                            mountOnEnter: true,
                            unmountOnExit: true,
                          },
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="h6" color="primary" gutterBottom>
                            Beneficiary Claim Special Handling
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <SiraTextEditor
                            setReadOnly
                            notTask
                            defaultValue={beneyClaimSpecialHandling}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  </Grid>
                ) : null}
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <Paper sx={{ mt: 2 }} elevation={3}>
                    <Accordion
                      sx={{ mt: 2, pt: 2, pb: 2 }}
                      elevation={0}
                      slotProps={{
                        transition: {
                          mountOnEnter: true,
                          unmountOnExit: true,
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" color="primary" gutterBottom>
                          Beneficiary Claim Information
                        </Typography>
                      </AccordionSummary>
                      <Divider sx={{ mb: 2 }} />
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              color="secondary"
                              gutterBottom
                            >
                              Account Information
                            </Typography>
                            <Typography variant="body1" color="primary">
                              Claim Amount:{' '}
                              <NumberFormat
                                value={
                                  beneficiaryClaimProfile.dateOfDeathAccountBalance
                                }
                                prefix="$"
                                displayType="text"
                                thousandSeparator
                                isNumericString
                                fixedDecimalScale
                                decimalScale={2}
                              />
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              pt={0.5}
                            >
                              Submitted Date:{' '}
                              {dateValueFormatter(
                                beneficiaryClaimProfile.submittedDate,
                              )}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              pt={0.5}
                            >
                              Submitted Status:{' '}
                              {beneficiaryClaimProfile.beneficiaryClaimStatus}
                            </Typography>

                            {userRoles.includes(UserRole.multi) && (
                              <Box pt={2}>
                                <BeneficiariesClaimsStatusSelectField
                                  initialValues={{
                                    ...BENEFICIARIES_STATUS_INIT,
                                    ...beneficiaryClaimProfile,
                                  }}
                                  onSubmit={(values) => {
                                    updateBeneficiariesStatus(
                                      values.beneficiaryClaimStatus,
                                      beneficiaryClaimProfile.beneficiaryClaimId,
                                    );
                                  }}
                                />
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              color="secondary"
                              gutterBottom
                              pt={0.5}
                            >
                              Preferred Contact
                            </Typography>
                            <Typography variant="body1" color="primary">
                              Name:{' '}
                              {`${beneficiaryClaimProfile.firstName} ${beneficiaryClaimProfile.lastName}`}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              pt={0.5}
                            >
                              Relationship:{' '}
                              {returnRelationship(beneficiaryClaimProfile)}
                            </Typography>
                            {beneficiaryClaimProfile.relationshipToAccountOwner ===
                              BeneficiaryTypes.OTHER && (
                              <Typography
                                variant="body1"
                                color="primary"
                                pt={0.5}
                              >
                                Relationship Description:{' '}
                                {
                                  beneficiaryClaimProfile.otherRelationshipToAccountOwner
                                }
                              </Typography>
                            )}
                            <Typography
                              variant="body1"
                              color="primary"
                              pt={0.5}
                            >
                              Preferred Contact Method:{' '}
                              {returnPreferredContact(beneficiaryClaimProfile)}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              pt={0.5}
                            >
                              Address:
                            </Typography>
                            <Typography variant="body2" color="primary">
                              {`${beneficiaryClaimProfile.pcmAddress}, ${beneficiaryClaimProfile.pcmCity}, ${beneficiaryClaimProfile.pcmState} ${beneficiaryClaimProfile.pcmZip}`}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              pt={0.5}
                            >
                              Email:
                            </Typography>
                            <Typography variant="body2" color="primary">
                              {beneficiaryClaimProfile.pcmEmail}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              pt={0.5}
                            >
                              Phone:
                            </Typography>
                            <Typography variant="body2" color="primary">
                              {beneficiaryClaimProfile.pcmPhone}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              color="secondary"
                              gutterBottom
                              mt={0.5}
                            >
                              Financial Organization Representative Information
                              <IconButton
                                data-testid="Edit Rep"
                                size="small"
                                aria-label="Edit Rep"
                                disabled={editRepInfo}
                                onClick={() => {
                                  setEditRepInfo(!editRepInfo);
                                }}
                                sx={{
                                  whiteSpace: 'nowrap',
                                  marginLeft: 1,
                                }}
                                color="primary"
                              >
                                <EditIcon />
                              </IconButton>
                            </Typography>

                            {editRepInfo ? (
                              <BeneficiariesClaimsRepForm
                                initialValues={{
                                  ...BENEFICIARIES_REP_INIT,
                                }}
                                response={response}
                                submitName="Save Rep"
                                onSubmit={(values) => {
                                  setEditRepInfo(!editRepInfo);
                                  getBenefitClaimsRepInfo(values.selectedRep);
                                  updateBeneficiaryClaim(values.selectedRep);
                                }}
                              />
                            ) : (
                              <>
                                <Typography variant="body1" color="primary">
                                  Name:{' '}
                                  {`${repInfo.firstName} ${repInfo.lastName}`}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="primary"
                                  pt={0.5}
                                >
                                  Email: {repInfo.emailAddress}
                                </Typography>
                                {repInfo.phoneNumber && (
                                  <>
                                    <Typography
                                      variant="body1"
                                      color="primary"
                                      pt={0.5}
                                    >
                                      Phone Number: {repInfo.phoneNumber}
                                    </Typography>
                                  </>
                                )}
                              </>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              color="secondary"
                              gutterBottom
                              pt={0.5}
                            >
                              Comments{' '}
                              <IconButton
                                data-testid="Edit Rep"
                                size="small"
                                aria-label="Edit Rep"
                                disabled={updateComment}
                                onClick={() => {
                                  setUpdateComment(true);
                                }}
                                sx={{
                                  color: 'primary',
                                  whiteSpace: 'nowrap',
                                  marginLeft: 1,
                                  backgroundColor: 'primary',
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Typography>
                            <Typography variant="body1" color="primary">
                              {commentValue}
                            </Typography>{' '}
                            {updateComment && (
                              <BeneficiariesClaimsCommentForm
                                initialValues={{
                                  ...BENEFICIARIES_COMMENT_INIT,
                                  comments: commentValue,
                                }}
                                submitName="Save Comment"
                                onSubmit={(values) => {
                                  updateBeneficiaryClaimComment(
                                    values.comments,
                                  );
                                }}
                                cancel={() => {
                                  setUpdateComment(false);
                                }}
                              />
                            )}
                          </Grid>
                          <Grid container spacing={0.5}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                color="secondary"
                                gutterBottom
                                pt={0.5}
                                pl={1}
                              >
                                Designated Beneficiary
                              </Typography>
                            </Grid>{' '}
                            <Grid item>
                              <Box pl={1}>
                                {beneficiaryClaimProfile.designateBeneficiary ? (
                                  <CheckBoxIcon />
                                ) : (
                                  <CheckBoxOutlineBlankIcon />
                                )}
                              </Box>
                            </Grid>{' '}
                            <Grid item xs={4}>
                              <Typography variant="body1" color="primary">
                                No beneficiary designation on file for this
                                account.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Paper>
                </Grid>
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <Paper sx={{ mt: 2 }} elevation={3}>
                    <Accordion
                      sx={{ mt: 2, pt: 2, pb: 2 }}
                      elevation={0}
                      slotProps={{
                        transition: {
                          mountOnEnter: true,
                          unmountOnExit: true,
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container wrap="nowrap" sx={classes.buttonBar}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              color="primary"
                              gutterBottom
                            >
                              Fair Market Value
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>

                      <AccordionDetails>
                        <AccountOwnerAccountFmv
                          account={pageState.selectedAccount}
                          accountOwner={accountOwner}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Paper>
                </Grid>{' '}
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <Paper sx={{ mt: 2 }} elevation={3}>
                    <Accordion
                      sx={{ mt: 2, pt: 2, pb: 2 }}
                      elevation={0}
                      slotProps={{
                        transition: {
                          mountOnEnter: true,
                          unmountOnExit: true,
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container wrap="nowrap" sx={classes.buttonBar}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              color="primary"
                              gutterBottom
                            >
                              Distributions
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                navigate(
                                  `/transactions/distributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}`,
                                );
                              }}
                              startIcon={<RemoveMoneyIcon color="primary" />}
                              sx={{ whiteSpace: 'nowrap' }}
                            >
                              Add Distribution
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionSummary>

                      <AccordionDetails>
                        <AccountOwnerAccountDistributions
                          account={pageState.selectedAccount}
                          accountOwner={accountOwner}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ mt: 2 }} elevation={3}>
                    <Accordion
                      sx={{ mt: 2, pt: 2, pb: 2 }}
                      elevation={0}
                      slotProps={{
                        transition: {
                          mountOnEnter: true,
                          unmountOnExit: true,
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" color="primary" gutterBottom>
                          Uploaded Documents
                        </Typography>
                      </AccordionSummary>

                      <Divider sx={{ mb: 2 }} />
                      <Box p={2}>
                        <ViewBeneficiaryClaimFiles
                          beneficiaryClaimDocuments={
                            beneficiaryClaimProfile.beneficiaryClaimDocuments
                          }
                          beneficiaryClaimsId={
                            beneficiaryClaimProfile.beneficiaryClaimId
                          }
                        />
                      </Box>
                    </Accordion>
                  </Paper>
                </Grid>
              </Grid>
              <DesignateBeneficiariesClaim
                accountOwner={accountOwner}
                accountId={accountId}
                account={pageState.selectedAccount}
                beneficiaryClaimsId={beneficiaryClaimProfile.beneficiaryClaimId}
                claimBeneficiaries={beneficiaryClaimProfile.beneficiaries}
              />
            </>
          )}
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default BeneficiaryClaimProfile;
