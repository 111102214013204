export interface TaxDocumentResponse {
  data?: Array<TaxDocument>;
  errorMessage?: any;
}

export enum TaxFormType {
  TAX_FORM_1099R = 'TAX_FORM_1099R',
  TAX_FORM_1099SA = 'TAX_FORM_1099SA',
  TAX_FORM_1099Q = 'TAX_FORM_1099Q',
  TAX_FORM_5498 = 'TAX_FORM_5498',
  TAX_FORM_5498SA = 'TAX_FORM_5498SA',
  TAX_FORM_5498ESA = 'TAX_FORM_5498ESA',
}

export interface TaxDocument {
  id: number;
  financialOrganizationId: number;
  taxFormType: TaxFormType;
  taxYear: number;
  readyToPrint: boolean;
  sentToOwner: boolean;
  sentToIrs: boolean;
  hasCorrections: boolean;
  correctionsReadyToPrint: boolean;
  correctionsSentToOwner: boolean;
  correctionsSentToIrs: boolean;
  updatedBy: string;
  updatedDate: string;
  enabled: boolean;
}
