import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
  useTheme,
  FormControlLabel,
  Typography,
  Grid,
  FormControlLabelProps,
} from '@mui/material';
import { FieldAttributes } from 'formik';

interface SiraCheckboxProps extends FormControlLabelProps {
  field: FieldAttributes<any>;
  form: any;
  additionText?: string;
}

const SiraCheckbox = (props: SiraCheckboxProps) => {
  const {
    field,
    form: { touched, errors },
    name,
    label,
    additionText,
  } = props;
  const fieldName = name || field.name;
  const theme = useTheme();
  const classes = {
    errorHelper: {
      color: theme.palette.error.main,
      paddingLeft: theme.spacing(2),
    },
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControlLabel
          {...field}
          name={name}
          label={label}
          checked={Boolean(field.value)}
          control={
            <Checkbox {...field} {...props} name={fieldName} color="primary" />
          }
        />

        {additionText ? (
          <Typography
            variant="caption"
            mt={1}
            style={{
              fontStyle: 'italic',
              color: 'rgba(0, 0, 0, 0.54)',
            }}
          >
            {additionText}
          </Typography>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        {touched[fieldName] && errors[fieldName] && (
          <Typography variant="caption" sx={classes.errorHelper}>
            {errors[fieldName]}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SiraCheckbox;
