/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import StepButtonBar from '../steps/StepButtonBar';
import { FederalRate } from '../../api/SiteApi.d';
import SiraPercentField from './SiraPercentField';
import SiraTextField from './SiraTextField';
import SiraSelectField from './SiraSelectField';
import { monthOptions } from '../../app.constants';

export const FEDERAL_RATE_SCHEMA = yup.object({
  annual: yup.string().required().label('Annual Rate'),
  month: yup.number().required().label('Month'),
  monthly: yup.string().required().label('Monthly Rate'),
  quarterly: yup.string().required().label('Quarterly Rate'),
  semiAnnual: yup.string().required().label('Semi-Annual Rate'),
  year: yup.number().min(1900).required().label('Tax Year'),
});

export const FEDERAL_RATE_INIT: FederalRate = {
  annual: '',
  month: '',
  monthly: '',
  quarterly: '',
  semiAnnual: '',
  year: '',
};

export interface FederalRateFormProps {
  isEditing?: boolean;
  scrollToOnMount?: boolean;
  initialValues: FederalRate;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}

const FederalRateForm = ({
  isEditing = false,
  scrollToOnMount = false,
  initialValues,
  onSubmit,
  onReset,
  submitName,
  resetName,
  onCancel,
}: FederalRateFormProps) => {
  useEffect(() => {
    if (scrollToOnMount) {
      const yearInputEl = document.querySelector('input[name="year"]');

      if (yearInputEl) {
        yearInputEl.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      validationSchema={FEDERAL_RATE_SCHEMA}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SiraTextField
                  disabled={isEditing}
                  name="year"
                  label="Tax Year"
                />
              </Grid>
              <Grid item xs={6}>
                <SiraSelectField
                  disabled={isEditing}
                  items={monthOptions}
                  name="month"
                  label="Month"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <SiraPercentField name="annual" label="Annual Rate" />
              </Grid>
              <Grid item xs={6} sm={3}>
                <SiraPercentField name="semiAnnual" label="Semi-Annual Rate" />
              </Grid>
              <Grid item xs={6} sm={3}>
                <SiraPercentField name="quarterly" label="Quarterly Rate" />
              </Grid>
              <Grid item xs={6} sm={3}>
                <SiraPercentField name="monthly" label="Monthly Rate" />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Enter the 120% AFR Mid-Term rate for each month from the{' '}
                  <Link
                    variant="inherit"
                    href="https://www.irs.gov/applicable-federal-rates"
                    target="_blank"
                  >
                    IRS website
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FederalRateForm;
