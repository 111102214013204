import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { useNavigate } from 'react-router-dom';
import { setBeneficiariesResponse } from '../components/beneficiaries/BeneficiariesReducer';
import { getBeneficiaries, updateBeneficiary } from '../api/BeneficiariesApi';
import { useUser } from '../auth/useUser';
import {
  Beneficiary,
  BeneficiaryStatus,
  BeneficiaryTypes,
} from '../api/BeneficiariesApi.d';
import BeneficiariesEditIcon from '../icons/BeneficiariesEdit';

import TrimmedNumber from '../components/TrimmedNumber';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import { beneficiaryRelationshipOptions } from '../app.constants';
import {
  attachBeneficiaryToClaim,
  detachBeneficiaryToClaim,
  getBeneficiariesClaimsProfile,
} from '../api/BeneficiariesClaimsApi';
import { ClaimsBeneficiary } from '../api/BeneficiaryClaimsApi';
import { useGlobalContext } from '../auth/useGlobalContext';
import DesignatedBeneficiariesCard from './DesignateBeneficiariesCard';
import { UserRole } from '../api/UserApi.d';
import DesignatedBeneficiaryProfile from '../components/form/beneficiariesClaims/DesignatedBeneficiaryProfile';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import { Account } from '../api/AccountApi.d';
import BeneficiaryForm, {
  BENEFICIARY_INIT,
} from '../components/form/BeneficiaryForm';
import { errorMessages } from '../utils/errorhandling.utils';


export interface DesignateBeneficiariesClaimProps {
  accountOwner: AccountOwner;
  accountId: string;
  account: Account;
  beneficiaryClaimsId: string;
  claimBeneficiaries: Array<ClaimsBeneficiary>;
}

function DesignateBeneficiariesClaim(props: DesignateBeneficiariesClaimProps) {
  let isMounted = true;
  const {
    accountOwner,
    accountId,
    account,
    beneficiaryClaimsId,
    claimBeneficiaries,
  } = props;
  const [displaySelection, setDisplaySelection] = useState<boolean>(false);
  const { user } = useUser();
  const navigate = useNavigate();

  const { roles: userRoles = [] } = user;
  const [beneficiaries, setBeneficiaries] = useState<Array<Beneficiary>>([]);
  const { addGlobalMessage } = useGlobalContext();
  const [beneficiariesCard, setBeneficiariesCard] = useState<
    Array<ClaimsBeneficiary>
  >([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(
    {} as ClaimsBeneficiary
  );
  const [beneficiary, setBeneficiary] = useState({} as Beneficiary);
  const [editBeneficiary, setEditBeneficiary] = useState(false as boolean);

  const [active, setActive] = useState<string>('');


  async function getBeneficiaryVersions(): Promise<void> {
    const params = {
      status: BeneficiaryStatus.live,
    };

    await getBeneficiaries(
      accountId,
      accountOwner.accountOwnerId,
      user.organizationId,
      user.token,
      params,
      user
    )
      .then((res) => {
        if (isMounted) {
          setEditBeneficiary(false);
          setBeneficiariesResponse(res);
          const beneficiariesList = [] as Array<Beneficiary>;

          res.data.forEach((data) => {
            if (data.beneficiaryStatus === BeneficiaryStatus.live)
              data.primaryBeneficiaries.forEach((primarydata) => {
                beneficiariesList.push({
                  ...primarydata,
                  primaryBeneficiary: true,
                });
              });

            data.secondaryBeneficiaries.forEach((secondarydata) => {
              beneficiariesList.push({
                ...secondarydata,
                primaryBeneficiary: false,
              });
            });
          });

          setBeneficiaries(beneficiariesList);
        }
      })
      .catch((err) => {


        if (isMounted) {
 addGlobalMessage(errorMessages(err) ||
              'Could not fetch beneficiaries for this account'
          );
        }
      });
  }

  async function getBenefitClaimsInfo(): Promise<void> {
    await getBeneficiariesClaimsProfile(
      accountId,
      accountOwner.accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((responseValue) => {
        setBeneficiariesCard(responseValue.data.beneficiaries);
      })
      .catch((err) => {

        if (isMounted) {
 addGlobalMessage(errorMessages(err) || 'Could not fetch the claim beneficiaries'
          );
        }
      });
  }

  async function addBeneficiary(row): Promise<void> {
    const { beneficiaryId } = row;
    attachBeneficiaryToClaim(
      user.organizationId,
      beneficiaryClaimsId,
      accountId,
      accountOwner.accountOwnerId,
      beneficiaryId,
      user.token
    )
      .then(() => {
        getBenefitClaimsInfo();
      })
      .catch((err) => {

        if (isMounted) {
 addGlobalMessage(errorMessages(err) || 'Could not fetch the claim info'
          );
        }
      });
  }

  async function addDistributionOption(value): Promise<void> {
    const { beneficiaryDistributionOption } = value;
    attachBeneficiaryToClaim(
      user.organizationId,
      beneficiaryClaimsId,
      accountId,
      accountOwner.accountOwnerId,
      selectedBeneficiary.beneficiaryId.toString(),
      user.token,
      beneficiaryDistributionOption
    )
      .then(() => {
        getBenefitClaimsInfo();
        addGlobalMessage('Distribution was successfully added', 'success');
        setActive('success');
      })
      .catch((err) => {

        if (isMounted) {
 addGlobalMessage(errorMessages(err) || 'Could not fetch the claim info'
          );
          setActive('error');
        }
      });
  }


  const detachBeneficiary = async (value: ClaimsBeneficiary): Promise<void> => {
    const { beneficiaryClaimId, beneficiaryId } = value;
    detachBeneficiaryToClaim(
      user.organizationId,
      beneficiaryClaimId,
      accountId,
      accountOwner.accountOwnerId,
      beneficiaryId,
      user.token
    )
      .then(() => {
        getBenefitClaimsInfo();
        if (selectedBeneficiary.beneficiaryId === beneficiaryId) {
          setSelectedBeneficiary({});
        }
      })
      .catch((err) => {

        if (isMounted) {
 addGlobalMessage(errorMessages(err) || 'Could not fetch the claim info'
          );
        }
      });
  };
  // Update a single, ACTIVE beneficiary after it's editied
  const updateEditedBeneficiary = async (data: Beneficiary): Promise<void> => {
    await updateBeneficiary(
      data,
      user.organizationId,
      accountId,
      accountOwner.accountOwnerId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          addGlobalMessage('Beneficiary was successfully updated', 'success');

          getBeneficiaryVersions();
        }
      })
      .catch((err) => {
        if (isMounted) {
  
 addGlobalMessage(errorMessages(err) || 'Error updating active beneficiary'
          );
        }
      });
  };


  useEffect(() => {
    if (user.token && accountOwner.accountOwnerId) {
      getBeneficiaryVersions();
    }

    return () => {
      isMounted = false;
    };
  }, [accountOwner.accountOwnerId, user.token]);

  useEffect(() => {
    if (claimBeneficiaries) {
      setBeneficiariesCard(claimBeneficiaries);
    }
  }, [claimBeneficiaries]);

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Beneficiary',
      width: 200,
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'relationship',
      headerName: 'Relationship',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (
          beneficiaryRelationshipOptions.find(
            ({ value = '' }) => value === params.value
          ).label || params.value
        );
      },
    },
    {
      field: 'primaryBeneficiary',
      headerName: 'Beneficiary',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;

        return value ? 'Primary' : 'Secondary';
      },
    },
    {
      field: 'percent',
      headerName: 'Percent',
      width: 80,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const percent = value.toString();

        return (
          <>
            <Box display="inline-block" mr={4}>
              <TrimmedNumber value={percent} decimalPlaces={2} />%
            </Box>
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 125,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <Grid container>
            <Tooltip title="Add">
              <IconButton
                data-testid="Add Beneficiary"
                size="small"
                aria-label="Add beneficiary"
                onClick={() => {
                  addBeneficiary(row);
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                data-testid="Edit Beneficiary"
                size="small"
                aria-label="Edit beneficiary"
                onClick={() => {
                  setBeneficiary(row);
                  setEditBeneficiary(true);
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];

  const rows = beneficiaries.map((result: Beneficiary, index: number) => ({
    ...result,
    id: index,
    fullName: [
      BeneficiaryTypes.TRUST,
      BeneficiaryTypes.ESTATE,
      BeneficiaryTypes.NONEHUMAN,
    ].includes(result.relationship)
      ? result.name
      : `${result.firstName} ${result.lastName}`,
    isEditing: false,
  }));

  return (
    <>
      <Box mt={4}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={2} sm={2}>
                <Typography variant="h5" color="secondary" gutterBottom>
                  Beneficiaries
                </Typography>
              </Grid>
              {userRoles.includes(UserRole.multi) && (
                <Grid item xs={10} sm={10}>
                  <Box sx={{ display: 'flex' }} justifyContent="flex-end">
                    <Button
                      style={{ marginRight: '5px' }}
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate(
                          `/transactions/beneficiaries?accountId=${accountId}&accountOwnerId=${accountOwner.accountOwnerId}`
                        );
                      }}
                      startIcon={<BeneficiariesEditIcon color="primary" />}
                    >
                      Update Beneficiaries
                    </Button>
                    <Button
                      style={{ marginRight: '5px' }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setDisplaySelection(true);
                      }}
                    >
                      Add Beneficiary
                    </Button>
 
                  </Box>
                </Grid>
              )}
            </Grid>
            <Divider sx={{ mb: 2, pt: 2 }} />
            {displaySelection && (
              <Paper elevation={3}>
                <Grid item xs={12}>
                  <DataGrid
                    components={{
                      NoRowsOverlay: SiraNoRowsOverlay,
                    }}
                    rowHeight={75}
                    disableColumnMenu
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10]}
                    hideFooter
                    autoHeight
                    columns={columns}
                    rows={rows}
                  />
                </Grid>
              </Paper>
            )}
            {!editBeneficiary && (
              <Grid container spacing={2}>
                {beneficiariesCard &&
                  beneficiariesCard.length > 0 &&
                  beneficiariesCard.map((beneficiaryCard) => (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      mt={3}
                      key={`claims-beneficiary-card_${beneficiaryCard.beneficiaryId}`}
                    >
                      <DesignatedBeneficiariesCard
                        beneficiary={beneficiaryCard}
                        selectedBeneficiaryId={
                          selectedBeneficiary.beneficiaryId
                        }
                        setSelectedBeneficiary={setSelectedBeneficiary}
                        deleteBeneficiary={detachBeneficiary}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
            {selectedBeneficiary.beneficiaryId && (
              <DesignatedBeneficiaryProfile
                beneficiary={selectedBeneficiary}
                addDistribution={(values) => {
                  addDistributionOption(values);
                }}
                addedDistribution={active}
                account={account}
              />
            )}
            {editBeneficiary && (
              <Paper elevation={3} sx={{ marginTop: 5, padding: 2 }}>
                <BeneficiaryForm
                  initialValues={{
                    ...BENEFICIARY_INIT,
                    ...beneficiary,
                  }}
                  accountOwner={accountOwner}
                  account={account}
                  onSubmit={updateEditedBeneficiary}
                  onCancel={() => {
                    setEditBeneficiary(false);
                  }}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Box>

    </>
  );
}

export default DesignateBeneficiariesClaim;
