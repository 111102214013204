import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Box} from '@mui/material';
import { getYear, parseISO } from 'date-fns';


import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import {
  getFairMarketValues,
} from '../../api/FairMarketValueApi';
import {
  FairMarketValue,
} from '../../api/FairMarketValueApi.d';
import {
  currentTaxYear,
  determineAgeGroup,
} from '../../app.constants';
import { currencyValueFormatter } from '../../utils/DataGrid.utils';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { Account, AccountType } from '../../api/AccountApi.d';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { getLifeExpectancyTerms } from '../../api/LifeExpectancyApi';
import { errorMessages } from '../../utils/errorhandling.utils';


interface AccountOwnerAccountFmvProps {
  account: Account;
  accountOwner: AccountOwner;
}

interface AccountFmvRow extends FairMarketValue {
  dateOfDeathAccountBalance?: string;
  rmd?: string;
  term?: number;
  lifeExpectancyTable?: string;
  dateofDeath?: string;
}

export const POSITIVE_DOLLAR_SCHEMA = {
  taxYearFilters: yup.array(yup.number()).label('Fair Market Value'),
};

function OwnerAccountFmv(props: AccountOwnerAccountFmvProps) {
  let isMounted = true;
  const { account, accountOwner } = props;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [fmvs, setFmvs] = useState([] as Array<AccountFmvRow>);

  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { accountId, accountOwnerId, accountType } = account;
  const { dateOfBirth, dateOfDeath } = accountOwner;
  const { token, organizationId } = user;



  // Fetch and attach some current tax year's RMD data to that year's FMV entry
  async function getAndAttachRMDForCurrentTaxYear(
    fmvRows: Array<AccountFmvRow>
  ): Promise<void> {
    // Look for and only load up RMD if there's a current tax year FMV entry
    const { fairMarketValue: currentTaxYearFMV = '' } =
      fmvRows.find(({ taxYear }) => Number(taxYear) === currentTaxYear) || {};

    // If there is, get the RMD info for this account using the relevant FMV
    if (currentTaxYearFMV) {
      await getLifeExpectancyTerms(
        organizationId,
        accountId,
        accountOwnerId,
        null,
        null, // We don't have to pass in the FMV fromt he client though as the endpoint will use the current one on record
        token,
        user
      ).then(({ data: lifeExpectancy }) => {
        const { uniformLifetimeRMD = {}, jointLifeExpectancyRMD = {} } =
          lifeExpectancy; // We'll only be getting ULE for now
        const { currently73Years, over73, turning73ThisYear } =
          determineAgeGroup(dateOfBirth);
        const isIRA = [
          AccountType.traditionalIra,
          AccountType.traditionalSep,
          AccountType.simpleIra,
        ].includes(accountType);

        // Add only when IRA account and owner 72 or older and an RMD election with the current FMV is made
        if (
          uniformLifetimeRMD &&
          isIRA &&
          (currently73Years || over73 || turning73ThisYear)
        ) {
          const { rmd: uleRMD, distributionPeriod } = uniformLifetimeRMD;
          const { rmd: jleRMD, lifeExpectancyFactor } = jointLifeExpectancyRMD;

          // Merge the RMD data into the row for the current tax year
          const mergedFmvs: Array<AccountFmvRow> = fmvRows.map((row) =>
            Number(row.taxYear) === currentTaxYear
              ? {
                  ...row,
                  rmd: jleRMD || uleRMD, // Prefer JLE first
                  term: lifeExpectancyFactor || distributionPeriod,
                  lifeExpectancyTable: jleRMD
                    ? 'Joint Life Expectancy'
                    : 'Uniform Life Expectancy',
                }
              : row
          );

          setFmvs(mergedFmvs);
        } else {
          setFmvs(fmvRows);
        }
      });
    } else {
      setFmvs(fmvRows);
    }

    setIsLoading(false);
  }



  // Get all of the selected account's FMV data and store it
  async function getAndSetFMVData(): Promise<void> {
    setIsLoading(true);

    await getFairMarketValues(organizationId, accountId, accountOwnerId, token,{}, user)
      .then(({ data = {} }) => {
        const { fairMarketValues = [], dateOfDeathAccountBalance = '' } = data;

        const yearOfDeath = String(getYear(parseISO(dateOfDeath)));

        // Morph the DOD & account balance info into a row
        const dateOfDeathFmvRow: AccountFmvRow = {
          dateOfDeathAccountBalance,
          taxYear: yearOfDeath,
        };

        if (isMounted) {

          getAndAttachRMDForCurrentTaxYear(
            // If a date of death account balance exists, shove it in as the first row
            dateOfDeathAccountBalance
              ? [dateOfDeathFmvRow, ...fairMarketValues]
              : fairMarketValues
          );
        }
      })
      .catch((err) => {

        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Error fetching fair market values'
          );
        }
      });
  }



  const columns = [
    {
      field: 'taxYear',
      headerName: 'Tax Year',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        if (value === 'NaN') {
          return '-';
        }
        return value !== '' || value !== undefined ? value : '-';
      },
    },
    {
      field: 'fairMarketValue',
      headerName: '12/31 FMV',
      sortable: false,
      width: 152,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'dateOfDeathAccountBalance',
      headerName: 'Date of Death FMV',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'rmd',
      headerName: 'RMD Amount',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'term',
      headerName: 'Term',
      width: 128,
      valueFormatter: ({ value = '' }) => (value ? `${value} years` : '-'),
    },
    {
      field: 'lifeExpectancyTable',
      headerName: 'Type',
      width: 200,
    },
  ] as GridColDef[];

  useEffect(() => {
    if (user.roles && accountId) {
      getAndSetFMVData();
    }

    return () => {
      isMounted = false;
    };
  }, [user.roles, accountId]);

  return (<Box width="1">
      <Box>
        <DataGrid
          sx={{
            filter: isLoading ? 'blur(5px)' : '',
            transition: 'filter 0.33s ease-in-out',
          }}
          components={{
            NoRowsOverlay: SiraNoRowsOverlay,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },

          }}
          pageSizeOptions={[10]}
          disableColumnMenu
          autoHeight
          columns={columns}
          rows={fmvs.map((row, id) => ({ ...row, id }))}
        />
      </Box>
    </Box>)
}

export default OwnerAccountFmv;
