import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgUserSearch(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fillRule="evenodd">
        <path
          d="M16.716 14l.198.005a3.716 3.716 0 012.621 6.13l.16.154h.452L23 23.15l-.852.851-2.859-2.853v-.452l-.154-.16-.167.136A3.716 3.716 0 1116.716 14v-.001zm-.216 1l-.165.005A2.496 2.496 0 0014 17.5c0 1.383 1.117 2.5 2.5 2.5l.165-.005A2.496 2.496 0 0019 17.5c0-1.383-1.117-2.5-2.5-2.5z"
          fill="currentColor"
        />
        <path
          d="M11 13c2.762 0 5-2.238 5-5 0-2.763-2.238-5-5-5S6 5.237 6 8c0 2.762 2.238 5 5 5zm-.5 2c.646 0 1.424.065 2.252.195A5.616 5.616 0 0012 18c0 1.537.626 2.939 1.655 4H1v-2.333C1 16.563 7.33 15 10.5 15z"
          fill="#currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgUserSearch;
