import React from 'react';
import { Box, Grid, Typography, Chip, Select, MenuItem } from '@mui/material';
import { SiraSelectItem } from '../SiraSelectField';



interface RepInfoSelectFieldProps {
  onChange: Function;
  reps: Array<string>;
  listOfReps: Array<any>;
}


function RepInfoSelectField(props: RepInfoSelectFieldProps) {
  const { onChange = () => {}, reps = [], listOfReps = [] } = props;

  // Allow the account type filter items to be selected or deselected
  const handleRepSelect = (
    selectedRepInfo
  ) => {
    onChange(selectedRepInfo);
  };

  // Allow the chip account types in the filter to be deleted
  const handleRepsDelete = (valueToExclude): void => {
    onChange(reps.filter((value) => value !== valueToExclude));
  };


  // change object to array
  const filterOptions: Array<SiraSelectItem> = listOfReps.map((value) => ({
    value: value.userId,
    label: value.name,
  }));



  return  (
    <Grid container spacing={2} alignItems="center" wrap="nowrap" pb={2}>
      <Grid item>
        <Typography noWrap variant="body2">
          Select Reps:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          onChange={(e) => {
            handleRepSelect(e.target.value);
          }}
          value={reps}
          multiple
          renderValue={(selected: Array<any>) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const label = listOfReps.find((rep) => rep.userId === value)?.name;

                return (
                  <Chip
                    key={value}
                    label={label}
                    onDelete={() => {
                      handleRepsDelete(value);
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation(); // Prevents clicking the chip from opening the select box
                    }}
                  />
                );
              })}
            </Box>
          )}
        >
          {filterOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}

export default RepInfoSelectField;
