export enum Frequency {
  ANNUAL,
  SEMI_ANNUAL,
  QUARTERLY,
  MONTHLY,
}

export enum FrequencyValues {
  default = '',
  annual = 'ANNUAL',
  semiAnnual = 'SEMI_ANNUAL',
  quarterly = 'QUARTERLY',
  monthly = 'MONTHLY',
  ownerResponsibile = 'OWNER_RESPONSIBILE',
}
