import React, { useEffect, useRef, useState } from 'react';
import { Link, Alert, Grid, AlertTitle, Typography, Button, Box } from '@mui/material';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';

import SiraTextField from '../SiraTextField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import StepButtonBar from '../../steps/StepButtonBar';
import { Account, AccountType } from '../../../api/AccountApi.d';
import CoverdellForm, { COVERDELL_SCHEMA } from './CoverdellForm';
import {
  AccountOwner,
  AccountOwnerSearchResult,
} from '../../../api/AccountOwnerApi.d';
import CoverdellAgeWarning from './CoverdellAgeWarning';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { ADDRESS_INIT } from '../AddressForm';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import SiraDateField from '../SiraDateField';


export const COVERDELL_ACCOUNT_INIT = {
  ...ADDRESS_INIT(),
  responsibleFirstName: '',
  responsibleLastName: '',
  responsiblePhoneNumber: '',
  responsibleEmailAddress: '',
  responsibleAddressLine1: '',
  responsibleAddressLine2: '',
  responsibleCity: '',
  responsibleState: '',
  responsibleZip: '',
  allowBeneficiaryChange: false,
  responsibleAfterMajority: false,
};

export const ACCOUNT_INIT: Account = {
  ...COVERDELL_ACCOUNT_INIT,
  accountType: AccountType.init,
  accountNumber: '',
};

const getAccountSchema = (coreIntegration) => {
  const baseSchema = yup.object({
    // Define your base schema here
    accountType: yup.string().required().label('Account Type'),
    accountNumber: yup.string().max(30).label('Account Number'),
  });

  return baseSchema;
};

export const ACCOUNT_SCHEMA = {
      // Define your base schema here
      accountType: yup.string().required().label('Account Type reeally'),
      accountNumber: yup.string().max(30).label('Account Number'),
};

export interface AccountTypeFormProps {
  ownerAccountResults?: Array<AccountOwnerSearchResult>;
  initialValues: Account;
  onSubmit?: Function;
  submitName?: string;
  resetName?: string;
  accountOwner?: AccountOwner;
  ownerProfile?: boolean;
}

interface AccountTypeFieldProps {
  ownerAccountResults?: Array<any>;
}

interface ExistingAccountAlertProps {
  accountOwner: AccountOwner;
}

// Alert about existing account by selected type and link to deposit flow for this owner
function ExistingAccountAlert(props: ExistingAccountAlertProps) {
  const { accountOwner = {} } = props;
  const { fullName = '' } = accountOwner;
  const navigate = useNavigate();
  const depositLink = `/transactions/contributions?search=${fullName}`;

  if (!fullName) {
    return null;
  }

  const goToDepositFlow = () => {
    navigate(depositLink);
  };

  return (
    <Alert severity="warning">
      <AlertTitle>
        Account owner already has an existing account of this type.
      </AlertTitle>

      <Typography variant="body2">
        Complete the{' '}
        <Link
          sx={{ cursor: 'pointer' }}
          onClick={goToDepositFlow}
          color="secondary"
        >
          Deposit Money
        </Link>{' '}
        workflow to add an additional investment to the existing account. Or
        click <b>Continue</b> below to add another account of the same type.
      </Typography>
    </Alert>
  );
}

function AccountTypeField(props: AccountTypeFieldProps) {
  const { ownerAccountResults } = props;
  const { values } = useFormikContext();
  const { accountType: selectedAccountType } = values as Account;
  const { getAppConstant, organization } = useGlobalContext();
  const { accountTypesSupported, planNumberEnabled } = organization;
  const matchingExistingResult = ownerAccountResults.find(
    ({ accountType }) => selectedAccountType === accountType,
  );

  const newAccountTypeOptions: Array<RadioGroupOption> = [
    {
      value: AccountType.traditionalIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalIra,
      ),
      afterContent: (
        <>
          <Typography variant="body2">
            This account is established to provide income to an individual
            during retirement. Contributions made to this account may be tax
            deductible for the owner. Individuals must have earned income to
            contribute, and the assets grow tax deferred. Withdrawals are
            generally taxable as ordinary income.
          </Typography>
          <ExistingAccountAlert accountOwner={matchingExistingResult} />
        </>
      ),
    },
    {
      value: AccountType.rothIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.rothIra,
      ),
      afterContent: (
        <>
          <Typography variant="body2">
            This account is established to provide income to an individual
            during retirement. It only accepts non-deductible contributions, the
            owner must have earned income to contribute, and distributions may
            be tax and penalty free (upon meeting certain criteria).
          </Typography>
          <ExistingAccountAlert accountOwner={matchingExistingResult} />
        </>
      ),
    },
    {
      value: AccountType.traditionalSep,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalSep,
      ),
      afterContent: (
        <>
          <Typography variant="body2">
            This IRA accepts contributions from an employer that offers a
            Simplified Employee Pension (SEP) plan to its employees.
            Contributions are determined based on the elections of the employer,
            are not tax deductible to the employee when contributed to the IRA,
            and are generally taxable as ordinary income when distributed.
          </Typography>
          <ExistingAccountAlert accountOwner={matchingExistingResult} />
        </>
      ),
    },
    {
      value: AccountType.simpleIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.simpleIra,
      ),
      afterContent: (
        <>
          <Typography variant="body2">
            This IRA is specifically established to accept employee deferrals
            and employer contributions made under a Simplified Employee Match
            Plan for Employees (SIMPLE) plan that is established by the
            individual’s employer. Employee deferrals made under this plan
            reduce the owner’s gross taxable compensation for the year. Employer
            contributions are not deductible by the employee. Distributions from
            SIMPLE IRAs are generally taxable as ordinary income.
          </Typography>
          <ExistingAccountAlert accountOwner={matchingExistingResult} />
        </>
      ),
    },
    {
      value: AccountType.esa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.esa),
      afterContent: (
        <>
          <Typography variant="body2">
            Coverdell education savings accounts (ESA) are established for
            designated beneficiaries who are generally under the age of 18 and
            used to assist families in funding educational expenses.
            Contributions made to an ESA are not tax deductible, the earnings
            grow tax deferred, and distributions from this account may be tax
            free if the assets are used for qualified educational expenses.
          </Typography>
          <ExistingAccountAlert accountOwner={matchingExistingResult} />
        </>
      ),
    },
    {
      value: AccountType.hsa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.hsa),
      afterContent: (
        <>
          <Typography variant="body2">
            This account is established exclusively for paying qualified medical
            expenses of the owner, his/her spouse, and dependents. Contributions
            may be made by an eligible individual or by an employer, and the
            amounts contributed are tax deductible to either the individual or
            employer (depending on who made the contribution). Amounts in an HSA
            grow tax deferred and if distributions are tax free if used to pay
            for qualified medical expenses distributions. HSA contributions can
            only be made on behalf of an eligible individual, which is a person
            who is covered by a high deductible health plan (HDHP), not covered
            by any health plan that is not an HDHP, is not enrolled in Medicare,
            and is not eligible to be claimed as a dependent on another person’s
            tax return.
          </Typography>
          <ExistingAccountAlert accountOwner={matchingExistingResult} />
        </>
      ),
    },
  ].filter(({ value }) => accountTypesSupported.includes(value));

  return (
    <Grid item xs={12}>
      <Field
        name="accountType"
        options={newAccountTypeOptions}
        component={SiraRadioField}
      />
    </Grid>
  );
}

function AccountTypeForm(props: AccountTypeFormProps) {
  const { organization } = useGlobalContext();
  const { planNumberEnabled } = organization;
  let isMounted = React.useRef(false);
  const formikRef = useRef(null);

  const {
    ownerAccountResults = [],
    initialValues,
    onSubmit,
    submitName,
    resetName,
    accountOwner,
    ownerProfile = false,
  } = props;

  const [schema, setSchema] = useState(
    yup.object(ACCOUNT_SCHEMA) as yup.AnyObjectSchema,
  );

  const enableCoverdellSchema = (addCoverdell: boolean) => {
    if (addCoverdell) {
      setSchema(COVERDELL_SCHEMA.shape(ACCOUNT_SCHEMA));
    } else {
      setSchema(getAccountSchema(organization.coreIntegration));
    }
  };


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={schema}
      enableReinitialize
    >
      {({ isSubmitting, values, setValues,validateForm }) => {


        useEffect(() => {
          if (isMounted.current) {
            return;
          }
          if (organization.coreIntegration === 'SYMITAR') {
            // check to see if account owner has a symitarDTO
            if (ownerAccountResults.length > 0) {
              const symitarAccountId =
                ownerAccountResults[0].accountOwner.symitarAccountId;
              if (symitarAccountId) {
                isMounted.current = true;
                setValues({
                  ...values,
                  accountNumber: symitarAccountId,
                });
              }
            }
          }

          // prevent memory leak
          return () => {
            isMounted.current = false;
          };
        }, [ownerAccountResults]);

        return (
          <Form>
            <Grid container spacing={3}>
              <AccountTypeField ownerAccountResults={ownerAccountResults} />
              <Grid item xs={4}>
                <SiraTextField
                  data-testid="accountNumber"
                  name="accountNumber"
                  label={
                    organization.accountNumberValue ===
                    AccountMemberValue.accountNumber
                      ? 'Account Number'
                      : 'Member Number'
                  }
                />
              </Grid>
              {planNumberEnabled ? (
                <Grid item xs={4}>
                  <SiraTextField
                    data-testid="planNumber"
                    name="planNumber"
                    label="Plan Number"
                  />
                </Grid>
              ) : null}
              <Grid item xs={4}>
                <SiraDateField
                  data-testid="openDate"
                  name="openDate"
                  label="Open Date"
                />
              </Grid>
              <Grid item xs={6} />
              <CoverdellForm
                enableCoverdellSchema={enableCoverdellSchema}
                account={values}
                accountOwner={accountOwner}
              />
              <CoverdellAgeWarning accountOwner={accountOwner} />
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default AccountTypeForm;
