import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgRecurringDistributionNew(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M8 2v2h8V2h2v2h1c1.1 0 2 .9 2 2v10h-1.5c-.17 0-.337.012-.5.035V9H5v11h11v1.5c0 .17.012.337.036.5H5a2 2 0 01-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2zm1 9v2H7v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2z" />
        <path d="M19.5 15a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm2.5 4h-5v1h5v-1z" />
      </g>
    </SvgIcon>
  );
}

export default SvgRecurringDistributionNew;
