import React from 'react';
import { Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import SiraCheckbox from '../SiraCheckbox';
import StepButtonBar from '../../steps/StepButtonBar';

export interface Review {
  userVerified?: boolean;
}

export const USER_VERIFY_INIT: Review = {
  userVerified: false,
};

export const USER_VERIFY_SCHEMA = {
  userVerified: yup
    .boolean()
    .oneOf([true], 'You must verify the information is accurate'),
};

interface UserVerifyFormProps {
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
  complete?: boolean;
}

export const ReviewDocumentSchema = {};

function ReviewDocumentForm({
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
  complete = false,
}: UserVerifyFormProps) {
  return (
    <Formik
      initialValues={USER_VERIFY_INIT}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => onReset()}
      validationSchema={yup.object(USER_VERIFY_SCHEMA)}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Box>
              <Field
                disabled={complete}
                name="userVerified"
                label="I agree that the user information is accurate and has been authorized by the organization."
                component={SiraCheckbox}
              />
            </Box>
            {!complete && (
              <StepButtonBar
                isSubmitting={isSubmitting}
                submitName={submitName}
                resetName={resetName}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ReviewDocumentForm;
