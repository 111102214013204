import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { getYear, subYears } from 'date-fns';

import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Grid,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { globalPaginationOptions } from '../../../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { useUser } from '../../../auth/useUser';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { AccountDetailAccordionType } from '../../accountOwnerProfile/AccountOwnerAccountDetails';
import DataGridExportToolbar from '../../DataGridExportToolbar';
import { AccountType, CategoricalAccountType } from '../../../api/AccountApi.d';
import AccountTypesSelectField from '../AccountTypesSelectField';
import { neededFairMarketValues } from '../../../api/FairMarketValueApi';
import { FmvSearchResponseObject } from '../../../api/FairMarketValueApi.d';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../../../utils/DataGrid.utils';

import Layout from '../../Layout';
import FormPaper from '../../FormPaper';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';

export interface ReportFMVRow extends FmvSearchResponseObject {
  categoricalAccountType: CategoricalAccountType | AccountType; // Special account type to group common types (e.g. IRAs)
  selectedYearDistributions: any; // We'll add the recurring distribution when it's returned
}

function ReportsFmv() {
  let isMounted = true;
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage, organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const { setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountTypeFilter, setAccountTypeFilter] = useState<
    Array<AccountType>
  >([]);
  const [filteredRows = [], setFilteredRows] = useState<Array<ReportFMVRow>>(
    []
  );
  const [rows, setRows] = useState([] as Array<ReportFMVRow>);

  // Apply all filters to the supplied rows
  const applyFilters = (rowsToFilter) => {
    // Filter by account type first or pass through if none selected
    const matchingAccountTypeRows =
      accountTypeFilter.length > 0
        ? rowsToFilter.filter(({ accountType }) =>
            accountTypeFilter.includes(accountType)
          )
        : rowsToFilter;

    setFilteredRows(matchingAccountTypeRows);
  };

  // Fetch all FMV for the given tax year and package them into result rows with RMD info
  const getAndSetOrgFmvRows = async (values): Promise<void> => {
    setIsLoading(true);

    // Convert the date passed from the DatePicker to an integer string
    const taxYear = String(getYear(values.taxYear));

    await neededFairMarketValues(user.organizationId, { taxYear }, user.token)
      .then(({ data = [] }) => {
        if (isMounted) {
          const resultRows = data.map((row) => {
            return {
              ...row,
            };
          });
          setRows(resultRows);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            'Error fetching organization withholding notifications'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  };

  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account Number'
      : 'Member Number';

  const gotoRecordAccount = (row: ReportFMVRow): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.contributions}`
    );
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
    },
    {
      field: 'middleInitial',
      headerName: 'Middle Initial',
    },
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      disableExport: true,
      field: 'fullName',
      headerName: 'Owner',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const { fullName = '', humanAcctOwner, nonHumanName = '' } = row;
        return (
          <Button
            onClick={() => {
              gotoRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {humanAcctOwner ? fullName : nonHumanName}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxId',
      headerName: 'Taxpayer Id Number',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
    },
    {
      field: 'accountId',
      headerName: 'Account Id',
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 130,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'accountStatus',
      headerName: 'Account Status',
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 126,
    },
    {
      field: 'taxYearFilter',
      headerName: `Tax Year`,
    },
    {
      field: 'fairMarketValue',
      headerName: `Fair Market Value`,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: true,
      field: 'dateOfDeathBalance',
      headerName: 'Date of Death Balance',
      width: 200,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: true,
      field: 'dateOfDeath',
      headerName: 'Date of Death ',
      width: 120,
      valueFormatter: dateValueFormatter,
    },
  ] as GridColDef[];

  useEffect(() => {
    applyFilters(rows);

    return () => {
      isMounted = false;
    };
  }, [rows, accountTypeFilter]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Tax Reporting
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Fair Market Value Needed
          </Typography>

          <Formik
            // Datepicker is only going to accept date in taxYear but we translate it to an integer on submit
            initialValues={{ taxYear: subYears(new Date(), 1), hasFmv: true }}
            onSubmit={getAndSetOrgFmvRows}
            validationSchema={yup.object({
              taxYear: yup
                .date()
                .min(new Date('1900-01-01'), 'Choose a later year')
                .max(new Date(), 'Cannot be later than current year')
                .nullable()
                .required()
                .label('Tax Year'),
              hasFmv: yup.boolean(),
            })}
          >
            {({ isSubmitting, values, setFieldValue, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
                    <Grid item xs={12} sm={7}>
                      {/* Year-only date viewer */}
                      <DatePicker
                        views={['year']}
                        label="Tax Year"
                        value={values.taxYear}
                        openTo='year'
                        onChange={(newValue) => {
                          setFieldValue('taxYear', newValue);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(errors.taxYear),
                            helperText: <>{errors.taxYear}</>,
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="secondary"
                        startIcon={<SearchOutlinedIcon />}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <AccountTypesSelectField
                        onChange={setAccountTypeFilter}
                        accountTypes={accountTypeFilter}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
                Toolbar: DataGridExportToolbar,
              }}
              disableColumnMenu
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                  columnVisibilityModel: {
                    firstName: false,
                    lastName: false,
                    middleInitial: false,
                    name: false,
                    accountStatus: false,
                    taxYearFilter: false,
                    fairMarketValue: false,
                    accountId: false,
                    dateOfBirth: false,
                  },
                },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={filteredRows.map((row, id) => ({
                ...row,
                id,
              }))}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default ReportsFmv;
