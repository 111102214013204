import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Fade,
  IconButton,
  LinearProgress,
  Modal,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

import { accountTransactionTypeNames } from '../app.constants';
import { deleteApiCall } from './dashboard/dashboard.utils';
import { useGlobalContext } from '../auth/useGlobalContext';
import { useUser } from '../auth/useUser';
import { getAccountContribution } from '../api/ContributionApi';
import { ContributionStatus } from '../api/ContributionApi.d';
import { AccountStatus, TransactionType } from '../api/AccountApi.d';
import { getAccount } from '../api/AccountApi';
import { getAccountDistribution } from '../api/DistributionApi';
import { getRecurringDistribution } from '../api/RecurringDistributionApi';
import { getTransferRequest } from '../api/TransferApi';
import { getBeneficiaries } from '../api/BeneficiariesApi';
import { DistributionStatus } from '../api/DistributionApi.d';
import { RecurringDistributionStatus } from '../api/RecurringDistributionApi.d';
import { TransferRequestStatus } from '../api/TransferApi.d';
import { BeneficiaryStatus } from '../api/BeneficiariesApi.d';
import { BeneficiaryStatus as BeneficiaryClaimStatus } from '../api/BeneficiaryClaimsApi.d';
import { getBeneficiariesClaimsProfile } from '../api/BeneficiariesClaimsApi';
import { errorMessages } from '../utils/errorhandling.utils';

export function TransactionRedirectModal(props) {
  const { onOK, onCancel, title, transactionValues } = props;

  const { addGlobalMessage } = useGlobalContext();
  const [isDeleting, setIsDeleting] = useState(false as boolean);
  const [isFetchingStatus, setIsFetchingStatus] = useState(false as boolean);
  const { user } = useUser();
  const {
    accountId,
    accountOwnerId,
    contributionId,
    distributionId,
    recurringDistributionId,
    transferRequestId,
  } = transactionValues;

  // Array configurations that map the transaction type to its respective api call
  // and which statuses to ignore when determining if to show the unsaved changes modal
  const transactionRequestConfig = {
    [TransactionType.account]: [
      getAccount, // 1. Which api endpoint to get the transaction
      [accountId, accountOwnerId, user.organizationId, user.token], // 2. Args to send (because each takes them in a different order)
      'accountStatus', // 3. What is the status field key we're looking at in the response
      [AccountStatus.open, AccountStatus.review], // 4. Which statuses can we ignore
    ],
    [TransactionType.contribution]: [
      getAccountContribution,
      [
        accountId,
        accountOwnerId,
        user.organizationId,
        contributionId,
        user.token,
        user
      ],
      'contributionStatus',
      [ContributionStatus.active, ContributionStatus.review],
    ],
    [TransactionType.distribution]: [
      getAccountDistribution,
      [
        accountId,
        accountOwnerId,
        user.organizationId,
        distributionId,
        user.token,
        user
      ],
      'distributionStatus',
      [DistributionStatus.active, DistributionStatus.review],
    ],
    [TransactionType.recurringDistribution]: [
      getRecurringDistribution,
      [
        accountId,
        accountOwnerId,
        recurringDistributionId,
        user.organizationId,
        user.token,
        user
      ],
      'recurringDistributionStatus',
      [RecurringDistributionStatus.active, RecurringDistributionStatus.review],
    ],
    [TransactionType.transfer]: [
      getTransferRequest,
      [
        user.organizationId,
        accountOwnerId,
        accountId,
        transferRequestId,
        user.token,
        user
      ],
      'transferRequestStatus',
      [TransferRequestStatus.awaiting, TransferRequestStatus.completed],
    ],
    [TransactionType.beneficiaries]: [
      getBeneficiaries,
      [accountId, accountOwnerId, user.organizationId, user.token, user],
      'beneficiaryStatus',
      [BeneficiaryStatus.live, BeneficiaryStatus.review],
    ],
    [TransactionType.beneficiaryClaims]: [
      getBeneficiariesClaimsProfile,
      [accountId, accountOwnerId, user.organizationId, user.token, user],
      'beneficiaryClaimStatus',
      [
        BeneficiaryClaimStatus.processing,
        BeneficiaryClaimStatus.completed,
        BeneficiaryClaimStatus.awaitingElection,
      ],
    ],
  }[transactionValues.transactionType];

  const containerStyle = {
    position: 'absolute' as any,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    maxWidth: 480,
    height: 'auto',
    maxHeight: '66%',
    bgcolor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 2,
    box: 42,
    p: 4,
    overflow: 'auto',
  };
  const closeButtonStyle = {
    position: 'fixed' as any,
    top: 0,
    right: 0,
  };
  const contentStyle = {
    height: '100%',
  };
  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 2,
  };

  async function discardItemClick(): Promise<void> {
    setIsDeleting(true);

    await deleteApiCall(transactionValues, user.organizationId, user.token, user)
      .then(() => {
        onOK();
        addGlobalMessage(
          `${
            accountTransactionTypeNames[transactionValues.transactionType]
          } successfully deleted`,
          { severity: 'success' }
        );
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) ||
            `Error deleting ${accountTransactionTypeNames[
              transactionValues.transactionType
            ].toLowerCase()}s`
        );
      });

    setIsDeleting(false);
  }

  // Fetch the lates transaction data in case it's been changed outside the UI
  // Continue navigating and hide the redirect modal if we can ignore it based on status
  const getLatestTransactionStatus = async () => {
    setIsFetchingStatus(true);

    if (transactionRequestConfig) {
      const [
        transactionApiCall,
        apiCallArgs,
        statusFieldName,
        statusesToIgnore,
      ] = transactionRequestConfig;

      await transactionApiCall(...apiCallArgs)
        .then(({ data }) => {
          if (
            statusesToIgnore.includes(
              data.length ? data[0][statusFieldName] : data[statusFieldName]
            )
          ) {
            onOK();
          }
        })
        .catch(() => {
          setIsFetchingStatus(false);
        });
    } else {
      onOK();
    }

    setIsFetchingStatus(false);
  };

  const handleDelete = async () => {
    await discardItemClick();
  };

  useEffect(() => {
    if (user.token && user.organizationId) {
      getLatestTransactionStatus();
    }
  }, [user.token, user.organizationId]);

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in>
        <Box sx={containerStyle}>
          <Box sx={closeButtonStyle}>
            <IconButton
              color="primary"
              onClick={onCancel}
              aria-label="close modal"
              size="large"
            >
              <CancelIcon />
            </IconButton>
          </Box>
          <Box sx={contentStyle}>
            {title && (
              <>
                <Typography variant="h6" component="h2">
                  {title}
                </Typography>
              </>
            )}
          </Box>

          {isFetchingStatus ? (
            <Box sx={contentStyle} pt={2} textAlign="center">
              <Typography variant="body2" gutterBottom>
                Checking{' '}
                {accountTransactionTypeNames[transactionValues.transactionType]}{' '}
                Status...
              </Typography>
              <LinearProgress />
            </Box>
          ) : (
            <>
              <Box sx={contentStyle} pt={2}>
                Would you like to delete this{' '}
                {accountTransactionTypeNames[transactionValues.transactionType]}
                ?
              </Box>
              <Box sx={buttonContentStyle}>
                <Box pr={2}>
                  <Button
                    disabled={isDeleting}
                    variant="outlined"
                    onClick={onCancel}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box pr={2}>
                  <Button
                    disabled={isDeleting}
                    data-qa="redirect-modal_keep"
                    variant="outlined"
                    onClick={onOK}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Keep
                  </Button>
                </Box>
                <Box>
                  <Button
                    disabled={isDeleting}
                    data-qa="redirect-modal_discard"
                    variant="outlined"
                    onClick={handleDelete}
                    sx={{ whiteSpace: 'nowrap' }}
                    startIcon={<DeleteIcon />}
                  >
                    Discard
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
