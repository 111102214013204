import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import AccountSearchForm from '../accountSearch/AccountSearchForm';
import AccountSearchResults from '../accountSearch/AccountSearchResults';
import ExternalAccountForm, {
  EXTERNAL_ACCOUNT_INIT,
} from './ExternalAccountForm';
import { getAccountOwner } from '../../../api/AccountOwnerApi';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { useUser } from '../../../auth/useUser';
import { AccountType, Account, AccountStatus } from '../../../api/AccountApi.d';
import { createOrSaveExternalAccount } from '../../../api/ExternalAccountApi';
import { ExternalAccount } from '../../../api/ExternalAccountApi.d';

export enum AccountLocationEnum {
  internal = 'INTERNAL',
  external = 'EXTERNAL',
  unknown = 'UNKNOWN',
}
export interface DeceasedOwnerFormProps {
  initialValues: any; // combine the toggle/internal acc/external info
  onSetDeceasedOwner?: Function;
  onSetDeceasedAccount?: Function;
  onReset?: Function;
  submitName?: string;
  setSearchResponse?: Function;
  resetName?: string;
}

const allowedStatuses = [AccountStatus.open, AccountStatus.closed];

function DeceasedOwnerForm({
  initialValues,
  onSetDeceasedOwner,
  onSetDeceasedAccount,
  onReset,
  submitName,
  setSearchResponse,
  resetName,
}: DeceasedOwnerFormProps) {
  let isMounted = true;
  const { user } = useUser();
  const { organization, addGlobalMessage } = useGlobalContext();
  const { accountTypesSupported = [] } = organization;
  const [deceasedOwnerOption = '', setDeceasedOwnerOption] = useState(
    initialValues.deceasedOwnerOption
  );
  const allowedAccounts = [];

  if (accountTypesSupported.includes(AccountType.inheritedTraditional)) {
    allowedAccounts.push(
      AccountType.inheritedTraditional,
      AccountType.traditionalIra,
      AccountType.traditionalSep,
      AccountType.simpleIra
    );
  }
  if (accountTypesSupported.includes(AccountType.inheritedRoth)) {
    allowedAccounts.push(AccountType.inheritedRoth, AccountType.rothIra);
  }

  const filterAccounts = (result) => {
    const { account = {} } = result;

    return (
      allowedAccounts.includes(account.accountType) &&
      allowedStatuses.includes(account.accountStatus)
    );
  };

  const changeOwnerInput = (event) => {
    setDeceasedOwnerOption(event.target.value);
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  // Create an external account owner account if using an external org's owner
  const createExternalAccountOwner = async (
    formData: ExternalAccount
  ): Promise<void> => {
    onSetDeceasedAccount({
      accountType: formData.accountType,
      deceasedOwnerOption, // Sneak in the deceased owner option for use in later steps
    } as Account);

    await createOrSaveExternalAccount(
      formData as ExternalAccount,
      user.organizationId,
      user.token
    )
      .then(({ data }) => {
        onSetDeceasedOwner(data);
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage('Unable to create external deceased account owner');
        }
      });
  };

  // Get the accountOwner for the account loaded -> Internal path
  async function loadAccountOwner(
    searchResult: Account & AccountOwner
  ): Promise<void> {
    // Sneak in the deceased owner option for use in later steps
    onSetDeceasedAccount({
      ...searchResult,
      deceasedOwnerOption,
    });
    await getAccountOwner(
      searchResult.accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        onSetDeceasedOwner(data);
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage('Unable to load account owner');
        }
      });
  }

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Is the deceased owner&apos;s original account held at this financial
          organization?
        </FormLabel>
        <Box ml={4} mt={4}>
          <RadioGroup
            aria-label="Internal or External Account"
            name="controlled-radio-buttons-group"
            value={deceasedOwnerOption}
            onChange={changeOwnerInput}
          >
            <FormControlLabel
              value={AccountLocationEnum.internal}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              value={AccountLocationEnum.external}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </Box>
      </FormControl>
      {deceasedOwnerOption === AccountLocationEnum.internal && (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12}>
              <Box mt={2} mb={4}>
                <AccountSearchForm setResponse={setSearchResponse} />
              </Box>
            </Grid>
          </Grid>
          {initialValues.searchResponse && (
            <AccountSearchResults
              response={initialValues.searchResponse}
              filterFunction={filterAccounts}
              onResultClick={(searchResult) => {
                loadAccountOwner(searchResult);
              }}
            />
          )}
        </Box>
      )}
      {deceasedOwnerOption === AccountLocationEnum.external && (
        <Box mt={4} mb={4}>
          <ExternalAccountForm
            initialValues={{
              ...EXTERNAL_ACCOUNT_INIT,
              ...initialValues.externalAccount,
            }}
            onSubmit={createExternalAccountOwner}
            submitName={submitName}
            resetName={resetName}
            onReset={onReset}
          />
        </Box>
      )}
    </>
  );
}

export default DeceasedOwnerForm;
