import { createTheme } from '@mui/material/styles';
import { Organization } from '../api/OrganizationApi.d';

export function convertStringToRGBArray(colorString: string) {
  const rgbArray = colorString.split(',').map(Number);
  const rgbValue = `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
  return rgbValue;
}

function createOwnerTheme(orgBranding: Organization) {
  const palette = {
    primary: {
      main: convertStringToRGBArray(orgBranding.primaryColorRGB), // Midnight Blue
      light: '#55626A',
      dark: '#030A0F',
    },
    secondary: {
      main: convertStringToRGBArray(orgBranding.secondaryColorRGB), // This is the lighter green
      light: convertStringToRGBArray(orgBranding.secondaryColorRGB),
      dark: '#51B383',
    },
    text: {
      primary: convertStringToRGBArray(orgBranding.primaryTextRGB), // black for the most part
      secondary: convertStringToRGBArray(orgBranding.secondaryTextRGB), // Text secondary is 'Grassy Green', should be replaced by secondary dark?
    },
  };

  const typography = {
    fontFamily: 'Lato',
    h1: {
      fontSize: '54px',
      fontWeight: 300,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
  };

  return createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: `${palette.primary.main} !important`,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontVariantLigatures: 'none',
            color: 'white',
          },
          h4: {
            color: `${palette.text.primary} !important`,
          },
          h6: {
            color: `${palette.text.primary} !important`,
          },
          h5: {
            color: `${palette.text.primary} !important`,
          },
          overline: {
            color: `${palette.text.primary} !important`,
          },
          h3: {
            color: `${palette.text.primary} !important`,
          },
          h1: {
            color: `${palette.text.primary} !important`,
          },
          h2: {
            color: `${palette.text.primary} !important`,
          },
          body1: {
            color: `${palette.text.primary} !important`,
          },
          body2: {
            color: `${palette.text.primary} !important`,
          },
          subtitle1: {
            color: `${palette.text.primary} !important`,
          },
          subtitle2: {
            color: `${palette.text.primary} !important`,
          },
          inherit: {
            color: `${palette.text.primary} !important`,
          },
          caption: {
            color: `white`,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: palette.text.primary,
          },
          labelContainer: {
            color: palette.text.primary,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: '8px',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: palette.text.primary,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: `${palette.primary.main} !important`,
            '&.Mui-completed': {
              color: '#5B5B5B !important',
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '42px',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&:not(.Mui-checked)': {
              color: palette.secondary.main,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: palette.text.secondary,
            verticalAlign: 'baseline !important',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            backgroundColor: 'white', // Set your desired background color here
          },
          tooltip: {
            backgroundColor: 'white',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: palette.secondary.main,
            },
            '&:not(.Mui-checked)': {
              color: palette.secondary.main,
            },
          },
        },
      },
    },
    palette,
    typography,
  });
}

export default createOwnerTheme;
