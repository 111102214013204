import React, { FC, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  useTheme,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format, parseISO } from 'date-fns';

import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { AccountMemberValue } from '../../api/OrganizationApi.d';
import { RecurringDistribution } from '../../api/RecurringDistributionApi.d';
import moneyFormater from '../../moneyFormatter';
import { FREQUENCY_TEXT } from './distribution/DistributionSchedule';
import { Frequency } from './distribution/Frequency';
import TrimmedNumber from '../TrimmedNumber';
import { distributionMethodShortNames } from '../../app.constants';
import { AccountStatus } from '../../api/AccountApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../ObscuredTaxId';

export interface AccountOwnerInfoProps {
  accountOwner: AccountOwner;
  selectedAccount: any;
  deceasedInfo?: boolean;
  inheritingAccountOwner?: {
    dateOfBirth: string;
    beneficiaryName: string;
    relationship: string;
  };
  editIsActive?: boolean;
  recurringDistribution?: RecurringDistribution;
}

export interface ActiveDistributionSummaryCardProps {
  distribution: RecurringDistribution;
  editIsActive?: boolean;
}

 interface CurrentSelectedDistribution {
  distributionMethod: string;
  frequency: string;
  totalAmount: number;
  distributionAmountType: string;
  stateWithholdingPercent: any;
  federalWithholdingPercent: any;  
}

const ActiveDistributionSummaryCard: FC<ActiveDistributionSummaryCardProps> = (
  props
) => {
  const { distribution = {} as RecurringDistribution, editIsActive = false } = props;
  const theme = useTheme();

  const [currentSelectedDistribution, setCurrentSelectedDistribution] =
    useState({
      distributionMethod:"",
      frequency:  '',
      totalAmount:  0,
      distributionAmountType:'',
      stateWithholdingPercent:0,
      federalWithholdingPercent: 0,
    } as CurrentSelectedDistribution);

  useEffect(() => {
    if (distribution) {
      setCurrentSelectedDistribution({
        distributionMethod: distribution.distributionMethod,
        frequency: distribution.frequency,
        totalAmount: distribution.totalAmount,
        distributionAmountType: distribution.distributionAmountType,
        stateWithholdingPercent: distribution.stateWithholdingPercent,
        federalWithholdingPercent: distribution.federalWithholdingPercent,
      });
    }
  }, [editIsActive, distribution]);

  return (
    <Card elevation={2}>
      <CardHeader
        sx={{
          background: `${theme.palette.primary.main}`,
        }}
        titleTypographyProps={{
          variant: 'h6',
          color: 'white',
          align: 'center',
        }}
        title="Current Schedule"
      />

      <CardContent>
        {/* each item of this card content will be set to each other. there will be two columns with three rows in them each */}
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="left">
              Type
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              {currentSelectedDistribution.distributionAmountType}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="left">
              Total Amount
            </Typography>
            <Typography variant="body2" gutterBottom align="left">
              {moneyFormater.format(currentSelectedDistribution.totalAmount || 0)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="left">
              Frequency
            </Typography>
            <Typography variant="body2" gutterBottom align="left">
              {FREQUENCY_TEXT[currentSelectedDistribution.frequency as unknown as Frequency]}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="left">
              Federal Withholding
            </Typography>
            <Typography variant="body2" gutterBottom align="left">
              <TrimmedNumber
                value={currentSelectedDistribution.federalWithholdingPercent}
                decimalPlaces={2}
              />
              %
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="left">
              Method
            </Typography>
            <Typography variant="body2" gutterBottom align="left">
              {distributionMethodShortNames[String(currentSelectedDistribution.distributionMethod)]}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="left">
              State Withholding
            </Typography>
            <Typography variant="body2" gutterBottom align="left">
              <TrimmedNumber
                value={currentSelectedDistribution.stateWithholdingPercent}
                decimalPlaces={2}
              />
              %
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const SiraAccountOwnerInfo: FC<AccountOwnerInfoProps> = (props) => {
  const {
    accountOwner = {},
    selectedAccount = {},
    deceasedInfo = false,
    inheritingAccountOwner,
    editIsActive = false,
    recurringDistribution = {},
  } = props;
  const {
    name = '',
    dateOfBirth = '',
    dateOfDeath = '',
    emailAddress = '',
    phoneNumber = '',
    addressLine1 = '',
    addressLine2 = '',
    addressLine3 = '',
    city = '',
    state = '',
    foreignStateProvinceRegion = '',
    zip = '',
    country = '',
    taxpayerIdNumber = '',
  } = accountOwner as AccountOwner;
  const {
    accountNumber = '',
    accountType = '',
    accountStatus = '',
    closedDate = '',
  } = selectedAccount;
  const { getAppConstant, countries, organization } = useGlobalContext();
  const theme = useTheme();

  const topLineCss = {
    borderTop: '1px solid lightgrey',
    marginTop: theme.spacing(3),
  };
  const rightLineCss = {
    borderRight: '1px solid lightgrey',
  };

  const setAccountOwnerOwnerName = () => {
    let fullName = '';

    if (accountOwner.fullName) {
      fullName = accountOwner.fullName;
    } else if (accountOwner.firstName && accountOwner.lastName) {
      fullName = `${accountOwner.firstName} ${accountOwner.lastName}`;
    }

    return fullName;
  };



  // Fallback/support any accountOwner model (e.g. external) which doesn't have a fullName field
  const displayAccountOwnerName = setAccountOwnerOwnerName();

  return (
    <>
      <Box p={4}>
        <Grid container>
          <Grid item sm={6}>
            <Typography variant="overline">
              {deceasedInfo ? 'Deceased Name' : 'Name'}
            </Typography>

            <Typography color="secondary" variant="h3" gutterBottom>
              {displayAccountOwnerName}
            </Typography>
            <Typography variant="overline">Account</Typography>

            <Typography variant="h4" >
              {getAppConstant(ConstantsMappingKey.accountType, accountType)}
            </Typography>
          </Grid>
          {inheritingAccountOwner && inheritingAccountOwner.beneficiaryName && (
            <Grid item sm={4}>
              <Box ml={1}>
                <Typography variant="overline">Beneficiary</Typography>
                <Typography variant="subtitle2">Name</Typography>
                <Typography variant="body1">
                  {inheritingAccountOwner.beneficiaryName}
                </Typography>
                <Grid container sx={topLineCss}>
                  <Grid item sm={7} sx={rightLineCss}>
                    <Box mt={1}>
                      <Typography variant="caption">Date of Birth</Typography>
                      <Typography variant="body2">
                        {inheritingAccountOwner.dateOfBirth}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={1} />
                  <Grid item sm={4}>
                    <Box mt={1}>
                      <Typography variant="caption">Relationship</Typography>
                      <Typography variant="body2">
                        {inheritingAccountOwner.relationship}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {editIsActive && (
            <Grid item sm={6}>
              <Box ml={1}>
                <ActiveDistributionSummaryCard
                  distribution={recurringDistribution}
                  editIsActive={editIsActive}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Accordion sx={{ p: 2 }} elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2">More Information</Typography>
        </AccordionSummary>

        <AccordionDetails>
          {' '}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Date of Birth</Typography>
              <Typography variant="body1">
                {dateOfBirth &&
                  format(parseISO(String(dateOfBirth)), 'MM/dd/yyyy')}
              </Typography>
            </Grid>
            {dateOfDeath ? (
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Date of Death</Typography>
                <Typography variant="body1">
                  {format(parseISO(String(dateOfDeath)), 'MM/dd/yyyy')}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={6} />
            )}
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Email</Typography>
              <Typography variant="body1">{emailAddress || '---'}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Phone</Typography>
              <Typography variant="body1">{phoneNumber || '---'}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                {organization.accountNumberValue ===
                AccountMemberValue.accountNumber
                  ? 'Account Number'
                  : 'Member Number'}
              </Typography>
              <Typography variant="body1">{accountNumber}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Account Status</Typography>
              <Typography variant="body1">{accountStatus === AccountStatus.closed ? `Closed on ${format(parseISO(String(closedDate)), 'MM/dd/yyyy')}` : "Open"}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Address</Typography>
              <Typography variant="body1">{addressLine1}</Typography>
              <Typography variant="body1">{addressLine2}</Typography>
              <Typography variant="body1">{addressLine3}</Typography>
              {city && (
                <Typography variant="body1">
                  {city}
                  {city && ','}{' '}
                  {foreignStateProvinceRegion || (state !== 'XX' && state)}
                  {zip}
                </Typography>
              )}{' '}
              <Typography variant="body1">{countries[country]}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    SSN/ID
                  </Typography>

                  <Typography variant="body1">
                    <ObscuredTaxId
                      taxId={taxpayerIdNumber}
                      format={name ? TaxIdFormat.ein : TaxIdFormat.ssn}
                    />
                  </Typography>
                </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SiraAccountOwnerInfo;
