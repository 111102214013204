/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';
import TaxIdField from '../TaxIdField';
import {
  DocumentationAddress,
  FiduciaryType,
  Organization,
  Solution,
  SpousalConsentOption,
  AccountMemberValue,
  SingelSignOn,
} from '../../../api/OrganizationApi.d';

import SiraSelectField from '../SiraSelectField';
import {
  fiduciaryTypeOptions,
  orgSpousalConsentOptions,
  orgSolutionOptions,
  documentationAddressOptions,
  accountNumberorMemberNumberOptions,
  singleSignOnOptions,
  orgInformationStatus,
} from '../../../app.constants';
import { useUser } from '../../../auth/useUser';
import { UserRole } from '../../../api/UserApi.d';
import SiraDateField from '../SiraDateField';
import SiraSwitchField from '../SiraSwitchField';
import SiraTextEditor from '../SiraTextEditor';
import { use } from 'chai';

export const FINANCIAL_ORG_INIT: Organization = {
  federalTaxId: '',
  name: '',
  legalName: '',
  fiduciaryType: FiduciaryType.default,
  spousalConsentRequired: SpousalConsentOption.community,
  logoImageString: '',
  primaryColorRGB: '',
  solution: '' as Solution,
  agreementDate: new Date().toISOString().slice(0, 10),
  documentationAddress: DocumentationAddress.userBranch,
  eSign: false,
  isEsignPassthroughId: false,
  investmentRatePrecision: 2,
  accountNumberValue: AccountMemberValue.accountNumber,
  apyRatePrecision: 0,
  customerPortalUrl: '',
  sftpFolderName: '',
  activeDate: new Date().toISOString().slice(0, 10),
  status: 'active',
  sso: SingelSignOn.none,
  ipFiltering: false,
  accountOwnerPortalCustomError: '',
  accountOwnerPortalCustomErrorHeader: '',
  displayInvestments: true,
  displayFeeAmount: true,
  beneyClaimSpecialHandling: '',
  planNumberEnabled: false,
};

export const FINANCIAL_ORG_SCHEMA = {
  federalTaxId: yup
    .string()
    .required()
    .matches(/\d{2}-\d{7}/, 'Tax ID must be 9 digits'),
  name: yup.string().required().max(80).label('Organization Name'),
  legalName: yup.string().required().max(80).label('Organization Legal Name'),
  fiduciaryType: yup.string().required().label('Fiduciary Type'),
  spousalConsentRequired: yup.string().required().label('Spousal Consent'),
  solution: yup.string().required().label('Solution'),
  investmentRatePrecision: yup
    .number()
    .typeError('Please enter a number')
    .required()
    .min(1)
    .max(5)
    .label('Investment Rate Precision'),
  documentationAddress: yup.string().required().label('Documentation Address'),
};

export interface FinancialOrgFormProps {
  initialValues: Organization;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  isEditing?: boolean;
}

const FinancialOrgForm = ({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
  isEditing,
}: FinancialOrgFormProps) => {
  const { user } = useUser();
  const allowSolutionChange = user.roles && user.roles.includes(UserRole.admin);
  const [objectValue, setObjectValue] = useState('');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      validationSchema={yup.object(FINANCIAL_ORG_SCHEMA)}
    >
      {({ isSubmitting, values, setFieldValue }) => {
        useEffect(() => {
          if (!isEditing) {
            setFieldValue('status', orgInformationStatus[1].value);
            setFieldValue('agreementDate', '');
            setFieldValue('beneyClaimSpecialHandling', '');
          }
        }, [isEditing]);

        useEffect(() => {
          if (initialValues.accountOwnerPortalCustomError) {
            // find value ', object: ' and split the string
            const splitValues =
              initialValues.accountOwnerPortalCustomError.split(', object: ');

            if (
              !initialValues.accountOwnerPortalCustomError.includes('markup:')
            ) {
              // set the value to the editor
              setObjectValue('');
            } else {
              setObjectValue(splitValues[1]);
              // set the value to the editor
              setFieldValue('accountOwnerPortalCustomError', objectValue);
            }
          }
        }, [initialValues.accountOwnerPortalCustomError]);

        return (
          <Form>
            <Box maxWidth={500}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TaxIdField name="federalTaxId" label="Federal Tax ID" />
                    </Grid>
                    <Grid item xs={12}>
                      <SiraTextField name="name" label="Organization Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <SiraTextField name="legalName" label="Legal Name" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SiraSelectField
                        items={fiduciaryTypeOptions}
                        name="fiduciaryType"
                        label="Fiduciary Type"
                      />
                    </Grid>
                    {!isEditing && (
                      <>
                        {' '}
                        <Grid item xs={12}>
                          <SiraSelectField
                            name="spousalConsentRequired"
                            items={orgSpousalConsentOptions}
                            label="Spousal Consent Required"
                          />
                        </Grid>{' '}
                        <Grid item xs={12}>
                          <SiraSelectField
                            name="status"
                            items={orgInformationStatus}
                            label="Status"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SiraSelectField
                            disabled={!allowSolutionChange}
                            name="solution"
                            items={orgSolutionOptions}
                            label="Current Solution"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SiraDateField
                            name="agreementDate"
                            label="Agreement Date"
                          />
                        </Grid>
                      </>
                    )}
                    {/* Spousal Consent */}
                    {/* Investment Rate Precision */}
                    {isEditing ? (
                      <>
                        <Grid item xs={12}>
                          <SiraSelectField
                            name="accountNumberValue"
                            items={accountNumberorMemberNumberOptions}
                            label="Account Owner Info"
                          />
                        </Grid>
                      </>
                    ) : null}
                    <Grid item xs={12} sx={{pb:1}}>
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                        wrap="nowrap"
                      >
                        <Grid item>
                          <Box>
                            <SiraSwitchField
                              name="planNumberEnabled"
                              checked={values.planNumberEnabled}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            Display Plan Number
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {user.roles.includes(UserRole.multi) && isEditing ? (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Accordion
                    slotProps={{
                      transition: {
                        mountOnEnter: true,
                        unmountOnExit: true,
                      },
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h5" color="secondary">
                        Additional Org Information
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Box maxWidth={480}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <SiraSelectField
                              disabled={!allowSolutionChange}
                              name="solution"
                              items={orgSolutionOptions}
                              label="Current Solution"
                            />
                          </Grid>{' '}
                          {allowSolutionChange && (
                            <Grid item xs={12}>
                              <SiraTextField
                                name="customerPortalUrl"
                                label="Account Owner Portal Url"
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <SiraTextEditor
                              fieldLabel="Beneficiary Claims Special Handling"
                              showToolbar
                              defaultValue={values.beneyClaimSpecialHandling}
                              setValue={(value: '') => {
                                setFieldValue(
                                  'beneyClaimSpecialHandling',
                                  value,
                                );
                              }}
                            />
                          </Grid>
                          {values.customerPortalUrl && (
                            <>
                              <Grid item xs={12}>
                                <SiraTextEditor
                                  fieldLabel="Account Owner Portal Error Message"
                                  showToolbar
                                  defaultValue={objectValue}
                                  setValue={(value: '', plainMarkup) => {
                                    setFieldValue(
                                      'accountOwnerPortalCustomError',
                                      `markup: ${plainMarkup}, object: ${value}`,
                                    );
                                  }}
                                  accountOwnerPortalCustomError
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <SiraTextField
                                  name="accountOwnerPortalCustomErrorHeader"
                                  label="Account Owner Error Message Header"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item xs={12}>
                            <Divider orientation="horizontal" />
                          </Grid>
                          <Grid item xs={12}>
                            <SiraTextField
                              name="sftpFolderName"
                              label="SFTP Site"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SiraDateField
                              name="agreementDate"
                              label="Agreement Date"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SiraDateField
                              name="activeDate"
                              label="Active Date"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SiraSelectField
                              name="sso"
                              items={singleSignOnOptions}
                              label="Single Sign On"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                              wrap="nowrap"
                            >
                              <Grid item>
                                <Box>
                                  <SiraSwitchField
                                    name="ipFiltering"
                                    checked={values.ipFiltering}
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <Typography>IP Filtering Enabled</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <SiraSelectField
                              name="status"
                              items={orgInformationStatus}
                              label="Status"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            ) : null}
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FinancialOrgForm;
