import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import {
  getSupportedAccountTypes,
  updateOrg,
  updateSupportedAccountTypes,
} from '../../api/OrganizationApi';
import OrgOptionsForm, {
  ORG_OPTIONS_INIT,
} from '../form/organization/OrgOptionsForm';
import { errorMessages } from '../../utils/errorhandling.utils';

function OrganizationProfileOptions() {
  let isMounted = true;
  const { organization, setCurrentOrg, addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const [organizationData, setOrganization] = useState(organization);

  // Separate endpoint to update the supported account types
  const saveSupportedAccountTypes = async (data) => {
    const { accountTypesSupported } = data;

    await updateSupportedAccountTypes(
      accountTypesSupported,
      organization,
      user.token
    )
      .then((res) => {
        if (isMounted) {
          setCurrentOrg({ ...data, accountTypesSupported: res.data });
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Error saving supported account types'
          );
        }
      });
  };

  const saveOrganization = async (data) => {
    // Update shallow org details
    await updateOrg({ ...data }, organization, user.token)
      .then((res) => {
        if (isMounted) {
          setCurrentOrg(res.data);
          saveSupportedAccountTypes(data);
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Error saving organization information'
          );
        }
      });
  };

  const retrieveSupportedAccountTypes = async () => {

    await getSupportedAccountTypes(user.organizationId, user.token).then((res) => {
      if (isMounted) {
        setOrganization({ ...organization, accountTypesSupported: res.data });
      }
    }
    ).catch((err) => {
      if (isMounted) {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching supported account types'
        );
      }
    });
  }


  useEffect(() => {
    retrieveSupportedAccountTypes();
    return () => {
      isMounted = false;
    };
  }, [user.token]);

  return (
    <Box width="1">
      <OrgOptionsForm
        initialValues={{ ...ORG_OPTIONS_INIT, ...organizationData }}
        onSubmit={saveOrganization}
      />
    </Box>
  );
}

export default memo(OrganizationProfileOptions);
