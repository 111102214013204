import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgBeneficiariesEdit(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeLinecap="square"
          d="M17.5 13.5v-1.077M3.5 13.5v-1.077M10.5 13.5v-2"
        />
        <path
          d="M13.538 10.154H5.077V8.002a2.92 2.92 0 012.912-2.925h2.637a2.917 2.917 0 012.912 2.925v2.152zM9.308 0a2.539 2.539 0 110 5.077 2.539 2.539 0 010-5.077zM7.77 24H1v-1.794c0-1.345 1.044-2.437 2.33-2.437h2.11a2.27 2.27 0 011.646.714c.424.443.683 1.05.683 1.723V24zM3.96 15.538a2.116 2.116 0 110 4.232 2.116 2.116 0 010-4.232zM14.538 24H7.77v-1.794c0-1.345 1.044-2.437 2.33-2.437h2.11a2.27 2.27 0 011.647.714c.423.443.682 1.05.682 1.723V24zm-3.807-8.462a2.116 2.116 0 110 4.232 2.116 2.116 0 010-4.232z"
          fill="currentColor"
        />
        <path
          d="M21.308 24h-6.77v-1.794c0-1.345 1.044-2.437 2.33-2.437h2.11a2.27 2.27 0 011.647.714c.423.443.683 1.05.683 1.723V24zM17.5 15.538a2.116 2.116 0 110 4.232 2.116 2.116 0 010-4.232z"
          fill="currentColor"
        />
        <path stroke="currentColor" strokeLinecap="square" d="M3.962 12.423H17.5" />
        <path
          d="M16 6.48V8h1.52L22 3.52 20.48 2 16 6.48zm7.9-5.103a.34.34 0 000-.48L23.103.1a.34.34 0 00-.48 0L22 .723 23.277 2l.623-.623z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgBeneficiariesEdit;
