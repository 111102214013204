import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgOnboarding(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="currentColor" fillRule="nonzero">
        <path
          d="M23.124 21.774L18.69 17.92c.336-1.076.022-2.29-.904-3.095-.974-.848-2.354-.934-3.412-.357l2.096 1.822-1.271 1.462-2.141-1.818c-.47 1.131-.147 2.482.828 3.329.926.805 2.171.947 3.19.463l4.435 3.856c.195.169.469.15.638-.045l.974-1.12c.216-.2.193-.518.001-.642z"
          opacity={0.98}
        />
        <path d="M0 19h9.982l1.487 3H0zM10 1L0 6v2h20V6zM2 10h3v7H2zM8 10h3v7H8zM14 10h3v2l-3 .5z" />
      </g>
    </SvgIcon>
  );
}

export default SvgOnboarding;
