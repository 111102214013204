import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgImport5498(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="currentColor" fillRule="nonzero" transform="matrix(0.99998435,0,0,1,-48.106815,-43.57181)">
    <path
       d="m 58.407604,52.87181 c 0,-0.55 0.45,-1 1,-1 0.55,0 1,0.45 1,1 v 7 h -2 z m 9,3 c -0.55,0 -1,0.45 -1,1 v 5 h -14 v -14 h 5 c 0.55,0 1,-0.45 1,-1 0,-0.55 -0.45,-1 -1,-1 h -5 c -1.1,0 -2,0.9 -2,2 v 14 c 0,1.1 0.9,2 2,2 h 14 c 1.1,0 2,-0.9 2,-2 v -5 c 0,-0.55 -0.45,-1 -1,-1 z m -4,0 c -0.55,0 -1,0.45 -1,1 v 3 h 2 v -3 c 0,-0.55 -0.45,-1 -1,-1 z m -9,-1 v 5 h 2 v -5 c 0,-0.55 -0.45,-1 -1,-1 -0.55,0 -1,0.45 -1,1 z"
       id="path61" />
    <path
       d="M 74.769001,50.193 H 62.769 c -0.55,0 -1,-0.45 -1,-1 0,-0.55 0.45,-1 1,-1 h 12.000001 c 0.55,0 1,0.45 1,1 0,0.55 -0.45,1 -1,1 z"
       id="path449" />
       </g>
    </SvgIcon>
  );
}

export default SvgImport5498;
