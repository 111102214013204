import React, { useEffect, useState } from 'react';
import { Alert, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { Account, AccountType } from '../../../api/AccountApi.d';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { determineAgeGroup } from '../../../app.constants';

export interface CoverdellAgeWarningProps {
  accountOwner: AccountOwner;
}

function CoverdellAgeWarning({ accountOwner }: CoverdellAgeWarningProps) {
  const [showCoverdellWarning, setShowCoverdellWarning] = useState(false);
  const { values } = useFormikContext<Account>();

  const { accountType } = values;
  const { dateOfBirth } = accountOwner;

  useEffect(() => {
    if (accountType === AccountType.esa) {
      const { currentlyOrOver18 } = determineAgeGroup(dateOfBirth);
      setShowCoverdellWarning(currentlyOrOver18);
    } else {
      setShowCoverdellWarning(false);
    }
  }, [accountType, dateOfBirth]);

  return (
    <Grid item xs={12}>
      {showCoverdellWarning && (
        <Alert severity="warning">
          Warning: The selected account owner is over age 18. A Coverdell ESA
          cannot be opened unless the individual is a special needs beneficiary.
        </Alert>
      )}
    </Grid>
  );
}

export default CoverdellAgeWarning;
