import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ScgAccountSearch(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M14 1a2 2 0 012 2v9.207A5.502 5.502 0 0013.259 21L3 21a2 2 0 01-2-2V3a2 2 0 012-2h11zM5 12H4v1h1v-1zm6 .143H6v.714h5v-.714zM5 10H4v1h1v-1zm8 0H6v1h7v-1zM5 8H4v1h1V8zm8 0H6v1h7V8zm0-1V6H5v1h8z"
          fill="currentColor"
        />
        <path
          d="M16.716 14l.198.005a3.716 3.716 0 012.621 6.13l.16.154h.452L23 23.15l-.852.851-2.859-2.853v-.452l-.154-.16-.167.136A3.716 3.716 0 1116.716 14v-.001zm-.216 1l-.165.005A2.496 2.496 0 0014 17.5c0 1.383 1.117 2.5 2.5 2.5l.165-.005A2.496 2.496 0 0019 17.5c0-1.383-1.117-2.5-2.5-2.5z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default ScgAccountSearch;
