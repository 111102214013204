import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import StepButtonBar from '../../steps/StepButtonBar';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraSwitchField from '../SiraSwitchField';
import { Solution } from '../../../api/OrganizationApi.d';

export interface ScheduledDistributionSettings {
  autoScheduledDistributionsEnabled: boolean;
  withholdingNotificationsEnabled: boolean;
}

export const SCHED_DIST_SETTINGS_INIT: ScheduledDistributionSettings = {
  autoScheduledDistributionsEnabled: true,
  withholdingNotificationsEnabled: true,
};

export interface ScheduledDistributionSettingsFormProps {
  initialValues: ScheduledDistributionSettings;
  onSubmit?: Function;
  submitName?: string;
}

function ScheduledDistributionSettingsForm({
  initialValues,
  onSubmit = () => {},
  submitName,
}: ScheduledDistributionSettingsFormProps) {
  const { organization } = useGlobalContext();
  const { solution } = organization;

  // Settings that are allowed given a solution tier
  const allowedSettings = {
    [Solution.black]: [
      'autoScheduledDistributionsEnabled',
      'withholdingNotificationsEnabled',
    ],
    [Solution.green]: ['autoScheduledDistributionsEnabled'],
  }[solution];

  // Disable options not allowed by their solution tier even if saved
  const initValuesBySolution: ScheduledDistributionSettings = {
    autoScheduledDistributionsEnabled: !allowedSettings.includes(
      'autoScheduledDistributionsEnabled'
    )
      ? false
      : initialValues.autoScheduledDistributionsEnabled,
    withholdingNotificationsEnabled: !allowedSettings.includes(
      'withholdingNotificationsEnabled'
    )
      ? false
      : initialValues.withholdingNotificationsEnabled,
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...initValuesBySolution,
      }}
      enableReinitialize
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
    >
      {({ isSubmitting, values }) => {
        const {
          autoScheduledDistributionsEnabled,
          withholdingNotificationsEnabled,
        } = values;

        return (
          <Form>
            <Grid container spacing={5}>
              {/* Schedule RMD Notifications */}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <SiraSwitchField
                      disabled={
                        !allowedSettings.includes(
                          'autoScheduledDistributionsEnabled'
                        )
                      }
                      name="autoScheduledDistributionsEnabled"
                      checked={autoScheduledDistributionsEnabled}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      System automatically adds scheduled distributions
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Send Withholding Notifications */}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <SiraSwitchField
                      disabled={
                        !allowedSettings.includes(
                          'withholdingNotificationsEnabled'
                        )
                      }
                      name="withholdingNotificationsEnabled"
                      checked={withholdingNotificationsEnabled}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      Superior mails withholding notifications to account owners
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default ScheduledDistributionSettingsForm;
