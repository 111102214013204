import axios from 'axios';
import format from 'string-format';

import { WithholdingNotificationsResponse } from './WithholdingNotificationApi.d';
import { setBaseUrl } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const withholdingNotificationsUrl =
  '/org/{0.orgId}/withholdingNotifications';
const accountWithholdingNotificationsUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/withholdingNotifications';
const withholdingNotificationDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/withholdingNotification/{0.withholdingNotificationId}/document';

export async function getOrgWithholdingNotifications(
  orgId: string,
  token: string,
  user
): Promise<WithholdingNotificationsResponse> {
  if (!orgId || !token) return null;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${withholdingNotificationsUrl}`, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getWithholdingNotificationDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  withholdingNotificationId: string,
  token: string,
  user: any,
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${withholdingNotificationDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    withholdingNotificationId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountWithholdingNotifications(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user: any
): Promise<WithholdingNotificationsResponse> {
  if (!orgId || !token) return null;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountWithholdingNotificationsUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export async function deleteAccountWithholdingNotifications(
  orgId: string,
  withholdingNotificationId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user: any
) {
  if (!orgId || !token) return null;

  const url = `${urlBase}${format(`${setBaseUrl(user)}${withholdingNotificationDocumentUrl}`, {
    orgId,
    withholdingNotificationId,
    accountOwnerId,
    accountId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}