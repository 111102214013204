import React, { useEffect, useState } from 'react';
import { LinearProgress, Box, Grid, Button, Typography } from '@mui/material';
import { isAfter, parseISO } from 'date-fns';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import {
  deleteBeneficiaries,
  getBeneficiaries,
  updateBeneficiary,
} from '../../api/BeneficiariesApi';
import { Beneficiary, BeneficiaryStatus } from '../../api/BeneficiariesApi.d';
import BeneficiaryForm, { BENEFICIARY_INIT } from '../form/BeneficiaryForm';
import SecondaryBeneficiaries from './SecondaryBeneficiaries';
import {
  setBeneficiariesResponse,
  updateLatestBeneficiariesStatus,
  setSecondaryBeneficiaries,
  addOrReplaceBeneficiary,
  setBeneficiaryToEdit,
  changeBeneficiaryAddType,
  cancelBeneficiaryForm,
  AddBeneficiaryType,
  BeneficiariesState,
} from './BeneficiariesReducer';
import { AccountStatus, Account } from '../../api/AccountApi.d';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';

interface DesignateSecondaryBeneficiariesProps {
  state: BeneficiariesState;
  account: Account;
  onSave?: Function;
  onSkipToSignature?: Function;
  statusFilter?: BeneficiaryStatus;
  accountOwner?: AccountOwner;
  accountOwnerProfile?: boolean;
}

function DesignateSecondaryBeneficiaries(
  props: DesignateSecondaryBeneficiariesProps
) {
  let isMounted = true;
  const {
    state,
    account = {},
    onSave = () => {},
    onSkipToSignature = () => {},
    statusFilter,
    accountOwner,
    accountOwnerProfile
  } = props;
  const navigate = useNavigate();
  const { accountId = '', accountStatus, closedDate } = account;
  const { addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [showBeneficiariesDeleted, setShowBeneficiariesDeleted] = useState(
    false as boolean
  );
  const { beneficiariesRes, showForm, beneficiaryToEdit } = state;
  const { data: versions = [] } = beneficiariesRes;
  const [latestBeneficiaries = {}] = versions;
  const { beneficiaryStatus, effectiveDate, version } = latestBeneficiaries;
  const isPending = beneficiaryStatus === BeneficiaryStatus.pending;
  const isAwaiting = [
    BeneficiaryStatus.signature,
    BeneficiaryStatus.review,
  ].includes(beneficiaryStatus);
  const isAccountClosed = accountStatus === AccountStatus.closed;
  const isBeforeAccountClosed = isAfter(
    parseISO(closedDate),
    parseISO(effectiveDate)
  );

  const updateEditedBeneficiary = async (data: Beneficiary): Promise<void> => {
    setIsLoading(true);
    await updateBeneficiary(
      data,
      user.organizationId,
      accountId,
      account.accountOwnerId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          addOrReplaceBeneficiary(data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
  
          setIsLoading(false);
          addGlobalMessage(errorMessages(err) || 'Error updating active beneficiary'
          );
        }
      });
  };

  async function getBeneficiaryVersions(): Promise<void> {
    setIsLoading(true);

    const params = {
      ...(statusFilter && { status: statusFilter }),
    };

    getBeneficiaries(
      accountId,
      account.accountOwnerId,
      user.organizationId,
      user.token,
      params,
      user
    )
      .then((res) => {
        if (isMounted) {
          setBeneficiariesResponse(res);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setBeneficiariesResponse({
            data: [],
            errorMessage: err?.response?.data?.message,
            status: err.response?.status,
          });
          setIsLoading(false);
        }
      });
  }

  // DELETE the pending beneficiares set (e.g. if account is closed and the can't be updated anyway)
  const deleteLatestBeneficiaries = async () => {
    setIsLoading(true);

    await deleteBeneficiaries(
      user.organizationId,
      accountId,
      account.accountOwnerId,
      version,
      user.token,
      user
    )
      .then(() => {
        setBeneficiariesResponse({
          data: [],
        });
        setIsLoading(false);
        setShowBeneficiariesDeleted(true);
      })
      .catch((err) => {


        setIsLoading(false);
        addGlobalMessage(errorMessages(err) || 'Error deleting beneficiaries');
      });
  };

  function skipToSignature() {
    // When AWAITING_SIGNATURE transaction date is before account closing, still allow signing
    if (isAccountClosed && isBeforeAccountClosed) {
      onSkipToSignature();
    }
  }

  const goToDashboard = () => {
    navigate('/');
  };

  useEffect(() => {
    if (user.token && user.organizationId) {
      getBeneficiaryVersions();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, accountId, user.organizationId]);

  useEffect(() => {
    skipToSignature();
  }, [isBeforeAccountClosed]);

  if (isLoading) {
    return (
      <Box width="1" mt={5} mb={3}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  if (showBeneficiariesDeleted) {
    return (
      <Box width="1" mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="success">
              Beneficiaries successfully deleted.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={goToDashboard} variant="contained" color="primary">
              Go to Dashboard
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (isAccountClosed) {
    return (
      <Box width="1" mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="error">
              Cannot update beneficiaries after an account is closed
            </Alert>
          </Grid>
          {(isPending || isAwaiting) && (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                There were contingent beneficiaries in-progress after the date
                the account was closed that cannot be updated. Would you like to
                delete them?
              </Typography>
              <Button
                onClick={deleteLatestBeneficiaries}
                variant="contained"
                color="primary"
              >
                Delete Beneficiaries
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  return (
    <Box width="1">
      <Box>
        <SecondaryBeneficiaries
          account={account}
          beneficiaries={latestBeneficiaries}
          onSave={(data) => {
            updateLatestBeneficiariesStatus(data.beneficiaryStatus);
            onSave(data);
          }}
          onSkipToSignature={onSkipToSignature}
          onAddClick={() => {
            changeBeneficiaryAddType(AddBeneficiaryType.secondary);
          }}
          onEditClick={(row) => {
            setBeneficiaryToEdit(row as Beneficiary);
            changeBeneficiaryAddType(AddBeneficiaryType.secondary);
          }}
          showForm={showForm}
          updateBeneficiaries={setSecondaryBeneficiaries}
          updateBeneficiariesStatus={updateLatestBeneficiariesStatus}
          accountOwnerProfile={accountOwnerProfile}
        />
      </Box>

      {showForm && (
        <Box mt={4}>
          {beneficiaryStatus === BeneficiaryStatus.live && beneficiaryToEdit ? (
            <BeneficiaryForm
              initialValues={{
                ...BENEFICIARY_INIT,
                ...beneficiaryToEdit,
              }}
              accountOwner={accountOwner}
              account={account}
              onSubmit={updateEditedBeneficiary}
              onCancel={cancelBeneficiaryForm}
              isLiveEditing
            />
          ) : (
            <BeneficiaryForm
              initialValues={{
                ...BENEFICIARY_INIT,
                ...beneficiaryToEdit,
              }}
              accountOwner={accountOwner}
              account={account}
              onSubmit={addOrReplaceBeneficiary}
              onCancel={cancelBeneficiaryForm}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default DesignateSecondaryBeneficiaries;
