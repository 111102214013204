import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

import SiraTextField from './SiraTextField';
import SiraDateField, { dateValidation } from './SiraDateField';
import SiraPercentField from './SiraPercentField';
import PhoneNumberField, {
  PhoneNumberFormat,
  phoneValidation,
} from './PhoneNumberField';
import TaxIdField, { ssnValidation, taxIdValidation } from './TaxIdField';
import AddressForm, { ADDRESS_SCHEMA, ADDRESS_INIT } from './AddressForm';
import { Beneficiary, BeneficiaryTypes } from '../../api/BeneficiariesApi.d';
import StepButtonBar from '../steps/StepButtonBar';
import DuplicateUserCheck from './userSearch/DuplicateUserCheck';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { Account } from '../../api/AccountApi.d';
import SiraSelectField from './SiraSelectField';
import { beneficiaryRelationshipOptions } from '../../app.constants';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { AccountMemberValue } from '../../api/OrganizationApi.d';

export const BENEFICIARY_INIT: Beneficiary = {
  ...ADDRESS_INIT(),
  name: '',
  representativeFirstName: '',
  representativeLastName: '',
  firstName: '',
  middleInitial: '',
  lastName: '',
  dateOfBirth: '',
  phoneNumber: '',
  emailAddress: '',
  taxpayerIdNumber: '',
  relationship: BeneficiaryTypes.DEFAULT,
  percent: 0,
  accountNumber: '',
  openDate: '',
};

export const BENEFICIARY_SCHEMA = (isInheritedOwner) =>
  ADDRESS_SCHEMA({ required: isInheritedOwner }).shape({
    name: yup
      .string()
      .max(40)
      .label('Estate, Organization or Trust Name')
      .when('relationship', (relationship, schema) => {
        let label;

        switch (relationship) {
          case BeneficiaryTypes.TRUST:
            label = 'Trust Name';
            break;
          case BeneficiaryTypes.ESTATE:
            label = 'Estate Name';
            break;
          case BeneficiaryTypes.NONEHUMAN:
            label = 'Organization Name';
            break;
          default:
            break;
        }
        return [
          BeneficiaryTypes.TRUST,
          BeneficiaryTypes.ESTATE,
          BeneficiaryTypes.NONEHUMAN,
        ].includes(relationship)
          ? schema.required().label(label)
          : schema;
      }),
    representativeFirstName: yup
      .string()
      .max(40)
      .when('relationship', (relationship, schema) =>
        [BeneficiaryTypes.TRUST].includes(relationship)
          ? schema.label('Trust Representative First Name')
          : schema.label('Representative First Name')
      ),
    representativeLastName: yup
      .string()
      .max(40)
      .when('relationship', (relationship, schema) =>
        [BeneficiaryTypes.TRUST].includes(relationship)
          ? schema.label('Trust Representative Last Name')
          : schema.label('Representative Last Name')
      ),
    firstName: yup
      .string()
      .max(40)
      .when('relationship', (relationship, schema) =>
        [
          BeneficiaryTypes.TRUST,
          BeneficiaryTypes.ESTATE,
          BeneficiaryTypes.NONEHUMAN,
        ].includes(relationship)
          ? schema
          : schema.required()
      )
      .label('First name'),
    middleInitial: yup.string().max(1).label('Middle initial'),
    lastName: yup
      .string()
      .max(40)
      .when('relationship', (relationship, schema) =>
        [
          BeneficiaryTypes.TRUST,
          BeneficiaryTypes.ESTATE,
          BeneficiaryTypes.NONEHUMAN,
        ].includes(relationship)
          ? schema
          : schema.required()
      )
      .label('Last name'),
    dateOfBirth: yup
      .string()
      .label('Date of Birth')
      .when('relationship', (relationship, schema) =>
        [
          BeneficiaryTypes.TRUST,
          BeneficiaryTypes.ESTATE,
          BeneficiaryTypes.NONEHUMAN,
          BeneficiaryTypes.OTHER,
          BeneficiaryTypes.CHILD,
          BeneficiaryTypes.DEFAULT,
        ].includes(relationship)
          ? schema
          : dateValidation().required().label('Date of Birth')
      ),
    taxpayerIdNumber: yup.string().when('relationship', (relationship) => {
      if (
        [
          BeneficiaryTypes.TRUST,
          BeneficiaryTypes.ESTATE,
          BeneficiaryTypes.NONEHUMAN,
        ].includes(relationship)
      ) {
        return isInheritedOwner
          ? taxIdValidation().required()
          : taxIdValidation();
      }

      return isInheritedOwner ? ssnValidation().required() : ssnValidation();
    }),
    phoneNumber: phoneValidation(PhoneNumberFormat.unitedStates).label(
      'Phone Number'
    ),
    emailAddress: yup.string().email().max(50).label('Email'),
    relationship: yup
      .string()
      .nullable()
      .max(20)
      .required()
      .label('Relationship'),
    percent:
      !isInheritedOwner &&
      yup
        .number()
        .typeError('Please enter a number')
        .required()
        .min(0.01)
        .max(100)
        .label('Percent'),
  });

export interface BeneficiaryFormProps {
  initialValues: Beneficiary;
  onSubmit?: (values: Beneficiary, formikHelpers?: any) => void;
  onReset?: (values: Beneficiary, formikHelpers?: any) => void;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  isLiveEditing?: boolean;
  onDuplicateUser?: Function;
  isInheritedOwner?: boolean;
  accountOwner?: AccountOwner;
  account?: Account;
}

function BeneficiaryForm({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
  isLiveEditing = false,
  onDuplicateUser,
  isInheritedOwner = false,
  accountOwner = {},
  account = {},
}: BeneficiaryFormProps) {
  const { organization } = useGlobalContext();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={BENEFICIARY_SCHEMA(isInheritedOwner)}
      enableReinitialize
    >
      {({ isSubmitting, values, setValues }) => {
        const isHumanBeneficairy = ![
          BeneficiaryTypes.ESTATE,
          BeneficiaryTypes.TRUST,
          BeneficiaryTypes.NONEHUMAN,
        ].includes(values.relationship);

        const [firstName, setfirstName] = useState('');
        const [fullName, setName] = useState('');
        const [lastName, setLastName] = useState('');
        const [ssnExist, setSSNExist] = useState(false);

        useEffect(() => {
          setSSNExist(false);
        }, [initialValues]);

        useEffect(() => {
          switch (values.relationship) {
            case BeneficiaryTypes.TRUST:
              setName('Trust Name');
              setfirstName('Trust Representative First Name');
              setLastName('Trust Representative Last Name');
              setValues({
                ...values,
                firstName: '',
                middleInitial: '',
                lastName: '',
              });
              break;
            case BeneficiaryTypes.ESTATE:
              setName('Estate Name');
              setfirstName('Estate Representative First Name');
              setLastName('Estate Representative Last Name');
              setValues({
                ...values,
                firstName: '',
                middleInitial: '',
                lastName: '',
              });
              break;
            case BeneficiaryTypes.NONEHUMAN:
              setName('Organization Name');
              setfirstName('Organization Rep First Name');
              setLastName('Organization Rep Last Name');
              +(
                // initialize the name, first name and last name to the default values
                setValues({
                  ...values,
                  firstName: '',
                  middleInitial: '',
                  lastName: '',
                  dateOfBirth: '',
                })
              );
              break;
            default:
              setName('Relationship to Owner');
              setfirstName('First Name');
              setLastName('Last Name');
              break;
          }
        }, [values.relationship]);
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid item xs={12} sm={5}>
                  <SiraSelectField
                    items={beneficiaryRelationshipOptions}
                    name="relationship"
                    label={
                      isInheritedOwner
                        ? 'Relationship to Deceased Owner'
                        : 'Relationship'
                    }
                    disabled={isLiveEditing}
                  />
                </Grid>
              </Grid>

              {isInheritedOwner && (
                <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <SiraTextField
                      name="accountNumber"
                      label={
                        organization.accountNumberValue ===
                        AccountMemberValue.accountNumber
                          ? 'Account Number'
                          : 'Member Number'
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SiraDateField
                      name="openDate"
                      label="Open Date"
                    />
                    </Grid>
                </Grid>
                </Grid>
              )}

              {/* Show this field sooner when checking for duplicate user */}
              {onDuplicateUser && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TaxIdField
                      disabled={isLiveEditing}
                      name="taxpayerIdNumber"
                      label={isHumanBeneficairy ? 'SSN' : 'Tax ID'}
                      ssn={isHumanBeneficairy}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DuplicateUserCheck
                      onDuplicateFound={onDuplicateUser(
                        values.relationship,
                        values.accountNumber
                      )}
                      taxId={values.taxpayerIdNumber}
                    />
                  </Grid>
                </>
              )}

              {!isHumanBeneficairy && (
                <>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField name="name" label={fullName} />
                  </Grid>
                  <Grid item xs={0} sm={6} />
                  <Grid item xs={12} sm={6}>
                    <SiraTextField
                      name="representativeFirstName"
                      label={firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField
                      name="representativeLastName"
                      label={lastName}
                    />
                  </Grid>
                </>
              )}

              {isHumanBeneficairy && (
                <>
                  <Grid item xs={12} sm={5}>
                    <SiraTextField
                      disabled={isLiveEditing}
                      name="firstName"
                      label="First Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SiraTextField
                      disabled={isLiveEditing}
                      maxLength={1}
                      name="middleInitial"
                      label="MI"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <SiraTextField name="lastName" label="Last Name" />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                      <SiraDateField
                        disabled={isLiveEditing}
                        name="dateOfBirth"
                        label="Birth Date"
                      />
                  </Grid>
                </>
              )}
              {!onDuplicateUser && (
                <Grid item xs={12} sm={6}>
                  <TaxIdField
                    disabled={ssnExist}
                    name="taxpayerIdNumber"
                    label={isHumanBeneficairy ? 'SSN' : 'Tax ID'}
                    ssn={isHumanBeneficairy}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <PhoneNumberField name="phoneNumber" label="Phone Number" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SiraTextField name="emailAddress" label="Email" />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <AddressForm
                    accountOwner={accountOwner}
                    account={account}
                    allowAddressCopy
                    international
                  />
                </Grid>
              </Grid>
              {!isInheritedOwner && (
                <Grid item xs={12} sm={6}>
                  <SiraPercentField
                    disabled={isLiveEditing}
                    name="percent"
                    label="Percentage"
                  />
                </Grid>
              )}
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default BeneficiaryForm;
