import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { Formik, Form, Field, useFormikContext } from 'formik';

import { TransferRequest } from '../../../api/TransferApi.d';
import { Account, AccountType } from '../../../api/AccountApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import SiraTextField from '../SiraTextField';
import PhoneNumberField, {
  PhoneNumberFormat,
  phoneValidation,
} from '../PhoneNumberField';
import getTransferRequestSourceOptions from './getTransferSourceOptions';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { determineAgeGroup } from '../../../app.constants';
import AddressForm, {
  AddressFieldNames,
  ADDRESS_INIT,
  ADDRESS_SCHEMA,
} from '../AddressForm';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';

export const TRANSFER_SOURCE_INIT: TransferRequest = {
  ...ADDRESS_INIT(),
  sourceAccountNumber: '',
  sourceOrgAddressLine1: '',
  sourceOrgAddressLine2: '',
  sourceOrgCity: '',
  sourceOrgState: '',
  sourceOrgZip: '',
  sourceOrgName: '',
  sourceOrgPhoneNumber: '',
  sourcePlanName: '',
  sourceAccountType: '' as AccountType,
};

const addressFieldNames: AddressFieldNames = {
  addressLine1: 'sourceOrgAddressLine1',
  addressLine2: 'sourceOrgAddressLine2',
  city: 'sourceOrgCity',
  state: 'sourceOrgState',
  zip: 'sourceOrgZip',
};

function isEmployerPlan(sourceAccountType: AccountType): boolean {
  return sourceAccountType === AccountType.employer;
}

export const TRANSFER_SOURCE_SCHEMA = ADDRESS_SCHEMA({
  addressFieldNames,
  required: true,
}).shape({
  sourceAccountNumber: yup.string().max(100).label('Source Account Number'),
  sourceOrgName: yup.string().required().label('Current Organization Name'),
  sourceOrgPhoneNumber: phoneValidation(PhoneNumberFormat.unitedStates).label(
    'Phone Number'
  ),
  sourcePlanName: yup
    .string()
    .label('Employer Plan Name')
    .when('sourceAccountType', (sourceAccountType, schema) => {
      return isEmployerPlan(sourceAccountType) ? schema.required() : schema;
    }),
  sourceAccountType: yup.string().required().label('Source Account Type'),
});

export interface TransferSourceFormProps {
  account: Account;
  accountOwner: AccountOwner;
  initialValues: TransferRequest;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

function TransferSourceSubForm(props) {
  const { options = [] as Array<RadioGroupOption> } = props;
  const { values, setValues } = useFormikContext();
  const { sourceAccountType } = values as TransferRequest;

  // Clear fields that are affected by the source account selection
  useEffect(() => {
    if (!isEmployerPlan(sourceAccountType)) {
      setValues({
        ...(values as TransferRequest),
        sourcePlanName: '',
      });
    }
  }, [sourceAccountType]);

  // Clear out form if a source option value is preloaded that isn't displayed
  useEffect(() => {
    const validOptionValues = options.map(({ value }) => value);

    if (!validOptionValues.includes(sourceAccountType)) {
      setValues({
        ...(values as TransferRequest),
        sourceAccountType: '',
      });
    }
  }, []);

  return (
    <>
      {isEmployerPlan(sourceAccountType) && (
        <>
          <Grid item xs={12}>
            <SiraTextField
              name="sourceOrgName"
              label="Plan Administrator Name"
            />
          </Grid>
          <Grid item xs={12}>
            <SiraTextField name="sourcePlanName" label="Plan Name" />
          </Grid>
        </>
      )}
      {!isEmployerPlan(sourceAccountType) && (
        <>
          <Grid item xs={12} sm={8}>
            <SiraTextField
              name="sourceOrgName"
              label="Current Trustee/Custodian Name"
            />
          </Grid>
        </>
      )}
    </>
  );
}

function TransferSourceForm({
  account = {},
  accountOwner = {},
  initialValues,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
}: TransferSourceFormProps) {
  const { getAppConstant, organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const { underHalf59: isEarlyDistribution } = determineAgeGroup(
    accountOwner.dateOfBirth
  );
  const transferRequestSourceOptions = getTransferRequestSourceOptions(
    getAppConstant,
    account.accountType,
    isEarlyDistribution
  );

  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Current Account Number': 'Current Member Number';


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        onSubmit(data);
      }}
      onReset={() => {
        onReset();
      }}
      validationSchema={TRANSFER_SOURCE_SCHEMA}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  label="Choose the type of account where the assets are currently held"
                  name="sourceAccountType"
                  options={transferRequestSourceOptions}
                  component={SiraRadioField}
                />
              </Grid>
              <TransferSourceSubForm options={transferRequestSourceOptions} />
              <Grid item xs={12} sm={7}>
                <SiraTextField
                  name="sourceAccountNumber"
                  label={headerAccountName}
                />
              </Grid>
              <AddressForm addressFieldNames={addressFieldNames} />
              <Grid item sm={6} xs={12}>
                <PhoneNumberField
                  name="sourceOrgPhoneNumber"
                  label="Phone Number"
                />
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default TransferSourceForm;
