import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import {
  AccountOwner,
  AccountOwnerAccount,
} from '../../../api/AccountOwnerApi.d';
import { useUser } from '../../../auth/useUser';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraAccountOwnerInfo from '../SiraAccountOwnerInfo';
import Layout from '../../Layout';
import PaperlessPage from '../../PaperlessPage';
import { getAccount } from '../../../api/AccountApi';
import { getAccountOwner } from '../../../api/AccountOwnerApi';
import TaxFormDistributions from './TaxFormDistributions';
import TaxFormContributions from './TaxFormContributions';
import { errorMessages } from '../../../utils/errorhandling.utils';

function TaxFormWorkFlow() {
  let isMounted = true;
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);

  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();

  const queryParams = new URLSearchParams(useLocation().search);
  const taxformid = queryParams.get('taxformid');
  const taxForm = queryParams.get('taxForm');
  const [selectedAccount, setSelectedAccount] = useState(
    {} as AccountOwnerAccount
  );

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    await getAccount(
      queryParams.get('account'),
      queryParams.get('accountOwner'),
      user.organizationId,
      user.token,
      user
    )
      .then(async (res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          await getAccountOwner(
            res.data.accountOwnerId,
            user.organizationId,
            user.token,
            user
          )
            .then((response) => {
              setAccountOwner(response.data);
            })
            .catch((err) => {
      
              if (isMounted) {
                addGlobalMessage(errorMessages(err) ||
                    'Could not fetch the account owner information'
                );
              }
            });
        }
      })
      .catch((err) => {

        if (isMounted) {
 addGlobalMessage(errorMessages(err) || 'Could not fetch the preselected account'
          );
        }
      });
  }

  // Optionaly set account when passed query params
  async function preselectAccountInfo(): Promise<void> {
    if (queryParams.get('account')) {
      await fetchAndSetAccount();
    }
  }

  useEffect(() => {
    if (user.organizationId && user.token) {
      preselectAccountInfo();
    }
    return () => {
      isMounted = false;
    };
  }, [user.token]);
  
  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            {`${taxForm} Tax Form Workflow`}
          </Typography>
          <Paper sx={{ mt: 2 }} elevation={3}>
            <SiraAccountOwnerInfo
              selectedAccount={selectedAccount}
              accountOwner={accountOwner}
            />
          </Paper>
          <Paper sx={{ mt: 2}} elevation={3}>
            {taxForm !== '5498' ? (
              <TaxFormDistributions
                account={selectedAccount}
                taxFormId={taxformid}
              />
            ) : (
              <TaxFormContributions
                account={selectedAccount}
              />
            )}
          </Paper>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default TaxFormWorkFlow;
