import React, { useState, useEffect } from 'react';
import { Alert, Button, Divider, Grid, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { format, parseISO } from 'date-fns';

import moment from 'moment';
import { useUser } from '../../../auth/useUser';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import {
  changeTransferRequestStatus,
  getTransferAllocations,
  getTransferRequestDocument,
} from '../../../api/TransferApi';
import {
  TransferAllocation,
  TransferRequest,
  TransferRequestStatusState,
  Workflow,
} from '../../../api/TransferApi.d';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraCurrencyField from '../SiraCurrencyField';
import SiraDateField, { dateValidation } from '../SiraDateField';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { getInvestmentRates } from '../../../api/InvestmentRateApi';
import { InvestmentRate } from '../../../api/InvestmentRateApi.d';
import { AccountStatus } from '../../../api/AccountApi.d';
import { createFileBlobFromBase64 } from '../../../utils/App.utils';
import { errorMessages } from '../../../utils/errorhandling.utils';

export const TRANSFER_INSTRUCTIONS_INIT: Workflow = {
  transferAmount: '',
    effectiveDate: moment().format('YYYY-MM-DD'),
};

export const TRANSFER_INSTRUCTIONS_SCHEMA = yup.object({
  transferAmount: yup.string().label('Amount').required().label('Amount'),
  effectiveDate: dateValidation().required().label('Date Funds Received'),
});

export interface TransferSourceFormProps {
  transferRequest: any | TransferRequest;
  initialValues?: Workflow;
  onSuccess?: Function;
  onCancel?: Function;
  submitName?: string;
}

interface TransferAllocationData extends TransferAllocation {
  type: string;
  rate: number;
}

// Fancy formatter that merges the investment rate and allocation data
function transferAllocationsDataFactory(
  allocations: Array<TransferAllocation>,
  investmentRates: Array<InvestmentRate>
): Array<TransferAllocationData> {
  return allocations.map(
    ({ investmentRateId: allocRateId, amount, percent }) => {
      const { description: type, interestRate: rate } = investmentRates.find(
        ({ investmentRateId }) => investmentRateId === allocRateId
      );

      return {
        type,
        rate,
        amount,
        percent,
      };
    }
  );
}

function TransferWorkflowForm({
  transferRequest,
  initialValues,
  onSuccess = () => {},
  onCancel = () => {},
  submitName = 'Submit',
}: TransferSourceFormProps) {
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const [transferAllocationsData, setTransferAllocationsData] = useState(
    [] as Array<TransferAllocationData>
  );
  const [isFetchingDocument, setIsFetchingDocument] = useState(
    false as boolean
  );
  const {
    fullName = '',
    accountId = '',
    accountType = '',
    accountOwnerId = '',
    taxpayerIdNumber = '',
    transferRequestId = '',
    transferAmount = '',
    signedDate = '',
    accountStatus = '',
    date = ''
  } = transferRequest;

  // Move the transferRequest to the final status and create contribution (latter handled by backend)
  const updateStatusAndWorkflow = async (workflow: Workflow) => {
    await changeTransferRequestStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      transferRequestId,
      TransferRequestStatusState.next,
      workflow,
      user.token
    )
      .then((res) => {
        onSuccess(res.data);
        addGlobalMessage('Successfully finalized rollover/transfer request', {
          severity: 'success',
        });
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Could not finalize rollover/transfer request'
        );
      });
  };

  // View the PDF for a specific transfer request before finalizing it
  async function openPdf() {
    await getTransferRequestDocument(
      user.organizationId,
      accountOwnerId,
      accountId,
      transferRequestId,
      user.token
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  // Fetch the allocation(s) info for the current transfer request
  const transferAllocationsPromise = getTransferAllocations(
    user.organizationId,
    accountOwnerId,
    accountId,
    transferRequestId,
    user.token
  )
    .then(({ data = [] }) => data)
    .catch(() => {
      addGlobalMessage('Error fetching transfer request allocations');
    });

  // Fetch all org investments to map allocations into
  const investmentRatespromise = getInvestmentRates(
    user.organizationId,
    user.token,{},
    user
  )
    .then(({ data = [] }) => data)
    .catch(() => {
      addGlobalMessage('Error fetching transfer request allocations');
    });

  // Combine allocations and investments into one, renderable data set.
  async function getAndPrepareAllocationsData() {
    await Promise.all([transferAllocationsPromise, investmentRatespromise])
      .then(([allocations, investmentRates]) => {
        setTransferAllocationsData(
          transferAllocationsDataFactory(
            allocations as TransferAllocation[],
            investmentRates as InvestmentRate[]
          )
        );
      })
      .catch(() => {
        addGlobalMessage(
          'Unable to prepare allocations data for this transfer'
        );
      });
  }

  useEffect(() => {
    if (user.token && user.organizationId) {
      getAndPrepareAllocationsData();
    }
  }, [user.token, user.organizationId]);

  return (
    <Formik
      initialValues={{
        ...TRANSFER_INSTRUCTIONS_INIT,
        ...initialValues,
        transferAmount,
      }}
      validationSchema={TRANSFER_INSTRUCTIONS_SCHEMA}
      onSubmit={updateStatusAndWorkflow}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={3}>
              {fullName && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Name</Typography>
                  <Typography>{fullName}</Typography>
                </Grid>
              )}
              {accountType && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Account Type</Typography>
                  <Typography>
                    {getAppConstant(
                      ConstantsMappingKey.accountType,
                      accountType
                    )}
                  </Typography>
                </Grid>
              )}
              {date && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Date Signed</Typography>
                  <Typography>
                    {signedDate &&
                      format(parseISO(String(date)), 'MM/dd/yyyy')}
                  </Typography>
                </Grid>
              )}
              {taxpayerIdNumber && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">SSN</Typography>
                  <Typography>
                    <ObscuredTaxId
                      format={TaxIdFormat.ssn}
                      taxId={taxpayerIdNumber}
                    />
                  </Typography>
                </Grid>
              )}
              {Boolean(transferAllocationsData.length) && (
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h6">
                    Allocations:
                  </Typography>
                  {transferAllocationsData.map(
                    ({ type = '', rate = '', amount = '', percent = '' }) => (
                      <Typography gutterBottom key={type} variant="body2">
                        {type} ({rate}%) -{' '}
                        {percent ? `${percent}%` : `$${amount}`}
                      </Typography>
                    )
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disabled={isFetchingDocument}
                  color="primary"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={async () => {
                    setIsFetchingDocument(true);
                    await openPdf();
                    setIsFetchingDocument(false);
                  }}
                >
                  View Document
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              {accountStatus === AccountStatus.review ? (
                <Alert severity="error">
                  This account is in Pending Review. It must be Open to finalize
                  this request.
                </Alert>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography>
                      What is the final amount for this rollover/transfer?
                    </Typography>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <SiraCurrencyField name="transferAmount" label="Amount" />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <SiraDateField
                      name="effectiveDate"
                      label="Date Funds Received"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default TransferWorkflowForm;
