import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgTransactionSignature(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 17.776 10.978 L 7.145 21.609 L 3.368 21.609 L 3.368 17.832 L 13.999 7.201 C 14.728 6.472 15.915 6.472 16.645 7.201 L 17.776 8.332 C 18.515 9.062 18.515 10.249 17.776 10.978 Z M 14.466 11.642 L 13.335 10.511 L 5.238 18.608 L 5.238 19.739 L 6.369 19.739 Z M 10.848 21.609 L 14.588 17.869 L 20.198 17.869 L 20.198 21.609 Z M 20.363 2.417 L 15.355 2.417 C 14.852 1.027 13.535 0.021 11.977 0.021 C 10.42 0.021 9.102 1.027 8.599 2.417 L 3.592 2.417 C 2.274 2.417 1.196 3.495 1.196 4.813 L 1.196 21.584 C 1.196 22.901 2.274 23.979 3.592 23.979 L 20.363 23.979 C 21.68 23.979 22.759 22.901 22.759 21.584 L 22.759 4.813 C 22.759 3.495 21.68 2.417 20.363 2.417 Z M 11.977 4.813 C 11.318 4.813 10.779 4.274 10.779 3.615 C 10.779 2.956 11.318 2.417 11.977 2.417 C 12.636 2.417 13.175 2.956 13.175 3.615 C 13.175 4.274 12.636 4.813 11.977 4.813 Z" />
    </SvgIcon>
  );
}

export default SvgTransactionSignature;
