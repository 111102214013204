import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  Box,
  LinearProgress,
  Link,
  Icon,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { format, parseISO } from 'date-fns';

import NumberFormat from 'react-number-format';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import { getOrg, updateOrg } from '../../api/OrganizationApi';
import {
  AccountMemberValue,
  DocumentationAddress,
  FiduciaryType,
  SingelSignOn,
  Solution,
  SpousalConsentOption,
} from '../../api/OrganizationApi.d';
import FinancialOrgForm, {
  FINANCIAL_ORG_INIT,
} from '../form/organization/FinancialOrgForm';
import {
  accountNumberorMemberNumber,
  documentationAddressLabels,
  fiduciaryTypeOptions,
  OrgInformationStatus,
  orgSolutionOptions,
  singleSignOnOptionsDescription,
} from '../../app.constants';
import { errorMessages } from '../../utils/errorhandling.utils';
import parse from 'react-html-parser';
import { set } from 'cypress/types/lodash';

function OrganizationProfileDetails() {
  let isMounted = true;
  const { organization, setCurrentOrg, addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    name = '',
    financialOrganizationId = '',
    legalName = '',
    federalTaxId = '',
    fiduciaryType = FiduciaryType.default,
    spousalConsentRequired = SpousalConsentOption.community,
    solution = '',
    agreementDate = '',
    documentationAddress = '' as DocumentationAddress,
    customerPortalUrl = '',
    investmentRatePercision,
    apyRatePrecision,
    accountNumberValue,
    activeDate = '',
    sftpFolderName = '',
    sso = SingelSignOn.none,
    ipFiltering = false,
    status = OrgInformationStatus.inactive,
    accountOwnerPortalCustomError = '',
    accountOwnerPortalCustomErrorHeader = '',
    planNumberEnabled = false,
  } = organization;
  const [orgErrorMessage, setOrgErrorMessage] = useState('');

  // setting the typograpy based on the color sent in for the solution
  // eslint-disable-next-line no-nested-ternary
  const colorValue =
    // eslint-disable-next-line no-nested-ternary
    solution === Solution.silver
      ? '#938D8A'
      : solution === Solution.green
        ? '#368E64'
        : '#0C1F2A';

  async function saveOrganization(data) {
    setIsLoading(true);

    await updateOrg({ ...organization, ...data }, organization, user.token)
      .then((res) => {
        if (isMounted) {
          setIsEditing(false);
          setIsLoading(false);
          setCurrentOrg(res.data);
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error saving organization information',
          );
        }
      });
  }

  async function fetchOrganization() {
    setIsLoading(true);
    await getOrg(organization.financialOrganizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setCurrentOrg(res.data);
          // find value ', object: ' and split the string
          if (res.data.accountOwnerPortalCustomError) {
            const splitValues =
              res.data.accountOwnerPortalCustomError.split(', object: ');
            if (splitValues.length > 1) {
              const plainMarkup = splitValues[0].replace('markup: ', '');
              setOrgErrorMessage(plainMarkup);
            } else {
              setOrgErrorMessage(res.data.accountOwnerPortalCustomError);
            }
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error fetching organization information',
          );
        }
      });
  }

  useEffect(() => {
    if (isMounted) {
      fetchOrganization();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <Box width="1" pt={10} pb={10} textAlign="center">
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return isEditing ? (
    <FinancialOrgForm
      initialValues={{
        ...FINANCIAL_ORG_INIT,
        ...organization,
        // Pass through some other required org properties here even though the fields aren't available in the UI
        // But avoid spreading branches, etc which have their own endpoints
        name,
        financialOrganizationId,
        legalName,
        federalTaxId,
        fiduciaryType,
        spousalConsentRequired,
        solution,
        documentationAddress,
        customerPortalUrl,
        investmentRatePercision,
        apyRatePrecision,
        accountNumberValue,
      }}
      onSubmit={async (data) => {
        await saveOrganization(data);
      }}
      onCancel={() => {
        setIsEditing(false);
      }}
      isEditing
    />
  ) : (
    <Box width="1">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Name</Typography>
          <Typography variant="body1">{name}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Org Number</Typography>
          <Typography variant="body1">{financialOrganizationId}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Legal Name</Typography>
          <Typography variant="body1">{legalName}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Federal Tax ID</Typography>
          <Typography variant="body1">{federalTaxId}</Typography>
        </Grid>
        {fiduciaryType && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Fiduciary Type</Typography>
            <Typography variant="body1">
              {
                fiduciaryTypeOptions.find(
                  ({ value }) => value === fiduciaryType,
                ).label
              }
            </Typography>
          </Grid>
        )}
        {solution && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Current Solution</Typography>
            <Typography variant="body1">
              {orgSolutionOptions.find(({ value }) => value === solution).label}
            </Typography>
          </Grid>
        )}
        {agreementDate && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Agreement Date</Typography>
            <Typography variant="body1">
              {agreementDate &&
                format(parseISO(String(agreementDate)), 'MM/dd/yyyy')}
            </Typography>
          </Grid>
        )}
        {customerPortalUrl && customerPortalUrl !== '' && (
          <Grid item xs={12}>
            <Typography variant="h6">Account Owner Portal Url</Typography>
            <Typography variant="body1">
              <Link href={customerPortalUrl} underline="always" target="_blank">
                {legalName} Account Owner Portal{' '}
                <Icon>
                  <OpenInNewIcon fontSize="small" />
                </Icon>
              </Link>
            </Typography>
          </Grid>
        )}
        {accountOwnerPortalCustomError &&
          accountOwnerPortalCustomError !== '' && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Account Owner Portal Error Message
                </Typography>
                <Typography variant="body1">
                  {parse(orgErrorMessage)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Account Owner Portal Error Header
                </Typography>
                <Typography variant="body1">
                  {accountOwnerPortalCustomErrorHeader}
                </Typography>
              </Grid>
            </>
          )}
        {sftpFolderName && (
          <Grid item xs={12}>
            <Typography variant="h6">SFTP Site</Typography>
            <Typography variant="body1">{sftpFolderName}</Typography>
          </Grid>
        )}
        {accountNumberValue && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Account Number Value</Typography>
            <Typography variant="body1">
              {accountNumberValue === AccountMemberValue.accountNumber
                ? accountNumberorMemberNumber.ACCOUNT_NUMBER
                : accountNumberorMemberNumber.MEMBER_NUMBER}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Display Plan Number</Typography>
          <Typography variant="body1">
            {planNumberEnabled ? 'True' : 'False'}
          </Typography>
        </Grid>
        {activeDate && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Active Date</Typography>
            <Typography variant="body1">{activeDate}</Typography>
          </Grid>
        )}

        {sso && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Single Sign On</Typography>
            <Typography variant="body1">
              {singleSignOnOptionsDescription[sso]}
            </Typography>
          </Grid>
        )}
        {ipFiltering && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">IP Filtering Enabled</Typography>
            <Typography variant="body1">
              {ipFiltering ? 'Enabled' : 'Disabled'}{' '}
            </Typography>
          </Grid>
        )}
        {status && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Status</Typography>
            <Typography variant="body1">{status}</Typography>
          </Grid>
        )}
      </Grid>
      <Box mt={4}>
        <Button
          aria-label="Edit organization details"
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit Details
        </Button>
      </Box>
    </Box>
  );
}

export default OrganizationProfileDetails;
