import axios from 'axios';

import { ConstantsMappingResponse } from './SharedTextApi.d';
import { setBaseUrl } from './api.utils';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const constantsMappingUrl = '/constantsMapping';

export async function getConstantsMapping(
  token: string,
  user
): Promise<ConstantsMappingResponse> {
  const url = `${urlBase}${setBaseUrl(user)}${constantsMappingUrl}`;
  const storedConstants = window.sessionStorage.getItem('constants');

  if (storedConstants) {
    return Promise.resolve({ data: JSON.parse(storedConstants) });
  }

  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res: ConstantsMappingResponse) => {
      if (res.data) {
        window.sessionStorage.setItem('constants', JSON.stringify(res.data));
      }

      return res;
    });
}
