import React, { useEffect } from 'react';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as yup from 'yup';
import { getYear, parseISO } from 'date-fns';
import { Grid, Typography, IconButton } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import SiraCheckbox from './SiraCheckbox';
import { getConstantActiveTaxYear } from '../../app.constants';

interface TaxYearFilterFormProps {
  onChange: Function;
  items: Array<any>;
  options: Array<string>;
  componentName?: string;
  selectedTaxYears?: Function;
}

interface TaxYearFilterFormData {
  taxYearFilters: Array<string>;
}

const TAX_YEAR_FILTER_SCHEMA = {
  taxYearFilters: yup.array(yup.string()).label('Filters'),
};

function TaxYearsFilterFormFields(props: TaxYearFilterFormProps) {
  const {
    options = [],
    items = [],
    onChange = () => {},
    componentName,
    selectedTaxYears =() => {},
  } = props;
  const { values, setFieldValue } = useFormikContext();
  const { taxYearFilters } = values as TaxYearFilterFormData;
  const allDeselected = taxYearFilters.length === 0;
  const someSelected =
    taxYearFilters.length > 0 && taxYearFilters.length < options.length;
  const allSelected = taxYearFilters.length === options.length;

  const clearOrSelectAll = () => {
    if (allSelected || someSelected) {
      setFieldValue('taxYearFilters', []);
    } else {
      setFieldValue('taxYearFilters', [...options]);
    }
  };

  useEffect(() => {
    selectedTaxYears(taxYearFilters);
    onChange(
      items.filter(({ effectiveDate, year }) => {
        const distTaxYear = year
          ? year.toString()
          : getYear(parseISO(effectiveDate)).toString();
        return taxYearFilters.includes(distTaxYear);
      }),
      taxYearFilters
    );
  }, [taxYearFilters]);

  // set the default filter to the current active tax year on initial load
  useEffect(() => {
    if (taxYearFilters.length === 0) {
      if (options.length > 0) {
        const activeTaxYearString = getConstantActiveTaxYear().toString();
        const indexOfActiveTaxYear = options.indexOf(activeTaxYearString);
        const currentYear = getYear(new Date()).toString();
        const indexOfCurrentYear = options.indexOf(currentYear);
        const lastEntry = options[options.length - 1];
        // select the last date in the options array
        const selectedOptions =
          componentName === 'WithholdingNotificationsList'
            ? [lastEntry]
            : [options[indexOfActiveTaxYear], options[indexOfCurrentYear]];
        setFieldValue('taxYearFilters', selectedOptions);
      }
    }
  }, [options]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={false}>
        <Typography variant="body2">Show Tax Years:</Typography>
      </Grid>

      {/* Aggregate checkbox */}
      <Grid item xs={false}>
        <IconButton color="primary" onClick={clearOrSelectAll} size="large">
          {allDeselected && <CheckBoxOutlineBlankIcon />}
          {someSelected && <IndeterminateCheckBoxIcon />}
          {allSelected && <CheckBoxIcon />}
        </IconButton>
      </Grid>

      <Grid item>
        <MoreVertIcon sx={{ verticalAlign: 'middle', ml: -1 }} />
      </Grid>

      {/* Filter options and sorted*/}
      {options.sort().map((option) => {
        return (
          <Grid item key={option} xs={false}>
            <Field
              checked={taxYearFilters.includes(option)}
              name="taxYearFilters"
              value={option}
              label={option}
              type="checkbox"
              component={SiraCheckbox}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

function  TaxYearsFilterForm(props: TaxYearFilterFormProps) {
  return (
    <Formik
      onSubmit={() => {}}
      initialValues={{ taxYearFilters: [] }}
      validationSchema={yup.object(TAX_YEAR_FILTER_SCHEMA)}
      enableReinitialize
    >
      {() => (
        <Form>
          <TaxYearsFilterFormFields {...props} />
        </Form>
      )}
    </Formik>
  );
}

export default TaxYearsFilterForm;
