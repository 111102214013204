import { StandardTextFieldProps, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { FieldAttributes, useField } from 'formik';
import InputMask from 'react-input-mask';

import { Country } from '../../api/StatesApi.d';

interface ZipFieldProps extends StandardTextFieldProps {
  country: Country;
}

// https://www.npmjs.com/package/react-input-mask#mask--string
// https://en.wikipedia.org/wiki/List_of_postal_codes
const masks = {
  [Country['en-US']]: '99999',
  [Country['en-CA']]: 'a9a 9a9',
  [Country['en-IE']]: 'a9a 9a9a',
  [Country['en-GB']]: ['AA99 9AA', 'AA9 9AA', 'A9 9AA', 'A99 9AA'],
  [Country.sv]: '999 99',
};

const ZipField: React.FC<FieldAttributes<ZipFieldProps>> = ({
  country,
  ...textFieldProps
}) => {
  const [field, meta] = useField<{}>(textFieldProps);
  const { disabled } = textFieldProps;
  const errorText = meta.error && meta.touched ? meta.error : '';
  const currentStateMask = masks[country] || "***************";

  useEffect(() => {
    field.value = String(field.value).toUpperCase();
  }, [field.value]);

  return (
    <InputMask
      mask={currentStateMask}
      disabled={disabled}
      maskChar=""
      // Force any chars to uppercase so we don't rely on the user doing it
      // This will mutate the value before it's updated in the UI so validation works
      beforeMaskedValueChange={(newState) => {
        const { value, selection } = newState;
        return {
          value: String(value).toUpperCase(),
          selection,
        };
      }}
      {...field}
    >
      {() => (
        <TextField
          helperText={errorText}
          error={!!errorText}
          variant="outlined"
          fullWidth
          type="text"
          {...field}
          {...textFieldProps}
        />
      )}
    </InputMask>
  );
};

export default ZipField;
