import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings } from './api.utils';
import {
  TaskListResponse,
  Task,
  TaskUpdateResponse,
  TaskStatus,
} from './TaskApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const multiOrgTaskUrl = '/api/todoList';
const taskListUrl = '/api/org/{0.orgId}/todolist/{0.toDoListId}';
const taskListUnassignedUrl =
  '/api/org/{0.orgId}/todolist/unassignedforuser/{0.userId}';

export async function getAllTasks(
  orgId: string,
  token: string
): Promise<TaskListResponse> {
  const url = `${urlBase}${format(taskListUrl, {
    orgId,
    toDoListId: '',
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAssignedTasks(
  orgId: string,
  params: {
    completedAfter?: string;
    status?: TaskStatus;
  },
  token: string
): Promise<TaskListResponse> {
  const url = `${urlBase}${format(taskListUrl, {
    orgId,
    toDoListId: '',
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
}

export async function getAvailableUnassignedTasks(
  orgId: string,
  token: string
): Promise<TaskListResponse> {
  const url = `${urlBase}${format(taskListUnassignedUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateTask(
  data: Task,
  orgId: string,
  toDoListId: string,
  token: string
): Promise<TaskUpdateResponse> {
  const url = `${urlBase}${format(taskListUrl, {
    orgId,
    toDoListId,
  })}`;

  const dataNorm = removeEmptyStrings(data);

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createMultiOrgTask(
  data: Task,
  token: string
): Promise<TaskUpdateResponse> {
  const url = `${urlBase}${multiOrgTaskUrl}`;

  const dataNorm = removeEmptyStrings(data);

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
