import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';

import moment from 'moment';
import { addYears, startOfYear } from 'date-fns';
import { Distribution } from '../../../api/DistributionApi.d';
import ScheduleForm from './ScheduleForm';
import { DistributionTerm } from './DistributionTermForm';
import { dateValidation } from '../SiraDateField';
import { Account } from '../../../api/AccountApi.d';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';

export interface ScheduleFormData {
  effectiveDate?: string;
  frequency?: string;
}

export const FREQUENCY_SCHEMA = {
  frequency: yup.string().required().label('Frequency'),
  effectiveDate: dateValidation(
    startOfYear(new Date()),
    addYears(new Date(), 100)
  )
    .required()
    .label('Date'),
};

export const SCHEDULE_INIT: ScheduleFormData = {
    effectiveDate: moment().format('YYYY-MM-DD'),
  frequency: '',
};

export interface DistributionScheduleContainerProps {
  distribution?: Distribution;
  taxAmounts?: Distribution;
  initialValues?: ScheduleFormData;
  onSubmit?: Function;
  onReset?: Function;
  distributionTerm?: DistributionTerm;
  account?: Account;
  saveCalculatedInfo: Function;
  saveDistributionInfo: Function;
  accountOwner: AccountOwner;
}

function DistributionScheduleContainer({
  distribution,
  initialValues = SCHEDULE_INIT,
  onSubmit = () => {},
  onReset = () => {},
  taxAmounts,
  distributionTerm,
  account,
  saveCalculatedInfo,
  saveDistributionInfo,
  accountOwner,
}: DistributionScheduleContainerProps) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      onReset={() => onReset()}
      validationSchema={yup.object(FREQUENCY_SCHEMA)}
    >
      <Form>
        <ScheduleForm
          distribution={distribution}
          taxAmounts={taxAmounts}
          distributionTerm={distributionTerm}
          account={account}
          saveCalculatedInfo={saveCalculatedInfo}
          saveDistributionInfo={saveDistributionInfo}
          accountOwner={accountOwner}
        />
      </Form>
    </Formik>
  );
}

export default DistributionScheduleContainer;
