import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;

// Look up the right url based on the document type passed along by the notification
const lookupDocumentFetchUrl = (documentType: string): string =>
  ({
    ACCOUNT_OWNER_ACCOUNT: '/api/org/{0.orgId}/accountOwnerAccountFile',
    USER: '/api/org/{0.orgId}/userFile',
    DISTRIBUTION: '/api/org/{0.orgId}/distributionFile',
    CONTRIBUTION: '/api/org/{0.orgId}/contributionFile',
    ACCOUNT_OWNER_AUDIT: '/api/org/{0.orgId}/accountOwnerAuditFile',    
  }[documentType]);

export async function getDoc(
  documentType: string,
  fileName: string,
  org: string,
  token: string
) {
  const url = `${urlBase}${format(lookupDocumentFetchUrl(documentType), {
    orgId: org,
  })}`;
  const params = removeEmptyStrings({ fileName });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}
