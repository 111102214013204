import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportIcon from '@mui/icons-material/Report';
import { useNavigate } from 'react-router-dom';

import FormPaper from './FormPaper';

function SiraLayoutError(props) {
  const { error, resetErrorBoundary } = props;
  const navigate = useNavigate();

  const reloadCurrentView = () => {
    navigate(0);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <FormPaper>
      <>
        <Box textAlign="center" maxWidth="sm" ml="auto" mr="auto">
          <ReportIcon color="error" sx={{ fontSize: '126px' }} />
          <Typography variant="h3" gutterBottom>
            Oops!
          </Typography>
          <Typography variant="body1" gutterBottom>
            There was an error performing the last operation. You may try again
            to continue anyway, reload the page to start over, or go back to the
            last page that worked.
          </Typography>
        </Box>

        <Box ml="auto" mr="auto">
          <Accordion sx={{ p: 2 }} elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2">More Information</Typography>
            </AccordionSummary>

            <AccordionDetails>
              {/* Render the stack trace on multiple lines */}
              {error.stack.split('\n').map((trace) => (
                <Typography component="p" variant="caption">
                  {trace}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>

        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="info"
              onClick={resetErrorBoundary}
            >
              Try Again
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="warning"
              onClick={reloadCurrentView}
            >
              Reload the Page
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="error" onClick={goBack}>
              Go Back
            </Button>
          </Grid>
        </Grid>
      </>
    </FormPaper>
  );
}

export default SiraLayoutError;
