import React from 'react';
import {  useAuth0 } from '@auth0/auth0-react';
import {  useNavigate } from 'react-router-dom';

import {
  useTheme,
  Grid,
  Avatar,
  Typography,
  Button,
  Card,
  Box,
  Link,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { sendPasswordReset } from '../../api/UserApi';
import { useUser } from '../../auth/useUser';



export interface SimpleDialogProps {}

function Profile() {
  const navigate = useNavigate();

  const theme = useTheme();
  const { user, isLoading } = useAuth0();
  const { addGlobalMessage, organization, currentUser } = useGlobalContext();
  const { user: userFromContext } = useUser();

  async function sendPasswordResetEmail(): Promise<void> {
    await sendPasswordReset(
      organization.financialOrganizationId,
      currentUser.userId,
      userFromContext.token
    )
      .then(() => {
        addGlobalMessage(
          "Instructions to reset password have been sent to the user's email",
          {
            severity: 'success',
          }
        );
      })
      .catch(() => {
        addGlobalMessage('Password reset email could not be sent');
      });
  }

  const classes = {
    root: {
      width: 277,
      maxHeight: 386,
      opacity: 0.96,
      padding: theme.spacing(4),
      backgroundColor: grey[900],
      color: theme.palette.primary.contrastText,
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      margin: 'auto',
    },
    actionSection: {
      marginTop: theme.spacing(4),
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }


  return (
    <Card sx={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Avatar alt={user.name} src={user.iconImage} sx={classes.avatar} />
        </Grid>
        <Grid item>
          <Typography variant="h6">{user.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">User Email</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{user.email}</Typography>
        </Grid>
        <Grid item sx={classes.actionSection}>
          <Box>
            <Button
              onClick={() => {
                navigate(`/editProfile/`);
              }}
              variant="contained"
              color="primary"
              sx={{ marginRight: '5px' }}
            >
              <EditIcon fontSize="small" sx={classes.icon} />
              Edit Profile
            </Button>
          </Box>
        </Grid>
        <Grid item sx={classes.actionSection}>
          <Typography variant="body2">
            <Link
              onClick={() => {
                sendPasswordResetEmail();
              }}
              sx={{ cursor: 'pointer' }}
            >
              Reset Password
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Profile;
