import React from 'react';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function DataGridExportToolbar() {
  return (
    <GridToolbarContainer style={{ justifyContent: 'end' }}>
      <GridToolbarExport
        csvOptions={{ allColumns: true }}
        printOptions={{ disableToolbarButton: true }}
        variant="contained"
        color="primary"
        size="small"
        sx={{ m: 1 }}
      />
    </GridToolbarContainer>
  );
}

export default DataGridExportToolbar;
