import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  format,
  formatISO,
  isAfter,
  parseISO,
  startOfYear,
  subYears,
} from 'date-fns';
import AccountSearchForm from '../../components/form/accountSearch/AccountSearchForm';
import AccountSearchResults from '../../components/form/accountSearch/AccountSearchResults';
import Layout from '../../components/Layout';
import FormPaper from '../../components/FormPaper';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import {
  addTransactionData,
  setSearchResponse,
  setSelectedAccount,
  skipStep,
  completeTransaction,
  useTransactionReducer,
} from '../TransactionReducer';
import { useUser } from '../../auth/useUser';
import DistributionTermForm, {
  DistributionTerm,
  DISTRIBUTION_TERM_INIT,
  TermEnabledOption,
} from '../../components/form/distribution/DistributionTermForm';
import DistributionAmountForm, {
  DISTRIBUTION_AMOUNT_INIT,
} from '../../components/form/distribution/DistributionAmountForm';
import { getAccountOwner } from '../../api/AccountOwnerApi';
import DistributionScheduleContainer, {
  ScheduleFormData,
  SCHEDULE_INIT,
} from '../../components/form/distribution/DistributionScheduleContainer';
import DistributionMethodForm, {
  DISTRIBUTION_METHOD_INIT,
} from '../../components/form/distribution/DistributionMethodForm';
import {
  calculateRecurringDistributionReason,
  changeRecurringDistributionStatus,
  createOrSaveRecurringDistribution,
  createRecurringDistributionDocument,
  getRecurringDistributionDocument,
  deleteRecurringDistribution,
  getRecurringDistribution,
} from '../../api/RecurringDistributionApi';
import {
  LifeExpectancyTable,
  RecurringDistribution,
  TermChoiceOption,
} from '../../api/RecurringDistributionApi.d';
import {
  WorkflowDates,
  DistributionStatusState,
  DistributionStatus,
  Distribution,
} from '../../api/DistributionApi.d';
import ReviewDocumentForm from '../../components/form/newAccount/ReviewDocumentForm';
import { getAccount } from '../../api/AccountApi';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { AccountStatus, TransactionType } from '../../api/AccountApi.d';
import SelectDistributionForm from '../../components/form/distribution/SelectDistributionForm';
import { DistributionChoices } from '../../components/form/distribution/DistributionAmountRecurringSubForm';
import ReconcileScheduledDistributions from '../../components/form/distribution/ReconcileScheduledDistributions';
import TransactionStepper, { TransactionStep } from '../TransactionStepper';
import {
  RowDefinition,
  useUnsavedChangesWarning,
} from '../../components/useUnsavedChangesWarning';
import {
  changeBeneficiariesStatus,
  createBeneficiaries,
} from '../../api/BeneficiariesApi';
import {
  BeneficiaryTypes,
  BeneficiaryStatusState,
  BeneficiaryStatus,
} from '../../api/BeneficiariesApi.d';
import SiraAccountOwnerInfo from '../../components/form/SiraAccountOwnerInfo';
import { currentTaxYear, determineAgeGroup } from '../../app.constants';
import { createOrSaveFairMarketValue } from '../../api/FairMarketValueApi';
import { IMMDocumentType } from '../../api/ESignApi.d';
import createOwnerTheme from '../../themes/ownerTheme';
import { AccountDetailAccordionType } from '../../components/accountOwnerProfile/AccountOwnerAccountDetails';
import { errorMessages } from '../../utils/errorhandling.utils';

function OwnerRecurringDistribution() {
  let isMounted = true;
  let revertPending = false;
  const { user } = useUser();
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);
  const [isLoading, setIsLoading] = useState(false);
  const [parentRecurringDistribution, setParentRecurringDistribution] =
    useState({} as RecurringDistribution);
  const [pageState] = useTransactionReducer();
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();
  const {
    distributionStatus,
    startDate,
    recurringDistributionId,
    ownerResponsible,
    fmvInDb = false,
  } = pageState.distributionInformation;
  const queryParams = new URLSearchParams(useLocation().search);
  const loadingExistingAccount = queryParams.get('accountId');
  const loadingExistingDistribution = queryParams.get(
    'recurringDistributionId'
  );
  const editActive = queryParams.get('editActive') === 'true';
  const { organization, addGlobalMessage } = useGlobalContext();
  const { query = '' } = pageState.searchResponse || {};
  const {
    accountType = '',
    accountOwnerId = '',
    accountId,
    accountStatus,
    closedDate,
  } = pageState.selectedAccount;
  const isPending = distributionStatus === DistributionStatus.pending;
  const isAwaiting = [
    DistributionStatus.signature,
    DistributionStatus.review,
  ].includes(distributionStatus);
  const isActive = [
    DistributionStatus.active,
    DistributionStatus.review,
  ].includes(distributionStatus);
  const isAccountClosed = accountStatus === AccountStatus.closed;

  const isBeforeAccountClosed = isAfter(
    parseISO(closedDate),
    parseISO(startDate)
  );

  // Only show state withholding when an org has at least one state that supports it
  const showStateWithholding =
    organization.stateTaxIDS &&
    organization.stateTaxIDS.some(
      ({ stateWithholding = false }) => stateWithholding
    );

  // Save term and conditionally assign some term-dependent fields to root distribution object
  const saveTermInfo = (
    distributionTermInformation: DistributionTerm,
    advanceStep: boolean
  ) => {
    const {
      ownerResponsible: newOwnerResponsible,
      termLength: newTermLength,
      termChoice: newTermChoice,
      termEndDateAction: newTermEndDateAction,
      qualifiedJLE: newQualifiedJLE,
    } = distributionTermInformation;

    // Lookup life expectancy table based on term choice
    const calcType = {
      [TermChoiceOption.jle]: newQualifiedJLE
        ? LifeExpectancyTable.jle
        : LifeExpectancyTable.ule,
      [TermChoiceOption.ult]: LifeExpectancyTable.ule,
      [TermChoiceOption.sle]: LifeExpectancyTable.sle,
      [TermChoiceOption.slenonrecalc]: LifeExpectancyTable.slenonrecalc,
    }[newTermChoice];

    const termValue = newTermLength || -1;

    const distributionInformation = {
      termEndDateAction: newTermEndDateAction,
      ownerResponsible: newOwnerResponsible,
      termChoice: newTermChoice,
      // Optional fields; one or the other will be added
      ...(!calcType && termValue && { term: termValue }),
      ...(calcType && { lifeExpectancyTable: calcType }),
    };

    addTransactionData({ distributionInformation }, false);
    addTransactionData({ distributionTermInformation }, advanceStep);
  };

  const saveDistributionInfo = (
    data: RecurringDistribution,
    advanceStep: boolean
  ) => {
    // set federalWithholgingAmount to
    const distributionInformation = {
      ...pageState.distributionInformation,
      ...data,
      federalWithholdingAmount: '0',
    };
    addTransactionData({ distributionInformation }, advanceStep);

    if (
      distributionInformation.recurringSelection === DistributionChoices.owner
    ) {
      skipStep(5);
    }
  };

  const copyParentDistribution = (
    distributionInformation: RecurringDistribution,
    advanceStep: boolean
  ) => {
    const newRecurringDist = {
      ...distributionInformation,
      termChoice: TermChoiceOption.empty,
      startDate: formatISO(new Date()).slice(0, 10),
      distributionStatus: DistributionStatus.pending,
      recurringDistributionId: '',
      parentRecurringDistributionId:
        distributionInformation.recurringDistributionId,
    };
    setParentRecurringDistribution(distributionInformation);
    saveDistributionInfo(newRecurringDist, advanceStep);
  };

  // DELETE the WIP transaction (e.g. if account is closed and they can't be updated anyway)
  const deleteTransaction = async () => {
    setIsLoading(true);

    await deleteRecurringDistribution(
      recurringDistributionId,
      accountId,
      accountOwner.accountOwnerId,
      user.organizationId,
      user.token,
      user
    );
  };

  function skipToSignature() {
    // When AWAITING_SIGNATURE transaction date is before account closing, still allow signing
    if (isAccountClosed && isBeforeAccountClosed) {
      skipStep(5);
    }
  }

  // Create a spouse/oldest beneficairy entry on the account that's in an active state
  const createActiveBeneficiaryWithElection = async (): Promise<void> => {
    const startOfPriorYear = startOfYear(subYears(new Date(), 1));
    const spouseDob = format(
      parseISO(pageState.distributionInformation.spouseBirthDate),
      'yyyy-MM-dd'
    );
    // New JLE beneficiary designation data from distribution info
    const newPrimaryBeneficiary = {
      dateOfBirth: spouseDob,
      firstName: 'Spouse or Oldest Designated',
      lastName: 'Beneficiary',
      percent: 100,
      relationship: BeneficiaryTypes.SPOUSE,
    };

    await createBeneficiaries(
      {
        signedDate: format(startOfPriorYear, 'yyyy-MM-dd'),
        primaryBeneficiaries: [newPrimaryBeneficiary],
      },
      user.organizationId,
      accountId,
      accountOwnerId,
      user.token,
      user
    )
      .then(async ({ data = {} }) => {
        const { version } = data;
        const progressBeneficiaryStatus = () =>
          changeBeneficiariesStatus(
            user.organizationId,
            accountId,
            accountOwnerId,
            version.toString(),
            BeneficiaryStatusState.next,
            {},
            user.token,
            user
          )
            .then(async ({ data: beneficiaryData = {} }) => {
              const { beneficiaryStatus } = beneficiaryData;

              // Recurse this function sequentially until they're ACTIVE
              if (beneficiaryStatus !== BeneficiaryStatus.live) {
                await progressBeneficiaryStatus();
              }
            })
            .catch(() => {
              addGlobalMessage("Error updating beneficiaries' status");
            });

        await progressBeneficiaryStatus();
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            'Error creating beneficiary designation from distribution'
        );
      });
  };

  const progressStatus = (finalizeWorkflow: boolean) => {
    if (isMounted) {
      if (finalizeWorkflow) {
        completeTransaction();
        setUnsavedChanges(null);
      }
    }
  };

  // Update the status imperatively after document creation
  // The api does this but doesn't send a payload back so we'll hardcode it here for now
  const handleCreateDocument = () => {
    if (distributionStatus === DistributionStatus.pending) {
      addTransactionData(
        {
          distributionInformation: {
            distributionStatus: DistributionStatus.signature,
          },
        },
        false
      );
    }
  };

  // Generate the PDF in S3 and/or stream it
  const viewDocument = (): Promise<any> => {
    return isAwaiting || isActive
      ? getRecurringDistributionDocument(
          user.organizationId,
          pageState.selectedAccount.accountOwnerId,
          pageState.selectedAccount.accountId,
          pageState.distributionInformation.recurringDistributionId,
          user.token,
          '',
          user
        )
      : createRecurringDistributionDocument(
          user.organizationId,
          pageState.selectedAccount.accountOwnerId,
          pageState.selectedAccount.accountId,
          pageState.distributionInformation.recurringDistributionId,
          user.token,
          user
        );
  };

  // Get the accountOwner for the account loaded
  async function updateAccountOwner(): Promise<void> {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setAccountOwner(res.data);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  }

  const pageStateToRecurringDistribution = (
    distributionMethodForm: Distribution
  ) => {
    const { underHalf59 } = determineAgeGroup(accountOwner.dateOfBirth);

    const distributionReason = calculateRecurringDistributionReason(
      accountType,
      underHalf59
    );

    const term = pageState.distributionInformation.term // if the term is 0, it needs to be blank
      ? pageState.distributionInformation.term
      : '';

    const ownerResponsibleDate =
      distributionMethodForm.termEnabled === TermEnabledOption.owner;

    const startDateVal = ownerResponsibleDate
      ? distributionMethodForm.startDate
      : pageState.schedule.effectiveDate;

    const recurringDistribution = {
      ...pageState.distributionInformation,
      ...distributionMethodForm,
      distributionStatus: revertPending ? DistributionStatus.pending : pageState.distributionInformation.distributionStatus,
      frequency: pageState.schedule.frequency,
      startDate: startDateVal,
      grossAmount: pageState.distributionInformation.totalAmount,
      distributionReason,
      term,
    } as RecurringDistribution;

    // Remove extra fields
    return recurringDistribution;
  };

  // Revert transaction to pending before saving to allow updates
  async function revertToPending(data: Distribution): Promise<void> {
    await changeRecurringDistributionStatus(
      user.organizationId,
      pageState.selectedAccount.accountId,
      pageState.selectedAccount.accountOwnerId,
      pageState.distributionInformation.recurringDistributionId,
      DistributionStatusState.previous,
      {} as WorkflowDates,
      user.token,
      user
    ).then(() => {
      saveDistributionInfo(
        {
          ...data,
          distributionStatus: DistributionStatus.pending,
        },
        false
      );
    });
  }

  // Save the FMV record for the current tax year if it was entered so it displays in the document
  const saveFMVRecord = async (data: Distribution) => {
    if (data.fairMarketValue && !fmvInDb && !data.ownerResponsible) {
      await createOrSaveFairMarketValue(
        { taxYear: currentTaxYear, fairMarketValue: data.fairMarketValue },
        user.organizationId,
        pageState.selectedAccount.accountId,
        pageState.selectedAccount.accountOwnerId,
        user.token,
        user
      ).then(() => {
        // Flag the FMV as now stored at the account
        addTransactionData(
          {
            distributionInformation: {
              ...data,
              fmvInDb: true,
            },
          },
          false
        );
      });
    }
  };

  const mergeAndSaveDistribution = async (
    data: Distribution
  ): Promise<void> => {
    setIsLoading(true);
    const { lifeExpectancyTable, spouseBirthDate } =
      pageState.distributionInformation;
    const shouldCreateJLEBene =
      lifeExpectancyTable === LifeExpectancyTable.jle && spouseBirthDate;
    revertPending = false;

    // This may need to wait until distribution info has that information
    if (isAwaiting) {
      revertPending = true;
      await revertToPending(data);

    }

    // Slip in a generic beneficiary before the distribution to qualify JLE on the backend validation
    if (!recurringDistributionId && shouldCreateJLEBene) {
      await createActiveBeneficiaryWithElection();
    }

    await saveFMVRecord(data);

    const termEnabledVal = data.termEnabled === TermEnabledOption.owner;

    await createOrSaveRecurringDistribution(
      pageStateToRecurringDistribution(data), // Here we use a factory to produce the distribution
      pageState.selectedAccount.accountId,
      pageState.selectedAccount.accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          // Might need to save more info here
          if (termEnabledVal) {
            addTransactionData({ distributionInformation: res.data }, false);
            skipStep(6);
          } else {
            saveDistributionInfo(res.data, true);
          }
        }
        const transactionData: RowDefinition = {
          accountId: pageState.selectedAccount.accountId,
          accountOwnerId: pageState.selectedAccount.accountOwnerId,
          transactionType: TransactionType.recurringDistribution,
          recurringDistributionId: res.data.recurringDistributionId,
        };

        setUnsavedChanges(transactionData);
      })
      .catch((err) => {

        setIsLoading(false);
        addGlobalMessage(errorMessages(err) || 'Error saving distribution');
      });
  };

  useEffect(() => {
    if (loadingExistingAccount) {
      updateAccountOwner();
    }
  }, [pageState.dis]);

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    await getAccount(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          setIsLoading(false);
          skipStep(0);
        }
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Could not fetch the preselected account'
          );
        }
      });
  }

  // Lookup distribution and set it with query params passed
  async function fetchAndSetDistributionInfo(): Promise<void> {
    await getRecurringDistribution(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      queryParams.get('recurringDistributionId'),
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        const skipToEnd = [
          DistributionStatus.signature,
          DistributionStatus.review,
        ].includes(res.data.distributionStatus);
        const { data = [] } = res;

        if (isMounted) {
          saveDistributionInfo(data, false);
          if (skipToEnd) {
            skipStep(4);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Error fetching distribution info'
          );
        }
      });
  }

  // Optionaly set account and recurring distribution when passed query params
  async function preselectAccountAndSetDistributionInfo(): Promise<void> {
    if (loadingExistingAccount) {
      setIsLoading(true);
      await fetchAndSetAccount();

      if (loadingExistingDistribution) {
        await fetchAndSetDistributionInfo();
      }
    }
  }

  const saveCalculatedInfo = (taxCalculatedInfo) => {
    addTransactionData({ taxAmounts: taxCalculatedInfo }, false);
  };

  const saveSchedule = (values: ScheduleFormData) => {
    addTransactionData({ schedule: values });
  };

  const AccountClosed = () => (
    <Box width="1" mt={5} mb={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Alert severity="error">
            Cannot start a recurring distribution after an account is closed
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Update RMD notification as COMPLETED
          </Typography>
          <Button
            onClick={async () => {
              await progressStatus(true);
            }}
            variant="contained"
            color="primary"
          >
            Update Status
          </Button>
        </Grid>
        {(isPending || isAwaiting) && (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              This recurring distribution was in-progress after the date the
              account was closed and cannot be updated. Would you like to delete
              it?
            </Typography>
            <Button
              onClick={deleteTransaction}
              variant="contained"
              color="primary"
            >
              Delete Recurring Distribution
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  const RecurringDistributionSteps: Array<TransactionStep> = [
    {
      label: 'Find Owner and Account',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <AccountSearchForm setResponse={setSearchResponse} />
              </Box>
            </Grid>
          </Grid>
          {query && (
            <AccountSearchResults
              filterFunction={({
                account: {
                  accountStatus: statusMatcher = '' as AccountStatus,
                } = {},
              }) =>
                [AccountStatus.open, AccountStatus.closed].includes(
                  statusMatcher
                )
              }
              response={pageState.searchResponse}
              onResultClick={(account) => {
                setSelectedAccount(account);
                // do the check for move forward here?
                skipStep(1);
              }}
            />
          )}
        </Box>
      ),
    },
    {
      label: 'Determine Term/Life Expectancy Table',
      stepContent: isAccountClosed ? (
        <AccountClosed />
      ) : (
        <Box mt={5} mb={3}>
          <DistributionTermForm
            account={pageState.selectedAccount}
            initialValues={{
              ...DISTRIBUTION_TERM_INIT,
              ...pageState.distributionTermInformation,
              ...pageState.distributionInformation,
            }}
            parentDistribution={parentRecurringDistribution}
            onSubmit={(values) => {
              saveTermInfo(values, !values.ownerResponsible);
              // Owner responsible elections end here—save it now.
              if (values.ownerResponsible) {
                mergeAndSaveDistribution(values);
              }
            }}
          />
        </Box>
      ),
    },
    {
      label: 'Provide Distribution Amounts',
      stepContent: (
        <Box mt={5} mb={3}>
          <DistributionAmountForm
            account={pageState.selectedAccount}
            accountOwner={accountOwner}
            onSubmit={(values) => saveDistributionInfo(values, true)}
            initialValues={{
              ...DISTRIBUTION_AMOUNT_INIT,
              ...pageState.distributionTermInformation,
              ...pageState.distributionInformation,
            }}
            parentDistribution={parentRecurringDistribution}
            term={pageState.distributionTermInformation}
            saveCalculatedInfo={saveCalculatedInfo}
            recurringDistribution
            showWithholding
            showStateWithholding={showStateWithholding}
          />
        </Box>
      ),
    },
    {
      label: 'Choose Distribution Schedule',
      stepContent: (
        <DistributionScheduleContainer
          accountOwner={accountOwner}
          distribution={pageState.distributionInformation}
          onSubmit={saveSchedule}
          taxAmounts={pageState.taxAmounts}
          distributionTerm={pageState.distributionTermInformation}
          account={pageState.selectedAccount}
          saveCalculatedInfo={saveCalculatedInfo}
          saveDistributionInfo={saveDistributionInfo}
          initialValues={{
            ...SCHEDULE_INIT,
            ...pageState.distributionInformation,
          }}
        />
      ),
    },
    {
      label: 'Select Distribution Method',
      stepContent: (
        <Box mt={3} mb={3}>
          <DistributionMethodForm
            onSubmit={mergeAndSaveDistribution}
            initialValues={{
              ...DISTRIBUTION_METHOD_INIT,
              ...pageState.distributionInformation,
            }}
            distributionReason={
              pageState.distributionInformation.distributionReason
            }
            accountType={accountType}
            distributionType="newRecurringDistribution"
          />
        </Box>
      ),
    },
    {
      label: 'Review and Sign Document',
      stepContent: (
        <>
          <ReviewDocumentForm
            searchResult={pageState.selectedAccount}
            allowSignature={isAwaiting}
            hideStepButtonBar={pageState.completed}
            getDocument={viewDocument}
            onGetDocument={handleCreateDocument}
            submitName="Done"
            onSubmit={async () => {
              await progressStatus(true);
            }}
            documentType={IMMDocumentType.recurringDistribution}
            transactionName="Distribution"
            accountTransaction={AccountDetailAccordionType.recurring}
          />
        </>
      ),
    },
  ];

  const selectDistributionStep = {
    label: 'Select Distribution',
    stepContent: (
      <Box width="1" mt={4} mb={4}>
        <SelectDistributionForm
          account={pageState.selectedAccount}
          onSelect={copyParentDistribution}
        />
      </Box>
    ),
  };

  const reconcileDistributionsStep = {
    label: 'Reconcile Distributions',
    stepContent: (
      <Box width="1" mt={4} mb={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={2} mb={4}>
              <ReconcileScheduledDistributions
                account={pageState.selectedAccount}
                distribution={parentRecurringDistribution}
                onSubmit={() => skipStep(pageState.activeStep + 1)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    ),
  };

  const recurringDistributionSteps = () => {
    if (editActive) {
      if (ownerResponsible) {
        return [
          selectDistributionStep,
          RecurringDistributionSteps[1],
          RecurringDistributionSteps[2],
          RecurringDistributionSteps[3],
          RecurringDistributionSteps[4],
          RecurringDistributionSteps[5],
        ];
      }

      return [
        selectDistributionStep,
        reconcileDistributionsStep,
        RecurringDistributionSteps[1],
        RecurringDistributionSteps[2],
        RecurringDistributionSteps[3],
        RecurringDistributionSteps[4],
        RecurringDistributionSteps[5],
      ];
    }

    if (ownerResponsible) {
      return [
        RecurringDistributionSteps[0],
        RecurringDistributionSteps[1],
        RecurringDistributionSteps[5],
      ];
    }

    return [
      RecurringDistributionSteps[1],
      RecurringDistributionSteps[2],
      RecurringDistributionSteps[3],
      RecurringDistributionSteps[4],
      RecurringDistributionSteps[5],
    ];
  };

  useEffect(() => {
    if (user.token || user.organizationId) {
      preselectAccountAndSetDistributionInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  useEffect(() => {
    setIsLoading(true);
    updateAccountOwner();
  }, [accountOwnerId]);

  useEffect(() => {
    skipToSignature();
  }, [isBeforeAccountClosed]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createOwnerTheme(organization)}>
        <Layout>
          <FormPaper>
            <>
              <Typography variant="overline" gutterBottom>
                Transactions
              </Typography>
              <Typography
                data-qa="recurringDistribution_header"
                color="secondary"
                variant="h1"
                gutterBottom
              >
                {editActive ? 'Edit' : 'Add'} Recurring Distribution
              </Typography>
              {pageState.selectedAccount.accountId && (
                <Box mt={2}>
                  <Paper elevation={3}>
                    <SiraAccountOwnerInfo
                      selectedAccount={pageState.selectedAccount}
                      accountOwner={accountOwner}
                    />
                  </Paper>
                </Box>
              )}
              <Box mt={5}>
                <TransactionStepper
                  steps={recurringDistributionSteps()}
                  activeStep={pageState.activeStep}
                  isLoading={isLoading}
                  onStepClick={(index) => {
                    if (
                      !loadingExistingDistribution ||
                      (loadingExistingDistribution && index > 0)
                    ) {
                      skipStep(index);
                    }
                  }}
                />
              </Box>
              {UnsavedChangesPrompt}
            </>
          </FormPaper>
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default OwnerRecurringDistribution;
