import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgOrganizationSettings(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M0 19h11.314L13 22H0zM10 1L0 6v2h20V6zM2 10h3v7H2zM8 10h3v7H8zM14 10h3v2l-3 .5z"
        />
        <path d="M18 16.571c-.808 0-1.469.643-1.469 1.429s.661 1.429 1.469 1.429 1.469-.643 1.469-1.429-.661-1.429-1.469-1.429zM21.855 18c0 .164-.014.329-.036.486l1.087.828a.248.248 0 01.058.322l-1.028 1.728c-.066.107-.198.15-.316.107l-1.277-.5c-.265.2-.558.365-.867.493l-.19 1.322a.264.264 0 01-.258.214h-2.056a.265.265 0 01-.257-.207l-.191-1.322a3.802 3.802 0 01-.867-.492l-1.277.5a.263.263 0 01-.316-.108l-1.028-1.728a.248.248 0 01.058-.322l1.087-.828a3.71 3.71 0 01-.036-.493c0-.164.014-.329.036-.486l-1.087-.828a.248.248 0 01-.058-.322l1.028-1.728c.066-.107.198-.15.316-.107l1.277.5c.265-.2.558-.365.867-.493l.19-1.322a.264.264 0 01.258-.214h2.056c.125 0 .235.093.257.207l.191 1.322c.316.128.602.292.867.492l1.277-.5c.118-.042.25 0 .316.108l1.028 1.728a.248.248 0 01-.058.322l-1.087.828c.022.164.036.329.036.493z" />
      </g>
    </SvgIcon>
  );
}

export default SvgOrganizationSettings;
