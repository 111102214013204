import React, { ReactChild, useState } from 'react';
import { FieldAttributes, useField } from 'formik';
import NumberFormat from 'react-number-format';
import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  allowNegative?: boolean;
}

interface SiraCurrencyFieldProps extends StandardTextFieldProps {
  allowNegative?: boolean;
  afterContent?: ReactChild;
}

// Enforces a max number that our API will support
const withValueLimit = ({ value, floatValue }) => {
  return value === '' || floatValue <= 9999999999.99;
};

const NumberFormatCustom = React.forwardRef(
  (props: NumberFormatCustomProps, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        isAllowed={withValueLimit}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        decimalScale={2}
        isNumericString
      />
    );
  }
);

const SiraCurrencyField: React.FC<FieldAttributes<SiraCurrencyFieldProps>> = ({
  allowNegative,
  afterContent,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const [focus, setfocus] = useState(false);
  const focusValue = () => setfocus(true);
  const blurValue = () => setfocus(false);

  return (
    <>
      <TextField
        {...field}
        {...props}
        fullWidth
        helperText={errorText}
        error={!!errorText}
        variant="outlined"
        onFocus={focusValue}
        onBlur={blurValue}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          inputProps: { allowNegative } as any,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          'data-qa': field.name, // This is the data-qa attribute
        }}
      />
      {focus && afterContent}
    </>
  );
};

export default SiraCurrencyField;
