import { TransferRequestRelationship } from '../../../api/TransferApi.d';
import { RadioGroupOption } from '../SiraRadioField';
import { AccountType } from '../../../api/AccountApi.d';
import { modal } from './resource.txt';

function getTransferOwnerOptions(
  destinationAccountType: AccountType,
  sourceAccountType: AccountType,
  isOwnerHuman: boolean
): Array<RadioGroupOption> {
  const ownerOptions = [];

  const ownerRadioOptions = {
    currentOwner: {
      value: TransferRequestRelationship.currentOwner,
      label: 'I am the current account owner',
    },
    currentInheritedOwner: {
      value: TransferRequestRelationship.currentOwner,
      label: 'I am the current inherited IRA owner',
    },
    formerSpouse: {
      value: TransferRequestRelationship.formerSpouse,
      label: 'I am the former spouse of the current account owner',
    },
    spouseBene: {
      value: TransferRequestRelationship.spouseBene,
      label:
        'I am the spouse beneficiary of the original account owner who is deceased',
    },
    childBene: {
      value: TransferRequestRelationship.childBene,
      label:
        'I am the child beneficiary of the original account owner who is deceased',
    },
    spouseBeneIRA: {
      value: TransferRequestRelationship.spouseBene,
      label:
        'I am the spouse beneficiary of the inherited IRA owner who is deceased',
    },
    childBeneIRA: {
      value: TransferRequestRelationship.childBene,
      label:
        'I am the child beneficiary of the inherited IRA owner who is deceased',
    },
    trustBene: {
      value: TransferRequestRelationship.trustBene,
      label: 'Trust beneficiary of the original account owner',
    },
    estateBene: {
      value: TransferRequestRelationship.estateBene,
      label: 'Estate beneficiary of the original account owner',
    },
    nonSpouseBene: {
      value: TransferRequestRelationship.nonSpouseBene,
      label:
        'I am a beneficiary of the original account owner who is deceased (but I’m not a spouse or child of the original owner)',
    },
    nonSpouseBeneIRA: {
      value: TransferRequestRelationship.nonSpouseBene,
      label:
        'I am a beneficiary of the inherited IRA owner who is deceased (but I’m not a spouse or child of the inherited IRA owner)',
    },
    currDesBene: {
      value: TransferRequestRelationship.currentDesBene,
      label: 'I am the current designated beneficiary',
    },
    qaulFamilyMember: {
      value: TransferRequestRelationship.qaulifiedFamily,
      label:
        'I am a qualified family member of the current designated beneficiary',
      afterContent: modal.qaulifiedDesBene,
    },
  };

  // Inherited traditional IRAs
  if ([AccountType.inheritedTraditional].includes(destinationAccountType)) {
    // Non-inherited source
    if (
      [
        AccountType.traditionalIra,
        AccountType.simpleIra,
        AccountType.employer,
      ].includes(sourceAccountType)
    ) {
      if (isOwnerHuman) {
        ownerOptions.push(
          ownerRadioOptions.spouseBene,
          ownerRadioOptions.childBene,
          ownerRadioOptions.nonSpouseBene
        );
      }
      if (!isOwnerHuman) {
        ownerOptions.push(
          ownerRadioOptions.trustBene,
          ownerRadioOptions.estateBene
        );
      }
    }

    // Inherited source
    if ([AccountType.inheritedTraditional].includes(sourceAccountType)) {
      if (isOwnerHuman) {
        ownerOptions.push(
          ownerRadioOptions.currentInheritedOwner,
          ownerRadioOptions.spouseBeneIRA,
          ownerRadioOptions.childBeneIRA,
          ownerRadioOptions.nonSpouseBeneIRA
        );
      }
      if (!isOwnerHuman) {
        ownerOptions.push(
          ownerRadioOptions.currentInheritedOwner,
          ownerRadioOptions.trustBene,
          ownerRadioOptions.estateBene
        );
      }
    }
  }

  if ([AccountType.hsa].includes(destinationAccountType)) {
    ownerOptions.push(
      ownerRadioOptions.currentOwner,
      ownerRadioOptions.formerSpouse
    );
  }

  if ([AccountType.esa].includes(destinationAccountType)) {
    ownerOptions.push(
      ownerRadioOptions.currDesBene,
      ownerRadioOptions.qaulFamilyMember
    );
  }

  if ([AccountType.inheritedRoth].includes(destinationAccountType)) {
    // Inherited Roth
    // Non-inherited source
    if (
      [AccountType.rothIra, AccountType.employer].includes(sourceAccountType)
    ) {
      if (isOwnerHuman) {
        ownerOptions.push(
          ownerRadioOptions.spouseBene,
          ownerRadioOptions.childBene,
          ownerRadioOptions.nonSpouseBene
        );
      }
      if (!isOwnerHuman) {
        ownerOptions.push(
          ownerRadioOptions.trustBene,
          ownerRadioOptions.estateBene
        );
      }
    }

    // Inherited source
    if ([AccountType.inheritedRoth].includes(sourceAccountType)) {
      if (isOwnerHuman) {
        ownerOptions.push(
          ownerRadioOptions.currentInheritedOwner,
          ownerRadioOptions.spouseBeneIRA,
          ownerRadioOptions.childBeneIRA,
          ownerRadioOptions.nonSpouseBeneIRA
        );
      }
      if (!isOwnerHuman) {
        ownerOptions.push(
          ownerRadioOptions.currentInheritedOwner,
          ownerRadioOptions.trustBene,
          ownerRadioOptions.estateBene
        );
      }
    }
  }

  // Non-inerited accounts
  if (
    ![
      AccountType.inheritedRoth,
      AccountType.inheritedTraditional,
      AccountType.hsa,
      AccountType.esa,
    ].includes(destinationAccountType)
  ) {
    ownerOptions.push(
      ownerRadioOptions.currentOwner,
      ownerRadioOptions.formerSpouse,
      ownerRadioOptions.spouseBene
    );
  }

  return ownerOptions;
}

export default getTransferOwnerOptions;
