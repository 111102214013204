import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Container,
} from '@mui/material';
import { darken, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DataGrid,
  GridCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Cell, Pie, PieChart } from 'recharts';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import { RecurringDistributionStatus } from '../api/RecurringDistributionApi.d';
import { getDashboardRecurringDistributions } from '../api/OrganizationApi';
import { searchAccountOwner } from '../api/AccountOwnerApi';
import { AccountOwnerSearchResult } from '../api/AccountOwnerApi.d';
import { AccountStatus, AccountType } from '../api/AccountApi.d';
import { determineAgeGroup, globalPaginationOptions } from '../app.constants';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { DistributionChoices } from '../components/form/distribution/DistributionAmountRecurringSubForm';
import ResponsivePercentBar from '../components/reports/ResponsivePercentBar';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import { dateValueFormatter } from '../utils/DataGrid.utils';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import DataGridExportToolbar from '../components/DataGridExportToolbar';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import { errorMessages } from '../utils/errorhandling.utils';

function ReportsRmdSummary() {
  let isMounted = true;
  const { user } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false as boolean);
  const [isLoadingRMDs, setIsLoadingRMDs] = useState(false as boolean);
  const {
    addGlobalMessage,
    organization: { legalName = '', accountNumberValue },
    getAppConstant,
  } = useGlobalContext();

  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';

  const { setGlobalPageSize } = usePaginationContext();
  const [relevantRecurringDistributions, setRelevantRecurringDistributions] =
    useState([] as Array<any>);
  const [turningOrOver73Owners, setTurningOrOver73Owners] = useState(
    [] as Array<AccountOwnerSearchResult>
  );

  // These are the account types to show in the 'over 73' summary section
  const over73RelevantAccountTypes = [
    AccountType.traditionalIra,
    AccountType.traditionalSep,
    AccountType.simpleIra,
  ];

  const turningOrOver73OwnersMap = useMemo(
    () =>
      // Reduce it into a sorted map of at or over 73 open accounts with account type as the keys e.g. {SIMPLE_IRA: ...<Array>, ROTH_IRA: ...<Array}
      turningOrOver73Owners.reduce(
        (map, result) => ({
          ...map,
          [result.account.accountType]: [
            ...(map[result.account.accountType] || []),
            result,
          ],
        }),
        {}
      ),
    [turningOrOver73Owners]
  );

  const pieData = Object.entries(turningOrOver73OwnersMap).map(
    ([key, value], i) => ({
      name: getAppConstant(ConstantsMappingKey.accountType, key),
      value: value.length,
      color: darken(
        theme.palette.secondary.light,
        i / Object.keys(turningOrOver73OwnersMap).length
      ),
    })
  );

  const ownerResponsibleElections = relevantRecurringDistributions.filter(
    ({ recurringDistribution }) => recurringDistribution.ownerResponsible
  );

  const rmdElections = relevantRecurringDistributions.filter(
    ({ recurringDistribution }) => {
      return (
        recurringDistribution.distributionAmountType ===
          DistributionChoices.rmd 
      );
    }
  );

  const specificElections = relevantRecurringDistributions.filter(
    ({ recurringDistribution }) =>
      recurringDistribution.distributionAmountType ===
      DistributionChoices.amount
  );

  const defaultElections = relevantRecurringDistributions.filter(
    ({ recurringDistribution }) => recurringDistribution.createdByDefault
  );

  // filter out accounts that do not have an rmd election and also make sure that duplicate account ids are not counted in the table.
  // if an individual does have an rmd but the date is in the future add it to noneElection
  const noneElections: Array<AccountOwnerSearchResult> =
    turningOrOver73Owners.filter((result) => {
      return !relevantRecurringDistributions.some((election) => {
        return election.account.accountId === result.account.accountId;
      });
    });

  // find accounts that have createdByDefault: true
  const createdDefaultElections = relevantRecurringDistributions.filter(
    ({ recurringDistribution }) => recurringDistribution.createdByDefault
  );

  const goToAccountProfile = (result: any): void => {
    const { accountOwnerId = '', accountId = '' } = result || {};

    // Link to account owner and preselect the passed account
    if (accountOwnerId && accountId) {
      navigate(`/accountOwner/${accountOwnerId}/account/${accountId}`);
    }
  };

  async function fetchAndSetRelevantRMDs(): Promise<void> {
    await getDashboardRecurringDistributions(
      user.organizationId,
      [RecurringDistributionStatus.active],
      user.token
    )
      .then(({ data }) => {
        if (isMounted) {
          const relevant = data.filter(
            ({ accountOwner, account, recurringDistribution }) => {
              const { accountType } = account;
              const { dateOfBirth, dateOfDeath } = accountOwner;
              const { currently73Years, over73, turning73ThisYear } =
                determineAgeGroup(dateOfBirth);
              const isEndDate =
                recurringDistribution.endDate &&
                new Date(recurringDistribution.endDate).getFullYear() <
                  new Date().getFullYear();

              const isActivelyDistributing = recurringDistribution.distributionStatus === RecurringDistributionStatus.active;

              return (
                isActivelyDistributing && !dateOfDeath && // Exclude deceased owners
                (turning73ThisYear || currently73Years || over73) && // Turning 73 or older
                !isEndDate && // any distribution with a startdate less than or equal to current year
                over73RelevantAccountTypes.includes(accountType) // Limit account types
              );
            }
          );
          setRelevantRecurringDistributions(relevant);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization RMD elections'
        );
      });

    setIsLoadingRMDs(false);
  }

  async function fetchAndSetRelevantAccounts(): Promise<void> {
    await searchAccountOwner({ query: '*' }, user.organizationId, user.token)
      .then(({ data }) => {
        if (isMounted) {
          setTurningOrOver73Owners(
            data.filter(
              ({
                account: { accountStatus, accountType } = {},
                accountOwner: { dateOfBirth, dateOfDeath },
              }) => {
                const { over73, currently73Years, turning73ThisYear } =
                  determineAgeGroup(dateOfBirth);

                return (
                  !dateOfDeath && // Exclude deceased owners
                  accountStatus === AccountStatus.open &&
                  (over73 || currently73Years || turning73ThisYear) &&
                  over73RelevantAccountTypes.includes(accountType)
                );
              }
            )
          );

          fetchAndSetRelevantRMDs();
        }
      })
      .catch(() => {
        addGlobalMessage('Error fetching account owner search results');
      });

    setIsLoadingAccounts(false);
  }

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              goToAccountProfile(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
    },
    {
      hideable: true, // The following are needed in CSV but don't show them in the UI
      field: 'addressLine1',
      headerName: 'Address Line 1',
    },
    {
      hideable: true,
      field: 'addressLine2',
      headerName: 'Address Line 2',
    },
    {
      hideable: true,
      field: 'city',
      headerName: 'City',
    },
    {
      hideable: true,
      field: 'state',
      headerName: 'State',
    },
    {
      hideable: true,
      field: 'zip',
      headerName: 'Zip Code',
    },
    {
      hideable: true,
      field: 'country',
      headerName: 'Country',
    },
  ];

  useEffect(() => {
    if (user.organizationId && user.token) {
      setIsLoadingRMDs(true);
      setIsLoadingAccounts(true);
      fetchAndSetRelevantAccounts();
      fetchAndSetRelevantRMDs();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Recurring Distributions
          </Typography>

          <Typography color="secondary" variant="h1">
            RMD Summary
          </Typography>

          <Typography gutterBottom>{legalName}</Typography>

          {/* 73 and Older Summary */}
          <Grid container spacing={3} wrap="wrap" alignItems="center" mt={5}>
            <Grid item xs={12}>
              <Typography variant="h5">Owners Turning 73 or Older</Typography>
            </Grid>
            {/* Total Count */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                filter: isLoadingAccounts ? 'blur(5px)' : '',
                transition: 'filter 0.33s ease-in-out',
              }}
            >
              <Typography variant="h2">
                {turningOrOver73Owners.length}
              </Typography>
              <Typography>
                of all open{' '}
                {Object.keys(turningOrOver73OwnersMap)
                  .map((accountType) =>
                    getAppConstant(ConstantsMappingKey.accountType, accountType)
                  )
                  .join(', ')}{' '}
                accounts combined with {legalName} have owners that are turning
                73 this year or are older and consist of the following
                elections:
              </Typography>
            </Grid>

            {/* Account Distribution Pie Chart */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                filter: isLoadingAccounts ? 'blur(5px)' : '',
                transition: 'filter 0.33s ease-in-out',
              }}
            >
              <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item>
                  <PieChart width={140} height={140}>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={pieData}
                      cx="50%"
                      cy="50%"
                      outerRadius={70}
                    >
                      {pieData &&
                        pieData.map(({ value, color }) => (
                          <Cell key={value} fill={color} />
                        ))}
                    </Pie>
                  </PieChart>
                </Grid>

                <Grid item xs={12}>
                  {pieData.map(({ name, value, color }) => (
                    <Typography key={name} variant="body2" gutterBottom>
                      <Box
                        component="span"
                        mr={1}
                        display="inline-block"
                        width={10}
                        height={10}
                        bgcolor={color}
                      />
                      {name}: {value}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Elections' Summary */}
          <Grid
            sx={{
              filter: isLoadingRMDs ? 'blur(5px)' : '',
              transition: 'filter 0.33s ease-in-out',
            }}
            container
            spacing={3}
            mt={2}
            alignItems="baseline"
          >
            <Container maxWidth="xs">
              <Grid container spacing={3} mt={2} mb={2} alignItems="baseline">
                <Grid item xs={6}>
                  <ResponsivePercentBar
                    vertical
                    legend="Default RMD"
                    noun="election"
                    quantity={defaultElections.length}
                    percent={
                      (defaultElections.length /
                        relevantRecurringDistributions.length) *
                        100 || 0
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <ResponsivePercentBar
                    vertical
                    legend="Has Signature"
                    noun="election"
                    quantity={
                      relevantRecurringDistributions.length -
                      defaultElections.length
                    }
                    percent={
                      ((relevantRecurringDistributions.length -
                        defaultElections.length) /
                        relevantRecurringDistributions.length) *
                        100 || 0
                    }
                  />
                </Grid>
              </Grid>
            </Container>

            <Grid item xs={12}>
              {/* Owner Responsible */}
              <Accordion elevation={2}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ResponsivePercentBar
                    legend="Owner Responsible"
                    noun="election"
                    quantity={ownerResponsibleElections.length}
                    percent={
                      (ownerResponsibleElections.length /
                        (noneElections.length + // Adding these two together gives an accurate divisor when a single account has > 1 election
                          relevantRecurringDistributions.length)) *
                        100 || 0
                    }
                  />
                </AccordionSummary>

                <AccordionDetails>
                  <DataGrid
                    components={{
                      NoRowsOverlay: SiraNoRowsOverlay,
                      Toolbar: DataGridExportToolbar,
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={globalPaginationOptions}
                    onPaginationModelChange={setGlobalPageSize}
                    disableColumnMenu
                    autoHeight
                    columns={columns}
                    rows={ownerResponsibleElections.map(
                      ({ account, accountOwner }, id) => ({
                        ...account,
                        ...accountOwner,
                        id,
                      })
                    )}
                  />
                </AccordionDetails>
              </Accordion>

              {/* RMD Amount */}
              <Accordion elevation={2}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ResponsivePercentBar
                    legend="RMD Amount"
                    noun="election"
                    quantity={rmdElections.length}
                    percent={
                      (rmdElections.length /
                        (noneElections.length +
                          relevantRecurringDistributions.length)) *
                        100 || 0
                    }
                  />
                </AccordionSummary>

                <AccordionDetails>
                  <DataGrid
                    components={{
                      NoRowsOverlay: SiraNoRowsOverlay,
                      Toolbar: DataGridExportToolbar,
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={globalPaginationOptions}
                    onPaginationModelChange={setGlobalPageSize}
                    disableColumnMenu
                    autoHeight
                    columns={columns}
                    rows={rmdElections.map(({ account, accountOwner }, id) => ({
                      ...account,
                      ...accountOwner,
                      id,
                    }))}
                  />
                </AccordionDetails>
              </Accordion>

              {/* Specific Amount */}
              <Accordion elevation={2}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ResponsivePercentBar
                    legend="Specific Amount"
                    noun="election"
                    quantity={specificElections.length}
                    percent={
                      (specificElections.length /
                        (noneElections.length +
                          relevantRecurringDistributions.length)) *
                        100 || 0
                    }
                  />
                </AccordionSummary>

                <AccordionDetails>
                  <DataGrid
                    components={{
                      NoRowsOverlay: SiraNoRowsOverlay,
                      Toolbar: DataGridExportToolbar,
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={globalPaginationOptions}
                    onPaginationModelChange={setGlobalPageSize}
                    disableColumnMenu
                    autoHeight
                    columns={columns}
                    rows={specificElections.map(
                      ({ account, accountOwner }, id) => ({
                        ...account,
                        ...accountOwner,
                        id,
                      })
                    )}
                  />
                </AccordionDetails>
              </Accordion>

              {/* No Election */}
              <Accordion elevation={2}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ResponsivePercentBar
                    legend="No Election"
                    noun="election"
                    quantity={noneElections.length}
                    percent={
                      (noneElections.length /
                        (noneElections.length +
                          relevantRecurringDistributions.length)) *
                        100 || 0
                    }
                  />
                </AccordionSummary>

                <AccordionDetails>
                  <DataGrid
                    components={{
                      NoRowsOverlay: SiraNoRowsOverlay,
                      Toolbar: DataGridExportToolbar,
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={globalPaginationOptions}
                    onPaginationModelChange={setGlobalPageSize}
                    disableColumnMenu
                    autoHeight
                    columns={columns}
                    rows={noneElections.map((result, id) => ({
                      ...result.account,
                      ...result.accountOwner,
                      id,
                    }))}
                  />
                </AccordionDetails>
              </Accordion>

              {/* Defaulted Election */}
              <Accordion elevation={2}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ResponsivePercentBar
                    legend="Defaulted Election"
                    noun="election"
                    quantity={createdDefaultElections.length}
                    percent={
                      (createdDefaultElections.length /
                        (createdDefaultElections.length +
                          relevantRecurringDistributions.length)) *
                        100 || 0
                    }
                  />
                </AccordionSummary>

                <AccordionDetails>
                  <DataGrid
                    components={{
                      NoRowsOverlay: SiraNoRowsOverlay,
                      Toolbar: DataGridExportToolbar,
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={globalPaginationOptions}
                    onPaginationModelChange={setGlobalPageSize}
                    disableColumnMenu
                    autoHeight
                    columns={columns}
                    rows={createdDefaultElections.map((result, id) => ({
                      ...result.account,
                      ...result.accountOwner,
                      id,
                    }))}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </>
      </FormPaper>
    </Layout>
  );
}
export default ReportsRmdSummary;
