import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Button, Collapse } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';
import {
  CompoundingFrequency,
  EarlyWithdrawalOption,
  InvestmentRate,
  TISDocumentMapping,
} from '../../../api/InvestmentRateApi.d';
import SiraPercentField from '../SiraPercentField';
import SiraCheckbox from '../SiraCheckbox';
import SiraCurrencyField from '../SiraCurrencyField';
import SiraSelectField from '../SiraSelectField';
import {
  compoundingFrequencyOptions,
  earlyWithdrawalOptions,
} from '../../../app.constants';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraFileField from '../SiraFileField';
import { useUser } from '../../../auth/useUser';
import { getDocumentMappings } from '../../../api/InvestmentRateApi';
import { errorMessages } from '../../../utils/errorhandling.utils';
import { makeStyles } from '@mui/styles';

export const INVESTMENT_RATE_INIT: InvestmentRate = {
  description: '',
  term: '',
  interestRate: 0,
  apy: 0,
  minimumInvestment: 0,
  active: false,
  enrollmentFee: '',
  annualFee: '',
  earlyWithdrawalFee: '',
  earlyWithdrawalOption: '' as EarlyWithdrawalOption,
  additionalFeeDescription: '',
  additionalFeeAmount: '',
  compoundingFrequency: '' as CompoundingFrequency,
  defaultInvestment: false,
  truthInSavingsDocument: '',
  importFiles: null,
};

export const INVESTMENT_RATE_SCHEMA = yup.object({
  apy: yup.number().required().min(0).label('APY'),
  description: yup.string().required().max(40).label('Description'),
  interestRate: yup.number().required().max(100).min(0).label('Interest Rate'),
  minimumInvestment: yup.number().required().min(0).label('Minimum Investment'),
  term: yup.number().required().min(0).max(9999).label('Term'),
  enrollmentFee: yup.string().label('Enrollment Fee'),
  annualFee: yup.string().label('Annual Fee'),
  earlyWithdrawalFee: yup.string().label('Early Withdrawal Fee'),
  earlyWithdrawalOption: yup.string().label('EarlyWithdrawal Option'),
  additionalFeeDescription: yup.string().label('Additional Fee Description'),
  additionalFeeAmount: yup
    .string()
    .when('additionalFeeDescription', (additionalFeeDescription, schema) =>
      additionalFeeDescription ? schema.required() : schema,
    )
    .label('Additional Fee Amount'),
  compoundingFrequency: yup.string().label('Compounding Frequency'),
});

export interface InvestmentRateFormProps {
  initialValues: InvestmentRate;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}

function InvestmentRateForm({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
}: InvestmentRateFormProps) {
  const { organization, addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const [tisDocumentMappingText, setTisDocumentMappingText] = React.useState(
    [] as Array<TISDocumentMapping>,
  );
  const [showMore, setShowMore] = useState(false);
  const useStyles = makeStyles((theme) => ({
    button: {
      textTransform: 'none', // Prevents all caps
    },
  }));
  const classes = useStyles();

  const retrieveTISDocumentMAppingText = async (token): Promise<void> => {
    await getDocumentMappings(token, user)
      .then((response) => {
        const { data } = response;
        setTisDocumentMappingText(data);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error retrieving TIS Document Mapping Text',
        );
      });
  };

  const renderMappings = () => {
    return tisDocumentMappingText.map((mapping, idx) => {
      const uniqueKey = `${mapping.item}-${idx}`;
      return (
        <Typography
          key={uniqueKey}
          variant="body2"
          sx={{ fontStyle: 'italic', padding: '0.5rem' }}
        >
          {mapping.item}: {mapping.description}
        </Typography>
      );
    });
  };

  useEffect(() => {
    if (user.organizationId) {
      retrieveTISDocumentMAppingText(user.token);
    }
  }, [user.organizationId, user.token]);

  return (
    <section>
      <Formik
        initialValues={{
          ...initialValues,
          // Format these values as strings for form input to support trailing zeros
          apy: initialValues.apy.toFixed(organization.investmentRatePrecision),
          interestRate: initialValues.interestRate.toFixed(
            organization.investmentRatePrecision,
          ),
        }}
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
        onReset={() => onReset()}
        validationSchema={INVESTMENT_RATE_SCHEMA}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          const { earlyWithdrawalOption } = values;

          return (
            <Form>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SiraTextField name="description" label="Description" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SiraTextField
                      name="term"
                      type="number"
                      label="Term in Months"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <SiraCurrencyField
                      name="minimumInvestment"
                      label="Minimum Investment"
                      allowNegative={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraPercentField
                      name="interestRate"
                      label="Interest Rate"
                      decimalScale={organization.investmentRatePrecision}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraPercentField
                      name="apy"
                      label="APY"
                      decimalScale={organization.investmentRatePrecision}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraCurrencyField
                      name="enrollmentFee"
                      label="Enrollment Fee"
                      allowNegative={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraCurrencyField
                      name="annualFee"
                      label="Annual Fee"
                      allowNegative={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {earlyWithdrawalOption !==
                    EarlyWithdrawalOption.dollarAmount ? (
                      <SiraTextField
                        name="earlyWithdrawalFee"
                        type="number"
                        label="Early Withdrawal Fee"
                      />
                    ) : (
                      <SiraCurrencyField
                        name="earlyWithdrawalFee"
                        label="Early Withdrawal Fee"
                        allowNegative={false}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraSelectField
                      items={earlyWithdrawalOptions}
                      name="earlyWithdrawalOption"
                      label="Early Withdrawal Option"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <SiraTextField
                      name="additionalFeeDescription"
                      label="Additional Fee"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SiraCurrencyField
                      name="additionalFeeAmount"
                      label="Additional Fee Amount"
                      allowNegative={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraSelectField
                      items={compoundingFrequencyOptions}
                      name="compoundingFrequency"
                      label="Compounding Frequency"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="active"
                      label="Active"
                      type="checkbox"
                      component={SiraCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="defaultInvestment"
                      label="Default"
                      type="checkbox"
                      component={SiraCheckbox}
                      additionText="( Note: the default investment should be the lowest rate
                          you offer for IRAs )"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Truth in Savings Document Mapping Fields
                    </Typography>
                    <Typography variant="body2">
                      Upload a truth-in-savings (TIS) document to be included
                      whenever a user creates a new account with this type of
                      investment. You may upload a static TIS document for the
                      investment or click Show More for instructions to add data
                      fields to the TIS document that automatically
                      populate/update based on the information entered for the
                      transaction/investment.
                    </Typography>
                    {/* Additional entries with animation */}
                    <Collapse in={showMore} timeout="auto" unmountOnExit>
                      {renderMappings()}
                    </Collapse>
                    {/* More link */}
                    <Button
                      className={classes.button}
                      onClick={() => setShowMore(!showMore)}
                    >
                      <Typography variant="body1" fontStyle={'italic'}>
                        {showMore ? 'Show Less' : '...Show More'}
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={1}>
                      <SiraFileField
                        onDropField={(docs) => {
                          setFieldValue('importFiles', docs);
                        }}
                        name="importFiles"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <StepButtonBar
                isSubmitting={isSubmitting}
                submitName={submitName}
                resetName={resetName}
                onCancel={onCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}

export default InvestmentRateForm;
