import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings } from './api.utils';
import {
  ExternalAccount,
  ExternalAccountResponse,
} from './ExternalAccountApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const externalAccountUrl =
  '/api/org/{0.orgId}/externalAccountOwnerAccount/{0.externalAccountOwnerAccountId}';

function externalAccountFactory(data: any): ExternalAccount {
  const {
    externalAccountOwnerAccountId,
    firstName,
    lastName,
    middleInitial,
    taxpayerIdNumber,
    dateOfBirth,
    dateOfDeath,
    accountType,
    accountNumber,
  } = data;

  return {
    externalAccountOwnerAccountId,
    firstName,
    lastName,
    middleInitial,
    taxpayerIdNumber,
    dateOfBirth,
    dateOfDeath,
    accountType,
    accountNumber,
  };
}

export async function createOrSaveExternalAccount(
  data: ExternalAccount,
  orgId: string,
  token: string
): Promise<ExternalAccountResponse> {
  const { externalAccountOwnerAccountId = '' } = data;
  const url = `${urlBase}${format(externalAccountUrl, {
    orgId,
    externalAccountOwnerAccountId,
  })}`;
  const dataNorm = removeEmptyStrings(externalAccountFactory(data));

  if (externalAccountOwnerAccountId) {
    return axiosInstance.put(url, dataNorm, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getExternalAccount(
  externalAccountOwnerAccountId: string,
  orgId: string,
  token: string
): Promise<ExternalAccountResponse> {
  const url = `${urlBase}${format(externalAccountUrl, {
    orgId,
    externalAccountOwnerAccountId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}