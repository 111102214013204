import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Blocker, Transition } from 'history';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { AccountStatus, TransactionType } from '../api/AccountApi.d';
import { TransactionRedirectModal } from './SiraRedirectModal';

export interface RowDefinition {
  accountId?: string | number;
  accountOwnerId?: number | string;
  accountType?: string;
  date?: string;
  contributionId?: string;
  distributionId?: number;
  recurringDistributionId?: string;
  fullName?: string;
  id?: number;
  transactionId?: number;
  transactionType: TransactionType;
  visibility?: boolean;
  transferRequestId?: string;
  beneficiaryClaimId?: string;
  transactionStatus?: AccountStatus;
}

// Generic blocking hook that can be used to create any blocking prompt
function useBlocker(blocker, unsavedChanges = {}) {
  const { navigator } = useContext(NavigationContext);

  useEffect((): Blocker | any => {
    if (!unsavedChanges) return false;

    const unblock = (navigator as any).block((transition: Transition) => {
      const autoUnblockingTx = {
        ...transition,
        retry() {
          unblock();
          transition.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock; // Unblock navigation when unmounting this component
  }, [navigator, blocker, unsavedChanges]);
}

export function useUnsavedChangesWarning() {
  const [showPrompt, setShowPrompt] = useState(false as boolean);
  const [unsavedChanges, setUnsavedChanges] = useState(null as RowDefinition);
  const [tx, setTx] = useState(null as Transition);

  const blocker = useCallback(
    (transition: Transition) => {
      setShowPrompt(true);

      setTx(transition);
    },
    [unsavedChanges]
  );

  const UnsavedChangesPrompt = showPrompt ? (
    <TransactionRedirectModal
      title="Leave this page?"
      onOK={tx.retry}
      onCancel={() => {
        setShowPrompt(false);
      }}
      transactionValues={unsavedChanges}
    />
  ) : null;

  useBlocker(blocker, unsavedChanges);

  return { UnsavedChangesPrompt, setUnsavedChanges };
}
