import { AccountType } from './AccountApi.d';

export interface TransferRequestResponse {
  data?: TransferRequest;
  errorMessage?: any;
}

export interface TransferStatusRequestResponse {
  data?: Array<TransferRequest>;
  errorMessage?: any;
}
 
export interface TransferRequest {
  accountId?: string;
  activeDate?: string;
  sourceAccountNumber?: string;
  sourceOrgAddressLine1?: string;
  sourceOrgAddressLine2?: string;
  sourceOrgCity?: string;
  contributionCreatedDate?: string;
  sourceOrgName?: string;
  entireAccount?: boolean;
  sourceOrgPhoneNumber?: string;
  sourcePlanName?: string;
  relationshipToCurrentOwner?: TransferRequestRelationship;
  signedDate?: string;
  sourceAccountType?: AccountType;
  sourceOwnerFirstName?: string;
  sourceOwnerLastName?: string;
  sourceOwnerName?: string;
  sourceOwnerMiddleInitial?: string;
  sourceOwnerTaxIdNumber?: string;
  sourceOrgState?: string;
  transferAmount?: string;
  transferRequestId?: string;
  transferRequestStatus?: TransferRequestStatus;
  sourceOrgZip?: string;
  rolloverTransferOccurValue?: TransferRequestOccur;
  transferDateImmediately?: boolean;
  transferDateInvestmentMaturity?: boolean;
  transferDate?: string;
  additionalDetails?: string;
}

export interface TransferAllocationResponse {
  data?: Array<TransferAllocation>;
  errorMessage?: any;
  status?: number;
}

export interface TransferAllocation {
  amount?: string | number;
  percent?: string | number;
  transferRequestAllocationId?: number;
  transferRequestId?: number;
  investmentRateId?: string | number;
  investmentNumber?: string;
}

export interface Workflow {
  effectiveDate?: string;
  signedDate?: string;
  transferAmount?: number | string;
}

export enum TransferRequestStatus {
  signature = 'AWAITING_SIGNATURE',
  awaiting = 'AWAITING_TRANSFER',
  completed = 'COMPLETED',
  pending = 'PENDING',
  review = 'PENDING_REVIEW',
  submitOwner = 'SUBMITTED_BY_OWNER',
}

export enum TransferRequestStatusState {
  next = 'NEXT',
  previous = 'PREVIOUS',
}

export enum TransferRequestRelationship {
  default = '',
  currentOwner = 'CURRENT_OWNER',
  formerSpouse = 'FORMER_SPOUSE',
  nonSpouseBene = 'NON_SPOUSE_BENEFICIARY',
  spouseBene = 'SPOUSE_BENEFICIARY',
  childBene = 'CHILD_BENEFICIARY',
  trustBene = 'TRUST_BENEFICIARY',
  estateBene = 'ESTATE_BENEFICIARY',
  qaulifiedFamily = 'QUALIFIED_FAMILY_MEMBER',
  currentDesBene = 'CURRENT_DESIGNATED_BENEFICIARY',
}

export enum TransferRequestOccur {
  immediately = 'IMMEDIATELY',
  specificDate = 'SPECIFIC_DATE',
  investmentMaturity = 'INVESTMENT_MATURITY',
}
