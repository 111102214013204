import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useUser } from '../auth/useUser';
import { useGlobalContext } from '../auth/useGlobalContext';
import { TaxForm, TaxFormType } from '../api/TaxFormApi.d';
import { getTaxFormInfo } from '../api/TaxFormApi';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { AccountType } from '../api/AccountApi.d';
import { dateValueFormatter } from '../components/form/beneficiariesClaims/resource.utils';
import { errorMessages } from '../utils/errorhandling.utils';
import { getUsers } from '../api/UserApi';
import { User } from '../api/UserApi.d';

export interface TaxInfo {
  accountId: string;
  accountOwnerId: string;
  taxId: string;
  formType: TaxFormType;
  accountType: string;
}

interface TaxFormDetailsProps {
  taxInfo?: TaxInfo;
  clicked?: Function;
}

function TaxForm1099Details(props: TaxFormDetailsProps) {
  let isMounted = true;
  const { taxInfo, clicked = () => {} } = props;
  const { accountId, accountOwnerId, taxId, accountType } = taxInfo;
  const [taxFormProfileInfo, setTaxFormProfileInfo] = useState({} as TaxForm);
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const coverdellEsa =
    taxFormProfileInfo.formType === 'Q' && accountType === AccountType.esa;
  const hsa =
    taxFormProfileInfo.formType === 'SA' && accountType === AccountType.hsa;
    const [userInfo, setUserInfo] = useState('' as string);

    // const coverdellEsa =
    //   taxFormProfileInfo.form1099Type === 'Q' && accountType === AccountType.esa;
    // const hsa =
    //   taxFormProfileInfo.form1099Type === 'SA' && accountType === AccountType.hsa;
  
    
    const setRepInfo = async (sentToOwnerBy): Promise<void> => {
      await getUsers(user.organizationId, user.token, { includeMultiOrgUsers: true })
        .then((res) => {
  
          // find the user that matches the sent to owner by id
          const rep = res.data.find(
            (userValue: User) => userValue.auth0UserId === sentToOwnerBy
          );
          setUserInfo(`${rep.firstName} ${rep.lastName}`);
        })
        .catch(() => {
          if (isMounted) {
            addGlobalMessage('Error fetching organization users');
          }
        });
    };

  async function getTaxForm(): Promise<void> {
    await getTaxFormInfo(
      accountId,
      accountOwnerId,
      taxId,
      user.organizationId,
      user.token,
      user
    )
      .then((responseValue) => {
        setTaxFormProfileInfo(responseValue.data);
        if (responseValue.data.sentToOwnerBy){
          setRepInfo(responseValue.data.sentToOwnerBy);
        }

      })
      .catch((err) => {

        if (isMounted) {
 addGlobalMessage(errorMessages(err) || 'Could not fetch Tax Form Information'
          );
        }
      });
  }

  useEffect(() => {
    if (user.organizationId && user.token) {
      getTaxForm();
    }
    return () => {
      isMounted = false;
    };
  }, [user.token]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Payee Information
          </Typography>
          <Typography variant="body1" color="primary" pt={0.5}>
            {taxFormProfileInfo.legalName}
          </Typography>
          <Typography variant="body1" color="primary">
            {taxFormProfileInfo.mailingAddress}
          </Typography>
          <Typography variant="body1" color="primary">
            {taxFormProfileInfo.city}
            {', '}
            {!taxFormProfileInfo.foreignCountryIndicator &&
              taxFormProfileInfo.state}{' '}
            {taxFormProfileInfo.zipCode}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Distribution Reason
          </Typography>
          <Typography variant="body1" color="primary">
            {getAppConstant(
              ConstantsMappingKey.distributionReason,
              taxFormProfileInfo.distributionReason
            )}
          </Typography>
          <Typography variant="body1" color="primary">
            {getAppConstant(
              ConstantsMappingKey.distributionReasonCode,
              taxFormProfileInfo.distributionReason
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Form Type
          </Typography>
          <Typography variant="body1" color="primary">
            {TaxFormType[taxFormProfileInfo.formType]}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" pt={1}>
            Gross Distribution
          </Typography>
          <Typography variant="body1">
            <NumberFormat
              value={taxFormProfileInfo.distributionAmount}
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Tax Year
          </Typography>
          <Typography variant="body1" color="primary">
            {taxFormProfileInfo.taxYear}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            {coverdellEsa || hsa
              ? `Earnings ${hsa ? 'on excess contribution' : ''}`
              : 'Federal Withholding'}
          </Typography>
          <Typography variant="body1" color="primary">
            <NumberFormat
              value={
                coverdellEsa || hsa
                  ? taxFormProfileInfo.taxableAmount
                  : taxFormProfileInfo.federalTaxWithheld
              }
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Unique ID
          </Typography>
          <Typography variant="body1" color="primary">
            {taxFormProfileInfo.uniqueId}
          </Typography>
        </Grid>

        {!hsa && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              {coverdellEsa ? 'Basis' : 'State Withholding'}
            </Typography>{' '}
            <Typography variant="body1" color="primary">
              <NumberFormat
                value={
                  coverdellEsa
                    ? taxFormProfileInfo.basis
                    : taxFormProfileInfo.stateIncomeTaxWithheld
                }
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
            {(!coverdellEsa || !hsa) &&
              // taxFormProfileInfo.stateIncomeTaxWithheld &&  // Display withholding state if State Income Tax Withheld = $0
              taxFormProfileInfo.formType === 'R' && (
                <Typography variant="body1" color="primary">
                  {taxFormProfileInfo.withholdingState !== 'XX' &&
                    taxFormProfileInfo.withholdingState}
                </Typography>
              )}
            {(!coverdellEsa || !hsa) &&
              taxFormProfileInfo.stateTaxId &&
              taxFormProfileInfo.formType === 'R' && (
                <Typography variant="body1" color="primary">
                  {taxFormProfileInfo.stateTaxId}
                </Typography>
              )}
          </Grid>
        )}

        {hsa && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom pt={1}>
              FMV on date of death
            </Typography>
            <Typography variant="body1" color="primary">
              <NumberFormat
                value={taxFormProfileInfo.dateOfDeathAccountBalance}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Date Sent to Owner
          </Typography>{' '}
          <Typography variant="body1" color="primary">
            {dateValueFormatter(taxFormProfileInfo.dateSentToOwner)}
          </Typography>
        </Grid>

        {!hsa && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom pt={1}>
              {coverdellEsa ? '12/31 FMV' : 'Taxable Amount'}
            </Typography>
            <Typography variant="body1" color="primary">
              <NumberFormat
                value={
                  coverdellEsa
                    ? taxFormProfileInfo.fairMarketValue
                    : taxFormProfileInfo.taxableAmount
                }
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom pt={1}>
            Date Filed With IRS
          </Typography>{' '}
          <Typography variant="body1" color="primary">
            {dateValueFormatter(taxFormProfileInfo.dateFiledWithIrs)}
          </Typography>
        </Grid>
        {!hsa && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={0.5}>
              <Grid item xs={1}>
                <Box pt={1}>
                  {taxFormProfileInfo.trusteeToTrusteeTransfer ||
                  taxFormProfileInfo.totalDistributionIndicator ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </Box>
              </Grid>{' '}
              <Grid item xs={11}>
                <Typography variant="body1" color="primary" pt={1}>
                  {coverdellEsa
                    ? 'Trustee-to-trustee transfer'
                    : 'Total Distribution Indicator'}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Box pt={1}>
                  {taxFormProfileInfo.taxableAmountNotDetermined ||
                  taxFormProfileInfo.designatedBeneficiary ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </Box>
              </Grid>{' '}
              <Grid item xs={8}>
                <Typography variant="body1" color="primary" pt={1}>
                  {coverdellEsa
                    ? 'Recipient is not the designated beneficiary'
                    : 'Taxable Amount Not Determined'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
                {taxFormProfileInfo.sentToOwnerBy ? (
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom pt={1}>
              Sent to Owner By
            </Typography>{' '}
            <Typography variant="body1" color="primary">
              {userInfo}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Button
            aria-label="Go back to account tax forms"
            variant="outlined"
            color="primary"
            onClick={() => {
              clicked();
            }}
            startIcon={<ArrowBackIcon />}
          >
            All Tax Forms
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default TaxForm1099Details;
