import React, { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import format from 'string-format';

import { useUser } from '../../../auth/useUser';
import StepButtonBar from '../../steps/StepButtonBar';
import { getLifeExpectancyTerms } from '../../../api/LifeExpectancyApi';
import { LifeExpectancyTerm } from '../../../api/LifeExpectancyApi.d';
import SiraDateField from '../SiraDateField';
import SiraTextField from '../SiraTextField';

import { determineAgeGroup } from '../../../app.constants';
import { AccountType, Account } from '../../../api/AccountApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import {
  LifeExpectancyTable,
  RecurringDistribution,
  TermChoiceOption,
  TermEndDateAction,
} from '../../../api/RecurringDistributionApi.d';
import SiraRadioField from '../SiraRadioField';
import DistributionTermChoice, {
  parentChoiceLabels,
} from './DistributionTermChoice';
import { errorMessages } from '../../../utils/errorhandling.utils';
import { BeneficiaryTypes } from '../../../api/BeneficiariesApi.d';
import { set } from 'cypress/types/lodash';

export interface DistributionTerm {
  fairMarketValue?: string;
  frequency?: any;
  termLength?: number;
  termChoice?: TermChoiceOption;
  spouseBirthDate?: string;
  ignoreSpouseBirthDate?: boolean;
  fmvInDb?: boolean;
  termEnabled?: TermEnabledOption;
  ownerResponsible?: boolean;
  qualifiedJLE?: boolean;
  termEndDateAction?: TermEndDateAction;
  lifeExpectancy?: number;
  startDate?: string;
  term?: number;
  lifeExpectancyTable?: any;
  distributionAmountType?: any;
}

export interface DistributionTermFormProps {
  account: Account;
  initialValues: DistributionTerm;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  parentDistribution?: RecurringDistribution;
  isEdit?: boolean;
}

export enum TermEnabledOption {
  schedule = 'SCHEDULE',
  owner = 'OWNER_RESPONSIBLE',
}

export const DISTRIBUTION_TERM_INIT: DistributionTerm = {
  fairMarketValue: '',
  termEnabled: '' as TermEnabledOption,
  termLength: 0,
  termChoice: TermChoiceOption.empty,
  spouseBirthDate: '',
  ignoreSpouseBirthDate: false,
  fmvInDb: false,
  ownerResponsible: false,
  termEndDateAction: '' as TermEndDateAction,
};

function calcSchema(
  spouseDefined,
  maxTerm,
  yearsTo73,
  allowOwnerResponsible,
  lifeExpectancy,
  lifeExpectancyFactor
) {
  return yup
    .object({
      termEnabled: allowOwnerResponsible
        ? yup.string().required().label('Term enabled')
        : yup.string().label('Term enabled'),
      termChoice: yup
        .string()
        .label('Term choice')
        .when('termEnabled', (type, schema) =>
          type !== TermEnabledOption.owner ? schema.required() : schema
        ),
    })
    .shape({
      termLength: yup
        .number()
        .label('Term Length')
        .when('termChoice', (type, schema) =>
          [TermChoiceOption.manual].includes(type)
            ? schema
                .required()
                .max(maxTerm || 26.6)
                .min(1)
            : schema
        )
        .when('termChoice', (type, schema) =>
          [TermChoiceOption.before73].includes(type)
            ? schema
                .required()
                .max(yearsTo73 || 13)
                .min(1)
            : schema
        )
        .when('termChoice', (type, schema) =>
          [TermChoiceOption.rothTerm].includes(type)
            ? schema.required().min(1)
            : schema
        )
        .when('termChoice', (type, schema) => {
          if ([TermChoiceOption.rothTermJle].includes(type)) {
            const termYears =
              lifeExpectancyFactor > maxTerm ? lifeExpectancyFactor : maxTerm;
            return schema.required().max(termYears).min(1);
          }
          return schema;
        })
        .when('termChoice', (type, schema) =>
          [TermChoiceOption.inheritedRoth10].includes(type)
            ? schema.required().max(10).min(1)
            : schema
        )
        .when('termChoice', (type, schema) =>
          [TermChoiceOption.inheritedRoth5].includes(type)
            ? schema.required().max(5).min(1)
            : schema
        )
        .when('termChoice', (type, schema) =>
          [TermChoiceOption.inheritedTrad10].includes(type)
            ? schema.required().max(10).min(1)
            : schema
        )
        .when('termChoice', (type, schema) =>
          [TermChoiceOption.inheritedTrad5].includes(type)
            ? schema.required().max(5).min(1)
            : schema
        ),
      spouseBirthDate: yup
        .string()
        .label('Birth Date')
        .when('termChoice', (type, schema) =>
          [TermChoiceOption.jle].includes(type) && !spouseDefined
            ? schema.required()
            : schema
        ),
      lifeExpectancy: yup
        .number()
        .label('Life Expectancy')
        .when('termChoice', (type, schema) =>
          [
            TermChoiceOption.rothTermInherited,
            TermChoiceOption.tradInherited,
          ].includes(type)
            ? schema.required().max(lifeExpectancy).min(1)
            : schema
        ),
    });
  // TODO: add in more for term length when user less than 59.5
}

const validAccountTypes: Array<AccountType> = [
  AccountType.traditionalIra,
  AccountType.traditionalSep,
  AccountType.simpleIra,
  AccountType.rothIra,
  AccountType.inheritedRoth,
  AccountType.inheritedTraditional,
];

const ownerResponsibleAccountTypes: Array<AccountType> = [
  AccountType.traditionalIra,
  AccountType.traditionalSep,
  AccountType.simpleIra,
];

const inheritedAccountTypes: Array<AccountType> = [
  AccountType.inheritedRoth,
  AccountType.inheritedTraditional,
];

// Option for owner responsible election
const termEnabledOptions = [
  {
    label: 'Establish a schedule to distribute money from this account',
    value: TermEnabledOption.schedule,
    afterContent: (
      <Typography variant="body2">
        The financial organization will automatically distribute the money
        according to the schedule determined by the IRA owner.
      </Typography>
    ),
  },
  {
    label:
      "Do not schedule distributions to satisfy the account owner's RMD each year",
    value: TermEnabledOption.owner,
    afterContent: (
      <Typography variant="body2">
        The account owner must still receive the RMD for this IRA but will be
        responsible for withdrawing the RMD every year instead of having the
        financial organization automatically distribute the amount.
      </Typography>
    ),
  },
];

function DistributionTermForm({
  account,
  initialValues,
  onSubmit,
  onReset = () => {},
  onCancel = () => {},
  submitName,
  resetName,
  parentDistribution = {},
  isEdit = false,
}: DistributionTermFormProps) {
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { accountId, accountType, accountOwnerId, relationship } =
    account as Account;
  const [isMounted, setMounted] = React.useState(true);
  const [loading, setLoading] = useState(true);
  const [hasInitialized, setHasinitialized] = useState(false as boolean);
  const [initialTermInfo, setInitialTermInfo] = useState(initialValues);
  const [spouseDefined, setSpouseDefined] = useState(true);
  const isValidAccountType = validAccountTypes.includes(accountType);
  const [lifeExpectancyTerms, setLifeExpectancyTerms] = React.useState({
    accountOwner: { dateOfBirth: '' },
  } as LifeExpectancyTerm);
  const { accountOwner = {} } = lifeExpectancyTerms;
  const {
    currently73Years,
    over73,
    turning73ThisYear,
    underHalf59,
    yearsTo73,
  } = determineAgeGroup(accountOwner.dateOfBirth);

  const allowOwnerResponsible =
    (accountOwner.dateOfBirth &&
      (currently73Years || turning73ThisYear || over73) &&
      ownerResponsibleAccountTypes.includes(accountType)) ||
    inheritedAccountTypes.includes(accountType);

  const isValidAccount = [
    AccountType.traditionalIra,
    AccountType.traditionalSep,
    AccountType.simpleIra,
  ].includes(account.accountType);
  const validAge = currently73Years || turning73ThisYear || over73;
  // Get life expectancy for current owner
  async function fetchLifeExpectancy() {
    setLoading(true);

    if (accountId) {
      await getLifeExpectancyTerms(
        user.organizationId,
        accountId,
        accountOwnerId,
        '',
        '',
        user.token,
        user
      )
        .then((response) => {
          const { primaryBeneficiaries = [], fairMarketValue } = response.data;

          if (isMounted) {
            setLifeExpectancyTerms(response.data);

            if (fairMarketValue) {
              setInitialTermInfo({
                ...initialTermInfo,
                fairMarketValue: fairMarketValue.fairMarketValue,
                fmvInDb: true,
              });
            }
            setSpouseDefined(Boolean(primaryBeneficiaries.length));
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          addGlobalMessage(
            errorMessages(err) ||
              'Unexpected problem loading Amortization Calculation'
          );
        });
    }

    setLoading(false);
  }

  useEffect(() => {
    if (account.accountId && account.accountOwnerId) {
      fetchLifeExpectancy();
    }

    return () => {
      setLoading(loading ? false : loading);
      setMounted(false);
    };
  }, [
    user.token,
    user.organizationId,
    account.accountId,
    account.accountOwnerId,
  ]);

  const dateFieldLabel = () => {
    let field = '';

    if (underHalf59) {
      field = 'Oldest Primary Beneficiary’s Birth Date';
    } else if (over73 || currently73Years || turning73ThisYear) {
      field = `Spouse's Birth Date`;
    }

    return field;
  };

  return !loading && isValidAccountType ? (
    <Box p={2}>
      <Formik
        initialValues={{
          ...initialValues,
          ...initialTermInfo,
        }}
        enableReinitialize
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
        onReset={() => onReset()}
        validationSchema={calcSchema(
          spouseDefined,
          lifeExpectancyTerms?.uniformLifetimeRMD?.distributionPeriod,
          yearsTo73,
          allowOwnerResponsible,
          relationship !== BeneficiaryTypes.SPOUSE
            ? lifeExpectancyTerms?.singleLifeExpectancyNonRecalculatedRMD
                ?.lifeExpectancy
            : lifeExpectancyTerms?.singleLifeExpectancyRMD?.lifeExpectancy,
          lifeExpectancyTerms?.jointLifeExpectancyRMD?.lifeExpectancyFactor
        )}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          const showScheduleOptions =
            values.termEnabled === TermEnabledOption.schedule;
          const showlifeExpectancyOption =
            [
              TermChoiceOption.rothTermInherited,
              TermChoiceOption.tradInherited,
            ].includes(values.termChoice as TermChoiceOption) &&
            [
              AccountType.inheritedRoth,
              AccountType.inheritedTraditional,
            ].includes(accountType);
          // Term choice value Trad_Term(manual) is not being used currently in term choice selections
          const showTermLengthOption = [
            TermChoiceOption.manual,
            TermChoiceOption.before73,
            TermChoiceOption.rothTerm,
            TermChoiceOption.rothTermJle,
            TermChoiceOption.inheritedRoth10,
            TermChoiceOption.inheritedRoth5,
            TermChoiceOption.inheritedTrad10,
            TermChoiceOption.inheritedTrad5,
          ].includes(values.termChoice as TermChoiceOption);

          // Reset fields when disabling distribution scheduling
          useEffect(() => {
            if (hasInitialized) {
              if (values.termEnabled === TermEnabledOption.owner) {
                setFieldValue('termChoice', TermChoiceOption.empty);
              }
            } else {
              setHasinitialized(true);
            }

            setFieldValue(
              'ownerResponsible',
              values.termEnabled === TermEnabledOption.owner
            );

            if (relationship !== BeneficiaryTypes.SPOUSE) {
              setFieldValue(
                'lifeExpectancy',
                lifeExpectancyTerms?.singleLifeExpectancyNonRecalculatedRMD
                  ?.lifeExpectancy
              );
            } else {
              setFieldValue(
                'lifeExpectancy',
                lifeExpectancyTerms?.singleLifeExpectancyRMD?.lifeExpectancy
              );
            }
            setFieldValue(
              'termLength',
              lifeExpectancyTerms?.singleLifeExpectancyRMD?.lifeExpectancy
            );
          }, [values.termEnabled]);

          useEffect(() => {
            let termYears = 0;
            if (isValidAccount && validAge) {
              termYears =
                lifeExpectancyTerms?.jointLifeExpectancyRMD
                  ?.lifeExpectancyFactor >
                lifeExpectancyTerms?.uniformLifetimeRMD.distributionPeriod
                  ? lifeExpectancyTerms?.jointLifeExpectancyRMD
                      .lifeExpectancyFactor
                  : lifeExpectancyTerms?.uniformLifetimeRMD.distributionPeriod;

              setFieldValue('termLength', termYears);
            }

            if (relationship !== BeneficiaryTypes.SPOUSE) {
              setFieldValue(
                'lifeExpectancy',
                lifeExpectancyTerms?.singleLifeExpectancyNonRecalculatedRMD
                  ?.lifeExpectancy
              );
            } else {
              setFieldValue(
                'lifeExpectancy',
                lifeExpectancyTerms?.singleLifeExpectancyRMD?.lifeExpectancy
              );
            }
          }, [values.termChoice]);

          // Set TermLength as lifeExpectancy value
          useEffect(() => {
            if (values.lifeExpectancy > 0) {
              setFieldValue('termLength', values.lifeExpectancy);
            }
          }, [values.lifeExpectancy]);

          useEffect(() => {
            if (isEdit) {
              if (initialValues.ownerResponsible) {
                setFieldValue('termEnabled', TermEnabledOption.owner);
              } else {
                setFieldValue('termEnabled', TermEnabledOption.schedule);
                if (
                  initialValues.lifeExpectancyTable === LifeExpectancyTable.ule
                ) {
                  setFieldValue('termChoice', 'UNIVERSAL_LIFETIME');
                } else if (
                  initialValues.lifeExpectancyTable === LifeExpectancyTable.jle
                ) {
                  setFieldValue('termChoice', 'JOINT_LIFE_EXPECTANCY');
                } else {
                  setFieldValue('termChoice', TermChoiceOption.rothTermJle);
                  setFieldValue('termLength', initialValues.term);
                }
              }
              setFieldValue('recurringSelection', initialValues.distributionAmountType);
              setFieldValue('frequency', initialValues.frequency);
            }
          }, [initialValues]);

          return (
            <Form>
              <Grid container spacing={3}>
                {allowOwnerResponsible && (
                  <Grid item xs={12}>
                    <Field
                      name="termEnabled"
                      options={termEnabledOptions}
                      component={SiraRadioField}
                    />
                  </Grid>
                )}

                {values.termEnabled === TermEnabledOption.owner && (
                  <Grid item xs={12} sm={10}>
                    <Box mt={2} mb={3}>
                      <SiraDateField name="startDate" label="Effective Date" />
                    </Box>
                  </Grid>
                )}

                {(showScheduleOptions || !allowOwnerResponsible) && (
                  <>
                    {parentDistribution.termChoice && (
                      <Grid item xs={12}>
                        <Alert severity="info">
                          The previous selection was{' '}
                          {format(
                            parentChoiceLabels[parentDistribution.termChoice],
                            { term: parentDistribution.term }
                          )}
                        </Alert>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <DistributionTermChoice
                        account={account}
                        initialTerms={lifeExpectancyTerms}
                      />
                    </Grid>
                    {showlifeExpectancyOption && (
                      <Grid item xs={6}>
                        <SiraTextField
                          name="lifeExpectancy"
                          label="Life Expectancy (in years)"
                          type="number"
                        />
                      </Grid>
                    )}
                    {values.termChoice === TermChoiceOption.jle &&
                      !spouseDefined && (
                        <>
                          <Grid item xs={6}>
                            <SiraDateField
                              data-qa="spouseBirthDate"
                              name="spouseBirthDate"
                              label={dateFieldLabel()}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Alert severity="warning" variant="outlined">
                              If {dateFieldLabel().toLocaleLowerCase()} is not
                              more than 10 years younger than the account
                              owner’s birth date, the Uniform Lifetime Table
                              will be used to calculate the owner’s RMD
                            </Alert>
                          </Grid>
                        </>
                      )}
                    <Grid item xs={6}>
                      {showTermLengthOption && (
                        <SiraTextField
                          name="termLength"
                          label="Term Length (in years)"
                          type="number"
                        />
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              <StepButtonBar
                isSubmitting={isSubmitting}
                submitName={submitName}
                resetName={resetName}
                onCancel={onCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  ) : (
    <Box width="1">
      {loading && <LinearProgress color="secondary" />}
      {!isValidAccountType && (
        <h4>Recurring Distributions not supported for this account</h4>
      )}
    </Box>
  );
}

export default DistributionTermForm;
