import React from 'react';
import { Box, Grid, Typography, Chip, Select, MenuItem } from '@mui/material';

import { SiraSelectItem } from './SiraSelectField';

interface ReportFMVSelectFieldProps {
  onChange: Function;
  reportFMVFilter: Array<ReportFMVFilter>;
  taxYear: string;
}

export enum ReportFMVFilter {
  noFmv = 'NO_FMV',
  hasFMV = 'HAS_FMV',
  hasTaxYearDoD = 'HAS_TAXYEAR_DOD',
  hasCurrentDoD = 'HAS_CURRENTYEAR_DOD',
}

function ReportFMVSelectField(props: ReportFMVSelectFieldProps) {
  const { onChange = () => {}, reportFMVFilter, taxYear } = props;

  // Allow the filter items to be selected or deselected
  const handleReportFMVFilterSelect = (fmvFilter: Array<ReportFMVFilter>) => {
    onChange(fmvFilter);
  };

  const fmvReportSupportedFilter = [
    { value: 'NO_FMV', label: '$0 or no FMV' },
    { value: 'HAS_FMV', label: `Has ${taxYear} FMV` },
    { value: 'HAS_TAXYEAR_DOD', label: `Has ${taxYear} Date of Death` },
    {
      value: 'HAS_CURRENTYEAR_DOD',
      label: `Has ${new Date().getFullYear()} Date of Death`,
    },
  ];

  // Allow the chip  in the filter to be deleted
  const handleReportFMVFilterDelete = (valueToExclude): void => {
    onChange(reportFMVFilter.filter((value) => value !== valueToExclude));
  };

  const filterOptions: Array<SiraSelectItem> = fmvReportSupportedFilter;

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      <Grid item>
        <Typography noWrap variant="body2">
          Show FMV filter options:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          onChange={(e) => {
            handleReportFMVFilterSelect(
              e.target.value as Array<ReportFMVFilter>
            );
          }}
          value={reportFMVFilter}
          multiple
          renderValue={(selected: Array<any>) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const { label = '' } =
                  fmvReportSupportedFilter.find(
                    ({ value: valueTolookup }) => value === valueTolookup
                  ) || value;
                return (
                  <Chip
                    key={value}
                    label={label}
                    onDelete={() => {
                      handleReportFMVFilterDelete(value);
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation(); // Prevents clicking the chip from opening the select box
                    }}
                  />
                );
              })}
            </Box>
          )}
        >
          {filterOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}

export default ReportFMVSelectField;
