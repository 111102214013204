import React, {  useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  useTheme, 
} from '@mui/material';
import { Formik, Form, useFormikContext, FieldArray } from 'formik';

import TransactionSubStepper, {
  TransactionSubStep,
} from '../../../page/TransactionSubStepper';
import StepButtonBar from '../../steps/StepButtonBar';
import { BeneficiariesClaimFiles } from './BeneficiariesClaimsForm.d';
import { ClaimDocumentType } from '../../../api/BeneficiaryClaimsApi.d';
import { addTransactionData } from '../../../page/TransactionReducer';
import SiraTextField from '../SiraTextField';
import SiraFileField from '../SiraFileField';
import { setImageIcon } from './resource.utils';

export const BENEFICIARIES_ADDITIONAL_INFO_INIT: BeneficiariesClaimFiles = {
  files: null,
  additionalFiles: null,
  additionalfileProperties: null,
};

export interface BeneficiariesClaimsFormProps {
  onSubmit?: Function;
  initialValues?: BeneficiariesClaimFiles;
}

function BeneficiariesComments() {
  return (
    <Box mt={5} mb={3} mr={3} maxWidth={500}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2">
            Examples: Updated beneficiary addresses or other pertinent
            information for processing the claim.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SiraTextField
            name="comments"
            label="Additional comments"
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function BeneficiariesClaimsUploadDesc() {
  const { values } = useFormikContext();
  const { additionalFiles } = values as BeneficiariesClaimFiles;

  const setDescriptionView =
    additionalFiles !== null &&
    additionalFiles.length > 0 &&
    additionalFiles.map((file, id) => {
      return (
        <>
          <Grid item xs={4}>
            <Box sx={{ width: 200, height: 100 }}>
              {['png', 'jpeg', 'jpg'].includes(file.name.split('.').pop()) ? (
                <img
                  alt=""
                  src={URL.createObjectURL(file)}
                  onLoad={() => {
                    URL.revokeObjectURL(URL.createObjectURL(file));
                  }}
                  width={100}
                />
              ) : (
                <Box sx={{ width: 185, height: 85, borderColor: 'black' }}>
                  {setImageIcon(file.name)}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ maxWidth: 300, height: 65 }}>
              <Typography variant="body2">{file.name}</Typography>
            </Box>
            <Box sx={{ width: 200, height: 100, borderColor: 'black' }}>
              <SiraTextField
                name={`additionalfileProperties[${id}.description]`}
                label="Description"
              />
            </Box>
          </Grid>
        </>
      );
    });

  return (
    <Box mt={2} mb={3} maxWidth={500}>
      <Grid container spacing={3} sx={{ pt: 2 }}>
        {additionalFiles !== null && additionalFiles.length > 0 && (
          <FieldArray name="additionalfileProperties">
            {() => {
              return setDescriptionView;
            }}
          </FieldArray>
        )}
      </Grid>
    </Box>
  );
}

function BeneficiariesClaimsUpload() {
  const { values } = useFormikContext();
  const { comments } = values as BeneficiariesClaimFiles;

  const onDropField = (docs) => {
    const filePropertiesVal = docs.map(() => {
      return {
        documentType: ClaimDocumentType.other,
      };
    });

    addTransactionData(
      {
        beneficiaryClaim: {
          additionalFiles: docs,
          comments,
          additionalfileProperties: filePropertiesVal,
        },
      },
      false
    );
  };

  return (
    <Box mt={2} mb={3} maxWidth={500}>
      <Typography variant="body2">
        Examples: Death certificate for beneficiary, estate paperwork, POA or
        guardianship information
      </Typography>
      <Grid container spacing={3} sx={{ pt: 2 }}>
        <SiraFileField
          name="additionalFiles"
          onDropField={onDropField}
          multipleFiles
        />
      </Grid>
    </Box>
  );
}

function BeneficiariesAdditionalInfoForm(props: BeneficiariesClaimsFormProps) {
  const { initialValues, onSubmit = () => {} } = props;
  const [activeStep, setActiveStep] = useState(0);

  const theme = useTheme();
  const classes = {
    root: {
      overflowX: 'auto' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1,
      },
    },
  };


  const beneficiariesClaimsSubSteps: Array<TransactionSubStep> = [
    {
      label: 'Provide Additional Comments',
      stepContent: <BeneficiariesComments />,
      validationFields: [],
    },
    {
      label: 'Upload Additional Documents',
      stepContent: <BeneficiariesClaimsUpload />,
      validationFields: [],
    },
    {
      label: 'Add Descriptions for Additional Documents',
      stepContent: <BeneficiariesClaimsUploadDesc />,
      validationFields: [],
    },
  ];

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container wrap="nowrap" sx={classes.root}>
              <Grid item xs={12}>
                <TransactionSubStepper
                  steps={beneficiariesClaimsSubSteps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  explodeSteps={false}
                />
                <StepButtonBar isSubmitting={isSubmitting} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default BeneficiariesAdditionalInfoForm;
