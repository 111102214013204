import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Paper,
  Button,
  LinearProgress,
  Grid,
  IconButton,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';

import { globalPaginationOptions } from '../app.constants';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import {
  deleteBeneficiaryClaimStatus,
  getMultiOrgBeneficiaryClaims,
} from '../api/BeneficiariesClaimsApi';
import { useUser } from '../auth/useUser';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import {
  MultiOrgBeneficiaryClaimResult,
  BeneficiaryStatus,
} from '../api/BeneficiaryClaimsApi.d';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import { linkToTransaction } from '../utils/transaction.utils';
import { TransactionType } from '../api/AccountApi.d';
import { getOrg } from '../api/OrganizationApi';
import { dateValueFormatter } from '../utils/DataGrid.utils';
import BeneficiarySelectField from '../components/form/BeneficiarySelectField';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { errorMessages } from '../utils/errorhandling.utils';

interface BeneficiaryClaimRow extends MultiOrgBeneficiaryClaimResult {
  transactionType: TransactionType;
}

function beneficiaryClaimRowsFactory(
  beneficiaryClaimResults: Array<MultiOrgBeneficiaryClaimResult>
): Array<BeneficiaryClaimRow> {
  return beneficiaryClaimResults.map((result) => {
    return {
      ...result,
      ...result.accountOwner,
      ...result.account,
      ...result.financialOrganization,
      transactionType: TransactionType.beneficiaryClaims,
    };
  });
}

function BeneficiaryClaimsDashboard() {
  let isMounted = true;
  const { user, setOrganizationId } = useUser();
  const navigate = useNavigate();
  const { setCurrentOrg, addGlobalMessage, getAppConstant } =
    useGlobalContext();
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const [rows, setRows] = useState([] as Array<BeneficiaryClaimRow>);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [beneficiaryStatusFilter, setBeneficiaryStatusFilter] = useState(
    [] as Array<BeneficiaryStatus>
  );
  const [filteredRows, setFilteredRows] = useState(
    [] as Array<BeneficiaryClaimRow>
  );

  // Apply all filters to the supplied rows
  const applyFilters = (rowsToFilter) => {
    const matchingRows = beneficiaryStatusFilter.length
      ? rowsToFilter.filter(({ beneficiaryClaimStatus }) =>
          beneficiaryStatusFilter.includes(beneficiaryClaimStatus)
        )
      : rowsToFilter;

    setFilteredRows(matchingRows);
  };

  async function fetchSndSetMultiOrgBeneficiaryClaims() {
    setIsLoading(true);

    await getMultiOrgBeneficiaryClaims(user.token)
      .then(({ data }) => {
        setRows(beneficiaryClaimRowsFactory(data));
        applyFilters(beneficiaryClaimRowsFactory(data));
      })
      .catch((err) => {


 addGlobalMessage(errorMessages(err) || 'Error fetching multi-org beneficiary claims'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  const onClaimResultClick = async (row): Promise<void> => {
    const { financialOrganizationId, accountOwnerId, accountId } = row;

    setOrganizationId(financialOrganizationId);

    await getOrg(financialOrganizationId, user.token, user).then(({ data }) => {
      setCurrentOrg(data);
      linkToTransaction(accountOwnerId, accountId, row, navigate);
    });
  };

  async function deleteBeneficiaryClaim(row) {
    const { accountId, accountOwnerId, beneficiaryClaimId } = row;
    await deleteBeneficiaryClaimStatus(
      user.organizationId,
      beneficiaryClaimId,
      accountId,
      accountOwnerId,
      user.token
    )
      .then((response) => {
        if (response) {
          fetchSndSetMultiOrgBeneficiaryClaims();
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            `Error deleting claim for account ${row.accountId} and owner ${row.accountOwnerId}.`
        );
      });
  }

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 185,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="bene-claim-result-link"
            onClick={() => {
              onClaimResultClick(row);
            }}
            sx={{
              justifyContent: 'flex-start',
              width: '100%',
              textDecoration: 'none',
              textTransform: 'none',
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'legalName',
      headerName: 'Organization',
      width: 200,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 185,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'submittedDate',
      headerName: 'Status Updated',
      width: 150,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'beneficiaryClaimStatus',
      headerName: 'Status',
      width: 250,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        const statusComponents = {
          [BeneficiaryStatus.pending]: (
            <Button color="warning" startIcon={<PendingIcon />}>
              PENDING
            </Button>
          ),
          [BeneficiaryStatus.processing]: (
            <Button color="success" startIcon={<CheckCircleIcon />}>
              SUBMITTED FOR PROCESSING{' '}
            </Button>
          ),
          [BeneficiaryStatus.awaitingElection]: (
            <Button color="info" startIcon={<InfoIcon />}>
              AWAITING ELECTION
            </Button>
          ),
          [BeneficiaryStatus.awaitingAdditionalInfo]: (
            <Button color="info" startIcon={<InfoIcon />}>
              AWAITING ADDITIONAL INFORMATION
            </Button>
          ),
          [BeneficiaryStatus.completed]: (
            <Button color="info" startIcon={<InfoIcon />}>
              COMPLETED
            </Button>
          ),          
          [BeneficiaryStatus.notProcessed]: (
            <Button color="info" startIcon={<InfoIcon />}>
              Not Processed
            </Button>
          ),
        };

        return statusComponents[value as BeneficiaryStatus];
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const [isDeleting, setIsDeleting] = useState(false as boolean);

        return (
          <>
            <Grid container justifyContent="flex-end">
              <IconButton
                disabled={isDeleting}
                data-testid="delete-federal-rate"
                size="small"
                aria-label="Delete Applicable Federal Rate"
                onClick={async () => {
                  setIsDeleting(true);
                  await deleteBeneficiaryClaim(row);
                  setIsDeleting(false);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    applyFilters(rows);

    return () => {
      isMounted = false;
    };
  }, [beneficiaryStatusFilter]);

  useEffect(() => {
    if (user.token) {
      fetchSndSetMultiOrgBeneficiaryClaims();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token]);

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Box mb={5}>
            <Typography variant="overline" gutterBottom>
              Beneficiary Claims Dashboard
            </Typography>
            <Typography variant="h1" color="secondary" gutterBottom>
              All Beneficiary Claims
            </Typography>
            <BeneficiarySelectField
              onChange={setBeneficiaryStatusFilter}
              beneficiaryTypes={beneficiaryStatusFilter}
            />
          </Box>

          {isLoading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Paper>
              <DataGrid
                components={{
                  NoRowsOverlay: SiraNoRowsOverlay,
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: globalPageSize } },
                }}
                pageSizeOptions={globalPaginationOptions}
                onPaginationModelChange={setGlobalPageSize}
                autoHeight
                columns={columns}
                rows={filteredRows.map((result, id) => ({
                  ...result,
                  id,
                }))}
              />
            </Paper>
          )}
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default BeneficiaryClaimsDashboard;
