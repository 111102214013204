export enum TaskStatus {
  complete = 'COMPLETED',
  incomplete = 'NOT_COMPLETED',
}

export interface Task {
  toDoListId?: string;
  title?: string;
  description?: string;
  financialOrganizationId?: string;
  financialOrganizationIds?: Array<string>; // For bulk-assigning organizations
  actionLink?: string;
  dueDate?: string; // date not time
  status?: TaskStatus;
  category?: string;
  completedById?: string;
  completedDate?: string; // date time
  assignedToId?: string;
  createdDate?: string; // date time
  descriptionForEmail?: string;
  sendEmailWhenTaskCreated: boolean;
  emailRecipientUserIds: List<strinbg>;
}


export interface TaskListResponse {
  data?: Array<Task>;
  errorMessage?: any;
}
export interface TaskUpdateResponse {
  data?: Task;
  errorMessage?: any;
}
