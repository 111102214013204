import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  addYears,
  endOfYear,
  formatISO,
  isValid,
  isWithinInterval,
  parseISO,
  startOfYear,
} from 'date-fns';
import { Cell, Pie, PieChart } from 'recharts';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DataGrid,
  GridCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';

import { useUser } from '../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import { Account, AccountStatus, AccountSupportedTypestring, AccountType } from '../api/AccountApi.d';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import { determineAgeGroup, globalPaginationOptions } from '../app.constants';
import { dateValidation } from '../components/form/SiraDateField';
import SiraDateRangeField from '../components/form/SiraDateRangeField';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { dateValueFormatter } from '../utils/DataGrid.utils';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import DataGridExportToolbar from '../components/DataGridExportToolbar';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import ResponsivePercentBar, {
  ResponsivePercentBarProps,
} from '../components/reports/ResponsivePercentBar';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import { getDashboardAccounts } from '../api/OrganizationApi';
import { errorMessages } from '../utils/errorhandling.utils';

interface AdditionalStatsProps {
  accountType: AccountType; // Account type for which to look up the stats component
  matchingOpenAccounts: Array<Account & AccountOwner>;
}

interface AccountSummaryForm {
  startDate: string;
  endDate: string;
}

interface AdditionalStatsChartProps {
  stats?: Array<ResponsivePercentBarProps>;
  accountType: AccountType | string;
  isOver18?: Array<Account & AccountOwner>;
  isOver30?: Array<Account & AccountOwner>;
  isUnder18?: Array<Account & AccountOwner>;
  isUnder73?: Array<Account & AccountOwner>;
  isOver73?: Array<Account & AccountOwner>;
  fiveYearMet?: Array<Account & AccountOwner>;
  fiveYearUnmet?: Array<Account & AccountOwner>;
  allAccounts?: Array<Account & AccountOwner>;
}

enum AgeDeterminationLegend {
  isOver18 = 'Owners 18 or over',
  isUnder18 = 'Owners under 18',
  isUnder73 = 'Owners under 73',
  isOver73 = 'Owners 73 or over',
  isOver30 = 'Owners 30 or over',
  fiveYearMet = 'Five year rule met',
  fiveYearUnmet = 'Five year rule not met',
}

const ACCOUNT_SUMMARY_INIT: AccountSummaryForm = {
  startDate: formatISO(startOfYear(new Date()), { representation: 'date' }),
  endDate: formatISO(endOfYear(new Date()), { representation: 'date' }),
};

const ACCOUNT_SUMMARY_SCHEMA = yup.object().shape(
  {
    startDate: yup
      .string()

      .when('endDate', (endDate, schema) =>
        endDate
          ? dateValidation(undefined, parseISO(endDate)).label('Start Date')
          : schema
      ),
    endDate: yup
      .string()

      .when('startDate', (startDate, schema) =>
        startDate
          ? dateValidation(parseISO(startDate), addYears(new Date(), 10)).label(
              'End Date'
            )
          : schema
      ),
  },
  [['startDate', 'endDate']]
);

function AdditionalStatsChart(props: AdditionalStatsChartProps) {
  const {
    stats = [],
    accountType = '',
    isOver18,
    isOver30,
    isOver73,
    isUnder18,
    isUnder73,
    fiveYearMet,
    fiveYearUnmet,
    allAccounts,
  } = props;
  const {  setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const { getAppConstant , organization: { accountNumberValue },
} = useGlobalContext();
const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account #': 'Member #';

  const goToAccountProfile = (result: Account & AccountOwner): void => {
    const { accountOwnerId = '' } = result || {};

    if (accountOwnerId) {
      navigate(`/accountOwner/${accountOwnerId}`);
    }
  };

  const matchingOpenAccounts = (legend?: string) => {
    switch (legend) {
      case AgeDeterminationLegend.isOver18:
        return isOver18;
      case AgeDeterminationLegend.isUnder18:
        return isUnder18;
      case AgeDeterminationLegend.isOver73:
        return isOver73;
      case AgeDeterminationLegend.isUnder73:
        return isUnder73;
      case AgeDeterminationLegend.isOver30:
        return isOver30;
      case AgeDeterminationLegend.fiveYearMet:
        return fiveYearMet;
      case AgeDeterminationLegend.fiveYearUnmet:
        return fiveYearUnmet;
      default:
        return allAccounts;
    }
  };

  const openDateColumn = {
    field: 'openDate',
    headerName: 'Date Opened',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              goToAccountProfile(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
      sortable: false,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {!stats.length ? (
        <Grid item xs={12}>
          <DataGrid
            components={{
              NoRowsOverlay: SiraNoRowsOverlay,
              Toolbar: DataGridExportToolbar,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={globalPaginationOptions}
            onPaginationModelChange={setGlobalPageSize}
            disableColumnMenu
            autoHeight
            columns={[openDateColumn, ...columns]}
            rows={matchingOpenAccounts().map((openAccount, id) => ({
              ...openAccount,
              id,
            }))}
          />
        </Grid>
      ) : (
        stats.map((row) => (
          <Grid item xs={12} key={row.legend}>
            <Accordion key={accountType} elevation={2}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid key={row.legend} item xs={12}>
                  <ResponsivePercentBar {...row} isNested />
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <DataGrid
                  components={{
                    NoRowsOverlay: SiraNoRowsOverlay,
                    Toolbar: DataGridExportToolbar,
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={globalPaginationOptions}
                  onPaginationModelChange={setGlobalPageSize}
                  disableColumnMenu
                  autoHeight
                  columns={[openDateColumn, ...columns]}
                  rows={matchingOpenAccounts(row.legend).map(
                    (openAccount, id) => ({
                      ...openAccount,
                      id,
                    })
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
      )}
    </Grid>
  );
}

// Just add stat components here and they'll show up in the accordion when they match the account type
function AdditionalStats(props: AdditionalStatsProps) {
  const { accountType = '', matchingOpenAccounts = [] } = props;

  const EsaStats = () => {
    const isUnder18 = matchingOpenAccounts.filter(({ dateOfBirth = '' }) => {
      const { currentlyOrOver18 } = determineAgeGroup(dateOfBirth);
      return !currentlyOrOver18;
    });
    const isOver18 = matchingOpenAccounts.filter(({ dateOfBirth = '' }) => {
      const { currentlyOrOver18 } = determineAgeGroup(dateOfBirth);
      return currentlyOrOver18;
    });

    const isOver30 = matchingOpenAccounts.filter(({ dateOfBirth = '' }) => {
      const { currentlyOrOver30 } = determineAgeGroup(dateOfBirth);
      return currentlyOrOver30;
    });

    const esaStats = [
      {
        legend: 'Owners under 18',
        quantity: isUnder18.length,
        percent: (isUnder18.length / matchingOpenAccounts.length) * 100,
      },
      {
        legend: 'Owners 18 or over',
        quantity: isOver18.length,
        percent: (isOver18.length / matchingOpenAccounts.length) * 100,
      },
      {
        legend: 'Owners 30 or over',
        quantity: isOver30.length,
        percent: (isOver30.length / matchingOpenAccounts.length) * 100,
      },
    ];

    return (
      <AdditionalStatsChart
        stats={esaStats}
        accountType={accountType}
        isOver18={isOver18}
        isUnder18={isUnder18}
        isOver30={isOver30}
      />
    );
  };

  const IraStats = () => {
    const isUnder73 = matchingOpenAccounts.filter(({ dateOfBirth = '' }) => {
      const { over73, currently73Years, turning73ThisYear } =
        determineAgeGroup(dateOfBirth);
      return !over73 && !currently73Years && !turning73ThisYear;
    });
    const isOver73 = matchingOpenAccounts.filter(({ dateOfBirth = '' }) => {
      const { over73, currently73Years, turning73ThisYear } =
        determineAgeGroup(dateOfBirth);
      return currently73Years || over73 || turning73ThisYear;
    });

    const iraStats = [
      {
        legend: 'Owners under 73',
        quantity: isUnder73.length,
        percent: (isUnder73.length / matchingOpenAccounts.length) * 100,
      },
      {
        legend: 'Owners 73 or over',
        quantity: isOver73.length,
        percent: (isOver73.length / matchingOpenAccounts.length) * 100,
      },
    ];

    return (
      <AdditionalStatsChart
        stats={iraStats}
        accountType={accountType}
        isUnder73={isUnder73}
        isOver73={isOver73}
      />
    );
  };

  const RothStats = () => {
    const fiveYearRuleMet = matchingOpenAccounts.filter(
      ({ fiveYearTest } = {}) => fiveYearTest
    );
    const fiveYearRuleUnmet = matchingOpenAccounts.filter(
      ({ fiveYearTest } = {}) => !fiveYearTest
    );

    const rothStats = [
      {
        legend: 'Five year rule met',
        quantity: fiveYearRuleMet.length,
        percent: (fiveYearRuleMet.length / matchingOpenAccounts.length) * 100,
      },
      {
        legend: 'Five year rule not met',
        quantity: fiveYearRuleUnmet.length,
        percent: (fiveYearRuleUnmet.length / matchingOpenAccounts.length) * 100,
      },
    ];

    return (
      <AdditionalStatsChart
        stats={rothStats}
        accountType={accountType}
        fiveYearMet={fiveYearRuleMet}
        fiveYearUnmet={fiveYearRuleUnmet}
      />
    );
  };

  const AccountList = () => {
    return (
      <AdditionalStatsChart
        accountType={accountType}
        allAccounts={matchingOpenAccounts}
      />
    );
  };

  const componentMap: { [key: string]: Function } = {
    [AccountType.esa]: EsaStats,
    [AccountType.traditionalIra]: IraStats,
    [AccountType.traditionalSep]: IraStats,
    [AccountType.simpleIra]: IraStats,
    [AccountType.rothIra]: RothStats,
    [AccountType.inheritedRoth]: RothStats,
    [AccountType.inheritedTraditional]: AccountList,
    [AccountType.hsa]: AccountList,
  };

  return componentMap[accountType] ? componentMap[accountType]() : null;
}

function AccountsSummary() {
  let isMounted = true;
  const theme = useTheme();
  const navigate = useNavigate();
  const {  setGlobalPageSize } = usePaginationContext();
  const {
    getAppConstant,
    organization: { legalName = '', accountNumberValue },
    addGlobalMessage,
  } = useGlobalContext();
  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account #': 'Member #';

  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [dateFilters, setDateFilters] = useState({} as any);
  const [orgAccounts, setOrgAccounts] = useState(
    [] as Array<Account & AccountOwner>
  );
  const accountTypesSupported = AccountSupportedTypestring as Array<AccountType>;

  const isInFilterDateRange = (date: string) => {
    const { startDate, endDate } = dateFilters;

    // Chek for dates' validity before attempting to apply the interval filter
    if (!isValid(startDate) || !isValid(endDate)) {
      return false;
    }

    return isWithinInterval(parseISO(date), {
      start: startDate,
      end: endDate,
    });
  };

  const goToAccountProfile = (result: Account & AccountOwner): void => {
    const { accountOwnerId = '' } = result || {};

    if (accountOwnerId) {
      navigate(`/accountOwner/${accountOwnerId}`);
    }
  };

  // All open accounts regardless of date filters
  const openAccounts = useMemo(
    () =>
      orgAccounts.filter(
        ({ accountStatus }) => accountStatus === AccountStatus.open
      ),
    [orgAccounts]
  );

  // Open accounts that adhere to the date filters
  const filteredOpenAccounts = useMemo(
    () =>
      dateFilters.startDate && dateFilters.endDate
        ? openAccounts.filter(({ openDate }) => isInFilterDateRange(openDate))
        : [],
    [openAccounts, dateFilters]
  );

  // Closed accounts that adhere to the date filters
  const filteredClosedAccounts = useMemo(
    () =>
      dateFilters.startDate && dateFilters.endDate
        ? orgAccounts.filter(
            ({ accountStatus, closedDate }) =>
              accountStatus === AccountStatus.closed &&
              isInFilterDateRange(closedDate)
          )
        : [],
    [orgAccounts, dateFilters]
  );

  // Pie chart data
  const pieData = [
    {
      name: 'Opened',
      value: filteredOpenAccounts.length,
      color: theme.palette.secondary.light,
    },
    {
      name: 'Closed',
      value: filteredClosedAccounts.length,
      color: theme.palette.grey[600],
    },
  ];

  async function fetchAllOrgAccounts() {
    setIsLoading(true);

    await getDashboardAccounts(user.organizationId, [AccountStatus.closed,AccountStatus.open ],user.token)
      .then(({ data = [] }) => {
        if (isMounted) {
          setOrgAccounts(
            data.map((result) => ({ ...result.account, ...result.accountOwner }))
          );
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error fetching organization accounts'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  const openDateColumn = {
    field: 'openDate',
    headerName: 'Date Opened',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const closedDateColumn = {
    field: 'closedDate',
    headerName: 'Date Closed',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              goToAccountProfile(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (user.token && user.organizationId) {
      fetchAllOrgAccounts();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Reports and Statistics
          </Typography>

          <Typography color="secondary" variant="h1">
            Accounts Summary
          </Typography>

          <Typography gutterBottom>{legalName}</Typography>

          <Grid item xs={12} mt={5}>
            {!user.organizationId ? (
              <Box p={2} pt={5} pb={5}>
                <Alert severity="warning">
                  Select an organization to see account summary
                </Alert>
              </Box>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>
                        Accounts Activity
                      </Typography>
                    </Grid>

                    {/* Date Range */}
                    <Grid item xs={12} md={7}>
                      <Formik
                        initialValues={ACCOUNT_SUMMARY_INIT}
                        onSubmit={(values: any) => {
                          setDateFilters({
                            startDate: parseISO(values.startDate),
                            endDate: parseISO(values.endDate),
                          });
                        }}
                        validationSchema={ACCOUNT_SUMMARY_SCHEMA}
                        enableReinitialize
                      >
                        {({ values, submitForm }) => {
                          // Submit the form whenever the values change
                          useEffect(() => {
                            submitForm();
                          }, [values]);

                          return (
                            <Form>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                pt={1}
                                pb={2}
                              >
                                <SiraDateRangeField />
                              </Grid>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>

                    {/* Pie Chart */}
                    <Grid
                      item
                      xs={12}
                      md={5}
                      sx={{
                        filter: isLoading ? 'blur(5px)' : '',
                        transition: 'filter 0.33s ease-in-out',
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <PieChart width={140} height={140}>
                            <Pie
                              dataKey="value"
                              isAnimationActive={false}
                              data={pieData}
                              cx="50%"
                              cy="50%"
                              outerRadius={70}
                            >
                              {pieData &&
                                pieData.map(({ value, color }) => (
                                  <Cell key={value} fill={color} />
                                ))}
                            </Pie>
                          </PieChart>
                        </Grid>

                        <Grid item xs={12}>
                          {pieData.map(({ name, value, color }) => (
                            <Typography key={name} variant="body2" gutterBottom>
                              <Box
                                component="span"
                                mr={1}
                                display="inline-block"
                                width={10}
                                height={10}
                                bgcolor={color}
                              />
                              {name}: {value}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Open Account Details */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                >
                  <Accordion elevation={2} disabled={isLoading}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            {filteredOpenAccounts.length} Accounts were Opened
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography variant="caption">View all</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DataGrid
                        components={{
                          NoRowsOverlay: SiraNoRowsOverlay,
                          Toolbar: DataGridExportToolbar,
                        }}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={globalPaginationOptions}
                        onPaginationModelChange={setGlobalPageSize}
                        disableColumnMenu
                        autoHeight
                        columns={[openDateColumn, ...columns]}
                        rows={filteredOpenAccounts.map((openAccount, id) => ({
                          ...openAccount,
                          id,
                        }))}
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Closed Account Details */}
                  <Accordion elevation={2} disabled={isLoading}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            {filteredClosedAccounts.length} Accounts were Closed
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography variant="caption">View all</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DataGrid
                        components={{
                          NoRowsOverlay: SiraNoRowsOverlay,
                          Toolbar: DataGridExportToolbar,
                        }}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={globalPaginationOptions}
                        onPaginationModelChange={setGlobalPageSize}
                        disableColumnMenu
                        autoHeight
                        columns={[closedDateColumn, ...columns]}
                        rows={filteredClosedAccounts.map(
                          (closedAccount, id) => ({
                            ...closedAccount,
                            id,
                          })
                        )}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {/* Open Accounts By Type */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                >
                  <Typography variant="h5">
                    {openAccounts.length} Current Open Accounts
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                >
                  {accountTypesSupported.map((accountTypeToMatch) => {
                    // Open accounts that match the current datum's type
                    const matchingOpenAccounts = openAccounts.filter(
                      ({ accountType = '' }) =>
                        accountType === accountTypeToMatch 
                    );
                    // Percent to display and use for the bar chart width
                    const matchingPercent =
                      (matchingOpenAccounts.length / openAccounts.length) * 100;

                    return matchingOpenAccounts.length > 0 ?
                      <Accordion
                        key={accountTypeToMatch}
                        elevation={2}
                        disabled={isLoading}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <ResponsivePercentBar
                            legend={getAppConstant(
                              ConstantsMappingKey.accountType,
                              accountTypeToMatch
                            )}
                            quantity={matchingOpenAccounts.length}
                            percent={matchingPercent}
                          />
                        </AccordionSummary>

                        <AccordionDetails>
                          <AdditionalStats
                            key={accountTypeToMatch}
                            accountType={accountTypeToMatch}
                            matchingOpenAccounts={matchingOpenAccounts}
                          />
                        </AccordionDetails>
                      </Accordion>
                    : null;
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      </FormPaper>
    </Layout>
  );
}

export default AccountsSummary;
