import React, { useEffect } from 'react';
import { Container, LinearProgress, Box } from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { searchBeneficiariesClaims } from '../../../api/BeneficiariesClaimsApi';
import { useUser } from '../../../auth/useUser';
import SiraSearchField from '../SiraSearchField';
import { AccountOwnerSearchForm } from '../accountSearch/AccountSearch';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { ViewBeneficiaryFilter } from './ViewBeneficiaryFilter';
import { BeneficiaryStatus } from '../../../api/BeneficiaryClaimsApi.d';

interface BeneficiaryClaimSearchFormProps {
  submitOnMount?: boolean;
  setResponse?: Function;
  initialValues?: AccountOwnerSearchForm;
  statusFilter?: Array<BeneficiaryStatus>;
  onItemClick?: Function;
}

export const ACCOUNT_SEARCH_INIT = { query: '' };

const SEARCH_QUERY_SCHEMA = yup.object().shape({
  query: yup.string().required().max(60).label('Search term'),
});

function BeneficiaryClaimSearchFormContent(props) {
  const { submitOnMount, submitSearch,  statusFilter,
  onItemClick } = props;
  const { isSubmitting, values } = useFormikContext();
  const { query } = values as AccountOwnerSearchForm;

  useEffect(() => {
    if (submitOnMount && query) {
      submitSearch(values);
    }
  }, []);

  return (
    <>
      <Container disableGutters>
        <SiraSearchField name="query" label="Enter name or tax id" />
      </Container>
      <ViewBeneficiaryFilter
        statusFilter={statusFilter}
        onItemClick={onItemClick}
      />
      {isSubmitting && (
        <Box width="1">
          <LinearProgress />
        </Box>
      )}
    </>
  );
}

function BeneficiarySearchForm(props: BeneficiaryClaimSearchFormProps) {
  let isMounted = true;
  const {
    setResponse = () => {},
    initialValues = { query: '' },
    submitOnMount = false,  statusFilter,
  onItemClick= () => {},
  } = props;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const queryParams = new URLSearchParams(useLocation().search);
  const queryFromParam = queryParams.get('search') || '';

  const submitSearch = async (data: AccountOwnerSearchForm): Promise<void> => {
    await searchBeneficiariesClaims(data, user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          setResponse({ ...res, query: data.query });
        }
      })
      .catch(() => {
        addGlobalMessage('Error fetching account owner search results');
      });
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (data) => {
        await submitSearch(data);
      }}
      validationSchema={SEARCH_QUERY_SCHEMA}
    >
      {({ setFieldValue }) => {
        useEffect(() => {
          if (queryFromParam) {
            setFieldValue('query', queryFromParam);
            submitSearch({ query: queryFromParam });
          }
        }, []);

        return (
          <Form>
            <BeneficiaryClaimSearchFormContent
              submitSearch={submitSearch}
              submitOnMount={submitOnMount}
              statusFilter={statusFilter}
              onItemClick={onItemClick}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default BeneficiarySearchForm;
