import React, { useEffect, useState } from 'react';
import { IconButton, Button, Box, Grid, LinearProgress } from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useUser } from '../../auth/useUser';
import {
  createStateTaxId,
  deleteStateTaxId,
  getStateTaxIds,
  updateStateTaxId,
} from '../../api/OrganizationApi';
import { StateTaxId } from '../../api/OrganizationApi.d';
import StateTaxIdForm, {
  STATE_TAX_ID_INIT,
} from '../form/organization/StateTaxIdForm';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { globalPaginationOptions } from '../../app.constants';
import { errorMessages } from '../../utils/errorhandling.utils';

function OrganizationStateTaxIdDetails() {
  let isMounted = true;
  const { user } = useUser();
  const { organization, addGlobalMessage, setCurrentOrg } = useGlobalContext();
  const { setGlobalPageSize, globalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState(false);
  const [creatingTaxId, setCreatingTaxId] = useState(false);
  const [editingTaxId, setEditingTaxId] = useState(false);
  const [taxIdToEdit, setTaxIdToEdit] = useState({} as StateTaxId);
  const [stateTaxIDS, setStateTaxIDS] = useState([] as Array<StateTaxId>);

  async function fetchStateTaxIds() {
    await getStateTaxIds(organization, user.token)
      .then((res) => {
        if (isMounted) {
          setStateTaxIDS(res.data);
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Error fetching state tax IDs'
          );
        }
      });
  }

  useEffect(() => {
    if(user.token) {
    fetchStateTaxIds();
    }
    return () => {
      isMounted = false;
    };
  }, [user.token]);

  async function addOrUpdateTaxId(taxId: StateTaxId) {
    
    const apiFunction = editingTaxId ? updateStateTaxId : createStateTaxId;

    await apiFunction(taxId, organization, user.token)
      .then((res) => {
        const newStateTaxIds = organization.stateTaxIDS.filter(
          (x) => x.state !== res.data.state
        );
        newStateTaxIds.push(res.data);
        if (isMounted) {
          setCurrentOrg({ stateTaxIDS: newStateTaxIds });
          setEditingTaxId(false);
          setCreatingTaxId(false);
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Error saving state');
        }
      });
  }

  async function removeTaxId(taxId: StateTaxId) {
    setIsLoading(true);
    await deleteStateTaxId(taxId, organization, user.token)
      .then(() => {
        const newStateTaxIds = organization.stateTaxIDS.filter(
          (item) => item.state !== taxId.state
        );
        if (isMounted) {
          setCurrentOrg({ stateTaxIDS: newStateTaxIds });
          setIsLoading(false);
          addGlobalMessage('State deleted successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error removing organization tax ID'
          );
        }
      });
  }

  const columns = [
    {
      field: 'state',
      headerName: 'State',
      width: 176,
    },
    {
      field: 'taxId',
      headerName: 'Tax ID',
      width: 176,
    },
    {
      field: 'stateWithholding',
      headerName: 'State Withholding',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        return row.stateWithholding ? 'Supported' : 'Not Supported';
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <>
            <Grid container justifyContent="flex-end">
              <IconButton
                data-testid="Edit Tax ID"
                size="small"
                aria-label="edit Tax ID"
                onClick={() => {
                  setTaxIdToEdit(row as StateTaxId);
                  setEditingTaxId(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                data-testid="Delete StateTaxId"
                size="small"
                aria-label="delete tax ID"
                onClick={async () => {
                  await removeTaxId(row as StateTaxId);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <Box width="1" pt={10} pb={10} textAlign="center">
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  const excludedStates = stateTaxIDS.reduce((accum, value) => {
    accum.push(value.state);
    return accum;
  }, []);

  return editingTaxId || creatingTaxId ? (
    <StateTaxIdForm
      initialValues={{
        ...STATE_TAX_ID_INIT,
        ...taxIdToEdit,
      }}
      editingMode={editingTaxId}
      onSubmit={async (data) => {
        await addOrUpdateTaxId(data as StateTaxId);
      }}
      onCancel={() => {
        setEditingTaxId(false);
        setCreatingTaxId(false);
      }}
      excludeStates={excludedStates}
    />
  ) : (
    <Box width="1">
      <Box>
        <DataGrid
          components={{
            NoRowsOverlay: SiraNoRowsOverlay,
          }}
          disableColumnMenu
          autoHeight
          columns={columns}
          rows={stateTaxIDS}
          initialState={{
            pagination: { paginationModel: { pageSize: globalPageSize } },
          }}
          pageSizeOptions={globalPaginationOptions}
          onPaginationModelChange={setGlobalPageSize}
          getRowId={(r) => r.state}
        />
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setTaxIdToEdit({} as StateTaxId);
            setCreatingTaxId(true);
          }}
        >
          Add State
        </Button>
      </Box>
    </Box>
  );
}

export default OrganizationStateTaxIdDetails;
