import { StandardTextFieldProps, TextField } from '@mui/material';
import React, { ReactChild, useState } from 'react';
import { FieldAttributes, useField } from 'formik';
import * as yup from 'yup';
import { endOfDay, format, isAfter, isBefore, parseISO } from 'date-fns';
import moment from 'moment-timezone';

interface SiraDateFieldProps extends StandardTextFieldProps {
  label?: string;
  afterContent?: ReactChild;
}

export function dateValidation(
  minDate: Date = endOfDay(new Date(1900, 1, 1)),
  maxDate: Date = endOfDay(new Date())
) {
  return yup
    .string()
    .matches(/[1,2]{1}\d{3}-\d{2}-\d{2}/, 'Use a valid 4-digit year')
    .test('valid Date', 'Invalid Date', (value) => {
      let x = null;
      try {
        x = new Date(value);
      } catch (err) {
        return false;
      }
      return x !== null;
    })
    .test(
      'min Date',
      `Date must be after ${format(minDate, 'MM/dd/yyyy')}`,
      (value) => {
        const asDate = endOfDay(parseISO(value));
        return value ? isAfter(asDate, minDate) : true;
      }
    )
    .test(
      'max Date',
      `Date must be on or before account closed date of ${format(
        maxDate,
        'MM/dd/yyyy'
      )}`,
      (value) => {
        const asDate = parseISO(value);
        return value ? isBefore(asDate, maxDate) : true;
      }
    );
}

export function distributionDateValidation(
  minDate: Date = new Date(),
  maxDate: Date = endOfDay(new Date()),
  accountClosedDate
) {

const previousDay = new Date(maxDate);
previousDay.setDate(maxDate.getDate() - 1);
  return yup
    .string()
    .matches(/[1,2]{1}\d{3}-\d{2}-\d{2}/, 'Use a valid 4-digit year')
    .test('valid Date', 'Invalid Date', (value) => {
      let x = null;
      try {
        x = new Date(value);
      } catch (err) {
        return false;
      }
      return x !== null;
    })
    .test(
      'min Date',
      `Date must be on or after account open date ${format(
        minDate,
        'MM/dd/yyyy'
      )}`,
      (value) => {
        const asDate = endOfDay(parseISO(value));
        return value ? isAfter(asDate, minDate) : true;
      }
    )
    .test(
      'max Date',
      `${
        accountClosedDate
          ? `Date must be on or before account closed date of ${format(
              maxDate,
              'MM/dd/yyyy'
            )}`
          : `Date must be ${format(
            previousDay,
            'MM/dd/yyyy'
          )} or earlier`
      }`,
      (value) => {
        const asDate = parseISO(value);
        return value ? isBefore(asDate, maxDate) : true;
      }
    );
}

const SiraDateField: React.FC<FieldAttributes<SiraDateFieldProps>> = ({
  afterContent,
  ...textFieldProps
}) => {
  const [field, meta] = useField<{}>(textFieldProps);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const guessedTimeZone = moment.tz.guess();
  const convertedDate = moment().tz(guessedTimeZone);
  const now = convertedDate.format('YYYY-MM-DD');
  const [date, setDate] = useState(now);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  return (
    <>
      <TextField
        fullWidth
        helperText={errorText}
        error={Boolean(errorText)}
        variant="outlined"
        type="date"
        value={date}
        data-qa="dateField"
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true, // Force the label to shrink because we show the mask when empty
        }}
        inputProps={{
          max: '9999-12-31', // Keeps year to 4 digits only (default allows up to 6)
          'data-qa': field.name, // This is the data-qa attribute
        }}
        {...field}
        {...textFieldProps}
      />
      {afterContent}
    </>
  );
};

export default SiraDateField;
