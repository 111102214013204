import axios from 'axios';
import format from 'string-format';
import { AccountComment, AccountCommentResponse } from './AccountApi.d';
import axiosInstance from '../utils/axiosInterceptor';


const urlBase = process.env.REACT_APP_API_BASE_URL;

const updateAccountCommentUrl =
  '/api/org/{0.orgId}/account/{0.accountId}/accountcomment/{0.accountCommentId}';
const getAccountCommentUrl =
  '/api/org/{0.orgId}/account/{0.accountId}/accountcomments';
const postAccountCommentUrl =
  '/api/org/{0.orgId}/account/{0.accountId}';
const deleteAccountCommentUrl =
  '/api/org/{0.orgId}/account/{0.accountId}/accountcomment/{0.accountCommentId}';

    export async function createAccountComment(
      accountId: string,
      accountComment: AccountComment,
      orgId: string,
      token: string
    ): Promise<AccountCommentResponse> {
      const url = `${urlBase}${format(postAccountCommentUrl, {
        orgId,
        accountId,
      })}`;

      return axiosInstance.post(url, accountComment,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }      
    
    export async function updateAccountComment(
      accountId: string,
      accountCommentId: number,
      accountComment: AccountComment,
      orgId: string,
      token: string
    ): Promise<AccountCommentResponse> {
      const url = `${urlBase}${format(updateAccountCommentUrl, {
        orgId,
        accountId,
        accountCommentId,
      })}`;

      return axiosInstance.put(url,accountComment, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }  
  

  export async function retrieveAccountComment(
    accountId: string,
    orgId: string,
    token: string
  ): Promise<AccountCommentResponse> {
    const url = `${urlBase}${format(getAccountCommentUrl, {
      orgId,
      accountId,
    })}`;

    return axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }  
  
  export async function deleteAccountComment(
    accountId: string,
    accountCommentId: number,
    orgId: string,
    token: string
  ): Promise<AccountCommentResponse> {
    const url = `${urlBase}${format(deleteAccountCommentUrl, {
      orgId,
      accountId,
      accountCommentId,
    })}`;

    return axiosInstance.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
