import React from 'react';
import { useTheme, Paper, Typography, Box, Grid } from '@mui/material';

import { format, parseISO } from 'date-fns';

interface TaxReportingFileCardsProps {
  irsTaxFileUploadId: string;
  fileName: string;
  created: string;
  createdByName: string;
  selecteTaxFileUploadId: string;
  setSelectedTaxFileUploadId: Function;
}

function TaxReportingFileCards(props: TaxReportingFileCardsProps) {
  const {
    irsTaxFileUploadId,
    fileName,
    createdByName,
    created,
    selecteTaxFileUploadId,
    setSelectedTaxFileUploadId,
  } = props;
  const theme = useTheme();

  const isSelected = irsTaxFileUploadId === selecteTaxFileUploadId;

  const classes = {
    accountSummaryCard: {
      cursor: 'pointer',
      border: `1px solid transparent`,
      borderTop: `${theme.spacing(3)} solid ${theme.palette.primary.main}`,
      padding: theme.spacing(2),
    },
    accountSummaryCardActive: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderTop: `${theme.spacing(3)} solid ${theme.palette.secondary.main}`,
    },
  };

  const selectTaxFileCard = (value) => {
    setSelectedTaxFileUploadId(value);
  };

  return (
    <>
      <Box
        onClick={() => {
          selectTaxFileCard(irsTaxFileUploadId);
        }}
      >
        <Paper
          elevation={isSelected ? 5 : 3}
          sx={{
            ...classes.accountSummaryCard,
            ...(isSelected && classes.accountSummaryCardActive),
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom>
                Irs Tax File Upload Id {irsTaxFileUploadId},
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">FileName: {fileName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Created Date: {format(parseISO(String(created)), 'MM/dd/yyyy')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Created by: {createdByName}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

export default TaxReportingFileCards;
