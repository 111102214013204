import React, { useEffect, useState } from 'react';
import { getYear, parseISO } from 'date-fns';
import {
  Box,
  Grid,
  Typography,
  Button,
  LinearProgress,
  IconButton,
  Popover,
} from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import TransactionSignatureIcon from '../../icons/TransactionSignature';
import TransactionPendingIcon from '../../icons/TransactionPending';
import TransactionReviewIcon from '../../icons/TransactionReview';

import Layout from '../Layout';
import FormPaper from '../FormPaper';

import { useUser } from '../../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import {
  RmdNotificationType,
  RmdNotificationStatus,
  RmdNotificationResponse,
  RmdNotification,
} from '../../api/RmdNotificationApi.d';
import { getOrgRmdNotifications } from '../../api/RmdNotificationApi';
import RecurringDistributionElection from './RecurringDistributionElection';
import { Account, AccountStatus } from '../../api/AccountApi.d';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../ObscuredTaxId';
import RmdNotificationSearchForm from '../form/recurringDistributions/RmdNotificationSearchForm';
import { AccountDetailAccordionType } from '../accountOwnerProfile/AccountOwnerAccountDetails';
import { globalPaginationOptions } from '../../app.constants';
import DataGridExportToolbar from '../DataGridExportToolbar';
import { dateValueFormatter } from '../../utils/DataGrid.utils';
import { errorMessages } from '../../utils/errorhandling.utils';

interface RmdNotificationFilterProps {
  onItemClick: Function;
  statusFilter: Array<RmdNotificationStatus>;
}

interface RmdNotificationRow extends RmdNotification {
  id: number;
  fullName: string;
  taxpayerIdNumber: string;
  account: Account;
  accountOwner: AccountOwner;
}

// Flatten and map out the rmd notifications for display in the data grid
function rmdNotificationRowsFactory(
  notifications: Array<RmdNotificationResponse>
): Array<RmdNotificationRow> {
  return notifications.map((result, id) => ({
    ...result.rmdNotification,
    accountOwner: result.accountOwner,
    account: result.account,
    accountStatus: result.account.accountStatus,
    id,
    fullName: result.accountOwner.fullName,
    taxpayerIdNumber: result.accountOwner.taxpayerIdNumber,
  }));
}

function RmdNotificationFilter(props: RmdNotificationFilterProps) {
  const { onItemClick = () => {}, statusFilter } = props;

  return (
    <Box mt={2} mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body2">Filter by:</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(RmdNotificationStatus.resolved)
                ? 'contained'
                : 'outlined'
            }
            color="success"
            startIcon={<CheckCircleIcon />}
            size="small"
            onClick={() => {
              onItemClick([RmdNotificationStatus.resolved]);
            }}
          >
            Completed
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(RmdNotificationStatus.draft)
                ? 'contained'
                : 'outlined'
            }
            color="warning"
            startIcon={<PendingIcon />}
            size="small"
            onClick={() => {
              onItemClick([RmdNotificationStatus.draft]);
            }}
          >
            Draft
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant={
              statusFilter.includes(RmdNotificationStatus.awaiting)
                ? 'contained'
                : 'outlined'
            }
            color="info"
            startIcon={<InfoIcon />}
            size="small"
            onClick={() => {
              onItemClick([RmdNotificationStatus.awaiting]);
            }}
          >
            Awaiting Election
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function RmdNotificationList() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [recordToEdit, setRecordToEdit] = useState({} as RmdNotificationRow);
  const [statusFilter, setStatusFilter] = useState<
    Array<RmdNotificationStatus>
  >([]);
  const [orgRmdNotifications, setOrgRmdNotifications] = useState(
    [] as Array<RmdNotificationResponse>
  );
  const [searchResponse, setSearchResponse] = useState({} as any);

  // Allow the filter items to be selected or deselected
  const handleTaskStatusClick = (
    rmdNotificationStatuses: Array<RmdNotificationStatus>
  ) => {
    if (
      rmdNotificationStatuses.some((status) => statusFilter.includes(status))
    ) {
      setStatusFilter([] as Array<RmdNotificationStatus>);
    } else {
      setStatusFilter(rmdNotificationStatuses);
    }
  };

  // Fetch all RMD notifications for the current organization
  async function getAndSetOrgRmdNotifications(): Promise<void> {
    setIsLoading(true);

    await getOrgRmdNotifications(user.organizationId, user.token, user)
      .then(({ data = [] }) => {
        if (isMounted) {
          const currentYearNotifications = data.filter(
            ({ rmdNotification: { recordCreated = '' } }) =>
              recordCreated &&
              getYear(parseISO(recordCreated)) === getYear(new Date())
          );

          setOrgRmdNotifications(currentYearNotifications);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization RMD notifications'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  const onCancelClick = (): void => {
    setRecordToEdit({} as RmdNotificationRow);
  };

  const onComplete = (): void => {
    setRecordToEdit({} as RmdNotificationRow);
    getAndSetOrgRmdNotifications();
  };

  const linkToRecordAccount = (row: RmdNotificationRow): void => {
    navigate(
      `/accountOwner/${row.accountOwner.accountOwnerId}/account/${row.account.accountId}/?expanded=${AccountDetailAccordionType.recurring}`
    );
  };

  const columns = [
    {
      field: 'accountStatus',
      headerName: ' ',
      width: 0,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const statusIcons = {
          [AccountStatus.open]: <AssignmentTurnedInIcon />,
          [AccountStatus.closed]: <BlockIcon />,
          [AccountStatus.pending]: <TransactionPendingIcon />,
          [AccountStatus.signature]: <TransactionSignatureIcon />,
          [AccountStatus.review]: <TransactionReviewIcon />,
        };
        const [rolesAnchorEl, setRolesAnchorEl] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setRolesAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setRolesAnchorEl(null);
        };
        return (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <IconButton
                color="primary"
                onClick={handleClick}
                aria-label={`account status ${value}`}
                size="large"
              >
                {statusIcons[value.toString()]}
              </IconButton>
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(rolesAnchorEl)}
              onClose={handleClose}
              anchorEl={rolesAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">Status: {value.toString()}</Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'fullName',
      headerName: 'Owner',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const { rmdNotificationStatus } = row;
        const shouldLinkToTransaction =
          rmdNotificationStatus !== RmdNotificationStatus.awaiting;

        return (
          <Button
            onClick={() => {
              if (shouldLinkToTransaction) {
                linkToRecordAccount(row);
              } else {
                setRecordToEdit(row);
              }
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 136,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'notificationSent',
      headerName: 'Sent Date',
      width: 100,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'electionDeadline',
      headerName: 'Election Deadline',
      width: 130,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'rmdNotificationType',
      headerName: 'Notification Type',
      width: 125,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        if (value === RmdNotificationType.seventyTwo) {
          return 'Age 72';
        }
        if (value === RmdNotificationType.seventyThreePlus) {
          return 'Age 73+';
        }
        if (value === RmdNotificationType.firstYear) {
          return 'First Year RMD';
        }
        return 'Age 74+';
      },
    },
    {
      field: 'rmdNotificationStatus',
      headerName: 'Status',
      width: 185,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const statusComponents = {
          [RmdNotificationStatus.resolved]: (
            <Box display="flex" alignItems="center">
              <CheckCircleIcon sx={{ color: 'success.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'success.main', paddingLeft: 1 }}
              >
                Completed{' '}
              </Typography>
            </Box>
          ),
          [RmdNotificationStatus.draft]: (
            <Box display="flex" alignItems="center">
              <PendingIcon sx={{ color: 'warning.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'warning.main', paddingLeft: 1 }}
              >
                Draft
              </Typography>
            </Box>
          ),
          [RmdNotificationStatus.awaiting]: (
            <Box display="flex" alignItems="center">
              <InfoIcon sx={{ color: 'info.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'info.main', paddingLeft: 1 }}
              >
                Awaiting Election
              </Typography>
            </Box>
          ),
        };

        return statusComponents[value as RmdNotificationStatus];
      },
    },
  ] as GridColDef[];

  const rows = rmdNotificationRowsFactory(orgRmdNotifications);

  // Multi-facted filter of entries based on the selected status and search terms
  const filteredRows = rows.filter(
    ({ rmdNotificationStatus, taxpayerIdNumber, fullName }) => {
      const { query = '' } = searchResponse || {};
      const matchesQuery =
        taxpayerIdNumber.toLowerCase().includes(query.toLowerCase()) ||
        fullName.toLowerCase().includes(query.toLowerCase());
      const matchesStatus = statusFilter.includes(rmdNotificationStatus);

      if (query && statusFilter.length) {
        return matchesQuery && matchesStatus;
      }

      return (
        (query && matchesQuery) ||
        (statusFilter.length && matchesStatus) ||
        (!query && !statusFilter.length && rows)
      );
    }
  );

  useEffect(() => {
    if (user.organizationId && user.token) {
      getAndSetOrgRmdNotifications();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token]);

  // If editing, create the recur distributions election steps
  if (recordToEdit.id >= 0) {
    return (
      <Layout>
        <FormPaper>
          <RecurringDistributionElection
            account={recordToEdit.account}
            accountOwner={recordToEdit.accountOwner}
            onCancel={onCancelClick}
            onComplete={onComplete}
          />
        </FormPaper>
      </Layout>
    );
  }

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography color="secondary" variant="h1" gutterBottom>
            RMD Notifications
          </Typography>

          <Box maxWidth={480}>
            <RmdNotificationSearchForm
              setResponse={(data) => {
                setSearchResponse({
                  ...searchResponse,
                  ...data,
                });
              }}
            />
          </Box>
          <RmdNotificationFilter
            statusFilter={statusFilter}
            onItemClick={handleTaskStatusClick}
          />
          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
                Toolbar: DataGridExportToolbar,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              disableColumnMenu
              autoHeight
              columns={columns}
              rows={filteredRows}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default RmdNotificationList;
