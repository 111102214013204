import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgTaxReporting(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="currentColor" fillRule="evenodd" >
        <path d="M11.5233938,0.000273725125 L11.5242387,1.30457395 L12.0657971,1.30457395 L12.0818495,2.54619111 C12.0818495,2.54619111 13.1660928,3.07338571 14.0703798,4.2139983 C14.9732587,5.35488462 14.9735404,6.6717762 14.9735404,6.6717762 L14.9735404,6.6717762 L15.6964631,6.67204993 L15.6964631,7.98866778 L15.0639409,7.98839405 L15.0645042,10.2017354 L16.1619836,10.2025566 L16.1622653,11.8123341 L21.9991551,11.8126078 L21.9993781,13.5993917 C21.2494625,13.2161591 20.3999746,13 19.5,13 C18.322407,13 17.2312523,13.3700868 16.3364999,14.0002965 L16.0007022,14 L16.00044,14.2567987 C14.7786836,15.2655873 14,16.791858 14,18.5 C14,19.0081791 14.0689202,19.5002611 14.1979235,19.9674086 C13.8533797,19.9606731 13.3437616,19.9567571 12.6703862,19.9558994 L12.0651948,19.9558994 C10.4913835,19.9576634 8.22490566,19.9723636 5.26576121,20 L5.26576121,20 L1.70014977,19.996989 L0.000844864886,19.9972627 L7.03437308e-13,11.8123341 L5.8281596,11.8126078 L5.82731474,10.2022829 L7.02308017,10.2022829 L7.02308017,7.9889415 L6.39140286,7.98866778 L6.39168448,6.67204993 L7.1146072,6.67204993 C7.1146072,6.67204993 7.11348072,5.35488462 8.01748614,4.21427203 C8.92064671,3.07338571 10.0046084,2.54619111 10.0046084,2.54619111 L10.0046084,2.54619111 L9.98799268,1.30484767 L10.5298327,1.30457395 L10.5295511,0 L11.5233938,0.000273725125 Z M3,14 L2,14.0002612 L2.0002343,15 L3,15 L3,14 Z M15,14 L14,14 L14,15 L15,15 L15,14 Z M12.9992973,14 L12,14.0007833 L12.0004685,15 L13,15 L12.9992973,14 Z M11,14 L10,14.0005222 L10.0002342,15 L11,15 L11,14 Z M8,14 L7,14 L7,15 L8,15 L8,14 Z M5.99976575,14 L5.00046849,14 L5,15 L6,15 L5.99976575,14 Z M12.9992978,9.00025113 L12,9 L12.0009363,11 L13,10.9997489 L12.9992978,9.00025113 Z M9.9997657,9.00050232 L9,9 L9,11 L10,11 L9.9997657,9.00050232 Z" id="Combined-Shape"/>
        <path d="M19.5,15 C21.9852814,15 24,17.0147186 24,19.5 C24,21.9852814 21.9852814,24 19.5,24 C17.0147186,24 15,21.9852814 15,19.5 C15,17.0147186 17.0147186,15 19.5,15 Z M20,16 L19,16 L19,16.6909105 C18.419295,16.9322152 18,17.5738909 18,18.3335137 C18,19.2981914 18.6730056,20.0832432 19.5,20.0832432 C19.775974,20.0832432 20,20.3451074 20,20.6664863 C20,20.9878652 19.775974,21.2497295 19.5,21.2497295 C19.224026,21.2497295 19,20.9878652 19,20.6664863 L19,20.6664863 L18,20.6664863 C18,21.4261091 18.419295,22.0677848 19,22.3090895 L19,22.3090895 L19,23 L20,23 L20,22.3090895 C20.580705,22.0677848 21,21.4261091 21,20.6664863 C21,19.7018086 20.3269944,18.9167568 19.5,18.9167568 C19.224026,18.9167568 19,18.6548926 19,18.3335137 C19,18.0121348 19.224026,17.7502705 19.5,17.7502705 C19.775974,17.7502705 20,18.0121348 20,18.3335137 L20,18.3335137 L21,18.3335137 C21,17.5738909 20.580705,16.9322152 20,16.6909105 L20,16.6909105 L20,16 Z" id="Combined-Shape"/>
      </g>
    </SvgIcon>
  );
}

export default SvgTaxReporting;
