import React from 'react';
import { SelectProps } from '@mui/material';

import { useStates } from './useStates';
import SiraSelectField, { SiraSelectItem } from './SiraSelectField';
import { Country } from '../../api/StatesApi.d';

export interface StateProps {
  country?: Country;
  filterFunction?: any;
  allowNoneState?: boolean;
}

type StateFieldProps = StateProps & SelectProps;

const StateField: React.FC<StateFieldProps> = ({
  filterFunction,
  allowNoneState,
  country: countryToMatch = Country['en-US'],
  ...props
}) => {
  const { states = [] } = useStates();

  const selectedCountryFilter = ({ country }) => country === countryToMatch;

  const items: Array<SiraSelectItem> = states
    .filter(selectedCountryFilter)
    .map(({ name: stateName = '', abbreviation = '' }) => ({
      label: stateName,
      value: abbreviation,
    }));

  const filteredStates = () => {
    const filtered = filterFunction ? items.filter(filterFunction) : items;

    if (allowNoneState) {
      filtered.unshift({
        label: 'None',
        value: 'XX',
      });
    }

    return filtered;
  };


  return (
    states.length > 0 && <SiraSelectField {...props} items={filteredStates()} />
  );
};

export default StateField;
