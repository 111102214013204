import React, { ComponentType } from 'react';
import { Navigate, RouterProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/Loading';
import { useUser } from './useUser';
import { UserRole } from '../api/UserApi.d';
import { PaginationContextProvider } from './GlobalContext';

interface ProtectedRouteProps extends RouterProps{
  roles?: Array<UserRole>;
  component: ComponentType;
}

function ProtectedRoute(props: ProtectedRouteProps) {
  const { user } = useUser();
  const { component, roles } = props;
  const { roles: userRoles } = user;
  const hasMatchingRoles =
    roles && userRoles
      ? userRoles.some((role) => roles.includes(role as UserRole))
      : true;

  const AuthenticatedComponent = withAuthenticationRequired(component, {
    onRedirecting: () => {
      return <Loading />;
    },
  });

  return hasMatchingRoles ? (
    <PaginationContextProvider>
      <AuthenticatedComponent />
    </PaginationContextProvider>
  ) : (
    <Navigate to="/" />
  );
}

export default ProtectedRoute;
