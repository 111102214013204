import React from 'react';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';

import StepButtonBar from '../../steps/StepButtonBar';

import SiraSelectField from '../SiraSelectField';
import {
  BeneficiariesClaimsInfo,
  BeneficiaryStatus,
} from '../../../api/BeneficiaryClaimsApi.d';

export const beneficiarySupportedStatus = [
  { value: BeneficiaryStatus.default, label: '' },
  { value: BeneficiaryStatus.pending, label: 'Pending' },
  { value: BeneficiaryStatus.processing, label: 'Submitted for Processing' },
  { value: BeneficiaryStatus.completed, label: 'Completed' },
  { value: BeneficiaryStatus.awaitingElection, label: 'Awaiting Election' },
  { value: BeneficiaryStatus.awaitingAdditionalInfo, label: 'Awaiting Additional Information' },
  { value: BeneficiaryStatus.notProcessed, label: 'Not Processed' },
];

export const BENEFICIARIES_STATUS_INIT: BeneficiariesClaimsInfo = {
  beneficiaryClaimStatus: BeneficiaryStatus.default,
};

export interface BeneficiariesClaimsStatusSelectFieldProps {
  initialValues: BeneficiariesClaimsInfo;
  onSubmit?: Function;
}

function BeneficiariesClaimsStatusSelectField({
  initialValues,
  onSubmit,
}: BeneficiariesClaimsStatusSelectFieldProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container>
            <Grid item xs={11}>
              <SiraSelectField
                items={beneficiarySupportedStatus}
                name="beneficiaryClaimStatus"
                label="Beneficiary Status"
              />{' '}
            </Grid>
            <Grid item>
              <StepButtonBar isSubmitting={isSubmitting} submitName='Save'/>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default BeneficiariesClaimsStatusSelectField;
