import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  Box,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';

import FinancialOrgEsignSettingsForm, {
  FINANCIAL_ORG_SETTINGS_INT,
} from '../form/organization/FinancialOrgEsignSettings';
import {
  getOrgEsignFieldMappings,
  getOrgEsignSetting,
  updateEsignOrgFields,
  updateEsignOrgFieldsMappings,
} from '../../api/OrganizationApi';
import {
  FiduciaryType,
  DocumentationAddress,
  SpousalConsentOption,
  EsignLandingPage,
} from '../../api/OrganizationApi.d';
import { UserRole } from '../../api/UserApi.d';
import {
  eSignLandingPageLabels,
  globalPaginationOptions,
} from '../../app.constants';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { errorMessages } from '../../utils/errorhandling.utils';

interface EsignSettings {
  eSign: boolean;
  eSignWithSuperior: boolean;
  eSignPassthroughId: boolean;
  esignDefaultAppUserId: string;
  esignUserId: string;
  esignHostFiid: string;
  eSignLandingPage: EsignLandingPage;
  eSignApiKey: string;
  eSignBaseUrl: string;
}

function OrganizationEsignSettings() {
  let isMounted = true;
  const { organization, setCurrentOrg, addGlobalMessage } = useGlobalContext();
  const { setGlobalPageSize, globalPageSize } = usePaginationContext();

  const { user } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [esignSettingsData, setEsignSettingsData] = useState([] as any);

  const [isAdvancedEsignSetting, setAdvancedEsignSetting] = useState(false);

  const {
    name = '',
    financialOrganizationId = '',
    legalName = '',
    federalTaxId = '',
    fiduciaryType = FiduciaryType.default,
    spousalConsentRequired = SpousalConsentOption.community,
    solution = '',
    documentationAddress = '' as DocumentationAddress,
    eSign= false,
    eSignWithSuperior= false,
  } = organization;

  const [organizationEsign, setOrganizationEsign] = useState({
    eSign: eSign,
    eSignWithSuperior: eSignWithSuperior,
    eSignPassthroughId: false,
    esignDefaultAppUserId: '',
    esignUserId: '',
    esignHostFiid: '',
    eSignLandingPage: '' as EsignLandingPage,
    eSignApiKey: '',
    eSignBaseUrl: '',
  } as any);

  const columns = [
    {
      field: 'superiorValue',
      headerName: 'Superior Value',
      width: 200,
    },
    {
      field: 'immField',
      headerName: 'Index Field Name',
      width: 200,
      editable: true,
    },
  ] as GridColDef[];

  async function saveOrganization(data) {
    setIsLoading(true);
    const cleanedData: EsignSettings = {
      eSign: data.eSign,
      eSignWithSuperior: data.eSignWithSuperior,
      eSignPassthroughId: data.isEsignPassthroughId,
      esignDefaultAppUserId: data.esignDefaultAppUserId,
      esignUserId: data.esignUserId,
      esignHostFiid: data.esignHostFiid,
      eSignLandingPage: data.eSignLandingPage,
      eSignApiKey: data.eSignApiKey,
      eSignBaseUrl: data.eSignBaseUrl,
    };
    setOrganizationEsign((prevSatate) => ({
      ...prevSatate,
      ...cleanedData,
    }));


    await updateEsignOrgFields(cleanedData, user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          setIsEditing(false);
          setIsLoading(false);
          setOrganizationEsign((prevSatate) => ({
            ...prevSatate,
            ...res.data,
            ...cleanedData,
          }));
          setCurrentOrg(res.data);
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error saving organization information'
          );
        }
      });
  }

  async function fetchOrganizationEsignFieldMapping() {
    setIsLoading(true);
    // get organization Advanced settings
    await getOrgEsignFieldMappings(user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          const data = res.data.map((row, id) => ({ ...row, id }));
          setEsignSettingsData((prevState) => [...prevState, ...data]);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error fetching organization information'
          );
        }
      });
  }

  async function fetchOrganizationEsign() {
    setIsLoading(true);
    // get organization Advanced settings
    await getOrgEsignSetting(user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          setOrganizationEsign((prevSatate) => ({
            ...prevSatate,
            ...res.data,
          }));
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error fetching organization information'
          );
        }
      });
  }

  async function saveTable() {
    const removeId = esignSettingsData.map(
      ({ id, superiorValue, ...keepAttrs }) => keepAttrs
    );
    await updateEsignOrgFieldsMappings(removeId, user.organizationId, user.token)
      .then(() => {
        if (isMounted) {
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setAdvancedEsignSetting(true);
          addGlobalMessage(
            errorMessages(err) || 'Error saving organization information'
          );
        }
      });
  }

  useEffect(() => {
    fetchOrganizationEsign();
    fetchOrganizationEsignFieldMapping();
    return () => {
      isMounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <Box width="1" pt={10} pb={10} textAlign="center">
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return isEditing ? (
    <FinancialOrgEsignSettingsForm
      initialValues={{
        ...FINANCIAL_ORG_SETTINGS_INT,
        ...organizationEsign,
        // Pass through some other required org properties here even though the fields aren't available in the UI
        // But avoid spreading branches, etc which have their own endpoints
        name,
        financialOrganizationId,
        legalName,
        federalTaxId,
        fiduciaryType,
        spousalConsentRequired,
        solution,
        documentationAddress,
        eSign,
        eSignWithSuperior,
      }}
      onSubmit={async (data) => {
        await saveOrganization(data);
      }}
      onCancel={() => {
        setIsEditing(false);
      }}
    />
  ) : (
    <Box width="1">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Esign </Typography>
          <Typography variant="body1">
            {organizationEsign.eSign ? 'Enabled' : 'Disabled'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Superior Esign </Typography>
          <Typography variant="body1">
            {organizationEsign.eSignWithSuperior ? 'Enabled' : 'Disabled'}
          </Typography>
        </Grid>
        {organizationEsign.eSign && (
          <>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Esign Landing Page </Typography>
              <Typography variant="body1">
                {eSignLandingPageLabels[organizationEsign.eSignLandingPage]}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Esign Pass-through </Typography>
              <Typography variant="body1">
                {organizationEsign.eSignPassthroughId ? 'Enabled' : 'Disabled'}
              </Typography>
            </Grid>
            {user.roles.includes(UserRole.multi) && (
              <>
                {organizationEsign.esignDefaultAppUserId && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                      Esign Default App User ID{' '}
                    </Typography>
                    <Typography variant="body1">
                      {organizationEsign.esignDefaultAppUserId}
                    </Typography>
                  </Grid>
                )}{' '}
                {organizationEsign.esignUserId && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Business App User ID </Typography>
                    <Typography variant="body1">{organizationEsign.esignUserId}</Typography>
                  </Grid>
                )}
                {organizationEsign.esignHostFiid && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                      Host Financial Institution ID
                    </Typography>
                    <Typography variant="body1">{organizationEsign.esignHostFiid}</Typography>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12}>
          {/* 
          Advance Settings in the OrganizationEsignSettings withing an Accordion
          */}
          <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
            <AccordionSummary
              disabled={isLoading}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="h5" color="secondary">
                Advanced E-sign Settings
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography
                variant="body2"
                color="primary"
                mt={4}
                mb={4}
                fontStyle="italic"
              >
                Double click an Index Field Name to edit the value.
              </Typography>
              <DataGridPro
                slots={{
                  noRowsOverlay: SiraNoRowsOverlay,
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: globalPageSize } },
                }}
                pagination
                pageSizeOptions={globalPaginationOptions}
                onPaginationModelChange={setGlobalPageSize}
                autoHeight
                columns={columns}
                rows={esignSettingsData}
                onCellEditStart={() => {
                  // open save button
                  setAdvancedEsignSetting(true);
                }}
                processRowUpdate={(updatedRow) => {
                  const updatedRows = esignSettingsData.map((row) => {
                    if (row.id === updatedRow.id) {
                      return updatedRow;
                    }
                    return row;
                  });
                  setEsignSettingsData(updatedRows);

                  return updatedRow;
                }}
              />
              {isAdvancedEsignSetting && (
                <Box mt={4}>
                  <Button
                    aria-label="Edit organization settings"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      saveTable();
                      setAdvancedEsignSetting(false);
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Button
          aria-label="Edit organization settings"
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit Details
        </Button>
      </Box>
    </Box>
  );
}

export default OrganizationEsignSettings;
