/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';
import {
  NotificationPreferences,
  User,
  UserRole,
} from '../../../api/UserApi.d';
import PhoneNumberField, {
  PhoneNumberFormat,
  phoneValidation,
} from '../PhoneNumberField';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import { Branch } from '../../../api/OrganizationApi.d';
import SiraSwitchField from '../SiraSwitchField';
import { notificationSelectItems } from '../../../app.constants';
import { useUser } from '../../../auth/useUser';

export const USER_INFO_INIT: User = {
  branchId: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  organizationRole: '',
  notificationPreference: NotificationPreferences.default,
  mainContact: false,
};

export const USER_INFO_SCHEMA = {
  firstName: yup.string().required().max(40).label('First Name'),
  lastName: yup.string().required().max(40).label('Last Name'),
  phoneNumber: phoneValidation(PhoneNumberFormat.unitedStates).label(
    'Phone Number'
  ),
  emailAddress: yup
    .string()
    .required()
    .email()
    .nullable()
    .max(50)
    .label('Email'),
  designateContact: yup.boolean().label('Designate as Contact'),
  notificationPreference: yup
    .string()
    .required()
    .label('Notification Preference'),
};

export interface UserInfoFormProps {
  initialValues: User;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  branches?: Array<Branch>;
  isEditing?: boolean;
}

function userBranchSelectItemsFactory(
  branches: Array<Branch>
): Array<SiraSelectItem> {
  return branches.map(({ branchId = '', name = '' }) => {
    return {
      value: branchId,
      label: name,
    } as SiraSelectItem;
  });
}

const UserInfoForm = ({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
  branches = [],
  isEditing = false,
}: UserInfoFormProps) => {
  const branchSelectItems = userBranchSelectItemsFactory(branches);
  const { user } = useUser();
  const [userRole, setUserRole] = useState(false as boolean);
  const { token, organizationId } = user;

  useEffect(() => {
    if (token && organizationId) {
      setUserRole(user.roles.includes(UserRole.orgUserAdmin) && isEditing);
    }
  }, [token, organizationId]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      validationSchema={yup.object(USER_INFO_SCHEMA)}
      enableReinitialize
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <Box maxWidth={480}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SiraTextField name="firstName" label="First Name" />
                </Grid>
                <Grid item xs={12}>
                  <SiraTextField name="lastName" label="Last Name" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <PhoneNumberField
                        name="phoneNumber"
                        label="Phone Number"
                      />
                    </Grid>
                    <Grid item>
                      <SiraTextField
                        name="phoneNumberExtension"
                        label="Extension"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <SiraTextField
                    disabled={!!initialValues.emailAddress}
                    name="emailAddress"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SiraTextField name="organizationRole" label="Job Title" />
                </Grid>
                <Grid item xs={12}>
                  <SiraSelectField
                    name="branchId"
                    items={branchSelectItems}
                    label="Branch"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <SiraSwitchField
                          checked={values.mainContact}
                          name="mainContact"
                          disabled={!userRole}
                        />
                      </Grid>
                      <Grid item>Designate as Main Contact</Grid>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SiraSelectField
                    name="notificationPreference"
                    items={notificationSelectItems}
                    label="Notification Preference"
                  />
                </Grid>
              </Grid>
            </Box>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserInfoForm;
