/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import { useDebounce } from 'use-debounce';
import { PieChart, Pie, Cell } from 'recharts';
import { Grid, Box, Paper, Typography } from '@mui/material';
import { useTheme, darken } from '@mui/material/styles';
import { netIncomeAttributableReasons } from './getDistributionCodes';
import { useUser } from '../../../auth/useUser';
import { DistributionAmountFormData } from './DistributionAmountForm.d';
import { Distribution } from '../../../api/DistributionApi.d';
import { getDistributionWithholdingAmounts } from '../../../api/DistributionApi';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { DistributionChoices } from './DistributionAmountRecurringSubForm';

interface DistributionSummaryProps {
  debounceDelay?: number;
  saveCalculatedInfo?: Function;
  showWithholding?: boolean;
  showStateWithholding?: boolean;
}

function DistributionSummary(props: DistributionSummaryProps) {
  let isMounted = true;
  const theme = useTheme();
  const { addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const {
    debounceDelay = 600,
    saveCalculatedInfo,
    showWithholding = true,
    showStateWithholding = true,
  } = props;

  const classes = {
    summaryCard: {
      padding: theme.spacing(2),
    },
    summaryContent: {
      transition: 'filter ease-in-out 0.66s',
      '&.loading': {
        transition: 'filter ease-in-out 4s',
        filter: 'blur(7px)',
      },
    },
  };

  const { values: distribution, setFieldValue } = useFormikContext();
  const [debouncedDistribution] = useDebounce(distribution, debounceDelay);
  const [calculatedAmounts, setCalculatedAmounts] = useState(
    {} as Distribution
  );
  const [isLoading, setIsLoading] = useState(false);
  const {
    netAmount,
    totalAmount,
    federalWithholdingPercent,
    stateWithholdingPercent,
    withholdingState = '',
    netAmountEntered,
    effectiveDate,
    additionalStateWithholding,
    penaltyAmount,
    recurringSelection,
    netIncomeAttributable,
    distributionReason,
    allowNoneState,
  } = debouncedDistribution as DistributionAmountFormData;

  const isNIAOptions =
    netIncomeAttributableReasons.RECHAR.includes(distributionReason) ||
    netIncomeAttributableReasons.EXCESS.includes(distributionReason) ||
    netIncomeAttributableReasons.REGULARREVOC.includes(distributionReason) ||
    netIncomeAttributableReasons.CONVERSIONREVOC.includes(distributionReason);


  const {
    totalAmount: calculatedTotalAmount = 0,
    netAmount: calculatedNetAmount = 0,
    federalWithholdingPercent: calculatedFederalWithholdingPercent,
    federalWithholdingAmount,
    stateWithholdingPercent: calculatedStateWithholdingPercent,
    stateWithholdingAmount,
    penaltyAmount: calculatedPenaltyAmount = 0,
  } = calculatedAmounts;

  async function updateWithholdingAmounts() {
    if (
      (netAmount || totalAmount) &&
      recurringSelection !== DistributionChoices.owner
    ) {
      setIsLoading(true);

      await getDistributionWithholdingAmounts(
        debouncedDistribution as DistributionAmountFormData,
        user.token,
        user
      )
        .then((res) => {
          if (saveCalculatedInfo) {
            saveCalculatedInfo(res.data);
          }
          if (isMounted) {
            setIsLoading(false);
            setCalculatedAmounts(res.data);
          }
        })
        .catch((err) => {
          const { response: { data = {} } = {} } = err;
          if (isMounted) {
            addGlobalMessage(data.message);
            setFieldValue('additionalStateWithholding', '');
          }
        });
    }
  }

  React.useEffect(() => {
    updateWithholdingAmounts();

    return () => {
      isMounted = false;
    };
  }, [
    federalWithholdingPercent,
    stateWithholdingPercent,
    netAmountEntered,
    effectiveDate,
    totalAmount,
    netAmount,
    additionalStateWithholding,
    penaltyAmount,
  ]);

  if (!totalAmount && !netAmount) {
    return null;
  }

  const chartColors = [
    theme.palette.secondary.light,
    darken(theme.palette.secondary.light, 0.3),
    darken(theme.palette.secondary.dark, 0.6),
    theme.palette.grey[500],
  ];

  const pieData = [
    {
      name: 'Net Amount',
      value: calculatedNetAmount,
    },
    {
      name: 'Federal',
      value: federalWithholdingAmount,
    },
    {
      name: 'State',
      value: stateWithholdingAmount,
    },
    {
      name: 'Financial Organization Fee Amount',
      value: calculatedPenaltyAmount,
    },
  ];

  return (
    <Box width={256} p={2}>
      {recurringSelection !== DistributionChoices.owner && (
        <Paper elevation={5} sx={classes.summaryCard}>
          <Grid
            container
            sx={classes.summaryContent}
            className={isLoading && 'loading'}
          >
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Distribution Summary
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Total annual amount</Typography>
              <Typography gutterBottom>
                <NumberFormat
                  value={calculatedTotalAmount}
                  prefix="$"
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Typography>
            </Grid>

            <Box width="1">
              <PieChart width={224} height={256}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                >
                  {pieData &&
                    pieData.map((entry, i) => (
                      <Cell
                        key={`pie-data_${entry.name}`}
                        fill={chartColors[i]}
                      />
                    ))}
                </Pie>
              </PieChart>
            </Box>

            <Grid item xs={12}>
              <Box width="4" pt={4} pb={2}>
                <Typography variant="body2">
                  <Box
                    component="span"
                    mr={1}
                    display="inline-block"
                    width={10}
                    height={10}
                    bgcolor={chartColors[0]}
                  />
                  Net Amount
                </Typography>
                <Typography gutterBottom>
                  <NumberFormat
                    value={calculatedNetAmount}
                    prefix="$"
                    displayType="text"
                    thousandSeparator
                    isNumericString
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>

                {isNIAOptions && (
                  <>
                    <Typography variant="body2">
                      <Box
                        component="span"
                        mr={1}
                        display="inline-block"
                        width={10}
                        height={10}
                        bgcolor={chartColors[0]}
                      />
                      NIA Amount
                    </Typography>
                    <Typography gutterBottom>
                      <NumberFormat
                        value={netIncomeAttributable}
                        prefix="$"
                        displayType="text"
                        thousandSeparator
                        isNumericString
                        fixedDecimalScale
                        decimalScale={2}
                      />
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>

            {Boolean(penaltyAmount) && (
              <Grid item xs={12} mb={2}>
                <Typography variant="body2">
                  <Box
                    component="span"
                    mr={1}
                    display="inline-block"
                    width={10}
                    height={10}
                    bgcolor={chartColors[3]}
                  />
                  Fee Amount
                </Typography>
                <Typography gutterBottom>
                  <NumberFormat
                    value={penaltyAmount}
                    prefix="$"
                    displayType="text"
                    thousandSeparator
                    isNumericString
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            )}

            {showWithholding && (
              <>
                <Grid item xs={6}>
                  <Box width="1" pb={2}>
                    <Typography variant="body2">Federal Tax</Typography>
                    <Typography variant="h5">
                      <NumberFormat
                        value={calculatedFederalWithholdingPercent}
                        suffix="%"
                        displayType="text"
                        isNumericString
                      />
                    </Typography>
                  </Box>
                </Grid>

                {showStateWithholding && (
                  <Grid item xs={6}>
                    <Box width="1" pb={2}>
                      <Typography variant="body2">
                        {!allowNoneState && withholdingState} State Tax
                      </Typography>
                      <Typography variant="h5">
                        <NumberFormat
                          value={calculatedStateWithholdingPercent}
                          suffix="%"
                          displayType="text"
                          isNumericString
                        />
                      </Typography>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Typography variant="body2">
                    <Box
                      component="span"
                      mr={1}
                      display="inline-block"
                      width={10}
                      height={10}
                      bgcolor={chartColors[1]}
                    />
                    Federal
                  </Typography>
                  <Typography gutterBottom>
                    <NumberFormat
                      value={federalWithholdingAmount}
                      prefix="$"
                      displayType="text"
                      thousandSeparator
                      isNumericString
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>

                {showStateWithholding && (
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <Box
                        component="span"
                        mr={1}
                        display="inline-block"
                        width={10}
                        height={10}
                        bgcolor={chartColors[2]}
                      />
                      State {!allowNoneState && `(${withholdingState})`}
                    </Typography>
                    <Typography gutterBottom>
                      <NumberFormat
                        value={stateWithholdingAmount}
                        prefix="$"
                        displayType="text"
                        thousandSeparator
                        isNumericString
                        fixedDecimalScale
                        decimalScale={2}
                      />
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default DistributionSummary;
