import React, { useCallback, useState } from 'react';
import { Grid, Box, Switch, FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { AccountContribution } from '../../../api/ContributionApi.d';
import SiraTextField from '../SiraTextField';
import PhoneNumberField from '../PhoneNumberField';

export interface DepositorFormProps {
  responsibleFirstName;
  responsibleLastName;
  responsiblePhoneNumber;
  responsibleEmailAddress;
}

function DepositorForm({
  responsibleFirstName,
  responsibleLastName,
  responsiblePhoneNumber,
  responsibleEmailAddress,
}: DepositorFormProps) {
  const { values, errors, setFieldValue } =
    useFormikContext<AccountContribution>();
  const [useResponsibleIndividual, setUseResponsibleIndividual] = useState(
    values.depositorFirstName === responsibleFirstName &&
      values.depositorLastName === responsibleLastName
  );

  const toggleResponsibleIndividual = useCallback(
    async (event) => {
      const {
        target: { checked },
      } = event;
      setUseResponsibleIndividual(checked);
      if (checked) {
        setFieldValue('depositorFirstName', responsibleFirstName, true);
        setFieldValue('depositorLastName', responsibleLastName, true);
        setFieldValue('depositorPhoneNumber', responsiblePhoneNumber);
        setFieldValue('depositorEmailAddress', responsibleEmailAddress);
      }
    },
    [
      responsibleFirstName,
      responsibleLastName,
      responsiblePhoneNumber,
      responsibleEmailAddress,
      errors,
      setFieldValue,
    ]
  );

  return (
    <>
      <Box pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={useResponsibleIndividual}
                  onChange={toggleResponsibleIndividual}
                />
              }
              label="Responsible Individual is making the deposit"
            />
          </Grid>
          <Grid item xs={6}>
            <SiraTextField
              name="depositorFirstName"
              label="First Name"
              disabled={useResponsibleIndividual}
            />
          </Grid>
          <Grid item xs={6}>
            <SiraTextField
              name="depositorLastName"
              label="Last Name"
              disabled={useResponsibleIndividual}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneNumberField
              name="depositorPhoneNumber"
              label="Phone Number"
              disabled={useResponsibleIndividual}
            />
          </Grid>
          <Grid item xs={6}>
            <SiraTextField
              name="depositorEmailAddress"
              label="Email"
              disabled={useResponsibleIndividual}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DepositorForm;
