import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportIcon from '@mui/icons-material/Report';
import { useNavigate } from 'react-router-dom';

function SiraLayoutError(props) {
  const { error, resetErrorBoundary } = props;
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate('/');
  };

  return (
    <Box bgcolor="white" position="absolute" width="1" top={0} bottom={0}>
      <Box textAlign="center" maxWidth="sm" ml="auto" mr="auto">
        <ReportIcon color="error" sx={{ fontSize: '126px' }} />
        <Typography variant="h3" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="body1" gutterBottom>
          There was an error on this page. You may try again to continue anyway,
          or go back to the dashboard.
        </Typography>
      </Box>

      <Box ml="auto" mr="auto" maxWidth="md">
        <Accordion sx={{ p: 2 }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">More Information</Typography>
          </AccordionSummary>

          <AccordionDetails>
            {/* Render the stack trace on multiple lines */}
            {error.stack.split('\n').map((trace) => (
              <Typography component="p" variant="caption">
                {trace}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button variant="contained" color="info" onClick={resetErrorBoundary}>
            Try Again
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="info"
            onClick={() => {
              goToDashboard();
              resetErrorBoundary();
            }}
          >
            Go to Dashboard
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SiraLayoutError;
