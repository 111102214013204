import React, {  useState } from 'react';
import { Box, Link, Popover, Typography } from '@mui/material';
import NumericFormat from 'react-number-format';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface PopoverChartProps {
  summary: any;
  stateTaxes: number;
}

function PopoverChart(props: PopoverChartProps) {
  const { summary, stateTaxes } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  
  const currencyFormatter = (value) => `$${value.toLocaleString('en-US',{minimumFractionDigits: 0,})}`;

  
  const handleClick = (event) => {
   event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleUserClick = (event) => {
    event.stopPropagation();
  };

  const CustomizedTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload) {
      return (
        <Box
          className="custom-tooltip"
          sx={{ background: 'white', padding: 2 }}
        >
          <Typography
            variant="body1"
            className="label"
            sx={{ padding: 2 }}
          >{`${label}`}</Typography>
          <Typography
            variant="body1"
            className="label"
            sx={{ padding: 2, color: '#63C196' }}
          >{`Federal Tax Withheld: ${currencyFormatter(
            payload[0].value
          )}`}</Typography>
          <Typography
            variant="body1"
            className="label"
            sx={{ padding: 2, color: '#9663C1' }}
          >{`State Tax Withheld: ${currencyFormatter(
            payload[1].value
          )}`}</Typography>{' '}
          <Typography
            variant="body1"
            className="label"
            sx={{ padding: 2, color: '#C19663' }}
          >{`Distribution Amount: ${currencyFormatter(
            payload[2].value
          )}`}</Typography>
        </Box>
      );
    }
  
    return null;
  };

  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Link
        component="button"
        variant="body2"
        onClick={(event) => {
          event.stopPropagation();
          handleClick(event);
        }}
      >
        <NumericFormat
          value={stateTaxes || 0}
          prefix="$"
          displayType="text"
          thousandSeparator
          isNumericString
          fixedDecimalScale
          decimalScale={2}
        />{' '}
      </Link>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ onClick: handleUserClick }}
      >
        <BarChart
          width={1200}
          height={400}
          data={summary}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="state"/>
          <YAxis tickFormatter={currencyFormatter}  />
          <Tooltip content={<CustomizedTooltip/>}/>
          <Legend />
          <Bar dataKey="federalTax" stackId="a" fill="#63C196" />
          <Bar dataKey="stateTax" stackId="a" fill="#9663C1" />
          <Bar dataKey="distributionAmount" fill="#C19663" />
        </BarChart>
      </Popover>{' '}
    </>
  );
}

export default PopoverChart;
