import React from 'react';
import * as yup from 'yup';
import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FieldAttributes, useField } from 'formik';
import InputMask from 'react-input-mask';

interface TaxIdFieldProps extends StandardTextFieldProps {
  ssn?: boolean;
}

export function ssnValidation() {
  return yup
    .string()
    .matches(/\d{3}-\d{2}-\d{4}/, 'SSN must be valid ssn')
    .label('SSN');
}

export function taxIdValidation() {
  return yup
    .string()
    .matches(/\d{2}-\d{7}/, 'Tax ID must be valid ein')
    .label('EIN');
}

const masks = {
  ssn: '999-99-9999',
  ein: '99-9999999',
};

const TaxIdField: React.FC<FieldAttributes<TaxIdFieldProps>> = ({
  ssn = false,
  disabled = false,
  ...textFieldProps
}) => {
  const [field, meta] = useField<{}>(textFieldProps);
  const [showText, setShowText] = React.useState(!ssn);
  const selectedMask = ssn ? masks.ssn : masks.ein;
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <InputMask maskChar={showText ? '_' : ''} mask={selectedMask} {...field}>
      {() => (
        <TextField
          {...field}
          {...textFieldProps}
          helperText={errorText}
          error={Boolean(errorText)}
          variant="outlined"
          fullWidth
          type={showText ? 'text' : 'password'}
          InputLabelProps={{
            disabled,
          }}
          autoComplete="off"
          InputProps={{
            disabled,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle ssn visibility"
                  onClick={() => setShowText(!showText)}
                  size="large"
                >
                  {showText ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </InputMask>
  );
};

export default TaxIdField;
