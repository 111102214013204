import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Alert, Link, AlertTitle } from '@mui/material';

import { useUser } from '../auth/useUser';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import {
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
  addTransactionData,
} from './TransactionReducer';
import { AccountImportColumns } from '../api/AccountApi.d';
import { importAccounts, importAccountsColumns } from '../api/AccountApi';
import UserVerifyForm from '../components/form/newUser/UserVerifyForm';
import FileUploadForm, {
  FileUpload,
  FILE_UPLOAD_INIT,
} from '../components/form/FileUploadForm';
import TransactionStepper, { TransactionStep } from './TransactionStepper';
import FileColumnsForm, {
  FILE_COLUMNS_INIT,
} from '../components/form/FileColumnsForm';
import { useGlobalContext } from '../auth/useGlobalContext';
import { BatchType } from '../api/BatchApi.d';
import { errorMessages } from '../utils/errorhandling.utils';

function AddMultipleAccounts() {
  let isMounted = true;
  const { user } = useUser();
  const [pageState] = useTransactionReducer();
  const { addGlobalMessage } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [columnsData, setColumnsData] = useState({} as AccountImportColumns);
  const { ourHeaders = [], fileHeaders = [], rowData = [] } = columnsData;

  // Get the header data of the file to be imported
  const getFileColumnData = async (files: Array<File>) => {
    setIsLoading(true);

    if (files) {
      await importAccountsColumns(files, user.organizationId, user.token)
        .then((res) => {
          setColumnsData(res.data);
        })
        .catch((err) => {
  
          addGlobalMessage(errorMessages(err) || 'Could not get file columns');
        });
    }

    setIsLoading(false);
  };

  // Do any custom vallidation here on the file
  const validateFileUpload = async (fileUploadInformation: FileUpload) => {
    const { importFiles } = fileUploadInformation;

    if (importFiles) {
      addTransactionData({ fileUploadInformation }, true);
      getFileColumnData(importFiles);
    }
  };

  // Upload the file as a multipart/form-data
  const uploadAccountsFile = async (): Promise<void> => {
    const { importFiles, headerMappings } = pageState.fileUploadInformation;

    await importAccounts(
      importFiles,
      headerMappings,
      user.organizationId,
      user.token
    )
      .then(() => {
        completeTransaction();
      })
      .catch(() => {
        if (isMounted) {
          rejectTransaction();
        }
      });
  };
  const fileTypes = {
    'application/xlsx': ['.xlsx'],
    'text/tsv': ['.tsv'],
  };

  const uploadAccountsSteps: Array<TransactionStep> = [
    {
      label: 'Upload New Account Information',
      stepContent: (
        <Box mt={5} mb={3}>
          <Box mb={2}>
            <Typography gutterBottom>
              <Link href="data-upload-templates/Import_New_Accounts.xlsx">
                Click Here
              </Link>{' '}
              to download an Excel template, or:
            </Typography>
          </Box>
          <FileUploadForm
            fileTypes={fileTypes}
            fileText="tsv, xlsx"
            onSubmit={validateFileUpload}
            initialValues={{
              ...FILE_UPLOAD_INIT,
              ...pageState.fileUploadInformation,
            }}
          />
        </Box>
      ),
    },
    {
      label: 'Assign Columns',
      stepContent: (
        <Box mt={5} mb={3}>
          <FileColumnsForm
            onSubmit={(fileUploadInformation) => {
              addTransactionData({ fileUploadInformation }, true);
            }}
            initialValues={{
              ...FILE_COLUMNS_INIT,
              ...pageState.fileUploadInformation,
            }}
            ourHeaders={ourHeaders}
            fileHeaders={fileHeaders}
            rowData={rowData}
          />
        </Box>
      ),
    },
    {
      label: 'Verify and Complete',
      stepContent: (
        <Box mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserVerifyForm
                complete={pageState.completed}
                onSubmit={uploadAccountsFile}
              />
            </Grid>
            <Grid item xs={12}>
              {(pageState.completed || pageState.rejected) && (
                <Alert
                  severity={pageState.rejected ? 'error' : 'success'}
                  variant="outlined"
                >
                  <AlertTitle>
                    {pageState.rejected ? (
                      'Accounts file could not be uploaded.'
                    ) : (
                      <Typography gutterBottom>
                        File successfully uploaded.                         <Link
                          sx={{ fontStyle: 'italic' }}
                          href={`/dataImport?type=${BatchType.accounts}`}
                        >
                          View
                        </Link> the Data Import page to
                        review any errors that may have occurred from the
                        upload.

                      </Typography>
                    )}
                  </AlertTitle>
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    // Clear out previous column mappings if changing to a different file before upload
    addTransactionData(
      {
        fileUploadInformation: {
          headerMappings: {},
        },
      },
      false
    );

    return () => {
      isMounted = false;
    };
  }, [pageState.fileUploadInformation.importFile]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Transactions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Import Multiple Accounts
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            The steps below will help you import new accounts to the financial
            organization.
          </Typography>

          <Box mt={5}>
            <TransactionStepper
              steps={uploadAccountsSteps}
              activeStep={pageState.activeStep}
              onStepClick={(index) => {
                skipStep(index);
              }}
              isLoading={isLoading}
            />
          </Box>
        </>
      </FormPaper>
    </Layout>
  );
}

export default AddMultipleAccounts;
