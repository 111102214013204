import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#0C1F2A', // Midnight Blue
    light: '#55626A',
    dark: '#030A0F',
  },
  secondary: {
    main: '#63C196', // This is the lighter green
    light: '#92D4B6',
    dark: '#51B383',
  },
  text: {
    primary: '#231F20', // black for the most part
    secondary: '#368E64', // Text secondary is 'Grassy Green', should be replaced by secondary dark?
  },
};

const typography = {
  fontFamily: 'Lato',
  h1: {
    fontSize: '54px',
    fontWeight: 300,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 600,
  },
};

const demoTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontVariantLigatures: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '8px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          color: `${palette.text.secondary} !important`,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: `${palette.secondary.main} !important`,
          '&.Mui-completed': {
            color: '#5B5B5B !important',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '42px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
          verticalAlign: 'baseline !important',
        },
      },
    },
  },
  palette,
  typography,
});

export default demoTheme;
