import React, { memo } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';


import StepButtonBar from '../../steps/StepButtonBar';
import { AccountType } from '../../../api/AccountApi.d';
import SiraCheckbox from '../SiraCheckbox';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';

export interface OrgOptionsFormData {
  accountTypesSupported: Array<AccountType>;

}

export const ORG_OPTIONS_INIT: OrgOptionsFormData = {
  accountTypesSupported: [],
};

export const ORG_OPTIONS_SCHEMA = {
  accountTypesSupported: yup
    .array(yup.string())
    .label('Supported Account Types'),
};

export interface OrgOptionsFormProps {
  initialValues: OrgOptionsFormData;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

function OrgOptionsForm({
  initialValues,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
}: OrgOptionsFormProps) {
  const { getAppConstant } = useGlobalContext();

  const supportedAccountTypeOptions = [
    {
      value: AccountType.traditionalIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalIra
      ),
    },
    {
      value: AccountType.rothIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.rothIra
      ),
    },
    {
      value: AccountType.traditionalSep,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.traditionalSep
      ),
    },
    {
      value: AccountType.simpleIra,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.simpleIra
      ),
    },
    {
      value: AccountType.esa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.esa),
    },
    {
      value: AccountType.hsa,
      label: getAppConstant(ConstantsMappingKey.accountType, AccountType.hsa),
    },
    {
      value: AccountType.inheritedTraditional,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.inheritedTraditional
      ),
    },
    {
      value: AccountType.inheritedRoth,
      label: getAppConstant(
        ConstantsMappingKey.accountType,
        AccountType.inheritedRoth
      ),
    },
  ];

  return (
    <section>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
        onReset={() => onReset()}
        validationSchema={yup.object(ORG_OPTIONS_SCHEMA)}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                {/* Supported Account Types */}
                <Grid item xs={12}>
                  <Typography>
                    Account types this organization supports
                  </Typography>
                  {supportedAccountTypeOptions.map(({ value, label }) => (
                    <Box mt={1.5} key={value}>
                      <Field
                        name="accountTypesSupported"
                        value={value}
                        label={label}
                        type="checkbox"
                        component={SiraCheckbox}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>

              <StepButtonBar
                isSubmitting={isSubmitting}
                submitName={submitName}
                resetName={resetName}
              />
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}

export default memo(OrgOptionsForm);
