import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';
import { parseISO, formatISO } from 'date-fns';
import { Box, Grid, LinearProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useUser } from '../../../auth/useUser';
import { getLifeExpectancyTerms } from '../../../api/LifeExpectancyApi';
import { LifeExpectancyTerm } from '../../../api/LifeExpectancyApi.d';
import { determineAgeGroup } from '../../../app.constants';
import SiraRadioField from '../SiraRadioField';
import TermLengthWarning from './TermLengthWarning';
import { AccountType, Account } from '../../../api/AccountApi.d';
import {
  TermChoiceOption,
  TermEndDateAction,
} from '../../../api/RecurringDistributionApi.d';
import { getTermChoices } from './getDistributionTermChoices';

export interface DistributionTermChoiceProps {
  account: Account;
  initialTerms?: LifeExpectancyTerm;
}

export const parentChoiceLabels = {
  [TermChoiceOption.empty]: '',
  [TermChoiceOption.rothInfinite]: `continue distributions until the account is depleted or a new election is made`,
  [TermChoiceOption.rothTerm]:
    'manually select number of years a term of {0.term} years',
  [TermChoiceOption.inheritedRoth10]:
    'manually select number of years a term of 10 years or fewer',
  [TermChoiceOption.inheritedRoth5]:
    'manually select number of years a term of 5 years or fewer',
  [TermChoiceOption.ult]:
    'based on the account owner’s individual life expectancy a term of {0.term} years',
  [TermChoiceOption.jle]:
    'based on the joint life expectancy of the account owner and spouse a term of {0.term} years',
  [TermChoiceOption.manual]:
    'manually select term less than life expectancty a term of {0.term} years',
  [TermChoiceOption.stop73]:
    'to stop once age 73 was reached a term of {0.term} years',
  [TermChoiceOption.before73]:
    'manually enter term ending before age 73 a term of {0.term} years',
  [TermChoiceOption.continuePast73]:
    'to continue or be replaced by larger RMD at age 73 a term of {0.term} years',
  [TermChoiceOption.tradInfinite]: `continue distributions until the account is depleted or a new election is made`,
};

const DistributionTermChoice = ({
  account,
  initialTerms,
}: DistributionTermChoiceProps) => {
  const { values, setValues, setFieldValue } = useFormikContext<any>();
  const { spouseBirthDate, ignoreSpouseBirthDate, termChoice } = values;
  const { user } = useUser();
  const [termOptions, setTermOptions] = React.useState([] as any);
  const [rmd, setRmd] = React.useState(initialTerms);
  const [error, setError] = React.useState('');
  const birthDate = parseISO(rmd.accountOwner.dateOfBirth);
  const { underHalf59, yearsTo73 } = determineAgeGroup(
    rmd.accountOwner.dateOfBirth
  );
  const { accountId, accountOwnerId, accountType } = account;
  const [debouncedSpouseBirthDate] = useDebounce(spouseBirthDate, 900);

  // Get the end date action based on the selected term choice
  const termEndDateAction =
    {
      [TermChoiceOption.stop73]: TermEndDateAction.end,
      [TermChoiceOption.before73]: TermEndDateAction.end,
      [TermChoiceOption.continuePast73]: TermEndDateAction.createRmd,
      [TermChoiceOption.tradInherited]: TermEndDateAction.end,
      [TermChoiceOption.rothTermInherited]: TermEndDateAction.end,
      [TermChoiceOption.inheritedTrad10]: TermEndDateAction.end,
      [TermChoiceOption.inheritedRoth10]: TermEndDateAction.end,
      [TermChoiceOption.rothTermJle]: TermEndDateAction.end,
      [TermChoiceOption.tradInfinite]: TermEndDateAction.end,
    }[termChoice] || '';

  // Get the term length per termChoice
  const termLength =
    {
      [TermChoiceOption.jle]:
        rmd.jointLifeExpectancyRMD &&
        rmd.jointLifeExpectancyRMD.lifeExpectancyFactor,
      [TermChoiceOption.ult]:
        rmd.uniformLifetimeRMD && rmd.uniformLifetimeRMD.distributionPeriod,
      [TermChoiceOption.stop73]: birthDate && yearsTo73,
      [TermChoiceOption.continuePast73]: birthDate && yearsTo73,
      [TermChoiceOption.inheritedRoth10]: 10,
      [TermChoiceOption.inheritedRoth5]: 5,
      [TermChoiceOption.rothTermInherited]: values.lifeExpectancy,
      [TermChoiceOption.tradInherited]: values.lifeExpectancy,
      [TermChoiceOption.tradInfinite]: -1,
    }[termChoice] || '';

  // Under 59.5 but not for inherited accounts
  const showTermLengthWarning =
    underHalf59 &&
    ![AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(
      accountType
    );

  async function fetchLifeExpectancyTerms(spouseDob: string): Promise<void> {
    const hasPrimaryJleBeneficiary =
      initialTerms.primaryBeneficiaries.length === 0 &&
      termChoice === TermChoiceOption.jle;

    setError('');
    await getLifeExpectancyTerms(
      user.organizationId,
      accountId,
      accountOwnerId,
      hasPrimaryJleBeneficiary ? spouseDob : '',
      '',
      user.token,
      user
    )
      .then((response) => {
        const { data } = response;
        const jleValue = Boolean(data.jointLifeExpectancyRMD);

        setTermOptions(getTermChoices(data));
        setRmd(data);
        setFieldValue('qualifiedJLE', jleValue);

        // In the case that the server knows the jle number already we need to set DOB for validation but ignore that DOB for all future calculations
        if (
          spouseBirthDate === '' &&
          data.jointLifeExpectancyRMD &&
          data.jointLifeExpectancyRMD.spouseAge
        ) {
          setFieldValue('ignoreSpouseBirthDate', true);
          setFieldValue('spouseBirthDate', formatISO(new Date()).slice(0, 10));
        }

        // In the case where we have selected jle and are returning that value, set it as the term
        if (
          data.jointLifeExpectancyRMD &&
          [TermChoiceOption.jle].includes(termChoice)
        ) {
          setFieldValue(
            'termLength',
            data.jointLifeExpectancyRMD.lifeExpectancyFactor
          );
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  // This effect calls the api for the rmd, onLoad and when the spouseBirthDate changes, mostly to get the termLength
  useEffect(() => {
    const spouseDob = debouncedSpouseBirthDate || '';

    if (accountId) {
      fetchLifeExpectancyTerms(spouseDob);
    }
  }, [debouncedSpouseBirthDate, ignoreSpouseBirthDate]);

  // This effect manages the termLength because it is sometimes inferred from the term choice
  useEffect(() => {

    setValues({
      ...values,
      termLength,
      termEndDateAction,
    });
  }, [termChoice]);

  return (
    <Box p={0}>
      {error && <Alert severity="error">Error: {error}</Alert>}
      {termOptions.length > 0 ? (
        <Grid container>
          <Grid item xs={12}>
            {showTermLengthWarning && (
              <TermLengthWarning dateOfBirth={rmd.accountOwner.dateOfBirth} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Field
              name="termChoice"
              options={termOptions}
              component={SiraRadioField}
            />
          </Grid>
        </Grid>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
};

export default DistributionTermChoice;
