import React, { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Paper, Typography } from '@mui/material';

import { useUser } from '../../../auth/useUser';
import {
  setSearchResponse,
  useTransactionReducer,
} from '../../../page/TransactionReducer';

import {
  AccountOwner,
  AccountOwnerAccount,
} from '../../../api/AccountOwnerApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { createOrSaveAccount, getAccount } from '../../../api/AccountApi';
import { Account } from '../../../api/AccountApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';
import DeceasedOwnerForm from '../inheritedIra/DeceasedOwnerForm';
import SiraAccountOwnerInfo from '../SiraAccountOwnerInfo';


interface AddDeceasedAccountOwnerProps {
  accountOwnerId: string;
  account: AccountOwnerAccount;
  setAddingDeceasedAccountOwner: Function;
}

function AddDeceasedAccountOwner(props: AddDeceasedAccountOwnerProps) {
    let isMounted = true;
  const { account, accountOwnerId, setAddingDeceasedAccountOwner } = props;
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [pageState] = useTransactionReducer();
  const { addGlobalMessage } = useGlobalContext();
  const [accountSelected, setAccountSelected] = useState<boolean>(false);
  const [deceasedAccountInformation, setDeceasedAccountInformation] =
    useState<Account>();
  const [deceasedAccountOwnerInformation, setDeceasedAccountOwnerInformation] =
    useState<AccountOwner>();
    const { accountId } = account;
const [accountLookup, setAccountLookup] = useState<Account>();
  const setDeceasedAccount = (accountInformation: Account & AccountOwner) => {
    setDeceasedAccountInformation(accountInformation);
  };

  const setDeceasedAccountOwner = (accountOwnerInformation: AccountOwner) => {
    setDeceasedAccountOwnerInformation(accountOwnerInformation);
    setAccountSelected(true);
  };

  //   const setDeceasedAccountInfo = (deceasedAccountInformation: Account) => {
  //     addTransactionData({ deceasedAccountInformation }, false);
  //   };

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccountData(
  ): Promise<void> {

    await getAccount(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(async (res) => {
       
        setAccountLookup(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Could not fetch the preselected account'
        );
      });
  }

  // Create or update the owner and the account the inheritance is going into
  const createOrUpdateAccount = async (): Promise<void> => {
    setIsLoading(true);

    const { externalAccountOwnerAccountId = '' } =
    pageState.deceasedAccountOwnerInformation;
    
    const mergedAccount = {
      ...accountLookup,
        deceasedAccountId:  deceasedAccountInformation.accountId,
        externalAccountOwnerAccountId,

    };

    await createOrSaveAccount(
      mergedAccount,
      user.organizationId,
      accountOwnerId,
      user.token,
      user
    )
      .then(() => {
        setIsLoading(false);
        setAddingDeceasedAccountOwner(false);        
        addGlobalMessage('Deceased Account successfully created', { severity: 'success' });

      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Could not create or update the account'
        );
      });
  };


  // set account and accountOwnerId with pagestate.selectedAccount
  useEffect(() => {
    if (user.token && user.organizationId) {
      if (isMounted) {
          fetchAndSetAccountData(

          );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);    
  return (
    <>
      {!accountSelected && (
        <Box mt={5}>
          <Box mt={2}>
            <Typography variant="h6">Add Deceased Account Owner</Typography>
          </Box>
          <DeceasedOwnerForm
            initialValues={{
              searchResponse: pageState.searchResponse,
              externalAccount: {
                ...pageState.deceasedAccountInformation,
                ...pageState.deceasedAccountOwnerInformation,
              },
              deceasedOwnerOption:
                pageState.deceasedAccountInformation.deceasedOwnerOption,
            }}
            onSetDeceasedAccount={setDeceasedAccount}
            onSetDeceasedOwner={setDeceasedAccountOwner}
            setSearchResponse={setSearchResponse}
          />

          {/*
            paper for selected account
        */}
        </Box>
      )}
      {accountSelected && (
        <Box mt={2}>
          <Typography variant="h6">Deceased Account Owner</Typography>

          <Paper elevation={3}>
            <SiraAccountOwnerInfo
              selectedAccount={deceasedAccountInformation}
              accountOwner={deceasedAccountOwnerInformation}
            />
          </Paper>
        </Box>
      )}
      {/**
       * cancel button and submit button for the form
       */}
      <Box mt={2}>
        {isLoading && <LinearProgress color='secondary' />}
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setAddingDeceasedAccountOwner(false) }
        >
          Cancel
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          variant="contained"
          color="primary"
          onClick={() => {
            createOrUpdateAccount();
          }}
          disabled={isLoading}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}

export default AddDeceasedAccountOwner;
