import { Account } from './AccountApi.d';
import { AccountOwner } from './AccountOwnerApi.d';

export interface RmdNotificationsResponse {
  data?: Array<RmdNotificationResponse>;
  errorMessage?: any;
  status?: number;
}

export interface RmdNotificationResponse {
  rmdNotification: RmdNotification;
  accountOwner: AccountOwner;
  account: Account;
}

export interface RmdNotification {
  rmdNotificationId?: string;
  financialOrganizationId?: string | number ;
  accountOwnerId?: string;
  accountId?: string;
  recurringDistributionId?: string;
  rmdNotificationStatus?: RmdNotificationStatus;
  rmdNotificationType?: RmdNotificationType;
  recordCreated?: string;
  notificationSent?: string;
  responseReceived?: string;
  defaultApplied?: string;
}

export enum RmdNotificationStatus {
  pending = 'PENDING',
  awaiting = 'AWAITING_ELECTION',
  resolved = 'RESOLVED',
  draft = 'DRAFT',
  sent = 'NOTIFICATION_SENT',
}

export enum RmdNotificationType {
  seventyTwo = 'SEVENTY_TWO',
  seventyThreePlus = 'SEVENTY_THREE_PLUS',
  firstYear = 'FIRST_YEAR_RMD',
  rmdAfterReqDob = 'RMD_AFTER_REQ_DOB',
}
