import axios from 'axios';
import format from 'string-format';
import { AccountOwnerSearchForm } from '../components/form/accountSearch/AccountSearch';
import { AccountOwnerSearchResponse } from './AccountOwnerApi.d';
import { prepareBinaryJson, setBaseUrl } from './api.utils';
import {
  BeneDocumentResponse,
  BeneficiariesClaimResponse,
  BeneficiariesClaimResult,
  BeneficiariesClaimsDeleteDocumentResponse,
  BeneficiariesDocumentClaimResponse,
  BeneficiaryClaim,
  BeneficiaryStatus,
  ClaimsBeneficiary,
  ClaimsBeneficiaryResponse,
  DeathCertificateResponse,
} from './BeneficiaryClaimsApi';
// eslint-disable-next-line import/no-cycle
import { BeneficiaryDistributionOption } from '../components/form/beneficiariesClaims/DesignatedBeneficiaryProfile';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const beneficiaryClaimsMultiOrgUrl = '/api/beneficiaryClaim';
const beneficiaryClaimSearchUrl =
  '/api/org/{0.orgId}/accountOwner/search?text={0.query}';
const beneficiaryClaimStatusUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimId}/status';
const beneficiaryClaimUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaim';
const beneficiaryClaimUrlUpdate =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimId}';
const beneficiaryClaimFileUpload =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/upload/beneficiaryClaim/{0.beneficiaryClaimId}/uploadFiles';
const beneficiaryClaimProfileUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaimForAccount';
const beneficiaryClaimDeleteUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimId}';
const beneficiaryClaimDeleteDocumentUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimId}/beneficiaryClaimDocument/{0.beneficiaryClaimDocId}';
const attachBeneficiaryToClaimUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimId}/beneficiary/{0.beneficiaryId}/attach';
const detachBeneficiaryToClaimUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimId}/account/{0.accountId}/beneficiary/{0.beneficiaryId}/detach';
const deathCertificateUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/deathCertificate';
const createDeathCertificateUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/upload/deathCertificate';
const beneficiaryClaimDocumentUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimId}/beneficiaryClaimDocument/{0.beneficiaryClaimDocId}';
  const getbeneDistributionPDFGenerateUrl = '/api/org/{0.orgId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimID}/beneficiary/{0.beneficiaryID}/election';
  const postbeneDistributionPDFGenerateUrl = '/api/org/{0.orgId}/account/{0.accountId}/beneficiaryClaim/{0.beneficiaryClaimID}/beneficiary/{0.beneficiaryID}/election/attach';

function claimsFactory(data: any): BeneficiariesClaimResult {
  const {
    beneficiaryClaimId,
    financialOrganizationId,
    accountOwnerId,
    accountId,
    beneficiaryId,
    dateOfDeath,
    beneficiaryClaimStatus,
    dateOfDeathAccountBalance,
    relationshipToAccountOwner,
    otherRelationshipToAccountOwner,
    firstName,
    lastName,
    preferredContactMethod,
    pcmEmail,
    pcmPhone,
    pcmAddress,
    pcmCity,
    pcmState,
    pcmZip,
    finOrgContactUserId,
    finOrgSubmittedByUserId,
    comments,
    submittedDate,
    designateBeneficiary,
  } = data;

  return {
    beneficiaryClaimId,
    financialOrganizationId,
    accountOwnerId,
    accountId,
    beneficiaryId,
    beneficiaryClaimStatus,
    dateOfDeathAccountBalance,
    relationshipToAccountOwner,
    otherRelationshipToAccountOwner,
    firstName,
    lastName,
    preferredContactMethod,
    pcmEmail,
    pcmPhone,
    pcmAddress,
    pcmCity,
    pcmState,
    pcmZip,
    finOrgContactUserId,
    finOrgSubmittedByUserId,
    comments,
    submittedDate,
    dateOfDeath,
    designateBeneficiary,
  };
}

export async function getBeneficiariesClaimsProfile(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any
): Promise<BeneficiariesClaimResponse> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${beneficiaryClaimProfileUrl}`,
    {
      orgId,
      accountId,
      accountOwnerId,
    }
  )}`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDeathCertificate(
  accountOwnerId: string,
  orgId: string,
  token: string
): Promise<DeathCertificateResponse> {
  const url = `${urlBase}${format(deathCertificateUrl, {
    orgId,
    accountOwnerId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getMultiOrgBeneficiaryClaims(
  token: string
): Promise<AccountOwnerSearchResponse> {
  const url = `${urlBase}${beneficiaryClaimsMultiOrgUrl}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function searchBeneficiariesClaims(
  data: AccountOwnerSearchForm,
  orgId: string,
  token: string
): Promise<AccountOwnerSearchResponse> {
  const { query = '' } = data;
  const url = `${urlBase}${format(beneficiaryClaimSearchUrl, {
    orgId,
    query,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeBeneficiaryClaimStatus(
  beneficiaryClaimId: string,
  orgId: string,
  status: BeneficiaryStatus,
  token: string,
  accountId: string,
  accountOwnerId: string
): Promise<BeneficiariesClaimResult> {
  const params = { status };
  const url = `${urlBase}${format(beneficiaryClaimStatusUrl, {
    orgId,
    beneficiaryClaimId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.put(
    url,
    {},
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export async function deleteBeneficiaryClaimDocument(
  beneficiaryClaimId: string,
  beneficiaryClaimDocId: number,
  orgId: string,
  token: string,
  accountId: string,
  accountOwnerId: string
): Promise<BeneficiariesClaimsDeleteDocumentResponse> {
  const url = `${urlBase}${format(beneficiaryClaimDeleteDocumentUrl, {
    orgId,
    beneficiaryClaimId,
    beneficiaryClaimDocId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteDeathCertificate(
  orgId: string,
  token: string,
  accountOwnerId: string
): Promise<DeathCertificateResponse> {
  const url = `${urlBase}${format(deathCertificateUrl, {
    orgId,
    accountOwnerId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createOrSaveBeneficiaryClaim(
  data: BeneficiaryClaim,
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string
): Promise<BeneficiariesClaimResponse> {
  const { beneficiaryClaim } = data;
  const { beneficiaryClaimId = '', beneficiaryClaimStatus } = beneficiaryClaim;
  const url = `${urlBase}${format(beneficiaryClaimUrl, {
    orgId,
    beneficiaryClaimId,
    accountId,
    accountOwnerId,
  })}`;
  const urlupdate = `${urlBase}${format(beneficiaryClaimUrlUpdate, {
    orgId,
    beneficiaryClaimId,
    beneficiaryClaimStatus,
    accountId,
    accountOwnerId,
  })}`;

  if (beneficiaryClaimId) {
    return axiosInstance.put(urlupdate, claimsFactory(beneficiaryClaim), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, claimsFactory(beneficiaryClaim), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateBeneficiaryClaimStatus(
  orgId: string,
  beneficiaryClaimId: string,
  accountId: string,
  accountOwnerId: string,
  token: string,
  state: BeneficiaryStatus
): Promise<BeneficiariesClaimResponse> {
  const params = { state };
  const url = `${urlBase}${format(beneficiaryClaimStatusUrl, {
    orgId,
    beneficiaryClaimId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.put(
    url,
    {},
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export async function saveDeathCertificateFile(
  data: BeneficiaryClaim,
  accountOwnerId: string,
  orgId: string,
  token: string
): Promise<BeneficiariesDocumentClaimResponse> {
  const { files } = data;
  const url = `${urlBase}${format(createDeathCertificateUrl, {
    orgId,
    accountOwnerId,
  })}`;

  const formData = new FormData();

  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append('file', file);
    });
  }
  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function saveBeneficiaryClaimFiles(
  data: BeneficiaryClaim,
  beneficiaryClaimId: string,
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  deathCertificate?: boolean
): Promise<BeneficiariesDocumentClaimResponse> {
  const { files, fileProperties } = data;
  const url = `${urlBase}${format(beneficiaryClaimFileUpload, {
    orgId,
    beneficiaryClaimId,
    accountId,
    accountOwnerId,
  })}`;

  const formData = new FormData();

  if (deathCertificate) {
    return saveDeathCertificateFile(data, accountOwnerId, orgId, token);
  }

  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (fileProperties && fileProperties.length > 0) {
    formData.append('fileProperties', prepareBinaryJson(fileProperties));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteBeneficiaryClaimStatus(
  orgId: string,
  beneficiaryClaimId: string,
  accountId: string,
  accountOwnerId: string,
  token: string
): Promise<BeneficiariesClaimResponse> {
  const url = `${urlBase}${format(beneficiaryClaimDeleteUrl, {
    orgId,
    beneficiaryClaimId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function attachBeneficiaryToClaim(
  orgId: string,
  beneficiaryClaimId: string,
  accountId: string,
  accountOwnerId: string,
  beneficiaryId: string,
  token: string,
  beneficiaryDistributionOption?: BeneficiaryDistributionOption
): Promise<ClaimsBeneficiaryResponse> {
  const params = { beneficiaryDistributionOption };
  const url = `${urlBase}${format(attachBeneficiaryToClaimUrl, {
    orgId,
    beneficiaryClaimId,
    accountId,
    accountOwnerId,
    beneficiaryId,
  })}`;

  if (beneficiaryDistributionOption !== '') {
    return axiosInstance.put(url, null, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return axiosInstance.put(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function detachBeneficiaryToClaim(
  orgId: string,
  beneficiaryClaimId: number,
  accountId: string,
  accountOwnerId: string,
  beneficiaryId: number,
  token: string
): Promise<ClaimsBeneficiary> {
  const url = `${urlBase}${format(detachBeneficiaryToClaimUrl, {
    orgId,
    beneficiaryClaimId,
    accountId,
    accountOwnerId,
    beneficiaryId,
  })}`;

  return axiosInstance.put(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function downloadBeneficiaryClaimFile(
  orgId: string,
  beneficiaryClaimId: number,
  accountId: string,
  accountOwnerId: string,
  beneficiaryClaimDocId: number,
  token: string
): Promise<BeneDocumentResponse> {
  const url = `${urlBase}${format(beneficiaryClaimDocumentUrl, {
    orgId,
    beneficiaryClaimId,
    accountId,
    accountOwnerId,
    beneficiaryClaimDocId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export async function getBeneficiaryDistributionPDF(
  orgId: string,
  accountId: string,
  beneficiaryClaimID: number,
  beneficiaryID: number,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(getbeneDistributionPDFGenerateUrl, {
    orgId,
    accountId,
    beneficiaryClaimID,
    beneficiaryID,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createBeneficiaryDistributionPDF(
  data: any,
  accountId: string,
  beneficiaryClaimID: number,
  beneficiaryID: number,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(postbeneDistributionPDFGenerateUrl, {
    orgId,
    accountId,
    beneficiaryClaimID,
    beneficiaryID,
  })}`;

  return axiosInstance.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}