import React from 'react';
import { isBefore, parseISO } from 'date-fns';

import { Typography } from '@mui/material';
import {
  AccountContribution,
  DepositCheckPayee,
  DepositMethod,
  DepositType,
} from '../../../api/ContributionApi.d';
import { Account, AccountType } from '../../../api/AccountApi.d';
import { RadioGroupOption } from '../SiraRadioField';
import SelfCertifiedQualifiedReasons from '../../contribution/SelfCertifiedQualifiedReasons';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { getInfoText } from './newaccount.util';
import { priorYearTaxReturnDeadline } from '../../../app.constants';

export default function getDepositTypes(
  getAppConstant: Function,
  account: Account,
  values: AccountContribution,
  depositorSame: boolean,
  statefilingDeadline: string,
  helpText: string,
) {
  // Is responsible individual same as depositor?
  const { accountType } = account;
  const { depositMethod, payableTo, fromAccountType, effectiveDate } = values;
  // Only allow regular prior if the effective date is before prior tax deadline
  const taxFilingDeadline = statefilingDeadline
    ? new Date(statefilingDeadline)
    : priorYearTaxReturnDeadline;

  const allowRegularPrior = isBefore(
    parseISO(effectiveDate),
    taxFilingDeadline
  );

  const depositTypes = {
    regular: {
      value: DepositType.regular,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.regular
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.regular,
        fromAccountType
      ),
    },
    regularPrior: {
      value: DepositType.regularPrior,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.regularPrior
      ),
      afterContent: helpText ? (
        <Typography variant="body2">{helpText}</Typography>
      ) : (
        getInfoText(accountType, DepositType.regularPrior, fromAccountType)
      ),
    },
    transfer: {
      value: DepositType.transfer,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.transfer
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.transfer,
        fromAccountType
      ),
    },
    rollover: {
      value: DepositType.rollover,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.rollover
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.rollover,
        fromAccountType
      ),
    },
    selfCertified: {
      value: DepositType.selfCertified,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.selfCertified
      ),
      afterContent: <SelfCertifiedQualifiedReasons />,
    },
    sep: {
      value: DepositType.sep,
      label: getAppConstant(ConstantsMappingKey.depositType, DepositType.sep),
      afterContent: getInfoText(accountType, DepositType.sep, fromAccountType),
    },
    repayment: {
      value: DepositType.repayment,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.repayment
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.repayment,
        fromAccountType
      ),
    },
    postponed: {
      value: DepositType.postponed,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.postponed
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.postponed,
        fromAccountType
      ),
    },
    recharacter: {
      value: DepositType.recharacter,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.recharacter
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.recharacter,
        fromAccountType
      ),
    },
    rothConversion: {
      value: DepositType.rothConversion,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.rothConversion
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.rothConversion,
        fromAccountType
      ),
    },
    qualifiedHsa: {
      value: DepositType.qualifiedHSA,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.qualifiedHSA
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.qualifiedHSA,
        fromAccountType
      ),
    },
    simple: {
      value: DepositType.simple,
      label: getAppConstant(
        ConstantsMappingKey.depositType,
        DepositType.simple
      ),
      afterContent: getInfoText(
        accountType,
        DepositType.simple,
        fromAccountType
      ),
    },
  };

  function getMethodsForTranditionalIra(): Array<RadioGroupOption> {
    // CHECK
    if (depositMethod === DepositMethod.check) {
      // TRADITIONAL IRA
      if (fromAccountType === AccountType.traditionalIra) {
        if (payableTo === DepositCheckPayee.owner) {
          return [depositTypes.rollover, depositTypes.selfCertified];
        }
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.transfer, depositTypes.sep]; 
        }
      }

      // ROTH
      if (fromAccountType === AccountType.rothIra) {
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.recharacter];
        }
      }

      // RETIREMENT PLAN
      if (fromAccountType === AccountType.employer) {
        return [depositTypes.sep, depositTypes.rollover];
      }

      // NON RETIREMENT
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.regular,
          ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
          depositTypes.rollover,
          depositTypes.selfCertified,
          depositTypes.sep,
          depositTypes.repayment,
          depositTypes.postponed,
        ];
      }
    }

    // CASH
    if (depositMethod === DepositMethod.cash) {
      return [
        depositTypes.regular,
        ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
        depositTypes.rollover,
        depositTypes.selfCertified,
        depositTypes.sep,
        depositTypes.repayment,
        depositTypes.postponed,
      ];
    }

    // ACCOUNT
    if (
      depositMethod === DepositMethod.direct ||
      depositMethod === DepositMethod.transfer 
    ) {
      if (fromAccountType === AccountType.employer) {
        return [depositTypes.sep, depositTypes.rollover];
      }
      if (fromAccountType === AccountType.traditionalIra) {
        return [depositTypes.transfer];
      }
      if (fromAccountType === AccountType.rothIra) {
        return [depositTypes.recharacter];
      }
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.regular,
          ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
          depositTypes.rollover,
          depositTypes.selfCertified,
          depositTypes.sep,
          depositTypes.repayment,
          depositTypes.postponed,
        ];
      }
    }
    return [];
  }

  function getMethodsForRothIra() {
    // CHECK
    if (depositMethod === DepositMethod.check) {
      // TRADITIONAL IRA
      if (fromAccountType === AccountType.traditionalIra) {
        if (payableTo === DepositCheckPayee.owner) {
          return [depositTypes.rothConversion];
        }
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.rothConversion, depositTypes.recharacter];
        }
      }

      // ROTH
      if (fromAccountType === AccountType.rothIra) {
        if (payableTo === DepositCheckPayee.owner) {
          return [depositTypes.rollover, depositTypes.selfCertified];
        }
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.transfer];
        }
      }

      // RETIREMENT PLAN
      if (fromAccountType === AccountType.employer) {
        return [depositTypes.rollover];
      }

      // NON RETIREMENT
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.regular,
          ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
          depositTypes.rollover,
          depositTypes.selfCertified,
          depositTypes.repayment,
          depositTypes.postponed,
        ];
      }
    }

    // CASH
    if (depositMethod === DepositMethod.cash) {
      return [
        depositTypes.regular,
        ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
        depositTypes.rollover,
        depositTypes.selfCertified,
        depositTypes.rothConversion,
        depositTypes.repayment,
        depositTypes.postponed,
      ];
    }

    // ACCOUNT
    if (
      depositMethod === DepositMethod.direct ||
      depositMethod === DepositMethod.transfer
    ) {
      if (fromAccountType === AccountType.employer) {
        return [depositTypes.rollover];
      }
      if (fromAccountType === AccountType.traditionalIra) {
        return [depositTypes.recharacter, depositTypes.rothConversion];
      }
      if (fromAccountType === AccountType.rothIra) {
        return [depositTypes.transfer];
      }
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.regular,
          ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
          depositTypes.rollover,
          depositTypes.selfCertified,
          depositTypes.rothConversion,
          depositTypes.repayment,
          depositTypes.postponed,
        ];
      }
    }
    return [];
  }

  function getMethodsForSepIra() {
    // CHECK
    if (depositMethod === DepositMethod.check) {
      // TRADITIONAL IRA
      if (fromAccountType === AccountType.traditionalIra) {
        if (payableTo === DepositCheckPayee.owner) {
          return [depositTypes.rollover, depositTypes.selfCertified];
        }
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.transfer, depositTypes.sep];
        }
      }

      // ROTH
      if (fromAccountType === AccountType.rothIra) {
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.recharacter];
        }
      }

      if (fromAccountType === AccountType.traditionalSep) {
        return [depositTypes.transfer];
      }

      // RETIREMENT PLAN
      if (fromAccountType === AccountType.employer) {
        return [depositTypes.sep];
      }

      // NON RETIREMENT
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.regular,
          depositTypes.rollover,
          depositTypes.selfCertified,
          depositTypes.sep,
          depositTypes.repayment,
          depositTypes.postponed,
        ];
      }
    }

    // CASH
    if (depositMethod === DepositMethod.cash) {
      return [
        depositTypes.sep,
        depositTypes.rollover,
        depositTypes.selfCertified,
        depositTypes.repayment,
        depositTypes.postponed,
      ];
    }

    // ACCOUNT
    if (
      depositMethod === DepositMethod.direct ||
      depositMethod === DepositMethod.transfer
    ) {
      if (fromAccountType === AccountType.traditionalSep) {
        return [depositTypes.transfer];
      }
      if (fromAccountType === AccountType.traditionalIra) {
        return [depositTypes.transfer];
      }

      if (fromAccountType === AccountType.employer) {
        return [depositTypes.sep];
      }
      if (fromAccountType === AccountType.rothIra) {
        return [];
      }
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.rollover,
          depositTypes.sep,
          depositTypes.selfCertified,
          depositTypes.repayment,
          depositTypes.postponed,
        ];
      }
    }
    return [];
  }

  function getMethodsForSimpleIra() {
    // CHECK
    if (depositMethod === DepositMethod.check) {
      // TRADITIONAL IRA
      if (fromAccountType === AccountType.traditionalIra) {
        if (payableTo === DepositCheckPayee.owner) {
          return [depositTypes.rollover, depositTypes.selfCertified];
        }
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.transfer, depositTypes.simple];
        }
      }

      // RETIREMENT PLAN
      if (fromAccountType === AccountType.employer) {
        return [depositTypes.simple, depositTypes.rollover];
      }

      // NON RETIREMENT
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.simple,
          depositTypes.rollover,
          depositTypes.selfCertified,
          depositTypes.repayment,
        ];
      }
    }

    // CASH
    if (depositMethod === DepositMethod.cash) {
      return [
        depositTypes.simple,
        depositTypes.rollover,
        depositTypes.selfCertified,
        depositTypes.repayment,
      ];
    }

    // ACCOUNT
    if (
      depositMethod === DepositMethod.direct ||
      depositMethod === DepositMethod.transfer
    ) {
      if (fromAccountType === AccountType.simpleIra) {
        return [depositTypes.transfer];
      }
      if (fromAccountType === AccountType.traditionalIra) {
        return [depositTypes.transfer];
      }
      if (fromAccountType === AccountType.employer) {
        return [depositTypes.simple, depositTypes.rollover];
      }
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.simple,
          depositTypes.rollover,
          depositTypes.selfCertified,
          depositTypes.repayment,
        ];
      }
    }
    return [];
  }

  function getMethodsForCoverdell() {
    // If cash and Depositor is Resp Indiv
    if (depositMethod === DepositMethod.cash && depositorSame) {
      return [
        depositTypes.regular,
        ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
        depositTypes.rollover,
      ];
    }
    // If cash and Depositor not Resp Indiv
    if (depositMethod === DepositMethod.cash && !depositorSame) {
      return [
        depositTypes.regular,
        ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
      ];
    }

    if (fromAccountType !== AccountType.esa) {
      return depositorSame
        ? [
            depositTypes.regular,
            ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
            depositTypes.rollover,
          ]
        : [
            depositTypes.regular,
            ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
          ];
    }

    if (fromAccountType === AccountType.esa) {
      if (depositMethod === DepositMethod.check) {
        if (payableTo === DepositCheckPayee.owner) {
          return [depositTypes.rollover];
        }
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.transfer];
        }
      }
      if (
        depositMethod === DepositMethod.direct ||
        depositMethod === DepositMethod.transfer
      ) {
        return [depositTypes.transfer];
      }
      return [depositTypes.rollover, depositTypes.transfer];
    }

    return [];
  }

  function getMethodsForHsa() {
    // CHECK
    if (depositMethod === DepositMethod.check) {
      // TRADITIONAL IRA
      if (fromAccountType === AccountType.hsa) {
        if (payableTo === DepositCheckPayee.owner) {
          return [depositTypes.rollover];
        }
        if (payableTo === DepositCheckPayee.org) {
          return [depositTypes.transfer];
        }
      }

      // IRA
      if (fromAccountType === AccountType.traditionalIra) {
        return [depositTypes.qualifiedHsa];
      }

      // NON HEALTH ACCOUNT
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.regular,
          ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
          depositTypes.rollover,
        ];
      }
    }

    // CASH
    if (depositMethod === DepositMethod.cash) {
      return [
        depositTypes.regular,
        ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
        depositTypes.rollover,
      ];
    }

    // ACCOUNT
    if (
      depositMethod === DepositMethod.direct ||
      depositMethod === DepositMethod.transfer
    ) {
      if (fromAccountType === AccountType.traditionalIra) {
        return [depositTypes.qualifiedHsa];
      }
      if (fromAccountType === AccountType.rothIra) {
        return [depositTypes.qualifiedHsa];
      }
      if (fromAccountType === AccountType.hsa) {
        return [depositTypes.transfer];
      }
      if (fromAccountType === AccountType.other) {
        return [
          depositTypes.regular,
          ...(allowRegularPrior ? [depositTypes.regularPrior] : []),
          depositTypes.rollover,
        ];
      }
      // MSA
      if (fromAccountType === AccountType.msa) {
        return [depositTypes.transfer];
      }
    }
    return [];
  }

  switch (accountType) {
    case AccountType.traditionalIra:
      return getMethodsForTranditionalIra();
    case AccountType.rothIra:
      return getMethodsForRothIra();
    case AccountType.traditionalSep:
      return getMethodsForSepIra();
    case AccountType.simpleIra:
      return getMethodsForSimpleIra();
    case AccountType.esa:
      return getMethodsForCoverdell();
    case AccountType.hsa:
      return getMethodsForHsa();
    case AccountType.inheritedRoth:
    case AccountType.inheritedTraditional:
      return [AccountType.employer].includes(fromAccountType)
        ? [depositTypes.rollover]
        : [depositTypes.transfer];
    default:
      return [];
  }
}
