import React, { useEffect, useState } from 'react';
import { IconButton, Button, Box, Grid, LinearProgress } from '@mui/material';

import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

import { useUser } from '../../auth/useUser';
import { createOrSaveBranch, deleteBranch, getBranch } from '../../api/OrganizationApi';
import { Branch } from '../../api/OrganizationApi.d';
import BranchForm, { BRANCH_INFO_INIT } from '../form/organization/BranchForm';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { globalPaginationOptions } from '../../app.constants';
import DataGridExportToolbar from '../DataGridExportToolbar';
import { errorMessages } from '../../utils/errorhandling.utils';

function OrganizationProfileBranchDetails() {
  let isMounted = true;
  const { user } = useUser();
  const { organization, addGlobalMessage, setCurrentOrg } = useGlobalContext();
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [branchToEdit, setBranchToEdit] = useState({} as Branch);
  const [branchesData, setBranchesData] = useState([] as Array<Branch>);

  async function addOrChangeBranch(branch: Branch) {
    await createOrSaveBranch(branch, organization, user.token)
      .then((res) => {
        if (isMounted) {
          setCurrentOrg({ branches: res.data });
          setShowForm(false);
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error saving branch information'
        );
      });
  }

  async function removeBranch(branch: Branch) {
    setIsLoading(true);

    await deleteBranch(branch, organization, user.token)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setCurrentOrg({ branches: res.data });
          setShowForm(false);
          addGlobalMessage('Branch deleted successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {

        setIsLoading(false);
        addGlobalMessage(errorMessages(err) || 'Error removing branch');
      });
  }

  async function fetchBranch() {
    setIsLoading(true);
    await getBranch(organization, user.token)
      .then((res) => {
        if (isMounted) {
          setBranchesData(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error fetching organization information'
          );
        }
      });
  }

  useEffect(() => {
    if (user.token) {
    fetchBranch();
    }
    return () => {
      isMounted = false;
    };
  }, [user.token]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },

    {
      field: 'phoneNumber',
      headerName: 'Phone',
      width: 156,
    },
    {
      field: 'emailAddress',
      headerName: 'Email',
      width: 156,
    },
    {
      field: 'addressLine1',
      headerName: 'Address Line 1',
      width: 156,
    },
    {
      field: 'addressLine2',
      headerName: 'Address Line 2',
      width: 156,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 156,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 100,
    },
    {
      field: 'primaryBranch',
      headerName: 'Primary Branch',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <>
            {row.primaryBranch && (
              <Grid container alignItems="center" justifyContent="center">
                <CheckIcon />
              </Grid>
            )}
            <Grid container justifyContent="flex-end">
              <IconButton
                data-testid="Edit Branch"
                size="small"
                aria-label="edit branch"
                onClick={() => {
                  setBranchToEdit(row as Branch);
                  setShowForm(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                data-testid="Delete Branch"
                size="small"
                aria-label="delete branch"
                onClick={async () => {
                  await removeBranch(row as Branch);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <Box width="1" pt={10} pb={10} textAlign="center">
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return showForm ? (
    <BranchForm
      initialValues={{
        ...BRANCH_INFO_INIT,
        ...branchToEdit,
      }}
      onSubmit={async (data) => {
        await addOrChangeBranch(data as Branch);
      }}
      onCancel={() => {
        setShowForm(false);
      }}
    />
  ) : (
    <Box width="1">
      <Box>
        <DataGrid
          components={{
            NoRowsOverlay: SiraNoRowsOverlay,
            Toolbar: DataGridExportToolbar,
          }}
          disableColumnMenu
          autoHeight
          columns={columns}
          rows={branchesData}
          initialState={{
            pagination: { paginationModel: { pageSize: globalPageSize } },
            columns: {
              columnVisibilityModel: {
                addressLine2: false,
                addressLine1: false,
                emailAddress: false,
              },
            },
          }}
          pageSizeOptions={globalPaginationOptions}
          onPaginationModelChange={setGlobalPageSize}
          getRowId={(r) => r.branchId}
        />
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setBranchToEdit({} as Branch);
            setShowForm(true);
          }}
        >
          Add Branch
        </Button>
      </Box>
    </Box>
  );
}

export default OrganizationProfileBranchDetails;
