import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { getYear, parseISO, subYears } from 'date-fns';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Grid,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { DatePicker } from '@mui/x-date-pickers';

import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { globalPaginationOptions } from '../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import { useUser } from '../auth/useUser';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import { AccountDetailAccordionType } from '../components/accountOwnerProfile/AccountOwnerAccountDetails';
import DataGridExportToolbar from '../components/DataGridExportToolbar';
import { AccountType } from '../api/AccountApi.d';
import AccountTypesSelectField from '../components/form/AccountTypesSelectField';
import { searchFairMarketValues } from '../api/FairMarketValueApi';
import {
  FairMarketValue,
  FmvSearchResponseObject,
} from '../api/FairMarketValueApi.d';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../utils/DataGrid.utils';
import ReportFMVSelectField, {
  ReportFMVFilter,
} from '../components/form/ReportFMVSelectField';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import { errorMessages } from '../utils/errorhandling.utils';

export interface ReportFMVRow extends FmvSearchResponseObject {
  noFmv: boolean;
  hasFMV: boolean;
  hasTaxYearDoD: boolean;
  hasCurrentDoD: boolean;
}
function reportFMVRowsFactory(
  reportFMVResults: Array<FmvSearchResponseObject>,
  taxYear
): Array<ReportFMVRow> {
  return reportFMVResults.map((result) => {
    const [fairMarketValue = {} as FairMarketValue] = result.fairMarketValues;
    return {
      ...result,
      ...result.fairMarketValues[0],
      ...fairMarketValue.rmdSearchResult,
      noFmv:
        result.fairMarketValues.length === 0 ||
        String(result.fairMarketValues[0].fairMarketValue) === '0',
      hasFMV:
        result.fairMarketValues.length > 0 &&
        String(result.fairMarketValues[0].fairMarketValue) !== '0',
      hasTaxYearDoD:
        getYear(parseISO(result.dateOfDeath)).toString() === taxYear,
      hasCurrentDoD:
        getYear(parseISO(result.dateOfDeath)).toString() ===
        String(getYear(new Date())),
    };
  });
}

const ReportsFmv:FC = () => {
  let isMounted = true;
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage , organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const {  setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [accountTypeFilter, setAccountTypeFilter] = useState(
    [] as Array<AccountType>
  );
  const [hasFmvFilter, setHasFmvFilter] = useState(
    [] as Array<ReportFMVFilter>
  );
  const [filteredRows = [], setFilteredRows] = useState(
    [] as Array<ReportFMVRow>
  );
  const [rows, setRows] = useState([] as Array<ReportFMVRow>);
  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account Number': 'Member Number';


  // Apply all filters to the supplied rows
  const applyFilters = () => {
    // Filter by account type first or pass through if none selected
    const matchingAccountTypeRows = accountTypeFilter.length
      ? rows.filter(({ accountType }) =>
          accountTypeFilter.includes(accountType as AccountType)
        )
      : rows;

    // Then filter on whether the FMV filter is true or false
    const matchingFmvFilterRows = hasFmvFilter.length
      ? matchingAccountTypeRows.filter(
          ({ noFmv, hasFMV, hasTaxYearDoD, hasCurrentDoD }) =>
            (hasFmvFilter.includes(ReportFMVFilter.hasFMV) && hasFMV) ||
            (hasFmvFilter.includes(ReportFMVFilter.hasCurrentDoD) &&
              hasCurrentDoD) ||
            (hasFmvFilter.includes(ReportFMVFilter.hasTaxYearDoD) &&
              hasTaxYearDoD) ||
            (hasFmvFilter.includes(ReportFMVFilter.noFmv) && noFmv)
        )
      : matchingAccountTypeRows;

    setFilteredRows(matchingFmvFilterRows);
  };

  // Fetch all FMV for the given tax year
  const getAndSetOrgFmvs = async (values): Promise<void> => {
    setIsLoading(true);

    // Convert the date passed from the DatePicker to an integer string
    const taxYear = String(getYear(values.taxYear));


    await searchFairMarketValues(user.organizationId, { taxYear }, user.token)
      .then(({ data = [] }) => {
        if (isMounted) {
          if (isMounted) {
            setRows(reportFMVRowsFactory(data, taxYear));
          }
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            'Error fetching organization withholding notifications'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  };

  const gotoRecordAccount = (row: ReportFMVRow): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.contributions}`
    );
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
    },
    {
      field: 'middleInitial',
      headerName: 'Middle Initial',
    },
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      disableExport: true,
      field: 'fullName',
      headerName: 'Owner',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              gotoRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'Taxpayer Id Number',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
    },
    {
      field: 'accountId',
      headerName: 'Account Id',
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 130,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'accountStatus',
      headerName: 'Account Status',
    },
    {
      field: 'accountNumber',
      headerName:headerAccountName,
      width: 126,
    },{
      field: 'taxYear',
      headerName: `Tax Year`,
    },
    {
      field: 'fairMarketValue',
      headerName: `Fair Market Value`,
      width: 180,
      valueFormatter: currencyValueFormatter,
    },{
      field: 'openDate',
      headerName: `Open Date`,
      valueFormatter: dateValueFormatter,
    },{
      field: 'closedDate',
      headerName: `Closed Date`,
      valueFormatter: dateValueFormatter,
    },

    {
      field: 'dateOfDeathAccountBalance',
      headerName: 'Date of Death Balance',
      width: 200,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'dateOfDeath',
      headerName: 'Date of Death ',
      width: 120,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'uniformLifetimeRmd',
      headerName: 'ULE RMD',
      width: 130,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'jointLifeExpectancyRmd',
      headerName: 'JLE RMD',
      width: 130,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'singleLifeExpectancyRmd',
      headerName: 'SLE RMD',
      width: 130,
      valueFormatter: currencyValueFormatter,
    },
  ] as GridColDef[];

  useEffect(() => {
    applyFilters();

    return () => {
      isMounted = false;
    };
  }, [rows, accountTypeFilter, hasFmvFilter]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Reports and Statistics
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Fair Market Values
          </Typography>

          <Formik
            // Datepicker is only going to accept date in taxYear but we translate it to an integer on submit
            initialValues={{ taxYear: subYears(new Date(), 1), hasFmv: true }}
            onSubmit={getAndSetOrgFmvs}
            validationSchema={yup.object({
              taxYear: yup
                .date()
                .min(new Date('1900-01-01'), 'Choose a later year')
                .max(new Date(), 'Cannot be later than current year')
                .nullable()
                .required()
                .label('Tax Year'),
              hasFmv: yup.boolean(),
            })}
          >
            {({ isSubmitting, values, setFieldValue, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
                    <Grid item xs={12} sm={7}>
                      {/* Year-only date viewer */}
                      <DatePicker
                        views={['year']}
                        label="Tax Year"
                        value={values.taxYear}
                        onChange={(newValue) => {
                          setFieldValue('taxYear', newValue);
                        }}
                        slotProps={
                          {
                            textField: {
                              error: Boolean(errors.taxYear),
                              helperText: `${errors.taxYear}`,
                              fullWidth: true,
                            },
                          }
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="secondary"
                        startIcon={<SearchOutlinedIcon />}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <AccountTypesSelectField
                        onChange={setAccountTypeFilter}
                        accountTypes={accountTypeFilter}
                      />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <ReportFMVSelectField
                        onChange={setHasFmvFilter}
                        reportFMVFilter={hasFmvFilter}
                        taxYear={String(getYear(values.taxYear))}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
                Toolbar: DataGridExportToolbar,
              }}
              disableColumnMenu
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                  columnVisibilityModel: {
                    closedDate: false,
                    openDate: false,
                    accountStatus: false,
                    taxYear: false,
                    name: false,
                    middleInitial: false,
                    lastName: false,
                    firstName: false,
                  },
                },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={filteredRows.map((row, id) => ({
                ...row,
                id,
              }))}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default ReportsFmv;
