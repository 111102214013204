import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import {
  Grid,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import {
  distributionGroupOptions,
  determineAgeGroup,
} from '../../../app.constants';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { SiraSelectItem } from '../SiraSelectField';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import StepButtonBar from '../../steps/StepButtonBar';
import { getDistributionCodeOptions } from './getDistributionCodes';
import {
  DistributionCode,
  DistributionCodeGroup,
  DistributionCodeFormData,
  RmdOption,
} from './DistributionCodeForm.d';
import { AccountType, Account } from '../../../api/AccountApi.d';
import { DistributionReason } from '../../../api/DistributionApi.d';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { BeneficiaryTypes } from '../../../api/BeneficiariesApi.d';

export const DISTRIBUTION_CODE_SCHEMA = {
  distributionReason: yup.string().required().label('Distribution Code'),
};

export const DISTRIBUTION_CODE_INIT: DistributionCodeFormData = {
  distributionReason: '' as DistributionReason,
  excludeFromRmd: false,
  rmdOptions: '' as RmdOption,
};

export interface DistributionCodeFormProps {
  account: Account;
  accountOwner: AccountOwner;
  initialValues: DistributionCodeFormData;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}

function distributionCodeOptionFactory(
  distributionCode: DistributionCode
): RadioGroupOption {
  const {
    label = undefined,
    value = undefined,
    afterContent = undefined,
  } = distributionCode;
  return { label, value, afterContent };
}

function DistributionCodeForm({
  account,
  accountOwner,
  initialValues = DISTRIBUTION_CODE_INIT,
  onSubmit = () => {},
  onReset = () => {},
  onCancel,
  submitName,
  resetName,
}: DistributionCodeFormProps) {
  const { accountType = AccountType.init } = account;
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [distributionGroup, setDistributionGroup] = useState('');
  const {
    underHalf59: isEarlyDistribution,
    currently73Years,
    turning73ThisYear,
    over73,
  } = determineAgeGroup(accountOwner.dateOfBirth);
  const selectedGroupOption = distributionGroupOptions.find(
    (groupOption) => groupOption.value === distributionGroup
  );
  const isExcludeRmd = currently73Years || over73 || turning73ThisYear;
  const { getAppConstant } = useGlobalContext();
  const inheritedAdditionalOptions = [AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(accountType) && account.relationship === BeneficiaryTypes.SPOUSE && account.eligibleDesignatedBeneficiary ;

  // Get the distribution codes available by account type + owner age
  const distributionCodes: Array<DistributionCode> = useMemo(() => {
    return getDistributionCodeOptions(
      accountType,
      isEarlyDistribution,
      isExcludeRmd,
    );
  }, [accountType, isEarlyDistribution]);

  // DEFAULT means options that always show regardless of the group filter
  const defaultDistributionReasonOptions: Array<RadioGroupOption> =
    useMemo(() => {
      return distributionCodes.filter(
        ({ group }) => group === DistributionCodeGroup.default
      );
    }, [distributionCodes]);

  // Filtered options by their respective group drop-down selection
  const advancedDistributionReasonOptions: Array<RadioGroupOption> =
    useMemo(() => {
      return distributionCodes

        .filter(({ group }) => group === distributionGroup)
        .map((code) => distributionCodeOptionFactory(code));
    }, [distributionGroup]);

  // Show only the applicable groups per an account's applicable reasons
  const distributionGroupOptionsToDisplay: Array<SiraSelectItem> =
    useMemo(() => {
      return distributionGroupOptions.filter(({ value }) =>
        distributionCodes.some(
          ({ group }) =>
            value === DistributionCodeGroup.empty || value === group
        )
      );
    }, [distributionCodes]);

  // Reverse-lookup the group option using the current distributionReason
  function preselectGroupByDistributionReason(values): void {
    const { group: matchingGroup = '' } =
      distributionCodes.find(
        ({ value }) => value === values.distributionReason
      ) || {};
    const distributionGroupToSelect: SiraSelectItem =
      distributionGroupOptions.find(({ value }) => value === matchingGroup) ||
      ({ value: DistributionCodeGroup.empty } as SiraSelectItem);

    if (distributionGroupToSelect.value) {
      setShowAdvancedOptions(true);
      setDistributionGroup(distributionGroupToSelect.value);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      onReset={() => onReset()}
      validationSchema={yup.object(DISTRIBUTION_CODE_SCHEMA)}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue, resetForm, values }) => {
        useEffect(() => {
          if (advancedDistributionReasonOptions.length === 1) {
            setFieldValue(
              'distributionReason',
              advancedDistributionReasonOptions[0].value
            );
          }
        }, [distributionGroup]);

        useEffect(() => {
          preselectGroupByDistributionReason(values);
        }, [values.distributionReason]);

        useEffect(() => {
          if (values.rmdOptions) {
            setFieldValue(
              'excludeFromRmd',
              values.rmdOptions === RmdOption.excludeFromRmd
            );
          } else {
            setFieldValue('excludeFromRmd', false);
          }
        }, [values.rmdOptions]);

        // set initial value for rmdOptions
        useEffect(() => {
          if (initialValues.excludeFromRmd) {
            setFieldValue('rmdOptions', RmdOption.excludeFromRmd);
          } else {
            setFieldValue('rmdOptions', RmdOption.doNotExcludeFromRmd);
          }
        }, []);

        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  name="distributionReason"
                  options={defaultDistributionReasonOptions}
                  component={SiraRadioField}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showAdvancedOptions}
                      onChange={() => {
                        resetForm({ values: DISTRIBUTION_CODE_INIT });
                        setDistributionGroup('');
                        setShowAdvancedOptions(!showAdvancedOptions);
                      }}
                      inputProps={{ 'aria-label': 'show advanced options' }}
                    />
                  }
                  label="Show more reasons"
                />
              </Grid>
              {showAdvancedOptions && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>More reasons</InputLabel>
                      <Select
                        label="More reasons"
                        variant="outlined"
                        fullWidth
                        value={distributionGroup}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          resetForm({ values: DISTRIBUTION_CODE_INIT });
                          setDistributionGroup(e.target.value);
                        }}
                      >
                        {distributionGroupOptionsToDisplay.map(
                          ({ value = '', label = '' }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {selectedGroupOption && selectedGroupOption.afterContent}
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label=""
                      name="distributionReason"
                      options={advancedDistributionReasonOptions}
                      component={SiraRadioField}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default DistributionCodeForm;
