import React, { useEffect, useMemo, useState } from 'react';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
  Popover,
} from '@mui/material';

import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BlockIcon from '@mui/icons-material/Block';

import {
  Distribution,
  DistributionStatus,
  DistributionTypeEnum,
} from '../../api/DistributionApi.d';
import TransactionSignatureIcon from '../../icons/TransactionSignature';
import TransactionPendingIcon from '../../icons/TransactionPending';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { Account, TransactionType } from '../../api/AccountApi.d';
import {
  RecurringDistribution,
  RecurringDistributionStatus,
} from '../../api/RecurringDistributionApi.d';
import {
  FREQUENCY_DIVISOR,
  FREQUENCY_TEXT,
} from '../form/distribution/DistributionSchedule';
import { Frequency } from '../form/distribution/Frequency.d';
import {
  getRecurringDistributions,
  getRecurringDistributionDocument,
} from '../../api/RecurringDistributionApi';
import RecurringDistributionDisplay from './RecurringDistributionDisplay';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { dateValueFormatter } from '../../utils/DataGrid.utils';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import { errorMessages } from '../../utils/errorhandling.utils';

interface AccountRecurringDistributionsProps {
  account: Account;
  setEditing?: Function;
}

export const distributionTypeText = {
  [DistributionTypeEnum.UNSCHEDULED]: 'Unscheduled',
  [DistributionTypeEnum.SCHEDULED]: 'Scheduled',
  [DistributionTypeEnum.JOINT_LIFE_EXPECTANCY]: 'Joint Life Expectancy',
  [DistributionTypeEnum.SINGLE_LIFE_EXPECTANCY]: 'Single Life Expectancy',
  [DistributionTypeEnum.UNIFORM_LIFETIME]: 'Uniform Lifetime',
};

function AccountRecurringDistributions(
  props: AccountRecurringDistributionsProps
) {
  let isMounted = true;
  const { account = {}, setEditing } = props;
  const { accountId = '', accountOwnerId = '' } = account;
  const navigate = useNavigate();
  const { addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const [allDistributions, setAllDistributions] = useState(
    [] as Array<Distribution>
  );
  const [selectedDistribution, setSelectedDistribution] = useState(
    null as Distribution
  );
  const [fetchingStatus, setFetchingStatus] = useState({} as any);

  // Only show active distributions here
  const distributions = useMemo(
    () =>
      allDistributions.filter(
        ({ distributionStatus }) =>
          distributionStatus === DistributionStatus.active ||
          distributionStatus === DistributionStatus.inActive
      ),
    [allDistributions]
  );

  const classes = {
    distributionLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  async function getActiveRecurringDistributions(): Promise<void> {
    await getRecurringDistributions(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
      [RecurringDistributionStatus.active, RecurringDistributionStatus.inActive]
    )
      .then((res) => {
        if (isMounted) {
          setAllDistributions(
            res.data.map((t) => ({
              ...t,
              transactionType: TransactionType.recurringDistribution,
            }))
          );
        }
      })
      .catch((err) => {
        if (isMounted) {
  
          setAllDistributions([]);
          addGlobalMessage(errorMessages(err) || `Error fetching account distributions`
          );
        }
      });
  }

  useEffect(() => {
    setEditing(allDistributions.length > 0);
  }, [allDistributions]);

  useEffect(() => {
    if (user.organizationId && user.token && accountId) {
      getActiveRecurringDistributions();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token, accountId]);

  useEffect(() => {
    setSelectedDistribution(null);
  }, [props]);

  function goToOrShowTransaction(row: RecurringDistribution) {
    const { recurringDistributionId = '', distributionStatus } = row;
    // Only navigate to WIP transactions
    const shouldGoToTransaction = [
      DistributionStatus.pending,
      DistributionStatus.signature,
    ].includes(distributionStatus);
    let one = 0;
    one = 1;

    if (shouldGoToTransaction && one === 0) {
      navigate(
        `/transactions/recurringDistributions?accountId=${accountId}&distributionId=${recurringDistributionId}`
      );
    } else {
      setSelectedDistribution(row);
    }
  }

  async function openPdf(distributionId: string) {
    await getRecurringDistributionDocument(
      user.organizationId,
      accountOwnerId,
      accountId,
      distributionId,
      user.token,
      '',
      user
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }
  const statusIcons = {
    [DistributionStatus.active]: <AssignmentTurnedInIcon />,
    [DistributionStatus.inActive]: <BlockIcon />,
    [DistributionStatus.pending]: <TransactionPendingIcon />,
    [DistributionStatus.signature]: <TransactionSignatureIcon />,
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFetchingStatus((prevStatus) => ({
      ...prevStatus,
      anchorEl: event.currentTarget,
    }));
  };
  const handleClose = () => {
    setFetchingStatus((prevStatus) => ({
      ...prevStatus,
      anchorEl: null,
    }));
  };

  const columns: GridColDef[] = [
    {
      field: 'distributionStatus',
      headerName: ' ',
      renderCell: (params: GridCellParams) => {
        const { value = '', row = {} } = params;
        const isPending = row.distributionStatus === DistributionStatus.pending;
        const isFetchingDocument = fetchingStatus.fetchingDocument || false;
        const  rolesAnchorEl  = fetchingStatus.anchorEl || null;

        return (
          <>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item>
                <IconButton
                  aria-label={`distribution status ${value}`}
                  size="small"
                  color="primary"
                  onClick={handleClick}
                >
                  {statusIcons[value.toString()]}
                </IconButton>
              </Grid>
              {!isPending && (
                <Grid item>
                  <IconButton
                    disabled={isFetchingDocument}
                    color="primary"
                    size="small"
                    data-testid={`open-distribution-pdf_${row.distributionId}`}
                    aria-label="Open latest pdf document"
                    onClick={async () => {
                      setFetchingStatus((prevStatus) => ({
                        ...prevStatus,
                        [`open-review-transaction-pdf_${row.transactionId}_${row.transactionStatus}`]:
                          false,
                        fetchingDocument: true,
                      }));
                      await openPdf(row.recurringDistributionId);
                      setFetchingStatus((prevStatus) => ({
                        ...prevStatus,
                        [`open-review-transaction-pdf_${row.transactionId}_${row.transactionStatus}`]:
                          true,
                        fetchingDocument: false,
                      }));
                    }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(rolesAnchorEl)}
              onClose={handleClose}
              anchorEl={rolesAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">Status: {value.toString()}</Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'distributionAmountType',
      headerName: 'Type',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const typeId = value.toString();
        const { ownerResponsible = false } = row;

        return (
          <Button
            onClick={() => {
              goToOrShowTransaction(row);
            }}
            sx={classes.distributionLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {ownerResponsible ? 'Owner Responsible' : typeId}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return FREQUENCY_TEXT[value as Frequency];
      },
    },
    {
      field: 'distributionAmount',
      headerName: 'Distribution Amount',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: ({ row }) => {
        const { frequency, totalAmount } = row as RecurringDistribution;
        const divisor = FREQUENCY_DIVISOR[frequency];

        return Number(totalAmount) / divisor;
      },
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const { ownerResponsible = false } = row;
        const amount = value.toString();

        return ownerResponsible ? (
          'N/A'
        ) : (
          <NumberFormat
            value={amount}
            prefix="$"
            displayType="text"
            thousandSeparator
            isNumericString
            fixedDecimalScale
            decimalScale={2}
          />
        );
      },
    },
    {
      field: 'federalWithholdingPercent',
      headerName: 'Federal Withholding',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const { ownerResponsible = false } = row;
        const amount = value.toString();

        return ownerResponsible ? (
          'N/A'
        ) : (
          <NumberFormat
            value={amount}
            suffix="%"
            displayType="text"
            isNumericString
            decimalScale={3}
          />
        );
      },
    },
    {
      field: 'stateWithholdingPercent',
      headerName: 'State Withholding',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const { ownerResponsible = false } = row;
        const amount = value.toString();

        return ownerResponsible ? (
          'N/A'
        ) : (
          <NumberFormat
            value={amount}
            suffix="%"
            displayType="text"
            thousandSeparator
            isNumericString
            decimalScale={2}
          />
        );
      },
    },
  ];

  const resetSelectedDistribution = (id) => {
    getActiveRecurringDistributions();
    setSelectedDistribution(
      allDistributions.find((d) => d.recurringDistributionId === id)
    );
  };

  return (
    <>
      {selectedDistribution ? (
        <RecurringDistributionDisplay
          recurringDistribution={selectedDistribution}
          onExit={() => setSelectedDistribution(null)}
          accountOwnerId={accountOwnerId}
          accountId={accountId}
          onEdit={(id) => {
            resetSelectedDistribution(id);
          }}
        />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              disableColumnMenu
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10]}
              autoHeight
              columns={columns}
              rows={distributions}
              getRowId={(r) => r.recurringDistributionId}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default AccountRecurringDistributions;
