import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Alert,
  AlertColor,
  LinearProgress,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PendingIcon from '@mui/icons-material/Pending';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format, parseISO } from 'date-fns';

import { useUser } from '../../../auth/useUser';
import { batchStatusLabels } from '../../../app.constants';
import {
  BatchInfo,
  BatchItem,
  BatchItemStatus,
  BatchStatus,
  BatchType,
} from '../../../api/BatchApi.d';
import PluralizedString from '../../PluralizedString';
import {
  getBatchDetailsFile,
  getBatchErrorFile,
  markBatchCompletedByUser,
  overrideBatchItem,
} from '../../../api/BatchApi';
import { downloadAsFile } from '../../../utils/App.utils';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import MultiButton, { MultiButtonAction } from '../../MultiButton';
import { errorMessages } from '../../../utils/errorhandling.utils';

interface BatchInfoListProps {
  batchData: Array<BatchInfo>;
  isLoading: boolean;
  onComplete?: Function;
}

interface BatchInfoCardProps {
  batchInfo: BatchInfo;
  onComplete: Function;
}

interface BatchOverrideableDataProps {
  batchItem: BatchItem;
}

interface BatchFailureMessageProps {
  onOverride: Function;
  batchItem: BatchItem;
  row: number;
}

function BatchOverrideableData(props: BatchOverrideableDataProps) {
  const { batchItem = {} } = props;
  const { batchItemObject = {} } = batchItem as BatchItem;

  return (
    <Box
      borderTop="1px solid lightgrey"
      borderBottom="1px solid lightgrey"
      borderRadius={2}
      mb={1}
      mt={1}
      width="1"
      overflow="auto"
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            {Object.keys(batchItemObject).map((key) => (
              <TableCell>{key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {Object.values(batchItemObject).map((value) => (
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {value.toString()}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

function BatchFailureMessage(props: BatchFailureMessageProps) {
  const { onOverride, batchItem, row } = props;
  const { failureResponseMessage, batchItemStatus } = batchItem;
  const [isOverridden, setIsOverridden] = useState(false as boolean);

  return failureResponseMessage ? (
    <Typography variant="body2" gutterBottom>
      <b>Row {row + 1}</b>: {failureResponseMessage}{' '}
      {batchItemStatus === BatchItemStatus.overrideable && !isOverridden && (
        <Box mb={2}>
          <BatchOverrideableData batchItem={batchItem} />
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              onOverride(batchItem);
              setIsOverridden(true);
            }}
          >
            Add Anyway
          </Button>
        </Box>
      )}
    </Typography>
  ) : null;
}

function BatchInfoCard(props: BatchInfoCardProps) {
  let isMounted = true;
  const { batchInfo, onComplete } = props;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [isDownloading, setIsDownloading] = useState(false as boolean);
  const [fileDownloaded, setFileDownloaded] = useState(false as boolean);
  const {
    batchId,
    batchType,
    batchAddedTimestamp,
    batchStatus,
    fileName,
    batchRecordCount,
    batchItemList,
    updatedCount,
    createdCount,
    batchCompletedTimestamp,
  } = batchInfo;

  const importLink =
    {
      [BatchType.accounts]: '/dataImport/newAccounts',
      [BatchType.users]: '/dataImport/addUsers',
      [BatchType.accountOwnerUpdate]: '/dataImport/updateAccountOwners',
      [BatchType.deposit]: '/dataImport/deposits',
      [BatchType.distribution]: '/dataImport/distributions',
      [BatchType.recurringDistribution]: '/dataImport/recurringDistributions',
      [BatchType.fairMarketValue]: '/dataImport/fairMarketValue',
    }[batchType] || '';

  const batchSeverities = {
    [BatchStatus.completed]: 'success',
    [BatchStatus.userCompleted]: 'success',
    [BatchStatus.errors]: 'error',
    [BatchStatus.processing]: 'warning',
    [BatchStatus.reProcessing]: 'warning',
  };

  const displayAsAccordion = [
    BatchStatus.errors,
    BatchStatus.processing,
    BatchStatus.reProcessing,
  ].includes(batchStatus);

  // Export a file with only the errored rows
  const getBatchErrorsAsFile = async (fileType: string): Promise<void> => {
    setIsDownloading(true);

    await getBatchErrorFile(user.organizationId, batchId, fileType, user.token)
      .then((res) => {
        if (isMounted) {
          downloadAsFile(res.data, `${batchType}_errors.${fileType}`);
          setFileDownloaded(true);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error exporting errors as a file'
        );
      });

    if (isMounted) {
      setIsDownloading(false);
    }
  };

  // Export a file with the batch details
  const getBatchDetailsAsFile = async (fileType: string): Promise<void> => {
    setIsDownloading(true);

    await getBatchDetailsFile(
      user.organizationId,
      batchId,
      fileType,
      user.token
    )
      .then((res) => {
        if (isMounted) {
          downloadAsFile(res.data, `${batchType}_audit_details.${fileType}`);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error exporting batch details as a file'
        );
      });

    if (isMounted) {
      setIsDownloading(false);
    }
  };

  // Define the download types
  const downloadActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => getBatchErrorsAsFile('tsv'),
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => getBatchErrorsAsFile('xlsx'),
      icon: <DownloadIcon />,
    },
  ];

  // Define the detail export types
  // Define the download types
  const exportDetailsActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => getBatchDetailsAsFile('tsv'),
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => getBatchDetailsAsFile('xlsx'),
      icon: <DownloadIcon />,
    },
  ];

  // Manualy mark a batch as completed by a user
  const markBatchAsCompleted = async (): Promise<void> => {
    setIsDownloading(true);

    await markBatchCompletedByUser(user.organizationId, batchId, user.token)
      .then(() => {
        onComplete();
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Error marking item complete');

        if (isMounted) {
          setIsDownloading(false);
        }
      });
  };

  // Override a batch item and insert it anyway if it has an overridable error
  const overrideSelectedBatchItem = async (batchItem): Promise<void> => {
    const { batchItemId } = batchItem as BatchItem;

    setIsDownloading(true);

    await overrideBatchItem(user.organizationId, batchItemId, user.token)
      .then(() => {
        addGlobalMessage('Successfully added record', { severity: 'success' });
        onComplete();
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Failed to add record');
      });

    if (isMounted) {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Accordion
      disableGutters
      elevation={2}
      slotProps={{
        transition: {
          mountOnEnter: true,
          unmountOnExit: true,
        },
      }}
    >
      <AccordionSummary
        sx={{ pt: 0, pb: 0 }}
        expandIcon={displayAsAccordion && <ExpandMoreIcon />} // Disguise non-errors as regular alerts
      >
        <Alert
          sx={{
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: '4px',
            zIndex: 0,
          }}
          iconMapping={{
            error: <ErrorIcon titleAccess={batchStatusLabels[batchStatus]} />,
            warning: (
              <PendingIcon titleAccess={batchStatusLabels[batchStatus]} />
            ),
            success: (
              <CheckCircleIcon titleAccess={batchStatusLabels[batchStatus]} />
            ),
          }}
          elevation={0}
          severity={batchSeverities[batchStatus] as AlertColor}
        />
        <Grid
          container
          ml={4}
          zIndex={1}
          position="relative"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              component="span"
              variant="body2"
              sx={{ wordBreak: 'break-all' }}
            >
              {batchAddedTimestamp &&
                format(
                  parseISO(String(batchAddedTimestamp)),
                  'MM/dd/yyyy'
                )}{' '}
              - {fileName && fileName}{' '}
            </Typography>
            <Typography component="span" variant="body2">
              ({batchRecordCount && batchRecordCount}{' '}
              <PluralizedString quantity={batchRecordCount} noun="record" /> |{' '}
              {createdCount} created | {updatedCount} updated  
              {batchCompletedTimestamp && `| completed ${format(parseISO(String(batchCompletedTimestamp)), 'MM/dd/yyyy')}`})
            </Typography>
          </Grid>
          <Grid item mr={1}>
            <MultiButton
              size="small"
              variant="outlined"
              actions={exportDetailsActions}
            >
              Export Details
            </MultiButton>
          </Grid>
        </Grid>
      </AccordionSummary>

      {displayAsAccordion && (
        <AccordionDetails>
          <Box mt={1} maxHeight={256} overflow="scroll">
            {batchStatus === BatchStatus.errors &&
              batchItemList.map((batchItem, i) => (
                <BatchFailureMessage
                  key={`batch_error_${batchItem.batchItemId}`}
                  onOverride={overrideSelectedBatchItem}
                  row={i}
                  batchItem={batchItem}
                />
              ))}
          </Box>
          {!fileDownloaded && (
            <Box mt={2}>
              <Grid container spacing={2}>
                {!isDownloading ? (
                  <>
                    {batchStatus === BatchStatus.errors && (
                      <Grid item>
                        <MultiButton actions={downloadActions}>
                          Download and Fix Errors
                        </MultiButton>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="contained"
                        color="success"
                        disabled={isDownloading}
                        onClick={markBatchAsCompleted}
                        startIcon={<CheckCircleIcon />}
                      >
                        Mark Completed
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <LinearProgress color="secondary" />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
          {fileDownloaded && (
            <Box mt={1}>
              <Alert>
                <Typography variant="body2">
                  File successfully downloaded. Fix the errors and{' '}
                  <RouterLink to={importLink}>
                    <Typography
                      variant="body2"
                      component="span"
                      color="textSecondary"
                    >
                      re-upload the file
                    </Typography>
                  </RouterLink>
                  .
                </Typography>
              </Alert>
            </Box>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
}

function BatchNoOrg() {
  return (
    <Box p={2} pt={5} pb={5}>
      <Alert severity="warning">
        Select an organization to see batch import status
      </Alert>
    </Box>
  );
}

function BatchNoResults() {
  return (
    <Box width="1" textAlign="center" p={3}>
      <Typography variant="body2">No batch import files found</Typography>
    </Box>
  );
}

function BatchInfoList(props: BatchInfoListProps) {
  const { batchData = [], isLoading = false, onComplete = () => {} } = props;
  const { user } = useUser();

  if (!user.organizationId && !isLoading) {
    return <BatchNoOrg />;
  }

  if (batchData.length === 0 && !isLoading) {
    return <BatchNoResults />;
  }

  return (
    <Grid container spacing={2}>
      {batchData.map((batchInfo) => {
        return (
          <Grid
            item
            xs={12}
            key={`batch_${batchInfo.batchId}`}
            sx={{
              filter: isLoading ? 'blur(5px)' : '',
              transition: 'filter 0.33s ease-in-out',
            }}
          >
            <BatchInfoCard onComplete={onComplete} batchInfo={batchInfo} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default BatchInfoList;
