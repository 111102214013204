import { Account } from './AccountApi';
import { AccountOwner } from './AccountOwnerApi';

export interface ContributionsResponse {
  data: Array<AccountContribution>;
  errorMessage?: any;
}

export interface Contribution5498Correction {
  accountId?: number;
  accountOwnerId?: number;
  accountOwnerName?: string;
  accountType?: any;
  id?: number,
  irsTaxFileUploadId?: number,
  matching?: boolean,
  field?: any,
  superiorValue?: string,
  fileValue?: string,
  record?: string,
  data?: string,
  depositValue: '',
  grouping?: any
  distributionReason?: any,
  disabled?: boolean,
}

export interface AccountContribution {
  accountId?: string;
  amount?: number;
  contributionId?: string;
  contributionStatus?: ContributionStatus;
  dateOnCheck?: string;
  depositMethod?: DepositMethod; // cash, check ...
  depositType?: DepositType; // Regular, postponed ...
  effectiveDate?: string;
  fromAccountNumber?: string;
  fromAccountType?: AccountType;
  fromSymitarAccountType?: any;
  fromFinancialOrganization?: string;
  payableTo?: DepositCheckPayee;
  postponedCode?: PostponementCode;
  postponedReason?: PostponementReason;
  repaymentCode?: RepaymentCode;
  signedDate?: string;
  taxYear?: number;
  dateOfWithdrawal?: string;
  transferRequestId?: string; // Only for contributions created by a transfer
  depositorFirstName?: string;
  depositorLastName?: string;
  depositorPhoneNumber?: string;
  depositorEmailAddress?: string;

  // form-only fields
  maxRemainingAmount?: number | string;
  fromCoreAccountId?: string;
}

export interface AccountContributionSearchResult extends AccountContribution {
  account?: Account;
  accountOwner?: AccountOwner;
  contribution?: AccountContribution;
  contributionDepositTypeSummary?: AccountContribution;
}

export interface ContributionImportColumnsResponse {
  data?: ContributionImportColumns;
  errorMessage?: any;
}

export interface ContributionImportColumns {
  ourHeaders?: Array<string>;
  fileHeaders?: Array<string>;
  rowData?: Array<any>;
}

export enum ContributionStatusState {
  next = 'NEXT',
  previous = 'PREVIOUS',
}

export enum ContributionStatus {
  active = 'ACTIVE',
  signature = 'AWAITING_SIGNATURE',
  pending = 'PENDING',
  review = 'PENDING_REVIEW',
  submitOwner = 'SUBMITTED_BY_OWNER',

}

export enum PostponementCode {
  executiveOrder = 'EXECUTIVE_ORDER',
  fedDisasterArea = 'FEDERAL_DISASTER_AREA',
  publicLaw = 'PUBLIC_LAW',
  qaulifiedPlan = 'QUALIFIED_PLAN_LOAN_OFFSET',
}

export enum ContributionLimitType {
  esa = 'ESA',
  hsaFamily = 'HSA-Family',
  hsaSelf = 'HSA-Self',
  ira = 'IRA',
}

export enum PostponementReason {
  afghanistan = 'AFGHANISTAN',
  arabianPen = 'ARABIAN_PENINSULA',
  yugoslaviaEo = 'FEDERAL_REPUBLIC_YUGOSLAVIA_EO',
  yugoslaviaPl = 'FEDERAL_REPUBLIC_YUGOSLAVIA_PL',
  sinaiPen = 'SINAI_PENINSULA_EGYPT',
}

export enum RepaymentCode {
  qualifiedBirth = 'QUALIFIED_BIRTH_OR_ADOPTION_DISTRIBUTION',
  qualifiedDisaster = 'QUALIFIED_DISASTER_REDISTRIBUTION',
  qualifiedReservist = 'QUALIFIED_RESERVIST_DISTRIBUTION',
}

export enum DepositType {
  regular = 'REGULAR_CURRENT_YEAR',
  regularPrior = 'REGULAR_PRIOR_YEAR',
  transfer = 'TRANSFER',
  rollover = 'ROLLOVER',
  selfCertified = 'SELF_CERTIFIED_LATE_ROLLOVER',
  sep = 'SEP',
  repayment = 'REPAYMENT',
  postponed = 'POSTPONED',
  recharacter = 'RECHARACTERIZATION',
  rothConversion = 'ROTH_CONVERSION',
  rothConversionlbl = 'ROTH CONVERSION',
  qualifiedHSA = 'QUALIFIED_HSA_FUNDING_DISTRIBUTION',
  qualifiedHSAlbl = 'QUALIFIED HSA FUNDING DISTRIBUTION',
  esalbl = 'Coverdell ESA',
  hsalbl = 'Health Savings Account',
  simple = 'SIMPLE',
}

export enum DepositMethod {
  cash = 'CASH',
  check = 'CHECK',
  direct = 'DIRECT_DEPOSIT',
  transfer = 'TRANSFER',
}

export enum DepositCheckPayee {
  owner = 'ACCOUNT_OWNER',
  org = 'FIN_ORG',
}

export interface ContributionStatusResponse {
  data?: AccountContribution;
  errorMessage?: any;
}
