import axios from 'axios';
import format from 'string-format';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const accountsExportUrl = '/api/org/{0.orgId}/audit/accountOwner';


export async function exportAccountOwners(
    orgId: string,
    token: string,
    fileType: string,
    startDate: string,
    endDate: string
  ): Promise<any> {
    const url = `${urlBase}${format(accountsExportUrl, {
      orgId,
    })}`;
  
    return axiosInstance.get(url, {
      params: {
        fileType,
        startDate,endDate
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
  }