import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgAddMoney(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M10.462 9.833c0-.726.595-1.233 1.591-1.233 1.05 0 1.438.567 1.474 1.4h1.302c-.041-1.147-.66-2.2-1.892-2.54V6H11.17v1.44c-1.143.28-2.063 1.12-2.063 2.407 0 1.54 1.126 2.306 2.77 2.753 1.474.4 1.768.987 1.768 1.607 0 .46-.288 1.193-1.591 1.193-1.214 0-1.692-.613-1.756-1.4H9c.07 1.46 1.037 2.28 2.169 2.553V18h1.768v-1.433c1.15-.247 2.063-1 2.063-2.367 0-1.893-1.432-2.54-2.77-2.933-1.338-.394-1.768-.8-1.768-1.434z"
          fill="currentColor"
        />
        <path
          d="M15.498 21.8L4.002 22A2.008 2.008 0 012 19.998V4.002C2 2.901 2.901 2 4.002 2h15.996C21.099 2 22 2.901 22 4.002V16.04"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M19.5 15a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm.5 2h-1v2h-2v1h1.999L19 22h1l-.001-2H22v-1h-2v-2z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgAddMoney;
