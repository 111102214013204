import React from 'react';
import { Grid, Box, Typography, AlertTitle, Alert, Link } from '@mui/material';

import { useUser } from '../auth/useUser';
import { Desc } from '../components/form/beneficiariesClaims/ViewBeneficiaryClaimFiles';

import {
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
} from './TransactionReducer';

import Layout from '../components/Layout';
import { BatchType } from '../api/BatchApi.d';
import FileUploadForm, {
  FILE_UPLOAD_INIT,
  FileUpload,
} from '../components/form/FileUploadForm';
import FormPaper from '../components/FormPaper';
import { import1099TaxReports } from '../api/TaxFormApi';
import { useGlobalContext } from '../auth/useGlobalContext';
import { errorMessages } from '../utils/errorhandling.utils';

export const FINANCIAL_ORGANIZATION_DOCS_INIT = {
  uploadFiles: [],
  descriptionField: [{ description: '' }],
};

export interface FinOrgFiles {
  uploadFiles: Array<File>;
  descriptionField: Array<Desc>;
}

function AddTaxReports() {
  const { user } = useUser();
  const [pageState] = useTransactionReducer();
  const {  activeTaxYear, addGlobalMessage  } = useGlobalContext();

  const taxYear = activeTaxYear;

  // Upload the file as a multipart/form-data
  const upload1099File = async (
    fileUploadInformation: FileUpload
  ): Promise<void> => {
    const { importFiles } = fileUploadInformation;

    await import1099TaxReports(importFiles, user.organizationId, user.token, taxYear, 'TaxForm1099')
      .then(() => {
        completeTransaction();
        addGlobalMessage(`Your 1099 file has been successfully uploaded. You'll receive an email after records have been applied.`, {
          severity: 'success',
        });
      })
      .catch((err) => {
        rejectTransaction();
        addGlobalMessage(
          errorMessages(err) || 'Your tax file file could not be uploaded.'
        );
      });
  };

  const fileTypes = {
    'text/tsv': ['.txt'],
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Transactions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Import 1099 Tax Files
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            The steps below will help you import a 1099 tax file for the current
            tax year.
          </Typography>

          <Box mt={5}>
            <Box mt={5} mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FileUploadForm
                    fileTypes={fileTypes}
                    fileText="text files such as .txt"
                    onSubmit={upload1099File}
                    submitName="Upload File"
                    initialValues={{
                      ...FILE_UPLOAD_INIT,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {(pageState.completed || pageState.rejected) && (
                    <Alert
                      severity={pageState.rejected ? 'error' : 'success'}
                      variant="outlined"
                    >
                      <AlertTitle>
                        {!pageState.rejected && (
                          <Typography gutterBottom>
                            Your tax file was successfully uploaded.{' '}
                            <Link
                              sx={{ fontStyle: 'italic' }}
                              href={`/dataImport?type=${BatchType.taxReport1099}`}
                            >
                              View
                            </Link>{' '}
                            the Data Import page to review any errors that may
                            have occurred from the upload.
                          </Typography>
                        )}
                      </AlertTitle>
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      </FormPaper>
    </Layout>
  );
}

export default AddTaxReports;
