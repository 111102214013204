import React, { useEffect, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  useTheme,
  Alert,
  Grid,
  Box,
  Paper,
  Link,
  Typography,
} from '@mui/material';

import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import {
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
  addTransactionData,
} from './TransactionReducer';
import UserInfoForm, {
  USER_INFO_INIT,
} from '../components/form/newUser/UserInfoForm';
import UserRoleForm, {
  USER_ROLE_INIT,
} from '../components/form/newUser/UserRoleForm';
import UserVerifyForm from '../components/form/newUser/UserVerifyForm';
import { useGlobalContext } from '../auth/useGlobalContext';
import { Organization } from '../api/OrganizationApi.d';
import { useUser } from '../auth/useUser';
import { createUser } from '../api/UserApi';
import TransactionStepper, { TransactionStep } from './TransactionStepper';
import { errorMessages } from '../utils/errorhandling.utils';

function AddNewUser() {
  let isMounted = true;
  const theme = useTheme();
  const { user } = useUser();
  const { organization, addGlobalMessage } = useGlobalContext();
  const [pageState] = useTransactionReducer();
  const {
    firstName = '',
    lastName = '',
    emailAddress = '',
    phoneNumber = '',
    organizationRole = '',
    branchId: userBranchId = '',
  } = pageState.userInformation;
  const { branches = [] } = organization as Organization;
  const { name: userBranchName } =
    branches.find(({ branchId = '' }) => branchId === userBranchId) || {};
  const [errorMessage, setErrorMesage] = useState('');

  const classes = {
    profileCard: {
      color: theme.palette.grey[300],
      backgroundColor: theme.palette.primary.main,
    },
  };

  const saveAndCreateUser = async (): Promise<void> => {
    await createUser(pageState.userInformation, user.organizationId, user.token)
      .then(() => {
        if (isMounted) {
          completeTransaction();
        }
      })
      .catch((err) => {
        const { response: { data = {} } = {} } = err;
        if (isMounted) {
          rejectTransaction();
          addGlobalMessage(
            errorMessages(err) || 'Could not create user. Please try again.'
          );
        }
      });
  };

  const makeUserSteps: Array<TransactionStep> = [
    {
      label: 'Enter New User Information',
      stepContent: (
        <Box mt={5} mb={3}>
          <UserInfoForm
            onSubmit={(userInformation) => {
              addTransactionData({ userInformation });
            }}
            initialValues={{
              ...USER_INFO_INIT,
              ...pageState.userInformation,
            }}
            branches={branches}
          />
        </Box>
      ),
    },
    {
      label: 'Roles, and Permissions',
      stepContent: (
        <Box mt={5} mb={3}>
          <UserRoleForm
            onSubmit={(userInformation) => {
              addTransactionData({ userInformation });
            }}
            initialValues={{
              ...USER_ROLE_INIT,
              ...pageState.userInformation,
            }}
          />
        </Box>
      ),
    },
    {
      label: 'Verify and Complete',
      stepContent: (
        <Box mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserVerifyForm
                complete={pageState.completed}
                onSubmit={saveAndCreateUser}
              />
            </Grid>
          </Grid>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Users
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Create New User
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            The steps below will help you add a new employee user to the
            financial organization.
          </Typography>

          {pageState.activeStep > 0 && (
            <Box mt={4}>
              <Paper elevation={3} sx={classes.profileCard}>
                <Box p={4} bgcolor="primary">
                  <Typography color="secondary" variant="overline">
                    Name
                  </Typography>

                  <Typography color="secondary" variant="h3" gutterBottom>
                    {firstName} {lastName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Grid container wrap="nowrap" spacing={1}>
                        <Grid item>
                          <EmailIcon color="secondary" />
                        </Grid>
                        <Grid item>
                          <Link color="inherit" href={`mailto:${emailAddress}`}>
                            <Typography gutterBottom>{emailAddress}</Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container wrap="nowrap" spacing={1}>
                        <Grid item>
                          <PhoneIcon color="secondary" />
                        </Grid>
                        <Grid item>
                          <Link color="inherit" href={`tel:${phoneNumber}`}>
                            <Typography gutterBottom>{phoneNumber}</Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="secondary">
                            Title
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>{organizationRole || '---'}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="secondary">
                            Branch
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>{userBranchName || '---'}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          )}

          <Box mt={5}>
            <TransactionStepper
              steps={makeUserSteps}
              activeStep={pageState.activeStep}
              onStepClick={(index) => {
                skipStep(index);
              }}
            />
          </Box>
        </>
      </FormPaper>
    </Layout>
  );
}

export default AddNewUser;
