import React from 'react';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { parseISO } from 'date-fns';

import SiraTextField from '../SiraTextField';
import SiraDateField, { dateValidation } from '../SiraDateField';
import PhoneNumberField, {
  PhoneNumberFormat,
  phoneValidation,
} from '../PhoneNumberField';
import TaxIdField, { ssnValidation, taxIdValidation } from '../TaxIdField';
import AddressForm, { ADDRESS_SCHEMA, ADDRESS_INIT } from '../AddressForm';
import StepButtonBar from '../../steps/StepButtonBar';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';

export const ACCOUNT_OWNER_INIT: AccountOwner = {
  ...ADDRESS_INIT(),
  emailAddress: '',
  name: '',
  representativeFirstName: '',
  representativeLastName: '',
  firstName: '',
  lastName: '',
  middleInitial: '',
  dateOfBirth: '',
  dateOfDeath: '',
  taxpayerIdNumber: '',
  phoneNumber: '',
  financialOrganizationId: '',
};

export const ACCOUNT_OWNER_SCHEMA = ADDRESS_SCHEMA({ required: true }).shape(
  {
    name: yup
      .string()
      .max(40)
      .label('Name')
      .when('firstName', (firstName, schema) =>
        firstName ? schema : schema.required()
      ),
    representativeFirstName: yup
      .string()
      .max(40)
      .label('Representative First Name')
      .when('name', (name, schema) => (name ? schema.required() : schema)),
    representativeLastName: yup
      .string()
      .max(40)
      .label('RepresentativeLast Name')
      .when('name', (name, schema) => (name ? schema.required() : schema)),
    firstName: yup
      .string()
      .max(40)
      .label('First Name')
      .when('name', (name, schema) => (name ? schema : schema.required())),
    lastName: yup
      .string()
      .max(40)
      .label('Last Name')
      .when('name', (name, schema) => (name ? schema : schema.required())),
    middleInitial: yup.string().max(1).label('Middle Initial'),
    dateOfBirth: yup
      .string()
      .when('name', (name, schema) =>
        name ? schema : dateValidation().required().label('Date of Birth')
      ),
    dateOfDeath: yup
      .string()
      .when('dateOfBirth', (dateOfBirth, schema) =>
        dateOfBirth
          ? dateValidation(parseISO(dateOfBirth), new Date()).label(
              'Date of Death'
            )
          : schema
      ),
    phoneNumber: phoneValidation(PhoneNumberFormat.unitedStates).label(
      'Phone Number'
    ),
    emailAddress: yup.string().email().max(50).label('Email'),
    taxpayerIdNumber: yup
      .string()
      .when('name', (name) => (name ? taxIdValidation() : ssnValidation())),
  },
  [['name', 'firstName']] // These two fields are interdependent
);

export interface AccountOwnerFormProps {
  initialValues: AccountOwner;
  onSubmit?: Function;
  onCancel?: Function;
  submitName?: string;
  isEditing?: boolean;
  showDateOfDeath?: boolean;
}

function AccountOwnerForm({
  initialValues,
  onSubmit,
  onCancel,
  submitName,
  isEditing = false,
  showDateOfDeath = false,
}: AccountOwnerFormProps) {
  const isHuman = !initialValues.name;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={ACCOUNT_OWNER_SCHEMA}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={3}>
              {isHuman && (
                <>
                  <Grid item xs={12} sm={5}>
                    <SiraTextField
                      disabled={isEditing}
                      name="firstName"
                      label="First Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SiraTextField
                      disabled={isEditing}
                      maxLength={1}
                      name="middleInitial"
                      label="MI"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <SiraTextField
                      disabled={isEditing}
                      name="lastName"
                      label="Last Name"
                    />
                  </Grid>
                </>
              )}

              {!isHuman && (
                <>
                  <Grid item xs={12} sm={8}>
                    <SiraTextField
                      disabled={isEditing}
                      name="name"
                      label="Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField
                      disabled={isEditing}
                      name="representativeFirstName"
                      label="Representative First Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField
                      disabled={isEditing}
                      name="representativeLastName"
                      label="Representative Last Name"
                    />
                  </Grid>
                </>
              )}

              {isHuman && (
                <Grid item xs={12} sm={6}>
                  <SiraDateField
                    disabled={isEditing}
                    name="dateOfBirth"
                    label="Birth Date"
                  />
                </Grid>
              )}

              {isHuman && showDateOfDeath && (
                <Grid item xs={12} sm={6}>
                  <SiraDateField
                    disabled={isEditing}
                    name="dateOfDeath"
                    label="Date of Death"
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={7}>
                <TaxIdField
                  disabled={isEditing}
                  name="taxpayerIdNumber"
                  label="SSN"
                  ssn={isHuman}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneNumberField name="phoneNumber" label="Phone Number" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SiraTextField name="emailAddress" label="Email" />
              </Grid>
              <AddressForm international />
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default AccountOwnerForm;
