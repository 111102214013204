// TODO: Find a way to fetch modal content from CRM once one is established

import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';

import SiraModal from '../../SiraModal';

function DistributionTransferESA() {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box>
        <Typography variant="body2">
          A movement of assets from one Coverdell ESA to another ESA for a{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link sx={{ cursor: 'pointer' }} variant="body2" onClick={openModal}>
            qualified member of the designated beneficiary’s family
          </Link>{' '}
          who is under age 30. The responsible individual/designated beneficiary
          does not have access to the assets.
        </Typography>
      </Box>

      <SiraModal
        title="Qualifying Family Members"
        open={modalOpen}
        handleClose={closeModal}
      >
        <>
          <Typography variant="body2">
            Qualifying family members include the designated beneficiary’s
            spouse and the following other relatives:
          </Typography>
          <Typography variant="body2" sx={{ paddingLeft: 3 }}>
            <li>
              Son, daughter, stepchild, foster child, adopted child, or a
              descendant of any of them.
            </li>
            <li>Brother, sister, stepbrother, or stepsister.</li>
            <li>Father or mother or ancestor of either.</li>
            <li>
              Stepfather or stepmother. Son or daughter of a brother or sister.
            </li>
            <li>
              Brother or sister of father or mother. Son-in-law,
              daughter-in-law, father-in-law, mother-in-law, brother-in-law, or
              sister-in-law.
            </li>
            <li>The spouse of any individual listed above.</li>
            <li>First cousin</li>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>NOTE: </strong>The age 30 restriction may be waived for a
            special needs individual.
          </Typography>
        </>
      </SiraModal>
    </>
  );
}

export default DistributionTransferESA;
