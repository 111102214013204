import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

import { Account } from '../../api/AccountApi.d';
import { BeneficiaryStatus } from '../../api/BeneficiariesApi.d';
import DesignateSecondaryBeneficiaries from '../beneficiaries/DesignateSecondaryBeneficiaries';
import { useBeneficiariesReducer } from '../beneficiaries/BeneficiariesReducer';
import DesignatePrimaryBeneficiaries from '../beneficiaries/DesignatePrimaryBeneficiaries';

interface AccountOwnerAccountBeneficiariesProps {
  account: Account;
  beneficiariesAccount: boolean;
}

function AccountOwnerAccountBeneficiaries(
  props: AccountOwnerAccountBeneficiariesProps
) {
  const { account = {}, beneficiariesAccount=false } = props;
  const [beneficiariesState] = useBeneficiariesReducer();

  return (
    <>
      <Box width="1">
        <Box mb={5}>
          <DesignatePrimaryBeneficiaries
            state={beneficiariesState}
            account={account}
            statusFilter={BeneficiaryStatus.live}
            beneficiariesAccount={beneficiariesAccount}
            accountOwnerProfile={true}
          />
        </Box>

        <Divider />

        <Box mt={4}>
          <Typography variant="overline">Contingent</Typography>
          <DesignateSecondaryBeneficiaries
            state={beneficiariesState}
            account={account}
            statusFilter={BeneficiaryStatus.live}
            accountOwnerProfile={true}
          />
        </Box>
      </Box>
    </>
  );
}

export default AccountOwnerAccountBeneficiaries;
