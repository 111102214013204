import React from 'react';
import {
  useTheme,
  Paper,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

import NumberFormat from 'react-number-format';
import { ClaimsBeneficiary } from '../api/BeneficiaryClaimsApi';
import { useUser } from '../auth/useUser';
import { UserRole } from '../api/UserApi.d';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { BeneficiaryDistributionOption } from '../components/form/beneficiariesClaims/DesignatedBeneficiaryProfile';
import { useGlobalContext } from '../auth/useGlobalContext';

interface DesignatedBeneficiariesCardProps {
  beneficiary: ClaimsBeneficiary;
  setSelectedBeneficiary: Function;
  deleteBeneficiary: Function;
  selectedBeneficiaryId: number;
}

function DesignatedBeneficiariesCard(props: DesignatedBeneficiariesCardProps) {
  const {
    beneficiary,
    setSelectedBeneficiary,
    selectedBeneficiaryId,
    deleteBeneficiary = () => {},
  } = props;
  const { fullName, name, percent, relationship, beneficiaryId } = beneficiary;
  const isSelected = beneficiaryId === selectedBeneficiaryId;
  const { user } = useUser();
  const { getAppConstant } = useGlobalContext();

  const theme = useTheme();

  const classes = {
    designeesCard: {
      cursor: 'pointer',
      border: `1px solid transparent`,
      borderTop: `${theme.spacing(3)} solid ${theme.palette.primary.main}`,
      padding: theme.spacing(2),
    },
    designeeCardActive: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderTop: `${theme.spacing(3)} solid ${theme.palette.secondary.main}`,
    },
  };

  const selectBeneficiary = () => {
    setSelectedBeneficiary(beneficiary);
  };

  return (
    <Box onClick={selectBeneficiary}>
      <Paper
        elevation={isSelected ? 5 : 3}
        sx={{
          ...classes.designeesCard,
          ...(isSelected && classes.designeeCardActive),
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              {name || fullName}
            </Typography>
          </Grid>
          {user.roles.includes(UserRole.multi) && (
            <Grid item xs={6}>
              <Box display="flex" justifyContent="right">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(event) => {
                    deleteBeneficiary(beneficiary);
                    event.stopPropagation();
                  }}
                  style={{ paddingTop: 0 }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2">
              Relationship: {relationship}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Percent:
              <NumberFormat
                value={percent}
                suffix="%"
                displayType="text"
                decimalScale={2}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
          {beneficiary.beneficiaryDistributionOption && (
            <Typography variant="body2" color="primary" pt={0.5}>
              Distribution option: {getAppConstant(
            ConstantsMappingKey.beneficiaryDistributionOption,
            beneficiary.beneficiaryDistributionOption as BeneficiaryDistributionOption
          )}
            </Typography>
          )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default DesignatedBeneficiariesCard;
