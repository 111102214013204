import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { StateTaxDeadline } from '../../../api/StatesApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../../form/SiraTextField';
import StateField from '../../form/StateField';
import SiraDateField from '../../form/SiraDateField';

export const STATE_TAX_DEADLINE: StateTaxDeadline = {
  state: '',
  filingDeadline: '',
  promptText: '',
  taxYear: 0,
};

export interface StateTaxDeadlineFormProps {
  initialValues: StateTaxDeadline;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  isEditing?: boolean;
}

const StateTaxDeadlineForm = ({
  initialValues,
  onSubmit,
  onReset,
  submitName,
  resetName,
  onCancel,
  isEditing,
}: StateTaxDeadlineFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {isEditing ? 'Edit' : 'Add'} State Tax Deadline
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <StateField name="state" label="State" />
              </Grid>
              <Grid item xs={6}>
                <SiraDateField
                  name="filingDeadline"
                  label="Tax Return Deadline"
                />
              </Grid>
              <Grid item xs={6}>
                <SiraTextField name="taxYear" label="Tax Year" />
              </Grid>
              <Grid item xs={6} sx={{ width: '75%' }}>
                <SiraTextField name="promptText" label="Help Text" multiline />
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default StateTaxDeadlineForm;
