import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { saveUser } from '../../api/UserApi';
import { User, UserResponse } from '../../api/UserApi.d';
import UserInfoForm, { USER_INFO_INIT } from '../form/newUser/UserInfoForm';
import { Organization } from '../../api/OrganizationApi.d';
import { notificationPrefItems } from '../../app.constants';
import { errorMessages } from '../../utils/errorhandling.utils';

interface UserProfileProps {
  response: UserResponse;
  setResponse: Function;
  editing?: boolean;
  organization?: Organization;
  setEditing?: Function;
}

function UserProfileDetails(props: UserProfileProps) {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [isEditing, setIsEditing] = useState(false);
  const {
    response = {},
    setResponse = () => {},
    setEditing = () => {},
    editing,
    organization = {},
  } = props;
  const { data: selectedUser = {} as User } = response as UserResponse;
  const {
    firstName = '',
    lastName = '',
    emailAddress = '',
    phoneNumber = '',
    phoneNumberExtension = '',
    branchId: userBranchId = '',
    organizationRole = '',
    userId,
    notificationPreference,
    mainContact=false
  } = selectedUser as User;
  const { branches = [] } = organization as Organization;
  const { name: userBranchName } =
    branches.find(({ branchId = '' }) => branchId === userBranchId) || {};

  async function saveUserInfo(data) {
    await saveUser(data, user.organizationId, userId, user.token)
      .then((res) => {
        if (isMounted) {
          setResponse(res);
          setIsEditing(false);
          setEditing(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
  
 addGlobalMessage(errorMessages(err) || 'Error saving user information'
          );
        }
      });
  }

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (editing) {
      setIsEditing(true);
    }
  }, [editing]);

  return isEditing ? (
    <UserInfoForm
      branches={branches}
      initialValues={{
        ...USER_INFO_INIT,
        ...selectedUser,
      }}
      onSubmit={async (data) => {
        await saveUserInfo(data);
      }}
      onCancel={() => {
        setIsEditing(false);
        setEditing(false);
      }}
      isEditing
    />
  ) : (
    <Box width="1">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2">Name</Typography>
          <Typography variant="body1">
            {firstName} {lastName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">Email</Typography>
          <Typography variant="body1">{emailAddress || '---'}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">Phone</Typography>
          <Typography variant="body1">{phoneNumber || '---'}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">Phone Extension</Typography>
          <Typography variant="body1">{phoneNumberExtension || '---'}</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="body2">Title</Typography>
          <Typography variant="body1">{organizationRole || '---'}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">Branch</Typography>
          <Typography variant="body1">{userBranchName || '---'}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">Notification Preference</Typography>
          <Typography variant="body1">
            {notificationPrefItems[notificationPreference]}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">Main Contact</Typography>
          <Typography variant="body1">
            {mainContact ? 'True' : 'False'}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Button
          aria-label="Edit user details"
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => {
            setIsEditing(true);
            setEditing(true);
          }}
        >
          Edit Details
        </Button>
      </Box>
    </Box>
  );
}

export default UserProfileDetails;
