import React from 'react';
import { Alert, AlertTitle, Box, Grid, Typography, Link } from '@mui/material';

import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';

import FileUploadForm, {
  FILE_UPLOAD_INIT,
  FileUpload,
} from '../components/form/FileUploadForm';
import { useUser } from '../auth/useUser';
import { import5498TaxReports } from '../api/TaxFormApi';
import { BatchType } from '../api/BatchApi.d';
import {
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
} from './TransactionReducer';
import { useGlobalContext } from '../auth/useGlobalContext';
import { errorMessages } from '../utils/errorhandling.utils';

enum PrintableTaxForm {
  TAX_FORM_5498 = 'TAX_FORM_5498',
  TAX_FORM_5498ESA = 'TAX_FORM_5498ESA',
  TAX_FORM_5498SA = 'TAX_FORM_5498SA',
}

export const taxFormDescriptions = {
  [PrintableTaxForm.TAX_FORM_5498ESA]: 'ESA',
  [PrintableTaxForm.TAX_FORM_5498SA]: 'HSA',
  [PrintableTaxForm.TAX_FORM_5498]: 'IRA',
};

export const printableTaxFormDescriptions = {
  [PrintableTaxForm.TAX_FORM_5498ESA]: 'TAX_FORM_5498ESA',
  [PrintableTaxForm.TAX_FORM_5498SA]: 'TAX_FORM_5498SA',
  [PrintableTaxForm.TAX_FORM_5498]: 'TAX_FORM_5498',
};

export interface TaxFormStatusSelectFieldProps {
  initialValues: { taxFormType: PrintableTaxForm };
  onSubmit?: Function;
}

function Add5498TaxReports() {
  const { user } = useUser();
  const [pageState] = useTransactionReducer();
  const { addGlobalMessage, activeTaxYear } = useGlobalContext();

  const taxYear = activeTaxYear;
  // Upload the file as a multipart/form-data
  const upload5498File = async (
    fileUploadInformation: FileUpload
  ): Promise<void> => {
    const { importFiles } = fileUploadInformation;

    await import5498TaxReports(
      importFiles,
      user.organizationId,
      user.token,
      taxYear,
      'TaxForm5498'
    )
      .then(() => {
        completeTransaction();
        addGlobalMessage(
          `Your 5498 file has been successfully uploaded. You'll receive an email after records have been applied.`,
          {
            severity: 'success',
          }
        );
      })
      .catch((err) => {
        rejectTransaction();
        addGlobalMessage(
          errorMessages(err) || 'Your tax file file could not be uploaded.'
        );
      });
  };

  const fileTypes = {
    'text/tsv': ['.txt'],
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Transactions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Import 5498 Tax Files
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            The steps below will help you import a 5498 tax file for the current
            tax year.
          </Typography>

          <Box mt={5}>
            <Box mt={5} mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FileUploadForm
                    fileTypes={fileTypes}
                    fileText="text files such as .txt"
                    onSubmit={upload5498File}
                    submitName="Upload File"
                    initialValues={{
                      ...FILE_UPLOAD_INIT,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {(pageState.completed || pageState.rejected) && (
                    <Alert
                      severity={pageState.rejected ? 'error' : 'success'}
                      variant="outlined"
                    >
                      <AlertTitle>
                        {!pageState.rejected && (
                          <Typography gutterBottom>
                            Your tax file was successfully uploaded.{' '}
                            <Link
                              sx={{ fontStyle: 'italic' }}
                              href={`/dataImport?type=${BatchType.taxReport5498}`}
                            >
                              View
                            </Link>{' '}
                            the Data Import page to review any errors that may
                            have occurred from the upload.
                          </Typography>
                        )}
                      </AlertTitle>
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      </FormPaper>
    </Layout>
  );
}

export default Add5498TaxReports;
