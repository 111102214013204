import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgTransactionReview(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 8.323 10.462 L 4.013 10.462 L 4.013 8.738 L 8.323 8.738 Z M 8.323 14.772 L 4.013 14.772 L 4.013 13.048 L 8.323 13.048 Z M 20.037 19.082 L 16.736 15.781 C 16.046 16.229 15.236 16.496 14.357 16.496 C 11.978 16.496 10.047 14.565 10.047 12.186 C 10.047 9.807 11.978 7.876 14.357 7.876 C 16.736 7.876 18.667 9.807 18.667 12.186 C 18.667 13.065 18.4 13.876 17.951 14.557 L 21.253 17.867 Z M 16.943 12.186 C 16.943 10.764 15.779 9.6 14.357 9.6 C 12.935 9.6 11.771 10.764 11.771 12.186 C 11.771 13.608 12.935 14.772 14.357 14.772 C 15.779 14.772 16.943 13.608 16.943 12.186 Z M 4.013 17.358 L 12.633 17.358 L 12.633 19.082 L 4.013 19.082 Z M 20.363 2.417 L 15.355 2.417 C 14.852 1.027 13.535 0.021 11.977 0.021 C 10.42 0.021 9.102 1.027 8.599 2.417 L 3.592 2.417 C 2.274 2.417 1.196 3.495 1.196 4.813 L 1.196 21.584 C 1.196 22.901 2.274 23.979 3.592 23.979 L 20.363 23.979 C 21.68 23.979 22.759 22.901 22.759 21.584 L 22.759 4.813 C 22.759 3.495 21.68 2.417 20.363 2.417 Z M 11.977 4.813 C 11.318 4.813 10.779 4.274 10.779 3.615 C 10.779 2.956 11.318 2.417 11.977 2.417 C 12.636 2.417 13.175 2.956 13.175 3.615 C 13.175 4.274 12.636 4.813 11.977 4.813 Z" />
    </SvgIcon>
  );
}

export default SvgTransactionReview;
