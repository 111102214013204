import axios from 'axios';
import format from 'string-format';

import { removeEmptyStrings } from './api.utils';
import { HubspotTokenRequestParams } from './ThirdPartyApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const hubspotChatTokenUrl = '/api/org/{0.orgId}/hubspot/token';

export async function getHubspotChatToken(
  query: HubspotTokenRequestParams,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(hubspotChatTokenUrl, { orgId })}`;
  const params = removeEmptyStrings(query);

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
