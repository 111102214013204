import React, { useCallback } from 'react';
import { Button, Grid } from '@mui/material';
import { useFormikContext } from 'formik';

interface SubStepButtonBarProps {
  steps: Array<any>;
  activeStep: number;
  setActiveStep: Function;
}

function SubStepButtonBar(props: SubStepButtonBarProps) {
  const { setFieldTouched, validateForm } = useFormikContext();
  const { steps, activeStep, setActiveStep } = props;

  const clearStep = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  const nextStep = async () => {
  // Without this the next button would need to be pressed twice.  The first validation fails because of the async nature of setFieldValue
    const errors = await validateForm();

    const stepIsInvalid = Object.keys(errors).some((key) =>
      steps[activeStep].validationFields.includes(key)
    );

    steps[activeStep].validationFields.forEach((field) =>
      setFieldTouched(field, true, true)
    );

    if (!stepIsInvalid) {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {activeStep !== steps.length - 1 && (
        <Grid item>
          <Button
            data-qa="subStep_Next"
            variant="outlined"
            onClick={() => {
              nextStep();
            }}
          >
            Next
          </Button>
        </Grid>
      )}
      {activeStep > 0 && (
        <Grid item>
          <Button
            data-qa="subStep_back"
            variant="outlined"
            onClick={() => clearStep()}
          >
            Back
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default SubStepButtonBar;
