// Creates an automated download link to save arbitrary text as a file
export function downloadAsFile(value, fileName): void {
  const element = document.createElement('a');
  element.href = URL.createObjectURL(value);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

// Helper type to round to 2 decimal places
export function roundNumber(value: number | string) {
  if (typeof value !== 'undefined') {
    const m = Number((Math.abs(Number(value)) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(Number(value));
  }

  return undefined;
}

// Helper to create a file blob from any encoded base64 string
export function createFileBlobFromBase64(
  base64: string,
  contentType: string = ''
): Blob {
  if (!contentType) {
    throw Error('Content type is missing but required');
  }

  if (base64) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: `${contentType};base64` });
  }

  return undefined;
}

// Helper create file size readable from bytes
export function createFileSizeReadable(bytes: number): string {
  if (bytes === 0) {
    return '0 B';
  }

  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const decimalPlaces = 2;
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / k ** i).toFixed(decimalPlaces)} ${units[i]}`;

}