import React from 'react';
import { useTheme, Paper, Typography, Button, Grid } from '@mui/material';

import { format, parseISO } from 'date-fns';
import { RecurringDistribution } from '../../../api/RecurringDistributionApi.d';
import moneyFormater from '../../../moneyFormatter';
import { FREQUENCY_DIVISOR, FREQUENCY_TEXT } from './DistributionSchedule';
import { Frequency } from './Frequency.d';

interface RecurringDistributionSummaryCardProps {
  distribution: RecurringDistribution;
  onClick: Function;
}

function RecurringDistributionSummaryCard(
  props: RecurringDistributionSummaryCardProps
) {
  const { distribution, onClick } = props;
  const theme = useTheme();
  const {
    distributionId,
    frequency = '',
    totalAmount = '',
    startDate = '',
    ownerResponsible = false,
  } = distribution;

  return (
    <Grid item xs={6} sm={4} lg={3}>
      <Button
        fullWidth
        onClick={() => onClick()}
        key={distributionId}
        sx={{
          textTransform: 'none',
          p: 0,
        }}
      >
        <Paper
          elevation={2}
          sx={{
            p: 2,
            width: '100%',
            borderTop: `${theme.spacing(3)} solid ${
              theme.palette.primary.main
            }`,
          }}
        >
          {ownerResponsible && (
            <Typography variant="h6" gutterBottom align="center">
              Owner Responsible
            </Typography>
          )}
          {totalAmount && (
            <Typography variant="h6" gutterBottom align="center">
              {moneyFormater.format(
                Number(totalAmount) /
                  FREQUENCY_DIVISOR[frequency as unknown as Frequency]
              )}
            </Typography>
          )}

          <Typography variant="body2" align="center">
            {FREQUENCY_TEXT[frequency as unknown as Frequency]}
          </Typography>

          <Typography variant="body2" align="center">
            Started:{' '}
            {startDate && format(parseISO(String(startDate)), 'MM/dd/yyyy')}
          </Typography>
        </Paper>
      </Button>
    </Grid>
  );
}

export default RecurringDistributionSummaryCard;
