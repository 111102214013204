import React from 'react';
import { Box } from '@mui/material';

interface LayoutProps {
  children: any;
}

function PaperlessPage(props: LayoutProps) {
  const { children } = props;
  return (
    <Box p={2} m={1} ml="5%" mr="5%">
      {children}
    </Box>
  );
}

export default PaperlessPage;
