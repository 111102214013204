import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Box,
  IconButton,
  Typography,
  LinearProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { subYears } from 'date-fns';
import { useUser } from '../../../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../../../utils/DataGrid.utils';

import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { globalPaginationOptions } from '../../../app.constants';
import { Account, TransactionType } from '../../../api/AccountApi.d';

import {
  createOrSaveContributionInformation,
  deleteAccountContribution,
  getAccountContributions,
} from '../../../api/ContributionApi';
import {
  AccountContribution,
  ContributionStatus,
} from '../../../api/ContributionApi.d';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import ContributionInfoForm, {
  CONTRIBUTION_INFO_INIT,
} from '../newAccount/ContributionInfoForm';
import { errorMessages } from '../../../utils/errorhandling.utils';

interface TaxFormContributionProps {
  account?: Account;
}

function TaxFormContributions(props: TaxFormContributionProps) {
  let isMounted = true;
  const { account } = props;
  const { accountId, accountOwnerId } = account;
  const [showForm, setShowForm] = useState(false);
  const [errorMessage] = useState('');
  const { user } = useUser();
  const { addGlobalMessage, getAppConstant, taxYears } = useGlobalContext();
  const { setGlobalPageSize } = usePaginationContext();
  const [allContributions, setAllContributions] = useState(
    [] as Array<AccountContribution>
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedContribution, setSelectedContribution] =
    useState<AccountContribution>({});
  // setting this to be used for calling document status
  const currentTaxYear = subYears(new Date(), 1);
  const activeYear = taxYears.find((year) => year.active === true)?.taxYear;

  async function getActiveDeposits() {
    setIsLoading(true);

    await getAccountContributions(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
      [ContributionStatus.active]
    )
      .then((res) => {
        if (isMounted) {
          if (res.data.length > 1) {
            // we need to add the total amount of all the contributions for the current active year
            // Calculate the overall total for the active tax year
            const activeYearContributions = res.data.filter(
              (contribution) => contribution.taxYear === activeYear
            );
            const overAlltotal = activeYearContributions.reduce(
              (total, contribution) => total + contribution.amount,
              0
            );
            res.data.push({
              amount: overAlltotal,
              taxYear: activeYear,
              contributionStatus: ContributionStatus.active,
              totalStatus: 'total',
            });
          } else {
            res.data.push({
              amount: res.data[0].amount,
              taxYear: currentTaxYear.getFullYear(),
              contributionStatus: ContributionStatus.active,
              totalStatus: 'total',
            });
          }

          setAllContributions(res.data);
        }
      })
      .catch((err) => {
        if (isMounted) {
    
          setAllContributions([]);
 addGlobalMessage(errorMessages(err) || `Error fetching account contributions`
          );
        }
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  // Only show active contributions in here
  const contributions = useMemo(
    () =>
      allContributions.filter(
        ({ contributionStatus, taxYear }) =>
          [ContributionStatus.active].includes(contributionStatus) &&
          taxYear === activeYear
      ),
    [allContributions]
  );

  // Delete the selected contribution record (superuser permission)
  const deleteSelectedContribution = async (row): Promise<void> => {
    setIsLoading(true);

    await deleteAccountContribution(
      accountId,
      accountOwnerId,
      user.organizationId,
      row.contributionId,
      user.token,
      user
    )
      .then(() => {
        getActiveDeposits();
        addGlobalMessage('Contribution successfully deleted', {
          severity: 'success',
        });
      })
      .catch((err) => {
 addGlobalMessage(errorMessages(err) || 'Could not delete this contribution'
        );
      });
  };

  const columnsContribution = [
    {
      field: 'depositType',
      headerName: 'Deposit Type',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return (
          <Box overflow="hidden" textOverflow="ellipsis">
            {getAppConstant(ConstantsMappingKey.depositType, typeId)}
          </Box>
        );
      },
    },
    {
      field: 'effectiveDate',
      headerName: 'Date',
      width: 112,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 148,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 110,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const { effectiveDate } = row;
        return (
          effectiveDate && (
            <>
              <Grid container justifyContent="flex-end">
                <IconButton
                  data-testid="EditSchedDist"
                  size="small"
                  aria-label="Edit Scheduled Distribution"
                  onClick={() => {
                    setSelectedContribution(row as AccountContribution);
                    setShowForm(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  data-testid="DeleteScheduledDistribution"
                  size="small"
                  aria-label="Delete Scheduled Distribution"
                  onClick={async () => {
                    await deleteSelectedContribution(
                      row as AccountContribution
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </>
          )
        );
      },
    },
  ] as GridColDef[];

  // Override the selected contribution record (superuser permission)
  const updateSelectedContribution = async (
    data: AccountContribution
  ): Promise<void> => {
    await createOrSaveContributionInformation(
      { ...selectedContribution, ...data },
      user.organizationId,
      accountOwnerId,
      accountId,
      data.contributionId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          setSelectedContribution({} as AccountContribution);
        }

        getActiveDeposits();
        addGlobalMessage('Contribution successfully updated', {
          severity: 'success',
        });
      })
      .catch((err) => {
 addGlobalMessage(errorMessages(err) || 'Could not update this contribution'
        );
      });

    if (isMounted) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (accountId) {
      getActiveDeposits();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, accountId]);

  return showForm ? (
    <Box
      style={{
        marginTop: '35px',
        paddingLeft: '10px',
        paddingTop: '15px',
        paddingBottom: '10px',
      }}
    >
      <ContributionInfoForm
        account={account}
        initialValues={{
          ...CONTRIBUTION_INFO_INIT,
          ...selectedContribution,
        }}
        onSubmit={updateSelectedContribution}
        onCancel={() => {
          setShowForm(false);
        }}
        explodeSteps
      />
    </Box>
  ) : (
    <Box width="2">
      <Typography variant="body2" color="error">
        {errorMessage}
      </Typography>
      <Box>
        {isLoading ? (
          <Box width="1">
            <LinearProgress color="secondary" />
          </Box>
        ) : (
          <Box
            sx={{
              '& .super-app-theme--total': {
                borderTop: 1,
              },
            }}
          >
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              disableColumnMenu
              autoHeight
              columns={columnsContribution}
              rows={contributions.map((t, id) => ({
                id,
                transactionType: TransactionType.distribution,
                ...t,
              }))}
              getRowClassName={(params) => {
                const { row } = params as any;
                return `super-app-theme--${row.totalStatus}`;
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TaxFormContributions;
