import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TaxReportingIcon from '../icons/TaxReporting';

import AccountNewIcon from '../icons/AccountNew';
import AddMoneyIcon from '../icons/AddMoney';
import RemoveMoneyIcon from '../icons/RemoveMoney';
import RecurringDistributionNewIcon from '../icons/RecurringDepositNew';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import BulkDataImports from '../components/dataImport/bulkDataImport/BulkDataImports';
import {
  getAccountOwnersUpdateBatchInfo,
  getAccountsBatchInfo,
  getContributionBatchInfo,
  getDistributionBatchInfo,
  getFairMarketValueBatchInfo,
  getRecurringDistributionBatchInfo,
  getUsersBatchInfo,
} from '../api/BatchApi';

import { BatchInfoResponse, BatchType } from '../api/BatchApi.d';
import { useUser } from '../auth/useUser';
import ActionCard from '../components/ActionCard';
import { BatchOptionConfig } from '../components/dataImport/DataImport.d';
import { useGlobalContext } from '../auth/useGlobalContext';
import { Solution } from '../api/OrganizationApi.d';
import {
  import1099TaxReportStatus,
  import5498TaxReportStatus,
} from '../api/TaxFormApi';
import TaxReportingImports from '../components/dataImport/taxReportingImport/TaxReportingImport5498';

function DataImport() {
  const { user } = useUser();
  const queryParams = new URLSearchParams(useLocation().search);
  const { organization, activeTaxYear  } = useGlobalContext();
  const { solution = [] } = organization || {};
  const [buttonText, setButtonText] = useState('Import Organization Data');
  const [currentBatchConfig, setCurrentBatchConfig] = useState<any>({});
  const [currentBatchType, setCurrentBatchType] = useState(
    queryParams.get('type') || ''
  );
const taxYear = activeTaxYear;

  const navigate = useNavigate();

  const [batchInfoConfigs, setBatchInfoConfig] = useState<
    Array<BatchOptionConfig>
  >([]);

  useEffect(() => {
    if (solution !== undefined && solution.length > 0) {
      if (solution === Solution.green || solution === Solution.silver) {
        setBatchInfoConfig([
          {
            type: BatchType.users,
            buttonText: 'Import Users',
            actionText: 'Add Users',
            linkPath: '/dataImport/addUsers',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getUsersBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.accounts,
            buttonText: 'Import Accounts',
            actionText: 'Add/Update Accounts',
            icon: <AccountNewIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/newAccounts',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getAccountsBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.accountOwnerUpdate,
            buttonText: 'Update Account Owners',
            actionText: 'Update Owners',
            linkPath: '/dataImport/updateAccountOwners',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getAccountOwnersUpdateBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.deposit,
            buttonText: 'Import Deposits',
            actionText: 'Add/Update Deposits',
            icon: <AddMoneyIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/deposits',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getContributionBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.distribution,
            buttonText: 'Import Distributions',
            actionText: 'Add/Update Distributions',
            icon: <RemoveMoneyIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/distributions',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getDistributionBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.recurringDistribution,
            buttonText: 'Import Recurring Distributions',
            actionText: 'Add Recurring Distributions',
            icon: <RecurringDistributionNewIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/recurringDistributions',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getRecurringDistributionBatchInfo(
                user.organizationId,
                user.token
              ),
          },
          {
            type: BatchType.fairMarketValue,
            buttonText: 'Import Fair Market Value',
            actionText: 'Add/Update Fair Market Values',
            icon: <AttachMoneyIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/fairMarketValue',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getFairMarketValueBatchInfo(user.organizationId, user.token),
          },
        ]);
      } else {
        setBatchInfoConfig([
          {
            type: BatchType.users,
            buttonText: 'Import Users',
            actionText: 'Add Users',
            linkPath: '/dataImport/addUsers',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getUsersBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.accounts,
            buttonText: 'Import Accounts',
            actionText: 'Add/Update Accounts',
            icon: <AccountNewIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/newAccounts',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getAccountsBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.accountOwnerUpdate,
            buttonText: 'Update Account Owners',
            actionText: 'Update Owners',
            linkPath: '/dataImport/updateAccountOwners',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getAccountOwnersUpdateBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.deposit,
            buttonText: 'Import Deposits',
            actionText: 'Add/Update Deposits',
            icon: <AddMoneyIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/deposits',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getContributionBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.distribution,
            buttonText: 'Import Distributions',
            actionText: 'Add/Update Distributions',
            icon: <RemoveMoneyIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/distributions',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getDistributionBatchInfo(user.organizationId, user.token),
          },
          {
            type: BatchType.recurringDistribution,
            buttonText: 'Import Recurring Distributions',
            actionText: 'Add Recurring Distributions',
            icon: <RecurringDistributionNewIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/recurringDistributions',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getRecurringDistributionBatchInfo(
                user.organizationId,
                user.token
              ),
          },
          {
            type: BatchType.taxReport1099,
            buttonText: 'Import 1099 Tax files',
            actionText: '1099 Tax files',
            icon: <TaxReportingIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/taxReporting',
            apiMethod: (): Promise<any> =>
              import1099TaxReportStatus(user.organizationId, user.token, taxYear, 'TaxForm1099'),
          },
          {
            type: BatchType.taxReport5498,
            buttonText: 'Import 5498 Tax files',
            actionText: '5498 Tax files',
            icon: <TaxReportingIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/taxReporting5498',
            apiMethod: (): Promise<any> =>
              import5498TaxReportStatus(user.organizationId, user.token, taxYear, 'TaxForm5498'),
          },
          {
            type: BatchType.fairMarketValue,
            buttonText: 'Import Fair Market Value',
            actionText: 'Add/Update Fair Market Values',
            icon: <AttachMoneyIcon style={{ fontSize: 42 }} />,
            linkPath: '/dataImport/fairMarketValue',
            apiMethod: (): Promise<BatchInfoResponse> =>
              getFairMarketValueBatchInfo(user.organizationId, user.token),
          },
        ]);
      }
    }
  }, [solution]);

  // *** Configure new batch type tabs here, the system handles the rest
  // ***

  useEffect(() => {
    const batchConfig = batchInfoConfigs.find(
      ({ type }) => type === currentBatchType
    );
    if (batchConfig) {
      setButtonText(batchConfig.buttonText);
      setCurrentBatchConfig(batchConfig);
    }
  }, [batchInfoConfigs]);

  useEffect(() => {
    if (queryParams.get('type')) {
      const batchType = queryParams.get('type');

      const batchConfig = batchInfoConfigs.find(
        ({ type }) => type === batchType
      );

      if (batchConfig) {
        setButtonText(batchConfig.buttonText);
        setCurrentBatchType(queryParams.get('type'));
      }
    } else {
      setButtonText('Import Organization Data');
      setCurrentBatchType('');
      navigate('/dataImport');
    }
  }, [queryParams.get('type')]);

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Grid container mb={5} wrap="nowrap">
            <Grid item xs={12}>
              <Typography variant="overline" gutterBottom>
                Data Import
              </Typography>
              <Typography color="secondary" variant="h1" gutterBottom>
                {buttonText}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                View the status of uploaded data files or upload additional
                files using the options below
              </Typography>
            </Grid>
          </Grid>

          {/* Action Card Links */}
          {!currentBatchType && (
            <Grid container spacing={2} mb={2}>
              {batchInfoConfigs.map(({ actionText, type, icon }, idx) => {
                return (
                  <ActionCard
                    key={`batch_info_card_${type}`}
                    dataQa={`batch_info_card_${type}`}
                    iconImage={icon}
                    buttonText={actionText}
                    route={`/dataImport?type=${type}`}
                    triggerCustomFunction={() => {
                      setButtonText(batchInfoConfigs[idx].buttonText);
                      setCurrentBatchType(type);
                      setCurrentBatchConfig(batchInfoConfigs[idx]);
                    }}
                  />
                );
              })}
            </Grid>
          )}

          {/* Batch Information */}
          {currentBatchType &&
            (currentBatchType === BatchType.taxReport5498 ||
            currentBatchType === BatchType.taxReport1099 ? (
              <Grid container spacing={2}>
                <TaxReportingImports batchOptionsConfig={currentBatchConfig} currentBatchType={currentBatchType}/>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <BulkDataImports
                  maxItems={10}
                  batchOptionsConfig={currentBatchConfig}
                />
              </Grid>
            ))}
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default DataImport;
