import React from 'react';
import {
  endOfDay,
  endOfYear,
  intervalToDuration,
  parseISO,
  subYears,
} from 'date-fns';
import { Typography } from '@mui/material';
import { SiraSelectItem } from './components/form/SiraSelectField';
import { BeneficiaryTypes } from './api/BeneficiariesApi.d';
import { DistributionCodeGroup } from './components/form/distribution/DistributionCodeForm.d';
import { FilingStatus } from './components/form/distribution/DistributionAmountForm.d';
import { RadioGroupOption } from './components/form/SiraRadioField';
import {
  AccountStatus,
  AccountType,
  TransactionType,
} from './api/AccountApi.d';
import {
  EarlyWithdrawalOption,
  CompoundingFrequency,
} from './api/InvestmentRateApi.d';
import {
  DepositMethod,
  DepositCheckPayee,
  PostponementCode,
  PostponementReason,
  RepaymentCode,
} from './api/ContributionApi.d';
import {
  AccountMemberValue,
  DocumentationAddress,
  EsignLandingPage,
  FiduciaryType,
  SingelSignOn,
  Solution,
  SpousalConsentOption,
  TransactionEmailSettings,
} from './api/OrganizationApi.d';
import { BatchStatus } from './api/BatchApi.d';
import { distributionReasonHelpText } from './components/form/distribution/resource.txt';
import { NotificationPreferences, UserRole } from './api/UserApi.d';
import { FrequencyValues } from './components/form/distribution/Frequency.d';
import { newAccountDepositHelpTxt } from './components/form/newAccount/resource.txt';
import { WithholdingNotificationType } from './api/WithholdingNotificationApi.d';
import { RmdNotificationType } from './api/RmdNotificationApi.d';

export const currentYear: number = new Date().getFullYear();
export const currentTaxYear: number = subYears(new Date(), 1).getFullYear();
export const priorYearTaxReturnDeadline = endOfDay(
  new Date(`Apr 18 ${currentYear}`)
);

export function getConstantActiveTaxYear(){
  // we will get the current taxt year from an object that contains TAX_YEARS in the local session storage
  // if not found then we will get the current year
  let taxYear;
  const taxYears = JSON.parse(sessionStorage.getItem('constants'))?.TAX_YEARS;

  if(taxYears){
    const activeTaxYear = taxYears.find((year) => year.active);
    taxYear = activeTaxYear?.taxYear;
  }
  return taxYear;

}

// These are major age milestones that matter to the IRS. All age calculations should be referenced here
export function determineAgeGroup(dob: string) {
  const birthDate: Date = dob ? parseISO(dob) : new Date();
  const exactAge: Duration = intervalToDuration({
    start: birthDate,
    end: new Date(),
  });

  const { years = 0, months = 0 } = exactAge;
  const ageTo73 = new Date().getFullYear() - birthDate.getFullYear();

  return {
    birthDate,
    exactAge,
    yearsTo72: 72 - years,
    yearsTo73: 73 - ageTo73,
    currentlyOrOver18: years >= 18, // Inclusive
    currentlyOrOver30:
      years >= 30 ||
      intervalToDuration({
        start: birthDate,
        end: endOfYear(new Date()),
      }).years === 30, // Inclusive
    underHalf59: years < 59 || (years === 59 && months < 6), // XOR for under 59 or if 59, less than 6 months into that year
    betweenHalf59and72:
      (years > 59 || (years === 59 && months >= 6)) && years < 72, // XOR for above 59 or if 59 and over 6 months into the year, and under 72
    betweenHalf59and73:
      (years > 59 || (years === 59 && months >= 6)) && years < 73, // XOR for above 59 or if 59 and over 6 months into the year, and under 73
    turning72ThisYear:
      intervalToDuration({
        start: birthDate,
        end: endOfYear(new Date()),
      }).years === 72, // Turning 72 at some point this year
    turning73ThisYear:
      intervalToDuration({
        start: birthDate,
        end: endOfYear(new Date()),
      }).years === 73, // Turning 72 at some point this year
    currently72Years: years === 72,
    currently73Years: years === 73,
    over73: years > 73, // Non-inclusive
    over72: years > 72, // Non-inclusive
    currentlyOrOver73: years >= 73, // Inclusive
  };
}


// These are major age milestones that matter to the IRS. All age calculations should be referenced here during the date of death
export function determineAgeGroupAtDeath(dob: string, dod: string) {
  const birthDate: Date = dob ? parseISO(dob) : new Date();
  const dateOfDeath: Date = dod ? parseISO(dod) : new Date();
  const exactAge: Duration = intervalToDuration({
    start: birthDate,
    end: dateOfDeath,
  });

  const { years = 0, months = 0 } = exactAge;
  const ageTo73 = new Date().getFullYear() - birthDate.getFullYear();

  return {
    birthDate,
    exactAge,
    yearsTo72: 72 - years,
    yearsTo73: 73 - ageTo73,
    currentlyOrOver18: years >= 18, // Inclusive
    currentlyOrOver30:
      years >= 30 ||
      intervalToDuration({
        start: birthDate,
        end: endOfYear(new Date()),
      }).years === 30, // Inclusive
    underHalf59: years < 59 || (years === 59 && months < 6), // XOR for under 59 or if 59, less than 6 months into that year
    betweenHalf59and72:
      (years > 59 || (years === 59 && months >= 6)) && years < 72, // XOR for above 59 or if 59 and over 6 months into the year, and under 72
    betweenHalf59and73:
      (years > 59 || (years === 59 && months >= 6)) && years < 73, // XOR for above 59 or if 59 and over 6 months into the year, and under 73
    turning72ThisYear:
      intervalToDuration({
        start: birthDate,
        end: endOfYear(new Date()),
      }).years === 72, // Turning 72 at some point this year
    turning73ThisYear:
      intervalToDuration({
        start: birthDate,
        end: endOfYear(new Date()),
      }).years === 73, // Turning 72 at some point this year
    currently72Years: years === 72,
    currently73Years: years === 73,
    over73: years > 73, // Non-inclusive
    over72: years > 72, // Non-inclusive
    currentlyOrOver73: years >= 73, // Inclusive
  };
}
// Pagination options that will be available anywhere there's tabular data
export const globalPaginationOptions = [10, 25, 50, 100];

// User roles that are assignable to a user based on tier
export const assignableUserRoles = {
  // SILVER
  [Solution.silver]: [
    UserRole.orgDataProcessor,
    UserRole.orgSiteAdmin,
    UserRole.orgUserAdmin,
    UserRole.orgTransactions,
    UserRole.orgTransactionsAdmin,
  ] as Array<UserRole>,
  // GREEN
  [Solution.green]: [
    UserRole.orgDataProcessor,
    UserRole.orgSiteAdmin,
    UserRole.orgUserAdmin,
    UserRole.orgTransactions,
    UserRole.orgTransactionsAdmin,
    UserRole.orgRecurringDistributions,
    UserRole.orgBeneficiaryClaims,
  ] as Array<UserRole>,
  // BLACK
  [Solution.black]: [
    UserRole.orgDataProcessor,
    UserRole.orgSiteAdmin,
    UserRole.orgUserAdmin,
    UserRole.orgTransactions,
    UserRole.orgTransactionsAdmin,
    UserRole.orgRecurringDistributions,
    UserRole.orgBeneficiaryClaims,
    UserRole.orgTaxReporting,
  ] as Array<UserRole>,
};

export const postponementReasonLabels = {
  [PostponementReason.afghanistan]: 'Afghanistan (EO13239)',
  [PostponementReason.arabianPen]: 'Arabian Peninsula (EO12744)',
  [PostponementReason.yugoslaviaEo]: 'Federal Republic Yugoslavia (EO13119)',
  [PostponementReason.yugoslaviaPl]: 'Federal Republic Yugoslavia (PL106-21)',
  [PostponementReason.sinaiPen]: 'Sinai Peninsula Egypt (PL115-97)',
};

export const postponementCodeLabels = {
  [PostponementCode.executiveOrder]: 'Executive Order',
  [PostponementCode.fedDisasterArea]: 'Federal Disaster Area',
  [PostponementCode.publicLaw]: 'Public Law',
  [PostponementCode.qaulifiedPlan]: 'Qualified Plan Loan Offset',
};

export const postponementCodes = [
  {
    value: PostponementCode.executiveOrder,
    label: postponementCodeLabels[PostponementCode.executiveOrder],
    afterContent:
      newAccountDepositHelpTxt.postponedContribution.executiveOrder
        .afterContent,
  },
  {
    value: PostponementCode.fedDisasterArea,
    label: postponementCodeLabels[PostponementCode.fedDisasterArea],
    afterContent:
      newAccountDepositHelpTxt.postponedContribution.federalDisasterArea,
  },
  {
    value: PostponementCode.publicLaw,
    label: postponementCodeLabels[PostponementCode.publicLaw],
    afterContent:
      newAccountDepositHelpTxt.postponedContribution.publicLaw.afterContent,
  },
  {
    value: PostponementCode.qaulifiedPlan,
    label: postponementCodeLabels[PostponementCode.qaulifiedPlan],
    afterContent: newAccountDepositHelpTxt.postponedContribution.qlPlanOffset,
  },
];

export const postponementReasonsExecutiveOrder = [
  {
    value: PostponementReason.afghanistan,
    label: postponementReasonLabels[PostponementReason.afghanistan],
    afterContent:
      newAccountDepositHelpTxt.postponedContribution.executiveOrder.afghanistan,
  },
  {
    value: PostponementReason.arabianPen,
    label: postponementReasonLabels[PostponementReason.arabianPen],
    afterContent:
      newAccountDepositHelpTxt.postponedContribution.executiveOrder
        .arabianPeninsula,
  },
  {
    value: PostponementReason.yugoslaviaEo,
    label: postponementReasonLabels[PostponementReason.yugoslaviaEo],
    afterContent:
      newAccountDepositHelpTxt.postponedContribution.executiveOrder
        .federalRepublicYugo,
  },
];

export const postponementReasonsPublicLaw = [
  {
    value: PostponementReason.yugoslaviaPl,
    label: postponementReasonLabels[PostponementReason.yugoslaviaPl],
    afterContent:
      newAccountDepositHelpTxt.postponedContribution.publicLaw
        .federalRepublicYugo,
  },
  {
    value: PostponementReason.sinaiPen,
    label: postponementReasonLabels[PostponementReason.sinaiPen],
    afterContent:
      newAccountDepositHelpTxt.postponedContribution.publicLaw
        .sinaiPeninsulaEgypt,
  },
];

export const repaymentCodeLabels = {
  [RepaymentCode.qualifiedBirth]: 'Birth or Adoption',
  [RepaymentCode.qualifiedDisaster]: 'Disaster Distribution',
  [RepaymentCode.qualifiedReservist]: 'Reservist Distribution',
};

export const repaymentCodes = [
  {
    value: RepaymentCode.qualifiedBirth,
    label: repaymentCodeLabels[RepaymentCode.qualifiedBirth],
    afterContent: newAccountDepositHelpTxt.repayment.birthOrAdoption,
  },
  {
    value: RepaymentCode.qualifiedDisaster,
    label: repaymentCodeLabels[RepaymentCode.qualifiedDisaster],
    afterContent: newAccountDepositHelpTxt.repayment.disasterDistribution,
  },
  {
    value: RepaymentCode.qualifiedReservist,
    label: repaymentCodeLabels[RepaymentCode.qualifiedReservist],
    afterContent: newAccountDepositHelpTxt.repayment.reservistDistribution,
  },
];

export const beneficiaryRelationshipOptions: Array<SiraSelectItem> = [
  { value: BeneficiaryTypes.DEFAULT, label: '' },
  { value: BeneficiaryTypes.SPOUSE, label: 'Spouse' },
  { value: BeneficiaryTypes.ESTATE, label: 'Estate' },
  { value: BeneficiaryTypes.TRUST, label: 'Trust' },
  { value: BeneficiaryTypes.CHILD, label: 'Child' },
  { value: BeneficiaryTypes.OTHER, label: 'Other Individual' },
  {
    value: BeneficiaryTypes.NONEHUMAN,
    label: 'Non-Human (e.g., charity, church, tax-exempt organization)',
  },
];

export const distributionGroupOptions: Array<SiraSelectItem> = [
  { value: DistributionCodeGroup.empty, label: '' },
  {
    value: DistributionCodeGroup.earlyPenalty,
    label: 'Early distribution with penalty exception',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.tradOrSepIRaEarlyDistributionWpenalty}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.esaTransfer,
    label: 'Transfer to another ESA',
  },
  { value: DistributionCodeGroup.rollover, label: 'Direct rollover' },

  { value: DistributionCodeGroup.qualified, label: 'Qualified Distribution' },
  { value: DistributionCodeGroup.disability, label: 'Disability distribution' },
  {
    value: DistributionCodeGroup.beneDistribution,
    label: 'Beneficiary Distribution',
  },
  {
    value: DistributionCodeGroup.iraconversion,
    label: 'Direct Roth IRA conversion',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.directRothIRAconversion}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.early,
    label: 'Early distribution with penalty exception',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.tradOrSepIRaEarlyDistributionWpenalty}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.excess,
    label: 'Removal of excess contribution',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.removalOfExcessContribution}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.esaexcess,
    label: 'Removal of excess contribution',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.esaRemovalofExcessContribution}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.hsaexcess,
    label: 'Removal of excess contribution',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.hsaRemovalofExcessContribution}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.rechar,
    label: 'Recharacterization',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.recharacterization}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.revocation,
    label: 'Revocation',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.revocation}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.prohibited,
    label: 'Prohibited transaction',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.prohibitedTransaction}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.esaprohibited,
    label: 'Prohibited transaction',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.esaProhibitedTransaction}
      </Typography>
    ),
  },
  {
    value: DistributionCodeGroup.hsaprohibited,
    label: 'Prohibited transaction',
    afterContent: (
      <Typography variant="body2">
        {distributionReasonHelpText.hsaProhibitedTransaction}
      </Typography>
    ),
  },
];

export const filingStatusOptions: Array<SiraSelectItem> = [
  { value: FilingStatus.default, label: '' },
  { value: FilingStatus.single, label: 'Single' },
  { value: FilingStatus.joint, label: 'Married Filing Jointly' },
  { value: FilingStatus.head, label: 'Head of Household' },
];

export const fiduciaryTypeLabels = {
  [FiduciaryType.custodian]: 'Custodian',
  [FiduciaryType.trustee]: 'Trustee',
};

export const fiduciaryTypeOptions: Array<SiraSelectItem> = [
  { value: FiduciaryType.default, label: '' },
  {
    value: FiduciaryType.custodian,
    label: fiduciaryTypeLabels[FiduciaryType.custodian],
  },
  {
    value: FiduciaryType.trustee,
    label: fiduciaryTypeLabels[FiduciaryType.trustee],
  },
];

export const distributionAccountTypes: Array<RadioGroupOption> = [
  { value: AccountType.checking, label: 'Checking' },
  { value: AccountType.savings, label: 'Savings' },
  { value: AccountType.other, label: 'Other' },
];

export const defaultRmdDistributionAccountTypes: Array<RadioGroupOption> = [
  { value: AccountType.checking, label: 'Checking' },
  { value: AccountType.savings, label: 'Savings' },
];

export const accountStatusDescriptions = {
  [AccountStatus.pending]: 'Pending',
  [AccountStatus.review]: 'Pending Review',
  [AccountStatus.signature]: 'Awaiting Signature',
  [AccountStatus.open]: 'Open',
  [AccountStatus.closed]: 'Closed',
};

export const accountStatusOptions: Array<SiraSelectItem> = [
  {
    value: AccountStatus.open,
    label: accountStatusDescriptions[AccountStatus.open],
  },
  {
    value: AccountStatus.closed,
    label: accountStatusDescriptions[AccountStatus.closed],
  },
];

export const accountTransactionTypeNames = {
  [TransactionType.account]: 'Account',
  [TransactionType.beneficiaries]: 'Beneficiary Designation',
  [TransactionType.contribution]: 'Deposit',
  [TransactionType.distribution]: 'Distribution',
  [TransactionType.recurringDistribution]: 'Recurring Distribution',
  [TransactionType.transfer]: 'Rollover/Transfer',
  [TransactionType.beneficiaryClaims]: 'Beneficiary Claim',
};

export const batchStatusLabels = {
  [BatchStatus.completed]: 'Completed',
  [BatchStatus.userCompleted]: 'Manually Marked Complete',
  [BatchStatus.errors]: 'Failed',
  [BatchStatus.processing]: 'Processing',
};

export const orgSpousalConsentOptions: Array<SiraSelectItem> = [
  {
    value: SpousalConsentOption.community,
    label: 'Only in community or marital property states',
  },
  { value: SpousalConsentOption.all, label: 'In all states' },
];

export const monthOptions: Array<SiraSelectItem> = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const orgSolutionOptions: Array<SiraSelectItem> = [
  { value: Solution.black, label: 'Black' },
  { value: Solution.green, label: 'Green' },
  { value: Solution.silver, label: 'Silver' },
];

export const frequencyOptions = [
  { value: FrequencyValues.default, label: '' },
  { value: FrequencyValues.annual, label: 'Annually' },
  { value: FrequencyValues.semiAnnual, label: 'Semi-Annually' },
  { value: FrequencyValues.quarterly, label: 'Quarterly' },
  { value: FrequencyValues.monthly, label: 'Monthly' },
];

export const frequencyOptionsValues = [
  { value: FrequencyValues.default, label: '' },
  { value: FrequencyValues.annual, label: 'Annually' },
  { value: FrequencyValues.semiAnnual, label: 'Semi-Annually' },
  { value: FrequencyValues.quarterly, label: 'Quarterly' },
  { value: FrequencyValues.monthly, label: 'Monthly' },
  { value: FrequencyValues.ownerResponsibile, label: 'Owner Responsible' },
];

export const withholdingNotificationTypeLabels = {
  [WithholdingNotificationType.december]: 'December',
  [WithholdingNotificationType.june]: 'June',
};

export const rmdNotificationTypeLabels = {
  [RmdNotificationType.seventyTwo]: 'Age 72',
  [RmdNotificationType.seventyThreePlus]: 'Age 73+',
  [RmdNotificationType.firstYear]:'First Year RMD',
  [RmdNotificationType.rmdAfterReqDob]: 'Age 74+ ',
};


export const earlyWithdrawalLabels = {
  [EarlyWithdrawalOption.daysInterest]: 'Days of Interest',
  [EarlyWithdrawalOption.monthsInterest]: 'Months of Interest',
  [EarlyWithdrawalOption.dollarAmount]: 'Dollar Amount',
};

export const earlyWithdrawalOptions: Array<SiraSelectItem> = [
  {
    value: EarlyWithdrawalOption.daysInterest,
    label: earlyWithdrawalLabels[EarlyWithdrawalOption.daysInterest],
  },
  {
    value: EarlyWithdrawalOption.monthsInterest,
    label: earlyWithdrawalLabels[EarlyWithdrawalOption.monthsInterest],
  },
  {
    value: EarlyWithdrawalOption.dollarAmount,
    label: earlyWithdrawalLabels[EarlyWithdrawalOption.dollarAmount],
  },
];

export const compoundingFrequencyLabels = {
  [CompoundingFrequency.yearly]: 'Yearly',
  [CompoundingFrequency.halfYearly]: 'Half-Yearly',
  [CompoundingFrequency.quarterly]: 'Quarterly',
  [CompoundingFrequency.monthly]: 'Monthly',
  [CompoundingFrequency.weekly]: 'Weekly',
  [CompoundingFrequency.daily]: 'Daily',
};

export const compoundingFrequencyOptions: Array<SiraSelectItem> = [
  {
    value: CompoundingFrequency.yearly,
    label: compoundingFrequencyLabels[CompoundingFrequency.yearly],
  },
  {
    value: CompoundingFrequency.halfYearly,
    label: compoundingFrequencyLabels[CompoundingFrequency.halfYearly],
  },
  {
    value: CompoundingFrequency.quarterly,
    label: compoundingFrequencyLabels[CompoundingFrequency.quarterly],
  },
  {
    value: CompoundingFrequency.monthly,
    label: compoundingFrequencyLabels[CompoundingFrequency.monthly],
  },
  {
    value: CompoundingFrequency.weekly,
    label: compoundingFrequencyLabels[CompoundingFrequency.weekly],
  },
  {
    value: CompoundingFrequency.daily,
    label: compoundingFrequencyLabels[CompoundingFrequency.daily],
  },
];

export const depositCheckPayeeLabels = {
  [DepositCheckPayee.org]: 'Financial Organization',
  [DepositCheckPayee.owner]: 'Account Owner',
};

export const depositCheckPayeeOptions: Array<SiraSelectItem> = [
  {
    value: DepositCheckPayee.org,
    label: depositCheckPayeeLabels[DepositCheckPayee.org],
  },
  {
    value: DepositCheckPayee.owner,
    label: depositCheckPayeeLabels[DepositCheckPayee.owner],
  },
];

export const notificationPrefItems = {
  [NotificationPreferences.all_notifications]: 'All notifications',
  [NotificationPreferences.basedOnUser]: 'Notifications based on my user roles',
  [NotificationPreferences.dontSendNotifications]:
    'Do not send me notifications',
};

export const notificationSelectItems: Array<SiraSelectItem> = [
  {
    value: NotificationPreferences.all_notifications,
    label: notificationPrefItems[NotificationPreferences.all_notifications],
  },
  {
    value: NotificationPreferences.basedOnUser,
    label: notificationPrefItems[NotificationPreferences.basedOnUser],
  },
  {
    value: NotificationPreferences.dontSendNotifications,
    label: notificationPrefItems[NotificationPreferences.dontSendNotifications],
  },
];

export const documentationAddressLabels = {
  [DocumentationAddress.userBranch]: "Use the user's branch address",
  [DocumentationAddress.primaryBranch]: 'Use the primary branch address',
};

export const documentationAddressOptions: Array<SiraSelectItem> = [
  {
    value: DocumentationAddress.primaryBranch,
    label: documentationAddressLabels[DocumentationAddress.primaryBranch],
  },
  {
    value: DocumentationAddress.userBranch,
    label: documentationAddressLabels[DocumentationAddress.userBranch],
  },
];

export const transactionEmailSettings = {
  [TransactionEmailSettings.none]: 'None',
  [TransactionEmailSettings.weekly]: 'Weekly',
  [TransactionEmailSettings.daily]: 'Daily',
  [TransactionEmailSettings.immediately]: 'Immediately',
};

export const transactionEmailSettingsOptions: Array<SiraSelectItem> = [
  {
    value: TransactionEmailSettings.none,
    label: transactionEmailSettings[TransactionEmailSettings.none],
  },
  {
    value: TransactionEmailSettings.daily,
    label: transactionEmailSettings[TransactionEmailSettings.daily],
  },
  {
    value: TransactionEmailSettings.weekly,
    label: transactionEmailSettings[TransactionEmailSettings.weekly],
  },

  {
    value: TransactionEmailSettings.immediately,
    label: transactionEmailSettings[TransactionEmailSettings.immediately],
  },
];

export const accountNumberorMemberNumber = {
  [AccountMemberValue.accountNumber]: 'Account Number',
  [AccountMemberValue.memberNumber]: 'Member Number',
};


export const accountNumberorMemberNumberOptions: Array<SiraSelectItem> = [
  {
    value: AccountMemberValue.accountNumber,
    label: accountNumberorMemberNumber[AccountMemberValue.accountNumber],
  },
  {
    value: AccountMemberValue.memberNumber,
    label: accountNumberorMemberNumber[AccountMemberValue.memberNumber],
  },
];

export const singleSignOnOptions: Array<SiraSelectItem> = [
  { value: SingelSignOn.adLadp, label: 'Active Directory / LDAP' },
  { value: SingelSignOn.adfs, label: 'ADFS' },
  { value: SingelSignOn.none, label: 'None' },
  { value: SingelSignOn.saml, label: 'SAML' },
  { value: SingelSignOn.okta, label: 'Okta Workforce' },
  { value: SingelSignOn.google, label: 'Google Workspace' },
  { value: SingelSignOn.openID, label: 'OpenID Connect' },
  { value: SingelSignOn.ping, label: 'Ping Federate' },
  { value: SingelSignOn.microsoftAzureAD, label: 'Microsoft Azure AD' },
];

export const singleSignOnOptionsDescription = {
   [SingelSignOn.adLadp]: 'Active Directory / LDAP',
  [SingelSignOn.adfs]: 'ADFS',
  [SingelSignOn.none]: 'None',
  [SingelSignOn.saml]: 'SAML',
  [SingelSignOn.okta]: 'Okta Workforce',
  [SingelSignOn.google]: 'Google Workspace',
  [SingelSignOn.openID]: 'OpenID Connect',
  [SingelSignOn.ping]: 'Ping Federate',
  [SingelSignOn.microsoftAzureAD]: 'Microsoft Azure AD',
};
export enum OrgInformationStatus{
  active = 'ACTIVE',
  implementing = 'IMPLEMENTING',
  inactive = 'INACTIVE',
}

export const orgInformationStatus: Array<SiraSelectItem> = [
  { value: OrgInformationStatus.active, label: 'Active' },
  { value: OrgInformationStatus.implementing, label: 'Implementing' },
  { value: OrgInformationStatus.inactive, label: 'Inactive' },
];


export const depositMethodShortNames = {
  [DepositMethod.cash]: 'Cash',
  [DepositMethod.check]: 'Check',
  [DepositMethod.direct]: 'Direct Deposit',
  [DepositMethod.transfer]: 'Transfer',
};

export const distributionMethodShortNames = {
  [DepositMethod.cash]: 'Cash',
  [DepositMethod.check]: 'Check',
  [DepositMethod.direct]: 'Direct Deposit',
  [DepositMethod.transfer]: 'Transfer',
};

export const eSignLandingPageLabels = {
  [EsignLandingPage.eSignManger]: 'eSignature Management page',
  [EsignLandingPage.docqDetails]: 'Session Details page',
};

export const eSignLandingPageOptions: Array<SiraSelectItem> = [
  {
    value: EsignLandingPage.eSignManger,
    label: eSignLandingPageLabels[EsignLandingPage.eSignManger],
  },
  {
    value: EsignLandingPage.docqDetails,
    label: eSignLandingPageLabels[EsignLandingPage.docqDetails],
  },
];
