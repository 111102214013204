export interface ConstantsMappingResponse {
  data?: any;
  errorMessage?: any;
}

export enum ConstantsMappingKey {
  accountType = 'ACCOUNT_TYPE',
  distributionFrequency = 'DISTRIBUTION_FREQUENCY',
  relationshipType = 'RELATIONSHIP_TYPE',
  depositType = 'DEPOSIT_TYPE',
  distributionReason = 'DISTRIBUTION_REASON',
  distributionReasonCode = 'DISTRIBUTION_REASON_CODE',
  depositMethod = 'DEPOSIT_METHOD',
  relationshipToCurrentOwner = 'RELATIONSHIP_TO_CURRENT_OWNER',
  distributionMethod = 'DISTRIBUTION_METHOD',
  country='COUNTRY',
  beneficiaryDistributionOption='BENEFICIARY_DISTRIBUTION_OPTIONS',
  taxYears='TAX_YEARS',
}

export interface TaxYearsMapping {
  taxYear: string;
  active: boolean;
}