import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Grid, Typography } from '@mui/material';

import { DistributionAmountFormData } from './DistributionAmountForm.d';
import SiraCurrencyField from '../SiraCurrencyField';
import { distributionAmountHelpText } from './resource.txt';
import SiraSwitchTooltip from '../SiraSwitchToolTip';

function NetOrTotalAmountField() {
  const { values, setValues } = useFormikContext();
  const [isInitialized, setIsInitialized] = useState(false);
  const { netAmountEntered, netAmount, totalAmount } =
    values as DistributionAmountFormData;

  useEffect(() => {
    if (!isInitialized) {
      setValues({
        ...(values as DistributionAmountFormData),
        netAmount: netAmountEntered ? netAmount : 0,
        totalAmount: !netAmountEntered ? totalAmount : 0,
      });
      setIsInitialized(true);
    }
    if (isInitialized) {
      setValues({
        ...(values as DistributionAmountFormData),
        netAmount: netAmountEntered ? totalAmount : 0,
        totalAmount: !netAmountEntered ? netAmount : 0,
      });
    }
  }, [netAmountEntered]);

  return (
    <>
      <Grid item xs={12} lg={6}>
        {netAmountEntered ? (
          <SiraCurrencyField
            name="netAmount"
            label="Net Amount"
            allowNegative={false}
          />
        ) : (
          <SiraCurrencyField
            name="totalAmount"
            label="Total Amount"
            allowNegative={false}
          />
        )}
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography component="div">
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            wrap="nowrap"
          >
            <Grid item>Total</Grid>
            <Grid item>
              <SiraSwitchTooltip
                checked={netAmountEntered}
                name="netAmountEntered"
                value={distributionAmountHelpText.totalNetIndicator.value}
              />
            </Grid>
            <Grid item>Net</Grid>
          </Grid>
        </Typography>
      </Grid>
    </>
  );
}

export default NetOrTotalAmountField;
