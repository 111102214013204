import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Button, LinearProgress, Tooltip, IconButton } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { globalPaginationOptions } from '../app.constants';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import { useUser } from '../auth/useUser';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import {
  MultiOrgBeneficiaryClaimResult,
} from '../api/BeneficiaryClaimsApi.d';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import { TransactionType } from '../api/AccountApi.d';
import { dateValueFormatter } from '../utils/DataGrid.utils';
import { createFileBlobFromBase64, createFileSizeReadable, downloadAsFile } from '../utils/App.utils';
import { deleteIrsFile, getIrsFileList, retreiveIrsFile } from '../api/IrsFileApi';
import { errorMessages } from '../utils/errorhandling.utils';

interface BeneficiaryClaimRow extends MultiOrgBeneficiaryClaimResult {
  transactionType: TransactionType;
}



const classes = {
  fileLink: {
    justifyContent: 'flex-start' as any,
    width: '100%',
    textDecoration: 'none' as any,
    textTransform: 'none' as any,
  },
};


function TaxReportingDashboard() {
    let isMounted = true;
    const { user } = useUser();
    const { addGlobalMessage } =
      useGlobalContext();
    const { globalPageSize, setGlobalPageSize } = usePaginationContext();
    const [isLoading, setIsLoading] = useState(false as boolean);

    const [filteredRows, setFilteredRows] = useState(
      [] as Array<BeneficiaryClaimRow>
    );
  
  
    async function fetchAndSetIrsTaxFile() {
      setIsLoading(true);
  
      await getIrsFileList(user.token)
        .then(({ data }) => {
          setFilteredRows(data);
        })
        .catch((err) => {
  
  
 addGlobalMessage(errorMessages(err) || 'Error fetching list of IRS files'
          );
        });
  
      if (isMounted) {
        setIsLoading(false);
      }
    }
  
    async function deleteSingleIrsFile(row): Promise<void> {
      const nameValue = row.key.replace(/^.*[\\/]/, '');
      await deleteIrsFile(
        nameValue,
        user.token
      )
        .then(() => {
          fetchAndSetIrsTaxFile();
        })
        .catch((err) => {
  
          if (isMounted) {
            setIsLoading(false);
             addGlobalMessage(errorMessages(err)||
                'Could not fetch the irs documents'
            );
          }
        });
    }

    async function downloadFile(row): Promise<void> {
      const nameValue = row.key.replace(/^.*[\\/]/, '');
      await retreiveIrsFile(
        nameValue,
        user.token
      )
        .then(({ data }) => {
          const { bytes: base64 = '' } = data;
          const blob =
            createFileBlobFromBase64(base64, 'application/pdf') || data;
  
          downloadAsFile(blob, nameValue);
        })
        .catch((err) => {
  
          if (isMounted) {
            setIsLoading(false);
             addGlobalMessage(errorMessages(err)||
                'Could not fetch this individuals organization documents'
            );
          }
        });
    }
  
    const columns: GridColDef[] = [
      {
        field: 'key',
        headerName: 'File Name',
        width: 500,
        renderCell: (params: GridCellParams) => {
          const { row = {} } = params;
          const nameValue = row.key.replace(/^.*[\\/]/, '');

          return (
            <Button
              data-qa="account-search-result-link"
              sx={classes.fileLink}
              startIcon={<FileDownloadIcon />}
              onClick={() => {
                downloadFile(row);
              }}
              title={nameValue}
            >
              <Box overflow="hidden" textOverflow="ellipsis">
                {nameValue}
              </Box>
            </Button>
          );
        },
      },
      {
        field: 'lastModified',
        headerName: 'Submitted Date',
        width: 126,
        valueFormatter: dateValueFormatter,
      },
      {
        field: 'size',
        headerName: 'File Size',
        width: 175,
        renderCell: (params: GridCellParams) => {
          const { row = {} } = params;
          return createFileSizeReadable(row.size) 
    
        },
      },
      {
        field: 'delete',
        headerName: '',
        width: 50,
        sortable: false,
        renderCell: (params: GridCellParams) => {
          const { row = {} } = params;

          return (
            <>
              <Box pl={1}>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => {
                      deleteSingleIrsFile(row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          );
        },
      },
    ];
  

    useEffect(() => {
      if (user.token) {
        fetchAndSetIrsTaxFile();
      }
  
      return () => {
        isMounted = false;
      };
    }, [user.token]);
  
    return (
      <Layout>
        <PaperlessPage>
          <>
            <Box mb={5}>
              <Typography variant="overline" gutterBottom>
                Tax Reporting Dashboard
              </Typography>
              <Typography variant="h1" color="secondary" gutterBottom>
                All Tax Reports
              </Typography>
            </Box>
  
            {isLoading ? (
              <LinearProgress color="secondary" />
            ) : (
              <Paper>
                <DataGrid
                  components={{
                    NoRowsOverlay: SiraNoRowsOverlay,
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: globalPageSize } },
                  }}
                  pageSizeOptions={globalPaginationOptions}
                  onPaginationModelChange={setGlobalPageSize}
                  autoHeight
                  columns={columns}
                  rows={filteredRows.map((result, id) => ({
                    ...result,
                    id,
                  }))}
                />
              </Paper>
            )}
          </>
        </PaperlessPage>
      </Layout>
    );
}

export default TaxReportingDashboard;
