import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  IconButton,
  Typography,
  LinearProgress,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import NumberFormat from 'react-number-format';
import { useFormikContext } from 'formik';
import { useUser } from '../../../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../../../utils/DataGrid.utils';
import {
  Distribution,
  DistributionMethod,
  DistributionReason,
  DistributionStatus,
} from '../../../api/DistributionApi.d';

import {
  createDistribution,
  deleteDistribution,
  getAllDistributions,
  getDistributions,
  updateDistribution,
} from '../../../api/DistributionApi';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import {
  getConstantActiveTaxYear,
  globalPaginationOptions,
} from '../../../app.constants';
import { Account, AccountType, TransactionType } from '../../../api/AccountApi.d';

import {
  deleteScheduledDistribution,
  updateScheduledDistribution,
} from '../../../api/ScheduledDistributionApi';
import ReconcileDistributionForm, { DIST_INIT } from './EditDistribution';
import { errorMessages } from '../../../utils/errorhandling.utils';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { BeneficiariesClaimFiles } from '../beneficiariesClaims/BeneficiariesClaimsForm.d';

interface TaxFormDistributionProps {
  account?: Account;
  taxFormId?: string;
  accountOwner?: AccountOwner;
}

function TaxFormDistributions(props: TaxFormDistributionProps) {
  let isMounted = true;
  const { account, taxFormId, accountOwner } = props;
  const { accountId, accountOwnerId, accountType } = account;
  const [distToEdit, setDistToEdit] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [errorMessage] = useState('');
  const [scheduledDists, setScheduledDists] = useState(
    [] as Array<Distribution>
  );
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { setGlobalPageSize } = usePaginationContext();
  const { token, organizationId } = user;
  const [allDistributions, setAllDistributions] = useState(
    [] as Array<Distribution>
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addNewDistribution, setAddDistribution] = useState(false);
  const [editCurrentDistribution, setEditCurrentDistribution] = useState(false);
      const { values } = useFormikContext() || {};
  const [reasoning , setReasoning] = useState(DistributionReason.IRA_NORMAL);
  const [distributionStatus, setDistributionStatus] = useState(DistributionStatus.active);
  
  async function getActiveDistributions() {

    await getDistributions(
      user.organizationId,
      [DistributionStatus.active],
      user.token,
      Number(taxFormId)
    )
      .then((res) => {
        setIsLoading(false);

        if (isMounted) {
          if (res.data.length > 1) {
            const moveTotals = res.data.map((item) => {
              const { totalAmount, grossAmount } = item;
              let amount = 0;
              if (item.distributionId) {
                amount = grossAmount;
              } else {
                amount = totalAmount;
              }
              return {
                ...item,
                calcAmount: amount,
              };
            });

            const overAlltotal = moveTotals.reduce((acc: any, val: any) => ({
              totalAmount: acc.totalAmount + val.calcAmount,
              federalWithholdingAmount:
                acc.federalWithholdingAmount + val.federalWithholdingAmount,
              stateWithholdingAmount:
                acc.stateWithholdingAmount + val.stateWithholdingAmount,
              totalStatus: 'total',
            }));

            res.data.push(overAlltotal);
          } else {
            res.data.push({
              totalAmount: res.data[0].grossAmount,
              federalWithholdingAmount: res.data[0].federalWithholdingAmount,
              stateWithholdingAmount: res.data[0].stateWithholdingAmount,
              totalStatus: 'total',
            });
          }

          
          setAllDistributions(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (isMounted) {
          setAllDistributions([]);
          addGlobalMessage(
            errorMessages(err) || `Error fetching account distributions`
          );
        }
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  async function getAllRelevantDistributions() {
    let year;

    if (taxFormId) {
      year = getConstantActiveTaxYear();
    } else {
      const { dateOfDeath } = values as BeneficiariesClaimFiles;
      year = new Date(dateOfDeath).getFullYear().toString();
    }

    await getAllDistributions(
      user.organizationId,
      [],
      user.token,
      accountId,
      accountOwnerId,
      year
    )
      .then((res) => {
        setIsLoading(false);
        if (isMounted) {
          if (res.data.length > 1) {
            const moveTotals = res.data.map((item) => {
              const { totalAmount, grossAmount } = item;
              let amount = 0;
              if (item.distributionId) {
                amount = grossAmount;
              } else {
                amount = totalAmount;
              }
              return {
                ...item,
                calcAmount: amount,
              };
            });

            const overAlltotal = moveTotals.reduce((acc: any, val: any) => ({
              totalAmount: acc.totalAmount + val.calcAmount,
              federalWithholdingAmount:
                acc.federalWithholdingAmount + val.federalWithholdingAmount,
              stateWithholdingAmount:
                acc.stateWithholdingAmount + val.stateWithholdingAmount,
              totalStatus: 'total',
            }));

            res.data.push(overAlltotal);
          }
          setAllDistributions(res.data ? res.data : []);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          setAllDistributions([]);
          addGlobalMessage(
            errorMessages(err) || `Error fetching account distributions`
          );
        }
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  async function removeDistribution(distribution: Distribution) {
    await deleteDistribution(
      accountId,
      accountOwnerId,
      organizationId,
      distribution.distributionId,
      token,
      user
    )
      .then(() => {
        const newDists = [...scheduledDists];
        const targetIndex = newDists.findIndex(
          (item) => item.distributionId === distribution.distributionId
        );
        newDists.splice(targetIndex, 1);
        setScheduledDists(newDists);
        addGlobalMessage('Distribution deletion was successful', {
          success: true,
        });
        getAllRelevantDistributions();
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Error Deleting Distribution');
        }
      });
  }

  async function removeSchedDistribution(scheduledDistribution: Distribution) {
    await deleteScheduledDistribution(
      organizationId,
      accountId,
      accountOwnerId,
      scheduledDistribution.scheduledDistributionId,
      token,
      user
    )
      .then(() => {
        const newDists = [...scheduledDists];
        const targetIndex = newDists.findIndex(
          (item) =>
            item.scheduledDistributionId ===
            scheduledDistribution.scheduledDistributionId
        );
        newDists.splice(targetIndex, 1);
        setScheduledDists(newDists);
        addGlobalMessage('Scheduled Distribution deletion was successful', {
          success: true,
        });
        getAllRelevantDistributions();
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Error Deleting Scheduled Distribution'
          );
        }
      });
  }

  async function update(distribution: Distribution) {
    const newDistribution = { ...distribution, netAmount: 0 };

    await updateDistribution(
      newDistribution,
      accountId,
      accountOwnerId,
      organizationId,
      token,
      user
    )
      .then(() => {
        if (isMounted) {
          if (taxFormId) {
            getActiveDistributions();
          } else {
            getAllRelevantDistributions();
          }
          setShowForm(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setShowForm(false);
          addGlobalMessage(errorMessages(err) || 'Error Updating Distribution');
        }
      });
  }

  async function updateScheduled(scheduledDistribution: Distribution) {
    const newDistribution = { ...scheduledDistribution, netAmount: 0 };

    await updateScheduledDistribution(
      newDistribution,
      organizationId,
      accountId,
      accountOwnerId,
      token,
      user
    )
      .then(() => {
        if (isMounted) {
          if (taxFormId) {
            getActiveDistributions();
          } else {
            getAllRelevantDistributions();
          }
          setShowForm(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setShowForm(false);
          addGlobalMessage(
            errorMessages(err) || 'Error Updating Scheduled Distribution'
          );
        }
      });
  }

  async function editDistribution(distribution: Distribution) {
    if (distribution.distributionId) {
      update(distribution);
    }

    if (distribution.scheduledDistributionId) {
      updateScheduled(distribution);
    }
  }

  async function distributionDelete(distribution: Distribution) {
    if (distribution.distributionId) {
      removeDistribution(distribution);
    }

    if (distribution.scheduledDistributionId) {
      removeSchedDistribution(distribution);
    }
  }

  async function addDistribution(distribution: Distribution) {
    const newSchedDist = {
      ...distribution,
      distributionMethod: DistributionMethod.cash,
    } as Distribution;

    await createDistribution(
      newSchedDist,
      accountId,
      accountOwnerId,
      organizationId,
      token,
      user
    )
      .then(({ data }) => {
        const tempDists = [...scheduledDists, data];

        if (isMounted) {
          setScheduledDists(tempDists);
          setShowForm(false);
          getAllRelevantDistributions();
        }
      })
      .catch((err) => {
        if (isMounted) {
          setShowForm(false);
          addGlobalMessage(
            errorMessages(err) || 'Error Adding Scheduled Distribution'
          );
        }
      });
  }

  const columns = [
    {
      field: 'totalAmount',
      headerName: 'Gross Amount',
      headerAlign: 'center',
      type: 'number',
      width: 148,
      valueFormatter: currencyValueFormatter,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const { totalAmount, grossAmount } = row as Distribution;

        if (row.distributionId) {
          return (
            <NumberFormat
              value={grossAmount}
              displayType="text"
              thousandSeparator
              prefix="$"
              decimalScale={2}
            />
          );
        }
        return (
          <NumberFormat
            value={totalAmount}
            displayType="text"
            thousandSeparator
            prefix="$"
            decimalScale={2}
          />
        );
      },
    },
    {
      field: 'federalWithholdingAmount',
      headerName: 'Federal Tax',
      sortable: false,
      headerAlign: 'center',
      type: 'number',
      width: 124,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'stateWithholdingAmount',
      headerName: 'State Tax',
      sortable: false,
      headerAlign: 'center',
      type: 'number',
      width: 124,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'effectiveDate',
      headerName: 'Date',
      width: 112,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 110,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const { effectiveDate } = row as Distribution;
        return (
          effectiveDate && (
            <>
              <Grid container justifyContent="flex-end">
                <IconButton
                  data-testid="EditSchedDist"
                  size="small"
                  aria-label="Edit Scheduled Distribution"
                  onClick={() => {
                    setDistToEdit(row as Distribution);
                    setDistributionStatus(row.distributionStatus)
                    setShowForm(true);
                    setAddDistribution(false);
                    setEditCurrentDistribution(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  data-testid="DeleteScheduledDistribution"
                  size="small"
                  aria-label="Delete Scheduled Distribution"
                  onClick={async () => {
                    await distributionDelete(row as Distribution);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </>
          )
        );
      },
    },
  ] as GridColDef[];

  useEffect(() => {
    if (user.organizationId && user.token) {
      setIsLoading(true);

      if (taxFormId) {
        getActiveDistributions();
      } else {
        getAllRelevantDistributions();
      }
      switch (accountType) {
        case AccountType.inheritedRoth:
          setReasoning(account.fiveYearTest ? DistributionReason.ROTH_QUALIFIED : DistributionReason.ROTH_EXCEPTION);
          setDistributionStatus(DistributionStatus.active);
          break;
        case AccountType.inheritedTraditional:
          setReasoning(DistributionReason.I_IRA_DEATH);
          setDistributionStatus(DistributionStatus.active);

          break;
        default:
          setReasoning(DistributionReason.IRA_NORMAL);
          setDistributionStatus(DistributionStatus.active);

          break;
      }
    }
    return () => {
      isMounted = false;
    };
  }, [user.token]);

  return showForm ? (
    <>
      {!taxFormId ? (
        <>
          <Typography variant="body1" color="primary" mb={1}>
            Confirm all distributions taken in the year of death are listed
            below to determine if the RMD was satisfied.
          </Typography>
          <Typography variant="body1" color="primary" mb={1}>
            Any distributions added, edited, or deleted in this section will
            instantly update the Distributions listed under the owner’s account.
          </Typography>
        </>
      ) : null}
      <ReconcileDistributionForm
        initialValues={{
          distributionReason: reasoning,
          distributionStatus,
          ...DIST_INIT,
          ...distToEdit,
        }}
        onSubmit={async (data) => {
          if (editCurrentDistribution) {
            await editDistribution(data as Distribution);
          } else if (addNewDistribution) {
            await addDistribution(data as Distribution);
          }
        }}
        onCancel={() => {
          setShowForm(false);
          setAddDistribution(false);
          setEditCurrentDistribution(false);
        }}
        accountType={accountType}
        accountOwner={accountOwner}
      />
    </>
  ) : (
    <Box width="2">
      <Typography variant="body2" color="error">
        {errorMessage}
      </Typography>
      <Box>
        {isLoading ? (
          <Box width="1">
            <LinearProgress color="secondary" />
          </Box>
        ) : (
          <Box
            sx={{
              '& .super-app-theme--total': {
                borderTop: 1,
              },
            }}
          >
            {!taxFormId ? (
              <>
                <Typography variant="body1" color="primary" mb={1}>
                  Confirm all distributions taken in the year of death are
                  listed below to determine if the RMD was satisfied.
                </Typography>
                <Typography variant="body1" color="primary" mb={1}>
                  Any distributions added, edited, or deleted in this section
                  will instantly update the Distributions listed under the
                  owner’s account.
                </Typography>
              </>
            ) : null}
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              disableColumnMenu
              autoHeight
              columns={columns}
              rows={allDistributions.map((t, id) => ({
                id,
                transactionType: TransactionType.distribution,
                ...t,
              }))}
              getRowClassName={(params: any) =>
                `super-app-theme--${params.row.totalStatus}`
              }
            />

            {!taxFormId ? (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    setDistToEdit({} as Distribution);
                    setShowForm(true);
                    setAddDistribution(true);
                  }}
                >
                  Add Distribution
                </Button>
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TaxFormDistributions;
