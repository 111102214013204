import React from 'react';
import { Alert, Box, Typography } from '@mui/material';

import { useUser } from '../auth/useUser';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import FinancialOrgForm, {
  FINANCIAL_ORG_INIT,
} from '../components/form/organization/FinancialOrgForm';
import BranchForm, {
  BRANCH_INFO_INIT,
} from '../components/form/organization/BranchForm';
import {
  addTransactionData,
  completeTransaction,
  rejectTransaction,
  skipStep,
  useTransactionReducer,
} from './TransactionReducer';
import {
  createOrSaveOrg,
  createOrSaveBranch,
  createStateTaxId,
} from '../api/OrganizationApi';
import StateTaxIdForm, {
  STATE_TAX_ID_INIT,
} from '../components/form/organization/StateTaxIdForm';
import { useGlobalContext } from '../auth/useGlobalContext';
import TransactionStepper, { TransactionStep } from './TransactionStepper';
import FinancialOrgEsignSettingsForm from '../components/form/organization/FinancialOrgEsignSettings';
import { errorMessages } from '../utils/errorhandling.utils';

function AddNewOrg() {
  let isMounted = true;
  const { addGlobalMessage } = useGlobalContext();
  const [pageState] = useTransactionReducer();
  const { user } = useUser();
  // Default the first item of each of these arrays if not defined
  // We'll pick off the first one down below
  const { branches = [BRANCH_INFO_INIT], stateTaxIDS = [STATE_TAX_ID_INIT] } =
    pageState.organizationInformation;

  const submitOrgStep = async (data: any) => {
    return createOrSaveOrg(data, user.token)
      .then((res) => {
        if (isMounted) {
          addTransactionData({ organizationInformation: res.data });
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Could not create organization'
          );
        }
      });
  };

  const submitBranchStep = async (data: any) => {
    return createOrSaveBranch(
      data,
      pageState.organizationInformation,
      user.token
    )
      .then((res) => {
        if (isMounted) {
          if (isMounted) {
            addTransactionData({
              organizationInformation: { branches: [...res.data] },
            });
          }
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Could not create branch');
        }
      });
  };

  const submitStateTaxIdStep = async (data: any) => {
    return createStateTaxId(data, pageState.organizationInformation, user.token)
      .then((res) => {
        if (isMounted) {
          addTransactionData({
            organizationInformation: { stateTaxIDS: [res.data] },
          });
          completeTransaction();
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Could not create state tax IDs'
          );
          rejectTransaction();
        }
      });
  };

  const newOrgSteps: Array<TransactionStep> = [
    {
      label: 'Organization Information',
      stepContent: (
        <Box mt={5} mb={3}>
          <FinancialOrgForm
            onSubmit={submitOrgStep}
            initialValues={{
              ...FINANCIAL_ORG_INIT,
              ...pageState.organizationInformation,
            }}
          />
        </Box>
      ),
    },
    {
      label: 'Branch Information',
      stepContent: (
        <Box mt={5} mb={3}>
          <BranchForm
            onSubmit={submitBranchStep}
            initialValues={{
              ...BRANCH_INFO_INIT,
              ...branches[0],
            }}
          />
        </Box>
      ),
    },
    {
      label: 'State Tax ID',
      stepContent: (
        <>
          <Box mt={5} mb={3}>
            <StateTaxIdForm
              onSubmit={submitStateTaxIdStep}
              initialValues={{
                ...STATE_TAX_ID_INIT,
                ...stateTaxIDS[0],
              }}
              submitName="Finish"
            />
          </Box>
        </>
      ),
    },
  ];

  React.useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Layout>
      <FormPaper>
        <Box width="100%">
          <Typography variant="overline">Onboarding</Typography>
          <Typography variant="h1" color="secondary" gutterBottom>
            Add New Organization
          </Typography>
          <Typography variant="body1">
            Control user permissions and site admin from this set of options
          </Typography>
          <Box mt={5} mb={3}>
            <TransactionStepper
              steps={newOrgSteps}
              activeStep={pageState.activeStep}
              onStepClick={(index) => {
                skipStep(index);
              }}
            />
          </Box>
          {(pageState.completed || pageState.rejected) && (
            <Alert
              severity={pageState.rejected ? 'error' : 'success'}
              variant="outlined"
            >
              {pageState.rejected
                ? 'Organization could not be added.'
                : 'Organization successfully added.'}
            </Alert>
          )}
        </Box>
      </FormPaper>
    </Layout>
  );
}

export default AddNewOrg;
