import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button, LinearProgress } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';

import { endOfMonth, format, startOfMonth } from 'date-fns';
import Layout from '../Layout';
import FormPaper from '../FormPaper';

import { ScheduledDistribution } from '../../api/ScheduledDistributionListApi.d';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import {
  distributionMethodShortNames,
  globalPaginationOptions,
} from '../../app.constants';
import {
  currencyValueFormatterPro,
  dateValueFormatterPro,
} from '../../utils/DataGrid.utils';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import ObscuredTaxId, { TaxIdFormat } from '../ObscuredTaxId';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import ScheduledDistributionSearchForm from '../form/recurringDistributions/ScheduleDistributionSearchForm';
import { getOrgScheduledDistributions } from '../../api/ScheduledDistributionApi';

import { AccountDetailAccordionType } from '../accountOwnerProfile/AccountOwnerAccountDetails';
import { AccountMemberValue } from '../../api/OrganizationApi.d';
import { DistributionChoices } from '../form/distribution/DistributionAmountRecurringSubForm';
import { errorMessages } from '../../utils/errorhandling.utils';
// import DataGridExportToolbar from '../DataGridExportToolbar';

export const distributionChoices = {
  [DistributionChoices.empty]:'',
  [DistributionChoices.rmd]:'RMD',
  [DistributionChoices.owner]:'Owner',
  [DistributionChoices.amount]:'Specific Amount',
  [DistributionChoices.amortization]: 'Amortization',
  [DistributionChoices.annuitization]: 'Annuitization',
}

interface ScheduleDistributionFilterProps {
  onItemClick: Function;
  statusFilter: boolean;
}

interface ScheduledDistributionRow extends ScheduledDistribution {
  id: number;
}

// Flatten and map out the withholding notifications for display in the data grid
function ScheduleDistributionRowsFactory(
  distributions: Array<ScheduledDistribution>
): Array<ScheduledDistributionRow> {
  return distributions.map((result, id) => ({
    ...result,
    id,
  }));
}

function ScheduleDistributionFilter(props: ScheduleDistributionFilterProps) {
  const { onItemClick = () => {}, statusFilter } = props;

  return (
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body2">Filter by:</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={statusFilter ? 'contained' : 'outlined'}
            color="success"
            startIcon={<CheckCircleIcon />}
            size="small"
            onClick={() => {
              onItemClick(true);
            }}
          >
            Completed
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={statusFilter === false ? 'contained' : 'outlined'}
            color="warning"
            startIcon={<PendingIcon />}
            size="small"
            onClick={() => {
              onItemClick(false);
            }}
          >
            Pending
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

  const useStyles = makeStyles(() => ({
    toolbarContainer: {
      display: 'flex',
      justifyContent: 'flex-end',      
      padding:"15px"

    },
    exportButton: {
      backgroundColor: 'black', // Set your desired background color
      color: 'white', // Set your desired text color
      '&:hover': {
        backgroundColor: 'black', // Set your desired hover background color
      },
      padding:"5px"
    },
  }));

function ScheduledDistributionList() {
  const isMounted = true;
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage , organization } = useGlobalContext();
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const { accountNumberValue } = organization;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [statusFilter, setStatusFilter] = useState(null as boolean);
  const [orgScheduleDistribution, setOrgScheduleDistribution] = useState(
    [] as Array<ScheduledDistribution>
  );

  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account #': 'Member #';


  const classes = useStyles();

  // Allow the filter items to be selected or deselected
  const handleTaskStatusClick = (scheduleDistributionStatuses: boolean) => {
    if (statusFilter === scheduleDistributionStatuses) {
      setStatusFilter(null);
    } else {
      setStatusFilter(scheduleDistributionStatuses);
    }
  };

  // Fetch all withholding notifications for the current organization
  const getAndSetOrgScheduledDistributions = async ({
    startDate,
    endDate,
  }): Promise<void> => {
    setIsLoading(true);

    await getOrgScheduledDistributions(
      user.organizationId,
      user.token,
      startDate,
      endDate
    )
      .then(({ data = [] }) => {
        if (isMounted) {
          setOrgScheduleDistribution(data);
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) ||
            'Error fetching organization withholding notifications'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  };

  const gotoRecordAccount = (row: ScheduledDistribution): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.distributions}`
    );
  };

  const columns = [
    {
      field: 'accountOwnerFullName',
      headerName: 'Owner',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              gotoRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 136,
      valueFormatter: (value) => {
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 116,
      sortable: false,
    },
    {
      field: 'accountOwnerTaxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'recurringDistAmountType',
      headerName: 'Distribution Type',
      width: 156,
      valueFormatter: (value) => {
        return distributionChoices[String(value)] || String(value);
      },
    },
    {
      field: 'distributionDate',
      headerName: 'Distribution Date',
      width: 156,
      valueFormatter: dateValueFormatterPro,
    },
    {
      field: 'distributionFrequency',
      headerName: 'Distribution Frequency',
      width: 156,
      valueFormatter: (value) => {
        return getAppConstant(ConstantsMappingKey.distributionFrequency, value);
      },
    },
    {
      field: 'distributionMethod',
      headerName: 'Method',
      width: 126,
      valueFormatter: (value) => {
        return distributionMethodShortNames[String(value)] || String(value);
      },
    },
    {
      field: 'distributionReason',
      headerName: 'Distribution Reason',
      width: 255,
      valueFormatter: (value) => {
        return getAppConstant(ConstantsMappingKey.distributionReason, value);
      },
    },
    {
      field: 'toFinancialOrganization',
      headerName: 'To Organization',
      width: 156,
    },
    {
      field: 'toAccountNumber',
      headerName: `To ${headerAccountName}`,
      width: 116,
      sortable: false,
    },
    {
      field: 'toAccountType',
      headerName: 'To Account Type',
      width: 136,
      valueFormatter: (value) => {
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'totalAmount',
      headerName: 'Gross Amount',
      width: 156,
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'netAmount',
      headerName: 'Net Amount',
      width: 136,
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'stateWithholdingAmount',
      headerName: 'State Withholding',
      width: 156,
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'federalWithholdingAmount',
      headerName: 'Federal Withholding',
      width: 176,
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'scheduledDistributionId',
      headerName: 'Distribution Status',
      width: 156,
      valueFormatter: (value) => {
        let statusComponents;
        if (value) {
          statusComponents = 'Completed';
        } else {
          statusComponents = 'Pending';
        }

        return statusComponents;
      },
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        let statusComponents;
        if (value) {
          statusComponents = (
            <Button color="success" startIcon={<CheckCircleIcon />}>
              Completed
            </Button>
          );
        } else {
          statusComponents = (
            <Button color="warning" startIcon={<PendingIcon />}>
              Pending
            </Button>
          );
        }
        return statusComponents;
      },
    },
  ] as GridColDef[];

  const rows = ScheduleDistributionRowsFactory(orgScheduleDistribution);

  const filteredRows = rows.filter((row) => {
    const isScheduled = Boolean(row.scheduledDistributionId);

    if (statusFilter !== null) {
      return isScheduled === statusFilter;
    }
    return row;
  });

  // const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({
  //   left: ['name'],
  // });

  // const handlePinnedColumnsChange = React.useCallback(
  //   (updatedPinnedColumns: GridPinnedColumns) => {
  //     setPinnedColumns(updatedPinnedColumns);
  //   },
  //   [],
  // );

  const CustomGridToolbarContainer = () => {


    return (
      <GridToolbarContainer className={classes.toolbarContainer}>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} slotProps={{ button: { variant: 'contained'} }}/>
      </GridToolbarContainer>
    );
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Recurring Distributions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Scheduled Distributions
          </Typography>

          <ScheduledDistributionSearchForm
            initialValues={{
              startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
              endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
            }}
            onSubmit={getAndSetOrgScheduledDistributions}
          />
          <ScheduleDistributionFilter
            statusFilter={statusFilter}
            onItemClick={handleTaskStatusClick}
          />
          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <DataGridPro
              slots={{
                noRowsOverlay: SiraNoRowsOverlay,
                toolbar: CustomGridToolbarContainer
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pagination
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={filteredRows}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default ScheduledDistributionList;
