import React from 'react';

import { Grid, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddMoneyIcon from '../icons/AddMoney';

import RemoveMoneyIcon from '../icons/RemoveMoney';
import PaperlessPage from '../components/PaperlessPage';
import Layout from '../components/Layout';
import ActionCard from '../components/ActionCard';
import Import5498 from '../icons/Import5498Tax';
import { useGlobalContext } from '../auth/useGlobalContext';

function ReportsFmv() {
  const { organization } = useGlobalContext();
  const { sftpFolderName } = organization;

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Tax Reporting
          </Typography>
          <Grid container spacing={3} wrap="wrap" mt={2}>
            <ActionCard
              dataQa="fmv-tax-reporting"
              iconImage={
                <AttachMoneyIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="Fair Market Value Needed"
              route="/taxReporting/FMV"
            />{' '}
            <ActionCard
              dataQa="1099-tax-reporting-report"
              iconImage={
                <RemoveMoneyIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="1099 Tax Report"
              route="/taxReporting/1099"
            />{' '}
            <ActionCard
              dataQa="5498-tax-reporting-report"
              iconImage={
                <AddMoneyIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="5498 Tax Report"
              route="/taxReporting/5498"
            />
            <ActionCard
              dataQa="1099-tax-reporting-checklist"
              iconImage={
                <FactCheckIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="1099 Tax Report Checklist"
              route="/taxReporting/checklist"
            />{' '}
            <ActionCard
              dataQa="1099-tax-reporting-checklist"
              iconImage={
                <FactCheckIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="5498 Tax Report Checklist"
              route="/taxReporting/checklist5498"
            />{' '}
            <ActionCard
              dataQa="1099-totals-page"
              iconImage={
                <Import5498 color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="1099 Totals Page"
              route="/taxReporting/totalsPage/1099"
            />{' '}
            <ActionCard
              dataQa="1099-State-Tax-Files"
              iconImage={
                <ArticleIcon color="secondary" style={{ fontSize: 42 }} />
              }
              buttonText="1099 State Tax Files"
              route="/taxReporting/stateTaxFiles/1099"
            />{' '}
            {sftpFolderName ? (
              <ActionCard
                dataQa="Download 1099/5498 Tax Forms"
                iconImage={
                  <PictureAsPdfIcon
                    color="secondary"
                    style={{ fontSize: 42 }}
                  />
                }
                buttonText="Download 1099/5498 Tax Forms"
                route='/taxReporting/downloadTaxForms'
              />
            ) : null}{' '}
          </Grid>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default ReportsFmv;
