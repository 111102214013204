import React, { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Layout from '../../Layout';
import { useUser } from '../../../auth/useUser';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import {
  addTransactionData,
  completeTransaction,
  rejectTransaction,
  setBeneficiarySearchResponse,
  setSelectedAccount,
  skipStep,
  useTransactionReducer,
} from '../../../page/TransactionReducer';
import { AccountStatus, TransactionType } from '../../../api/AccountApi.d';
import ClaimsSearchResults from './BeneficiariesClaimsSearchResults';
import BeneficiariesClaimsForm, {
  BENEFICIARIES_CLAIMS_INIT,
} from './BeneficiariesClaimsForm';
import { getAccountOwner } from '../../../api/AccountOwnerApi';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { getUsers } from '../../../api/UserApi';
import { User } from '../../../api/UserApi.d';
import ContactInformationForm, {
  CONTACT_INFORMATION_INIT,
} from './BeneficiariesClaimsContactInfoForm';
import BeneficiariesClaimsRepForm, {
  BENEFICIARIES_REP_INIT,
} from './BeneficiariesClaimsRepForm';
import BeneficiariesAdditionalInfoForm, {
  BENEFICIARIES_ADDITIONAL_INFO_INIT,
} from './BeneficiariesAdditionalInfo';
import FormPaper from '../../FormPaper';
import TransactionStepper, {
  TransactionStep,
} from '../../../page/TransactionStepper';
import BeneficiaryClaimSearchForm from './BeneficiaryClaimSearchForm';
import {
  createOrSaveBeneficiaryClaim,
  getBeneficiariesClaimsProfile,
  getDeathCertificate,
  saveBeneficiaryClaimFiles,
  updateBeneficiaryClaimStatus,
} from '../../../api/BeneficiariesClaimsApi';
import SiraAccountOwnerInfo from '../SiraAccountOwnerInfo';
import {
  BeneficiariesClaimsInfo,
  BeneficiaryStatus,
  ClaimDocumentType,
} from '../../../api/BeneficiaryClaimsApi.d';
import { getAccount } from '../../../api/AccountApi';
import { setFileTypeName } from './resource.utils';
import SiraVerifyForm from '../SiraVerificationForm';
import {
  useUnsavedChangesWarning,
  RowDefinition,
} from '../../useUnsavedChangesWarning';
import BeneficiariesDesignatedForm, {
  BENEFICIARIES_DESIGNEE_INIT,
} from './BeneficiariesDesignatedInfo';
import SiraPageAlert from '../../SiraPageAlert';
import { errorMessages } from '../../../utils/errorhandling.utils';

function SubmitBeneficiaryClaim() {
  let isMounted = true;
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [response, setResponse] = useState<Array<User>>([]);
  const [rejected, setRejected] = useState(true);
  const queryParams = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState(false);
  const [pageState] = useTransactionReducer();
  const { query = '' } = pageState.searchResponse || {};
  const {
    accountId = '',
    accountOwnerId = '',
    beneficiaryClaimId = '',
    beneficiaryClaimStatus,
  } = pageState.selectedAccount;
  const loadingExistingAccount = Boolean(queryParams.get('accountId'));
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();

  const convertToFile = (file) => {
    const fileType = setFileTypeName(file.fileName);

    const bin = btoa(file.fileContents);
    const decodedB64 = `data:${fileType};base64,${atob(bin)}`;
    const arr = decodedB64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const parsedFile: Array<File> = [];

    parsedFile.push(new File([u8arr], file.fileName, { type: mime }));

    return parsedFile;
  };

  // Get the accountOwner for the account loaded
  const updateAccountOwner = async (): Promise<void> => {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then(async (res) => {
        if (res.data.deathCertificateFileName) {
          await getDeathCertificate(
            accountOwnerId,
            user.organizationId,
            user.token
          ).then((deathcertificate) => {
            if (isMounted) {
              setIsLoading(false);
              setAccountOwner(res.data);
              addTransactionData(
                {
                  beneficiaryClaim: {
                    dateOfBirth: res.data.dateOfBirth,
                    dateOfDeath: res.data.dateOfDeath,
                    dateOfDeathAccountBalance:
                      pageState.selectedAccount.dateOfDeathAccountBalance,
                    files: convertToFile(deathcertificate.data),
                    fileProperties: [
                      {
                        beneficiaryClaimDocumentId:
                          deathcertificate.data.beneficiaryClaimDocId,
                        documentType:
                          deathcertificate.data.beneficiaryClaimDocumentType,
                        description: deathcertificate.data.description || '',
                      },
                    ],
                  },
                },
                false
              );
            }
          });
        } else {
          setIsLoading(false);
          setAccountOwner(res.data);
          addTransactionData(
            {
              beneficiaryClaim: {
                dateOfBirth: res.data.dateOfBirth,
                dateOfDeath: res.data.dateOfDeath,
              },
            },
            false
          );
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  };

  // set BeneficiaryClaim to pending
  const setOwnerAccountInfo = async (beneficiaryClaimInformation) => {
    const mergedBeneficiary = {
      ...beneficiaryClaimInformation,
      accountId,
      accountOwnerId,
      beneficiaryClaimId,
      beneficiaryClaimStatus,
      financialOrganizationId: user.organizationId,
    };

    const mergedData = {
      ...pageState.beneficiaryClaim,
      beneficiaryClaim: {
        ...mergedBeneficiary,
        beneficiaryClaimId:
          pageState.beneficiaryClaim.beneficiaryClaimId || beneficiaryClaimId,
        beneficiaryClaimStatus:
          pageState.beneficiaryClaim.beneficiaryClaimStatus ||
          beneficiaryClaimStatus,
      },
    };

    const deathCertificate = true;

    await createOrSaveBeneficiaryClaim(
      mergedData,
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token
    )
      .then(async (res) => {
        await saveBeneficiaryClaimFiles(
          mergedData,
          res.data.beneficiaryClaimId,
          accountId,
          accountOwnerId,
          user.organizationId,
          user.token,
          deathCertificate
        )
          .then(() => {
            const transactionData: RowDefinition = {
              beneficiaryClaimId: res.data.beneficiaryClaimId,
              transactionType: TransactionType.beneficiaryClaims,
              accountId,
              accountOwnerId,
            };
            setUnsavedChanges(transactionData);
            addTransactionData({
              beneficiaryClaim: {
                ...res.data,
              },
            });
          })
          .catch((err) => {
            addGlobalMessage(err.message);
          });
      })
      .catch((err) => {
        addGlobalMessage(err.message);
      });
  };

  const setRepsentativeInformation = (beneficiaryRepInformation) => {
    const { selectedRep } = beneficiaryRepInformation;
    const mergedBeneficiary = {
      ...pageState.beneficiaryClaim,
      finOrgContactUserId: selectedRep,
      finOrgSubmittedByUserId: selectedRep,
      selectedRep,
    };

    addTransactionData({ beneficiaryClaim: mergedBeneficiary });
  };

  const saveContactInformation = (claimContactInformation) => {
    addTransactionData({
      beneficiaryClaim: {
        ...pageState.beneficiaryClaim,
        ...claimContactInformation,
      },
    });
  };

  const saveBeneficiaryDesigneeInformation = (
    beneficiaryDesigneeInformation
  ) => {
    const mergedData = beneficiaryDesigneeInformation.designateBeneficiaryFiles
      ? {
          ...pageState.beneficiaryClaim,
          files: [...beneficiaryDesigneeInformation.designateBeneficiaryFiles],
          fileProperties: [...beneficiaryDesigneeInformation.fileProperties],
          designateBeneficiary:
            beneficiaryDesigneeInformation.designateBeneficiary,
        }
      : {
          ...pageState.beneficiaryClaim,
          files: [],
          fileProperties: [],
          designateBeneficiary:
            beneficiaryDesigneeInformation.designateBeneficiary,
        };
    addTransactionData({ beneficiaryClaim: { ...mergedData } });
  };

  // this is called to change the beneficiaryClaim status for the account
  const updateBeneStatus = async (): Promise<void> => {
    await updateBeneficiaryClaimStatus(
      user.organizationId,
      pageState.beneficiaryClaim.beneficiaryClaimId,
      accountId,
      accountOwnerId,
      user.token,
      BeneficiaryStatus.next
    )
      .then(() => {
        completeTransaction();
        setRejected(false);
      })
      .catch(() => {
        if (isMounted) {
          rejectTransaction();
        }
      });
  };

  const saveAdditionalbeneficiaryClaim = async (additionalInfo) => {
    let additionalFiles = [...pageState.beneficiaryClaim.files];
    let additionalfileProperties = [
      ...pageState.beneficiaryClaim.fileProperties,
    ];

    if (
      additionalInfo.additionalFiles &&
      additionalInfo.additionalFiles.length > 0
    ) {
      additionalFiles = [
        ...additionalInfo.additionalFiles,
        ...pageState.beneficiaryClaim.files,
      ];
      additionalfileProperties = [
        ...additionalInfo.additionalfileProperties,
        ...pageState.beneficiaryClaim.fileProperties,
      ];
    }
    
    const mergedData = {
      ...pageState.beneficiaryClaim,
      files: additionalFiles,
      fileProperties: additionalfileProperties,
      comments: additionalInfo.comments,
    };

    addTransactionData({
      beneficiaryClaim: { ...mergedData },
    });
  };

  const submitbeneficiaryClaim = async () => {
    await createOrSaveBeneficiaryClaim(
      pageState,
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token
    )
      .then(async (res) => {
        if (pageState.beneficiaryClaim.files.length > 0) {
          await saveBeneficiaryClaimFiles(
            pageState.beneficiaryClaim,
            res.data.beneficiaryClaimId,
            accountId,
            accountOwnerId,
            user.organizationId,
            user.token
          )
            .then(() => {
              updateBeneStatus();
              addTransactionData({
                beneficiaryClaim: res.data,
              });
            })
            .catch((err) => {
              addGlobalMessage(err.message);
            });
        } else {
          updateBeneStatus();
          addTransactionData({
            beneficiaryClaim: res.data,
          });
        }
        setUnsavedChanges(null);
      })
      .catch((err) => {
        addGlobalMessage(err.message);
      });
  };

  // set the correct file info and beneficiaryClaim
  const setFileAndBeneInfo = (beneficiaryClaim: BeneficiariesClaimsInfo) => {
    const { beneficiaryClaimDocuments } = beneficiaryClaim;
    addTransactionData({ beneficiaryClaim }, false);

    if (beneficiaryClaimDocuments) {
      beneficiaryClaimDocuments.forEach((file) => {
        if (file.beneficiaryClaimDocumentType === ClaimDocumentType.other) {
          const fileType = setFileTypeName(file.fileName);
          const bin = btoa(file.fileContents);
          const decodedB64 = `data:${fileType};base64,${atob(bin)}`;

          const arr = decodedB64.split(',');
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);

          // eslint-disable-next-line no-plusplus
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          const parsedFile = new File([u8arr], file.fileName, { type: mime });

          addTransactionData(
            {
              beneficiaryClaim: {
                additionalFiles: [
                  pageState.beneficiaryClaim.additionalFiles !== undefined && {
                    ...pageState.beneficiaryClaim.additionalFiles,
                  },
                  parsedFile,
                ],
              },
            },
            false
          );
        } else if (
          file.beneficiaryClaimDocumentType === ClaimDocumentType.deathCert
        ) {
          const fileType = setFileTypeName(file.fileName);
          const bin = btoa(file.fileContents);
          const decodedB64 = `data:${fileType};base64,${atob(bin)}`;

          const arr = decodedB64.split(',');
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);

          // eslint-disable-next-line no-plusplus
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          const parsedFile = new File([u8arr], file.fileName, { type: mime });

          addTransactionData(
            {
              beneficiaryClaim: {
                files: [parsedFile],
              },
            },
            false
          );
        }
      });
    }
  };

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    await getAccount(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      user.token,
      user
    )
      .then(async (res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          await getBeneficiariesClaimsProfile(
            queryParams.get('accountId'),
            queryParams.get('accountOwnerId'),
            user.organizationId,
            user.token,
            user
          )
            .then((responseValue) => {
              setFileAndBeneInfo(responseValue.data);

              setIsLoading(false);
            })
            .catch((err) => {
              if (isMounted) {
                setIsLoading(false);
                addGlobalMessage(errorMessages(err) ||
                    'Could not fetch the preselected beneficiary claim'
                );
              }
            });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
            addGlobalMessage(errorMessages(err) || 'Could not fetch the preselected account'
          );
        }
      });
  }

  // Optionaly set account when passed query params
  async function preselectAccountInfo(): Promise<void> {
    if (queryParams.get('accountId')) {
      setIsLoading(true);
      await fetchAndSetAccount();
    }
  }

  async function getBenefitClaimsInfo(): Promise<void> {
    await getBeneficiariesClaimsProfile(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((responseValue) => {
        setFileAndBeneInfo(responseValue.data);
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(errorMessages(err) ||
              'Could not fetch the preselected beneficiary claim'
          );
        }
      });
  }

  useEffect(() => {
    setIsLoading(true);
    updateAccountOwner();
  }, [accountOwnerId]);

  const setRepInfo = async (): Promise<void> => {
    await getUsers(user.organizationId, user.token)
      .then((res) => {
        setResponse(res.data);
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage('Error fetching organization users');
        }
      });
  };

  useEffect(() => {
    if (user.organizationId && user.token) {
      preselectAccountInfo();
      setRepInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token]);

  useEffect(() => {
    const { beneficiaryClaim } = pageState.selectedAccount;
    if (beneficiaryClaim) {
      getBenefitClaimsInfo();
    }
  }, [pageState.selectedAccount]);

  const beneficiaryClaimsSteps: Array<TransactionStep> = [
    {
      label: 'Find Deceased Owner and Account',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <BeneficiaryClaimSearchForm
                  setResponse={setBeneficiarySearchResponse}
                  newClaim
                />
              </Box>
            </Grid>
          </Grid>
          {query && (
            <ClaimsSearchResults
              filterFunction={({
                account: {
                  accountStatus: statusMatcher = '' as AccountStatus,
                } = {},
              }) =>
                [AccountStatus.open, AccountStatus.closed].includes(
                  statusMatcher
                )
              }
              response={pageState.searchResponse}
              onResultClick={setSelectedAccount}
            />
          )}
        </Box>
      ),
    },
    {
      label: 'Owner and Account Information',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12}>
              <BeneficiariesClaimsForm
                initialValues={{
                  ...BENEFICIARIES_CLAIMS_INIT,
                  ...pageState.beneficiaryClaim,
                }}
                onSubmit={setOwnerAccountInfo}
                account={pageState.selectedAccount}
                accountOwner={accountOwner}
              />
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: 'Upload Designated Beneficiary Information',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <BeneficiariesDesignatedForm
                initialValues={{
                  ...BENEFICIARIES_DESIGNEE_INIT,
                  ...pageState.beneficiaryClaim,
                }}
                onSubmit={saveBeneficiaryDesigneeInformation}
              />
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: 'Financial Organization Representative Information',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <BeneficiariesClaimsRepForm
                initialValues={{
                  ...BENEFICIARIES_REP_INIT,
                  ...pageState.beneficiaryClaim,
                }}
                response={response}
                onSubmit={setRepsentativeInformation}
              />
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: 'Contact Information',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <ContactInformationForm
                initialValues={{
                  ...CONTACT_INFORMATION_INIT,
                  ...pageState.beneficiaryClaim,
                }}
                onSubmit={saveContactInformation}
                account={pageState.selectedAccount}
                accountOwner={accountOwner}
              />
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: 'Additional Information',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <BeneficiariesAdditionalInfoForm
                initialValues={{
                  ...BENEFICIARIES_ADDITIONAL_INFO_INIT,
                  ...pageState.beneficiaryClaim,
                }}
                onSubmit={saveAdditionalbeneficiaryClaim}
              />
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: 'Approval of Information',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={1.5}>
                <SiraVerifyForm
                  onSubmit={submitbeneficiaryClaim}
                  label="I understand that the claim will be processed based on the information provided and certify the information is true and accurate."
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
    },
  ];

  return (
    <Layout>
      <FormPaper>
        <Box width="100%">
          <Typography variant="h1" color="secondary" gutterBottom>
            Beneficiary Claims
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Find the account owner and select the correct account to establish a
            claim.
          </Typography>

          {accountId && (
            <SiraAccountOwnerInfo
              selectedAccount={pageState.selectedAccount}
              accountOwner={accountOwner}
            />
          )}
          {isLoading ? (
            <Box mt={4}>
              <LinearProgress color="secondary" />
            </Box>
          ) : (
            <Box mt={5}>
              <TransactionStepper
                steps={beneficiaryClaimsSteps}
                activeStep={pageState.activeStep}
                isLoading={isLoading}
                onStepClick={(index) => {
                  if (
                    !loadingExistingAccount ||
                    (loadingExistingAccount && index > 1)
                  ) {
                    skipStep(index);
                  }
                }}
              />
            </Box>
          )}
          {pageState.completed && (
            <SiraPageAlert
              pageStatus={rejected}
              transactionName="Beneficiary claim"
              accountId={pageState.selectedAccount.accountId}
              accountOwnerId={accountOwnerId}
              linklabel="View Claim"
            />
          )}
          {UnsavedChangesPrompt}
        </Box>
      </FormPaper>
    </Layout>
  );
}

export default SubmitBeneficiaryClaim;
