import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
  useTheme,
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
  Paper,
} from '@mui/material';
import Alert from '@mui/material/Alert';

import { useUser } from '../../../auth/useUser';
import {
  Distribution,
  DistributionAllocation,
} from '../../../api/DistributionApi.d';
import { getInvestmentRates } from '../../../api/InvestmentRateApi';
import { InvestmentRate } from '../../../api/InvestmentRateApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraCurrencyField from '../SiraCurrencyField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import AllocationPieDisplay from '../newAccount/AllocationPieDisplay';
import SiraTextField from '../SiraTextField';
import { roundNumber } from '../../../utils/App.utils';

export interface DistributionAllocationFormData {
  allocations: Array<DistributionAllocation>;
}

export interface DistributionAllocationFormProps {
  existingAllocations: Array<DistributionAllocation>;
  distributionInfo: Distribution;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

export const DISTRIBUTION_ALLOCATION_INIT: Array<DistributionAllocation> = [];

function DistributionAllocationForm({
  existingAllocations = [],
  distributionInfo,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
}: DistributionAllocationFormProps) {
  let isMounted = true;
  const theme = useTheme();
  const [allocations, setAllocations] = useState(existingAllocations);
  const [investmentRates, setInvestmentRates] = useState(
    [] as Array<InvestmentRate>
  );
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { totalAmount = 0 } = distributionInfo;

  const classes = {
    tableLabel: {
      marginBottom: '1.5rem',
    },
    tableCell: {
      borderBottom: 'none',
      borderRight: '1px solid grey',
    },
    headCell: {
      paddingTop: 0,
      borderBottom: 'none',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    lastTableCell: {
      borderBottom: 'none',
    },
    lastHeadCell: {
      paddingTop: 0,
      borderBottom: 'none',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  };

  const DISTRIBUTION_ALLOCATION_SCHEMA = yup.object().shape({
    allocations: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup.string(),
          investmentNumber: yup.string(),
        })
      )
      .required()
      .test(
        'total',
        `The sum of all allocation amounts must equal $${totalAmount}`,
        (allocs = []) => {
          const totalAlloc = allocs.reduce((total, allocation) => {
            return roundNumber(total) + (Number(allocation.amount) || 0);
          }, 0);

          return Number(totalAlloc) === Number(totalAmount);
        }
      ),
  });

  // Trims 0 distribution allocations and adds fields that aren't in the form data
  function distributionAllocationsFactory(
    formAllocations: Array<DistributionAllocation>
  ): Array<DistributionAllocation> {
    return investmentRates.reduce(
      (acc: Array<DistributionAllocation>, rate: InvestmentRate, index) => {
        const { amount = '', investmentNumber = '' } =
          formAllocations[index] || {};
        const { investmentRateId = '' } = rate || {};

        if (amount) {
          acc.push({
            amount,
            investmentRateId,
            investmentNumber,
          } as DistributionAllocation);
        }

        return acc;
      },
      []
    );
  }

  // Maps incoming allocations to their respective investment rate
  function mapInitialAllocations(
    rates: Array<InvestmentRate>
  ): Array<DistributionAllocation> {
    return rates.map((rate) => {
      const { amount = 0, investmentNumber = '' } =
        allocations.find(
          ({ investmentRateId }) => rate.investmentRateId === investmentRateId
        ) || {};

      return {
        amount,
        investmentRateId: rate.investmentRateId,
        investmentNumber,
      } as DistributionAllocation;
    });
  }

  async function getInitialInvestmentRates(): Promise<void> {
    if (user.token && user.organizationId) {
      setIsLoading(true);

      await getInvestmentRates(
        user.organizationId,
        user.token,
        {
          active: true,
        },
        user
      )
        .then((resp) => {
          const initialAllocations = mapInitialAllocations(resp.data);
          if (isMounted) {
            setInvestmentRates(resp.data);
            setAllocations(initialAllocations);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          const { response: { data = {} } = {} } = err;

          setIsLoading(false);
          addGlobalMessage(data.message);
        });
    }
  }

  useEffect(() => {
    getInitialInvestmentRates();

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  if (isLoading) {
    return (
      <Box width="1" mt={5} mb={3}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  const investmentRows =
    Boolean(investmentRates.length) &&
    allocations.map((investment: DistributionAllocation, index: number) => {
      return (
        <TableRow key={investmentRates[index].investmentRateId}>
          <TableCell colSpan={3}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                marginBottom: 2,
                height: '25px !important',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  {investmentRates[index].description}
                </Grid>
                <Grid item xs={4} >
                  <SiraTextField
                    name={`allocations[${index}.investmentNumber]`}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '35px',
                        padding: 1,

                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SiraCurrencyField
                    name={`allocations[${index}.amount]`}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '35px',
                        padding: 1,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <Formik
      initialValues={{ allocations }}
      onSubmit={async (values) => {
        await onSubmit(distributionAllocationsFactory(values.allocations));
      }}
      enableReinitialize
      onReset={() => onReset()}
      validationSchema={DISTRIBUTION_ALLOCATION_SCHEMA}
    >
      {({ isSubmitting }) => (
        <Form>
          {investmentRates.length > 0 && (
            <AllocationPieDisplay
              investmentRates={investmentRates}
              amount={totalAmount}
            />
          )}

          <Box maxWidth={580}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={classes.tableLabel}>
                  Enter the amounts being distributed from the investments
                  below. Select Skip if you do not wish to enter any
                  information.
                </Typography>
                <TableContainer sx={{ maxHeight: '480px', overflowY: 'auto' }}>
                  <Table stickyHeader >
                    <TableHead>
                      <FieldArray name="investments">
                        {() => (
                          <TableRow>
                            <TableCell sx={classes.headCell}>
                              Account Type
                            </TableCell>
                            <TableCell sx={{...classes.headCell, width:'145px'}}>
                              Investment Number
                            </TableCell>
                            <TableCell sx={{...classes.lastHeadCell, width:'180px'}}>
                              Amount
                            </TableCell>
                          </TableRow>
                        )}
                      </FieldArray>
                    </TableHead>

                    {allocations && (
                      <TableBody >
                        <FieldArray name="allocations">
                          {() => {
                            return investmentRows;
                          }}
                        </FieldArray>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <ErrorMessage name="allocations">
                  {(msg) => (
                    <Alert severity="error" variant="outlined">
                      {msg}
                    </Alert>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
          </Box>
          <StepButtonBar
            isSubmitting={isSubmitting}
            submitName={submitName}
            resetName={resetName}
          />
        </Form>
      )}
    </Formik>
  );
}

export default DistributionAllocationForm;
