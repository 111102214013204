import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  useTheme,
  Grid,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';

import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import {
  depositCheckPayeeLabels,
  depositMethodShortNames,
  globalPaginationOptions,
  postponementCodeLabels,
  postponementReasonLabels,
  repaymentCodeLabels,
} from '../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../utils/DataGrid.utils';
import { useUser } from '../auth/useUser';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import { AccountDetailAccordionType } from '../components/accountOwnerProfile/AccountOwnerAccountDetails';
import {
  exportDeposits,
  getActiveContributions,
  getActiveContributionsSummarized,
} from '../api/ContributionApi';
import { AccountContributionSearchResult } from '../api/ContributionApi.d';
import { AccountType } from '../api/AccountApi.d';
import TaxYearsFilterForm from '../components/form/TaxYearsFilterForm';
import AccountTypesSelectField from '../components/form/AccountTypesSelectField';
import ReportsDepositSearchForm from '../components/form/reports/ReportsDepositSearchForm';
import MultiButton, { MultiButtonAction } from '../components/MultiButton';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import { errorMessages } from '../utils/errorhandling.utils';

interface ActiveContributionRow extends AccountContributionSearchResult {
  id: number;
  year: string;
  accountOwnerId: string;
}

// Flatten and map out the contributions for display in the data grid
function ActiveDepositsRowFactory(
  data: Array<AccountContributionSearchResult>
): Array<ActiveContributionRow> {
  return data.map((result, id) => ({
    ...result.account,
    ...result.accountOwner,
    ...result.contribution,
    ...result.contributionDepositTypeSummary,
    year: result.taxYear || result.contribution.taxYear, // Used by the tax year filter
    id,
  }));
}

function ReportsDeposit() {
  let isMounted = true;
  const { user } = useUser();
  const {
    getAppConstant,
    addGlobalMessage,
    organization: { accountNumberValue },
  } = useGlobalContext();
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';

  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [accountTypeFilter, setAccountTypeFilter] = useState(
    [] as Array<AccountType>
  );
  const [filteredRows, setFilteredRows] = useState(
    [] as Array<ActiveContributionRow>
  );
  const [rows, setRows] = useState([] as Array<ActiveContributionRow>);
  const [startDateParam, setStartDateParam] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [endDateParam, setEndDateParam] = useState(
    format(endOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const theme = useTheme();
  const [selectedYears, setSelectedYears] = useState([] as Array<string>);

  // Map out relevant options for the org active deposits
  const taxYearFilterOptions: Array<string> = [
    ...new Set(rows.map(({ year }) => String(year))),
  ];


  const classes = {
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      justifyContent: 'flex-end' as any,
      padding: theme.spacing(2),
    },
  };

  // Export org deposit
  const exportOrgDeposits = async (fileType): Promise<void> => {
    await exportDeposits(
      user.organizationId,
      user.token,
      fileType,
      startDateParam,
      endDateParam,
      selectedYears,
      accountTypeFilter
    )
      .then(() => {
        setIsLoading(false);
        addGlobalMessage(
          'Your file will be available shortly under Notifications, located at the top of the page.',
          {
            severity: 'success',
          }
        );
      })
      .catch(() => {
        setIsLoading(false);
        addGlobalMessage('Error exporting organization deposits');
      });
  };

  // Define the download types
  const downloadActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => {
        setIsLoading(true);
        exportOrgDeposits('tsv');
      },
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => {
        setIsLoading(true);
        exportOrgDeposits('xlsx');
      },
      icon: <DownloadIcon />,
    },
  ];

  // Apply all filters to the supplied rows
  const applyFilters = (rowsToFilter) => {
    const matchingRows: Array<ActiveContributionRow> = accountTypeFilter.length
      ? rowsToFilter.filter(({ accountType }) =>
          accountTypeFilter.includes(accountType)
        )
      : rowsToFilter;

     const years: Array<string> = [
      ...new Set(matchingRows.map(({ year }) => String(year))),
    ];


    setFilteredRows(matchingRows);
    setSelectedYears(years)
  };

  // Fetch all active contributions for the current organization
  const getAndSetOrgActiveContributions = async ({
    startDate,
    endDate,
    totals,
  }): Promise<void> => {
    setIsLoading(true);
    setStartDateParam(startDate);
    setEndDateParam(endDate);

    const apiCall = totals
      ? getActiveContributionsSummarized
      : getActiveContributions;

    await apiCall(user.organizationId, { startDate, endDate }, user.token)
      .then(({ data = [] }) => {
        if (isMounted) {
          const preppedRows = ActiveDepositsRowFactory(data);

          if (isMounted) {
            setRows(preppedRows);
            applyFilters(preppedRows);
          }
        }
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error fetching organization deposits'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  };

  const gotoRecordAccount = (row: ActiveContributionRow): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.contributions}`
    );
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Owner',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              gotoRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 130,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'depositType',
      headerName: 'Deposit Type',
      width: 126,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.depositType, value);
      },
    },
    {
      hideable: true, // The following are needed in CSV but don't show them in the UI
      field: 'year',
      headerName: 'Tax Year',
    },
    {
      field: 'effectiveDate',
      headerName: 'Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 126,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'dateOfWithdrawal',
      headerName: 'Date of Withdrawal',
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'dateOnCheck',
      headerName: 'Date on Check',
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'depositorEmailAddress',
      headerName: 'Depositor Email Address',
    },
    {
      field: 'depositorFirstName',
      headerName: 'Depositor First Name',
    },
    {
      field: 'depositorLastName',
      headerName: 'Depositor Last Name',
    },
    {
      field: 'depositorPhoneNumber',
      headerName: 'Depositor Phone #',
    },
    {
      field: 'depositMethod',
      headerName: 'Deposit Method',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return depositMethodShortNames[String(value)];
      },
    },
    {
      field: 'fromFinancialOrganization',
      headerName: 'Source Organization',
    },

    {
      field: 'fromAccountNumber',
      headerName: `Source ${headerAccountName}`,
    },
    {
      field: 'fromAccountType',
      headerName: 'Source Account Type',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'payableTo',
      headerName: 'Check Payable To',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return depositCheckPayeeLabels[String(value)];
      },
    },
    {
      field: 'postponedCode',
      headerName: 'Postponed Code',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return postponementCodeLabels[String(value)];
      },
    },
    {
      field: 'postponedReason',
      headerName: 'Postponed Reason',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return postponementReasonLabels[String(value)];
      },
    },
    {
      field: 'repaymentCode',
      headerName: 'Repayment Code',
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        return repaymentCodeLabels[String(value)];
      },
    },
    {
      field: 'signedDate',
      headerName: 'Date Signed',
      valueFormatter: dateValueFormatter,
    },
  ] as GridColDef[];

  useEffect(() => {
    applyFilters(rows);

    return () => {
      isMounted = false;
    };
  }, [accountTypeFilter]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Reports and Statistics
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Deposits
          </Typography>

          <ReportsDepositSearchForm
            initialValues={{
              startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
              endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
              totals: false,
            }}
            onSubmit={getAndSetOrgActiveContributions}
          />

          <AccountTypesSelectField
            onChange={setAccountTypeFilter}
            accountTypes={accountTypeFilter}
          />

          <TaxYearsFilterForm
            items={rows}
            options={taxYearFilterOptions}
            onChange={applyFilters}
          />

          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <Box p={2}>
              <Box mt={4}>
                <DataGrid
                  components={{
                    NoRowsOverlay: SiraNoRowsOverlay,
                    Toolbar: () => (
                      <Grid
                        container
                        wrap="nowrap"
                        spacing={2}
                        sx={classes.buttonBar}
                      >
                        <Grid item>
                          <MultiButton
                            actions={downloadActions}
                            sx={{ whiteSpace: 'nowrap' }}
                            disabled={isLoading}
                            variant="contained"
                            color="primary"
                            startIcon={<DownloadIcon />}
                            endIcon={<ExpandMoreIcon />}
                          >
                            Export Deposit
                          </MultiButton>
                        </Grid>
                      </Grid>
                    ),
                  }}
                  disableColumnMenu
                  initialState={{
                    pagination: { paginationModel: { pageSize: globalPageSize } },
                    columns: {
                      columnVisibilityModel: {
                        dateOfWithdrawal: false,
                        dateOnCheck: false,
                        depositorEmailAddress: false,
                        depositorFirstName: false,
                        depositorLastName: false,
                        depositorPhoneNumber: false,
                        depositMethod: false,
                        fromFinancialOrganization: false,
                        fromAccountNumber: false,
                        fromAccountType: false,
                        payableTo: false,
                        postponedCode: false,
                        postponedReason: false,
                        repaymentCode: false,
                        signedDate: false,
                      },
                    },
                  }}
                  pageSizeOptions={globalPaginationOptions}
                  onPaginationModelChange={setGlobalPageSize}
                  autoHeight
                  columns={columns}
                  rows={filteredRows}
                />
              </Box>
            </Box>
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default ReportsDeposit;
