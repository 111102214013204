import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as yup from 'yup';
import { addYears, endOfYear, startOfYear } from 'date-fns';

import StepButtonBar from '../../steps/StepButtonBar';
import {
  frequencyOptions,
  defaultRmdDistributionAccountTypes,
} from '../../../app.constants';
import SiraSelectField from '../SiraSelectField';
import { DistributionMethod } from '../../../api/DistributionApi.d';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import { FrequencyValues } from '../distribution/Frequency';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { DefaultRmdFormData, DefaultRmdType } from './DefaultSettingsForm.d';
import { getAllowedDistributionMethod } from '../distribution/distributionMethod.util';
import SiraSlider from '../SiraSlider';
import PluralizedString from '../../PluralizedString';
import SiraSwitchField from '../SiraSwitchField';
import { AccountType } from '../../../api/AccountApi.d';
import { DatePicker } from '@mui/x-date-pickers';

export const DEFAULT_RMD_SETTINGS_INIT: DefaultRmdFormData = {
  rmdNotificationsEnabled: false as boolean,
  age72FirstRmdFrequency: '' as FrequencyValues,
  age72FirstRmdStartDate: '',
  age72SubsequentRmdFrequency: '' as FrequencyValues,
  age72SubsequentRmdStartDate: '',
  age73CurrentRmdFrequency: '' as FrequencyValues,
  age73CurrentRmdStartDate: '',
  age73SubsequentRmdFrequency: '' as FrequencyValues,
  age73SubsequentRmdStartDate: '',
  age72FirstRmdIsFollowingYear: false,
  defaultRmdType: '' as DefaultRmdType,
  distributionMethod: '' as DistributionMethod,
  ownerResponsible: false,
  electionDeadline: 30,
  toAccountType: '' as AccountType,
};

export const DEFAULT_RMD_SETTINGS_SCHEMA = yup.object({
  rmdNotificationsEnabled: yup.boolean(),
  defaultRmdType: yup
    .string()
    .when('rmdNotificationsEnabled', (rmdNotificationsEnabled, schema) =>
      rmdNotificationsEnabled ? schema.required() : schema
    ),
  distributionMethod: yup
    .string()
    .label('Distribution Method')
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required('Choose a distribution method')
        : schema;
    }),
  ownerResponsible: yup.boolean(),
  electionDeadline: yup
    .number()
    .when('rmdNotificationsEnabled', (rmdNotificationsEnabled, schema) =>
      rmdNotificationsEnabled ? schema.required() : schema
    )
    .label('Election Deadline'),
  toAccountType: yup
    .string()
    .nullable()
    .label('Account Type')
    .when(['rmdNotificationsEnabled', 'distributionMethod'], (...args) => {
      const [rmdNotificationsEnabled, distributionMethod, schema] = args;

      return rmdNotificationsEnabled &&
        [DistributionMethod.direct, DistributionMethod.transfer].includes(
          distributionMethod
        )
        ? schema.required('Choose a distribution method')
        : schema;
    }),
  age72FirstRmdFrequency: yup
    .string()
    .label('Frequency')
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required()
        : schema;
    }),
  age72FirstRmdStartDate: yup
    .date()
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required('Enter a valid date')
        : schema;
    }),
  age72SubsequentRmdFrequency: yup
    .string()
    .label('Frequency')
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required()
        : schema;
    }),
  age72SubsequentRmdStartDate: yup
    .date()
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required('Enter a valid date')
        : schema;
    }),
  age73CurrentRmdFrequency: yup
    .string()
    .label('Frequency')
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required()
        : schema;
    }),
  age73CurrentRmdStartDate: yup
    .date()
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required('Enter a valid date')
        : schema;
    }),
  age73SubsequentRmdFrequency: yup
    .string()
    .label('Frequency')
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required()
        : schema;
    }),
  age73SubsequentRmdStartDate: yup
    .date()
    .when(['rmdNotificationsEnabled', 'defaultRmdType'], (...args) => {
      const [rmdNotificationsEnabled, defaultRmdType, schema] = args;

      return rmdNotificationsEnabled && defaultRmdType === DefaultRmdType.rmd
        ? schema.required('Enter a valid date')
        : schema;
    }),
});

export interface DefaultRmdSettingsFormProps {
  initialValues: DefaultRmdFormData;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

interface StartDateSelectorProps {
  name: string;
  yearSelectField?: string;
}

interface StartDateRange {
  min: Date;
  max: Date;
}

function DefaultRmdTypeField() {
  const { values, setValues } = useFormikContext();
  const { defaultRmdType } = values as DefaultRmdFormData;
  const defaultRmdOptions: Array<RadioGroupOption> = [
    {
      label:
        "RMD amount over the owner's life expectancy, minus 10% federal withholding and any applicable state withholding",
      value: DefaultRmdType.rmd,
    },
    {
      label: 'Owner responsible',
      value: DefaultRmdType.owner,
      afterContent: (
        <Typography variant="body2">
          The account owner must still receive the RMD for this IRA but will be
          responsible for withdrawing the RMD every year instead of having your
          financial organization automatically distribute the amount.
        </Typography>
      ),
    },
  ];

  // Imperatively (re)set values when changing the RMD type
  function handleRmdTypeChange(): void {
    if (defaultRmdType === DefaultRmdType.owner) {
      setValues({
        ...(values as DefaultRmdFormData),
        ownerResponsible: true,
        distributionMethod: '',
        frequency: '',
        startDate: '',
      });
    } else {
      setValues({
        ...(values as DefaultRmdFormData),
        ownerResponsible: false,
      });
    }
  }

  useEffect(() => {
    handleRmdTypeChange();
  }, [defaultRmdType]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Amount and Term
        </Typography>
        <Typography variant="body1">
          Select how your account owners will be defaulted if they do not
          respond to the RMD notifications.{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          label=""
          name="defaultRmdType"
          options={defaultRmdOptions}
          component={SiraRadioField}
        />
      </Grid>
    </>
  );
}

// Map out the initial values that need a little transformation coming from the server
const initialValuesFactory = (
  values: DefaultRmdFormData
): DefaultRmdFormData => {
  const {
    age72FirstRmdStartDate = '',
    age72FirstRmdIsFollowingYear = false,
    age72SubsequentRmdStartDate = '',
    age73CurrentRmdStartDate = '',
    age73SubsequentRmdStartDate = '',
    ownerResponsible,
  } = values;

  return {
    ...values,
    defaultRmdType: ownerResponsible
      ? DefaultRmdType.owner
      : DefaultRmdType.rmd,
    age72FirstRmdStartDate: age72FirstRmdStartDate
      ? `${age72FirstRmdStartDate}-${addYears(
          new Date(),
          age72FirstRmdIsFollowingYear ? 1 : 0
        ).getFullYear()}`
      : '',
    age72SubsequentRmdStartDate: age72SubsequentRmdStartDate
      ? `${age72SubsequentRmdStartDate}-${addYears(
          new Date(),
          1
        ).getFullYear()}`
      : '',
    age73CurrentRmdStartDate: age73CurrentRmdStartDate
      ? `${age73CurrentRmdStartDate}-${new Date().getFullYear()}`
      : '',
    age73SubsequentRmdStartDate: age73SubsequentRmdStartDate
      ? `${age73SubsequentRmdStartDate}-${addYears(
          new Date(),
          1
        ).getFullYear()}`
      : '',
  };
};

// Get the date range for the date picker to limit the options (rather than with validation)
function getStartDateRange(
  field: string,
  isFollowingYear: boolean = false
): StartDateRange {
  const currentYear = new Date().getFullYear();

  switch (field) {
    // This one is current year or 1/1 to 4/1 of next year
    case 'age72FirstRmdStartDate':
      return {
        min: isFollowingYear
          ? addYears(startOfYear(new Date()), 1)
          : startOfYear(new Date()),
        max: isFollowingYear
          ? addYears(new Date(`${currentYear}-04-01`), 1)
          : endOfYear(new Date()),
      };

    // This one is to end of next year
    case 'age72SubsequentRmdStartDate':
      return {
        min: addYears(startOfYear(new Date()), 1),
        max: addYears(endOfYear(new Date()), 1),
      };

    // This one is to the end of current year
    case 'age73CurrentRmdStartDate':
      return {
        min: startOfYear(new Date()),
        max: endOfYear(new Date()),
      };

    // This one is to end of next year
    case 'age73SubsequentRmdStartDate':
      return {
        min: addYears(startOfYear(new Date()), 1),
        max: addYears(endOfYear(new Date()), 1),
      };

    default:
      return { min: new Date(), max: new Date() };
  }
}


function StartDateYearSelector(props) {
  const { name = '' } = props;
  const { values } = useFormikContext();

  return (
    <Grid component="label" container alignItems="center" wrap="nowrap">
      <Grid item>
        <SiraSwitchField checked={values[name]} name={name} />
      </Grid>
      <Grid item>
        <Typography variant="body2" textAlign="right" gutterBottom>
          {values[name] ? 'Following' : 'Current'} year on:
        </Typography>
      </Grid>
    </Grid>
  );
}

function DefaultRmdSettingsForm({
  initialValues,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
}: DefaultRmdSettingsFormProps) {
  const { organization } = useGlobalContext();
  const distributionMethodOptions: Array<RadioGroupOption> =
    getAllowedDistributionMethod(organization);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...initialValuesFactory(initialValues),
      }}
      enableReinitialize
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => onReset()}
      validationSchema={DEFAULT_RMD_SETTINGS_SCHEMA}
    >
      {({ isSubmitting, values, setFieldValue }) => {
        return (
          <Form>
            <Grid container spacing={5}>
              {/* Enable/Diable Default RMD */}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <SiraSwitchField
                      name="rmdNotificationsEnabled"
                      checked={values.rmdNotificationsEnabled}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      Superior mails RMD notifications to account owners
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {values.rmdNotificationsEnabled && (
                <>
                  {/* RMD Type */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <DefaultRmdTypeField />
                    </Grid>
                  </Grid>

                  {/* Frequency */}
                  {values.defaultRmdType === DefaultRmdType.rmd && (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={2}
                          maxWidth={640}
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography variant="h6">Age 73 Default</Typography>
                          </Grid>

                          {/* Age 73 First */}
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              An account owner&apos;s first RMD must be received
                              by April 1 of the year following the year the
                              individual attains age 73.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <SiraSelectField
                              items={frequencyOptions}
                              name="age72FirstRmdFrequency"
                              label="First RMD"
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <StartDateYearSelector name="age72FirstRmdIsFollowingYear" />
                          </Grid>
                          <Grid item xs={9} sm={4}>
                            <DatePicker
                              minDate={
                                getStartDateRange(
                                  'age72FirstRmdStartDate',
                                  values['age72FirstRmdIsFollowingYear']
                                ).min
                              }
                              maxDate={
                                getStartDateRange(
                                  'age72FirstRmdStartDate',
                                  values['age72FirstRmdIsFollowingYear']
                                ).max
                              }
                              format="MMMM dd"
                              views={['year', 'day']}
                              value={new Date(values['age72FirstRmdStartDate'])}
                              label="Start Date"
                              onChange={(newValue) => {
                                setFieldValue(
                                  'age72FirstRmdStartDate',
                                  newValue
                                );
                              }}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Grid>

                          {/* Age 73 Subsequent */}
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              Each subsequent RMD must be received by December
                              31 of the year the RMD is required.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <SiraSelectField
                              items={frequencyOptions}
                              name="age72SubsequentRmdFrequency"
                              label="Subsequent RMDs"
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <Typography
                              variant="body2"
                              textAlign="right"
                              gutterBottom
                            >
                              Following Year on:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={4}>
                            <DatePicker
                              minDate={
                                getStartDateRange(
                                  'age72SubsequentRmdStartDate',
                                  values['']
                                ).min
                              }
                              maxDate={
                                getStartDateRange(
                                  'age72SubsequentRmdStartDate',
                                  values['']
                                ).max
                              }
                              format="MMMM dd"
                              views={['year', 'day']}
                              value={new Date(values['age72SubsequentRmdStartDate'])}
                              label="Start Date"
                              onChange={(newValue) => {
                                setFieldValue(
                                  'age72SubsequentRmdStartDate',
                                  newValue
                                );
                              }}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Age 74 and Older Default
                            </Typography>
                          </Grid>

                          {/* Age 73+ Current */}
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              The current year RMD must be received by December
                              31 of the current year.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <SiraSelectField
                              items={frequencyOptions}
                              name="age73CurrentRmdFrequency"
                              label="Current Year RMD"
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <Typography
                              variant="body2"
                              textAlign="right"
                              gutterBottom
                            >
                              Current Year on:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={4}>
                            <DatePicker
                              minDate={
                                getStartDateRange(
                                  'age73CurrentRmdStartDate',
                                  values['']
                                ).min
                              }
                              maxDate={
                                getStartDateRange(
                                  'age73CurrentRmdStartDate',
                                  values['']
                                ).max
                              }
                              format="MMMM dd"
                              views={['year', 'day']}
                              value={new Date(values['age73CurrentRmdStartDate'])}
                              label="Start Date"
                              onChange={(newValue) => {
                                setFieldValue(
                                  'age73CurrentRmdStartDate',
                                  newValue
                                );
                              }}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Grid>

                          {/* Age 73+ Subsequent */}
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              Each subsequent RMD must be received by December
                              31 of the year the RMD is required.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <SiraSelectField
                              items={frequencyOptions}
                              name="age73SubsequentRmdFrequency"
                              label="Subsequent RMDs"
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <Typography
                              variant="body2"
                              textAlign="right"
                              gutterBottom
                            >
                              Following Year on:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={4}>
                            <DatePicker
                              minDate={
                                getStartDateRange(
                                  'age73SubsequentRmdStartDate',
                                  values['']
                                ).min
                              }
                              maxDate={
                                getStartDateRange(
                                  'age73SubsequentRmdStartDate',
                                  values['']
                                ).max
                              }
                              format="MMMM dd"
                              views={['year', 'day']}
                              value={new Date(values['age73SubsequentRmdStartDate'])}
                              label="Start Date"
                              onChange={(newValue) => {
                                setFieldValue(
                                  'age73SubsequentRmdStartDate',
                                  newValue
                                );
                              }}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Distribution Method
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="distributionMethod"
                              options={distributionMethodOptions}
                              component={SiraRadioField}
                            />
                          </Grid>
                          {values.distributionMethod ===
                            DistributionMethod.direct && (
                            <Grid item sx={{ ml: 4 }} xs={12}>
                              <Field
                                label=""
                                name="toAccountType"
                                options={defaultRmdDistributionAccountTypes}
                                component={SiraRadioField}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/* Election Deadline */}
                  <Grid item xs={12}>
                    <SiraSlider
                      min={0}
                      max={180}
                      width="1"
                      valueLabelDisplay="off"
                      label="Election Deadline"
                      name="electionDeadline"
                      aria-labelledby="input-slider"
                      afterContent={
                        <>
                          <Typography variant="h5" gutterBottom>
                            {values.electionDeadline}{' '}
                            <PluralizedString
                              noun="day"
                              quantity={values.electionDeadline}
                            />
                          </Typography>
                          <Typography variant="body2">
                            Use the slider to select the deadline by which your
                            account owners must return their RMD Elections to
                            avoid being defaulted to the selections made on this
                            page (e.g., 30 days after the RMD notification is
                            mailed, 60 days after the RMD notification is
                            mailed, etc.). If an account owner’s RMD Election is
                            not saved within the Superior platform by this
                            deadline, the individual will be defaulted the
                            following day.
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <StepButtonBar
                  isSubmitting={isSubmitting}
                  submitName={submitName}
                  resetName={resetName}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DefaultRmdSettingsForm;
