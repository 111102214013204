import { StandardTextFieldProps, TextField } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { FieldAttributes, useField } from 'formik';
import InputMask from 'react-input-mask';

export enum PhoneNumberFormat {
  unitedStates = 'en_US',
}

export function phoneValidation(format: PhoneNumberFormat) {
  const formatMap = {
    [PhoneNumberFormat.unitedStates]:
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  };

  return yup.string().matches(new RegExp(formatMap[format]), {
    message: 'Please enter a valid Phone Number',
    excludeEmptyString: true,
  });
}

const PhoneNumberField: React.FC<FieldAttributes<StandardTextFieldProps>> = (
  props
) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const { disabled = false } = props;

  return (
    <InputMask
      alwaysShowMask={false}
      disabled={disabled}
      mask="(999) 999-9999"
      {...field}
    >
      {() => (
        <TextField
          {...field}
          {...props}
          helperText={errorText}
          error={Boolean(errorText)}
          variant="outlined"
          fullWidth
        />
      )}
    </InputMask>
  );
};

export default PhoneNumberField;
