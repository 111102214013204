import { UserRole } from './UserApi.d';

// Removes any empty entries within an object (includes empty strings)
export function removeEmptyStrings(data: any) {
  const dataNorm = Object.entries(data).reduce((newObj, [key, val]) => {
    if (val !== '') {
      Object.assign(newObj, { [key]: val });
    }
    return newObj;
  }, {});
  return dataNorm;
}

// Prepares any arbitrary JSON for sending as part of a multipart/form payload
export function prepareBinaryJson(obj: Object) {
  const string = JSON.stringify(obj);
  return new Blob([string], {
    type: 'application/json',
  });
}

export const setBaseUrl = (user: any) => {
  const customerPortalUrl = '/api/customerPortal';
  const branchUrl = '/api';

  const { roles } = user;
  if (roles) {
    if (roles.includes(UserRole.accountOwner)) {
      return customerPortalUrl;
    }
  }

  return branchUrl;
};
