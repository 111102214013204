import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Account } from '../../../api/AccountApi.d';
import { useUser } from '../../../auth/useUser';
import { getBeneficiaries } from '../../../api/BeneficiariesApi';
import {
  Beneficiaries,
  Beneficiary,
  BeneficiaryStatus,
} from '../../../api/BeneficiariesApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SelectBeneficiaryList from '../../beneficiaries/SelectBeneficiaryList';
import BeneficiaryForm, { BENEFICIARY_INIT } from '../BeneficiaryForm';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import DeathDateForm, { DEATH_DATE_FORM_INIT } from './DeathDateForm';
import { ExternalAccount } from '../../../api/ExternalAccountApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';

export interface InheritingAccountOwner extends AccountOwner, Beneficiary {}

export interface InheritedAccountOwnerProps {
  initialValues: Beneficiary;
  deceasedAccount: Account;
  deceasedAccountOwner: ExternalAccount;
  onSubmit?: Function;
  onUpdateOwner?: Function;
  onUpdateAccount?: Function;
  submitName?: string;
  resetName?: string;
}

function InheritedAccountOwner({
  initialValues,
  deceasedAccountOwner,
  deceasedAccount,
  onUpdateOwner = () => {},
  onUpdateAccount = () => {},
  onSubmit,
  submitName,
  resetName,
}: InheritedAccountOwnerProps) {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { dateOfDeath = '', externalAccountOwnerAccountId = '' } =
    deceasedAccountOwner;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [addNew, setAddNew] = useState(false as boolean);
  const [beneficiaries, setBeneficiaries] = useState({} as Beneficiaries);
  const [beneficiary, setBeneficiary] = useState(initialValues as Beneficiary);
  const [needsDeathDate, setNeedsDeathDate] = useState(true as boolean);
  const { primaryBeneficiaries = [], secondaryBeneficiaries = [] } =
    beneficiaries || {};
  const beneficiariesAvailable =
    beneficiaries &&
    primaryBeneficiaries.length + secondaryBeneficiaries.length > 0;
  const showBeneficiariesList =
    beneficiariesAvailable &&
    (!beneficiary.fullName) &&
    !addNew;
  const isExternalAccount = Boolean(externalAccountOwnerAccountId);
  const { accountId, accountOwnerId, dateOfDeathAccountBalance } =
    deceasedAccount;

  // Load all beneficiaries from the selected deceased's account
  async function fetchAndSetBeneficiaries(): Promise<void> {
    setIsLoading(true);

    await getBeneficiaries(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      {},
      user
    )
      .then(({ data: allBeneficiaries = [] }) => {
        const currentLiveBeneficiaryVersion = allBeneficiaries.find(
          (item) => item.beneficiaryStatus === BeneficiaryStatus.live
        );

        if (isMounted) {
          setBeneficiaries(currentLiveBeneficiaryVersion);
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Error fetching Beneficiaries');
        }
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  const onDuplicateUser = useCallback(
    (relationship, accountNumber) => (userInfo) => {
      setBeneficiary({
        ...beneficiary,
        relationship,
        accountNumber,
        ...userInfo,
      });
    },
    [beneficiary]
  );

  
  useEffect(() => {
    if (deceasedAccount.accountId && user.organizationId && user.token) {
      fetchAndSetBeneficiaries();
    }

    return () => {
      isMounted = false;
    };
  }, [deceasedAccount.accountId]);

  return (
    <>
      {isLoading ? (
        <Box width="1">
          <LinearProgress color="secondary" />
        </Box>
      ) : (
        <>
          {needsDeathDate && !isExternalAccount ? (
            <DeathDateForm
              initialValues={{
                ...DEATH_DATE_FORM_INIT,
                dateOfDeath,
                dateOfDeathAccountBalance,
              }}
              onUpdateOwner={(values) => {
                setNeedsDeathDate(false);
                onUpdateOwner(values);
              }}
              onUpdateAccount={onUpdateAccount}
              deceasedOwner={deceasedAccountOwner}
              deceasedAccount={deceasedAccount}
            />
          ) : (
            <>
              {showBeneficiariesList ? (
                <>
                  <Box width="1">
                    <SelectBeneficiaryList
                      beneficiaries={beneficiaries}
                      onSelect={(value) => {
                        setBeneficiary(value);
                      }}
                    />
                  </Box>
                  <Box pt={2} mr={2} display="inline-block">
                    <Button
                      data-qa="Enter new beneficiary info"
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setBeneficiary({});
                        setAddNew(true);
                      }}
                    >
                      Enter New Beneficiary
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <BeneficiaryForm
                    isInheritedOwner
                    onDuplicateUser={onDuplicateUser}
                    initialValues={{
                      ...BENEFICIARY_INIT,
                      ...beneficiary,
                    }}
                    submitName={submitName}
                    resetName={resetName}
                    onSubmit={
                      onSubmit as (
                        values: Beneficiary,
                        formikHelpers?: any
                      ) => void
                    }
                    onCancel={() => {
                      setBeneficiary({});
                      setAddNew(false);
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default InheritedAccountOwner;
