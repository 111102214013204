import React, { useEffect, useState } from 'react';
import { useTheme, Grid, Avatar, Typography, Box } from '@mui/material';

import { isValid, parseISO } from 'date-fns';
import { FrequencyValues } from './Frequency.d';

export interface ScheduledMonthProps {
  frequency: FrequencyValues;
  effectiveDate: string;
}
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const intervalMap = {
  ANNUAL: 12,
  SEMI_ANNUAL: 6,
  QUARTERLY: 3,
  MONTHLY: 1,
};

function getSelectedMonths(frequency, effectiveDate) {
  const selectedMonths = [];
  const validDate = effectiveDate && isValid(parseISO(effectiveDate));
  if (frequency && validDate) {
    const firstMonth = parseISO(effectiveDate).getMonth();
    const interval = intervalMap[frequency];
    let nextMonth = firstMonth;
    do {
      selectedMonths.push(nextMonth);
      nextMonth = (nextMonth + interval) % 12;
    } while (nextMonth !== firstMonth);
  }
  return selectedMonths;
}

const ScheduledMonths = ({ frequency, effectiveDate }) => {
  const [monthGrid, setMonthGrid] = useState(<div>test</div>);
  const theme = useTheme();
  const classes = {
    selectedMonth: {
      backgroundColor: theme.palette.text.secondary,
      border: '0.1px solid lightgray',
    },
    unselectedMonth: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.grey[200],
      border: '0.1px solid lightgray',
    },
  };

  useEffect(() => {
    const selectedMonths = getSelectedMonths(frequency, effectiveDate);
    const grids = months.map((item, index) => {
      return (
        <Grid item xs={3} key={months[index]}>
          <Box p={1}>
            <Avatar
              sx={
                selectedMonths.includes(index)
                  ? classes.selectedMonth
                  : classes.unselectedMonth
              }
            >
              <Typography variant="body2">{item}</Typography>
            </Avatar>
          </Box>
        </Grid>
      );
    });
    setMonthGrid(<>{grids}</>);
  }, [frequency, effectiveDate]);

  return (
    <Grid container>
      <>{monthGrid}</>
    </Grid>
  );
};

export default ScheduledMonths;
