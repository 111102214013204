import React, { ReactElement } from 'react';
import { Paper, useTheme } from '@mui/material';

interface LayoutProps {
  children: ReactElement | ReactElement[];
}

function FormPaper(props: LayoutProps) {
  const { children } = props;
  const theme = useTheme();
  const classes = {
    root: {
      padding: `${theme.spacing(6)} 3vw`,
      margin: `${theme.spacing(1)} 3%`,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        margin: theme.spacing(1),
      },
    },
  };

  return (
    <Paper elevation={3} sx={classes.root}>
      {children}
    </Paper>
  );
}

export default FormPaper;
