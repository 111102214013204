export const errorMessages = (err: any) => {
  const { response: { data: errorData = {} } = {} } = err;
  // string object together to create an error message
  const errorMessage = Object.values(errorData)
    .map((value) => value)
    .join(', ');
  if (errorData.message) {
    return errorData.message;
  }
  return errorMessage;
};
