import React, { useState, useMemo, useEffect } from 'react';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import {
  Box,
  Grid,
  Button,
  Typography,
  Popover,
  IconButton,
  LinearProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NumberFormat from 'react-number-format';
import { getYear, format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  postponementCodeLabels,
  postponementReasonLabels,
  repaymentCodeLabels,
} from '../../app.constants';
import {
  AccountContribution,
  ContributionStatus,
} from '../../api/ContributionApi.d';
import TaxYearsFilterForm from '../form/TaxYearsFilterForm';
import TransactionSignatureIcon from '../../icons/TransactionSignature';
import TransactionPendingIcon from '../../icons/TransactionPending';
import { useUser } from '../../auth/useUser';
import { UserRole } from '../../api/UserApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import {
  createOrSaveContributionInformation,
  deleteAccountContribution,
  getAccountContributions,
  getContributionDocument,
} from '../../api/ContributionApi';
import { linkToTransaction } from '../../utils/transaction.utils';
import { TransactionType, Account } from '../../api/AccountApi.d';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { getTransferRequestDocument } from '../../api/TransferApi';
import ContributionInfoForm, {
  CONTRIBUTION_INFO_INIT,
} from '../form/newAccount/ContributionInfoForm';
import { SiraDeleteModal } from '../SiraDeleteModal';
import { dateValueFormatter } from '../../utils/DataGrid.utils';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import { errorMessages } from '../../utils/errorhandling.utils';

interface AccountOwnerAccountContributionsProps {
  account: Account;
}

function AccountOwnerAccountContributions(
  props: AccountOwnerAccountContributionsProps
) {
  let isMounted = true;
  const { account = {} } = props;
  const { accountId = '', accountOwnerId = '' } = account;
  const navigate = useNavigate();
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [allContributions, setAllContributions] = useState<
    Array<AccountContribution>
  >([]);
  const [selectedContribution, setSelectedContribution] =
    useState<AccountContribution>({});
  const [filteredContributions, setFilteredContributions] = useState<
    Array<AccountContribution>
  >([]);
  const [modalData, setModalData] = useState({ body: '', title: '' });
  const [modalOpen, setmodalOpen] = useState<boolean>(false);

  // Only show active contributions in here
  const contributions = useMemo(
    () =>
      allContributions.filter(({ contributionStatus }) =>
        [ContributionStatus.active].includes(contributionStatus)
      ),
    [allContributions]
  );

  const taxYearFilterOptions = useMemo(
    () => [
      ...new Set(
        contributions.map(({ effectiveDate }) =>
          getYear(parseISO(effectiveDate)).toString()
        )
      ),
    ],
    [contributions]
  );

  const classes = {
    contributionLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  async function getActiveDeposits() {
    setIsLoading(true);

    await getAccountContributions(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
      [ContributionStatus.active]
    )
      .then((res) => {
        if (isMounted) {
          setAllContributions(
            res.data.map((t) => ({
              ...t,
              transactionType: TransactionType.contribution,
            }))
          );
        }
      })
      .catch((err) => {
        if (isMounted) {
          setAllContributions([]);
          addGlobalMessage(errorMessages(err) || `Error fetching account contributions`
          );
        }
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (user.organizationId && user.token && accountId) {
      getActiveDeposits();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token, accountId]);

  // Override the selected contribution record (superuser permission)
  const updateSelectedContribution = async (
    data: AccountContribution
  ): Promise<void> => {
    await createOrSaveContributionInformation(
      { ...selectedContribution, ...data },
      user.organizationId,
      accountOwnerId,
      accountId,
      data.contributionId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          setSelectedContribution({} as AccountContribution);
        }

        getActiveDeposits();
        addGlobalMessage('Contribution successfully updated', {
          severity: 'success',
        });
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Could not update this contribution'
        );
      });

    if (isMounted) {
      setIsEditing(false);
    }
  };

  const buildData = () => {
    setModalData({
      title: TransactionType.contribution,
      body: `Do you want to delete this deposit for ${getAppConstant(
        ConstantsMappingKey.depositType,
        selectedContribution.depositType
      )} ?`,
    });
    setmodalOpen(true);
  };

  // Delete the selected contribution record (superuser permission)
  const deleteSelectedContribution = async (): Promise<void> => {
    setIsDeleting(true);
    await deleteAccountContribution(
      accountId,
      accountOwnerId,
      user.organizationId,
      selectedContribution.contributionId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          setSelectedContribution({} as AccountContribution);
        }

        getActiveDeposits();
        addGlobalMessage('Contribution successfully deleted', {
          severity: 'success',
        });
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Could not delete this contribution'
        );
      });

    if (isMounted) {
      setIsDeleting(false);
    }
  };

  function goToOrShowTransaction(row: AccountContribution) {
    const { contributionStatus } = row;
    // Only navigate to WIP transactions
    const shouldGoToTransaction = [
      ContributionStatus.pending,
      ContributionStatus.signature,
    ].includes(contributionStatus);

    if (shouldGoToTransaction) {
      linkToTransaction(accountOwnerId, accountId, row, navigate);
    } else {
      setSelectedContribution(row);
    }
  }

  async function openPdf(contribution: AccountContribution) {
    const { transferRequestId = '', contributionId = '' } = contribution;
    const apiMethod = transferRequestId
      ? getTransferRequestDocument
      : getContributionDocument;

    await apiMethod(
      user.organizationId,
      accountOwnerId,
      accountId,
      transferRequestId || contributionId,
      user.token,
      user,
      ''
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  const columns: GridColDef[] = [
    {
      field: 'contributionStatus',
      headerName: '',
      width: 90,
      renderCell: (params: GridCellParams) => {
        const { value = '', row = {} } = params;
        const [isFetchingDocument, setIsFetchingDocument] = useState(
          false as boolean
        );
        const isPending = row.contributionStatus === ContributionStatus.pending;
        const statusIcons = {
          [ContributionStatus.active]: <AssignmentTurnedInIcon />,
          [ContributionStatus.pending]: <TransactionPendingIcon />,
          [ContributionStatus.signature]: <TransactionSignatureIcon />,
        };
        const [rolesAnchorEl, setRolesAnchorEl] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setRolesAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setRolesAnchorEl(null);
        };

        return (
          <>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item>
                <IconButton
                  aria-label={`contribution status ${value}`}
                  size="small"
                  color="primary"
                  onClick={handleClick}
                >
                  {statusIcons[value.toString()]}
                </IconButton>
              </Grid>

              {!isPending && (
                <Grid item>
                  <IconButton
                    disabled={isFetchingDocument}
                    color="primary"
                    size="small"
                    data-testid={`open-contribution-pdf_${row.contributionId}`}
                    aria-label="Open latest pdf document"
                    onClick={async () => {
                      setIsFetchingDocument(true);
                      await openPdf(row);
                      setIsFetchingDocument(false);
                    }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(rolesAnchorEl)}
              onClose={handleClose}
              anchorEl={rolesAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">Status: {value.toString()}</Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'depositType',
      headerName: 'Deposit Type',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const typeId = value.toString();

        return (
          <Button
            onClick={() => {
              goToOrShowTransaction(row);
            }}
            sx={classes.contributionLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {getAppConstant(ConstantsMappingKey.depositType, typeId)}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'effectiveDate',
      headerName: 'Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      headerAlign: 'right',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const amount = value.toString();

        return (
          <NumberFormat
            value={amount}
            prefix="$"
            displayType="text"
            thousandSeparator
            isNumericString
            fixedDecimalScale
            decimalScale={2}
          />
        );
      },
    },
  ];

  // LOADING
  if (isLoading) {
    return <LinearProgress />;
  }

  // EDIT
  if (isEditing) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ContributionInfoForm
            account={account}
            amount={selectedContribution.amount}
            initialValues={{
              ...CONTRIBUTION_INFO_INIT,
              ...selectedContribution,
            }}
            onSubmit={updateSelectedContribution}
            onCancel={() => {
              setIsEditing(false);
            }}
            explodeSteps
          />
        </Grid>
      </Grid>
    );
  }

  const handleClose = () => {
    setmodalOpen(false);
  };

  // DETAILS
  if (selectedContribution.contributionId) {
    const {
      contributionId = '',
      depositType = '',
      depositMethod = '',
      dateOnCheck = '',
      dateOfWithdrawal = '',
      fromFinancialOrganization = '',
      fromAccountNumber = '',
      fromAccountType = '',
      payableTo = '',
      amount = '',
      effectiveDate = '',
      postponedCode = '',
      postponedReason = '',
      taxYear = '',
      repaymentCode = '',
      signedDate = '',
    } = selectedContribution;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Deposit Method</Typography>
            <Typography variant="body1">{depositMethod}</Typography>
            {dateOnCheck && (
              <Typography variant="body1">
                {dateOnCheck &&
                  format(parseISO(String(dateOnCheck)), 'MM/dd/yyyy')}
              </Typography>
            )}
            {payableTo && (
              <Typography variant="body1">Payable To: {payableTo}</Typography>
            )}
            {fromFinancialOrganization && (
              <Typography variant="body1">
                From: {fromFinancialOrganization}
              </Typography>
            )}
            {fromAccountNumber && (
              <Typography variant="body1"># {fromAccountNumber}</Typography>
            )}
            {fromAccountType && (
              <Typography variant="body1">{fromAccountType}</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">ID</Typography>
            <Typography variant="body1">{contributionId}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Date</Typography>
            <Typography variant="body1">
              {effectiveDate &&
                format(parseISO(String(effectiveDate)), 'MM/dd/yyyy')}{' '}
              (Tax Year: {taxYear})
            </Typography>
          </Grid>
          {signedDate && (
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Date Signed</Typography>
              <Typography variant="body1">
                {signedDate &&
                  format(parseISO(String(signedDate)), 'MM/dd/yyyy')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Amount</Typography>
            <Typography variant="body1">
              <NumberFormat
                value={amount}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Deposit Type</Typography>
            <Typography variant="body1">
              {getAppConstant(ConstantsMappingKey.depositType, depositType)}
            </Typography>
            {repaymentCode && (
              <Typography variant="body1">
                Code: {repaymentCodeLabels[repaymentCode] || repaymentCode}
              </Typography>
            )}
            {postponedCode && (
              <Typography variant="body1">
                Code: {postponementCodeLabels[postponedCode] || postponedCode}
              </Typography>
            )}
            {postponedReason && (
              <Typography variant="body1">
                Reason:{' '}
                {postponementReasonLabels[postponedReason] || postponedReason}
              </Typography>
            )}
            {dateOfWithdrawal && (
              <Typography variant="body1">
                Withdrawal Date: {dateOfWithdrawal}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item>
              <Button
                aria-label="Go back to account deposits"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSelectedContribution({});
                }}
                startIcon={<ArrowBackIcon />}
              >
                All Deposits
              </Button>
            </Grid>

            {user.roles.includes(UserRole.orgTransactionsAdmin) && (
              <>
                <Grid item>
                  <Button
                    aria-label="Edit deposit details"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    startIcon={<EditIcon />}
                  >
                    Edit Deposit
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    disabled={isDeleting}
                    aria-label="Delete this deposit"
                    variant="contained"
                    color="error"
                    onClick={buildData}
                    startIcon={<DeleteIcon />}
                  >
                    Delete Deposit
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <SiraDeleteModal
          title={modalData.title}
          body={modalData.body}
          showPrompt={modalOpen}
          handleClose={handleClose}
          deleteTransaction={deleteSelectedContribution}
        />
      </>
    );
  }

  // SHOW ALL
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TaxYearsFilterForm
            items={contributions}
            options={taxYearFilterOptions}
            onChange={setFilteredContributions}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            components={{
              NoRowsOverlay: SiraNoRowsOverlay,
            }}
            pageSizeOptions={[10]}
            disableColumnMenu
            autoHeight
            columns={columns}
            rows={filteredContributions.map((result, id) => ({
              id,
              transactionType: TransactionType.contribution,
              ...result,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AccountOwnerAccountContributions;
