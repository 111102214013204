import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BeneficiaryStatus } from '../../../api/BeneficiaryClaimsApi.d';

interface ViewBeneficiaryFilterProps {
  onItemClick: Function;
  statusFilter: Array<BeneficiaryStatus>;
}

export function ViewBeneficiaryFilter(props: ViewBeneficiaryFilterProps) {
  const { onItemClick = () => {}, statusFilter } = props;

  return (
    <Box mt={2} mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body2">Filter by:</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(BeneficiaryStatus.processing)
                ? 'contained'
                : 'outlined'
            }
            color="success"
            startIcon={<CheckCircleIcon />}
            size="small"
            onClick={() => {
              onItemClick([BeneficiaryStatus.processing]);
            }}
          >
            Submitted for processing
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(BeneficiaryStatus.pending)
                ? 'contained'
                : 'outlined'
            }
            color="warning"
            startIcon={<PendingIcon />}
            size="small"
            onClick={() => {
              onItemClick([BeneficiaryStatus.pending]);
            }}
          >
            Pending
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(BeneficiaryStatus.awaitingElection)
                ? 'contained'
                : 'outlined'
            }
            color="info"
            startIcon={<PendingIcon />}
            size="small"
            onClick={() => {
              onItemClick([BeneficiaryStatus.awaitingElection]);
            }}
          >
            Awaiting Election
          </Button>
        </Grid>{' '}
        <Grid item>
          <Button
            variant={
              statusFilter.includes(BeneficiaryStatus.awaitingAdditionalInfo)
                ? 'contained'
                : 'outlined'
            }
            color="info"
            startIcon={<PendingIcon />}
            size="small"
            onClick={() => {
              onItemClick([BeneficiaryStatus.awaitingAdditionalInfo]);
            }}
          >
            Awaiting Additional Info
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(BeneficiaryStatus.completed)
                ? 'contained'
                : 'outlined'
            }
            color="success"
            startIcon={<CheckCircleIcon />}
            size="small"
            onClick={() => {
              onItemClick([BeneficiaryStatus.completed]);
            }}
          >
            Completed
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              statusFilter.includes(BeneficiaryStatus.notProcessed)
                ? 'contained'
                : 'outlined'
            }
            color="info"
            startIcon={<PendingIcon />}
            size="small"
            onClick={() => {
              onItemClick([BeneficiaryStatus.notProcessed]);
            }}
          >
            Not Processed
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
