import React from 'react';
import { Alert, Link, Typography } from '@mui/material';

export interface SiraPageAlertProps {
  pageStatus: boolean;
  transactionName: string;
  accountOwnerId: string;
  accountId: string;
  linklabel?: string;
}
export const SiraPageAlert = (props: SiraPageAlertProps) => {
  const {
    pageStatus,
    transactionName,
    accountId,
    accountOwnerId,
    linklabel = 'View Account',
  } = props;

  const label = () => {

    if (linklabel === 'Request a Rollover/Transfer for this account') {
      return `/transactions/rolloverTransfer?accountId=${accountId}&accountOwnerId=${accountOwnerId}`;
    }

    if (linklabel === 'View Claim') {
    return `/beneficiaryClaims/viewBeneficiaryClaim/beneficiaryClaimsProfile?accountId=${accountId}&accountOwnerId=${accountOwnerId}`;
    }    
    
      return `/accountOwner/${accountOwnerId}/account/${accountId}`;
  };

  return (
    <Alert severity={pageStatus ? 'error' : 'success'} variant="outlined">
      {pageStatus ? (
        `${transactionName} could not be added.`
      ) : (
        <Typography gutterBottom>
          {transactionName} successfully added.{' '}
          <Link sx={{ fontStyle: 'italic' }} href={label()}>
            {linklabel}
          </Link>
        </Typography>
      )}
    </Alert>
  );
};

export default SiraPageAlert;
