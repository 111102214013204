import { Account } from './AccountApi.d';
import { Organization } from './OrganizationApi.d';

export enum BeneficiaryTypes {
  DEFAULT = '',
  SPOUSE = 'SPOUSE',
  ESTATE = 'ESTATE',
  TRUST = 'TRUST',
  CHILD = 'CHILD',
  OTHER = 'OTHER',
  NONEHUMAN = 'OTHER_NON_HUMAN',
}

export enum BeneficiaryStatus {
  empty = '',
  next = 'NEXT',
  previous = 'PREVIOUS',
  default = '',
  pending = 'PENDING',
  processing = 'SUBMITTED_FOR_PROCESSING',
  completed = 'COMPLETED',
  awaitingElection = 'AWAITING_ELECTION',
  awaitingAdditionalInfo = 'AWAITING_ADDITIONAL_INFORMATION',
  notProcessed = 'NOT_PROCESSED',
}

export enum BeneficiaryStatusState {
  next = 'NEXT',
  previous = 'PREVIOUS',
}

export enum PreferredContactMethod {
  mail = 'MAIL',
  email = 'EMAIL',
  call = 'CALL',
}

export interface BeneficiariesClaimResult {
  beneficiaryClaimId?: string;
  financialOrganizationId?: number | string;
  accountOwnerId?: string;
  accountId?: string;
  beneficiaryId?: string;
  beneficiaryClaimStatus?: BeneficiaryStatus;
  beneficiaryClaimDocuments?: Array<BeneficiariesClaimsDocuments>;
  dateOfDeathAccountBalance?: string;
  relationshipToAccountOwner?: BeneficiaryTypes;
  otherRelationshipToAccountOwner?: string;
  firstName?: string;
  lastName?: string;
  preferredContactMethod?: string;
  pcmEmail?: string;
  pcmPhone?: string;
  pcmAddress?: string;
  pcmCity?: string;
  pcmState?: string;
  pcmZip?: string;
  finOrgContactUserId?: string;
  finOrgSubmittedByUserId?: string;
  comments?: string;
  submittedDate?: string;
  dateOfDeath?: string;
  dateOfBirth?: string;
  selectedRep?: string;
  designateBeneficiary?: boolean;
}

export interface MultiOrgBeneficiaryClaimResult extends BeneficiaryClaimResult {
  account?: Account;
  accountOwner?: AccountOwner;
  financialOrganization?: Organization;
  beneficiaryClaimId?: number;
}

export enum ClaimDocumentType {
  other = 'OTHER',
  deathCert = 'DEATH_CERTIFICATE',
  designatedBene = 'BENEFICIARY_DESIGNATION',
  distributionElection = 'BENEFICIARY_DISTRIBUTION_ELECTION',
  completedDistElection = 'COMPLETED_BENEFICIARY_DISTRIBUTION_ELECTION',
}

export const claimDocumentTypeLabel: Array<SiraSelectItem> = [
  {
    value: ClaimDocumentType.other,
    label: 'Other',
  },
  { value: ClaimDocumentType.deathCert, label: 'Death Certificate' },
  { value: ClaimDocumentType.designatedBene, label: 'Beneficiary Designation' },
  {
    value: ClaimDocumentType.completedDistElection,
    label: 'Completed Beneficiary Distribution Election',
  },
];

export const claimDocumentTypeLabelWithoutDC: Array<SiraSelectItem> = [
  {
    value: ClaimDocumentType.other,
    label: 'Other',
  },
  { value: ClaimDocumentType.designatedBene, label: 'Beneficiary Designation' },
  {
    value: ClaimDocumentType.completedDistElection,
    label: 'Completed Beneficiary Distribution Election',
  },
];

export interface ClaimsBeneficiary {
  beneficiaryId?: number;
  beneficiaryVersion?: {
    version?: number;
    beneficiaryStatus?: string;
    signedDate?: string;
    startDate?: string;
    endDate?: string;
    primaryBeneficiaries?: Array<string>;
    secondaryBeneficiaries?: Array<string>;
  };
  relationship?: string;
  percent?: number;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  state?: string;
  foreignStateProvinceRegion?: string;
  zip?: string;
  country?: string;
  emailAddress?: string;
  phoneNumber?: string;
  taxpayerIdNumber?: string;
  beneficiaryDistributionOption?: BeneficiaryDistributionOption;
  startDate?: string;
  beneficiaryClaimId?: number;
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  dateOfBirth?: string;
  fullName?: string;
  name?: string;
  representativeFirstName?: string;
  representativeLastName?: string;
  electionId?: number;
}

export interface BeneficiariesClaimsDocuments {
  beneficiaryClaimDocId: number;
  fileName: string;
  beneficiaryClaimId: number;
  fileContents: any;
  documentType: ClaimDocumentType;
  description: string;
  beneficiaryClaimDocumentType: string;
  created?: string;
}

export interface BeneficiariesClaimsDeleteDocument {
  beneficiaryClaimDocId: number;
  fileName: string;
  beneficiaryClaimId: number;
  fileContents: Array<string>;
  documentType: ClaimDocumentType;
  description: string;
  created: string;
  beneficiaryClaimDocumentType: ClaimDocumentType;
}

export interface BeneficiariesClaimsInfo {
  beneficiaryClaimId?: string;
  financialOrganizationId?: string;
  accountOwnerId?: string;
  accountId?: string;
  beneficiaryId?: string;
  beneficiaryClaimStatus?: BeneficiaryStatus;
  beneficiaryClaimDocuments?: Array<BeneficiariesClaimsDocuments>;
  dateOfDeathAccountBalance?: string;
  relationshipToAccountOwner?: BeneficiaryTypes;
  otherRelationshipToAccountOwner?: string;
  firstName?: string;
  lastName?: string;
  preferredContactMethod?: string;
  pcmEmail?: string;
  pcmPhone?: string;
  pcmAddress?: string;
  pcmCity?: string;
  pcmState?: string;
  pcmZip?: string;
  finOrgContactUserId?: string;
  finOrgSubmittedByUserId?: string;
  comments?: string;
  submittedDate?: string;
  dateOfDeath?: string;
  designateBeneficiary?: boolean;
  beneficiaries?: Array<ClaimsBeneficiary>;
}

export interface BeneficiaryClaim {
  files?: Array<File>;
  fileProperties?: Array<FileProperties>;
  beneficiaryClaim?: BeneficiariesClaimResult;
}

export interface BeneficiaryClaims {
  beneficiaryClaim?: BeneficiariesClaimsInfo;
}

export interface BeneficiaryClaimFile {
  files?: Array<File>;
  fileProperties: Array<FileProperties>;
}

export interface FileProperties {
  beneficiaryClaimDocumentId?: number;
  documentType: ClaimDocumentType | string;
  description: string;
}

export interface BeneficiariesClaimResponse {
  data?: BeneficiariesClaimsInfo;
  errorMessage?: any;
}

export interface DeathCertificateResponse {
  data?: BeneficiariesClaimsDocuments;
  errorMessage?: any;
}

export interface BeneDocumentResponse {
  data?: BeneficiariesClaimsDocuments;
  errorMessage?: any;
}

export interface BeneficiariesDocumentClaimResponse {
  data?: Array<BeneficiariesClaimsDocuments>;
  errorMessage?: any;
}

export interface BeneficiariesClaimsDeleteDocumentResponse {
  data?: BeneficiariesClaimsDeleteDocument;
  errorMessage?: any;
}

export interface ClaimsBeneficiaryResponse {
  data?: ClaimsBeneficiary;
  errorMessage?: any;
}
