import React from 'react';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import SubStepButtonBar from '../components/steps/SubStepButtonBar';
import SubStepIcon from '../components/steps/SubStepIcon';
import { TransactionStep } from './TransactionStepper';

export interface TransactionSubStep extends TransactionStep {
  validationFields: Array<string>;
}

interface TransactionSubStepperProps {
  steps: Array<TransactionSubStep>;
  activeStep: number;
  setActiveStep: Function;
  explodeSteps: boolean;
}

function DynamicContent({ content }) {
  // Split the content into an array of words
  const words = content.split(' ');

  // Map over the array and replace certain words with JSX elements
  const parsedContent = words.map((word, index) => {
    if (word === 'icon') {
      // Replace '[icon]' with an icon component
      // eslint-disable-next-line react/no-array-index-key
      return  <ErrorIcon color="error" key={index} sx={{paddingTop:1}} />
      ;
    } 
    
    if (word.startsWith('<strong>') && word.endsWith('</strong>')) {
      // Replace '<strong>' and '</strong>' with the <strong> JSX element
      const innerText = word.slice(8, -9);
      // eslint-disable-next-line react/no-array-index-key
      return <strong key={index}>{innerText}</strong>;
    } 
      // Return the word as is
      return `${word  } `;
    
  });

  return <>{parsedContent}</>;
}


function TransactionSubStepper(props: TransactionSubStepperProps) {
  const {
    steps = [],
    activeStep = 0,
    setActiveStep = () => {},
    explodeSteps = false,
  } = props;

  return (
    <Stepper orientation="vertical" activeStep={activeStep} sx={{ mt: 2 }}>
      {steps.map(({ label = '', stepContent = null }, i) => (
        <Step key={label} active={i === activeStep || explodeSteps}>
          <StepLabel StepIconComponent={SubStepIcon}>
            <DynamicContent content={label} />
          </StepLabel>
          <StepContent>
            {stepContent}
            {!explodeSteps && (
              <SubStepButtonBar
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

export default TransactionSubStepper;
