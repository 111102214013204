import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';

import { useUser } from '../../../auth/useUser';
import { getDistributionFederalWithholding } from '../../../api/DistributionApi';
import { DistributionAmountFormData } from './DistributionAmountForm.d';
import SiraPercentField from '../SiraPercentField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { FederalTaxElectionType } from '../../../api/DistributionApi.d';
import { suggestedWithholding } from './distribution.utils';
import { errorMessages } from '../../../utils/errorhandling.utils';

interface FederalWithholdingPercentFieldProps {
  isEditing?: boolean;
  initialPercent?: any;
}

function FederalWithholdingPercentField(
  props: FederalWithholdingPercentFieldProps
) {
  let isMounted = true;
  const { isEditing = false, initialPercent } = props;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { values, setFieldValue } =
    useFormikContext<DistributionAmountFormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedDistribution] = useDebounce(values, 600);
  const {
    totalIncome,
    filingStatus,
    netAmount,
    totalAmount,
    effectiveDate,
    doNotWithholdFederal,
    federalTaxElectionType,
    useSuggestedWithholding,
    federalWithholdingPercent,
  } = debouncedDistribution as DistributionAmountFormData;

  const allowUserEnteredRate = isEditing
    ? true
    : !(
        isLoading ||
        values.doNotWithholdFederal ||
        federalTaxElectionType === FederalTaxElectionType.current
      );

  async function onWithholdingInfoChanged() {
    await getDistributionFederalWithholding(
      values as DistributionAmountFormData,
      user.token
    )
      .then((res) => {
        const { withholdingPercent = '' } = res.data;

        if (isMounted) {
          if (isEditing && initialPercent !== withholdingPercent) {
            setFieldValue('federalWithholdingPercent', initialPercent, true);
          } else {
            setFieldValue(
              'federalWithholdingPercent',
              withholdingPercent,
              true
            );
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(errorMessages(err));
        }
      });
  }

  useEffect(() => {
    const shouldRecalculate =
      !doNotWithholdFederal && // If withholding isn't disabled
      totalIncome && // required for calculation
      filingStatus && // required for calculation
      (netAmount || totalAmount) && // either required for calculation
      effectiveDate && // to get tax year for rates
      federalTaxElectionType === FederalTaxElectionType.suggested; // only if user selected the relevant option

    if (shouldRecalculate) {
      setIsLoading(true);
      onWithholdingInfoChanged();
    }

    return () => {
      isMounted = false;
    };
  }, [
    totalIncome,
    filingStatus,
    netAmount,
    totalAmount,
    effectiveDate,
    doNotWithholdFederal,
    federalTaxElectionType,
  ]);

  useEffect(() => {
    setFieldValue('percentFieldChange', !allowUserEnteredRate);
  }, [federalWithholdingPercent]);

  return (
    <>
      <SiraPercentField
        disabled={!allowUserEnteredRate}
        name="federalWithholdingPercent"
        label="Federal Tax"
        decimalScale={0}
        afterContent={suggestedWithholding(useSuggestedWithholding)}
      />
    </>
  );
}

export default FederalWithholdingPercentField;
