import React from 'react';
import { Switch, Tooltip, Typography } from '@mui/material';
import { FieldAttributes, useField } from 'formik';

const SiraSwitchTooltip: React.FC<
  FieldAttributes<{
    label?: string;
    checked?: boolean;
    value?: string;
  }>
> = ({ disabled, checked, value, ...props }) => {
  const [field] = useField<{}>(props);

  
  return (
    <>
      <Tooltip
        title={<Typography style={{ fontSize: 13 }}>{value}</Typography>}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Switch
          {...field}
          checked={checked}
          disabled={disabled}
        />
      </Tooltip>
    </>
  );
};

export default SiraSwitchTooltip;
