import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, LinearProgress } from '@mui/material';

import { useUser } from '../auth/useUser';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import UserSearchForm from '../components/form/userSearch/UserSearchForm';
import { getUsers } from '../api/UserApi';
import { UsersResponse } from '../api/UserApi.d';
import UserSearchResults from '../components/form/userSearch/UserSearchResults';
import { useGlobalContext } from '../auth/useGlobalContext';

function UserSearch() {
  let isMounted = true;
  const navigate = useNavigate();
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const goToUserProfile = (userRow): void => {
    const { userId = '' } = userRow;
    if (userId) {
      navigate(`/users/${userId}`);
    }
  };

  async function getAllUsers(): Promise<void> {
    setIsLoading(true);

    await getUsers(user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          setResponse(res);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage('Error fetching organization users');
        }
      });
  }

  useEffect(() => {
    if (user.organizationId && user.token) {
      getAllUsers();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token]);

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Users
          </Typography>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box mt={3} mb={5}>
                <Box pb={2}>
                  <Typography variant="body2">Filter Users</Typography>
                </Box>

                <UserSearchForm
                  setResponse={(data) => {
                    setResponse({
                      ...response,
                      ...data,
                    });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {isLoading ? (
            <Box width="1">
              <LinearProgress color="secondary" />
            </Box>
          ) : (
            <UserSearchResults
              onResultClick={goToUserProfile}
              response={response as UsersResponse}
              onDeleteUser={() => {
                getAllUsers();
              }}
            />
          )}
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default UserSearch;
