import { Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import StepButtonBar from '../components/steps/StepButtonBar';
import SiraRadioField, {
  RadioGroupOption,
} from '../components/form/SiraRadioField';
import { useGlobalContext } from '../auth/useGlobalContext';
import SiraCheckbox from '../components/form/SiraCheckbox';
import TransactionSubStepper, {
  TransactionSubStep,
} from './TransactionSubStepper';
import { Account } from '../api/AccountApi.d';
import { currentYear } from '../app.constants';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import SiraTextField from '../components/form/SiraTextField';
import {
  BeneDistributionElectionSub,
  BeneDistributionElectionSubText,
  BeneDistributionElectionTransfers,
  BeneWithholdingDistributitionOption,
  DistributionMethod,
} from '../api/DistributionApi.d';
import { distributionMethods } from '../components/form/distribution/resource.txt';
import SiraCheckboxField from '../components/form/SiraCheckboxField';

export interface BeneficiaryPDF {
  years?: number;
  transferRBD: BeneDistributionElectionSub;
  transferRBDPayoutSchedule: BeneDistributionElectionSub;
  transferToInheritedIraChecked: Boolean;
  transferRBDChecked: Boolean;
  transferRMDSubText: BeneDistributionElectionSubText;
  signatureLabel?: string;
  optionalSignatureLabel?: string;
  nameLabel?: string;
  notary?: boolean;
  disabilityCertification?: boolean;
  distributionMethod?: DistributionMethod;
  totalDistribution?: BeneWithholdingDistributitionOption;
  transferTotalDistributionChecked: Boolean;
  transferRBDFinOrgChecked: Boolean;
  transferRBDOwnChecked: Boolean;
  transferLifeExpectancyChecked: Boolean;
  transferRMDOwnSubText: BeneDistributionElectionSubText;
  transferRMDSubTextFinOrg: BeneDistributionElectionSubText;
}

export interface GenerateBeneficiaryPDFProps {
  onSubmit?: Function;
  onCancel?: Function;
  initialValues?: BeneficiaryPDF;
  account: Account;
}

export const BENEFICIARY_PDF_INITIAL_VALUES: BeneficiaryPDF = {
  years: 0,
  transferRBD: BeneDistributionElectionSub.default,
  transferRBDPayoutSchedule: BeneDistributionElectionSub.default,
  transferRMDSubText: BeneDistributionElectionSubText.default,
  transferRMDSubTextFinOrg: BeneDistributionElectionSubText.default,
  transferRMDOwnSubText: BeneDistributionElectionSubText.default,
  signatureLabel: '',
  optionalSignatureLabel: '',
  nameLabel: '',
  notary: false,
  disabilityCertification: false,
  distributionMethod: DistributionMethod.default,
  totalDistribution: BeneWithholdingDistributitionOption.default,
  transferToInheritedIraChecked: false,
  transferRBDChecked: false,
  transferTotalDistributionChecked: false,
  transferRBDFinOrgChecked: false,
  transferRBDOwnChecked: false,
  transferLifeExpectancyChecked: false,
};

function GenerateBeneficiaryPDF(props: GenerateBeneficiaryPDFProps) {
  const { onSubmit, initialValues, onCancel, account } = props;
  const { organization,getAppConstant } = useGlobalContext();
  const [activeStep, setActiveStep] = React.useState(0);
  const [userSelectedYears, setUserSelectedYears] = React.useState(0);
  const [iraEndByDate, setIraEndByDate] = React.useState(currentYear);

  const benePdfDistributionMethodMethods = [
    {
      value: DistributionMethod.cash,
      label: 'Cash',
    },
    {
      value: DistributionMethod.check,
      label: 'Check',
      afterContent: distributionMethods.default.check.afterContent,
    },
    {
      value: DistributionMethod.direct,
      label: `Transfer to account at ${organization.name}`,
    },
    {
      value: DistributionMethod.transfer,
      label: 'Transfer to account at another financial organization',
    },
  ];

  const beneDistributionElectionSubTextOption: Array<RadioGroupOption> = [
    {
      value: BeneDistributionElectionSubText.subtextWithRMD,
      label: 'Subtext with RMD',
    },
    {
      value: BeneDistributionElectionSubText.subtextWithoutRMD,
      label: 'Subtext without RMD',
    },
  ];

  const beneTransferToInheritedIRAOptions = [
    {
      name: 'transferTotalDistributionChecked',
      value: BeneDistributionElectionTransfers.totalDistribution,
      label: `Total Distribution of Assets`,
    },
    {
      name: 'transferLifeExpectancyChecked',
      value:
        BeneDistributionElectionTransfers.transferToInheritedLifeExpectancyPayoutSchedule,
      label: `Transfer to an inherited ${getAppConstant(
        ConstantsMappingKey.accountType,
        account.accountType
      )} at ${organization.legalName} with life expectancy payments`,
    },
    {
      name: 'transferRBDChecked',
      value:
        BeneDistributionElectionTransfers.transferToInheritedIraPayoutSchedule,
      label: `Transfer to an inherited ${getAppConstant(
        ConstantsMappingKey.accountType,
        account.accountType
      )} at ${
        organization.legalName
      } and take payments over ${userSelectedYears} years (entire IRA must be distributed by 12/31/${iraEndByDate})`,
      afterContent: (
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <SiraTextField
              name="years"
              label="Term Length (in years)"
              type="number"
            />
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'transferToInheritedIraChecked',
      value: BeneDistributionElectionTransfers.transferToInheritedFinOrg,
      label: `Transfer to an inherited ${getAppConstant(
        ConstantsMappingKey.accountType,
        account.accountType
      )} at a different financial organization`,
      afterContent: (
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <Field
              name="transferRMDSubText"
              options={beneDistributionElectionSubTextOption}
              component={SiraRadioField}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'transferRBDOwnChecked',
      value: BeneDistributionElectionTransfers.transferToOwn,
      label: `Transfer to my own IRA at ${organization.legalName}.`,
      afterContent: (
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <Field
              name="transferRMDOwnSubText"
              options={beneDistributionElectionSubTextOption}
              component={SiraRadioField}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'transferRBDFinOrgChecked',
      value: BeneDistributionElectionTransfers.transferToOwnFinOrg,
      label: `Transfer to my own IRA at a different financial organization.`,
      afterContent: (
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <Field
              name="transferRMDSubTextFinOrg"
              options={beneDistributionElectionSubTextOption}
              component={SiraRadioField}
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  const beneficiariesPdfSteps: Array<TransactionSubStep> = [
    {
      label: `Distribution Options`,
      stepContent: (
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            {beneTransferToInheritedIRAOptions.map((option) => (
              <Field
                name={option.name}
                label={option.label}
                afterContent={option.afterContent}
                component={SiraCheckboxField}
              />
            ))}
          </Grid>
        </Grid>
      ),
      validationFields: [],
    },
    {
      label: 'Distribution Method',
      stepContent: (
        <>
          <Grid item xs={12}>
            {/* yoou can select multiple distribution options with this checkbox field */}
            {benePdfDistributionMethodMethods.map((option) => (
              <Field
                name="distributionMethod"
                value={option.value}
                label={option.label}
                type="checkbox"
                component={SiraCheckbox}
              />
            ))}
          </Grid>
        </>
      ),
      validationFields: [],
    },
    {
      label: 'Additional Beneficiary Distribution ',
      stepContent: (
        <>
          <Grid item xs={12}>
            <Field
              name="notary"
              label="Notary"
              type="checkbox"
              component={SiraCheckbox}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="disabilityCertification"
              label="Disability Certification"
              type="checkbox"
              component={SiraCheckbox}
            />
          </Grid>
        </>
      ),

      validationFields: [],
    },
    {
      label: 'Signature',
      stepContent: (
        <>
          <Grid item xs={12} mt={2}>
            <SiraTextField name="signatureLabel" label="Signature Label" />
          </Grid>
          <Grid item xs={12} mt={2}>
            <SiraTextField
              name="optionalSignatureLabel"
              label="Optional Signature Label"
            />
          </Grid>
        </>
      ),

      validationFields: [],
    },
  ];

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      enableReinitialize
    >
      {({ isSubmitting, values }) => {
        useEffect(() => {
          if (values.years) {
            setUserSelectedYears(values.years);
            setIraEndByDate(Number(currentYear) + Number(values.years));
          }
        }, [values.years]);

        return (
          <Form>
            <TransactionSubStepper
              steps={beneficiariesPdfSteps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              explodeSteps={false}
            />
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName="Create PDF"
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default GenerateBeneficiaryPDF;
