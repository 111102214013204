import React from 'react';
import { Formik, Form } from 'formik';
import { Button, Grid, Typography } from '@mui/material';
import * as yup from 'yup';
import { endOfYear } from 'date-fns';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { dateValidation } from '../SiraDateField';
import SiraDateRangeField from '../SiraDateRangeField';
import SiraSwitchField from '../SiraSwitchField';

interface ReportsDepositSearchFormData {
  startDate: string;
  endDate: string;
  totals: boolean;
}

interface ReportsDepositSearchFormProps {
  initialValues: ReportsDepositSearchFormData;
  onSubmit?: Function;
}

const SEARCH_QUERY_SCHEMA = yup.object().shape({
  startDate: dateValidation(undefined, endOfYear(new Date()))
    .required()
    .label('Start Date'),
  endDate: dateValidation(undefined, endOfYear(new Date()))
    .required()
    .label('End Date'),
  totals: yup.boolean(),
});

function ReportsDepositSearchForm({
  initialValues,
  onSubmit = () => {},
}: ReportsDepositSearchFormProps) {
  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={SEARCH_QUERY_SCHEMA}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
            <SiraDateRangeField />

            <Grid item>
              <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography>Itemized</Typography>
                </Grid>
                <Grid item>
                  <SiraSwitchField name="totals" checked={values.totals} />
                </Grid>
                <Grid item>
                  <Typography>Totals</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                startIcon={<SearchOutlinedIcon />}
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default ReportsDepositSearchForm;
