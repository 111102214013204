import React from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';

import {
  AccountOwner,
  AccountOwnerSearchResponse,
  AccountOwnerSearchResult,
} from '../../../api/AccountOwnerApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import PluralizedString from '../../PluralizedString';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { usePaginationContext } from '../../../auth/useGlobalContext';
import { globalPaginationOptions } from '../../../app.constants';
import { dateValueFormatter } from '../../../utils/DataGrid.utils';
import { Account } from '../../../api/AccountApi.d';

interface AccountOwnerSearchResultsProps {
  onResultClick: Function;
  response: AccountOwnerSearchResponse;
}

interface SearchResultRow extends Account, AccountOwner {
  id: number;
  accounts: Array<AccountOwnerSearchResult>;
}

export function AccountSearchResults(props: AccountOwnerSearchResultsProps) {
  const { response = {}, onResultClick = () => {} } = props;
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const { data: results = [], query = '' } =
    response as AccountOwnerSearchResponse;

  const classes = {
    accountOwnerLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  if (!query) {
    return null;
  }

  // Group accounts by owner ID in a 2-dimensional array
  const groupedResults = Object.values(
    results.reduce((acc, currentValue) => {
      if (!acc[currentValue.accountOwner.accountOwnerId]) {
        acc[currentValue.accountOwner.accountOwnerId] = [];
      }
      acc[currentValue.accountOwner.accountOwnerId].push(currentValue);
      return acc;
    }, [])
  );

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 256,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-owner-search-result-link"
            onClick={() => {
              onResultClick(row);
            }}
            sx={classes.accountOwnerLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
  ];

  function resultRowFactory(
    resultGroup: Array<AccountOwnerSearchResult>,
    index: number
  ): SearchResultRow {
    return {
      ...resultGroup[0].account,
      ...resultGroup[0].accountOwner,
      id: index,
      accounts: resultGroup,
    };
  }

  const rows = groupedResults.map((resultGroup, index) =>
    resultRowFactory(resultGroup, index)
  );

  return (
    <>
      <Box mb={4}>
        <Typography variant="body1">Showing results for</Typography>
        <Typography variant="h5">&quot;{query}&quot;</Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <Typography variant="body1">
            {groupedResults.length} account{' '}
            <PluralizedString noun="owner" quantity={groupedResults.length} />{' '}
            found
          </Typography>

          <Box mt={4} style={{width:'100%'}}>
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              disableColumnMenu
              autoHeight
              columns={columns}
              rows={rows}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default AccountSearchResults;
