import React, { useState } from 'react';

import { Box, Paper, Typography, Button, Popover, Grid } from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';

import {
  OrganizationSearchResponse,
  Solution,
} from '../../../api/OrganizationApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import PluralizedString from '../../PluralizedString';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { usePaginationContext } from '../../../auth/useGlobalContext';
import { globalPaginationOptions } from '../../../app.constants';

interface OrganizationSearchResultsProps {
  response: OrganizationSearchResponse;
  onResultClick?: Function;
}

export function OrganizationSearchResults(
  props: OrganizationSearchResultsProps
) {
  const { response = {}, onResultClick = () => {} } = props;
  const { setGlobalPageSize } = usePaginationContext();
  const { data: results = [], query = '' } =
    response as OrganizationSearchResponse;
  const classes = {
    organizationLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  const columns = [
    {
      field: 'financialOrganizationName',
      headerName: 'Name',
      width: 356,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="searchResult_button"
            onClick={() => {
              onResultClick(row);
            }}
            sx={classes.organizationLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'financialOrganizationId',
      headerName: 'ID',
      width: 72,
    },
    {
      field: 'financialOrganizationFederalTaxId',
      headerName: 'Federal Tax ID',
      width: 156,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ein} />
        );
      },
    },
    {
      field: 'primaryBranchStateAbbreviation',
      headerName: 'State',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const { value = '', row = {} } = params;
        const [orgStateAnchor, setOrgStateAnchor] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setOrgStateAnchor(event.currentTarget);
        };

        const handleClose = () => {
          setOrgStateAnchor(null);
        };

        return (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <Button onClick={handleClick}>
                <Typography variant="body2">{value.toString()}</Typography>
              </Button>
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(orgStateAnchor)}
              onClose={handleClose}
              anchorEl={orgStateAnchor}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">
                  {row.primaryBranchStateName}
                </Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'solution',
      headerName: 'Solution',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        // setting the typograpy based on the color sent in for the solution
        // eslint-disable-next-line no-nested-ternary
        const colorValue =
          // eslint-disable-next-line no-nested-ternary
          value === Solution.silver
            ? '#938D8A'
            : value === Solution.green
            ? '#368E64'
            : '#0C1F2A';
        return (
          <Typography variant="body2" style={{ color: colorValue }}>
            {value.toString()}
          </Typography>
        );
      },
    },
  ];

  const rows = results.map((result, id) => {
    return {
      ...result,
      id,
    };
  });

  return (
    <>
      <Box mb={4}>
        <Typography variant="body1">Showing results for</Typography>

        <Typography variant="h5">&quot;{query}&quot;</Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <Typography variant="body1">
            {results.length} financial{' '}
            <PluralizedString noun="organization" quantity={results.length} />{' '}
            found
          </Typography>

          <Box mt={4}>
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              autoHeight
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              disableColumnMenu
              rows={rows}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default OrganizationSearchResults;
