import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgScheduledDistribution(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="null"
          fill="currentColor"
          d="m19,3.08306l-1,0l0,-1.99972l-2,0l0,1.99972l-8,0l0,-1.99972l-2,0l0,1.99972l-1,0c-1.11,0 -1.99001,0.89986 -1.99001,1.99972l-0.00999,13.99805c0,1.09985 0.89,1.99972 2,1.99972l14,0c1.10001,0 2,-0.89986 2,-1.99972l0,-13.99805c0,-1.09985 -0.89999,-1.99972 -2,-1.99972zm0,15.99777l-14,0l0,-9.99861l14,0l0,9.99861zm0,-11.99833l-14,0l0,-1.99972l14,0l0,1.99972zm-10,5.99916l-2,0l0,-1.99972l2,0l0,1.99972zm4,0l-2,0l0,-1.99972l2,0l0,1.99972zm4,0l-2,0l0,-1.99972l2,0l0,1.99972zm-8,3.99945l-2,0l0,-1.99972l2,0l0,1.99972zm4,0l-2,0l0,-1.99972l2,0l0,1.99972zm4,0l-2,0l0,-1.99972l2,0l0,1.99972z"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgScheduledDistribution;
