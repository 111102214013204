import useSWR from 'swr';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

import { UserRole } from '../api/UserApi.d';

export interface SiraUser {
  token: string;
  scope?: string;
  permissions?: Array<string>;
  roles?: Array<UserRole>;
  organizationId: string;
  sub?: string;
  email?: string;
}

export interface UseUserResponse {
  user: SiraUser;
  setOrganizationId: Function;
  isLoading: boolean;
  isError: any;
}

const parseJwt = (token) => {
  if (!token) return null;
  try {
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    tokenData.organizationId = tokenData['http://superiorira.com/org_id'];
    tokenData.roles = tokenData['http://superiorira.com/roles'];
    return tokenData;
  } catch (e) {
    return null;
  }
};

export function useUser(): UseUserResponse {
  const { getAccessTokenSilently, user } = useAuth0();
  const { data: authData, error: authError } = useSWR(
    ['token', user && user.name],
    getAccessTokenSilently
  );
  const tokenInfo = useMemo(() => parseJwt(authData), [authData]);

  // Always overwrite org ID with user meta org_id
  // Multi org users won't have org_id meta anyway
  const organizationId =
    tokenInfo &&
    (tokenInfo.organizationId ||
      JSON.parse(sessionStorage.getItem('selectedOrgId')));

  function setOrganizationId(orgIdToSet) {
    if (orgIdToSet) {
      window.sessionStorage.setItem('selectedOrgId', orgIdToSet);
    }
  }

  return {
    user: {
      ...tokenInfo,
      token: authData,
      organizationId,
      email: user?.email,
    },
    isLoading: !authError && !authData,
    isError: authError,
    setOrganizationId,
  };
}
