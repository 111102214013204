import React from 'react';
import { Formik, Form } from 'formik';
import { Button, Grid } from '@mui/material';
import * as yup from 'yup';
import { endOfYear } from 'date-fns';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { dateValidation } from '../SiraDateField';
import SiraDateRangeField from '../SiraDateRangeField';

interface ScheduledDistributionSearchFormData {
  startDate: string;
  endDate: string;
}

interface ScheduledDistributionSearchFormProps {
  initialValues: ScheduledDistributionSearchFormData;
  onSubmit?: Function;
}

const SEARCH_QUERY_SCHEMA = yup.object().shape({
  startDate: dateValidation(undefined, endOfYear(new Date()))
    .required()
    .label('Start Date'),
  endDate: dateValidation(undefined, endOfYear(new Date()))
    .required()
    .label('End Date'),
});

function ScheduledDistributionSearchForm({
  initialValues,
  onSubmit = () => {},
}: ScheduledDistributionSearchFormProps) {
  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={SEARCH_QUERY_SCHEMA}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
            <SiraDateRangeField />

            <Grid item xs={12} sm={3}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                startIcon={<SearchOutlinedIcon />}
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default ScheduledDistributionSearchForm;
