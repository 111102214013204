import React from "react";
import { Avatar, Button, Popover } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Profile from "./Profile";

function UserAvatar() {
  const { user } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const openProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  //<Profile open={open} onClose={handleClose} anchorEl={anchorEl} />
  return (
    <span>
      <Button onClick={handleClick}>
        <Avatar alt={user.name} src={user.iconImage} />
      </Button>
      <Popover
        id={"34"}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Profile></Profile>
      </Popover>
    </span>
  );
}

export default UserAvatar;
