import axios from 'axios';
import format from 'string-format';

import { prepareBinaryJson, removeEmptyStrings } from './api.utils';
import {
  UserImportColumnsResponse,
  User,
  UserResponse,
  UsersResponse,
  CustomerVerifyUser,
} from './UserApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const userUrl = '/api/org/{0.orgId}/user/{0.userId}';
const userSSOUrl = '/api/org/{0.orgId}/ssoUser';
const usersImportUrl = '/api/org/{0.orgId}/upload/userListImport';
const usersImportColumsUrl = '/api/org/{0.orgId}/upload/userListColumns';
const usersExportUrl = '/api/org/{0.orgId}/userListExport';
const passwordResetUrl = '/api/org/{0.orgId}/user/{0.userId}/passwordReset';
const customerPortalUserUrl = '/api/customerPortal/org/{0.orgId}/customerPortalUser';

function userRequestFactory(user: User): User {
  const {
    branchId,
    emailAddress,
    financialOrganizationId,
    firstName,
    lastName,
    organizationRole,
    phoneNumber,
    phoneNumberExtension,
    roles,
    userId,
    notificationPreference,
    mainContact,
  } = user;

  return {
    branchId,
    emailAddress,
    financialOrganizationId,
    firstName,
    lastName,
    organizationRole,
    phoneNumber,
    phoneNumberExtension, 
    roles,
    userId,
    notificationPreference,
    mainContact,
  };
}

export async function createUser(
  data: User,
  orgId: string,
  token: string
): Promise<UserResponse> {
  const dataNorm = removeEmptyStrings(userRequestFactory(data));
  const url = `${urlBase}${format(userUrl, {
    orgId,
    userId: '',
  })}`;

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createSSOUser(
  data: User,
  orgId: string,
  token: string
): Promise<UserResponse> {
  const dataNorm = removeEmptyStrings(userRequestFactory(data));
  const url = `${urlBase}${format(userSSOUrl, {
    orgId,
    userId: '',
  })}`;

  return axiosInstance.post(url, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getUser(
  orgId: string,
  userId: string | number,
  token: string
): Promise<UserResponse> {
  if (!orgId || !token) return null;
  const url = `${urlBase}${format(userUrl, {
    orgId,
    userId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function saveUser(
  data: User,
  orgId: string,
  userId: string | number,
  token: string
): Promise<UserResponse> {
  const dataNorm = removeEmptyStrings(userRequestFactory(data));
  const url = `${urlBase}${format(userUrl, {
    orgId,
    userId,
  })}`;

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteUser(
  orgId: string,
  userId: string | number,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(userUrl, {
    orgId,
    userId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getUsers(
  orgId: string,
  token: string,
  params?: { includeMultiOrgUsers: boolean }
): Promise<UsersResponse> {
  if (!orgId || !token) return null;
  const url = `${urlBase}${format(userUrl, {
    orgId,
    userId: '',
  })}`;

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function sendPasswordReset(
  orgId: string,
  userId: string | number,
  token: string
): Promise<UserResponse> {
  const url = `${urlBase}${format(passwordResetUrl, {
    orgId,
    userId,
  })}`;

  return axiosInstance.put(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export async function importUsers(
  files: Array<File>,
  headerMappings: any,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(usersImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();

  formData.append('file', files[0]);
  if (headerMappings) {
    formData.append('headerMappings', prepareBinaryJson(headerMappings));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importUsersColumns(
  files: Array<File>,
  orgId: string,
  token: string
): Promise<UserImportColumnsResponse> {
  const url = `${urlBase}${format(usersImportColumsUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function exportUsers(
  orgId: string,
  token: string,
  fileType: string = 'tsv'
): Promise<any> {
  const url = `${urlBase}${format(usersExportUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    params: {
      fileType,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}

// get Account Owner user
export async function getCustomerPortalUser(
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(customerPortalUserUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


// verify user with a post call
export async function verifyUser(
  data: CustomerVerifyUser,
  orgId: string,
  token: string
): Promise<any> {
  const dataNorm = removeEmptyStrings(data);
  const url = `${urlBase}${format(customerPortalUserUrl, {
    orgId,
  })}`;

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}