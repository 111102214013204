import React from 'react';
import { useTheme, Avatar, Typography } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface SubStepIconProps extends StepIconProps {}

function SubStepIcon(props: SubStepIconProps) {
  const theme = useTheme();
  const { completed, icon } = props;
  const classes = {
    stepNumber: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      border: '2px solid',
      borderColor: theme.palette.secondary.main,
    },
    stepComplete: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      border: '2px solid',
      borderColor: theme.palette.secondary.main,
    },
  };

  return (
    <Avatar sx={completed ? classes.stepComplete : classes.stepNumber}>
      {completed ? (
        <CheckCircleIcon fontSize="small" />
      ) : (
        <Typography variant="button">{icon}</Typography>
      )}
    </Avatar>
  );
}

export default SubStepIcon;
