export interface UsersResponse {
  data?: Array<User>;
  errorMessage?: any;
  status?: number;
  query?: string;
}

export interface UserResponse {
  data?: User;
  errorMessage?: any;
  status?: number;
  query?: string;
}

export interface UserImportColumnsResponse {
  data?: UserImportColumns;
  errorMessage?: any;
}

export interface UserImportColumns {
  ourHeaders?: Array<string>;
  fileHeaders?: Array<string>;
  rowData?: Array<any>;
}


export interface CustomerVerifyUser {
  // TODO
  lastName: string;
  taxpayerIdNumber: string;
  dateOfBirth: string;
}

export enum UserRole {
  admin = 'Administrator',
  multi = 'MultiOrgAccess',
  orgBeneficiaryClaims = 'OrgBeneficiaryClaims',
  orgRecurringDistributions = 'OrgRecurringDistributions',
  orgSiteAdmin = 'OrgSiteAdmin',
  orgTaxReporting = 'OrgTaxReporting',
  orgTransactions = 'OrgTransactions',
  orgUserAdmin = 'OrgUserAdmin',
  orgDataProcessor = 'OrgDataProcessor',
  orgTransactionsAdmin = 'OrgTransactionsAdmin',
  accountOwner = 'CustomerPortalUser',
  reportsAccess = 'ReportingRole'
}

export enum NotificationPreferences {
  default = '',
  all_notifications = 'ALL_NOTIFICATIONS',
  basedOnUser = 'FOR_USER_ROLES',
  dontSendNotifications = 'NONE',
}

export interface User {
  auth0UserId?: string;
  branchId?: number | string;
  financialOrganizationId?: number | string;
  organizationRole?: string;
  roles?: Array<UserRole>;
  userId?: number | string;
  mainContact?: boolean;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  phoneNumberExtension?: string;
  emailAddress?: string;
  email?: string;
  notificationPreference?: NotificationPreferences;
}
