import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Layout from '../components/Layout';
import ApplicableFederalRates from '../components/siteSettings/ApplicableFederalRates';
import PaperlessPage from '../components/PaperlessPage';
import NewTask from '../components/tasks/NewTask';
import StateTaxDeductible from '../components/siteSettings/StateTaxDeadlines';

function SiteSettings() {
  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Site Settings
          </Typography>

          <Box mt={5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* Applicable Federal Rates */}
                <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      Applicable Federal Rates
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <ApplicableFederalRates />
                  </AccordionDetails>
                </Accordion>               
                
                 {/* Applicable Federal Rates */}
                <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      State Tax Deadlines
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <StateTaxDeductible />
                  </AccordionDetails>
                </Accordion>

                {/* Tasks */}
                <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" color="secondary">
                      Create a Task
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <NewTask />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </>
      </PaperlessPage>
    </Layout>
  );
}
export default SiteSettings;
