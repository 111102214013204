// TODO: Find a way to fetch modal content from CRM once one is established

import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';

import SiraModal from '../SiraModal';

function SelfCertifiedQualifiedReasons() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(!modalOpen);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box>
        <Typography variant="body2">
          If the IRA owner missed the 60-day deadline to complete a valid
          rollover, the individual may make a written certification to the
          financial organization that the inability to complete the rollover was
          due to one or more{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            variant="inherit"
            onClick={handleClick}
            sx={{ cursor: 'pointer' }}
          >
            qualified reasons
          </Link>{' '}
          (as long as there has been no prior denial by the IRS as to the
          rollover in question).
        </Typography>
      </Box>

      <SiraModal
        title="Late Rollover Certification"
        open={modalOpen}
        handleClose={handleClose}
      >
        <>
          <Typography variant="body2">
            <ul>
              <li>
                An error was committed by the financial organization receiving
                the contribution or making the distribution to which the
                contribution relates.
              </li>
              <li>
                The distribution was in the form of a check, which was misplaced
                or never cashed.
              </li>
              <li>
                The distribution was deposited into and remained in an account
                that the IRA owner mistakenly thought was an eligible retirement
                plan or IRA.
              </li>
              <li>The IRA owner’s principal residence was severely damaged.</li>
              <li>A member of the IRA owner’s family died.</li>
              <li>
                The IRA owner or a member of the IRA owner’s family was
                seriously ill.
              </li>
              <li>The IRA owner was incarcerated.</li>
              <li>Restrictions were imposed by a foreign country</li>
              <li>A postal error occurred</li>
              <li>
                The distribution was made on account of an IRS levy, and the
                proceeds of the levy were returned to the IRA owner.
              </li>
              <li>
                The party making the distribution to which the rollover relates
                delayed providing information that the receiving IRA
                custodian/trustee required to complete the rollover despite the
                IRA owner’s reasonable efforts to obtain the information.
              </li>
              <li>
                The distribution was made to a state unclaimed property fund.
              </li>
            </ul>
          </Typography>
          <Typography variant="body2">
            The rollover contribution must be made to the IRA as soon as
            practicable (generally within 30 days) after the applicable
            reason(s) listed above no longer prevent the IRA owner from
            completing the transaction.
          </Typography>
        </>
      </SiraModal>
    </>
  );
}

export default SelfCertifiedQualifiedReasons;
