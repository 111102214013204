import React, { ReactChild } from 'react';

import Radio from '@mui/material/Radio';
import { Grid, FormLabel, Typography, Box } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoPopover from '../InfoPopover';
import { requestRolloverTransfer } from './transfer/resource.txt';
import { AccountType } from '../../api/AccountApi.d';

const renderOptions = (options, field) => {
  return options.map((option) => {
    const isSelected = field.value === option.value;

    return (
      <Grid
        key={`${option.name}-${option.value}`}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Box pt={1} pb={1}>
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            disabled={option.disabled}
          />
          {option.forceAfterContent ||
          (!option.forceAfterContent && isSelected && option.afterContent) ? (
            <Box ml={4}>{option.afterContent}</Box>
          ) : (
            ''
          )}
        </Box>
        {option.info && (
          <InfoPopover text={option.info} ariaLabel={option.infoAria} />
        )}
      </Grid>
    );
  });
};

export interface RadioGroupOption {
  forceAfterContent?: boolean;
  disabled?: boolean;
  label: string;
  value: string;
  info?: string;
  infoAria?: string;
  afterContent?: ReactChild;
}

const SiraTransferRadioField = ({
  field,
  form: { touched, errors },
  name,
  accountType,
  options,
  children,
  label = 'Choose One',
  ...props
}) => {
  const fieldName = name || field.name;
  const classes = {
    radioGroup: {
      pt: 1,
      pb: 1,
    },
    errorHelper: {
      pl: 2,
    },
  };

  return (
    <>
      {label && (
        <>
          <FormLabel sx={classes.radioGroup}>
            {[
              AccountType.inheritedTraditional,
              AccountType.inheritedRoth,
            ].includes(accountType) ? (
              <>
                {label}
                <InfoPopover
                  text={requestRolloverTransfer.inheritedTradRothIra}
                  ariaLabel={label}
                />
              </>
            ) : (
              label
            )}
            {touched[fieldName] && errors[fieldName] && (
              <Typography
                variant="caption"
                color="error"
                sx={classes.errorHelper}
              >
                {errors[fieldName]}
              </Typography>
            )}
          </FormLabel>
        </>
      )}
      <RadioGroup {...field} {...props} name={fieldName}>
        {options ? renderOptions(options, field) : children}
      </RadioGroup>
    </>
  );
};

export default SiraTransferRadioField;
