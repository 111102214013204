/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';

import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import BeneficiariesList from './BeneficiariesList';
import PluralizedString from '../PluralizedString';
import {
  createBeneficiaries,
  updatePendingBeneficiaries,
  changeBeneficiariesStatus,
} from '../../api/BeneficiariesApi';
import {
  Beneficiaries,
  BeneficiaryStatusState,
  BeneficiaryStatus,
} from '../../api/BeneficiariesApi.d';
import { Account } from '../../api/AccountApi.d';
import { newAccountDepositHelpTxt } from '../form/newAccount/resource.txt';
import { errorMessages } from '../../utils/errorhandling.utils';

interface SecondaryBeneficiariesProps {
  account: Account;
  beneficiaries: Beneficiaries;
  onSkipToSignature?: Function;
  onSave?: Function;
  updateBeneficiaries: Function;
  updateBeneficiariesStatus: Function;
  onAddClick?: Function;
  onEditClick?: Function;
  showForm: boolean;
  accountOwnerProfile?:boolean
}

function SecondaryBeneficiaries(props: SecondaryBeneficiariesProps) {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage, customerPortalUser } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    account,
    beneficiaries = {} as Beneficiaries,
    onSkipToSignature = () => {},
    onSave = () => {},
    onAddClick = () => {},
    onEditClick = () => {},
    updateBeneficiaries = () => {},
    updateBeneficiariesStatus = () => {},
    showForm = false,
    accountOwnerProfile = false,
  } = props;
  const { accountId, accountOwnerId } = account;
  const {
    secondaryBeneficiaries = [],
    version,
    beneficiaryStatus,
  } = beneficiaries;
  const [allowAddSave, setAllowAddSave] = useState(false);
  const isAwaitingSignature = [BeneficiaryStatus.signature].includes(
    beneficiaryStatus
  );


  useEffect(() => {
    if(!accountOwnerProfile ){
      setAllowAddSave(!beneficiaryStatus || beneficiaryStatus === BeneficiaryStatus.pending);
    }else{
      setAllowAddSave(false);
    }

  }, [beneficiaryStatus, accountOwnerProfile]);

  async function resetAwaitingSignatureBeneficiaries(): Promise<void> {
    await changeBeneficiariesStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      version.toString(),
      BeneficiaryStatusState.previous,
      {},
      user.token,
      user
    )
      .then((res) => {
        const { beneficiaryStatus: newBeneficiaryStatus } = res.data;

        if (isMounted) {
          updateBeneficiariesStatus(newBeneficiaryStatus);
        }
      })
      .catch(() => {
        addGlobalMessage("Error resetting beneficiaries' status");
      });
  }

  const savePendingBeneficiaries = async () => {
    const apiCall =
      !beneficiaryStatus || beneficiaryStatus !== BeneficiaryStatus.pending
        ? createBeneficiaries
        : updatePendingBeneficiaries;

    setIsLoading(true);

    await apiCall(
      beneficiaries,
      user.organizationId,
      accountId,
      accountOwnerId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          onSave(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
  
          addGlobalMessage(errorMessages(err) || 'Error saving contingent beneficiaries'
          );
        }
      });
  };

  const startOver = async () => {
    await resetAwaitingSignatureBeneficiaries();
  };

  const skipToSignature = () => {
    onSkipToSignature(beneficiaries);
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  if (isAwaitingSignature) {
    return (
      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="warning">
              A signature is required for the current set of beneficiaries.
            </Alert>
          </Grid>
          <Grid item xs={false}>
            <Button
              variant="contained"
              color="secondary"
              onClick={skipToSignature}
            >
              Continue to Signing
            </Button>
          </Grid>
          <Grid item xs={false}>
            <Button variant="contained" color="primary" onClick={startOver}>
              Edit
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box mt={2} mb={2}>
      <Box mb={2}>
        <Typography variant="body1" color="primary">
          Currently {secondaryBeneficiaries.length} Contingent{' '}
          <PluralizedString
            noun="Beneficiary"
            quantity={secondaryBeneficiaries.length}
          />
        </Typography>
        {newAccountDepositHelpTxt.primaryBeneficiary.curContingentBeneficiary}
      </Box>

      <BeneficiariesList
        beneficiaries={secondaryBeneficiaries}
        updateBeneficiaries={updateBeneficiaries}
        showForm={showForm}
        onEditClick={onEditClick}
      />

      {allowAddSave && (
        <>
          <Box pt={2} mr={2} display="inline-block">
            <Button
              data-qa="add-secondary-beneficiary-button"
              variant="contained"
              color="primary"
              disabled={showForm || isLoading}
              startIcon={<AddIcon />}
              onClick={() => {
                onAddClick();
              }}
            >
              Add Beneficiary
            </Button>
          </Box>

          {!showForm && (
            <Box pt={2} display="inline-block">
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading}
                onClick={savePendingBeneficiaries}
              >
                Continue
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default SecondaryBeneficiaries;
