import axios from 'axios';
import format from 'string-format';
import { LifeExpectancyTermResponse } from './LifeExpectancyApi.d';
import { setBaseUrl } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
export const investmentRateUrl = '/api/org/{0.orgId}/investmentRate';
export const rmdUrl = '/org/{orgId}/accountOwner/{accountOwnerId}/account/{accountId}/rmd';

export async function getLifeExpectancyTerms(
  orgId: string,
  accountId: string,
  accountOwnerId:string,
  spouseBirthDate: string,
  fairMarketValue: string,
  token: string,
  user: any 
): Promise<LifeExpectancyTermResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${rmdUrl}`, { orgId, accountId, accountOwnerId })}`;
  const params = {} as any;
  if (spouseBirthDate || fairMarketValue) {
    if (spouseBirthDate) params.spouseDOB = spouseBirthDate;
    if (fairMarketValue) params.fmv = fairMarketValue;
  }

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
