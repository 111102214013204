import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgRmdNotification(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="null"
          fill="currentColor"
          d="m15.7375,13.84636l-0.89375,-0.8007c-1.5,1.02466 -2.4875,2.60365 -2.575,4.39539l1.25,0c0.09375,-1.48379 0.94375,-2.78282 2.21875,-3.59469zm7.74375,3.59469l1.25,0c-0.09375,-1.79174 -1.08126,-3.37074 -2.575,-4.39539l-0.88751,0.8007c1.26251,0.81188 2.11876,2.11091 2.21251,3.59469zm-1.23125,0.27995c0,-1.71896 -1.025,-3.15795 -2.8125,-3.53871l0,-0.38074c0,-0.46475 -0.41875,-0.83988 -0.9375,-0.83988s-0.9375,0.37513 -0.9375,0.83988l0,0.38074c-1.79375,0.38076 -2.8125,1.81416 -2.8125,3.53871l0,2.79962l-1.25,1.11984l0,0.55993l10,0l0,-0.55993l-1.25,-1.11984l0,-2.79962zm-3.75,6.15915c0.0875,0 0.16875,-0.0056 0.25,-0.02241c0.40626,-0.07837 0.73751,-0.32474 0.9,-0.66069c0.0625,-0.13439 0.09376,-0.27998 0.09376,-0.43676l-2.5,0c0.00625,0.61593 0.5625,1.11986 1.25625,1.11986l-0.00001,0z"
        />

        <path
          stroke="null"
          fill="currentColor"
          d="m19,3.08306l-1,0l0,-1.99972l-2,0l0,1.99972l-8,0l0,-1.99972l-2,0l0,1.99972l-1,0c-1.11,0 -1.99001,0.89986 -1.99001,1.99972l-0.00999,13.99805c0,1.09985 0.89,1.99972 2,1.99972l14,0c1.10001,0 2,-0.89986 2,-1.99972l0,-13.99805c0,-1.09985 -0.89999,-1.99972 -2,-1.99972zm0,15.99777l-14,0l0,-9.99861l14,0l0,9.99861zm0,-11.99833l-14,0l0,-1.99972l14,0l0,1.99972zm-10,5.99916l-2,0l0,-1.99972l2,0l0,1.99972zm4,0l-2,0l0,-1.99972l2,0l0,1.99972zm4,0l-2,0l0,-1.99972l2,0l0,1.99972zm-8,3.99945l-2,0l0,-1.99972l2,0l0,1.99972zm4,0l-2,0l0,-1.99972l2,0l0,1.99972zm4,0l-2,0l0,-1.99972l2,0l0,1.99972z"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgRmdNotification;
