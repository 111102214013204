import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  // Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { useUser } from '../../auth/useUser';
import { Account } from '../../api/AccountApi.d';
import {
  deleteTaxFormInfo5498,
  getaccountOwnerTaxForm,
  getaccountOwnerTaxForm5498,
  getTaxForm5498Document,
  getTaxFormDocument,
  taxformDateSentToOwnerRequest,
} from '../../api/TaxFormApi';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { TaxForm, TaxForm5498, TaxFormType } from '../../api/TaxFormApi.d';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../../utils/DataGrid.utils';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { UserRole } from '../../api/UserApi.d';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import TaxForm1099Details, {
  TaxInfo,
} from '../../page/Account1099TaxFormProfile';
import TaxForm5498Details from '../../page/Account5498TaxFormProfile';
import TaxYearsFilterForm from '../form/TaxYearsFilterForm';
import { errorMessages } from '../../utils/errorhandling.utils';
import SiraModal from '../SiraModal';
import { DeleteOutline } from '@mui/icons-material';
import { SiraDeleteModal } from '../SiraDeleteModal';

export const POSITIVE_DOLLAR_SCHEMA = {
  taxYearFilters: yup.array(yup.number()).label('Fair Market Value'),
};

interface AccountOwnerTaxFormsProps {
  account: Account;
}

function AccountOwnerTaxForms(props: AccountOwnerTaxFormsProps) {
  let isMounted = true;
  const theme = useTheme();

  const [errorMessage] = useState('');
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage, taxYears } = useGlobalContext();
  const [taxFormData, setTaxFormData] = useState([] as Array<TaxForm>);
  const [taxForm5498Data, setTaxForm5498Data] = useState(
    [] as Array<TaxForm5498>,
  );
  const { account } = props;
  const { accountId, accountOwnerId, accountType } = account;
  const [displayTaxFormsTable, setDisplayTaxFormsTable] = useState(
    true as boolean,
  );
  const [taxInfo, setTaxInfo] = useState({} as TaxInfo);
  const [userRole, setUserRole] = useState(false as boolean);
  const [isTaxReportAdmin, setIsTaxReportAdmin] = useState(false as boolean);
  const [hasMatchingRoles, setHasMatchingRoles] = useState(
    (user.roles.includes(UserRole.orgTransactions) &&
      !user.roles.includes(UserRole.orgTransactionsAdmin)) as boolean,
  );
  const [show1099TaxForm, setshow1099TaxForm] = useState(false as boolean);
  const [show5498TaxForm, setshow5498TaxForm] = useState(false as boolean);
  const navigate = useNavigate();
  const [expand1099TaxForm, setexpand1099TaxForm] = useState(false as boolean);
  const [expand5498TaxForm, setexpand5498TaxForm] = useState(false as boolean);
  const [filtered5498, setFiltered5498] = useState([] as TaxForm5498[]);
  const [filtered, setFiltered] = useState([] as TaxForm[]);
  const [acknowledgedSent, setAcknowledgeSent] = useState(false as boolean);
  const [dateSent, setDateSentToOwner] = useState('' as string);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      actions: userRole,
      editSchedDistribution: hasMatchingRoles,
      sentMailAction: isTaxReportAdmin,
    });

  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
  };
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isAccountOwner, setIsAccountOwner] = useState(false as boolean);
  const [selectedCorrection, setSelectedCorrection] = useState({} as any);
  const [modalData, setModalData] = useState({ body: '', title: '' });
  const [modalOpen, setmodalOpen] = useState(false);
  const [taxFormDeleteData, setTaxFormDeleteData] = useState({} as any);

  const gotoDistribution = (row: any): void => {
    navigate(
      `/accountOwner/${accountOwnerId}/account/${accountId}/taxFormWorkflow?accountOwner=${accountOwnerId}&account=${accountId}&taxformid=${row.id}&taxForm=1099`,
    );
  };

  const gotoContribution = (row: any): void => {
    navigate(
      `/accountOwner/${accountOwnerId}/account/${accountId}/taxFormWorkflow?accountOwner=${accountOwnerId}&account=${accountId}&taxformid=${row.id}&taxForm=5498`,
    );
  };

  const checkActiveYearEdit = (row: any) => {
    const rowTaxYear = row.taxYear;
    const activeYear = taxYears.find((year) => year.active === true)?.taxYear;

    return rowTaxYear === activeYear;
  };

  async function openPdf(taxForm: TaxForm) {
    await getTaxFormDocument(
      accountId,
      accountOwnerId,
      taxForm.id,
      user.organizationId,
      user.token,
      user,
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data,
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  async function open5498Pdf(taxForm: TaxForm5498) {
    await getTaxForm5498Document(
      accountId,
      accountOwnerId,
      taxForm.id,
      user.organizationId,
      user.token,
      user,
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data,
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  const acknowledgeSent = (row: TaxForm) => {
    setAcknowledgeSent(true);
    setSelectedCorrection(row);
  };

  const buildModalData = (row) => {
    setTaxFormDeleteData(row);
    setModalData({
      title: 'Tax Form',
      body: `This will delete the tax form. Do you want to delete this tax form?`,
    });
    setmodalOpen(true);
  };

  // close opened modal
  const handleClose = () => {
    setmodalOpen(false);
  };

  async function deleteTaxFormClick() {
    const { id } = taxFormDeleteData;

    await deleteTaxFormInfo5498(
      accountId,
      accountOwnerId,
      id,
      user.organizationId,
      user.token,
      user,
    )
      .then((res) => {
        setmodalOpen(false);
        addGlobalMessage('Tax Form deleted successfully', {
          severity: 'success',
        });
        setFiltered5498((prevData) => prevData.filter((row) => row.id !== id));
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Error deleting Tax Form');
      });
  }

  const columnsR = isAccountOwner
    ? ([
        {
          field: 'action',
          headerName: '',
          sortable: false,
          width: 50,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;

            return (
              <>
                <Grid container justifyContent="flex-end">
                  {row.pdfGenerated && (
                    <IconButton
                      data-testid="pdfDocument"
                      size="small"
                      aria-label="Get PDF Document"
                      onClick={() => {
                        openPdf(row);
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  )}
                </Grid>
              </>
            );
          },
        },
        {
          field: 'taxYear',
          headerName: 'Tax Year',
          width: 85,
          renderCell: (params: GridCellParams) => {
            const { row = {}, value = '' } = params;
            return (
              <Button
                data-qa="account-search-result-link"
                onClick={() => {
                  setDisplayTaxFormsTable(false);
                  setshow1099TaxForm(true);
                  setexpand1099TaxForm(false);

                  setTaxInfo({
                    accountId,
                    accountOwnerId,
                    taxId: row.id,
                    formType: TaxFormType[row.formType],
                    accountType,
                  });
                }}
                sx={classes.accountLink}
              >
                <Box overflow="hidden" textOverflow="ellipsis">
                  {value.toString()}
                </Box>
              </Button>
            );
          },
        },
        {
          field: 'distributionReason',
          headerName: 'Reason',
          width: 175,
          valueFormatter: (params: GridValueFormatterParams) => {
            const { value = '' } = params;
            return getAppConstant(
              ConstantsMappingKey.distributionReason,
              value,
            );
          },
        },
        {
          field: 'distributionAmount',
          headerName: 'Amount',
          sortable: false,
          width: 100,
          valueFormatter: currencyValueFormatter,
        },
        {
          field: 'dateSentToOwner',
          headerName: 'Date Sent to Owner',
          width: 100,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'dateFiledWithIrs',
          headerName: 'Date Filed with IRS',
          width: 100,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'corrected',
          headerName: 'Corrected',
          width: 125,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            return (
              <>
                <Grid container justifyContent="center">
                  {row.corrected && <CheckIcon />}
                </Grid>
              </>
            );
          },
        },
      ] as GridColDef[])
    : ([
        {
          field: 'action',
          headerName: '',
          sortable: false,
          width: 50,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;

            return (
              <>
                <Grid container justifyContent="flex-end">
                  {row.pdfGenerated && (
                    <IconButton
                      data-testid="pdfDocument"
                      size="small"
                      aria-label="Get PDF Document"
                      onClick={() => {
                        openPdf(row);
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  )}
                </Grid>
              </>
            );
          },
        },
        {
          field: 'taxYear',
          headerName: 'Tax Year',
          width: 85,
          renderCell: (params: GridCellParams) => {
            const { row = {}, value = '' } = params;
            return (
              <Button
                data-qa="account-search-result-link"
                onClick={() => {
                  setDisplayTaxFormsTable(false);
                  setshow1099TaxForm(true);
                  setexpand1099TaxForm(false);

                  setTaxInfo({
                    accountId,
                    accountOwnerId,
                    taxId: row.id,
                    formType: TaxFormType[row.formType],
                    accountType,
                  });
                }}
                sx={classes.accountLink}
              >
                <Box overflow="hidden" textOverflow="ellipsis">
                  {value.toString()}
                </Box>
              </Button>
            );
          },
        },
        {
          field: 'distributionReason',
          headerName: 'Reason',
          width: 175,
          valueFormatter: (params: GridValueFormatterParams) => {
            const { value = '' } = params;
            return getAppConstant(
              ConstantsMappingKey.distributionReason,
              value,
            );
          },
        },
        {
          field: 'distributionAmount',
          headerName: 'Amount',
          sortable: false,
          width: 100,
          valueFormatter: currencyValueFormatter,
        },
        {
          field: 'dateSentToOwner',
          headerName: 'Date Sent to Owner',
          width: 100,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'dateFiledWithIrs',
          headerName: 'Date Filed with IRS',
          width: 100,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'corrected',
          headerName: 'Corrected',
          width: 125,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            return (
              <>
                <Grid container justifyContent="center">
                  {row.corrected && <CheckIcon />}
                </Grid>
              </>
            );
          },
        },
        {
          field: 'editSchedDistribution',
          type: 'actions',
          sortable: false,
          width: 50,
          hide: !hasMatchingRoles,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            return (
              checkActiveYearEdit(row) && (
                <>
                  <Grid container justifyContent="flex-start">
                    <IconButton
                      data-testid="EditSchedDist"
                      size="small"
                      aria-label="Edit Scheduled Distribution"
                      onClick={() => {
                        gotoDistribution(row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </>
              )
            );
          },
        },
        {
          field: 'sentMailAction',
          headerName: '',
          sortable: false,
          width: 50,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            const { corrected, dateSentToOwner } = row;
            return (
              checkActiveYearEdit(row) && (
                <>
                  {' '}
                  <Tooltip title="Do Not Mail to Owner.">
                    <Grid container justifyContent="flex-end">
                      {/* wrapped in a tooltip to show the reason for the disabled button/ to acknowledge the sent correction or send to owner */}

                      <IconButton
                        data-testid="sentCorrectionContribution"
                        size="small"
                        aria-label="Sent Correction Contribution"
                        onClick={() => {
                          acknowledgeSent(row);
                        }}
                        disabled={Boolean(dateSentToOwner)}
                      >
                        {dateSentToOwner ? (
                          <MarkEmailReadOutlinedIcon />
                        ) : (
                          <MailOutlinedIcon />
                        )}
                      </IconButton>
                    </Grid>
                  </Tooltip>
                </>
              )
            );
          },
        },
      ] as GridColDef[]);

  const columns = isAccountOwner
    ? ([
        {
          field: 'action',
          headerName: '',
          sortable: false,
          width: 85,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;

            return (
              <>
                <Grid container justifyContent="flex-end">
                  {row.pdfGenerated && (
                    <IconButton
                      data-testid="pdfDocument"
                      size="small"
                      aria-label="Get PDF Document"
                      onClick={() => {
                        open5498Pdf(row);
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  )}
                </Grid>
              </>
            );
          },
        },
        {
          field: 'taxYear',
          headerName: 'Tax Year',
          width: 85,
          renderCell: (params: GridCellParams) => {
            const { row = {}, value = '' } = params;
            return (
              <Button
                data-qa="account-search-result-link"
                onClick={() => {
                  setDisplayTaxFormsTable(false);
                  setshow5498TaxForm(true);
                  setexpand5498TaxForm(false);

                  setTaxInfo({
                    accountId,
                    accountOwnerId,
                    taxId: row.id,
                    formType: TaxFormType[row.formType],
                    accountType,
                  });
                }}
                sx={classes.accountLink}
              >
                <Box overflow="hidden" textOverflow="ellipsis">
                  {value.toString()}
                </Box>
              </Button>
            );
          },
        },
        {
          field: 'dateSentToOwner',
          headerName: 'Date Sent to Owner',
          width: 172,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'dateFiledWithIrs',
          headerName: 'Date Filed with IRS',
          width: 172,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'corrected',
          headerName: 'Corrected',
          width: 124,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            return (
              <>
                <Grid container justifyContent="center">
                  {row.corrected && <CheckIcon />}
                </Grid>
              </>
            );
          },
        },
      ] as GridColDef[])
    : ([
        {
          field: 'action',
          headerName: '',
          sortable: false,
          width: 85,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;

            return (
              <>
                <Grid container justifyContent="flex-end">
                  {row.pdfGenerated && (
                    <IconButton
                      data-testid="pdfDocument"
                      size="small"
                      aria-label="Get PDF Document"
                      onClick={() => {
                        open5498Pdf(row);
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  )}
                </Grid>
              </>
            );
          },
        },
        {
          field: 'taxYear',
          headerName: 'Tax Year',
          width: 85,
          renderCell: (params: GridCellParams) => {
            const { row = {}, value = '' } = params;
            return (
              <Button
                data-qa="account-search-result-link"
                onClick={() => {
                  setDisplayTaxFormsTable(false);
                  setshow5498TaxForm(true);
                  setexpand5498TaxForm(false);

                  setTaxInfo({
                    accountId,
                    accountOwnerId,
                    taxId: row.id,
                    formType: TaxFormType[row.formType],
                    accountType,
                  });
                }}
                sx={classes.accountLink}
              >
                <Box overflow="hidden" textOverflow="ellipsis">
                  {value.toString()}
                </Box>
              </Button>
            );
          },
        },
        {
          field: 'dateSentToOwner',
          headerName: 'Date Sent to Owner',
          width: 172,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'dateFiledWithIrs',
          headerName: 'Date Filed with IRS',
          width: 172,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'corrected',
          headerName: 'Corrected',
          width: 124,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            return (
              <>
                <Grid container justifyContent="center">
                  {row.corrected && <CheckIcon />}
                </Grid>
              </>
            );
          },
        },
        {
          field: 'editAction',
          headerName: '',
          sortable: false,
          width: 50,
          hide: !hasMatchingRoles,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            return (
              checkActiveYearEdit(row) && (
                <>
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      data-testid="EditContribution"
                      size="small"
                      aria-label="Edit Contribution"
                      onClick={() => {
                        gotoContribution(row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </>
              )
            );
          },
        },
        {
          field: 'sentMailAction',
          headerName: '',
          sortable: false,
          width: 50,
          hide: !isTaxReportAdmin,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            const { corrected, dateSentToOwner } = row;
            return (
              checkActiveYearEdit(row) && (
                <>
                  {' '}
                  <Tooltip title="Do Not Mail to Owner.">
                    <Grid container justifyContent="flex-end">
                      {/* wrapped in a tooltip to show the reason for the disabled button/ to acknowledge the sent correction or send to owner */}

                      <IconButton
                        data-testid="sentCorrectionContribution"
                        size="small"
                        aria-label="Sent Correction Contribution"
                        onClick={() => {
                          acknowledgeSent(row);
                        }}
                        disabled={Boolean(dateSentToOwner)}
                      >
                        {dateSentToOwner ? (
                          <MarkEmailReadOutlinedIcon />
                        ) : (
                          <MailOutlinedIcon />
                        )}
                      </IconButton>
                    </Grid>
                  </Tooltip>
                </>
              )
            );
          },
        },
        {
          field: 'deleteAction',
          headerName: '',
          sortable: false,
          width: 50,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            const { dateFiledWithIrs, dateSentToOwner } = row;
            const isDeletable =
              dateFiledWithIrs === null &&
              dateSentToOwner === null &&
              user.roles.includes(UserRole.multi);
            return (
              isDeletable && (
                <>
                  {' '}
                  <Tooltip title="Delete Tax Form">
                    <Grid container justifyContent="flex-end">
                      {/* wrapped in a tooltip to show the reason for the disabled button/ to acknowledge the sent correction or send to owner */}

                      <IconButton
                        data-testid="deleteCorrectionContribution"
                        size="small"
                        aria-label="Delete Tax Form"
                        onClick={() => {
                          buildModalData(row);
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Grid>
                  </Tooltip>
                </>
              )
            );
          },
        },
      ] as GridColDef[]);

  function resultRowFactory(resultRow: TaxForm, idx): TaxForm {
    return {
      id: idx,
      ...resultRow,
      year: resultRow.taxYear,
    };
  }

  function result5498RowFactory(resultRow: TaxForm5498, idx): TaxForm5498 {
    return {
      id: idx,
      ...resultRow,
      year: resultRow.taxYear,
    };
  }

  const rows = taxFormData
    ? taxFormData.map((resultRow, idx) => resultRowFactory(resultRow, idx))
    : [];

  const rows5498 = useMemo(
    () =>
      taxForm5498Data
        ? taxForm5498Data.map((resultRow, idx) =>
            result5498RowFactory(resultRow, idx),
          )
        : [],
    [taxForm5498Data],
  );

  // rows is set when new data is fetched, this then kicks off the useMemo to recalculate the filter options
  const taxYear5498FilterOptions = useMemo(
    () => [...new Set(rows5498.map((item) => item.taxYear.toString()))],
    [rows5498],
  );

  // retrieving account specific tax forms for 5498
  async function getAccount5498TaxForm(): Promise<void> {
    setIsLoading(true);

    await getaccountOwnerTaxForm5498(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
    )
      .then((response) => {
        setIsLoading(false);
        // set the tax form data for 5498 overwriting the previous data
        setTaxForm5498Data(prevData => { return [...response.data]});
      })
      .catch((err) => {
        setIsLoading(false);

        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'No Tax Forms found for acount',
          );
        }
      });
  }

  // rows is set when new data is fetched, this then kicks off the useMemo to recalculate the filter options
  const taxYearFilterOptions = useMemo(
    () => [...new Set(rows.map((item) => item.taxYear.toString()))],
    [rows],
  );

  // retrieving account specific tax forms for 1099
  async function getAccount1099TaxForm(): Promise<void> {
    setIsLoading(true);

    await getaccountOwnerTaxForm(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
    )
      .then((response) => {
        setIsLoading(false);
        setTaxFormData(response.data);
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'No Tax Forms found for acount',
          );
        }
      });
  }

  async function updateTaxFormDateSentToOwner(): Promise<void> {
    setIsLoading(true);
    await taxformDateSentToOwnerRequest(
      { dateSentToOwner: dateSent },
      user.organizationId,
      user.token,
      accountOwnerId,
      accountId,
      selectedCorrection.id,
      selectedCorrection.formType,
    )
      .then(() => {
        getAccount5498TaxForm();
        getAccount1099TaxForm();
        setAcknowledgeSent(false);
        setexpand5498TaxForm(false);
        setexpand1099TaxForm(false);
        addGlobalMessage('Tax Form date sent to owner updated successfully', {
          severity: 'success',
        });
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Error updating Tax Form');
      });
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDateSentToOwner(value);
  };

  useEffect(() => {
    if (user.roles && accountId) {
      // intitialize the tax forms
      setTaxForm5498Data([] as Array<TaxForm5498>);
      setTaxFormData([] as Array<TaxForm>);
      setshow1099TaxForm(false);
      setshow5498TaxForm(false);
      setDisplayTaxFormsTable(true);
      setexpand1099TaxForm(false);
      setexpand5498TaxForm(false);
      getAccount1099TaxForm();
      getAccount5498TaxForm();
      setUserRole(!user.roles.includes(UserRole.orgTransactionsAdmin));
      setIsAccountOwner(user.roles.includes(UserRole.accountOwner));
      setIsTaxReportAdmin(user.roles.includes(UserRole.orgTaxReporting));
      setColumnVisibilityModel({
        actions: !user.roles.includes(UserRole.orgTransactionsAdmin),
        editSchedDistribution: !user.roles.includes(UserRole.accountOwner),
        sentMailAction: user.roles.includes(UserRole.orgTaxReporting),
      });
      setHasMatchingRoles(
        user.roles.includes(UserRole.orgTransactions) &&
          !user.roles.includes(UserRole.orgTransactionsAdmin),
      );
    }

    return () => {
      isMounted = false;
    };
  }, [user.roles, accountId]);

  return (
    <Box width="1">
      <Typography variant="body2" color="error">
        {errorMessage}
      </Typography>
      <Box>
        {show1099TaxForm && (
          <TaxForm1099Details
            taxInfo={taxInfo}
            clicked={() => {
              setDisplayTaxFormsTable(true);
              setexpand1099TaxForm(true);

              setshow1099TaxForm(false);
            }}
          />
        )}
        {show5498TaxForm && (
          <TaxForm5498Details
            taxInfo={taxInfo}
            clicked={() => {
              setDisplayTaxFormsTable(true);
              setexpand5498TaxForm(true);

              setshow5498TaxForm(false);
            }}
          />
        )}
        {displayTaxFormsTable && (
          <>
            <Accordion
              onChange={(e, expanded) => {
                setexpand1099TaxForm(expanded);
              }}
              expanded={expand1099TaxForm}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      1099 Forms
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TaxYearsFilterForm
                      items={rows}
                      options={taxYearFilterOptions}
                      onChange={setFiltered}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DataGrid
                      sx={{
                        filter: isLoading ? 'blur(5px)' : '',
                        transition: 'filter 0.33s ease-in-out',
                      }}
                      components={{
                        NoRowsOverlay: SiraNoRowsOverlay,
                      }}
                      columnVisibilityModel={columnVisibilityModel}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[10]}
                      disableColumnMenu
                      autoHeight
                      columns={columnsR}
                      rows={filtered}
                      onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel(newModel)
                      }
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              onChange={(e, expanded) => {
                setexpand5498TaxForm(expanded);
              }}
              expanded={expand5498TaxForm}
              slotProps={{
                transition: {
                  mountOnEnter: true,
                  unmountOnExit: true,
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      5498 Forms
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TaxYearsFilterForm
                      items={rows5498}
                      options={taxYear5498FilterOptions}
                      onChange={setFiltered5498}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DataGrid
                      sx={{
                        filter: isLoading ? 'blur(5px)' : '',
                        transition: 'filter 0.33s ease-in-out',
                      }}
                      components={{
                        NoRowsOverlay: SiraNoRowsOverlay,
                      }}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns based on user role
                            editAction: !hasMatchingRoles,
                            sentMailAction: isTaxReportAdmin,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      disableColumnMenu
                      autoHeight
                      columns={columns}
                      rows={filtered5498}
                    />{' '}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <SiraModal
              handleClose={() => {
                setAcknowledgeSent(false);
              }}
              open={acknowledgedSent}
            >
              <Box>
                <Typography variant="h6" component="h2">
                  Correction Provided by Organization{' '}
                </Typography>
                <Box pt={2} pb={2}>
                  <Divider />
                </Box>
                <Box>
                  <Typography variant="body1">
                    Our organization has provided the corrected tax form to the
                    account owner. Enter the date and click
                    <strong> Acknowledge</strong> , and Superior{' '}
                    <strong>will not</strong> mail the corrected tax form.
                  </Typography>
                </Box>
                <Box mt={2}>
                  <TextField
                    label="Date Sent to Owner"
                    fullWidth
                    variant="outlined"
                    type="date"
                    value={selectedCorrection.dateSentToOwner}
                    data-qa="dateField"
                    onChange={handleDateChange}
                    InputLabelProps={{
                      shrink: true, // Force the label to shrink because we show the mask when empty
                    }}
                    inputProps={{
                      max: '9999-12-31', // Keeps year to 4 digits only (default allows up to 6)
                    }}
                  />
                </Box>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAcknowledgeSent(false);
                    }}
                    sx={{
                      marginRight: '1rem',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      updateTaxFormDateSentToOwner();
                    }}
                  >
                    Acknowledge
                  </Button>
                </Box>
              </Box>
            </SiraModal>

            <SiraDeleteModal
              title={modalData.title}
              body={modalData.body}
              showPrompt={modalOpen}
              handleClose={handleClose}
              deleteTransaction={deleteTaxFormClick}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default AccountOwnerTaxForms;
