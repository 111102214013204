/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  Tooltip,
  Typography,
} from '@mui/material';

import * as yup from 'yup';

import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

import { format, startOfYear } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { useGlobalContext } from '../auth/useGlobalContext';
import { useUser } from '../auth/useUser';
import {
  exportDeposits,
  getTaxDocumnentStatus,
  updateTaxDocumnentStatus,
} from '../api/TaxDocumentApi';
import { TaxDocument, TaxFormType } from '../api/TaxDocument.d';
import TaxReportingCheckListProgress from './TaxReportingCheckListProgress';
import SiraModal from '../components/SiraModal';
import SiraSelectField, {
  SiraSelectItem,
} from '../components/form/SiraSelectField';
import SiraSwitchField from '../components/form/SiraSwitchField';
import MultiButton, { MultiButtonAction } from '../components/MultiButton';
import { errorMessages } from '../utils/errorhandling.utils';
import { getConstantActiveTaxYear } from '../app.constants';
import { AccountType } from '../api/AccountApi.d';

// irs use tax reporting
// envelope for sent to owner
// create pdf
// change to icons instead of checkbox and change color
enum PrintableTaxForm {
  default = '',
  TAX_FORM_5498 = 'TAX_FORM_5498',
  TAX_FORM_5498ESA = 'TAX_FORM_5498ESA',
  TAX_FORM_5498SA = 'TAX_FORM_5498SA',
}

enum AccountTypeValues {
  TAX_FORM_5498 = 'IRA',
  TAX_FORM_5498ESA = 'COVERDELL_ESA',
  TAX_FORM_5498SA = 'HEALTH_SAVINGS_ACCOUNT',
}

type SiraListItem = ListItemTextProps &
  ListItemProps & {
    isroot?: boolean;
    group: number;
    indent?: number;
    formtype?: TaxFormType;
    buttonstring?: string;
    url?: string;
    // Number of levels to indent a list item
  };

export const taxFormDescriptions = {
  [PrintableTaxForm.default]: '',
  [PrintableTaxForm.TAX_FORM_5498ESA]: 'ESA',
  [PrintableTaxForm.TAX_FORM_5498SA]: 'HSA',
  [PrintableTaxForm.TAX_FORM_5498]: 'IRA',
};

export const printableTaxFormDescriptions = {
  [PrintableTaxForm.default]: '',
  [PrintableTaxForm.TAX_FORM_5498ESA]: 'TAX_FORM_5498ESA',
  [PrintableTaxForm.TAX_FORM_5498SA]: 'TAX_FORM_5498SA',
  [PrintableTaxForm.TAX_FORM_5498]: 'TAX_FORM_5498',
};

interface SecondaryActionButtonsProps {
  formtype?: TaxFormType;
  documentStatus: TaxDocument;
  buttonstring: string;
  triggerReadyToPrint: Function;
  checkButtonClicked: Function;
  accountType: string;
}

interface TaxReporting5498SelectionProps {
  initialValues: { taxFormType: PrintableTaxForm; switchValue: boolean };
  onSubmit?: Function;
  valid5498TaxTypes: Array<TaxDocument>;
}
export interface ActiveSteps {
  readyToPrint: boolean;
  sentToOwner: boolean;
  sentToIrs: boolean;
  correctionsReadyToPrint: boolean;
  correctionsSentToOwner: boolean;
  correctionsSentToIrs: boolean;
}

export function TaxReporting5498Selection({
  initialValues,
  onSubmit,
  valid5498TaxTypes,
}: TaxReporting5498SelectionProps) {
  const helpText =
    'Select Spreadsheet if you plan to pull FMV/deposit spreadsheets from the Superior platform to review the current data listed in the site and make updates accordingly. Select Tax File if you plan to upload an IRS 750 formatted file to the platform for the 5498 series reporting.';
  const [taxFormTypeDescriptions, settaxFormTypeDescriptions] = useState(
    [] as Array<SiraSelectItem>
  );
  const TYPESELECTION_SCHEMA = yup.object().shape({
    taxFormType: yup.string().required().label('Tax Form Type'),
  });
  const [disabled, setDisabled] = useState(false);

  const classes = {
    button: {
      mr: 2,
    },
  };

  useEffect(() => {
    if (valid5498TaxTypes.length > 0) {
      const siraSelectItems = valid5498TaxTypes.map((taxType) => {
        return {
          value: taxType.taxFormType,
          label: taxFormDescriptions[taxType.taxFormType],
        };
      });

      settaxFormTypeDescriptions(siraSelectItems);
    }
  }, [valid5498TaxTypes]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      onSubmit={(values) => {
        onSubmit(values);
        setDisabled(true);
      }}
      validationSchema={TYPESELECTION_SCHEMA}
    >
      {({ values }) => {
        const { switchValue } = values;

        useEffect(() => {
          setDisabled(false);
        }, [values.taxFormType]);

        return (
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', pt: 2, pb: 2 }}
                  gutterBottom
                >
                  Select an account type to review
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SiraSelectField
                  items={taxFormTypeDescriptions}
                  name="taxFormType"
                  label="5498 Tax Form Type"
                />
              </Grid>
              <Grid container>
                <Grid item pt={2}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', pr: 2, pt: 2, pb: 2 }}
                  >
                    Spreadsheet (Excel)
                  </Typography>
                </Grid>
                <Grid item pt={2}>
                  <Tooltip
                    title={
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', p: 2 }}
                      >
                        {helpText}
                      </Typography>
                    }
                    disableFocusListener
                    disableTouchListener
                    aria-describedby="five-Year-Test-description"
                  >
                    <Box p={1}>
                      <SiraSwitchField
                        name="switchValue"
                        checked={switchValue}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item pt={2}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', p: 2 }}>
                    Tax File (IRS 750 Format)
                  </Typography>
                </Grid>
                <Grid item ml={4}>
                  <Box pt={3}>
                    <Button
                      type="submit"
                      disabled={disabled}
                      variant="contained"
                      color="primary"
                      sx={classes.button}
                      data-qa="step-submit-button"
                      onClick={() => {
                        onSubmit(values);
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export function SecondaryActionButtons(props: SecondaryActionButtonsProps) {
  const {
    formtype,
    documentStatus,
    buttonstring,
    triggerReadyToPrint,
    checkButtonClicked,
    accountType,
  } = props;

  const { addGlobalMessage, activeTaxYear } = useGlobalContext();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const taxYear = activeTaxYear;

  const exportOrgDeposits = async (fileType): Promise<void> => {
    const startDate = format(
      startOfYear(new Date(taxYear, 0, 1)),
      'yyyy-MM-dd'
    );
    const endDate = format(new Date(), 'yyyy-MM-dd');
    const reportable = true;
    const accountTypes =
      accountType === PrintableTaxForm.TAX_FORM_5498
        ? [
            AccountType.traditionalIra,
            AccountType.traditionalSep,
            AccountType.simpleIra,
            AccountType.rothIra,
            AccountType.inheritedTraditional,
            AccountType.inheritedRoth,
          ]
        : [AccountTypeValues[accountType]];

    await exportDeposits(
      user.organizationId,
      user.token,
      fileType,
      accountTypes,
      [taxYear],
      reportable,
      startDate,
      endDate
    )
      .then(() => {
        setLoading(false);
        addGlobalMessage(
          'Your file will be available shortly under Notifications, located at the top of the page.',
          {
            severity: 'success',
          }
        );
      })
      .catch(() => {
        setLoading(false);
        addGlobalMessage('Error exporting organization deposits');
      });
  };

  // Define the download types
  const downloadActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => {
        setLoading(true);
        exportOrgDeposits('tsv');
      },
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => {
        setLoading(true);
        exportOrgDeposits('xlsx');
      },
      icon: <DownloadIcon />,
    },
  ];

  if (buttonstring && formtype && documentStatus) {
    return !documentStatus.readyToPrint ? (
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          triggerReadyToPrint(documentStatus);
        }}
      >
        {buttonstring}
      </Button>
    ) : (
      <></>
    );
  }

  if (buttonstring === 'Export Deposits') {
    return (
      <MultiButton
        actions={downloadActions}
        sx={{ whiteSpace: 'nowrap' }}
        disabled={loading}
        variant="contained"
        color="primary"
        startIcon={<DownloadIcon />}
        endIcon={<ExpandMoreIcon />}
      >
        {buttonstring}
      </MultiButton>
    );
  }

  return buttonstring ? (
    <Button
      variant="contained"
      size="small"
      sx={{ whiteSpace: 'nowrap' }}
      onClick={() => {
        checkButtonClicked(buttonstring);
      }}
    >
      {buttonstring}
    </Button>
  ) : (
    <></>
  );
}

function TaxReporting5498Checklist() {
  // Could send this array to an API or session storage when saving progress
  // Just fetch it and pass it into the default arg
  let isMounted = true;

  const [taxDocumentStatus, settaxDocumentStatus] = useState(
    [] as Array<TaxDocument>
  );
  const { width, height } = useWindowSize();

  const [selectedDocumentStatus, setselectedDocumentStatus] = useState(
    {} as TaxDocument
  );
  const { addGlobalMessage, activeTaxYear } = useGlobalContext();
  const { user } = useUser();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [reviewType, setReviewType] = useState(
    PrintableTaxForm.default as PrintableTaxForm
  );
  const [valid5498TaxTypes, setValid5498TaxTypes] = useState([] as Array<any>);
  const currentYear = useMemo(() => activeTaxYear, [activeTaxYear]);

  const [fileFormat, setFileFormat] = useState(false as boolean);

  const contentStyle = {
    height: '100%',
  };

  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 2,
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = (value: TaxDocument) => {
    setselectedDocumentStatus(value);
    setModalOpen(true);
  };

  const getCurrentTaxDocumentStatus = async (): Promise<void> => {
    await getTaxDocumnentStatus(
      getConstantActiveTaxYear(),
      user.organizationId,
      user.token
    )
      .then((status) => {
        if (isMounted) {
          settaxDocumentStatus(status.data);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not retreive the tax documents status'
        );
      });
  };

  const triggerReadyToPrint = async (): Promise<void> => {
    const data = { ...selectedDocumentStatus, readyToPrint: true };
    await updateTaxDocumnentStatus(data, user.organizationId, user.token)
      .then(() => {
        if (isMounted) {
          getCurrentTaxDocumentStatus();
          setModalOpen(false);
          setShowConfetti(true);
          addGlobalMessage('Tax form production approved!', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not set the tax documents status'
        );
      });
  };

  const gotoUploadDeposits = (): void => {
    navigate(`/taxReporting/checklist5498/deposits`);
  };

  const gotoUpload5498Deposits = (): void => {
    navigate(`/taxReporting/checklist5498/taxReporting5498`);
  };

  const gotoTaxReport = (): void => {
    navigate(`/taxReporting/checklist5498/5498TaxReport`);
  };

  const gotoFMVs = (): void => {
    navigate(`/taxReporting/checklist5498/fmv`);
  };

  const uploadFMVs = (): void => {
    navigate(`/taxReporting/checklist5498/fairMarketValue`);
  };

  const checkButtonClicked = (value: string): void => {
    switch (value) {
      case 'Upload Deposits':
        gotoUploadDeposits();
        break;
      case 'Upload 5498 Tax File':
        gotoUpload5498Deposits();
        break;
      case 'Get Report':
        gotoTaxReport();
        break;
      case 'Export FMVs':
        gotoFMVs();
        break;
      case 'Upload FMVs':
        uploadFMVs();
        break;

      default:
        break;
    }
  };

  const handleListClick = (event, formtype, documentStatus: TaxDocument) => {
    if (formtype && !documentStatus.readyToPrint) {
      handleModalOpen(documentStatus);
    }
    event.stopPropagation();
  };

  useEffect(() => {
    if (user.organizationId) {
      getCurrentTaxDocumentStatus();
    }
    return () => {
      isMounted = false;
    };
  }, [user.organizationId]);

  useEffect(() => {
    const validTaxType = taxDocumentStatus.filter((taxDocument) =>
      [
        TaxFormType.TAX_FORM_5498,
        TaxFormType.TAX_FORM_5498SA,
        TaxFormType.TAX_FORM_5498ESA,
      ].includes(taxDocument.taxFormType)
    );

    setValid5498TaxTypes(validTaxType);
  }, [taxDocumentStatus]);

  const listItems: Array<SiraListItem> = [
    {
      group: 3,
      isroot: true,
      primary: `Validate ${currentYear} 5498 Tax Records`,
    },
    {
      group: 3,
      primary: `Export ${currentYear} 5498 report`,
      buttonstring: 'Get Report',
      indent: 1,
    },

    {
      group: 4,
      isroot: true,
      primary: 'Approve 5498s to begin tax form production.',
    },
    {
      group: 4,
      primary: `5498`,
      buttonstring: 'Create 5498 tax forms',
      indent: 1,
      formtype: TaxFormType.TAX_FORM_5498,
    },
    {
      group: 4,
      primary: `5498-ESA`,
      buttonstring: 'Create 5498-ESA tax forms',
      indent: 1,
      formtype: TaxFormType.TAX_FORM_5498ESA,
    },
    {
      group: 4,
      primary: `5498-SA`,
      buttonstring: 'Create 5498-SA tax forms',
      formtype: TaxFormType.TAX_FORM_5498SA,
      indent: 1,
    },
  ];

  const iraListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review 12/31/${currentYear} IRA FMVs`,
    },
    {
      group: 0,
      primary: `Export IRAs with $0 or no 12/31/${currentYear} fair market values`,
      indent: 1,
      buttonstring: 'Export FMVs',
    },
    {
      group: 0,
      primary: 'Add FMV records as needed in Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary: 'If many updates are needed, upload an updated FMV spreadsheet',
      indent: 2,
      buttonstring: 'Upload FMVs',
    },
  ];

  const hsaListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review 12/31/${currentYear} HSA FMVs`,
    },
    {
      group: 0,
      primary: `Export HSAs with $0 or no 12/31/${currentYear} fair market values`,
      indent: 1,
      buttonstring: 'Export FMVs',
    },
    {
      group: 0,
      primary: 'Add FMV records as needed in Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary: 'If many updates are needed, upload an updated FMV spreadsheet',
      indent: 2,
      buttonstring: 'Upload FMVs',
    },
  ];

  const reviewListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Deposits`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} reportable deposits`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Deposits',
      indent: 1,
    },
    {
      group: 0,
      primary: 'Add/update deposit records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary:
        'If many updates are needed, upload an updated deposits spreadsheet',
      indent: 2,
      buttonstring: 'Upload Deposits',
    },
  ];

  const reviewHSAListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Deposits`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} reportable deposits`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Deposits',
      indent: 1,
    },
    {
      group: 0,
      primary: 'Add/update deposit records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary:
        'If many updates are needed, upload an updated deposits spreadsheet',
      indent: 2,
      buttonstring: 'Upload Deposits',
    },
    {
      group: 0,
      primary: `Note: Forms 5498-SA report all HSA deposits made for tax year ${currentYear} (whether they were deposited in ${currentYear} or ${
        currentYear + 1
      }). Deposits made in ${currentYear} for tax year ${
        currentYear - 1
      } must also be reported on this tax form. Confirm all deposits that fit this description are reported to Superior IRA & HSA.`,
      indent: 2,
    },
  ];

  const reviewEsaListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Deposits`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} reportable deposits`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Deposits',
      indent: 1,
    },
    {
      group: 0,
      primary: 'Add/update deposit records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary:
        'If many updates are needed, upload an updated deposits spreadsheet',
      indent: 2,
      buttonstring: 'Upload Deposits',
    },
  ];

  const errorListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review Errors from Uploaded Spreadsheets (if applicable)`,
    },
    {
      group: 0,
      isroot: true,
      secondary: `If you uploaded a spreadsheet to update deposit records`,
    },
    {
      group: 0,
      primary: 'Correct any errors from the upload as needed',
      indent: 1,
    },
    { group: 0, primary: `Download the error file`, indent: 2 },
    { group: 0, primary: 'Correct errors', indent: 2 },
    {
      group: 0,
      primary: 'Upload the file with the corrected records',
      indent: 2,
    },
    {
      group: 0,
      primary:
        'Review the audit details report to view records that were added/updated',
      indent: 1,
    },
  ];

  const retrieveListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Retrieve 5498 Tax File from Core Processing System`,
    },
    {
      group: 0,
      primary: 'Upload IRS 750 formatted tax file',
      indent: 1,
      buttonstring: 'Upload 5498 Tax File',
    },
    {
      group: 0,
      isroot: true,
      primary: `Review Errors from Uploaded Tax File`,
    },
    {
      group: 0,
      primary: `Correct any errors from the upload as needed' accounts`,
      indent: 1,
    },
    {
      group: 0,
      primary:
        'Review audit details report to view records that were added/updated',
      indent: 1,
    },
  ];

  const getTaxReportingSteps = (
    accountType: PrintableTaxForm,
    fileType: boolean
  ) => {
    if (accountType === PrintableTaxForm.TAX_FORM_5498ESA && !fileType) {
      return [...reviewEsaListItems, ...errorListItems, ...listItems];
    }
    if (accountType === PrintableTaxForm.TAX_FORM_5498 && !fileType) {
      return [
        ...reviewListItems,
        ...iraListItems,
        ...errorListItems,
        ...listItems,
      ];
    }
    if (accountType === PrintableTaxForm.TAX_FORM_5498SA && !fileType) {
      return [
        ...reviewHSAListItems,
        ...hsaListItems,
        ...errorListItems,
        ...listItems,
      ];
    }

    if (
      [
        PrintableTaxForm.TAX_FORM_5498ESA,
        PrintableTaxForm.TAX_FORM_5498SA,
        PrintableTaxForm.TAX_FORM_5498,
      ].includes(accountType) &&
      fileType
    ) {
      return [...retrieveListItems, ...listItems];
    }
    return [...retrieveListItems, ...listItems];
  };

  const checkListEval = (props: any, documentStatus: TaxDocument, i: any) => {
    if (!props.formtype) {
      return (
        <ListItem
          key={`listItem_${+1}`}
          sx={{ pl: 5 * props.indent }}
          secondaryAction={
            <SecondaryActionButtons
              formtype={props.formtype}
              documentStatus={documentStatus}
              buttonstring={props.buttonstring}
              triggerReadyToPrint={handleModalOpen}
              checkButtonClicked={checkButtonClicked}
              accountType={reviewType}
            />
          }
          disablePadding
        >
          <ListItemButton
            onClick={(event) => {
              handleListClick(event, props.formtype, documentStatus);
            }}
          >
            {!props.isroot && (
              <ListItemIcon id={i}>
                <CircleIcon color="primary" />
              </ListItemIcon>
            )}
            <Box width={!props.isRoot ? 354 : 640}>
              <ListItemText
                primaryTypographyProps={
                  props.isroot && {
                    variant: 'h5',
                    fontWeight: 'bold',
                  }
                }
                {...props}
              />
            </Box>
          </ListItemButton>
        </ListItem>
      );
    }

    return (
      documentStatus &&
      documentStatus.enabled &&
      !documentStatus.readyToPrint && (
        <ListItem
          key={`listItem_${+1}`}
          sx={{ pl: 5 * props.indent }}
          secondaryAction={
            <SecondaryActionButtons
              formtype={props.formtype}
              documentStatus={documentStatus}
              buttonstring={props.buttonstring}
              triggerReadyToPrint={handleModalOpen}
              checkButtonClicked={checkButtonClicked}
              accountType={reviewType}
            />
          }
          disablePadding
        >
          <ListItemButton
            onClick={(event) => {
              handleListClick(event, props.formtype, documentStatus);
            }}
          >
            {!props.isroot && (
              <ListItemIcon>
                <CircleIcon color="primary" />
              </ListItemIcon>
            )}
            <ListItemText
              primaryTypographyProps={
                props.isroot && {
                  variant: 'h5',
                  fontWeight: 'bold',
                }
              }
              {...props}
            />
          </ListItemButton>
        </ListItem>
      )
    );
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Tax Reporting
          </Typography>
          <Typography variant="h1" color="secondary" gutterBottom>
            5498 Reporting Checklist
          </Typography>
          <TaxReporting5498Selection
            initialValues={{
              taxFormType: reviewType,
              switchValue: fileFormat,
            }}
            onSubmit={(value) => {
              setReviewType(value.taxFormType);
              setFileFormat(value.switchValue);
            }}
            valid5498TaxTypes={valid5498TaxTypes}
          />

          {reviewType && (
            <List sx={{ maxWidth: 640 }}>
              {getTaxReportingSteps(reviewType, fileFormat).map((props, i) => {
                const documentStatus = taxDocumentStatus
                  ? taxDocumentStatus.find(
                      (status) => status.taxFormType === props.formtype
                    )
                  : ({} as TaxDocument);
                const showProgress =
                  documentStatus &&
                  documentStatus.readyToPrint &&
                  documentStatus.enabled;

                return (
                  <>
                    {checkListEval(props, documentStatus, i)}
                    {showProgress && (
                      <Box sx={{ pl: 5 * props.indent }}>
                        <Typography variant="body1">{props.primary}</Typography>

                        <TaxReportingCheckListProgress
                          documentStatus={documentStatus}
                        />
                      </Box>
                    )}
                  </>
                );
              })}
            </List>
          )}

          <SiraModal open={modalOpen} handleClose={handleClose}>
            <>
              <Box sx={contentStyle} pt={2}>
                <Typography variant="body1">
                  {' '}
                  Tax forms will be created upon your approval and will be
                  delivered by Superior.
                </Typography>
              </Box>
              <Box sx={buttonContentStyle}>
                <Box pr={2}>
                  <Button
                    data-qa="delete-modal_cancel"
                    variant="outlined"
                    onClick={() => {
                      handleClose();
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <Button
                    data-qa="delete-modal_confirm"
                    variant="outlined"
                    onClick={() => {
                      triggerReadyToPrint();
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    OK
                  </Button>
                </Box>
              </Box>
            </>
          </SiraModal>
          {showConfetti && (
            <Confetti
              width={width}
              height={height + 400}
              numberOfPieces={200}
              onConfettiComplete={() => {
                setShowConfetti(false);
              }}
              recycle={false}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default TaxReporting5498Checklist;
