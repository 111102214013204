import React from 'react';
import Alert from '@mui/material/Alert';
import { isAfter, parseISO, sub } from 'date-fns';

export interface TermLengthWarningProps {
  dateOfBirth?: string;
}

const TermLengthWarning = ({ dateOfBirth }: TermLengthWarningProps) => {
  const [years, setYears] = React.useState(0);
  const [year, setYear] = React.useState(2100);
  const earlyDistributionMaxBirthdate: Date = sub(new Date(), {
    years: 59,
    months: 6,
  });

  React.useEffect(() => {
    const birthDate = parseISO(dateOfBirth);
    const fiveYearsOlder = sub(birthDate, { years: 5 });

    // User less than 59.5 - 5 so ... Need to take distribution longer than 5 years
    if (isAfter(fiveYearsOlder, earlyDistributionMaxBirthdate)) {
      const sixMonthsOlder = sub(birthDate, { months: 6 });
      const tempYears =
        sixMonthsOlder.getFullYear() -
        earlyDistributionMaxBirthdate.getFullYear();
      setYears(tempYears);
      setYear(new Date().getFullYear() + tempYears);
    } else {
      setYears(5);
      setYear(new Date().getFullYear() + 5);
    }
  }, [dateOfBirth]);

  return (
    <>
      {dateOfBirth && (
        <Alert severity="warning">
          Warning: You will be required to take only these distributions for{' '}
          {years} years. You will be able to stop these distributions or take
          different distributions in {year}.{' '}
        </Alert>
      )}
    </>
  );
};

export default TermLengthWarning;
