/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import {
  Grid,
  Switch,
  Typography,
  Autocomplete,
  TextField,
  IconButton,
  InputAdornment,
  Link,
} from '@mui/material';
import { useFormikContext } from 'formik';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';

import SiraTextField from './SiraTextField';
import StateField from './StateField';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { Account } from '../../api/AccountApi.d';
import { Country } from '../../api/StatesApi.d';
import SiraSelectField, { SiraSelectItem } from './SiraSelectField';
import ZipField from './SiraZipField';
import { getCopyAddressText } from '../../utils/transaction.utils';
import { getGeoApifyClaims } from '../../api/GeoApify';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';

export interface Address {
  id?: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  responsibleForeignStateProvinceRegion: string;
  zip: string;
  country: Country;
}

const defaultAddressFieldNames: AddressFieldNames = {
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  addressLine3: 'addressLine3',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
};

export const ADDRESS_INIT = (addressFieldNames?: AddressFieldNames) => {
  const fieldNames = { ...defaultAddressFieldNames, ...addressFieldNames };

  return {
    [fieldNames.addressLine1]: '',
    [fieldNames.addressLine2]: '',
    [fieldNames.addressLine3]: '',
    [fieldNames.city]: '',
    [fieldNames.state]: '',
    [fieldNames.responsibleForeignStateProvinceRegion]: '',
    [fieldNames.zip]: '',
    [fieldNames.country]: Country['en-US'] as Country,
  };
};

export interface AddressFieldNames {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  state?: string;
  responsibleForeignStateProvinceRegion?: string;
  zip?: string;
  country?: string;
}

interface AddressSchemaConfig {
  required?: boolean;
  addressFieldNames?: AddressFieldNames;
}

const masks = {
  [Country['en-US']]: '99999',
  [Country['en-CA']]: 'a9a 9a9',
  [Country['en-IE']]: 'a9a 9a9a',
  [Country['en-GB']]: 'AA9A 9AA, A9A AAA, A9 9AA or A99 9AA',
  [Country.sv]: '999 99',
};

const regex = /^[A-Z]{1,2}\d{1,2}[A-Z]? \d[A-Z]{2}$/;

const postalCodeTests = {
  /*
    USA
    In the format 11111, where 1 is a digit.
  */
  [Country['en-US']]: '^\\d{5}?$',
  /*
    CANADA
    In the format A1A 1A1, where A is a letter and 1 is a digit.
    A space separates the third and fourth characters.
    Do not include the letters D, F, I, O, Q or U.
    The first position does not make use of the letters W or Z.
  */
  [Country['en-CA']]: '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$',
  [Country['en-GB']]: regex,
};

export const ADDRESS_SCHEMA = (config: AddressSchemaConfig = {}) => {
  const { addressFieldNames = defaultAddressFieldNames, required = false } =
    config;
  const fieldNames = { ...defaultAddressFieldNames, ...addressFieldNames };

  const addressSchemaObj = {
    [fieldNames.country]: yup
      .string()
      .max(80)
      .label('Country')
      .when([fieldNames.addressLine3], (...args) => {
        const [schema] = args;
        return schema && schema.required();
      }),
    [fieldNames.addressLine1]: yup
      .string()
      .trim()
      .max(40)
      .label('Address Line 1')
      .when(fieldNames.zip, (zip, schema) =>
        required || (!required && zip) ? schema.required() : schema,
      ),
    [fieldNames.addressLine2]: yup.string().max(40).label('Address Line 2'),
    [fieldNames.addressLine3]: yup.string().max(40).label('Address Line 3'),
    [fieldNames.city]: yup
      .string()
      .max(40)
      .label('City')
      .when([fieldNames.addressLine1, fieldNames.country], (...args) => {
        const [addressLine1, country, schema] = args;
        const shouldExclude = [Country['en-IE']].includes(country);

        return !shouldExclude && (required || (!required && addressLine1))
          ? schema.required()
          : schema;
      }),
    [fieldNames.state]: yup
      .string()
      .label('State')
      .when([fieldNames.city, fieldNames.country], (...args) => {
        const [city, country, schema] = args;
        return (required && country === Country['en-US']) ||
          (!required && country === Country['en-US'] && city)
          ? schema.required()
          : schema;
      }),
    [fieldNames.responsibleForeignStateProvinceRegion]: yup
      .string()
      .label('Province')
      .when([fieldNames.city, fieldNames.country], (...args) => {
        const [city, country, schema] = args;

        // if country exist then step into this and validate province
        if (country) {
          // Exclude province from validation of this list of countries

          const shouldExclude = [Country['en-CA']].includes(country);
          return shouldExclude && (required || (!required && city))
            ? schema.required()
            : schema;
        }

        return schema;
      }),
    [fieldNames.zip]: yup
      .string()
      .label('Postal Code')
      .when(
        [
          fieldNames.state,
          fieldNames.responsibleForeignStateProvinceRegion,
          fieldNames.country,
        ],
        (...args) => {
          const [state, responsibleForeignStateProvinceRegion, country, schema ] = args;
          const shouldExclude = [
            Country['en-IE'],
            Country['en-WI'],
            Country.il,
            Country.cn,
          ].includes(country);

          return !shouldExclude &&
            (required ||
              (!required && // XOR - If not force-required...
                (state || responsibleForeignStateProvinceRegion) && // but state/province are filled
                postalCodeTests[country])) // Require only if there's a postal code regExp that matches
            ? schema
                .required()
                .matches(
                  new RegExp(postalCodeTests[country]),
                  `Must be a valid postal code for ${country}: ${masks[country]}`,
                )
            : schema;
        },
      ),
  };

  // The array at the end of shape expresses any interdependent fields to prevent dep cycle error
  // Is there any good documentation around it though? No.
  return yup
    .object({})
    .shape(addressSchemaObj, [[fieldNames.addressLine1, fieldNames.zip]]);
};

export interface AddressProps {
  addressFieldNames?: AddressFieldNames; // Mapping of address field names by context (e.g. responsibleAddressLine1 => addressLine1)
  allowAddressCopy?: boolean; // Enable option to copy address from account owner
  accountOwner?: AccountOwner;
  account?: Account;
  international?: boolean;
  copyType?: string;
  esa?: boolean;
}

export interface AutoCompleteProps {
  addressFieldNames?: AddressFieldNames; // Mapping of address field names by context (e.g. responsibleAddressLine1 => addressLine1)
  setCopyFields?: Function;
}

function AutoComplete({ addressFieldNames }: AutoCompleteProps) {
  const { user } = useUser();

  const [options, setOptions] = useState([]);
  const { values, setValues } = useFormikContext();

  const [open, setOpen] = React.useState(false);
  const [noLocation, setNoLocation] = React.useState(false);

  const loading = open && options.length === 0;
  const [results, setresults] = useState([]);

  const classes = {
    input: {
      background: 'white',
      paddingRight: '0px !important',
      paddingLeft: '0px !important',
    },
  };

  const getOptionsDelayed = async (query: string) => {
    const formattedSelection = [];
    const rawData = [];

    await getGeoApifyClaims(user.token, query, user).then((res) => {
      if (res.data.features.length !== 0 && res.data.features) {
        res.data.features.forEach((feature) => {
          formattedSelection.push(feature.properties.formatted);
          rawData.push(feature.properties);
        });
      } else {
        setNoLocation(true);
        formattedSelection.push(
          'No address found based on the information entered',
        );
      }

      setOpen(true);

      setOptions(formattedSelection);
      setresults(rawData);
    });
  };

  const countryCode = (code) => {
    switch (code) {
      case 'us':
        return Country['en-US'];
      case 'ca':
        return Country['en-CA'];
      case 'ie':
        return Country['en-IE'];
      case 'sv':
        return Country.sv;
      case 'ch':
        return Country['en-CH'];
      default:
        return Country['en-US'];
    }
  };

  const onChange = (event: unknown, value: any) => {
    if (results) {
      results.forEach((result) => {
        const houseNumber = result.housenumber || '';
        const street = result.street || '';
        if (result.formatted === value) {
          // Translates Geoapify country code to our frontend enum
          const country = countryCode(result.country_code);

          setValues({
            ...(values as Address),
            [addressFieldNames.country]: country || Country['en-US'],
            [addressFieldNames.addressLine1]: `${houseNumber} ${street}` || '',
            [addressFieldNames.addressLine2]: '',
            [addressFieldNames.addressLine3]: '',
            [addressFieldNames.city]: result.city || '',
            // assign state field if country is US
            [addressFieldNames.state]:
              country === Country['en-US'] ? result.state_code : '',
            // all other countries, province gets assigned
            [addressFieldNames.responsibleForeignStateProvinceRegion]:
              country !== Country['en-US'] ? result.state_code : '',
            [addressFieldNames.zip]: result.postcode || '',
            [addressFieldNames.country]: result.country || Country['en-US'],
          });
        }
      });
    }
  };

  const onInputChange = (event: unknown, value: string) => {
    getOptionsDelayed(value);
  };

  return (
    <>
      <Autocomplete
        freeSolo
        id="autoComplete"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onFocus={() => {
          setOpen(true);
        }}
        options={options}
        filterOptions={(x) => x}
        loading={loading}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <li {...props}>
            {noLocation ? (
              <Typography variant="body2">{option}</Typography>
            ) : (
              <>
                {' '}
                <LocationOnIcon />
                <Typography variant="body2">{option}</Typography>{' '}
              </>
            )}
          </li>
        )}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label="Address Look up"
              InputProps={{
                sx: classes.input,
                ...params.InputProps,
                type: 'search',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="secondary"
                      aria-label="submit and find address"
                      type="submit"
                      size="large"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="body2" pl={1} style={{ fontStyle: 'italic' }}>
              Powered by{' '}
              <Link href="https://www.geoapify.com" variant="body2">
                geoapify.
              </Link>
            </Typography>
          </>
        )}
      />
    </>
  );
}

function determineUserCountry(values, esa) {
  const { responsibleCountry, responsibleState, country } = values;
  if (esa) {
    if (responsibleCountry === undefined && responsibleState) {
      return false;
    }
    return responsibleCountry !== Country['en-US'];
  }
  return country !== Country['en-US'];
}

function ResponsibleAddressForm({
  addressFieldNames,
  allowAddressCopy = false,
  accountOwner = {},
  account = {},
  international = false,
  copyType,
  esa = false,
}: AddressProps) {
  const fieldNames = { ...defaultAddressFieldNames, ...addressFieldNames };
  const { values, setValues, initialValues } = useFormikContext<any>();
  const [copyFields, setCopyFields] = useState(false as boolean);
  const [isInitialized, setIsInitialized] = useState(false as boolean);
  const copyAddressFields = (event) => {
    setCopyFields(event.target.checked);
  };
  const { countries } = useGlobalContext();
  const accountType = copyType || account.accountType;
  const [showCity, setshowCity] = useState(true as boolean);
  const [showState, setshowState] = useState(true as boolean);
  const [showZip, setshowZip] = useState(true as boolean);
  const [showforeignStateProvinceRegion, setforeignStateProvinceRegion] =
    useState(true as boolean);


  // Check if non-US address since we need to send a different field name to the API
  const [isForeignProvince, setisForeignProvince] = useState(false);

  // Get the text for the copy address fied based on account type
  const copyAddressText = getCopyAddressText(accountType);

  const countryOptionDropDown: Array<SiraSelectItem> = Object.keys(
    countries || {},
  ).map((countryName) => {
    return {
      value: countryName,
      label: countries[countryName as keyof typeof countries],
    };
  });

  useEffect(() => {
    if (copyFields) {
      setValues({
        ...(values as Address),
        [fieldNames.country]: accountOwner.country || '',
        [fieldNames.addressLine1]: accountOwner.addressLine1 || '',
        [fieldNames.addressLine2]: accountOwner.addressLine2 || '',
        [fieldNames.addressLine3]: accountOwner.addressLine3 || '',
        [fieldNames.city]: accountOwner.city || '',
        [fieldNames.state]: accountOwner.state || '',
        [fieldNames.responsibleForeignStateProvinceRegion]:
          accountOwner.foreignStateProvinceRegion || '',
        [fieldNames.zip]: accountOwner.zip || '',
      });
    } else {
      setValues({
        ...(values as Address),
        [fieldNames.addressLine1]: initialValues[fieldNames.addressLine1] || '',
        [fieldNames.addressLine2]: initialValues[fieldNames.addressLine2] || '',
        [fieldNames.addressLine3]: initialValues[fieldNames.addressLine3] || '',
        [fieldNames.city]: initialValues[fieldNames.city] || '',
        [fieldNames.state]: initialValues[fieldNames.state] || '',
        [fieldNames.responsibleForeignStateProvinceRegion]:
          initialValues[fieldNames.responsibleForeignStateProvinceRegion] || '',
        [fieldNames.zip]: initialValues[fieldNames.zip] || '',
      });
    }
  }, [copyFields]);

  useEffect(() => {
    // we bring in the new grouping that is setup in the constant session storage
    let countryRequiredFields = JSON.parse(sessionStorage.getItem('constants'));
    if (countryRequiredFields) {
      if (values.responsibleCountry && esa) {
        countryRequiredFields =
          countryRequiredFields.ADDRESS_REQUIREMENTS[
            values.responsibleCountry.toUpperCase()
          ];
      } else if (values.country) {
        countryRequiredFields =
          countryRequiredFields.ADDRESS_REQUIREMENTS[
            values.country.toUpperCase()
          ];
      } else {
        countryRequiredFields =
          countryRequiredFields.ADDRESS_REQUIREMENTS[Country['en-US']];
      }
    }

    setisForeignProvince(determineUserCountry(values, esa));

    // grab constats and find selected country in the list, take the results and set the fields for city,state,zip and foreignStateProvinceRegion
    if (countryRequiredFields) {
      setshowZip(countryRequiredFields.zip === 'true');
      setshowCity(countryRequiredFields.city === 'true');
      setshowState(countryRequiredFields.state === 'true');
      setforeignStateProvinceRegion(
        countryRequiredFields.foreignStateProvinceRegion === 'true',
      );
    }
    // We out the values that likely wouldn't translate to another country's address format
    if (isInitialized && !copyFields) {
      setValues({
        ...values,
        [fieldNames.addressLine3]: '',
        [fieldNames.responsibleForeignStateProvinceRegion]: '',
        [fieldNames.city]: '',
        [fieldNames.state]: '',
        [fieldNames.zip]: '',
      });
    } else {
      setIsInitialized(true);
    }

  }, [values.responsibleCountry]);

  return (
    <>
      <Grid item xs={12}>
        <AutoComplete
          addressFieldNames={fieldNames}
          setCopyFields={setCopyFields}
        />
      </Grid>
      {international && (
        <Grid item xs={12} sm={5}>
          <SiraSelectField
            disabled={copyFields}
            items={countryOptionDropDown}
            name={fieldNames.country}
            label="Country"
          />
        </Grid>
      )}

      <Grid item xs={12} sm={8}>
        <SiraTextField
          disabled={copyFields}
          name={fieldNames.addressLine1}
          label="Address Line 1"
        />
      </Grid>

      {/* Copy Address From Owner */}
      {allowAddressCopy && accountOwner.addressLine1 && (
        <Grid item xs={12} sm={4}>
          <Typography component="div">
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <Switch checked={copyFields} onChange={copyAddressFields} />
              </Grid>
              <Grid item>{copyAddressText}</Grid>
            </Grid>
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} sm={8}>
        <SiraTextField
          disabled={copyFields}
          name={fieldNames.addressLine2}
          label="Address Line 2"
        />
      </Grid>
      {!showState &&
        !showCity &&
        !showZip &&
        !showforeignStateProvinceRegion && (
          <Grid item xs={12} sm={8}>
            <SiraTextField
              disabled={copyFields}
              name={fieldNames.addressLine3}
              label="Address Line 3"
            />
          </Grid>
        )}
      <>
        {showCity && (
          <Grid item xs={12} sm={6}>
            <SiraTextField
              disabled={copyFields}
              name={fieldNames.city}
              label="City"
            />
          </Grid>
        )}
        {(showState || showforeignStateProvinceRegion) && (
          <Grid item xs={12} sm={3}>
            <StateField
              country={values.responsibleCountry}
              disabled={copyFields}
              name={
                isForeignProvince
                  ? fieldNames.responsibleForeignStateProvinceRegion 
                  : fieldNames.state
              }
              label={isForeignProvince ? 'Province' : 'State'}
            />
          </Grid>
        )}
        {showZip && (
          <Grid item xs={12} sm={3}>
            <ZipField
              country={values.responsibleCountry}
              disabled={copyFields}
              name={fieldNames.zip}
              label={isForeignProvince ? 'Postal Code' : 'Zip Code'}
            />
          </Grid>
        )}
      </>
    </>
  );
}

export default ResponsibleAddressForm;
