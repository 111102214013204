import axios from 'axios';
import format from 'string-format';

import {
  IMMDocumentType,
  IMMSignatureUserType,
  ImmSessionRequest,
  ImmSessionResponse,
  SuperiorIraEsignReponse,
} from './ESignApi.d';
import { setBaseUrl } from './api.utils';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const ImmSessionUrl = `/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/esign/generate`;
const customerPortalEsignUrl = `/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/signature`;
const customerPortalSignUrl = `/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/documents/sign`;
const superioruserPortalEsignUrl = `/org/{0.orgId}/superioruser/signature`;
const superioruserPortalSignUrl = `/org/{0.orgId}/superioruser/documents/sign`;

export async function getIMMSessionUrl(
  data: Array<ImmSessionRequest>,
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  s3FilePath: string,
  documentType: IMMDocumentType,
  token: string
): Promise<ImmSessionResponse> {
  const url = `${urlBase}${format(ImmSessionUrl, {
    orgId,
    accountId,
    accountOwnerId,
  })}`;

  const params = { s3FilePath, documentType };

  return axiosInstance.post(url, data, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getCustomerPortalEsignUrl(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  user
): Promise<SuperiorIraEsignReponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${customerPortalEsignUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// call put to update user signature
export async function putCustomerPortalSignUrl(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  signatureText: string,
  user
): Promise<SuperiorIraEsignReponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${customerPortalEsignUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
  })}`;
  const params = { signatureText };

  return axiosInstance.put(url,{},{
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// post user signature to s3path
export async function postCustomerPortalSignUrl(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  token: string,
  s3FilePath: string,
  eSignatureType: IMMSignatureUserType,
  user
): Promise<SuperiorIraEsignReponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${customerPortalSignUrl}`, {
    orgId,
    accountOwnerId,
    accountId
  })}`;

  const data = { s3FilePath, eSignatureType };
  return axiosInstance.post(url, data,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export async function getSuperioruserPortalEsignUrl(
  orgId: string,
  token: string,
  user
): Promise<SuperiorIraEsignReponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${superioruserPortalEsignUrl}`, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// call put to update user signature
export async function putsuperioruserPortalSignUrl(
  orgId: string,
  token: string,
  signatureText: string,
  user
): Promise<SuperiorIraEsignReponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${superioruserPortalEsignUrl}`, {
    orgId
  })}`;
  const params = { signatureText };

  return axiosInstance.put(url,{},{
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// post user signature to s3path
export async function postsuperioruserPortalSignUrl(
  orgId: string,
  token: string,
  s3FilePath: string,
  eSignatureType: IMMSignatureUserType,
  user
): Promise<SuperiorIraEsignReponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${superioruserPortalSignUrl}`, {
    orgId,
  })}`;

  const data = { s3FilePath, eSignatureType };
  return axiosInstance.post(url, data,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
