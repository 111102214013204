import { DistributionMethod } from '../../../api/DistributionApi.d';
import { DefaultRmd } from '../../../api/OrganizationApi.d';

export enum DefaultRmdType {
  rmd = 'RMD',
  owner = 'OWNER_RESPONSIBLE',
}

export interface DefaultRmdFormData extends DefaultRmd {
  defaultRmdType: DefaultRmdType;
  rmdNotificationsEnabled: boolean;
}

export interface AllowedDistribution {
  allowedDistributionMethods: Array<DistributionMethod>;
}
