import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SvgRolloverTransfer(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M6.236 13.05a.561.561 0 00-.462 0c-.392.2-.747.399-1.138.599-1.138.599-2.311 1.23-3.45 1.83-.141.066-.213.166-.177.299v.266c0 .2.071.266.284.266h.676v.033c0 .166.142.266.284.266h.143v.067c0 .233-.036.499-.036.732l.036.399c-.036.532-.071 1.097-.107 1.663 0 .133 0 .233-.036.366v.067h-.07c-.32 0-.57.232-.57.532 0 .166.071.299.178.399.107.1.249.166.427.166h7.68c.32 0 .604-.233.604-.532 0-.167-.07-.3-.177-.4a.61.61 0 00-.427-.165H9.79c-.035-.466-.07-.932-.07-1.365 0-.2-.037-.365-.037-.565-.035-.4-.035-.798-.07-1.23v-.134h.106a.271.271 0 00.284-.266v-.033h.712c.213 0 .284-.067.284-.266v-.266a.344.344 0 00-.178-.3c-1.529-.798-3.058-1.596-4.586-2.428zM18.236 13.05a.561.561 0 00-.462 0c-.392.2-.747.399-1.138.599-1.138.599-2.311 1.23-3.45 1.83-.141.066-.213.166-.177.299v.266c0 .2.071.266.284.266h.676v.033c0 .166.142.266.284.266h.143v.067c0 .233-.036.499-.036.732l.036.399c-.036.532-.071 1.097-.107 1.663 0 .133 0 .233-.036.366v.067h-.07c-.32 0-.57.232-.57.532 0 .166.071.299.178.399.107.1.249.166.427.166h7.68c.32 0 .604-.233.604-.532 0-.167-.07-.3-.177-.4a.61.61 0 00-.427-.165h-.107c-.035-.466-.07-.932-.07-1.365 0-.2-.037-.365-.037-.565-.035-.4-.035-.798-.07-1.23v-.134h.106a.271.271 0 00.284-.266v-.033h.712c.213 0 .284-.067.284-.266v-.266a.344.344 0 00-.178-.3c-1.529-.798-3.058-1.596-4.586-2.428zM4.439 7c.134 0 .27-.047.36-.187 1.845-2.477 4.635-3.879 7.65-3.879 2.655 0 5.176 1.122 7.02 3.131l-2.07-.187c-.224 0-.45.187-.45.42 0 .235.18.468.406.468l2.655.187h.045c.18 0 .36-.093.54-.187.18-.14.27-.374.27-.607L21 3.402c0-.234-.18-.468-.405-.468-.225 0-.45.187-.45.42l-.09 2.057A10.232 10.232 0 0012.405 2C9.118 2 6.103 3.542 4.077 6.252c-.134.187-.09.468.09.654.09.047.18.094.27.094z" />
        <path d="M15 11c0 .976-.699 1.801-1.667 2.112V14h-1.666v-.888C10.699 12.802 10 11.976 10 11h1.667c0 .413.373.75.833.75.46 0 .833-.337.833-.75s-.373-.75-.833-.75C11.122 10.25 10 9.24 10 8c0-.976.699-1.801 1.667-2.112V5h1.666v.888C14.301 6.198 15 7.024 15 8h-1.667c0-.413-.373-.75-.833-.75-.46 0-.833.337-.833.75s.373.75.833.75c1.378 0 2.5 1.01 2.5 2.25z" />
      </g>
    </SvgIcon>
  );
}

export default SvgRolloverTransfer;
