import { RolesResponse } from './RoleApi.d';
import { UserRole } from './UserApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const rolesUrl = '/api/roles';
const customerPortalRolesUrl = '/api/customerPortal/roles';

export async function getAllRoles(token: string, user:any): Promise<RolesResponse> {
  let url ='';
  if(user.roles.includes(UserRole.accountOwner)){
    url = `${urlBase}${customerPortalRolesUrl}`;
  } else {
    url = `${urlBase}${rolesUrl}`;
  }

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
